/* eslint-disable */
/* tslint:disable */

//////////////////////////////////////////////////////////////////////////////
/// Autogenerated by tailwindcss-classnames CLI. https://git.io/JtsPU
/// DO NOT EDIT THIS FILE DIRECTLY!
//////////////////////////////////////////////////////////////////////////////

import classnamesLib from 'clsx'

export type TScreenReaders = 'sr-only' | 'not-sr-only'

export type TAccessibility = TScreenReaders

export type TBackgroundAttachment = 'bg-fixed' | 'bg-local' | 'bg-scroll'

export type TBackgroundClip =
  | 'bg-clip-border'
  | 'bg-clip-padding'
  | 'bg-clip-content'
  | 'bg-clip-text'

export type TBackgroundOrigin =
  | 'bg-origin-border'
  | 'bg-origin-padding'
  | 'bg-origin-content'

export type TBackgroundRepeat =
  | 'bg-repeat'
  | 'bg-no-repeat'
  | 'bg-repeat-x'
  | 'bg-repeat-y'
  | 'bg-repeat-round'
  | 'bg-repeat-space'

export type TBackgroundOpacity =
  | 'bg-opacity-0'
  | 'bg-opacity-5'
  | 'bg-opacity-10'
  | 'bg-opacity-20'
  | 'bg-opacity-25'
  | 'bg-opacity-30'
  | 'bg-opacity-40'
  | 'bg-opacity-50'
  | 'bg-opacity-60'
  | 'bg-opacity-70'
  | 'bg-opacity-75'
  | 'bg-opacity-80'
  | 'bg-opacity-90'
  | 'bg-opacity-95'
  | 'bg-opacity-100'

export type TBackgroundColor =
  | 'bg-transparent'
  | 'bg-current'
  | 'bg-black'
  | 'bg-white'
  | 'bg-gray-50'
  | 'bg-gray-100'
  | 'bg-gray-200'
  | 'bg-gray-300'
  | 'bg-gray-400'
  | 'bg-gray-500'
  | 'bg-gray-600'
  | 'bg-gray-700'
  | 'bg-gray-800'
  | 'bg-gray-900'
  | 'bg-red-50'
  | 'bg-red-100'
  | 'bg-red-200'
  | 'bg-red-300'
  | 'bg-red-400'
  | 'bg-red-500'
  | 'bg-red-600'
  | 'bg-red-700'
  | 'bg-red-800'
  | 'bg-red-900'
  | 'bg-yellow-50'
  | 'bg-yellow-100'
  | 'bg-yellow-200'
  | 'bg-yellow-300'
  | 'bg-yellow-400'
  | 'bg-yellow-500'
  | 'bg-yellow-600'
  | 'bg-yellow-700'
  | 'bg-yellow-800'
  | 'bg-yellow-900'
  | 'bg-green-50'
  | 'bg-green-100'
  | 'bg-green-200'
  | 'bg-green-300'
  | 'bg-green-400'
  | 'bg-green-500'
  | 'bg-green-600'
  | 'bg-green-700'
  | 'bg-green-800'
  | 'bg-green-900'
  | 'bg-blue-50'
  | 'bg-blue-100'
  | 'bg-blue-200'
  | 'bg-blue-300'
  | 'bg-blue-400'
  | 'bg-blue-500'
  | 'bg-blue-600'
  | 'bg-blue-700'
  | 'bg-blue-800'
  | 'bg-blue-900'
  | 'bg-indigo-50'
  | 'bg-indigo-100'
  | 'bg-indigo-200'
  | 'bg-indigo-300'
  | 'bg-indigo-400'
  | 'bg-indigo-500'
  | 'bg-indigo-600'
  | 'bg-indigo-700'
  | 'bg-indigo-800'
  | 'bg-indigo-900'
  | 'bg-purple-50'
  | 'bg-purple-100'
  | 'bg-purple-200'
  | 'bg-purple-300'
  | 'bg-purple-400'
  | 'bg-purple-500'
  | 'bg-purple-600'
  | 'bg-purple-700'
  | 'bg-purple-800'
  | 'bg-purple-900'
  | 'bg-pink-50'
  | 'bg-pink-100'
  | 'bg-pink-200'
  | 'bg-pink-300'
  | 'bg-pink-400'
  | 'bg-pink-500'
  | 'bg-pink-600'
  | 'bg-pink-700'
  | 'bg-pink-800'
  | 'bg-pink-900'

export type TBackgroundPosition =
  | 'bg-bottom'
  | 'bg-center'
  | 'bg-left'
  | 'bg-left-bottom'
  | 'bg-left-top'
  | 'bg-right'
  | 'bg-right-bottom'
  | 'bg-right-top'
  | 'bg-top'

export type TBackgroundSize = 'bg-auto' | 'bg-cover' | 'bg-contain'

export type TBackgroundImage =
  | 'bg-none'
  | 'bg-gradient-to-t'
  | 'bg-gradient-to-tr'
  | 'bg-gradient-to-r'
  | 'bg-gradient-to-br'
  | 'bg-gradient-to-b'
  | 'bg-gradient-to-bl'
  | 'bg-gradient-to-l'
  | 'bg-gradient-to-tl'

export type TGradientColorStops =
  | 'from-transparent'
  | 'via-transparent'
  | 'to-transparent'
  | 'from-current'
  | 'via-current'
  | 'to-current'
  | 'from-black'
  | 'via-black'
  | 'to-black'
  | 'from-white'
  | 'via-white'
  | 'to-white'
  | 'from-gray-50'
  | 'via-gray-50'
  | 'to-gray-50'
  | 'from-gray-100'
  | 'via-gray-100'
  | 'to-gray-100'
  | 'from-gray-200'
  | 'via-gray-200'
  | 'to-gray-200'
  | 'from-gray-300'
  | 'via-gray-300'
  | 'to-gray-300'
  | 'from-gray-400'
  | 'via-gray-400'
  | 'to-gray-400'
  | 'from-gray-500'
  | 'via-gray-500'
  | 'to-gray-500'
  | 'from-gray-600'
  | 'via-gray-600'
  | 'to-gray-600'
  | 'from-gray-700'
  | 'via-gray-700'
  | 'to-gray-700'
  | 'from-gray-800'
  | 'via-gray-800'
  | 'to-gray-800'
  | 'from-gray-900'
  | 'via-gray-900'
  | 'to-gray-900'
  | 'from-red-50'
  | 'via-red-50'
  | 'to-red-50'
  | 'from-red-100'
  | 'via-red-100'
  | 'to-red-100'
  | 'from-red-200'
  | 'via-red-200'
  | 'to-red-200'
  | 'from-red-300'
  | 'via-red-300'
  | 'to-red-300'
  | 'from-red-400'
  | 'via-red-400'
  | 'to-red-400'
  | 'from-red-500'
  | 'via-red-500'
  | 'to-red-500'
  | 'from-red-600'
  | 'via-red-600'
  | 'to-red-600'
  | 'from-red-700'
  | 'via-red-700'
  | 'to-red-700'
  | 'from-red-800'
  | 'via-red-800'
  | 'to-red-800'
  | 'from-red-900'
  | 'via-red-900'
  | 'to-red-900'
  | 'from-yellow-50'
  | 'via-yellow-50'
  | 'to-yellow-50'
  | 'from-yellow-100'
  | 'via-yellow-100'
  | 'to-yellow-100'
  | 'from-yellow-200'
  | 'via-yellow-200'
  | 'to-yellow-200'
  | 'from-yellow-300'
  | 'via-yellow-300'
  | 'to-yellow-300'
  | 'from-yellow-400'
  | 'via-yellow-400'
  | 'to-yellow-400'
  | 'from-yellow-500'
  | 'via-yellow-500'
  | 'to-yellow-500'
  | 'from-yellow-600'
  | 'via-yellow-600'
  | 'to-yellow-600'
  | 'from-yellow-700'
  | 'via-yellow-700'
  | 'to-yellow-700'
  | 'from-yellow-800'
  | 'via-yellow-800'
  | 'to-yellow-800'
  | 'from-yellow-900'
  | 'via-yellow-900'
  | 'to-yellow-900'
  | 'from-green-50'
  | 'via-green-50'
  | 'to-green-50'
  | 'from-green-100'
  | 'via-green-100'
  | 'to-green-100'
  | 'from-green-200'
  | 'via-green-200'
  | 'to-green-200'
  | 'from-green-300'
  | 'via-green-300'
  | 'to-green-300'
  | 'from-green-400'
  | 'via-green-400'
  | 'to-green-400'
  | 'from-green-500'
  | 'via-green-500'
  | 'to-green-500'
  | 'from-green-600'
  | 'via-green-600'
  | 'to-green-600'
  | 'from-green-700'
  | 'via-green-700'
  | 'to-green-700'
  | 'from-green-800'
  | 'via-green-800'
  | 'to-green-800'
  | 'from-green-900'
  | 'via-green-900'
  | 'to-green-900'
  | 'from-blue-50'
  | 'via-blue-50'
  | 'to-blue-50'
  | 'from-blue-100'
  | 'via-blue-100'
  | 'to-blue-100'
  | 'from-blue-200'
  | 'via-blue-200'
  | 'to-blue-200'
  | 'from-blue-300'
  | 'via-blue-300'
  | 'to-blue-300'
  | 'from-blue-400'
  | 'via-blue-400'
  | 'to-blue-400'
  | 'from-blue-500'
  | 'via-blue-500'
  | 'to-blue-500'
  | 'from-blue-600'
  | 'via-blue-600'
  | 'to-blue-600'
  | 'from-blue-700'
  | 'via-blue-700'
  | 'to-blue-700'
  | 'from-blue-800'
  | 'via-blue-800'
  | 'to-blue-800'
  | 'from-blue-900'
  | 'via-blue-900'
  | 'to-blue-900'
  | 'from-indigo-50'
  | 'via-indigo-50'
  | 'to-indigo-50'
  | 'from-indigo-100'
  | 'via-indigo-100'
  | 'to-indigo-100'
  | 'from-indigo-200'
  | 'via-indigo-200'
  | 'to-indigo-200'
  | 'from-indigo-300'
  | 'via-indigo-300'
  | 'to-indigo-300'
  | 'from-indigo-400'
  | 'via-indigo-400'
  | 'to-indigo-400'
  | 'from-indigo-500'
  | 'via-indigo-500'
  | 'to-indigo-500'
  | 'from-indigo-600'
  | 'via-indigo-600'
  | 'to-indigo-600'
  | 'from-indigo-700'
  | 'via-indigo-700'
  | 'to-indigo-700'
  | 'from-indigo-800'
  | 'via-indigo-800'
  | 'to-indigo-800'
  | 'from-indigo-900'
  | 'via-indigo-900'
  | 'to-indigo-900'
  | 'from-purple-50'
  | 'via-purple-50'
  | 'to-purple-50'
  | 'from-purple-100'
  | 'via-purple-100'
  | 'to-purple-100'
  | 'from-purple-200'
  | 'via-purple-200'
  | 'to-purple-200'
  | 'from-purple-300'
  | 'via-purple-300'
  | 'to-purple-300'
  | 'from-purple-400'
  | 'via-purple-400'
  | 'to-purple-400'
  | 'from-purple-500'
  | 'via-purple-500'
  | 'to-purple-500'
  | 'from-purple-600'
  | 'via-purple-600'
  | 'to-purple-600'
  | 'from-purple-700'
  | 'via-purple-700'
  | 'to-purple-700'
  | 'from-purple-800'
  | 'via-purple-800'
  | 'to-purple-800'
  | 'from-purple-900'
  | 'via-purple-900'
  | 'to-purple-900'
  | 'from-pink-50'
  | 'via-pink-50'
  | 'to-pink-50'
  | 'from-pink-100'
  | 'via-pink-100'
  | 'to-pink-100'
  | 'from-pink-200'
  | 'via-pink-200'
  | 'to-pink-200'
  | 'from-pink-300'
  | 'via-pink-300'
  | 'to-pink-300'
  | 'from-pink-400'
  | 'via-pink-400'
  | 'to-pink-400'
  | 'from-pink-500'
  | 'via-pink-500'
  | 'to-pink-500'
  | 'from-pink-600'
  | 'via-pink-600'
  | 'to-pink-600'
  | 'from-pink-700'
  | 'via-pink-700'
  | 'to-pink-700'
  | 'from-pink-800'
  | 'via-pink-800'
  | 'to-pink-800'
  | 'from-pink-900'
  | 'via-pink-900'
  | 'to-pink-900'

export type TBackgrounds =
  | TBackgroundAttachment
  | TBackgroundClip
  | TBackgroundOrigin
  | TBackgroundRepeat
  | TBackgroundOpacity
  | TBackgroundColor
  | TBackgroundPosition
  | TBackgroundSize
  | TBackgroundImage
  | TGradientColorStops

export type TBorderStyle =
  | 'border-solid'
  | 'border-dashed'
  | 'border-dotted'
  | 'border-double'
  | 'border-none'

export type TDivideStyle =
  | 'divide-solid'
  | 'divide-dashed'
  | 'divide-dotted'
  | 'divide-double'
  | 'divide-none'

export type TBorderColor =
  | 'border-transparent'
  | 'border-current'
  | 'border-black'
  | 'border-white'
  | 'border-gray-50'
  | 'border-gray-100'
  | 'border-gray-200'
  | 'border-gray-300'
  | 'border-gray-400'
  | 'border-gray-500'
  | 'border-gray-600'
  | 'border-gray-700'
  | 'border-gray-800'
  | 'border-gray-900'
  | 'border-red-50'
  | 'border-red-100'
  | 'border-red-200'
  | 'border-red-300'
  | 'border-red-400'
  | 'border-red-500'
  | 'border-red-600'
  | 'border-red-700'
  | 'border-red-800'
  | 'border-red-900'
  | 'border-yellow-50'
  | 'border-yellow-100'
  | 'border-yellow-200'
  | 'border-yellow-300'
  | 'border-yellow-400'
  | 'border-yellow-500'
  | 'border-yellow-600'
  | 'border-yellow-700'
  | 'border-yellow-800'
  | 'border-yellow-900'
  | 'border-green-50'
  | 'border-green-100'
  | 'border-green-200'
  | 'border-green-300'
  | 'border-green-400'
  | 'border-green-500'
  | 'border-green-600'
  | 'border-green-700'
  | 'border-green-800'
  | 'border-green-900'
  | 'border-blue-50'
  | 'border-blue-100'
  | 'border-blue-200'
  | 'border-blue-300'
  | 'border-blue-400'
  | 'border-blue-500'
  | 'border-blue-600'
  | 'border-blue-700'
  | 'border-blue-800'
  | 'border-blue-900'
  | 'border-indigo-50'
  | 'border-indigo-100'
  | 'border-indigo-200'
  | 'border-indigo-300'
  | 'border-indigo-400'
  | 'border-indigo-500'
  | 'border-indigo-600'
  | 'border-indigo-700'
  | 'border-indigo-800'
  | 'border-indigo-900'
  | 'border-purple-50'
  | 'border-purple-100'
  | 'border-purple-200'
  | 'border-purple-300'
  | 'border-purple-400'
  | 'border-purple-500'
  | 'border-purple-600'
  | 'border-purple-700'
  | 'border-purple-800'
  | 'border-purple-900'
  | 'border-pink-50'
  | 'border-pink-100'
  | 'border-pink-200'
  | 'border-pink-300'
  | 'border-pink-400'
  | 'border-pink-500'
  | 'border-pink-600'
  | 'border-pink-700'
  | 'border-pink-800'
  | 'border-pink-900'

export type TBorderOpacity =
  | 'border-opacity-0'
  | 'border-opacity-5'
  | 'border-opacity-10'
  | 'border-opacity-20'
  | 'border-opacity-25'
  | 'border-opacity-30'
  | 'border-opacity-40'
  | 'border-opacity-50'
  | 'border-opacity-60'
  | 'border-opacity-70'
  | 'border-opacity-75'
  | 'border-opacity-80'
  | 'border-opacity-90'
  | 'border-opacity-95'
  | 'border-opacity-100'

export type TBorderRadius =
  | 'rounded-t-none'
  | 'rounded-r-none'
  | 'rounded-b-none'
  | 'rounded-l-none'
  | 'rounded-tr-none'
  | 'rounded-tl-none'
  | 'rounded-br-none'
  | 'rounded-bl-none'
  | 'rounded-none'
  | 'rounded-t-sm'
  | 'rounded-r-sm'
  | 'rounded-b-sm'
  | 'rounded-l-sm'
  | 'rounded-tr-sm'
  | 'rounded-tl-sm'
  | 'rounded-br-sm'
  | 'rounded-bl-sm'
  | 'rounded-sm'
  | 'rounded-t'
  | 'rounded-r'
  | 'rounded-b'
  | 'rounded-l'
  | 'rounded-tr'
  | 'rounded-tl'
  | 'rounded-br'
  | 'rounded-bl'
  | 'rounded'
  | 'rounded-t-md'
  | 'rounded-r-md'
  | 'rounded-b-md'
  | 'rounded-l-md'
  | 'rounded-tr-md'
  | 'rounded-tl-md'
  | 'rounded-br-md'
  | 'rounded-bl-md'
  | 'rounded-md'
  | 'rounded-t-lg'
  | 'rounded-r-lg'
  | 'rounded-b-lg'
  | 'rounded-l-lg'
  | 'rounded-tr-lg'
  | 'rounded-tl-lg'
  | 'rounded-br-lg'
  | 'rounded-bl-lg'
  | 'rounded-lg'
  | 'rounded-t-xl'
  | 'rounded-r-xl'
  | 'rounded-b-xl'
  | 'rounded-l-xl'
  | 'rounded-tr-xl'
  | 'rounded-tl-xl'
  | 'rounded-br-xl'
  | 'rounded-bl-xl'
  | 'rounded-xl'
  | 'rounded-t-2xl'
  | 'rounded-r-2xl'
  | 'rounded-b-2xl'
  | 'rounded-l-2xl'
  | 'rounded-tr-2xl'
  | 'rounded-tl-2xl'
  | 'rounded-br-2xl'
  | 'rounded-bl-2xl'
  | 'rounded-2xl'
  | 'rounded-t-3xl'
  | 'rounded-r-3xl'
  | 'rounded-b-3xl'
  | 'rounded-l-3xl'
  | 'rounded-tr-3xl'
  | 'rounded-tl-3xl'
  | 'rounded-br-3xl'
  | 'rounded-bl-3xl'
  | 'rounded-3xl'
  | 'rounded-t-full'
  | 'rounded-r-full'
  | 'rounded-b-full'
  | 'rounded-l-full'
  | 'rounded-tr-full'
  | 'rounded-tl-full'
  | 'rounded-br-full'
  | 'rounded-bl-full'
  | 'rounded-full'

export type TBorderWidth =
  | 'border-t-0'
  | 'border-r-0'
  | 'border-b-0'
  | 'border-l-0'
  | 'border-0'
  | 'border-t-2'
  | 'border-r-2'
  | 'border-b-2'
  | 'border-l-2'
  | 'border-2'
  | 'border-t-4'
  | 'border-r-4'
  | 'border-b-4'
  | 'border-l-4'
  | 'border-4'
  | 'border-t-8'
  | 'border-r-8'
  | 'border-b-8'
  | 'border-l-8'
  | 'border-8'
  | 'border-t'
  | 'border-r'
  | 'border-b'
  | 'border-l'
  | 'border'

export type TDivideColor =
  | 'divide-transparent'
  | 'divide-current'
  | 'divide-black'
  | 'divide-white'
  | 'divide-gray-50'
  | 'divide-gray-100'
  | 'divide-gray-200'
  | 'divide-gray-300'
  | 'divide-gray-400'
  | 'divide-gray-500'
  | 'divide-gray-600'
  | 'divide-gray-700'
  | 'divide-gray-800'
  | 'divide-gray-900'
  | 'divide-red-50'
  | 'divide-red-100'
  | 'divide-red-200'
  | 'divide-red-300'
  | 'divide-red-400'
  | 'divide-red-500'
  | 'divide-red-600'
  | 'divide-red-700'
  | 'divide-red-800'
  | 'divide-red-900'
  | 'divide-yellow-50'
  | 'divide-yellow-100'
  | 'divide-yellow-200'
  | 'divide-yellow-300'
  | 'divide-yellow-400'
  | 'divide-yellow-500'
  | 'divide-yellow-600'
  | 'divide-yellow-700'
  | 'divide-yellow-800'
  | 'divide-yellow-900'
  | 'divide-green-50'
  | 'divide-green-100'
  | 'divide-green-200'
  | 'divide-green-300'
  | 'divide-green-400'
  | 'divide-green-500'
  | 'divide-green-600'
  | 'divide-green-700'
  | 'divide-green-800'
  | 'divide-green-900'
  | 'divide-blue-50'
  | 'divide-blue-100'
  | 'divide-blue-200'
  | 'divide-blue-300'
  | 'divide-blue-400'
  | 'divide-blue-500'
  | 'divide-blue-600'
  | 'divide-blue-700'
  | 'divide-blue-800'
  | 'divide-blue-900'
  | 'divide-indigo-50'
  | 'divide-indigo-100'
  | 'divide-indigo-200'
  | 'divide-indigo-300'
  | 'divide-indigo-400'
  | 'divide-indigo-500'
  | 'divide-indigo-600'
  | 'divide-indigo-700'
  | 'divide-indigo-800'
  | 'divide-indigo-900'
  | 'divide-purple-50'
  | 'divide-purple-100'
  | 'divide-purple-200'
  | 'divide-purple-300'
  | 'divide-purple-400'
  | 'divide-purple-500'
  | 'divide-purple-600'
  | 'divide-purple-700'
  | 'divide-purple-800'
  | 'divide-purple-900'
  | 'divide-pink-50'
  | 'divide-pink-100'
  | 'divide-pink-200'
  | 'divide-pink-300'
  | 'divide-pink-400'
  | 'divide-pink-500'
  | 'divide-pink-600'
  | 'divide-pink-700'
  | 'divide-pink-800'
  | 'divide-pink-900'

export type TDivideOpacity =
  | 'divide-opacity-0'
  | 'divide-opacity-5'
  | 'divide-opacity-10'
  | 'divide-opacity-20'
  | 'divide-opacity-25'
  | 'divide-opacity-30'
  | 'divide-opacity-40'
  | 'divide-opacity-50'
  | 'divide-opacity-60'
  | 'divide-opacity-70'
  | 'divide-opacity-75'
  | 'divide-opacity-80'
  | 'divide-opacity-90'
  | 'divide-opacity-95'
  | 'divide-opacity-100'

export type TDivideWidth =
  | 'divide-x-0'
  | 'divide-y-0'
  | 'divide-x-2'
  | 'divide-y-2'
  | 'divide-x-4'
  | 'divide-y-4'
  | 'divide-x-8'
  | 'divide-y-8'
  | 'divide-x'
  | 'divide-y'
  | 'divide-x-reverse'
  | 'divide-y-reverse'

export type TRingColor =
  | 'ring-transparent'
  | 'ring-current'
  | 'ring-black'
  | 'ring-white'
  | 'ring-gray'
  | 'ring-red-50'
  | 'ring-red-100'
  | 'ring-red-200'
  | 'ring-red-300'
  | 'ring-red-400'
  | 'ring-red-500'
  | 'ring-red-600'
  | 'ring-red-700'
  | 'ring-red-800'
  | 'ring-red-900'
  | 'ring-yellow-50'
  | 'ring-yellow-100'
  | 'ring-yellow-200'
  | 'ring-yellow-300'
  | 'ring-yellow-400'
  | 'ring-yellow-500'
  | 'ring-yellow-600'
  | 'ring-yellow-700'
  | 'ring-yellow-800'
  | 'ring-yellow-900'
  | 'ring-green-50'
  | 'ring-green-100'
  | 'ring-green-200'
  | 'ring-green-300'
  | 'ring-green-400'
  | 'ring-green-500'
  | 'ring-green-600'
  | 'ring-green-700'
  | 'ring-green-800'
  | 'ring-green-900'
  | 'ring-blue-50'
  | 'ring-blue-100'
  | 'ring-blue-200'
  | 'ring-blue-300'
  | 'ring-blue-400'
  | 'ring-blue-500'
  | 'ring-blue-600'
  | 'ring-blue-700'
  | 'ring-blue-800'
  | 'ring-blue-900'
  | 'ring-indigo-50'
  | 'ring-indigo-100'
  | 'ring-indigo-200'
  | 'ring-indigo-300'
  | 'ring-indigo-400'
  | 'ring-indigo-500'
  | 'ring-indigo-600'
  | 'ring-indigo-700'
  | 'ring-indigo-800'
  | 'ring-indigo-900'
  | 'ring-purple-50'
  | 'ring-purple-100'
  | 'ring-purple-200'
  | 'ring-purple-300'
  | 'ring-purple-400'
  | 'ring-purple-500'
  | 'ring-purple-600'
  | 'ring-purple-700'
  | 'ring-purple-800'
  | 'ring-purple-900'
  | 'ring-pink-50'
  | 'ring-pink-100'
  | 'ring-pink-200'
  | 'ring-pink-300'
  | 'ring-pink-400'
  | 'ring-pink-500'
  | 'ring-pink-600'
  | 'ring-pink-700'
  | 'ring-pink-800'
  | 'ring-pink-900'

export type TRingWidth =
  | 'ring-0'
  | 'ring-1'
  | 'ring-2'
  | 'ring-4'
  | 'ring-8'
  | 'ring'
  | 'inset'

export type TRingOpacity =
  | 'ring-opacity-0'
  | 'ring-opacity-5'
  | 'ring-opacity-10'
  | 'ring-opacity-20'
  | 'ring-opacity-25'
  | 'ring-opacity-30'
  | 'ring-opacity-40'
  | 'ring-opacity-50'
  | 'ring-opacity-60'
  | 'ring-opacity-70'
  | 'ring-opacity-75'
  | 'ring-opacity-80'
  | 'ring-opacity-90'
  | 'ring-opacity-95'
  | 'ring-opacity-100'
  | 'ring-opacity'

export type TRingOffsetColor =
  | 'ring-offset-transparent'
  | 'ring-offset-current'
  | 'ring-offset-black'
  | 'ring-offset-white'
  | 'ring-offset-gray-50'
  | 'ring-offset-gray-100'
  | 'ring-offset-gray-200'
  | 'ring-offset-gray-300'
  | 'ring-offset-gray-400'
  | 'ring-offset-gray-500'
  | 'ring-offset-gray-600'
  | 'ring-offset-gray-700'
  | 'ring-offset-gray-800'
  | 'ring-offset-gray-900'
  | 'ring-offset-red-50'
  | 'ring-offset-red-100'
  | 'ring-offset-red-200'
  | 'ring-offset-red-300'
  | 'ring-offset-red-400'
  | 'ring-offset-red-500'
  | 'ring-offset-red-600'
  | 'ring-offset-red-700'
  | 'ring-offset-red-800'
  | 'ring-offset-red-900'
  | 'ring-offset-yellow-50'
  | 'ring-offset-yellow-100'
  | 'ring-offset-yellow-200'
  | 'ring-offset-yellow-300'
  | 'ring-offset-yellow-400'
  | 'ring-offset-yellow-500'
  | 'ring-offset-yellow-600'
  | 'ring-offset-yellow-700'
  | 'ring-offset-yellow-800'
  | 'ring-offset-yellow-900'
  | 'ring-offset-green-50'
  | 'ring-offset-green-100'
  | 'ring-offset-green-200'
  | 'ring-offset-green-300'
  | 'ring-offset-green-400'
  | 'ring-offset-green-500'
  | 'ring-offset-green-600'
  | 'ring-offset-green-700'
  | 'ring-offset-green-800'
  | 'ring-offset-green-900'
  | 'ring-offset-blue-50'
  | 'ring-offset-blue-100'
  | 'ring-offset-blue-200'
  | 'ring-offset-blue-300'
  | 'ring-offset-blue-400'
  | 'ring-offset-blue-500'
  | 'ring-offset-blue-600'
  | 'ring-offset-blue-700'
  | 'ring-offset-blue-800'
  | 'ring-offset-blue-900'
  | 'ring-offset-indigo-50'
  | 'ring-offset-indigo-100'
  | 'ring-offset-indigo-200'
  | 'ring-offset-indigo-300'
  | 'ring-offset-indigo-400'
  | 'ring-offset-indigo-500'
  | 'ring-offset-indigo-600'
  | 'ring-offset-indigo-700'
  | 'ring-offset-indigo-800'
  | 'ring-offset-indigo-900'
  | 'ring-offset-purple-50'
  | 'ring-offset-purple-100'
  | 'ring-offset-purple-200'
  | 'ring-offset-purple-300'
  | 'ring-offset-purple-400'
  | 'ring-offset-purple-500'
  | 'ring-offset-purple-600'
  | 'ring-offset-purple-700'
  | 'ring-offset-purple-800'
  | 'ring-offset-purple-900'
  | 'ring-offset-pink-50'
  | 'ring-offset-pink-100'
  | 'ring-offset-pink-200'
  | 'ring-offset-pink-300'
  | 'ring-offset-pink-400'
  | 'ring-offset-pink-500'
  | 'ring-offset-pink-600'
  | 'ring-offset-pink-700'
  | 'ring-offset-pink-800'
  | 'ring-offset-pink-900'

export type TRingOffsetWidth =
  | 'ring-offset-0'
  | 'ring-offset-1'
  | 'ring-offset-2'
  | 'ring-offset-4'
  | 'ring-offset-8'

export type TBorders =
  | TBorderStyle
  | TDivideStyle
  | TBorderColor
  | TBorderOpacity
  | TBorderRadius
  | TBorderWidth
  | TDivideColor
  | TDivideOpacity
  | TDivideWidth
  | TRingColor
  | TRingWidth
  | TRingOpacity
  | TRingOffsetColor
  | TRingOffsetWidth

export type TBorderCollapse = 'border-collapse' | 'border-separate'

export type TTableLayout = 'table-auto' | 'table-fixed'

export type TTables = TBorderCollapse | TTableLayout

export type TMixBlendMode =
  | 'mix-blend-normal'
  | 'mix-blend-multiply'
  | 'mix-blend-screen'
  | 'mix-blend-overlay'
  | 'mix-blend-darken'
  | 'mix-blend-lighten'
  | 'mix-blend-color-dodge'
  | 'mix-blend-color-burn'
  | 'mix-blend-hard-light'
  | 'mix-blend-soft-light'
  | 'mix-blend-difference'
  | 'mix-blend-exclusion'
  | 'mix-blend-hue'
  | 'mix-blend-saturation'
  | 'mix-blend-color'
  | 'mix-blend-luminosity'

export type TBackgroundBlendMode =
  | 'bg-blend-normal'
  | 'bg-blend-multiply'
  | 'bg-blend-screen'
  | 'bg-blend-overlay'
  | 'bg-blend-darken'
  | 'bg-blend-lighten'
  | 'bg-blend-color-dodge'
  | 'bg-blend-color-burn'
  | 'bg-blend-hard-light'
  | 'bg-blend-soft-light'
  | 'bg-blend-difference'
  | 'bg-blend-exclusion'
  | 'bg-blend-hue'
  | 'bg-blend-saturation'
  | 'bg-blend-color'
  | 'bg-blend-luminosity'

export type TBoxShadow =
  | 'shadow-sm'
  | 'shadow'
  | 'shadow-md'
  | 'shadow-lg'
  | 'shadow-xl'
  | 'shadow-2xl'
  | 'shadow-inner'
  | 'shadow-none'

export type TOpacity =
  | 'opacity-0'
  | 'opacity-5'
  | 'opacity-10'
  | 'opacity-20'
  | 'opacity-25'
  | 'opacity-30'
  | 'opacity-40'
  | 'opacity-50'
  | 'opacity-60'
  | 'opacity-70'
  | 'opacity-75'
  | 'opacity-80'
  | 'opacity-90'
  | 'opacity-95'
  | 'opacity-100'

export type TEffects =
  | TMixBlendMode
  | TBackgroundBlendMode
  | TBoxShadow
  | TOpacity

export type TTransitionProperty =
  | 'transition-none'
  | 'transition-all'
  | 'transition'
  | 'transition-colors'
  | 'transition-opacity'
  | 'transition-shadow'
  | 'transition-transform'

export type TTransitionDuration =
  | 'duration-75'
  | 'duration-100'
  | 'duration-150'
  | 'duration-200'
  | 'duration-300'
  | 'duration-500'
  | 'duration-700'
  | 'duration-1000'
  | 'duration'

export type TTransitionTimingFunction =
  | 'ease-linear'
  | 'ease-in'
  | 'ease-out'
  | 'ease-in-out'

export type TTransitionDelay =
  | 'delay-75'
  | 'delay-100'
  | 'delay-150'
  | 'delay-200'
  | 'delay-300'
  | 'delay-500'
  | 'delay-700'
  | 'delay-1000'

export type TAnimation =
  | 'animate-none'
  | 'animate-spin'
  | 'animate-ping'
  | 'animate-pulse'
  | 'animate-bounce'

export type TTransitionsAndAnimations =
  | TTransitionProperty
  | TTransitionDuration
  | TTransitionTimingFunction
  | TTransitionDelay
  | TAnimation

export type TFilter = 'filter' | 'filter-none'

export type TBackdropFilter = 'backdrop-filter' | 'backdrop-filter-none'

export type TBlur =
  | 'blur-0'
  | 'blur-none'
  | 'blur-sm'
  | 'blur'
  | 'blur-md'
  | 'blur-lg'
  | 'blur-xl'
  | 'blur-2xl'
  | 'blur-3xl'

export type TBrightness =
  | 'brightness-0'
  | 'brightness-50'
  | 'brightness-75'
  | 'brightness-90'
  | 'brightness-95'
  | 'brightness-100'
  | 'brightness-105'
  | 'brightness-110'
  | 'brightness-125'
  | 'brightness-150'
  | 'brightness-200'

export type TContrast =
  | 'contrast-0'
  | 'contrast-50'
  | 'contrast-75'
  | 'contrast-100'
  | 'contrast-125'
  | 'contrast-150'
  | 'contrast-200'

export type TDropShadow =
  | 'drop-shadow-sm'
  | 'drop-shadow'
  | 'drop-shadow-md'
  | 'drop-shadow-lg'
  | 'drop-shadow-xl'
  | 'drop-shadow-2xl'
  | 'drop-shadow-none'

export type TGrayscale = 'grayscale-0' | 'grayscale'

export type THueRotate =
  | 'hue-rotate-0'
  | 'hue-rotate-15'
  | 'hue-rotate-30'
  | 'hue-rotate-60'
  | 'hue-rotate-90'
  | 'hue-rotate-180'
  | '-hue-rotate-180'
  | '-hue-rotate-90'
  | '-hue-rotate-60'
  | '-hue-rotate-30'
  | '-hue-rotate-15'

export type TInvert = 'invert-0' | 'invert'

export type TSaturate =
  | 'saturate-0'
  | 'saturate-50'
  | 'saturate-100'
  | 'saturate-150'
  | 'saturate-200'

export type TSepia = 'sepia-0' | 'sepia'

export type TBackdropBlur =
  | 'backdrop-blur-0'
  | 'backdrop-blur-none'
  | 'backdrop-blur-sm'
  | 'backdrop-blur'
  | 'backdrop-blur-md'
  | 'backdrop-blur-lg'
  | 'backdrop-blur-xl'
  | 'backdrop-blur-2xl'
  | 'backdrop-blur-3xl'

export type TBackdropBrightness =
  | 'backdrop-brightness-0'
  | 'backdrop-brightness-50'
  | 'backdrop-brightness-75'
  | 'backdrop-brightness-90'
  | 'backdrop-brightness-95'
  | 'backdrop-brightness-100'
  | 'backdrop-brightness-105'
  | 'backdrop-brightness-110'
  | 'backdrop-brightness-125'
  | 'backdrop-brightness-150'
  | 'backdrop-brightness-200'

export type TBackdropContrast =
  | 'backdrop-contrast-0'
  | 'backdrop-contrast-50'
  | 'backdrop-contrast-75'
  | 'backdrop-contrast-100'
  | 'backdrop-contrast-125'
  | 'backdrop-contrast-150'
  | 'backdrop-contrast-200'

export type TBackdropGrayscale = 'backdrop-grayscale-0' | 'backdrop-grayscale'

export type TBackdropHueRotate =
  | 'backdrop-hue-rotate-0'
  | 'backdrop-hue-rotate-15'
  | 'backdrop-hue-rotate-30'
  | 'backdrop-hue-rotate-60'
  | 'backdrop-hue-rotate-90'
  | 'backdrop-hue-rotate-180'
  | '-backdrop-hue-rotate-180'
  | '-backdrop-hue-rotate-90'
  | '-backdrop-hue-rotate-60'
  | '-backdrop-hue-rotate-30'
  | '-backdrop-hue-rotate-15'

export type TBackdropInvert = 'backdrop-invert-0' | 'backdrop-invert'

export type TBackdropOpacity =
  | 'backdrop-opacity-0'
  | 'backdrop-opacity-5'
  | 'backdrop-opacity-10'
  | 'backdrop-opacity-20'
  | 'backdrop-opacity-25'
  | 'backdrop-opacity-30'
  | 'backdrop-opacity-40'
  | 'backdrop-opacity-50'
  | 'backdrop-opacity-60'
  | 'backdrop-opacity-70'
  | 'backdrop-opacity-75'
  | 'backdrop-opacity-80'
  | 'backdrop-opacity-90'
  | 'backdrop-opacity-95'
  | 'backdrop-opacity-100'

export type TBackdropSaturate =
  | 'backdrop-saturate-0'
  | 'backdrop-saturate-50'
  | 'backdrop-saturate-100'
  | 'backdrop-saturate-150'
  | 'backdrop-saturate-200'

export type TBackdropSepia = 'backdrop-sepia-0' | 'backdrop-sepia'

export type TFilters =
  | TFilter
  | TBackdropFilter
  | TBlur
  | TBrightness
  | TContrast
  | TDropShadow
  | TGrayscale
  | THueRotate
  | TInvert
  | TSaturate
  | TSepia
  | TBackdropBlur
  | TBackdropBrightness
  | TBackdropContrast
  | TBackdropGrayscale
  | TBackdropHueRotate
  | TBackdropInvert
  | TBackdropOpacity
  | TBackdropSaturate
  | TBackdropSepia

export type TFlexDirection =
  | 'flex-row'
  | 'flex-row-reverse'
  | 'flex-col'
  | 'flex-col-reverse'

export type TFlexWrap = 'flex-nowrap' | 'flex-wrap' | 'flex-wrap-reverse'

export type TAlignItems =
  | 'items-stretch'
  | 'items-start'
  | 'items-center'
  | 'items-end'
  | 'items-baseline'

export type TAlignContent =
  | 'content-start'
  | 'content-center'
  | 'content-end'
  | 'content-between'
  | 'content-around'

export type TAlignSelf =
  | 'self-auto'
  | 'self-start'
  | 'self-center'
  | 'self-end'
  | 'self-stretch'
  | 'self-baseline'

export type TPlaceContent =
  | 'place-content-start'
  | 'place-content-center'
  | 'place-content-end'
  | 'place-content-between'
  | 'place-content-around'
  | 'place-content-evenly'
  | 'place-content-stretch'

export type TPlaceItems =
  | 'place-items-auto'
  | 'place-items-start'
  | 'place-items-center'
  | 'place-items-end'
  | 'place-items-stretch'

export type TPlaceSelf =
  | 'place-self-auto'
  | 'place-self-start'
  | 'place-self-center'
  | 'place-self-end'
  | 'place-self-stretch'

export type TJustifyContent =
  | 'justify-start'
  | 'justify-center'
  | 'justify-end'
  | 'justify-between'
  | 'justify-around'

export type TJustifyItems =
  | 'justify-items-auto'
  | 'justify-items-start'
  | 'justify-items-center'
  | 'justify-items-end'
  | 'justify-items-stretch'

export type TJustifySelf =
  | 'justify-self-auto'
  | 'justify-self-start'
  | 'justify-self-center'
  | 'justify-self-end'
  | 'justify-self-stretch'

export type TFlex = 'flex-initial' | 'flex-1' | 'flex-auto' | 'flex-none'

export type TFlexGrow = 'flex-grow-0' | 'flex-grow'

export type TFlexShrink = 'flex-shrink-0' | 'flex-shrink'

export type TOrder =
  | 'order-1'
  | 'order-2'
  | 'order-3'
  | 'order-4'
  | 'order-5'
  | 'order-6'
  | 'order-7'
  | 'order-8'
  | 'order-9'
  | 'order-10'
  | 'order-11'
  | 'order-12'
  | 'order-first'
  | 'order-last'
  | 'order-none'

export type TFlexBox =
  | TFlexDirection
  | TFlexWrap
  | TAlignItems
  | TAlignContent
  | TAlignSelf
  | TPlaceContent
  | TPlaceItems
  | TPlaceSelf
  | TJustifyContent
  | TJustifyItems
  | TJustifySelf
  | TFlex
  | TFlexGrow
  | TFlexShrink
  | TOrder

export type TGridAutoFlow =
  | 'grid-flow-row'
  | 'grid-flow-col'
  | 'grid-flow-row-dense'
  | 'grid-flow-col-dense'

export type TGridTemplateColumns =
  | 'grid-cols-1'
  | 'grid-cols-2'
  | 'grid-cols-3'
  | 'grid-cols-4'
  | 'grid-cols-5'
  | 'grid-cols-6'
  | 'grid-cols-7'
  | 'grid-cols-8'
  | 'grid-cols-9'
  | 'grid-cols-10'
  | 'grid-cols-11'
  | 'grid-cols-12'
  | 'grid-cols-none'

export type TGridAutoColumns =
  | 'auto-cols-auto'
  | 'auto-cols-min'
  | 'auto-cols-max'
  | 'auto-cols-fr'

export type TGridColumn =
  | 'col-auto'
  | 'col-span-1'
  | 'col-span-2'
  | 'col-span-3'
  | 'col-span-4'
  | 'col-span-5'
  | 'col-span-6'
  | 'col-span-7'
  | 'col-span-8'
  | 'col-span-9'
  | 'col-span-10'
  | 'col-span-11'
  | 'col-span-12'
  | 'col-span-full'

export type TGridColumnStart =
  | 'col-start-1'
  | 'col-start-2'
  | 'col-start-3'
  | 'col-start-4'
  | 'col-start-5'
  | 'col-start-6'
  | 'col-start-7'
  | 'col-start-8'
  | 'col-start-9'
  | 'col-start-10'
  | 'col-start-11'
  | 'col-start-12'
  | 'col-start-13'
  | 'col-start-auto'

export type TGridColumnEnd =
  | 'col-end-1'
  | 'col-end-2'
  | 'col-end-3'
  | 'col-end-4'
  | 'col-end-5'
  | 'col-end-6'
  | 'col-end-7'
  | 'col-end-8'
  | 'col-end-9'
  | 'col-end-10'
  | 'col-end-11'
  | 'col-end-12'
  | 'col-end-13'
  | 'col-end-auto'

export type TGridTemplateRows =
  | 'grid-rows-1'
  | 'grid-rows-2'
  | 'grid-rows-3'
  | 'grid-rows-4'
  | 'grid-rows-5'
  | 'grid-rows-6'
  | 'grid-rows-none'

export type TGridAutoRows =
  | 'auto-rows-auto'
  | 'auto-rows-min'
  | 'auto-rows-max'
  | 'auto-rows-fr'

export type TGridRow =
  | 'row-auto'
  | 'row-span-1'
  | 'row-span-2'
  | 'row-span-3'
  | 'row-span-4'
  | 'row-span-5'
  | 'row-span-6'
  | 'row-span-full'

export type TGridRowStart =
  | 'row-start-1'
  | 'row-start-2'
  | 'row-start-3'
  | 'row-start-4'
  | 'row-start-5'
  | 'row-start-6'
  | 'row-start-7'
  | 'row-start-auto'

export type TGridRowEnd =
  | 'row-end-1'
  | 'row-end-2'
  | 'row-end-3'
  | 'row-end-4'
  | 'row-end-5'
  | 'row-end-6'
  | 'row-end-7'
  | 'row-end-auto'

export type TGap =
  | 'gap-0'
  | 'gap-1'
  | 'gap-2'
  | 'gap-3'
  | 'gap-4'
  | 'gap-5'
  | 'gap-6'
  | 'gap-7'
  | 'gap-8'
  | 'gap-9'
  | 'gap-10'
  | 'gap-11'
  | 'gap-12'
  | 'gap-14'
  | 'gap-16'
  | 'gap-20'
  | 'gap-24'
  | 'gap-28'
  | 'gap-32'
  | 'gap-36'
  | 'gap-40'
  | 'gap-44'
  | 'gap-48'
  | 'gap-52'
  | 'gap-56'
  | 'gap-60'
  | 'gap-64'
  | 'gap-72'
  | 'gap-80'
  | 'gap-96'
  | 'gap-px'
  | 'gap-0.5'
  | 'gap-1.5'
  | 'gap-2.5'
  | 'gap-3.5'
  | 'gap-y-0'
  | 'gap-y-1'
  | 'gap-y-2'
  | 'gap-y-3'
  | 'gap-y-4'
  | 'gap-y-5'
  | 'gap-y-6'
  | 'gap-y-7'
  | 'gap-y-8'
  | 'gap-y-9'
  | 'gap-y-10'
  | 'gap-y-11'
  | 'gap-y-12'
  | 'gap-y-14'
  | 'gap-y-16'
  | 'gap-y-20'
  | 'gap-y-24'
  | 'gap-y-28'
  | 'gap-y-32'
  | 'gap-y-36'
  | 'gap-y-40'
  | 'gap-y-44'
  | 'gap-y-48'
  | 'gap-y-52'
  | 'gap-y-56'
  | 'gap-y-60'
  | 'gap-y-64'
  | 'gap-y-72'
  | 'gap-y-80'
  | 'gap-y-96'
  | 'gap-y-px'
  | 'gap-y-0.5'
  | 'gap-y-1.5'
  | 'gap-y-2.5'
  | 'gap-y-3.5'
  | 'gap-x-0'
  | 'gap-x-1'
  | 'gap-x-2'
  | 'gap-x-3'
  | 'gap-x-4'
  | 'gap-x-5'
  | 'gap-x-6'
  | 'gap-x-7'
  | 'gap-x-8'
  | 'gap-x-9'
  | 'gap-x-10'
  | 'gap-x-11'
  | 'gap-x-12'
  | 'gap-x-14'
  | 'gap-x-16'
  | 'gap-x-20'
  | 'gap-x-24'
  | 'gap-x-28'
  | 'gap-x-32'
  | 'gap-x-36'
  | 'gap-x-40'
  | 'gap-x-44'
  | 'gap-x-48'
  | 'gap-x-52'
  | 'gap-x-56'
  | 'gap-x-60'
  | 'gap-x-64'
  | 'gap-x-72'
  | 'gap-x-80'
  | 'gap-x-96'
  | 'gap-x-px'
  | 'gap-x-0.5'
  | 'gap-x-1.5'
  | 'gap-x-2.5'
  | 'gap-x-3.5'

export type TGrid =
  | TGridAutoFlow
  | TGridTemplateColumns
  | TGridAutoColumns
  | TGridColumn
  | TGridColumnStart
  | TGridColumnEnd
  | TGridTemplateRows
  | TGridAutoRows
  | TGridRow
  | TGridRowStart
  | TGridRowEnd
  | TGap

export type TPadding =
  | 'p-0'
  | 'p-1'
  | 'p-2'
  | 'p-3'
  | 'p-4'
  | 'p-5'
  | 'p-6'
  | 'p-7'
  | 'p-8'
  | 'p-9'
  | 'p-10'
  | 'p-11'
  | 'p-12'
  | 'p-14'
  | 'p-16'
  | 'p-20'
  | 'p-24'
  | 'p-28'
  | 'p-32'
  | 'p-36'
  | 'p-40'
  | 'p-44'
  | 'p-48'
  | 'p-52'
  | 'p-56'
  | 'p-60'
  | 'p-64'
  | 'p-72'
  | 'p-80'
  | 'p-96'
  | 'p-px'
  | 'p-0.5'
  | 'p-1.5'
  | 'p-2.5'
  | 'p-3.5'
  | 'py-0'
  | 'py-1'
  | 'py-2'
  | 'py-3'
  | 'py-4'
  | 'py-5'
  | 'py-6'
  | 'py-7'
  | 'py-8'
  | 'py-9'
  | 'py-10'
  | 'py-11'
  | 'py-12'
  | 'py-14'
  | 'py-16'
  | 'py-20'
  | 'py-24'
  | 'py-28'
  | 'py-32'
  | 'py-36'
  | 'py-40'
  | 'py-44'
  | 'py-48'
  | 'py-52'
  | 'py-56'
  | 'py-60'
  | 'py-64'
  | 'py-72'
  | 'py-80'
  | 'py-96'
  | 'py-px'
  | 'py-0.5'
  | 'py-1.5'
  | 'py-2.5'
  | 'py-3.5'
  | 'px-0'
  | 'px-1'
  | 'px-2'
  | 'px-3'
  | 'px-4'
  | 'px-5'
  | 'px-6'
  | 'px-7'
  | 'px-8'
  | 'px-9'
  | 'px-10'
  | 'px-11'
  | 'px-12'
  | 'px-14'
  | 'px-16'
  | 'px-20'
  | 'px-24'
  | 'px-28'
  | 'px-32'
  | 'px-36'
  | 'px-40'
  | 'px-44'
  | 'px-48'
  | 'px-52'
  | 'px-56'
  | 'px-60'
  | 'px-64'
  | 'px-72'
  | 'px-80'
  | 'px-96'
  | 'px-px'
  | 'px-0.5'
  | 'px-1.5'
  | 'px-2.5'
  | 'px-3.5'
  | 'pt-0'
  | 'pt-1'
  | 'pt-2'
  | 'pt-3'
  | 'pt-4'
  | 'pt-5'
  | 'pt-6'
  | 'pt-7'
  | 'pt-8'
  | 'pt-9'
  | 'pt-10'
  | 'pt-11'
  | 'pt-12'
  | 'pt-14'
  | 'pt-16'
  | 'pt-20'
  | 'pt-24'
  | 'pt-28'
  | 'pt-32'
  | 'pt-36'
  | 'pt-40'
  | 'pt-44'
  | 'pt-48'
  | 'pt-52'
  | 'pt-56'
  | 'pt-60'
  | 'pt-64'
  | 'pt-72'
  | 'pt-80'
  | 'pt-96'
  | 'pt-px'
  | 'pt-0.5'
  | 'pt-1.5'
  | 'pt-2.5'
  | 'pt-3.5'
  | 'pr-0'
  | 'pr-1'
  | 'pr-2'
  | 'pr-3'
  | 'pr-4'
  | 'pr-5'
  | 'pr-6'
  | 'pr-7'
  | 'pr-8'
  | 'pr-9'
  | 'pr-10'
  | 'pr-11'
  | 'pr-12'
  | 'pr-14'
  | 'pr-16'
  | 'pr-20'
  | 'pr-24'
  | 'pr-28'
  | 'pr-32'
  | 'pr-36'
  | 'pr-40'
  | 'pr-44'
  | 'pr-48'
  | 'pr-52'
  | 'pr-56'
  | 'pr-60'
  | 'pr-64'
  | 'pr-72'
  | 'pr-80'
  | 'pr-96'
  | 'pr-px'
  | 'pr-0.5'
  | 'pr-1.5'
  | 'pr-2.5'
  | 'pr-3.5'
  | 'pb-0'
  | 'pb-1'
  | 'pb-2'
  | 'pb-3'
  | 'pb-4'
  | 'pb-5'
  | 'pb-6'
  | 'pb-7'
  | 'pb-8'
  | 'pb-9'
  | 'pb-10'
  | 'pb-11'
  | 'pb-12'
  | 'pb-14'
  | 'pb-16'
  | 'pb-20'
  | 'pb-24'
  | 'pb-28'
  | 'pb-32'
  | 'pb-36'
  | 'pb-40'
  | 'pb-44'
  | 'pb-48'
  | 'pb-52'
  | 'pb-56'
  | 'pb-60'
  | 'pb-64'
  | 'pb-72'
  | 'pb-80'
  | 'pb-96'
  | 'pb-px'
  | 'pb-0.5'
  | 'pb-1.5'
  | 'pb-2.5'
  | 'pb-3.5'
  | 'pl-0'
  | 'pl-1'
  | 'pl-2'
  | 'pl-3'
  | 'pl-4'
  | 'pl-5'
  | 'pl-6'
  | 'pl-7'
  | 'pl-8'
  | 'pl-9'
  | 'pl-10'
  | 'pl-11'
  | 'pl-12'
  | 'pl-14'
  | 'pl-16'
  | 'pl-20'
  | 'pl-24'
  | 'pl-28'
  | 'pl-32'
  | 'pl-36'
  | 'pl-40'
  | 'pl-44'
  | 'pl-48'
  | 'pl-52'
  | 'pl-56'
  | 'pl-60'
  | 'pl-64'
  | 'pl-72'
  | 'pl-80'
  | 'pl-96'
  | 'pl-px'
  | 'pl-0.5'
  | 'pl-1.5'
  | 'pl-2.5'
  | 'pl-3.5'

export type TMargin =
  | 'm-0'
  | 'm-1'
  | 'm-2'
  | 'm-3'
  | 'm-4'
  | 'm-5'
  | 'm-6'
  | 'm-7'
  | 'm-8'
  | 'm-9'
  | 'm-10'
  | 'm-11'
  | 'm-12'
  | 'm-14'
  | 'm-16'
  | 'm-20'
  | 'm-24'
  | 'm-28'
  | 'm-32'
  | 'm-36'
  | 'm-40'
  | 'm-44'
  | 'm-48'
  | 'm-52'
  | 'm-56'
  | 'm-60'
  | 'm-64'
  | 'm-72'
  | 'm-80'
  | 'm-96'
  | 'm-auto'
  | 'm-px'
  | 'm-0.5'
  | 'm-1.5'
  | 'm-2.5'
  | 'm-3.5'
  | '-m-0'
  | '-m-1'
  | '-m-2'
  | '-m-3'
  | '-m-4'
  | '-m-5'
  | '-m-6'
  | '-m-7'
  | '-m-8'
  | '-m-9'
  | '-m-10'
  | '-m-11'
  | '-m-12'
  | '-m-14'
  | '-m-16'
  | '-m-20'
  | '-m-24'
  | '-m-28'
  | '-m-32'
  | '-m-36'
  | '-m-40'
  | '-m-44'
  | '-m-48'
  | '-m-52'
  | '-m-56'
  | '-m-60'
  | '-m-64'
  | '-m-72'
  | '-m-80'
  | '-m-96'
  | '-m-px'
  | '-m-0.5'
  | '-m-1.5'
  | '-m-2.5'
  | '-m-3.5'
  | 'my-0'
  | 'my-1'
  | 'my-2'
  | 'my-3'
  | 'my-4'
  | 'my-5'
  | 'my-6'
  | 'my-7'
  | 'my-8'
  | 'my-9'
  | 'my-10'
  | 'my-11'
  | 'my-12'
  | 'my-14'
  | 'my-16'
  | 'my-20'
  | 'my-24'
  | 'my-28'
  | 'my-32'
  | 'my-36'
  | 'my-40'
  | 'my-44'
  | 'my-48'
  | 'my-52'
  | 'my-56'
  | 'my-60'
  | 'my-64'
  | 'my-72'
  | 'my-80'
  | 'my-96'
  | 'my-auto'
  | 'my-px'
  | 'my-0.5'
  | 'my-1.5'
  | 'my-2.5'
  | 'my-3.5'
  | '-my-0'
  | '-my-1'
  | '-my-2'
  | '-my-3'
  | '-my-4'
  | '-my-5'
  | '-my-6'
  | '-my-7'
  | '-my-8'
  | '-my-9'
  | '-my-10'
  | '-my-11'
  | '-my-12'
  | '-my-14'
  | '-my-16'
  | '-my-20'
  | '-my-24'
  | '-my-28'
  | '-my-32'
  | '-my-36'
  | '-my-40'
  | '-my-44'
  | '-my-48'
  | '-my-52'
  | '-my-56'
  | '-my-60'
  | '-my-64'
  | '-my-72'
  | '-my-80'
  | '-my-96'
  | '-my-px'
  | '-my-0.5'
  | '-my-1.5'
  | '-my-2.5'
  | '-my-3.5'
  | 'mx-0'
  | 'mx-1'
  | 'mx-2'
  | 'mx-3'
  | 'mx-4'
  | 'mx-5'
  | 'mx-6'
  | 'mx-7'
  | 'mx-8'
  | 'mx-9'
  | 'mx-10'
  | 'mx-11'
  | 'mx-12'
  | 'mx-14'
  | 'mx-16'
  | 'mx-20'
  | 'mx-24'
  | 'mx-28'
  | 'mx-32'
  | 'mx-36'
  | 'mx-40'
  | 'mx-44'
  | 'mx-48'
  | 'mx-52'
  | 'mx-56'
  | 'mx-60'
  | 'mx-64'
  | 'mx-72'
  | 'mx-80'
  | 'mx-96'
  | 'mx-auto'
  | 'mx-px'
  | 'mx-0.5'
  | 'mx-1.5'
  | 'mx-2.5'
  | 'mx-3.5'
  | '-mx-0'
  | '-mx-1'
  | '-mx-2'
  | '-mx-3'
  | '-mx-4'
  | '-mx-5'
  | '-mx-6'
  | '-mx-7'
  | '-mx-8'
  | '-mx-9'
  | '-mx-10'
  | '-mx-11'
  | '-mx-12'
  | '-mx-14'
  | '-mx-16'
  | '-mx-20'
  | '-mx-24'
  | '-mx-28'
  | '-mx-32'
  | '-mx-36'
  | '-mx-40'
  | '-mx-44'
  | '-mx-48'
  | '-mx-52'
  | '-mx-56'
  | '-mx-60'
  | '-mx-64'
  | '-mx-72'
  | '-mx-80'
  | '-mx-96'
  | '-mx-px'
  | '-mx-0.5'
  | '-mx-1.5'
  | '-mx-2.5'
  | '-mx-3.5'
  | 'mt-0'
  | 'mt-1'
  | 'mt-2'
  | 'mt-3'
  | 'mt-4'
  | 'mt-5'
  | 'mt-6'
  | 'mt-7'
  | 'mt-8'
  | 'mt-9'
  | 'mt-10'
  | 'mt-11'
  | 'mt-12'
  | 'mt-14'
  | 'mt-16'
  | 'mt-20'
  | 'mt-24'
  | 'mt-28'
  | 'mt-32'
  | 'mt-36'
  | 'mt-40'
  | 'mt-44'
  | 'mt-48'
  | 'mt-52'
  | 'mt-56'
  | 'mt-60'
  | 'mt-64'
  | 'mt-72'
  | 'mt-80'
  | 'mt-96'
  | 'mt-auto'
  | 'mt-px'
  | 'mt-0.5'
  | 'mt-1.5'
  | 'mt-2.5'
  | 'mt-3.5'
  | '-mt-0'
  | '-mt-1'
  | '-mt-2'
  | '-mt-3'
  | '-mt-4'
  | '-mt-5'
  | '-mt-6'
  | '-mt-7'
  | '-mt-8'
  | '-mt-9'
  | '-mt-10'
  | '-mt-11'
  | '-mt-12'
  | '-mt-14'
  | '-mt-16'
  | '-mt-20'
  | '-mt-24'
  | '-mt-28'
  | '-mt-32'
  | '-mt-36'
  | '-mt-40'
  | '-mt-44'
  | '-mt-48'
  | '-mt-52'
  | '-mt-56'
  | '-mt-60'
  | '-mt-64'
  | '-mt-72'
  | '-mt-80'
  | '-mt-96'
  | '-mt-px'
  | '-mt-0.5'
  | '-mt-1.5'
  | '-mt-2.5'
  | '-mt-3.5'
  | 'mr-0'
  | 'mr-1'
  | 'mr-2'
  | 'mr-3'
  | 'mr-4'
  | 'mr-5'
  | 'mr-6'
  | 'mr-7'
  | 'mr-8'
  | 'mr-9'
  | 'mr-10'
  | 'mr-11'
  | 'mr-12'
  | 'mr-14'
  | 'mr-16'
  | 'mr-20'
  | 'mr-24'
  | 'mr-28'
  | 'mr-32'
  | 'mr-36'
  | 'mr-40'
  | 'mr-44'
  | 'mr-48'
  | 'mr-52'
  | 'mr-56'
  | 'mr-60'
  | 'mr-64'
  | 'mr-72'
  | 'mr-80'
  | 'mr-96'
  | 'mr-auto'
  | 'mr-px'
  | 'mr-0.5'
  | 'mr-1.5'
  | 'mr-2.5'
  | 'mr-3.5'
  | '-mr-0'
  | '-mr-1'
  | '-mr-2'
  | '-mr-3'
  | '-mr-4'
  | '-mr-5'
  | '-mr-6'
  | '-mr-7'
  | '-mr-8'
  | '-mr-9'
  | '-mr-10'
  | '-mr-11'
  | '-mr-12'
  | '-mr-14'
  | '-mr-16'
  | '-mr-20'
  | '-mr-24'
  | '-mr-28'
  | '-mr-32'
  | '-mr-36'
  | '-mr-40'
  | '-mr-44'
  | '-mr-48'
  | '-mr-52'
  | '-mr-56'
  | '-mr-60'
  | '-mr-64'
  | '-mr-72'
  | '-mr-80'
  | '-mr-96'
  | '-mr-px'
  | '-mr-0.5'
  | '-mr-1.5'
  | '-mr-2.5'
  | '-mr-3.5'
  | 'mb-0'
  | 'mb-1'
  | 'mb-2'
  | 'mb-3'
  | 'mb-4'
  | 'mb-5'
  | 'mb-6'
  | 'mb-7'
  | 'mb-8'
  | 'mb-9'
  | 'mb-10'
  | 'mb-11'
  | 'mb-12'
  | 'mb-14'
  | 'mb-16'
  | 'mb-20'
  | 'mb-24'
  | 'mb-28'
  | 'mb-32'
  | 'mb-36'
  | 'mb-40'
  | 'mb-44'
  | 'mb-48'
  | 'mb-52'
  | 'mb-56'
  | 'mb-60'
  | 'mb-64'
  | 'mb-72'
  | 'mb-80'
  | 'mb-96'
  | 'mb-auto'
  | 'mb-px'
  | 'mb-0.5'
  | 'mb-1.5'
  | 'mb-2.5'
  | 'mb-3.5'
  | '-mb-0'
  | '-mb-1'
  | '-mb-2'
  | '-mb-3'
  | '-mb-4'
  | '-mb-5'
  | '-mb-6'
  | '-mb-7'
  | '-mb-8'
  | '-mb-9'
  | '-mb-10'
  | '-mb-11'
  | '-mb-12'
  | '-mb-14'
  | '-mb-16'
  | '-mb-20'
  | '-mb-24'
  | '-mb-28'
  | '-mb-32'
  | '-mb-36'
  | '-mb-40'
  | '-mb-44'
  | '-mb-48'
  | '-mb-52'
  | '-mb-56'
  | '-mb-60'
  | '-mb-64'
  | '-mb-72'
  | '-mb-80'
  | '-mb-96'
  | '-mb-px'
  | '-mb-0.5'
  | '-mb-1.5'
  | '-mb-2.5'
  | '-mb-3.5'
  | 'ml-0'
  | 'ml-1'
  | 'ml-2'
  | 'ml-3'
  | 'ml-4'
  | 'ml-5'
  | 'ml-6'
  | 'ml-7'
  | 'ml-8'
  | 'ml-9'
  | 'ml-10'
  | 'ml-11'
  | 'ml-12'
  | 'ml-14'
  | 'ml-16'
  | 'ml-20'
  | 'ml-24'
  | 'ml-28'
  | 'ml-32'
  | 'ml-36'
  | 'ml-40'
  | 'ml-44'
  | 'ml-48'
  | 'ml-52'
  | 'ml-56'
  | 'ml-60'
  | 'ml-64'
  | 'ml-72'
  | 'ml-80'
  | 'ml-96'
  | 'ml-auto'
  | 'ml-px'
  | 'ml-0.5'
  | 'ml-1.5'
  | 'ml-2.5'
  | 'ml-3.5'
  | '-ml-0'
  | '-ml-1'
  | '-ml-2'
  | '-ml-3'
  | '-ml-4'
  | '-ml-5'
  | '-ml-6'
  | '-ml-7'
  | '-ml-8'
  | '-ml-9'
  | '-ml-10'
  | '-ml-11'
  | '-ml-12'
  | '-ml-14'
  | '-ml-16'
  | '-ml-20'
  | '-ml-24'
  | '-ml-28'
  | '-ml-32'
  | '-ml-36'
  | '-ml-40'
  | '-ml-44'
  | '-ml-48'
  | '-ml-52'
  | '-ml-56'
  | '-ml-60'
  | '-ml-64'
  | '-ml-72'
  | '-ml-80'
  | '-ml-96'
  | '-ml-px'
  | '-ml-0.5'
  | '-ml-1.5'
  | '-ml-2.5'
  | '-ml-3.5'

export type TSpace =
  | 'space-x-0'
  | 'space-x-1'
  | 'space-x-2'
  | 'space-x-3'
  | 'space-x-4'
  | 'space-x-5'
  | 'space-x-6'
  | 'space-x-7'
  | 'space-x-8'
  | 'space-x-9'
  | 'space-x-10'
  | 'space-x-11'
  | 'space-x-12'
  | 'space-x-14'
  | 'space-x-16'
  | 'space-x-20'
  | 'space-x-24'
  | 'space-x-28'
  | 'space-x-32'
  | 'space-x-36'
  | 'space-x-40'
  | 'space-x-44'
  | 'space-x-48'
  | 'space-x-52'
  | 'space-x-56'
  | 'space-x-60'
  | 'space-x-64'
  | 'space-x-72'
  | 'space-x-80'
  | 'space-x-96'
  | 'space-x-px'
  | 'space-x-0.5'
  | 'space-x-1.5'
  | 'space-x-2.5'
  | 'space-x-3.5'
  | '-space-x-0'
  | '-space-x-1'
  | '-space-x-2'
  | '-space-x-3'
  | '-space-x-4'
  | '-space-x-5'
  | '-space-x-6'
  | '-space-x-7'
  | '-space-x-8'
  | '-space-x-9'
  | '-space-x-10'
  | '-space-x-11'
  | '-space-x-12'
  | '-space-x-14'
  | '-space-x-16'
  | '-space-x-20'
  | '-space-x-24'
  | '-space-x-28'
  | '-space-x-32'
  | '-space-x-36'
  | '-space-x-40'
  | '-space-x-44'
  | '-space-x-48'
  | '-space-x-52'
  | '-space-x-56'
  | '-space-x-60'
  | '-space-x-64'
  | '-space-x-72'
  | '-space-x-80'
  | '-space-x-96'
  | '-space-x-px'
  | '-space-x-0.5'
  | '-space-x-1.5'
  | '-space-x-2.5'
  | '-space-x-3.5'
  | 'space-x-reverse'
  | 'space-y-0'
  | 'space-y-1'
  | 'space-y-2'
  | 'space-y-3'
  | 'space-y-4'
  | 'space-y-5'
  | 'space-y-6'
  | 'space-y-7'
  | 'space-y-8'
  | 'space-y-9'
  | 'space-y-10'
  | 'space-y-11'
  | 'space-y-12'
  | 'space-y-14'
  | 'space-y-16'
  | 'space-y-20'
  | 'space-y-24'
  | 'space-y-28'
  | 'space-y-32'
  | 'space-y-36'
  | 'space-y-40'
  | 'space-y-44'
  | 'space-y-48'
  | 'space-y-52'
  | 'space-y-56'
  | 'space-y-60'
  | 'space-y-64'
  | 'space-y-72'
  | 'space-y-80'
  | 'space-y-96'
  | 'space-y-px'
  | 'space-y-0.5'
  | 'space-y-1.5'
  | 'space-y-2.5'
  | 'space-y-3.5'
  | '-space-y-0'
  | '-space-y-1'
  | '-space-y-2'
  | '-space-y-3'
  | '-space-y-4'
  | '-space-y-5'
  | '-space-y-6'
  | '-space-y-7'
  | '-space-y-8'
  | '-space-y-9'
  | '-space-y-10'
  | '-space-y-11'
  | '-space-y-12'
  | '-space-y-14'
  | '-space-y-16'
  | '-space-y-20'
  | '-space-y-24'
  | '-space-y-28'
  | '-space-y-32'
  | '-space-y-36'
  | '-space-y-40'
  | '-space-y-44'
  | '-space-y-48'
  | '-space-y-52'
  | '-space-y-56'
  | '-space-y-60'
  | '-space-y-64'
  | '-space-y-72'
  | '-space-y-80'
  | '-space-y-96'
  | '-space-y-px'
  | '-space-y-0.5'
  | '-space-y-1.5'
  | '-space-y-2.5'
  | '-space-y-3.5'
  | 'space-y-reverse'

export type TSpacing = TPadding | TMargin | TSpace

export type TAppearance = 'appearance-none'

export type TPointerEvents = 'pointer-events-none' | 'pointer-events-auto'

export type TResize = 'resize-none' | 'resize' | 'resize-y' | 'resize-x'

export type TUserSelect =
  | 'select-none'
  | 'select-text'
  | 'select-all'
  | 'select-auto'

export type TCursor =
  | 'cursor-auto'
  | 'cursor-default'
  | 'cursor-pointer'
  | 'cursor-wait'
  | 'cursor-text'
  | 'cursor-move'
  | 'cursor-help'
  | 'cursor-not-allowed'

export type TOutline = 'outline-none' | 'outline-white' | 'outline-black'

export type TInteractivity =
  | TAppearance
  | TPointerEvents
  | TResize
  | TUserSelect
  | TCursor
  | TOutline

export type TDisplay =
  | 'block'
  | 'inline-block'
  | 'inline'
  | 'flex'
  | 'inline-flex'
  | 'grid'
  | 'inline-grid'
  | 'table'
  | 'inline-table'
  | 'table-row'
  | 'table-cell'
  | 'contents'
  | 'list-item'
  | 'hidden'

export type TBoxDecorationBreak = 'decoration-slice' | 'decoration-clone'

export type TBoxSizing = 'box-border' | 'box-content'

export type TContainer = 'container'

export type TFloat = 'float-right' | 'float-left' | 'float-none'

export type TClear = 'clear-left' | 'clear-right' | 'clear-both' | 'clear-none'

export type TIsolation = 'isolate' | 'isolation-auto'

export type TObjectFit =
  | 'object-contain'
  | 'object-cover'
  | 'object-fill'
  | 'object-none'
  | 'object-scale-down'

export type TOverflow =
  | 'overflow-auto'
  | 'overflow-hidden'
  | 'overflow-visible'
  | 'overflow-scroll'
  | 'overflow-x-auto'
  | 'overflow-y-auto'
  | 'overflow-x-hidden'
  | 'overflow-y-hidden'
  | 'overflow-x-visible'
  | 'overflow-y-visible'
  | 'overflow-x-scroll'
  | 'overflow-y-scroll'

export type TOverscrollBehavior =
  | 'overscroll-auto'
  | 'overscroll-contain'
  | 'overscroll-none'
  | 'overscroll-y-auto'
  | 'overscroll-y-contain'
  | 'overscroll-y-none'
  | 'overscroll-x-auto'
  | 'overscroll-x-contain'
  | 'overscroll-x-none'

export type TPosition = 'static' | 'fixed' | 'absolute' | 'relative' | 'sticky'

export type TVisibility = 'visible' | 'invisible'

export type TObjectPosition =
  | 'object-bottom'
  | 'object-center'
  | 'object-left'
  | 'object-left-bottom'
  | 'object-left-top'
  | 'object-right'
  | 'object-right-bottom'
  | 'object-right-top'
  | 'object-top'

export type TInset =
  | 'inset-0'
  | 'inset-x-0'
  | 'inset-y-0'
  | 'top-0'
  | 'right-0'
  | 'bottom-0'
  | 'left-0'
  | 'inset-1'
  | 'inset-x-1'
  | 'inset-y-1'
  | 'top-1'
  | 'right-1'
  | 'bottom-1'
  | 'left-1'
  | 'inset-2'
  | 'inset-x-2'
  | 'inset-y-2'
  | 'top-2'
  | 'right-2'
  | 'bottom-2'
  | 'left-2'
  | 'inset-3'
  | 'inset-x-3'
  | 'inset-y-3'
  | 'top-3'
  | 'right-3'
  | 'bottom-3'
  | 'left-3'
  | 'inset-4'
  | 'inset-x-4'
  | 'inset-y-4'
  | 'top-4'
  | 'right-4'
  | 'bottom-4'
  | 'left-4'
  | 'inset-5'
  | 'inset-x-5'
  | 'inset-y-5'
  | 'top-5'
  | 'right-5'
  | 'bottom-5'
  | 'left-5'
  | 'inset-6'
  | 'inset-x-6'
  | 'inset-y-6'
  | 'top-6'
  | 'right-6'
  | 'bottom-6'
  | 'left-6'
  | 'inset-7'
  | 'inset-x-7'
  | 'inset-y-7'
  | 'top-7'
  | 'right-7'
  | 'bottom-7'
  | 'left-7'
  | 'inset-8'
  | 'inset-x-8'
  | 'inset-y-8'
  | 'top-8'
  | 'right-8'
  | 'bottom-8'
  | 'left-8'
  | 'inset-9'
  | 'inset-x-9'
  | 'inset-y-9'
  | 'top-9'
  | 'right-9'
  | 'bottom-9'
  | 'left-9'
  | 'inset-10'
  | 'inset-x-10'
  | 'inset-y-10'
  | 'top-10'
  | 'right-10'
  | 'bottom-10'
  | 'left-10'
  | 'inset-11'
  | 'inset-x-11'
  | 'inset-y-11'
  | 'top-11'
  | 'right-11'
  | 'bottom-11'
  | 'left-11'
  | 'inset-12'
  | 'inset-x-12'
  | 'inset-y-12'
  | 'top-12'
  | 'right-12'
  | 'bottom-12'
  | 'left-12'
  | 'inset-14'
  | 'inset-x-14'
  | 'inset-y-14'
  | 'top-14'
  | 'right-14'
  | 'bottom-14'
  | 'left-14'
  | 'inset-16'
  | 'inset-x-16'
  | 'inset-y-16'
  | 'top-16'
  | 'right-16'
  | 'bottom-16'
  | 'left-16'
  | 'inset-20'
  | 'inset-x-20'
  | 'inset-y-20'
  | 'top-20'
  | 'right-20'
  | 'bottom-20'
  | 'left-20'
  | 'inset-24'
  | 'inset-x-24'
  | 'inset-y-24'
  | 'top-24'
  | 'right-24'
  | 'bottom-24'
  | 'left-24'
  | 'inset-28'
  | 'inset-x-28'
  | 'inset-y-28'
  | 'top-28'
  | 'right-28'
  | 'bottom-28'
  | 'left-28'
  | 'inset-32'
  | 'inset-x-32'
  | 'inset-y-32'
  | 'top-32'
  | 'right-32'
  | 'bottom-32'
  | 'left-32'
  | 'inset-36'
  | 'inset-x-36'
  | 'inset-y-36'
  | 'top-36'
  | 'right-36'
  | 'bottom-36'
  | 'left-36'
  | 'inset-40'
  | 'inset-x-40'
  | 'inset-y-40'
  | 'top-40'
  | 'right-40'
  | 'bottom-40'
  | 'left-40'
  | 'inset-44'
  | 'inset-x-44'
  | 'inset-y-44'
  | 'top-44'
  | 'right-44'
  | 'bottom-44'
  | 'left-44'
  | 'inset-48'
  | 'inset-x-48'
  | 'inset-y-48'
  | 'top-48'
  | 'right-48'
  | 'bottom-48'
  | 'left-48'
  | 'inset-52'
  | 'inset-x-52'
  | 'inset-y-52'
  | 'top-52'
  | 'right-52'
  | 'bottom-52'
  | 'left-52'
  | 'inset-56'
  | 'inset-x-56'
  | 'inset-y-56'
  | 'top-56'
  | 'right-56'
  | 'bottom-56'
  | 'left-56'
  | 'inset-60'
  | 'inset-x-60'
  | 'inset-y-60'
  | 'top-60'
  | 'right-60'
  | 'bottom-60'
  | 'left-60'
  | 'inset-64'
  | 'inset-x-64'
  | 'inset-y-64'
  | 'top-64'
  | 'right-64'
  | 'bottom-64'
  | 'left-64'
  | 'inset-72'
  | 'inset-x-72'
  | 'inset-y-72'
  | 'top-72'
  | 'right-72'
  | 'bottom-72'
  | 'left-72'
  | 'inset-80'
  | 'inset-x-80'
  | 'inset-y-80'
  | 'top-80'
  | 'right-80'
  | 'bottom-80'
  | 'left-80'
  | 'inset-96'
  | 'inset-x-96'
  | 'inset-y-96'
  | 'top-96'
  | 'right-96'
  | 'bottom-96'
  | 'left-96'
  | 'inset-auto'
  | 'inset-x-auto'
  | 'inset-y-auto'
  | 'top-auto'
  | 'right-auto'
  | 'bottom-auto'
  | 'left-auto'
  | 'inset-px'
  | 'inset-x-px'
  | 'inset-y-px'
  | 'top-px'
  | 'right-px'
  | 'bottom-px'
  | 'left-px'
  | 'inset-0.5'
  | 'inset-x-0.5'
  | 'inset-y-0.5'
  | 'top-0.5'
  | 'right-0.5'
  | 'bottom-0.5'
  | 'left-0.5'
  | 'inset-1.5'
  | 'inset-x-1.5'
  | 'inset-y-1.5'
  | 'top-1.5'
  | 'right-1.5'
  | 'bottom-1.5'
  | 'left-1.5'
  | 'inset-2.5'
  | 'inset-x-2.5'
  | 'inset-y-2.5'
  | 'top-2.5'
  | 'right-2.5'
  | 'bottom-2.5'
  | 'left-2.5'
  | 'inset-3.5'
  | 'inset-x-3.5'
  | 'inset-y-3.5'
  | 'top-3.5'
  | 'right-3.5'
  | 'bottom-3.5'
  | 'left-3.5'
  | '-inset-0'
  | '-inset-x-0'
  | '-inset-y-0'
  | '-top-0'
  | '-right-0'
  | '-bottom-0'
  | '-left-0'
  | '-inset-1'
  | '-inset-x-1'
  | '-inset-y-1'
  | '-top-1'
  | '-right-1'
  | '-bottom-1'
  | '-left-1'
  | '-inset-2'
  | '-inset-x-2'
  | '-inset-y-2'
  | '-top-2'
  | '-right-2'
  | '-bottom-2'
  | '-left-2'
  | '-inset-3'
  | '-inset-x-3'
  | '-inset-y-3'
  | '-top-3'
  | '-right-3'
  | '-bottom-3'
  | '-left-3'
  | '-inset-4'
  | '-inset-x-4'
  | '-inset-y-4'
  | '-top-4'
  | '-right-4'
  | '-bottom-4'
  | '-left-4'
  | '-inset-5'
  | '-inset-x-5'
  | '-inset-y-5'
  | '-top-5'
  | '-right-5'
  | '-bottom-5'
  | '-left-5'
  | '-inset-6'
  | '-inset-x-6'
  | '-inset-y-6'
  | '-top-6'
  | '-right-6'
  | '-bottom-6'
  | '-left-6'
  | '-inset-7'
  | '-inset-x-7'
  | '-inset-y-7'
  | '-top-7'
  | '-right-7'
  | '-bottom-7'
  | '-left-7'
  | '-inset-8'
  | '-inset-x-8'
  | '-inset-y-8'
  | '-top-8'
  | '-right-8'
  | '-bottom-8'
  | '-left-8'
  | '-inset-9'
  | '-inset-x-9'
  | '-inset-y-9'
  | '-top-9'
  | '-right-9'
  | '-bottom-9'
  | '-left-9'
  | '-inset-10'
  | '-inset-x-10'
  | '-inset-y-10'
  | '-top-10'
  | '-right-10'
  | '-bottom-10'
  | '-left-10'
  | '-inset-11'
  | '-inset-x-11'
  | '-inset-y-11'
  | '-top-11'
  | '-right-11'
  | '-bottom-11'
  | '-left-11'
  | '-inset-12'
  | '-inset-x-12'
  | '-inset-y-12'
  | '-top-12'
  | '-right-12'
  | '-bottom-12'
  | '-left-12'
  | '-inset-14'
  | '-inset-x-14'
  | '-inset-y-14'
  | '-top-14'
  | '-right-14'
  | '-bottom-14'
  | '-left-14'
  | '-inset-16'
  | '-inset-x-16'
  | '-inset-y-16'
  | '-top-16'
  | '-right-16'
  | '-bottom-16'
  | '-left-16'
  | '-inset-20'
  | '-inset-x-20'
  | '-inset-y-20'
  | '-top-20'
  | '-right-20'
  | '-bottom-20'
  | '-left-20'
  | '-inset-24'
  | '-inset-x-24'
  | '-inset-y-24'
  | '-top-24'
  | '-right-24'
  | '-bottom-24'
  | '-left-24'
  | '-inset-28'
  | '-inset-x-28'
  | '-inset-y-28'
  | '-top-28'
  | '-right-28'
  | '-bottom-28'
  | '-left-28'
  | '-inset-32'
  | '-inset-x-32'
  | '-inset-y-32'
  | '-top-32'
  | '-right-32'
  | '-bottom-32'
  | '-left-32'
  | '-inset-36'
  | '-inset-x-36'
  | '-inset-y-36'
  | '-top-36'
  | '-right-36'
  | '-bottom-36'
  | '-left-36'
  | '-inset-40'
  | '-inset-x-40'
  | '-inset-y-40'
  | '-top-40'
  | '-right-40'
  | '-bottom-40'
  | '-left-40'
  | '-inset-44'
  | '-inset-x-44'
  | '-inset-y-44'
  | '-top-44'
  | '-right-44'
  | '-bottom-44'
  | '-left-44'
  | '-inset-48'
  | '-inset-x-48'
  | '-inset-y-48'
  | '-top-48'
  | '-right-48'
  | '-bottom-48'
  | '-left-48'
  | '-inset-52'
  | '-inset-x-52'
  | '-inset-y-52'
  | '-top-52'
  | '-right-52'
  | '-bottom-52'
  | '-left-52'
  | '-inset-56'
  | '-inset-x-56'
  | '-inset-y-56'
  | '-top-56'
  | '-right-56'
  | '-bottom-56'
  | '-left-56'
  | '-inset-60'
  | '-inset-x-60'
  | '-inset-y-60'
  | '-top-60'
  | '-right-60'
  | '-bottom-60'
  | '-left-60'
  | '-inset-64'
  | '-inset-x-64'
  | '-inset-y-64'
  | '-top-64'
  | '-right-64'
  | '-bottom-64'
  | '-left-64'
  | '-inset-72'
  | '-inset-x-72'
  | '-inset-y-72'
  | '-top-72'
  | '-right-72'
  | '-bottom-72'
  | '-left-72'
  | '-inset-80'
  | '-inset-x-80'
  | '-inset-y-80'
  | '-top-80'
  | '-right-80'
  | '-bottom-80'
  | '-left-80'
  | '-inset-96'
  | '-inset-x-96'
  | '-inset-y-96'
  | '-top-96'
  | '-right-96'
  | '-bottom-96'
  | '-left-96'
  | '-inset-px'
  | '-inset-x-px'
  | '-inset-y-px'
  | '-top-px'
  | '-right-px'
  | '-bottom-px'
  | '-left-px'
  | '-inset-0.5'
  | '-inset-x-0.5'
  | '-inset-y-0.5'
  | '-top-0.5'
  | '-right-0.5'
  | '-bottom-0.5'
  | '-left-0.5'
  | '-inset-1.5'
  | '-inset-x-1.5'
  | '-inset-y-1.5'
  | '-top-1.5'
  | '-right-1.5'
  | '-bottom-1.5'
  | '-left-1.5'
  | '-inset-2.5'
  | '-inset-x-2.5'
  | '-inset-y-2.5'
  | '-top-2.5'
  | '-right-2.5'
  | '-bottom-2.5'
  | '-left-2.5'
  | '-inset-3.5'
  | '-inset-x-3.5'
  | '-inset-y-3.5'
  | '-top-3.5'
  | '-right-3.5'
  | '-bottom-3.5'
  | '-left-3.5'
  | 'inset-1/2'
  | 'inset-x-1/2'
  | 'inset-y-1/2'
  | 'top-1/2'
  | 'right-1/2'
  | 'bottom-1/2'
  | 'left-1/2'
  | 'inset-1/3'
  | 'inset-x-1/3'
  | 'inset-y-1/3'
  | 'top-1/3'
  | 'right-1/3'
  | 'bottom-1/3'
  | 'left-1/3'
  | 'inset-2/3'
  | 'inset-x-2/3'
  | 'inset-y-2/3'
  | 'top-2/3'
  | 'right-2/3'
  | 'bottom-2/3'
  | 'left-2/3'
  | 'inset-1/4'
  | 'inset-x-1/4'
  | 'inset-y-1/4'
  | 'top-1/4'
  | 'right-1/4'
  | 'bottom-1/4'
  | 'left-1/4'
  | 'inset-2/4'
  | 'inset-x-2/4'
  | 'inset-y-2/4'
  | 'top-2/4'
  | 'right-2/4'
  | 'bottom-2/4'
  | 'left-2/4'
  | 'inset-3/4'
  | 'inset-x-3/4'
  | 'inset-y-3/4'
  | 'top-3/4'
  | 'right-3/4'
  | 'bottom-3/4'
  | 'left-3/4'
  | 'inset-full'
  | 'inset-x-full'
  | 'inset-y-full'
  | 'top-full'
  | 'right-full'
  | 'bottom-full'
  | 'left-full'
  | '-inset-1/2'
  | '-inset-x-1/2'
  | '-inset-y-1/2'
  | '-top-1/2'
  | '-right-1/2'
  | '-bottom-1/2'
  | '-left-1/2'
  | '-inset-1/3'
  | '-inset-x-1/3'
  | '-inset-y-1/3'
  | '-top-1/3'
  | '-right-1/3'
  | '-bottom-1/3'
  | '-left-1/3'
  | '-inset-2/3'
  | '-inset-x-2/3'
  | '-inset-y-2/3'
  | '-top-2/3'
  | '-right-2/3'
  | '-bottom-2/3'
  | '-left-2/3'
  | '-inset-1/4'
  | '-inset-x-1/4'
  | '-inset-y-1/4'
  | '-top-1/4'
  | '-right-1/4'
  | '-bottom-1/4'
  | '-left-1/4'
  | '-inset-2/4'
  | '-inset-x-2/4'
  | '-inset-y-2/4'
  | '-top-2/4'
  | '-right-2/4'
  | '-bottom-2/4'
  | '-left-2/4'
  | '-inset-3/4'
  | '-inset-x-3/4'
  | '-inset-y-3/4'
  | '-top-3/4'
  | '-right-3/4'
  | '-bottom-3/4'
  | '-left-3/4'
  | '-inset-full'
  | '-inset-x-full'
  | '-inset-y-full'
  | '-top-full'
  | '-right-full'
  | '-bottom-full'
  | '-left-full'

export type TZIndex =
  | 'z-0'
  | 'z-10'
  | 'z-20'
  | 'z-30'
  | 'z-40'
  | 'z-50'
  | 'z-auto'

export type TLayout =
  | TDisplay
  | TBoxDecorationBreak
  | TBoxSizing
  | TContainer
  | TFloat
  | TClear
  | TIsolation
  | TObjectFit
  | TOverflow
  | TOverscrollBehavior
  | TPosition
  | TVisibility
  | TObjectPosition
  | TInset
  | TZIndex

export type TWidth =
  | 'w-0'
  | 'w-1'
  | 'w-2'
  | 'w-3'
  | 'w-4'
  | 'w-5'
  | 'w-6'
  | 'w-7'
  | 'w-8'
  | 'w-9'
  | 'w-10'
  | 'w-11'
  | 'w-12'
  | 'w-14'
  | 'w-16'
  | 'w-20'
  | 'w-24'
  | 'w-28'
  | 'w-32'
  | 'w-36'
  | 'w-40'
  | 'w-44'
  | 'w-48'
  | 'w-52'
  | 'w-56'
  | 'w-60'
  | 'w-64'
  | 'w-72'
  | 'w-80'
  | 'w-96'
  | 'w-auto'
  | 'w-px'
  | 'w-0.5'
  | 'w-1.5'
  | 'w-2.5'
  | 'w-3.5'
  | 'w-1/2'
  | 'w-1/3'
  | 'w-2/3'
  | 'w-1/4'
  | 'w-2/4'
  | 'w-3/4'
  | 'w-1/5'
  | 'w-2/5'
  | 'w-3/5'
  | 'w-4/5'
  | 'w-1/6'
  | 'w-2/6'
  | 'w-3/6'
  | 'w-4/6'
  | 'w-5/6'
  | 'w-1/12'
  | 'w-2/12'
  | 'w-3/12'
  | 'w-4/12'
  | 'w-5/12'
  | 'w-6/12'
  | 'w-7/12'
  | 'w-8/12'
  | 'w-9/12'
  | 'w-10/12'
  | 'w-11/12'
  | 'w-full'
  | 'w-screen'
  | 'w-min'
  | 'w-max'

export type TMinWidth = 'min-w-0' | 'min-w-full' | 'min-w-min' | 'min-w-max'

export type TMaxWidth =
  | 'max-w-0'
  | 'max-w-none'
  | 'max-w-xs'
  | 'max-w-sm'
  | 'max-w-md'
  | 'max-w-lg'
  | 'max-w-xl'
  | 'max-w-2xl'
  | 'max-w-3xl'
  | 'max-w-4xl'
  | 'max-w-5xl'
  | 'max-w-6xl'
  | 'max-w-7xl'
  | 'max-w-full'
  | 'max-w-min'
  | 'max-w-max'
  | 'max-w-prose'
  | 'max-w-screen-sm'
  | 'max-w-screen-md'
  | 'max-w-screen-lg'
  | 'max-w-screen-xl'
  | 'max-w-screen-2xl'

export type THeight =
  | 'h-0'
  | 'h-1'
  | 'h-2'
  | 'h-3'
  | 'h-4'
  | 'h-5'
  | 'h-6'
  | 'h-7'
  | 'h-8'
  | 'h-9'
  | 'h-10'
  | 'h-11'
  | 'h-12'
  | 'h-14'
  | 'h-16'
  | 'h-20'
  | 'h-24'
  | 'h-28'
  | 'h-32'
  | 'h-36'
  | 'h-40'
  | 'h-44'
  | 'h-48'
  | 'h-52'
  | 'h-56'
  | 'h-60'
  | 'h-64'
  | 'h-72'
  | 'h-80'
  | 'h-96'
  | 'h-auto'
  | 'h-px'
  | 'h-0.5'
  | 'h-1.5'
  | 'h-2.5'
  | 'h-3.5'
  | 'h-1/2'
  | 'h-1/3'
  | 'h-2/3'
  | 'h-1/4'
  | 'h-2/4'
  | 'h-3/4'
  | 'h-1/5'
  | 'h-2/5'
  | 'h-3/5'
  | 'h-4/5'
  | 'h-1/6'
  | 'h-2/6'
  | 'h-3/6'
  | 'h-4/6'
  | 'h-5/6'
  | 'h-full'
  | 'h-screen'

export type TMinHeight = 'min-h-0' | 'min-h-full' | 'min-h-screen'

export type TMaxHeight =
  | 'max-h-0'
  | 'max-h-1'
  | 'max-h-2'
  | 'max-h-3'
  | 'max-h-4'
  | 'max-h-5'
  | 'max-h-6'
  | 'max-h-7'
  | 'max-h-8'
  | 'max-h-9'
  | 'max-h-10'
  | 'max-h-11'
  | 'max-h-12'
  | 'max-h-14'
  | 'max-h-16'
  | 'max-h-20'
  | 'max-h-24'
  | 'max-h-28'
  | 'max-h-32'
  | 'max-h-36'
  | 'max-h-40'
  | 'max-h-44'
  | 'max-h-48'
  | 'max-h-52'
  | 'max-h-56'
  | 'max-h-60'
  | 'max-h-64'
  | 'max-h-72'
  | 'max-h-80'
  | 'max-h-96'
  | 'max-h-px'
  | 'max-h-0.5'
  | 'max-h-1.5'
  | 'max-h-2.5'
  | 'max-h-3.5'
  | 'max-h-full'
  | 'max-h-screen'

export type TSizing =
  | TWidth
  | TMinWidth
  | TMaxWidth
  | THeight
  | TMinHeight
  | TMaxHeight

export type TFill = 'fill-current'

export type TStroke = 'stroke-current'

export type TStrokeWidth = 'stroke-0' | 'stroke-1' | 'stroke-2'

export type TSVG = TFill | TStroke | TStrokeWidth

export type THardwareAcceleration = 'transform-gpu'

export type TScale =
  | 'scale-0'
  | 'scale-50'
  | 'scale-75'
  | 'scale-90'
  | 'scale-95'
  | 'scale-100'
  | 'scale-105'
  | 'scale-110'
  | 'scale-125'
  | 'scale-150'
  | 'scale-x-0'
  | 'scale-x-50'
  | 'scale-x-75'
  | 'scale-x-90'
  | 'scale-x-95'
  | 'scale-x-100'
  | 'scale-x-105'
  | 'scale-x-110'
  | 'scale-x-125'
  | 'scale-x-150'
  | 'scale-y-0'
  | 'scale-y-50'
  | 'scale-y-75'
  | 'scale-y-90'
  | 'scale-y-95'
  | 'scale-y-100'
  | 'scale-y-105'
  | 'scale-y-110'
  | 'scale-y-125'
  | 'scale-y-150'

export type TRotate =
  | 'rotate-0'
  | 'rotate-1'
  | 'rotate-2'
  | 'rotate-3'
  | 'rotate-6'
  | 'rotate-12'
  | 'rotate-45'
  | 'rotate-90'
  | 'rotate-180'
  | '-rotate-180'
  | '-rotate-90'
  | '-rotate-45'
  | '-rotate-12'
  | '-rotate-6'
  | '-rotate-3'
  | '-rotate-2'
  | '-rotate-1'

export type TTranslate =
  | 'translate-x-0'
  | 'translate-x-1'
  | 'translate-x-2'
  | 'translate-x-3'
  | 'translate-x-4'
  | 'translate-x-5'
  | 'translate-x-6'
  | 'translate-x-7'
  | 'translate-x-8'
  | 'translate-x-9'
  | 'translate-x-10'
  | 'translate-x-11'
  | 'translate-x-12'
  | 'translate-x-14'
  | 'translate-x-16'
  | 'translate-x-20'
  | 'translate-x-24'
  | 'translate-x-28'
  | 'translate-x-32'
  | 'translate-x-36'
  | 'translate-x-40'
  | 'translate-x-44'
  | 'translate-x-48'
  | 'translate-x-52'
  | 'translate-x-56'
  | 'translate-x-60'
  | 'translate-x-64'
  | 'translate-x-72'
  | 'translate-x-80'
  | 'translate-x-96'
  | 'translate-x-px'
  | 'translate-x-0.5'
  | 'translate-x-1.5'
  | 'translate-x-2.5'
  | 'translate-x-3.5'
  | '-translate-x-0'
  | '-translate-x-1'
  | '-translate-x-2'
  | '-translate-x-3'
  | '-translate-x-4'
  | '-translate-x-5'
  | '-translate-x-6'
  | '-translate-x-7'
  | '-translate-x-8'
  | '-translate-x-9'
  | '-translate-x-10'
  | '-translate-x-11'
  | '-translate-x-12'
  | '-translate-x-14'
  | '-translate-x-16'
  | '-translate-x-20'
  | '-translate-x-24'
  | '-translate-x-28'
  | '-translate-x-32'
  | '-translate-x-36'
  | '-translate-x-40'
  | '-translate-x-44'
  | '-translate-x-48'
  | '-translate-x-52'
  | '-translate-x-56'
  | '-translate-x-60'
  | '-translate-x-64'
  | '-translate-x-72'
  | '-translate-x-80'
  | '-translate-x-96'
  | '-translate-x-px'
  | '-translate-x-0.5'
  | '-translate-x-1.5'
  | '-translate-x-2.5'
  | '-translate-x-3.5'
  | 'translate-x-1/2'
  | 'translate-x-1/3'
  | 'translate-x-2/3'
  | 'translate-x-1/4'
  | 'translate-x-2/4'
  | 'translate-x-3/4'
  | 'translate-x-full'
  | '-translate-x-1/2'
  | '-translate-x-1/3'
  | '-translate-x-2/3'
  | '-translate-x-1/4'
  | '-translate-x-2/4'
  | '-translate-x-3/4'
  | '-translate-x-full'
  | 'translate-y-0'
  | 'translate-y-1'
  | 'translate-y-2'
  | 'translate-y-3'
  | 'translate-y-4'
  | 'translate-y-5'
  | 'translate-y-6'
  | 'translate-y-7'
  | 'translate-y-8'
  | 'translate-y-9'
  | 'translate-y-10'
  | 'translate-y-11'
  | 'translate-y-12'
  | 'translate-y-14'
  | 'translate-y-16'
  | 'translate-y-20'
  | 'translate-y-24'
  | 'translate-y-28'
  | 'translate-y-32'
  | 'translate-y-36'
  | 'translate-y-40'
  | 'translate-y-44'
  | 'translate-y-48'
  | 'translate-y-52'
  | 'translate-y-56'
  | 'translate-y-60'
  | 'translate-y-64'
  | 'translate-y-72'
  | 'translate-y-80'
  | 'translate-y-96'
  | 'translate-y-px'
  | 'translate-y-0.5'
  | 'translate-y-1.5'
  | 'translate-y-2.5'
  | 'translate-y-3.5'
  | '-translate-y-0'
  | '-translate-y-1'
  | '-translate-y-2'
  | '-translate-y-3'
  | '-translate-y-4'
  | '-translate-y-5'
  | '-translate-y-6'
  | '-translate-y-7'
  | '-translate-y-8'
  | '-translate-y-9'
  | '-translate-y-10'
  | '-translate-y-11'
  | '-translate-y-12'
  | '-translate-y-14'
  | '-translate-y-16'
  | '-translate-y-20'
  | '-translate-y-24'
  | '-translate-y-28'
  | '-translate-y-32'
  | '-translate-y-36'
  | '-translate-y-40'
  | '-translate-y-44'
  | '-translate-y-48'
  | '-translate-y-52'
  | '-translate-y-56'
  | '-translate-y-60'
  | '-translate-y-64'
  | '-translate-y-72'
  | '-translate-y-80'
  | '-translate-y-96'
  | '-translate-y-px'
  | '-translate-y-0.5'
  | '-translate-y-1.5'
  | '-translate-y-2.5'
  | '-translate-y-3.5'
  | 'translate-y-1/2'
  | 'translate-y-1/3'
  | 'translate-y-2/3'
  | 'translate-y-1/4'
  | 'translate-y-2/4'
  | 'translate-y-3/4'
  | 'translate-y-full'
  | '-translate-y-1/2'
  | '-translate-y-1/3'
  | '-translate-y-2/3'
  | '-translate-y-1/4'
  | '-translate-y-2/4'
  | '-translate-y-3/4'
  | '-translate-y-full'

export type TSkew =
  | 'skew-x-0'
  | 'skew-x-1'
  | 'skew-x-2'
  | 'skew-x-3'
  | 'skew-x-6'
  | 'skew-x-12'
  | '-skew-x-12'
  | '-skew-x-6'
  | '-skew-x-3'
  | '-skew-x-2'
  | '-skew-x-1'
  | 'skew-y-0'
  | 'skew-y-1'
  | 'skew-y-2'
  | 'skew-y-3'
  | 'skew-y-6'
  | 'skew-y-12'
  | '-skew-y-12'
  | '-skew-y-6'
  | '-skew-y-3'
  | '-skew-y-2'
  | '-skew-y-1'

export type TTransformOrigin =
  | 'origin-center'
  | 'origin-top'
  | 'origin-top-right'
  | 'origin-right'
  | 'origin-bottom-right'
  | 'origin-bottom'
  | 'origin-bottom-left'
  | 'origin-left'
  | 'origin-top-left'

export type TTransforms =
  | THardwareAcceleration
  | TScale
  | TRotate
  | TTranslate
  | TSkew
  | TTransformOrigin

export type TFontSmoothing = 'antialiased' | 'subpixel-antialiased'

export type TFontStyle = 'italic' | 'non-italic'

export type TFontVariantNumeric =
  | 'normal-nums'
  | 'ordinal'
  | 'slashed-zero'
  | 'lining-nums'
  | 'oldstyle-nums'
  | 'proportional-nums'
  | 'tabular-nums'
  | 'diagonal-fractions'
  | 'stacked-fractions'

export type TListStylePosition = 'list-inside' | 'list-outside'

export type TTextAlign =
  | 'text-left'
  | 'text-center'
  | 'text-right'
  | 'text-justify'

export type TTextDecoration = 'underline' | 'line-through' | 'no-underline'

export type TTextTransform =
  | 'uppercase'
  | 'lowercase'
  | 'capitalize'
  | 'normal-case'

export type TTextOverflow = 'truncate' | 'overflow-ellipsis' | 'overflow-clip'

export type TVerticalAlign =
  | 'align-baseline'
  | 'align-top'
  | 'align-middle'
  | 'align-bottom'
  | 'align-text-top'
  | 'align-text-bottom'

export type TWhitespace =
  | 'whitespace-normal'
  | 'whitespace-nowrap'
  | 'whitespace-pre'
  | 'whitespace-pre-line'
  | 'whitespace-pre-wrap'

export type TWordBreak = 'break-normal' | 'break-words' | 'break-all'

export type TFontFamily = 'font-sans' | 'font-serif' | 'font-mono'

export type TFontSize =
  | 'text-xs'
  | 'text-sm'
  | 'text-base'
  | 'text-lg'
  | 'text-xl'
  | 'text-2xl'
  | 'text-3xl'
  | 'text-4xl'
  | 'text-5xl'
  | 'text-6xl'
  | 'text-7xl'
  | 'text-8xl'
  | 'text-9xl'

export type TFontWeight =
  | 'font-thin'
  | 'font-extralight'
  | 'font-light'
  | 'font-normal'
  | 'font-medium'
  | 'font-semibold'
  | 'font-bold'
  | 'font-extrabold'
  | 'font-black'

export type TLetterSpacing =
  | 'tracking-tighter'
  | 'tracking-tight'
  | 'tracking-normal'
  | 'tracking-wide'
  | 'tracking-wider'
  | 'tracking-widest'

export type TLineHeight =
  | 'leading-3'
  | 'leading-4'
  | 'leading-5'
  | 'leading-6'
  | 'leading-7'
  | 'leading-8'
  | 'leading-9'
  | 'leading-10'
  | 'leading-none'
  | 'leading-tight'
  | 'leading-snug'
  | 'leading-normal'
  | 'leading-relaxed'
  | 'leading-loose'

export type TListStyleType = 'list-none' | 'list-disc' | 'list-decimal'

export type TPlaceholderColor =
  | 'placeholder-transparent'
  | 'placeholder-current'
  | 'placeholder-black'
  | 'placeholder-white'
  | 'placeholder-gray-50'
  | 'placeholder-gray-100'
  | 'placeholder-gray-200'
  | 'placeholder-gray-300'
  | 'placeholder-gray-400'
  | 'placeholder-gray-500'
  | 'placeholder-gray-600'
  | 'placeholder-gray-700'
  | 'placeholder-gray-800'
  | 'placeholder-gray-900'
  | 'placeholder-red-50'
  | 'placeholder-red-100'
  | 'placeholder-red-200'
  | 'placeholder-red-300'
  | 'placeholder-red-400'
  | 'placeholder-red-500'
  | 'placeholder-red-600'
  | 'placeholder-red-700'
  | 'placeholder-red-800'
  | 'placeholder-red-900'
  | 'placeholder-yellow-50'
  | 'placeholder-yellow-100'
  | 'placeholder-yellow-200'
  | 'placeholder-yellow-300'
  | 'placeholder-yellow-400'
  | 'placeholder-yellow-500'
  | 'placeholder-yellow-600'
  | 'placeholder-yellow-700'
  | 'placeholder-yellow-800'
  | 'placeholder-yellow-900'
  | 'placeholder-green-50'
  | 'placeholder-green-100'
  | 'placeholder-green-200'
  | 'placeholder-green-300'
  | 'placeholder-green-400'
  | 'placeholder-green-500'
  | 'placeholder-green-600'
  | 'placeholder-green-700'
  | 'placeholder-green-800'
  | 'placeholder-green-900'
  | 'placeholder-blue-50'
  | 'placeholder-blue-100'
  | 'placeholder-blue-200'
  | 'placeholder-blue-300'
  | 'placeholder-blue-400'
  | 'placeholder-blue-500'
  | 'placeholder-blue-600'
  | 'placeholder-blue-700'
  | 'placeholder-blue-800'
  | 'placeholder-blue-900'
  | 'placeholder-indigo-50'
  | 'placeholder-indigo-100'
  | 'placeholder-indigo-200'
  | 'placeholder-indigo-300'
  | 'placeholder-indigo-400'
  | 'placeholder-indigo-500'
  | 'placeholder-indigo-600'
  | 'placeholder-indigo-700'
  | 'placeholder-indigo-800'
  | 'placeholder-indigo-900'
  | 'placeholder-purple-50'
  | 'placeholder-purple-100'
  | 'placeholder-purple-200'
  | 'placeholder-purple-300'
  | 'placeholder-purple-400'
  | 'placeholder-purple-500'
  | 'placeholder-purple-600'
  | 'placeholder-purple-700'
  | 'placeholder-purple-800'
  | 'placeholder-purple-900'
  | 'placeholder-pink-50'
  | 'placeholder-pink-100'
  | 'placeholder-pink-200'
  | 'placeholder-pink-300'
  | 'placeholder-pink-400'
  | 'placeholder-pink-500'
  | 'placeholder-pink-600'
  | 'placeholder-pink-700'
  | 'placeholder-pink-800'
  | 'placeholder-pink-900'

export type TPlaceholderOpacity =
  | 'placeholder-opacity-0'
  | 'placeholder-opacity-5'
  | 'placeholder-opacity-10'
  | 'placeholder-opacity-20'
  | 'placeholder-opacity-25'
  | 'placeholder-opacity-30'
  | 'placeholder-opacity-40'
  | 'placeholder-opacity-50'
  | 'placeholder-opacity-60'
  | 'placeholder-opacity-70'
  | 'placeholder-opacity-75'
  | 'placeholder-opacity-80'
  | 'placeholder-opacity-90'
  | 'placeholder-opacity-95'
  | 'placeholder-opacity-100'

export type TTextColor =
  | 'text-transparent'
  | 'text-current'
  | 'text-black'
  | 'text-white'
  | 'text-gray-50'
  | 'text-gray-100'
  | 'text-gray-200'
  | 'text-gray-300'
  | 'text-gray-400'
  | 'text-gray-500'
  | 'text-gray-600'
  | 'text-gray-700'
  | 'text-gray-800'
  | 'text-gray-900'
  | 'text-red-50'
  | 'text-red-100'
  | 'text-red-200'
  | 'text-red-300'
  | 'text-red-400'
  | 'text-red-500'
  | 'text-red-600'
  | 'text-red-700'
  | 'text-red-800'
  | 'text-red-900'
  | 'text-yellow-50'
  | 'text-yellow-100'
  | 'text-yellow-200'
  | 'text-yellow-300'
  | 'text-yellow-400'
  | 'text-yellow-500'
  | 'text-yellow-600'
  | 'text-yellow-700'
  | 'text-yellow-800'
  | 'text-yellow-900'
  | 'text-green-50'
  | 'text-green-100'
  | 'text-green-200'
  | 'text-green-300'
  | 'text-green-400'
  | 'text-green-500'
  | 'text-green-600'
  | 'text-green-700'
  | 'text-green-800'
  | 'text-green-900'
  | 'text-blue-50'
  | 'text-blue-100'
  | 'text-blue-200'
  | 'text-blue-300'
  | 'text-blue-400'
  | 'text-blue-500'
  | 'text-blue-600'
  | 'text-blue-700'
  | 'text-blue-800'
  | 'text-blue-900'
  | 'text-indigo-50'
  | 'text-indigo-100'
  | 'text-indigo-200'
  | 'text-indigo-300'
  | 'text-indigo-400'
  | 'text-indigo-500'
  | 'text-indigo-600'
  | 'text-indigo-700'
  | 'text-indigo-800'
  | 'text-indigo-900'
  | 'text-purple-50'
  | 'text-purple-100'
  | 'text-purple-200'
  | 'text-purple-300'
  | 'text-purple-400'
  | 'text-purple-500'
  | 'text-purple-600'
  | 'text-purple-700'
  | 'text-purple-800'
  | 'text-purple-900'
  | 'text-pink-50'
  | 'text-pink-100'
  | 'text-pink-200'
  | 'text-pink-300'
  | 'text-pink-400'
  | 'text-pink-500'
  | 'text-pink-600'
  | 'text-pink-700'
  | 'text-pink-800'
  | 'text-pink-900'

export type TTextOpacity =
  | 'text-opacity-0'
  | 'text-opacity-5'
  | 'text-opacity-10'
  | 'text-opacity-20'
  | 'text-opacity-25'
  | 'text-opacity-30'
  | 'text-opacity-40'
  | 'text-opacity-50'
  | 'text-opacity-60'
  | 'text-opacity-70'
  | 'text-opacity-75'
  | 'text-opacity-80'
  | 'text-opacity-90'
  | 'text-opacity-95'
  | 'text-opacity-100'

export type TTypography =
  | TFontSmoothing
  | TFontStyle
  | TFontVariantNumeric
  | TListStylePosition
  | TTextAlign
  | TTextDecoration
  | TTextTransform
  | TTextOverflow
  | TVerticalAlign
  | TWhitespace
  | TWordBreak
  | TFontFamily
  | TFontSize
  | TFontWeight
  | TLetterSpacing
  | TLineHeight
  | TListStyleType
  | TPlaceholderColor
  | TPlaceholderOpacity
  | TTextColor
  | TTextOpacity

export type TPseudoClasses =
  | 'sm:content-start'
  | 'md:content-start'
  | 'lg:content-start'
  | 'xl:content-start'
  | '2xl:content-start'
  | 'sm:content-center'
  | 'md:content-center'
  | 'lg:content-center'
  | 'xl:content-center'
  | '2xl:content-center'
  | 'sm:content-end'
  | 'md:content-end'
  | 'lg:content-end'
  | 'xl:content-end'
  | '2xl:content-end'
  | 'sm:content-between'
  | 'md:content-between'
  | 'lg:content-between'
  | 'xl:content-between'
  | '2xl:content-between'
  | 'sm:content-around'
  | 'md:content-around'
  | 'lg:content-around'
  | 'xl:content-around'
  | '2xl:content-around'
  | 'sm:items-stretch'
  | 'md:items-stretch'
  | 'lg:items-stretch'
  | 'xl:items-stretch'
  | '2xl:items-stretch'
  | 'sm:items-start'
  | 'md:items-start'
  | 'lg:items-start'
  | 'xl:items-start'
  | '2xl:items-start'
  | 'sm:items-center'
  | 'md:items-center'
  | 'lg:items-center'
  | 'xl:items-center'
  | '2xl:items-center'
  | 'sm:items-end'
  | 'md:items-end'
  | 'lg:items-end'
  | 'xl:items-end'
  | '2xl:items-end'
  | 'sm:items-baseline'
  | 'md:items-baseline'
  | 'lg:items-baseline'
  | 'xl:items-baseline'
  | '2xl:items-baseline'
  | 'sm:self-auto'
  | 'md:self-auto'
  | 'lg:self-auto'
  | 'xl:self-auto'
  | '2xl:self-auto'
  | 'sm:self-start'
  | 'md:self-start'
  | 'lg:self-start'
  | 'xl:self-start'
  | '2xl:self-start'
  | 'sm:self-center'
  | 'md:self-center'
  | 'lg:self-center'
  | 'xl:self-center'
  | '2xl:self-center'
  | 'sm:self-end'
  | 'md:self-end'
  | 'lg:self-end'
  | 'xl:self-end'
  | '2xl:self-end'
  | 'sm:self-stretch'
  | 'md:self-stretch'
  | 'lg:self-stretch'
  | 'xl:self-stretch'
  | '2xl:self-stretch'
  | 'sm:self-baseline'
  | 'md:self-baseline'
  | 'lg:self-baseline'
  | 'xl:self-baseline'
  | '2xl:self-baseline'
  | 'sm:animate-none'
  | 'md:animate-none'
  | 'lg:animate-none'
  | 'xl:animate-none'
  | '2xl:animate-none'
  | 'sm:animate-spin'
  | 'md:animate-spin'
  | 'lg:animate-spin'
  | 'xl:animate-spin'
  | '2xl:animate-spin'
  | 'sm:animate-ping'
  | 'md:animate-ping'
  | 'lg:animate-ping'
  | 'xl:animate-ping'
  | '2xl:animate-ping'
  | 'sm:animate-pulse'
  | 'md:animate-pulse'
  | 'lg:animate-pulse'
  | 'xl:animate-pulse'
  | '2xl:animate-pulse'
  | 'sm:animate-bounce'
  | 'md:animate-bounce'
  | 'lg:animate-bounce'
  | 'xl:animate-bounce'
  | '2xl:animate-bounce'
  | 'sm:appearance-none'
  | 'md:appearance-none'
  | 'lg:appearance-none'
  | 'xl:appearance-none'
  | '2xl:appearance-none'
  | 'sm:backdrop-blur-0'
  | 'md:backdrop-blur-0'
  | 'lg:backdrop-blur-0'
  | 'xl:backdrop-blur-0'
  | '2xl:backdrop-blur-0'
  | 'sm:backdrop-blur-none'
  | 'md:backdrop-blur-none'
  | 'lg:backdrop-blur-none'
  | 'xl:backdrop-blur-none'
  | '2xl:backdrop-blur-none'
  | 'sm:backdrop-blur-sm'
  | 'md:backdrop-blur-sm'
  | 'lg:backdrop-blur-sm'
  | 'xl:backdrop-blur-sm'
  | '2xl:backdrop-blur-sm'
  | 'sm:backdrop-blur'
  | 'md:backdrop-blur'
  | 'lg:backdrop-blur'
  | 'xl:backdrop-blur'
  | '2xl:backdrop-blur'
  | 'sm:backdrop-blur-md'
  | 'md:backdrop-blur-md'
  | 'lg:backdrop-blur-md'
  | 'xl:backdrop-blur-md'
  | '2xl:backdrop-blur-md'
  | 'sm:backdrop-blur-lg'
  | 'md:backdrop-blur-lg'
  | 'lg:backdrop-blur-lg'
  | 'xl:backdrop-blur-lg'
  | '2xl:backdrop-blur-lg'
  | 'sm:backdrop-blur-xl'
  | 'md:backdrop-blur-xl'
  | 'lg:backdrop-blur-xl'
  | 'xl:backdrop-blur-xl'
  | '2xl:backdrop-blur-xl'
  | 'sm:backdrop-blur-2xl'
  | 'md:backdrop-blur-2xl'
  | 'lg:backdrop-blur-2xl'
  | 'xl:backdrop-blur-2xl'
  | '2xl:backdrop-blur-2xl'
  | 'sm:backdrop-blur-3xl'
  | 'md:backdrop-blur-3xl'
  | 'lg:backdrop-blur-3xl'
  | 'xl:backdrop-blur-3xl'
  | '2xl:backdrop-blur-3xl'
  | 'sm:backdrop-brightness-0'
  | 'md:backdrop-brightness-0'
  | 'lg:backdrop-brightness-0'
  | 'xl:backdrop-brightness-0'
  | '2xl:backdrop-brightness-0'
  | 'sm:backdrop-brightness-50'
  | 'md:backdrop-brightness-50'
  | 'lg:backdrop-brightness-50'
  | 'xl:backdrop-brightness-50'
  | '2xl:backdrop-brightness-50'
  | 'sm:backdrop-brightness-75'
  | 'md:backdrop-brightness-75'
  | 'lg:backdrop-brightness-75'
  | 'xl:backdrop-brightness-75'
  | '2xl:backdrop-brightness-75'
  | 'sm:backdrop-brightness-90'
  | 'md:backdrop-brightness-90'
  | 'lg:backdrop-brightness-90'
  | 'xl:backdrop-brightness-90'
  | '2xl:backdrop-brightness-90'
  | 'sm:backdrop-brightness-95'
  | 'md:backdrop-brightness-95'
  | 'lg:backdrop-brightness-95'
  | 'xl:backdrop-brightness-95'
  | '2xl:backdrop-brightness-95'
  | 'sm:backdrop-brightness-100'
  | 'md:backdrop-brightness-100'
  | 'lg:backdrop-brightness-100'
  | 'xl:backdrop-brightness-100'
  | '2xl:backdrop-brightness-100'
  | 'sm:backdrop-brightness-105'
  | 'md:backdrop-brightness-105'
  | 'lg:backdrop-brightness-105'
  | 'xl:backdrop-brightness-105'
  | '2xl:backdrop-brightness-105'
  | 'sm:backdrop-brightness-110'
  | 'md:backdrop-brightness-110'
  | 'lg:backdrop-brightness-110'
  | 'xl:backdrop-brightness-110'
  | '2xl:backdrop-brightness-110'
  | 'sm:backdrop-brightness-125'
  | 'md:backdrop-brightness-125'
  | 'lg:backdrop-brightness-125'
  | 'xl:backdrop-brightness-125'
  | '2xl:backdrop-brightness-125'
  | 'sm:backdrop-brightness-150'
  | 'md:backdrop-brightness-150'
  | 'lg:backdrop-brightness-150'
  | 'xl:backdrop-brightness-150'
  | '2xl:backdrop-brightness-150'
  | 'sm:backdrop-brightness-200'
  | 'md:backdrop-brightness-200'
  | 'lg:backdrop-brightness-200'
  | 'xl:backdrop-brightness-200'
  | '2xl:backdrop-brightness-200'
  | 'sm:backdrop-contrast-0'
  | 'md:backdrop-contrast-0'
  | 'lg:backdrop-contrast-0'
  | 'xl:backdrop-contrast-0'
  | '2xl:backdrop-contrast-0'
  | 'sm:backdrop-contrast-50'
  | 'md:backdrop-contrast-50'
  | 'lg:backdrop-contrast-50'
  | 'xl:backdrop-contrast-50'
  | '2xl:backdrop-contrast-50'
  | 'sm:backdrop-contrast-75'
  | 'md:backdrop-contrast-75'
  | 'lg:backdrop-contrast-75'
  | 'xl:backdrop-contrast-75'
  | '2xl:backdrop-contrast-75'
  | 'sm:backdrop-contrast-100'
  | 'md:backdrop-contrast-100'
  | 'lg:backdrop-contrast-100'
  | 'xl:backdrop-contrast-100'
  | '2xl:backdrop-contrast-100'
  | 'sm:backdrop-contrast-125'
  | 'md:backdrop-contrast-125'
  | 'lg:backdrop-contrast-125'
  | 'xl:backdrop-contrast-125'
  | '2xl:backdrop-contrast-125'
  | 'sm:backdrop-contrast-150'
  | 'md:backdrop-contrast-150'
  | 'lg:backdrop-contrast-150'
  | 'xl:backdrop-contrast-150'
  | '2xl:backdrop-contrast-150'
  | 'sm:backdrop-contrast-200'
  | 'md:backdrop-contrast-200'
  | 'lg:backdrop-contrast-200'
  | 'xl:backdrop-contrast-200'
  | '2xl:backdrop-contrast-200'
  | 'sm:backdrop-filter'
  | 'md:backdrop-filter'
  | 'lg:backdrop-filter'
  | 'xl:backdrop-filter'
  | '2xl:backdrop-filter'
  | 'sm:backdrop-filter-none'
  | 'md:backdrop-filter-none'
  | 'lg:backdrop-filter-none'
  | 'xl:backdrop-filter-none'
  | '2xl:backdrop-filter-none'
  | 'sm:backdrop-grayscale-0'
  | 'md:backdrop-grayscale-0'
  | 'lg:backdrop-grayscale-0'
  | 'xl:backdrop-grayscale-0'
  | '2xl:backdrop-grayscale-0'
  | 'sm:backdrop-grayscale'
  | 'md:backdrop-grayscale'
  | 'lg:backdrop-grayscale'
  | 'xl:backdrop-grayscale'
  | '2xl:backdrop-grayscale'
  | 'sm:backdrop-hue-rotate-0'
  | 'md:backdrop-hue-rotate-0'
  | 'lg:backdrop-hue-rotate-0'
  | 'xl:backdrop-hue-rotate-0'
  | '2xl:backdrop-hue-rotate-0'
  | 'sm:backdrop-hue-rotate-15'
  | 'md:backdrop-hue-rotate-15'
  | 'lg:backdrop-hue-rotate-15'
  | 'xl:backdrop-hue-rotate-15'
  | '2xl:backdrop-hue-rotate-15'
  | 'sm:backdrop-hue-rotate-30'
  | 'md:backdrop-hue-rotate-30'
  | 'lg:backdrop-hue-rotate-30'
  | 'xl:backdrop-hue-rotate-30'
  | '2xl:backdrop-hue-rotate-30'
  | 'sm:backdrop-hue-rotate-60'
  | 'md:backdrop-hue-rotate-60'
  | 'lg:backdrop-hue-rotate-60'
  | 'xl:backdrop-hue-rotate-60'
  | '2xl:backdrop-hue-rotate-60'
  | 'sm:backdrop-hue-rotate-90'
  | 'md:backdrop-hue-rotate-90'
  | 'lg:backdrop-hue-rotate-90'
  | 'xl:backdrop-hue-rotate-90'
  | '2xl:backdrop-hue-rotate-90'
  | 'sm:backdrop-hue-rotate-180'
  | 'md:backdrop-hue-rotate-180'
  | 'lg:backdrop-hue-rotate-180'
  | 'xl:backdrop-hue-rotate-180'
  | '2xl:backdrop-hue-rotate-180'
  | 'sm:-backdrop-hue-rotate-180'
  | 'md:-backdrop-hue-rotate-180'
  | 'lg:-backdrop-hue-rotate-180'
  | 'xl:-backdrop-hue-rotate-180'
  | '2xl:-backdrop-hue-rotate-180'
  | 'sm:-backdrop-hue-rotate-90'
  | 'md:-backdrop-hue-rotate-90'
  | 'lg:-backdrop-hue-rotate-90'
  | 'xl:-backdrop-hue-rotate-90'
  | '2xl:-backdrop-hue-rotate-90'
  | 'sm:-backdrop-hue-rotate-60'
  | 'md:-backdrop-hue-rotate-60'
  | 'lg:-backdrop-hue-rotate-60'
  | 'xl:-backdrop-hue-rotate-60'
  | '2xl:-backdrop-hue-rotate-60'
  | 'sm:-backdrop-hue-rotate-30'
  | 'md:-backdrop-hue-rotate-30'
  | 'lg:-backdrop-hue-rotate-30'
  | 'xl:-backdrop-hue-rotate-30'
  | '2xl:-backdrop-hue-rotate-30'
  | 'sm:-backdrop-hue-rotate-15'
  | 'md:-backdrop-hue-rotate-15'
  | 'lg:-backdrop-hue-rotate-15'
  | 'xl:-backdrop-hue-rotate-15'
  | '2xl:-backdrop-hue-rotate-15'
  | 'sm:backdrop-invert-0'
  | 'md:backdrop-invert-0'
  | 'lg:backdrop-invert-0'
  | 'xl:backdrop-invert-0'
  | '2xl:backdrop-invert-0'
  | 'sm:backdrop-invert'
  | 'md:backdrop-invert'
  | 'lg:backdrop-invert'
  | 'xl:backdrop-invert'
  | '2xl:backdrop-invert'
  | 'sm:backdrop-saturate-0'
  | 'md:backdrop-saturate-0'
  | 'lg:backdrop-saturate-0'
  | 'xl:backdrop-saturate-0'
  | '2xl:backdrop-saturate-0'
  | 'sm:backdrop-saturate-50'
  | 'md:backdrop-saturate-50'
  | 'lg:backdrop-saturate-50'
  | 'xl:backdrop-saturate-50'
  | '2xl:backdrop-saturate-50'
  | 'sm:backdrop-saturate-100'
  | 'md:backdrop-saturate-100'
  | 'lg:backdrop-saturate-100'
  | 'xl:backdrop-saturate-100'
  | '2xl:backdrop-saturate-100'
  | 'sm:backdrop-saturate-150'
  | 'md:backdrop-saturate-150'
  | 'lg:backdrop-saturate-150'
  | 'xl:backdrop-saturate-150'
  | '2xl:backdrop-saturate-150'
  | 'sm:backdrop-saturate-200'
  | 'md:backdrop-saturate-200'
  | 'lg:backdrop-saturate-200'
  | 'xl:backdrop-saturate-200'
  | '2xl:backdrop-saturate-200'
  | 'sm:backdrop-sepia-0'
  | 'md:backdrop-sepia-0'
  | 'lg:backdrop-sepia-0'
  | 'xl:backdrop-sepia-0'
  | '2xl:backdrop-sepia-0'
  | 'sm:backdrop-sepia'
  | 'md:backdrop-sepia'
  | 'lg:backdrop-sepia'
  | 'xl:backdrop-sepia'
  | '2xl:backdrop-sepia'
  | 'sm:bg-fixed'
  | 'md:bg-fixed'
  | 'lg:bg-fixed'
  | 'xl:bg-fixed'
  | '2xl:bg-fixed'
  | 'sm:bg-local'
  | 'md:bg-local'
  | 'lg:bg-local'
  | 'xl:bg-local'
  | '2xl:bg-local'
  | 'sm:bg-scroll'
  | 'md:bg-scroll'
  | 'lg:bg-scroll'
  | 'xl:bg-scroll'
  | '2xl:bg-scroll'
  | 'sm:bg-blend-normal'
  | 'md:bg-blend-normal'
  | 'lg:bg-blend-normal'
  | 'xl:bg-blend-normal'
  | '2xl:bg-blend-normal'
  | 'sm:bg-blend-multiply'
  | 'md:bg-blend-multiply'
  | 'lg:bg-blend-multiply'
  | 'xl:bg-blend-multiply'
  | '2xl:bg-blend-multiply'
  | 'sm:bg-blend-screen'
  | 'md:bg-blend-screen'
  | 'lg:bg-blend-screen'
  | 'xl:bg-blend-screen'
  | '2xl:bg-blend-screen'
  | 'sm:bg-blend-overlay'
  | 'md:bg-blend-overlay'
  | 'lg:bg-blend-overlay'
  | 'xl:bg-blend-overlay'
  | '2xl:bg-blend-overlay'
  | 'sm:bg-blend-darken'
  | 'md:bg-blend-darken'
  | 'lg:bg-blend-darken'
  | 'xl:bg-blend-darken'
  | '2xl:bg-blend-darken'
  | 'sm:bg-blend-lighten'
  | 'md:bg-blend-lighten'
  | 'lg:bg-blend-lighten'
  | 'xl:bg-blend-lighten'
  | '2xl:bg-blend-lighten'
  | 'sm:bg-blend-color-dodge'
  | 'md:bg-blend-color-dodge'
  | 'lg:bg-blend-color-dodge'
  | 'xl:bg-blend-color-dodge'
  | '2xl:bg-blend-color-dodge'
  | 'sm:bg-blend-color-burn'
  | 'md:bg-blend-color-burn'
  | 'lg:bg-blend-color-burn'
  | 'xl:bg-blend-color-burn'
  | '2xl:bg-blend-color-burn'
  | 'sm:bg-blend-hard-light'
  | 'md:bg-blend-hard-light'
  | 'lg:bg-blend-hard-light'
  | 'xl:bg-blend-hard-light'
  | '2xl:bg-blend-hard-light'
  | 'sm:bg-blend-soft-light'
  | 'md:bg-blend-soft-light'
  | 'lg:bg-blend-soft-light'
  | 'xl:bg-blend-soft-light'
  | '2xl:bg-blend-soft-light'
  | 'sm:bg-blend-difference'
  | 'md:bg-blend-difference'
  | 'lg:bg-blend-difference'
  | 'xl:bg-blend-difference'
  | '2xl:bg-blend-difference'
  | 'sm:bg-blend-exclusion'
  | 'md:bg-blend-exclusion'
  | 'lg:bg-blend-exclusion'
  | 'xl:bg-blend-exclusion'
  | '2xl:bg-blend-exclusion'
  | 'sm:bg-blend-hue'
  | 'md:bg-blend-hue'
  | 'lg:bg-blend-hue'
  | 'xl:bg-blend-hue'
  | '2xl:bg-blend-hue'
  | 'sm:bg-blend-saturation'
  | 'md:bg-blend-saturation'
  | 'lg:bg-blend-saturation'
  | 'xl:bg-blend-saturation'
  | '2xl:bg-blend-saturation'
  | 'sm:bg-blend-color'
  | 'md:bg-blend-color'
  | 'lg:bg-blend-color'
  | 'xl:bg-blend-color'
  | '2xl:bg-blend-color'
  | 'sm:bg-blend-luminosity'
  | 'md:bg-blend-luminosity'
  | 'lg:bg-blend-luminosity'
  | 'xl:bg-blend-luminosity'
  | '2xl:bg-blend-luminosity'
  | 'sm:bg-clip-border'
  | 'md:bg-clip-border'
  | 'lg:bg-clip-border'
  | 'xl:bg-clip-border'
  | '2xl:bg-clip-border'
  | 'sm:bg-clip-padding'
  | 'md:bg-clip-padding'
  | 'lg:bg-clip-padding'
  | 'xl:bg-clip-padding'
  | '2xl:bg-clip-padding'
  | 'sm:bg-clip-content'
  | 'md:bg-clip-content'
  | 'lg:bg-clip-content'
  | 'xl:bg-clip-content'
  | '2xl:bg-clip-content'
  | 'sm:bg-clip-text'
  | 'md:bg-clip-text'
  | 'lg:bg-clip-text'
  | 'xl:bg-clip-text'
  | '2xl:bg-clip-text'
  | 'sm:bg-transparent'
  | 'md:bg-transparent'
  | 'lg:bg-transparent'
  | 'xl:bg-transparent'
  | '2xl:bg-transparent'
  | 'group-hover:bg-transparent'
  | 'group'
  | 'focus-within:bg-transparent'
  | 'hover:bg-transparent'
  | 'focus:bg-transparent'
  | 'sm:bg-current'
  | 'md:bg-current'
  | 'lg:bg-current'
  | 'xl:bg-current'
  | '2xl:bg-current'
  | 'group-hover:bg-current'
  | 'focus-within:bg-current'
  | 'hover:bg-current'
  | 'focus:bg-current'
  | 'sm:bg-black'
  | 'md:bg-black'
  | 'lg:bg-black'
  | 'xl:bg-black'
  | '2xl:bg-black'
  | 'group-hover:bg-black'
  | 'focus-within:bg-black'
  | 'hover:bg-black'
  | 'focus:bg-black'
  | 'sm:bg-white'
  | 'md:bg-white'
  | 'lg:bg-white'
  | 'xl:bg-white'
  | '2xl:bg-white'
  | 'group-hover:bg-white'
  | 'focus-within:bg-white'
  | 'hover:bg-white'
  | 'focus:bg-white'
  | 'sm:bg-gray-50'
  | 'md:bg-gray-50'
  | 'lg:bg-gray-50'
  | 'xl:bg-gray-50'
  | '2xl:bg-gray-50'
  | 'group-hover:bg-gray-50'
  | 'focus-within:bg-gray-50'
  | 'hover:bg-gray-50'
  | 'focus:bg-gray-50'
  | 'sm:bg-gray-100'
  | 'md:bg-gray-100'
  | 'lg:bg-gray-100'
  | 'xl:bg-gray-100'
  | '2xl:bg-gray-100'
  | 'group-hover:bg-gray-100'
  | 'focus-within:bg-gray-100'
  | 'hover:bg-gray-100'
  | 'focus:bg-gray-100'
  | 'sm:bg-gray-200'
  | 'md:bg-gray-200'
  | 'lg:bg-gray-200'
  | 'xl:bg-gray-200'
  | '2xl:bg-gray-200'
  | 'group-hover:bg-gray-200'
  | 'focus-within:bg-gray-200'
  | 'hover:bg-gray-200'
  | 'focus:bg-gray-200'
  | 'sm:bg-gray-300'
  | 'md:bg-gray-300'
  | 'lg:bg-gray-300'
  | 'xl:bg-gray-300'
  | '2xl:bg-gray-300'
  | 'group-hover:bg-gray-300'
  | 'focus-within:bg-gray-300'
  | 'hover:bg-gray-300'
  | 'focus:bg-gray-300'
  | 'sm:bg-gray-400'
  | 'md:bg-gray-400'
  | 'lg:bg-gray-400'
  | 'xl:bg-gray-400'
  | '2xl:bg-gray-400'
  | 'group-hover:bg-gray-400'
  | 'focus-within:bg-gray-400'
  | 'hover:bg-gray-400'
  | 'focus:bg-gray-400'
  | 'sm:bg-gray-500'
  | 'md:bg-gray-500'
  | 'lg:bg-gray-500'
  | 'xl:bg-gray-500'
  | '2xl:bg-gray-500'
  | 'group-hover:bg-gray-500'
  | 'focus-within:bg-gray-500'
  | 'hover:bg-gray-500'
  | 'focus:bg-gray-500'
  | 'sm:bg-gray-600'
  | 'md:bg-gray-600'
  | 'lg:bg-gray-600'
  | 'xl:bg-gray-600'
  | '2xl:bg-gray-600'
  | 'group-hover:bg-gray-600'
  | 'focus-within:bg-gray-600'
  | 'hover:bg-gray-600'
  | 'focus:bg-gray-600'
  | 'sm:bg-gray-700'
  | 'md:bg-gray-700'
  | 'lg:bg-gray-700'
  | 'xl:bg-gray-700'
  | '2xl:bg-gray-700'
  | 'group-hover:bg-gray-700'
  | 'focus-within:bg-gray-700'
  | 'hover:bg-gray-700'
  | 'focus:bg-gray-700'
  | 'sm:bg-gray-800'
  | 'md:bg-gray-800'
  | 'lg:bg-gray-800'
  | 'xl:bg-gray-800'
  | '2xl:bg-gray-800'
  | 'group-hover:bg-gray-800'
  | 'focus-within:bg-gray-800'
  | 'hover:bg-gray-800'
  | 'focus:bg-gray-800'
  | 'sm:bg-gray-900'
  | 'md:bg-gray-900'
  | 'lg:bg-gray-900'
  | 'xl:bg-gray-900'
  | '2xl:bg-gray-900'
  | 'group-hover:bg-gray-900'
  | 'focus-within:bg-gray-900'
  | 'hover:bg-gray-900'
  | 'focus:bg-gray-900'
  | 'sm:bg-red-50'
  | 'md:bg-red-50'
  | 'lg:bg-red-50'
  | 'xl:bg-red-50'
  | '2xl:bg-red-50'
  | 'group-hover:bg-red-50'
  | 'focus-within:bg-red-50'
  | 'hover:bg-red-50'
  | 'focus:bg-red-50'
  | 'sm:bg-red-100'
  | 'md:bg-red-100'
  | 'lg:bg-red-100'
  | 'xl:bg-red-100'
  | '2xl:bg-red-100'
  | 'group-hover:bg-red-100'
  | 'focus-within:bg-red-100'
  | 'hover:bg-red-100'
  | 'focus:bg-red-100'
  | 'sm:bg-red-200'
  | 'md:bg-red-200'
  | 'lg:bg-red-200'
  | 'xl:bg-red-200'
  | '2xl:bg-red-200'
  | 'group-hover:bg-red-200'
  | 'focus-within:bg-red-200'
  | 'hover:bg-red-200'
  | 'focus:bg-red-200'
  | 'sm:bg-red-300'
  | 'md:bg-red-300'
  | 'lg:bg-red-300'
  | 'xl:bg-red-300'
  | '2xl:bg-red-300'
  | 'group-hover:bg-red-300'
  | 'focus-within:bg-red-300'
  | 'hover:bg-red-300'
  | 'focus:bg-red-300'
  | 'sm:bg-red-400'
  | 'md:bg-red-400'
  | 'lg:bg-red-400'
  | 'xl:bg-red-400'
  | '2xl:bg-red-400'
  | 'group-hover:bg-red-400'
  | 'focus-within:bg-red-400'
  | 'hover:bg-red-400'
  | 'focus:bg-red-400'
  | 'sm:bg-red-500'
  | 'md:bg-red-500'
  | 'lg:bg-red-500'
  | 'xl:bg-red-500'
  | '2xl:bg-red-500'
  | 'group-hover:bg-red-500'
  | 'focus-within:bg-red-500'
  | 'hover:bg-red-500'
  | 'focus:bg-red-500'
  | 'sm:bg-red-600'
  | 'md:bg-red-600'
  | 'lg:bg-red-600'
  | 'xl:bg-red-600'
  | '2xl:bg-red-600'
  | 'group-hover:bg-red-600'
  | 'focus-within:bg-red-600'
  | 'hover:bg-red-600'
  | 'focus:bg-red-600'
  | 'sm:bg-red-700'
  | 'md:bg-red-700'
  | 'lg:bg-red-700'
  | 'xl:bg-red-700'
  | '2xl:bg-red-700'
  | 'group-hover:bg-red-700'
  | 'focus-within:bg-red-700'
  | 'hover:bg-red-700'
  | 'focus:bg-red-700'
  | 'sm:bg-red-800'
  | 'md:bg-red-800'
  | 'lg:bg-red-800'
  | 'xl:bg-red-800'
  | '2xl:bg-red-800'
  | 'group-hover:bg-red-800'
  | 'focus-within:bg-red-800'
  | 'hover:bg-red-800'
  | 'focus:bg-red-800'
  | 'sm:bg-red-900'
  | 'md:bg-red-900'
  | 'lg:bg-red-900'
  | 'xl:bg-red-900'
  | '2xl:bg-red-900'
  | 'group-hover:bg-red-900'
  | 'focus-within:bg-red-900'
  | 'hover:bg-red-900'
  | 'focus:bg-red-900'
  | 'sm:bg-yellow-50'
  | 'md:bg-yellow-50'
  | 'lg:bg-yellow-50'
  | 'xl:bg-yellow-50'
  | '2xl:bg-yellow-50'
  | 'group-hover:bg-yellow-50'
  | 'focus-within:bg-yellow-50'
  | 'hover:bg-yellow-50'
  | 'focus:bg-yellow-50'
  | 'sm:bg-yellow-100'
  | 'md:bg-yellow-100'
  | 'lg:bg-yellow-100'
  | 'xl:bg-yellow-100'
  | '2xl:bg-yellow-100'
  | 'group-hover:bg-yellow-100'
  | 'focus-within:bg-yellow-100'
  | 'hover:bg-yellow-100'
  | 'focus:bg-yellow-100'
  | 'sm:bg-yellow-200'
  | 'md:bg-yellow-200'
  | 'lg:bg-yellow-200'
  | 'xl:bg-yellow-200'
  | '2xl:bg-yellow-200'
  | 'group-hover:bg-yellow-200'
  | 'focus-within:bg-yellow-200'
  | 'hover:bg-yellow-200'
  | 'focus:bg-yellow-200'
  | 'sm:bg-yellow-300'
  | 'md:bg-yellow-300'
  | 'lg:bg-yellow-300'
  | 'xl:bg-yellow-300'
  | '2xl:bg-yellow-300'
  | 'group-hover:bg-yellow-300'
  | 'focus-within:bg-yellow-300'
  | 'hover:bg-yellow-300'
  | 'focus:bg-yellow-300'
  | 'sm:bg-yellow-400'
  | 'md:bg-yellow-400'
  | 'lg:bg-yellow-400'
  | 'xl:bg-yellow-400'
  | '2xl:bg-yellow-400'
  | 'group-hover:bg-yellow-400'
  | 'focus-within:bg-yellow-400'
  | 'hover:bg-yellow-400'
  | 'focus:bg-yellow-400'
  | 'sm:bg-yellow-500'
  | 'md:bg-yellow-500'
  | 'lg:bg-yellow-500'
  | 'xl:bg-yellow-500'
  | '2xl:bg-yellow-500'
  | 'group-hover:bg-yellow-500'
  | 'focus-within:bg-yellow-500'
  | 'hover:bg-yellow-500'
  | 'focus:bg-yellow-500'
  | 'sm:bg-yellow-600'
  | 'md:bg-yellow-600'
  | 'lg:bg-yellow-600'
  | 'xl:bg-yellow-600'
  | '2xl:bg-yellow-600'
  | 'group-hover:bg-yellow-600'
  | 'focus-within:bg-yellow-600'
  | 'hover:bg-yellow-600'
  | 'focus:bg-yellow-600'
  | 'sm:bg-yellow-700'
  | 'md:bg-yellow-700'
  | 'lg:bg-yellow-700'
  | 'xl:bg-yellow-700'
  | '2xl:bg-yellow-700'
  | 'group-hover:bg-yellow-700'
  | 'focus-within:bg-yellow-700'
  | 'hover:bg-yellow-700'
  | 'focus:bg-yellow-700'
  | 'sm:bg-yellow-800'
  | 'md:bg-yellow-800'
  | 'lg:bg-yellow-800'
  | 'xl:bg-yellow-800'
  | '2xl:bg-yellow-800'
  | 'group-hover:bg-yellow-800'
  | 'focus-within:bg-yellow-800'
  | 'hover:bg-yellow-800'
  | 'focus:bg-yellow-800'
  | 'sm:bg-yellow-900'
  | 'md:bg-yellow-900'
  | 'lg:bg-yellow-900'
  | 'xl:bg-yellow-900'
  | '2xl:bg-yellow-900'
  | 'group-hover:bg-yellow-900'
  | 'focus-within:bg-yellow-900'
  | 'hover:bg-yellow-900'
  | 'focus:bg-yellow-900'
  | 'sm:bg-green-50'
  | 'md:bg-green-50'
  | 'lg:bg-green-50'
  | 'xl:bg-green-50'
  | '2xl:bg-green-50'
  | 'group-hover:bg-green-50'
  | 'focus-within:bg-green-50'
  | 'hover:bg-green-50'
  | 'focus:bg-green-50'
  | 'sm:bg-green-100'
  | 'md:bg-green-100'
  | 'lg:bg-green-100'
  | 'xl:bg-green-100'
  | '2xl:bg-green-100'
  | 'group-hover:bg-green-100'
  | 'focus-within:bg-green-100'
  | 'hover:bg-green-100'
  | 'focus:bg-green-100'
  | 'sm:bg-green-200'
  | 'md:bg-green-200'
  | 'lg:bg-green-200'
  | 'xl:bg-green-200'
  | '2xl:bg-green-200'
  | 'group-hover:bg-green-200'
  | 'focus-within:bg-green-200'
  | 'hover:bg-green-200'
  | 'focus:bg-green-200'
  | 'sm:bg-green-300'
  | 'md:bg-green-300'
  | 'lg:bg-green-300'
  | 'xl:bg-green-300'
  | '2xl:bg-green-300'
  | 'group-hover:bg-green-300'
  | 'focus-within:bg-green-300'
  | 'hover:bg-green-300'
  | 'focus:bg-green-300'
  | 'sm:bg-green-400'
  | 'md:bg-green-400'
  | 'lg:bg-green-400'
  | 'xl:bg-green-400'
  | '2xl:bg-green-400'
  | 'group-hover:bg-green-400'
  | 'focus-within:bg-green-400'
  | 'hover:bg-green-400'
  | 'focus:bg-green-400'
  | 'sm:bg-green-500'
  | 'md:bg-green-500'
  | 'lg:bg-green-500'
  | 'xl:bg-green-500'
  | '2xl:bg-green-500'
  | 'group-hover:bg-green-500'
  | 'focus-within:bg-green-500'
  | 'hover:bg-green-500'
  | 'focus:bg-green-500'
  | 'sm:bg-green-600'
  | 'md:bg-green-600'
  | 'lg:bg-green-600'
  | 'xl:bg-green-600'
  | '2xl:bg-green-600'
  | 'group-hover:bg-green-600'
  | 'focus-within:bg-green-600'
  | 'hover:bg-green-600'
  | 'focus:bg-green-600'
  | 'sm:bg-green-700'
  | 'md:bg-green-700'
  | 'lg:bg-green-700'
  | 'xl:bg-green-700'
  | '2xl:bg-green-700'
  | 'group-hover:bg-green-700'
  | 'focus-within:bg-green-700'
  | 'hover:bg-green-700'
  | 'focus:bg-green-700'
  | 'sm:bg-green-800'
  | 'md:bg-green-800'
  | 'lg:bg-green-800'
  | 'xl:bg-green-800'
  | '2xl:bg-green-800'
  | 'group-hover:bg-green-800'
  | 'focus-within:bg-green-800'
  | 'hover:bg-green-800'
  | 'focus:bg-green-800'
  | 'sm:bg-green-900'
  | 'md:bg-green-900'
  | 'lg:bg-green-900'
  | 'xl:bg-green-900'
  | '2xl:bg-green-900'
  | 'group-hover:bg-green-900'
  | 'focus-within:bg-green-900'
  | 'hover:bg-green-900'
  | 'focus:bg-green-900'
  | 'sm:bg-blue-50'
  | 'md:bg-blue-50'
  | 'lg:bg-blue-50'
  | 'xl:bg-blue-50'
  | '2xl:bg-blue-50'
  | 'group-hover:bg-blue-50'
  | 'focus-within:bg-blue-50'
  | 'hover:bg-blue-50'
  | 'focus:bg-blue-50'
  | 'sm:bg-blue-100'
  | 'md:bg-blue-100'
  | 'lg:bg-blue-100'
  | 'xl:bg-blue-100'
  | '2xl:bg-blue-100'
  | 'group-hover:bg-blue-100'
  | 'focus-within:bg-blue-100'
  | 'hover:bg-blue-100'
  | 'focus:bg-blue-100'
  | 'sm:bg-blue-200'
  | 'md:bg-blue-200'
  | 'lg:bg-blue-200'
  | 'xl:bg-blue-200'
  | '2xl:bg-blue-200'
  | 'group-hover:bg-blue-200'
  | 'focus-within:bg-blue-200'
  | 'hover:bg-blue-200'
  | 'focus:bg-blue-200'
  | 'sm:bg-blue-300'
  | 'md:bg-blue-300'
  | 'lg:bg-blue-300'
  | 'xl:bg-blue-300'
  | '2xl:bg-blue-300'
  | 'group-hover:bg-blue-300'
  | 'focus-within:bg-blue-300'
  | 'hover:bg-blue-300'
  | 'focus:bg-blue-300'
  | 'sm:bg-blue-400'
  | 'md:bg-blue-400'
  | 'lg:bg-blue-400'
  | 'xl:bg-blue-400'
  | '2xl:bg-blue-400'
  | 'group-hover:bg-blue-400'
  | 'focus-within:bg-blue-400'
  | 'hover:bg-blue-400'
  | 'focus:bg-blue-400'
  | 'sm:bg-blue-500'
  | 'md:bg-blue-500'
  | 'lg:bg-blue-500'
  | 'xl:bg-blue-500'
  | '2xl:bg-blue-500'
  | 'group-hover:bg-blue-500'
  | 'focus-within:bg-blue-500'
  | 'hover:bg-blue-500'
  | 'focus:bg-blue-500'
  | 'sm:bg-blue-600'
  | 'md:bg-blue-600'
  | 'lg:bg-blue-600'
  | 'xl:bg-blue-600'
  | '2xl:bg-blue-600'
  | 'group-hover:bg-blue-600'
  | 'focus-within:bg-blue-600'
  | 'hover:bg-blue-600'
  | 'focus:bg-blue-600'
  | 'sm:bg-blue-700'
  | 'md:bg-blue-700'
  | 'lg:bg-blue-700'
  | 'xl:bg-blue-700'
  | '2xl:bg-blue-700'
  | 'group-hover:bg-blue-700'
  | 'focus-within:bg-blue-700'
  | 'hover:bg-blue-700'
  | 'focus:bg-blue-700'
  | 'sm:bg-blue-800'
  | 'md:bg-blue-800'
  | 'lg:bg-blue-800'
  | 'xl:bg-blue-800'
  | '2xl:bg-blue-800'
  | 'group-hover:bg-blue-800'
  | 'focus-within:bg-blue-800'
  | 'hover:bg-blue-800'
  | 'focus:bg-blue-800'
  | 'sm:bg-blue-900'
  | 'md:bg-blue-900'
  | 'lg:bg-blue-900'
  | 'xl:bg-blue-900'
  | '2xl:bg-blue-900'
  | 'group-hover:bg-blue-900'
  | 'focus-within:bg-blue-900'
  | 'hover:bg-blue-900'
  | 'focus:bg-blue-900'
  | 'sm:bg-indigo-50'
  | 'md:bg-indigo-50'
  | 'lg:bg-indigo-50'
  | 'xl:bg-indigo-50'
  | '2xl:bg-indigo-50'
  | 'group-hover:bg-indigo-50'
  | 'focus-within:bg-indigo-50'
  | 'hover:bg-indigo-50'
  | 'focus:bg-indigo-50'
  | 'sm:bg-indigo-100'
  | 'md:bg-indigo-100'
  | 'lg:bg-indigo-100'
  | 'xl:bg-indigo-100'
  | '2xl:bg-indigo-100'
  | 'group-hover:bg-indigo-100'
  | 'focus-within:bg-indigo-100'
  | 'hover:bg-indigo-100'
  | 'focus:bg-indigo-100'
  | 'sm:bg-indigo-200'
  | 'md:bg-indigo-200'
  | 'lg:bg-indigo-200'
  | 'xl:bg-indigo-200'
  | '2xl:bg-indigo-200'
  | 'group-hover:bg-indigo-200'
  | 'focus-within:bg-indigo-200'
  | 'hover:bg-indigo-200'
  | 'focus:bg-indigo-200'
  | 'sm:bg-indigo-300'
  | 'md:bg-indigo-300'
  | 'lg:bg-indigo-300'
  | 'xl:bg-indigo-300'
  | '2xl:bg-indigo-300'
  | 'group-hover:bg-indigo-300'
  | 'focus-within:bg-indigo-300'
  | 'hover:bg-indigo-300'
  | 'focus:bg-indigo-300'
  | 'sm:bg-indigo-400'
  | 'md:bg-indigo-400'
  | 'lg:bg-indigo-400'
  | 'xl:bg-indigo-400'
  | '2xl:bg-indigo-400'
  | 'group-hover:bg-indigo-400'
  | 'focus-within:bg-indigo-400'
  | 'hover:bg-indigo-400'
  | 'focus:bg-indigo-400'
  | 'sm:bg-indigo-500'
  | 'md:bg-indigo-500'
  | 'lg:bg-indigo-500'
  | 'xl:bg-indigo-500'
  | '2xl:bg-indigo-500'
  | 'group-hover:bg-indigo-500'
  | 'focus-within:bg-indigo-500'
  | 'hover:bg-indigo-500'
  | 'focus:bg-indigo-500'
  | 'sm:bg-indigo-600'
  | 'md:bg-indigo-600'
  | 'lg:bg-indigo-600'
  | 'xl:bg-indigo-600'
  | '2xl:bg-indigo-600'
  | 'group-hover:bg-indigo-600'
  | 'focus-within:bg-indigo-600'
  | 'hover:bg-indigo-600'
  | 'focus:bg-indigo-600'
  | 'sm:bg-indigo-700'
  | 'md:bg-indigo-700'
  | 'lg:bg-indigo-700'
  | 'xl:bg-indigo-700'
  | '2xl:bg-indigo-700'
  | 'group-hover:bg-indigo-700'
  | 'focus-within:bg-indigo-700'
  | 'hover:bg-indigo-700'
  | 'focus:bg-indigo-700'
  | 'sm:bg-indigo-800'
  | 'md:bg-indigo-800'
  | 'lg:bg-indigo-800'
  | 'xl:bg-indigo-800'
  | '2xl:bg-indigo-800'
  | 'group-hover:bg-indigo-800'
  | 'focus-within:bg-indigo-800'
  | 'hover:bg-indigo-800'
  | 'focus:bg-indigo-800'
  | 'sm:bg-indigo-900'
  | 'md:bg-indigo-900'
  | 'lg:bg-indigo-900'
  | 'xl:bg-indigo-900'
  | '2xl:bg-indigo-900'
  | 'group-hover:bg-indigo-900'
  | 'focus-within:bg-indigo-900'
  | 'hover:bg-indigo-900'
  | 'focus:bg-indigo-900'
  | 'sm:bg-purple-50'
  | 'md:bg-purple-50'
  | 'lg:bg-purple-50'
  | 'xl:bg-purple-50'
  | '2xl:bg-purple-50'
  | 'group-hover:bg-purple-50'
  | 'focus-within:bg-purple-50'
  | 'hover:bg-purple-50'
  | 'focus:bg-purple-50'
  | 'sm:bg-purple-100'
  | 'md:bg-purple-100'
  | 'lg:bg-purple-100'
  | 'xl:bg-purple-100'
  | '2xl:bg-purple-100'
  | 'group-hover:bg-purple-100'
  | 'focus-within:bg-purple-100'
  | 'hover:bg-purple-100'
  | 'focus:bg-purple-100'
  | 'sm:bg-purple-200'
  | 'md:bg-purple-200'
  | 'lg:bg-purple-200'
  | 'xl:bg-purple-200'
  | '2xl:bg-purple-200'
  | 'group-hover:bg-purple-200'
  | 'focus-within:bg-purple-200'
  | 'hover:bg-purple-200'
  | 'focus:bg-purple-200'
  | 'sm:bg-purple-300'
  | 'md:bg-purple-300'
  | 'lg:bg-purple-300'
  | 'xl:bg-purple-300'
  | '2xl:bg-purple-300'
  | 'group-hover:bg-purple-300'
  | 'focus-within:bg-purple-300'
  | 'hover:bg-purple-300'
  | 'focus:bg-purple-300'
  | 'sm:bg-purple-400'
  | 'md:bg-purple-400'
  | 'lg:bg-purple-400'
  | 'xl:bg-purple-400'
  | '2xl:bg-purple-400'
  | 'group-hover:bg-purple-400'
  | 'focus-within:bg-purple-400'
  | 'hover:bg-purple-400'
  | 'focus:bg-purple-400'
  | 'sm:bg-purple-500'
  | 'md:bg-purple-500'
  | 'lg:bg-purple-500'
  | 'xl:bg-purple-500'
  | '2xl:bg-purple-500'
  | 'group-hover:bg-purple-500'
  | 'focus-within:bg-purple-500'
  | 'hover:bg-purple-500'
  | 'focus:bg-purple-500'
  | 'sm:bg-purple-600'
  | 'md:bg-purple-600'
  | 'lg:bg-purple-600'
  | 'xl:bg-purple-600'
  | '2xl:bg-purple-600'
  | 'group-hover:bg-purple-600'
  | 'focus-within:bg-purple-600'
  | 'hover:bg-purple-600'
  | 'focus:bg-purple-600'
  | 'sm:bg-purple-700'
  | 'md:bg-purple-700'
  | 'lg:bg-purple-700'
  | 'xl:bg-purple-700'
  | '2xl:bg-purple-700'
  | 'group-hover:bg-purple-700'
  | 'focus-within:bg-purple-700'
  | 'hover:bg-purple-700'
  | 'focus:bg-purple-700'
  | 'sm:bg-purple-800'
  | 'md:bg-purple-800'
  | 'lg:bg-purple-800'
  | 'xl:bg-purple-800'
  | '2xl:bg-purple-800'
  | 'group-hover:bg-purple-800'
  | 'focus-within:bg-purple-800'
  | 'hover:bg-purple-800'
  | 'focus:bg-purple-800'
  | 'sm:bg-purple-900'
  | 'md:bg-purple-900'
  | 'lg:bg-purple-900'
  | 'xl:bg-purple-900'
  | '2xl:bg-purple-900'
  | 'group-hover:bg-purple-900'
  | 'focus-within:bg-purple-900'
  | 'hover:bg-purple-900'
  | 'focus:bg-purple-900'
  | 'sm:bg-pink-50'
  | 'md:bg-pink-50'
  | 'lg:bg-pink-50'
  | 'xl:bg-pink-50'
  | '2xl:bg-pink-50'
  | 'group-hover:bg-pink-50'
  | 'focus-within:bg-pink-50'
  | 'hover:bg-pink-50'
  | 'focus:bg-pink-50'
  | 'sm:bg-pink-100'
  | 'md:bg-pink-100'
  | 'lg:bg-pink-100'
  | 'xl:bg-pink-100'
  | '2xl:bg-pink-100'
  | 'group-hover:bg-pink-100'
  | 'focus-within:bg-pink-100'
  | 'hover:bg-pink-100'
  | 'focus:bg-pink-100'
  | 'sm:bg-pink-200'
  | 'md:bg-pink-200'
  | 'lg:bg-pink-200'
  | 'xl:bg-pink-200'
  | '2xl:bg-pink-200'
  | 'group-hover:bg-pink-200'
  | 'focus-within:bg-pink-200'
  | 'hover:bg-pink-200'
  | 'focus:bg-pink-200'
  | 'sm:bg-pink-300'
  | 'md:bg-pink-300'
  | 'lg:bg-pink-300'
  | 'xl:bg-pink-300'
  | '2xl:bg-pink-300'
  | 'group-hover:bg-pink-300'
  | 'focus-within:bg-pink-300'
  | 'hover:bg-pink-300'
  | 'focus:bg-pink-300'
  | 'sm:bg-pink-400'
  | 'md:bg-pink-400'
  | 'lg:bg-pink-400'
  | 'xl:bg-pink-400'
  | '2xl:bg-pink-400'
  | 'group-hover:bg-pink-400'
  | 'focus-within:bg-pink-400'
  | 'hover:bg-pink-400'
  | 'focus:bg-pink-400'
  | 'sm:bg-pink-500'
  | 'md:bg-pink-500'
  | 'lg:bg-pink-500'
  | 'xl:bg-pink-500'
  | '2xl:bg-pink-500'
  | 'group-hover:bg-pink-500'
  | 'focus-within:bg-pink-500'
  | 'hover:bg-pink-500'
  | 'focus:bg-pink-500'
  | 'sm:bg-pink-600'
  | 'md:bg-pink-600'
  | 'lg:bg-pink-600'
  | 'xl:bg-pink-600'
  | '2xl:bg-pink-600'
  | 'group-hover:bg-pink-600'
  | 'focus-within:bg-pink-600'
  | 'hover:bg-pink-600'
  | 'focus:bg-pink-600'
  | 'sm:bg-pink-700'
  | 'md:bg-pink-700'
  | 'lg:bg-pink-700'
  | 'xl:bg-pink-700'
  | '2xl:bg-pink-700'
  | 'group-hover:bg-pink-700'
  | 'focus-within:bg-pink-700'
  | 'hover:bg-pink-700'
  | 'focus:bg-pink-700'
  | 'sm:bg-pink-800'
  | 'md:bg-pink-800'
  | 'lg:bg-pink-800'
  | 'xl:bg-pink-800'
  | '2xl:bg-pink-800'
  | 'group-hover:bg-pink-800'
  | 'focus-within:bg-pink-800'
  | 'hover:bg-pink-800'
  | 'focus:bg-pink-800'
  | 'sm:bg-pink-900'
  | 'md:bg-pink-900'
  | 'lg:bg-pink-900'
  | 'xl:bg-pink-900'
  | '2xl:bg-pink-900'
  | 'group-hover:bg-pink-900'
  | 'focus-within:bg-pink-900'
  | 'hover:bg-pink-900'
  | 'focus:bg-pink-900'
  | 'sm:bg-none'
  | 'md:bg-none'
  | 'lg:bg-none'
  | 'xl:bg-none'
  | '2xl:bg-none'
  | 'sm:bg-gradient-to-t'
  | 'md:bg-gradient-to-t'
  | 'lg:bg-gradient-to-t'
  | 'xl:bg-gradient-to-t'
  | '2xl:bg-gradient-to-t'
  | 'sm:bg-gradient-to-tr'
  | 'md:bg-gradient-to-tr'
  | 'lg:bg-gradient-to-tr'
  | 'xl:bg-gradient-to-tr'
  | '2xl:bg-gradient-to-tr'
  | 'sm:bg-gradient-to-r'
  | 'md:bg-gradient-to-r'
  | 'lg:bg-gradient-to-r'
  | 'xl:bg-gradient-to-r'
  | '2xl:bg-gradient-to-r'
  | 'sm:bg-gradient-to-br'
  | 'md:bg-gradient-to-br'
  | 'lg:bg-gradient-to-br'
  | 'xl:bg-gradient-to-br'
  | '2xl:bg-gradient-to-br'
  | 'sm:bg-gradient-to-b'
  | 'md:bg-gradient-to-b'
  | 'lg:bg-gradient-to-b'
  | 'xl:bg-gradient-to-b'
  | '2xl:bg-gradient-to-b'
  | 'sm:bg-gradient-to-bl'
  | 'md:bg-gradient-to-bl'
  | 'lg:bg-gradient-to-bl'
  | 'xl:bg-gradient-to-bl'
  | '2xl:bg-gradient-to-bl'
  | 'sm:bg-gradient-to-l'
  | 'md:bg-gradient-to-l'
  | 'lg:bg-gradient-to-l'
  | 'xl:bg-gradient-to-l'
  | '2xl:bg-gradient-to-l'
  | 'sm:bg-gradient-to-tl'
  | 'md:bg-gradient-to-tl'
  | 'lg:bg-gradient-to-tl'
  | 'xl:bg-gradient-to-tl'
  | '2xl:bg-gradient-to-tl'
  | 'sm:bg-opacity-0'
  | 'md:bg-opacity-0'
  | 'lg:bg-opacity-0'
  | 'xl:bg-opacity-0'
  | '2xl:bg-opacity-0'
  | 'group-hover:bg-opacity-0'
  | 'focus-within:bg-opacity-0'
  | 'hover:bg-opacity-0'
  | 'focus:bg-opacity-0'
  | 'sm:bg-opacity-5'
  | 'md:bg-opacity-5'
  | 'lg:bg-opacity-5'
  | 'xl:bg-opacity-5'
  | '2xl:bg-opacity-5'
  | 'group-hover:bg-opacity-5'
  | 'focus-within:bg-opacity-5'
  | 'hover:bg-opacity-5'
  | 'focus:bg-opacity-5'
  | 'sm:bg-opacity-10'
  | 'md:bg-opacity-10'
  | 'lg:bg-opacity-10'
  | 'xl:bg-opacity-10'
  | '2xl:bg-opacity-10'
  | 'group-hover:bg-opacity-10'
  | 'focus-within:bg-opacity-10'
  | 'hover:bg-opacity-10'
  | 'focus:bg-opacity-10'
  | 'sm:bg-opacity-20'
  | 'md:bg-opacity-20'
  | 'lg:bg-opacity-20'
  | 'xl:bg-opacity-20'
  | '2xl:bg-opacity-20'
  | 'group-hover:bg-opacity-20'
  | 'focus-within:bg-opacity-20'
  | 'hover:bg-opacity-20'
  | 'focus:bg-opacity-20'
  | 'sm:bg-opacity-25'
  | 'md:bg-opacity-25'
  | 'lg:bg-opacity-25'
  | 'xl:bg-opacity-25'
  | '2xl:bg-opacity-25'
  | 'group-hover:bg-opacity-25'
  | 'focus-within:bg-opacity-25'
  | 'hover:bg-opacity-25'
  | 'focus:bg-opacity-25'
  | 'sm:bg-opacity-30'
  | 'md:bg-opacity-30'
  | 'lg:bg-opacity-30'
  | 'xl:bg-opacity-30'
  | '2xl:bg-opacity-30'
  | 'group-hover:bg-opacity-30'
  | 'focus-within:bg-opacity-30'
  | 'hover:bg-opacity-30'
  | 'focus:bg-opacity-30'
  | 'sm:bg-opacity-40'
  | 'md:bg-opacity-40'
  | 'lg:bg-opacity-40'
  | 'xl:bg-opacity-40'
  | '2xl:bg-opacity-40'
  | 'group-hover:bg-opacity-40'
  | 'focus-within:bg-opacity-40'
  | 'hover:bg-opacity-40'
  | 'focus:bg-opacity-40'
  | 'sm:bg-opacity-50'
  | 'md:bg-opacity-50'
  | 'lg:bg-opacity-50'
  | 'xl:bg-opacity-50'
  | '2xl:bg-opacity-50'
  | 'group-hover:bg-opacity-50'
  | 'focus-within:bg-opacity-50'
  | 'hover:bg-opacity-50'
  | 'focus:bg-opacity-50'
  | 'sm:bg-opacity-60'
  | 'md:bg-opacity-60'
  | 'lg:bg-opacity-60'
  | 'xl:bg-opacity-60'
  | '2xl:bg-opacity-60'
  | 'group-hover:bg-opacity-60'
  | 'focus-within:bg-opacity-60'
  | 'hover:bg-opacity-60'
  | 'focus:bg-opacity-60'
  | 'sm:bg-opacity-70'
  | 'md:bg-opacity-70'
  | 'lg:bg-opacity-70'
  | 'xl:bg-opacity-70'
  | '2xl:bg-opacity-70'
  | 'group-hover:bg-opacity-70'
  | 'focus-within:bg-opacity-70'
  | 'hover:bg-opacity-70'
  | 'focus:bg-opacity-70'
  | 'sm:bg-opacity-75'
  | 'md:bg-opacity-75'
  | 'lg:bg-opacity-75'
  | 'xl:bg-opacity-75'
  | '2xl:bg-opacity-75'
  | 'group-hover:bg-opacity-75'
  | 'focus-within:bg-opacity-75'
  | 'hover:bg-opacity-75'
  | 'focus:bg-opacity-75'
  | 'sm:bg-opacity-80'
  | 'md:bg-opacity-80'
  | 'lg:bg-opacity-80'
  | 'xl:bg-opacity-80'
  | '2xl:bg-opacity-80'
  | 'group-hover:bg-opacity-80'
  | 'focus-within:bg-opacity-80'
  | 'hover:bg-opacity-80'
  | 'focus:bg-opacity-80'
  | 'sm:bg-opacity-90'
  | 'md:bg-opacity-90'
  | 'lg:bg-opacity-90'
  | 'xl:bg-opacity-90'
  | '2xl:bg-opacity-90'
  | 'group-hover:bg-opacity-90'
  | 'focus-within:bg-opacity-90'
  | 'hover:bg-opacity-90'
  | 'focus:bg-opacity-90'
  | 'sm:bg-opacity-95'
  | 'md:bg-opacity-95'
  | 'lg:bg-opacity-95'
  | 'xl:bg-opacity-95'
  | '2xl:bg-opacity-95'
  | 'group-hover:bg-opacity-95'
  | 'focus-within:bg-opacity-95'
  | 'hover:bg-opacity-95'
  | 'focus:bg-opacity-95'
  | 'sm:bg-opacity-100'
  | 'md:bg-opacity-100'
  | 'lg:bg-opacity-100'
  | 'xl:bg-opacity-100'
  | '2xl:bg-opacity-100'
  | 'group-hover:bg-opacity-100'
  | 'focus-within:bg-opacity-100'
  | 'hover:bg-opacity-100'
  | 'focus:bg-opacity-100'
  | 'sm:bg-bottom'
  | 'md:bg-bottom'
  | 'lg:bg-bottom'
  | 'xl:bg-bottom'
  | '2xl:bg-bottom'
  | 'sm:bg-center'
  | 'md:bg-center'
  | 'lg:bg-center'
  | 'xl:bg-center'
  | '2xl:bg-center'
  | 'sm:bg-left'
  | 'md:bg-left'
  | 'lg:bg-left'
  | 'xl:bg-left'
  | '2xl:bg-left'
  | 'sm:bg-left-bottom'
  | 'md:bg-left-bottom'
  | 'lg:bg-left-bottom'
  | 'xl:bg-left-bottom'
  | '2xl:bg-left-bottom'
  | 'sm:bg-left-top'
  | 'md:bg-left-top'
  | 'lg:bg-left-top'
  | 'xl:bg-left-top'
  | '2xl:bg-left-top'
  | 'sm:bg-right'
  | 'md:bg-right'
  | 'lg:bg-right'
  | 'xl:bg-right'
  | '2xl:bg-right'
  | 'sm:bg-right-bottom'
  | 'md:bg-right-bottom'
  | 'lg:bg-right-bottom'
  | 'xl:bg-right-bottom'
  | '2xl:bg-right-bottom'
  | 'sm:bg-right-top'
  | 'md:bg-right-top'
  | 'lg:bg-right-top'
  | 'xl:bg-right-top'
  | '2xl:bg-right-top'
  | 'sm:bg-top'
  | 'md:bg-top'
  | 'lg:bg-top'
  | 'xl:bg-top'
  | '2xl:bg-top'
  | 'sm:bg-repeat'
  | 'md:bg-repeat'
  | 'lg:bg-repeat'
  | 'xl:bg-repeat'
  | '2xl:bg-repeat'
  | 'sm:bg-no-repeat'
  | 'md:bg-no-repeat'
  | 'lg:bg-no-repeat'
  | 'xl:bg-no-repeat'
  | '2xl:bg-no-repeat'
  | 'sm:bg-repeat-x'
  | 'md:bg-repeat-x'
  | 'lg:bg-repeat-x'
  | 'xl:bg-repeat-x'
  | '2xl:bg-repeat-x'
  | 'sm:bg-repeat-y'
  | 'md:bg-repeat-y'
  | 'lg:bg-repeat-y'
  | 'xl:bg-repeat-y'
  | '2xl:bg-repeat-y'
  | 'sm:bg-repeat-round'
  | 'md:bg-repeat-round'
  | 'lg:bg-repeat-round'
  | 'xl:bg-repeat-round'
  | '2xl:bg-repeat-round'
  | 'sm:bg-repeat-space'
  | 'md:bg-repeat-space'
  | 'lg:bg-repeat-space'
  | 'xl:bg-repeat-space'
  | '2xl:bg-repeat-space'
  | 'sm:bg-auto'
  | 'md:bg-auto'
  | 'lg:bg-auto'
  | 'xl:bg-auto'
  | '2xl:bg-auto'
  | 'sm:bg-cover'
  | 'md:bg-cover'
  | 'lg:bg-cover'
  | 'xl:bg-cover'
  | '2xl:bg-cover'
  | 'sm:bg-contain'
  | 'md:bg-contain'
  | 'lg:bg-contain'
  | 'xl:bg-contain'
  | '2xl:bg-contain'
  | 'sm:bg-origin-border'
  | 'md:bg-origin-border'
  | 'lg:bg-origin-border'
  | 'xl:bg-origin-border'
  | '2xl:bg-origin-border'
  | 'sm:bg-origin-padding'
  | 'md:bg-origin-padding'
  | 'lg:bg-origin-padding'
  | 'xl:bg-origin-padding'
  | '2xl:bg-origin-padding'
  | 'sm:bg-origin-content'
  | 'md:bg-origin-content'
  | 'lg:bg-origin-content'
  | 'xl:bg-origin-content'
  | '2xl:bg-origin-content'
  | 'sm:blur-0'
  | 'md:blur-0'
  | 'lg:blur-0'
  | 'xl:blur-0'
  | '2xl:blur-0'
  | 'sm:blur-none'
  | 'md:blur-none'
  | 'lg:blur-none'
  | 'xl:blur-none'
  | '2xl:blur-none'
  | 'sm:blur-sm'
  | 'md:blur-sm'
  | 'lg:blur-sm'
  | 'xl:blur-sm'
  | '2xl:blur-sm'
  | 'sm:blur'
  | 'md:blur'
  | 'lg:blur'
  | 'xl:blur'
  | '2xl:blur'
  | 'sm:blur-md'
  | 'md:blur-md'
  | 'lg:blur-md'
  | 'xl:blur-md'
  | '2xl:blur-md'
  | 'sm:blur-lg'
  | 'md:blur-lg'
  | 'lg:blur-lg'
  | 'xl:blur-lg'
  | '2xl:blur-lg'
  | 'sm:blur-xl'
  | 'md:blur-xl'
  | 'lg:blur-xl'
  | 'xl:blur-xl'
  | '2xl:blur-xl'
  | 'sm:blur-2xl'
  | 'md:blur-2xl'
  | 'lg:blur-2xl'
  | 'xl:blur-2xl'
  | '2xl:blur-2xl'
  | 'sm:blur-3xl'
  | 'md:blur-3xl'
  | 'lg:blur-3xl'
  | 'xl:blur-3xl'
  | '2xl:blur-3xl'
  | 'sm:border-collapse'
  | 'md:border-collapse'
  | 'lg:border-collapse'
  | 'xl:border-collapse'
  | '2xl:border-collapse'
  | 'sm:border-separate'
  | 'md:border-separate'
  | 'lg:border-separate'
  | 'xl:border-separate'
  | '2xl:border-separate'
  | 'sm:border-transparent'
  | 'md:border-transparent'
  | 'lg:border-transparent'
  | 'xl:border-transparent'
  | '2xl:border-transparent'
  | 'group-hover:border-transparent'
  | 'focus-within:border-transparent'
  | 'hover:border-transparent'
  | 'focus:border-transparent'
  | 'sm:border-current'
  | 'md:border-current'
  | 'lg:border-current'
  | 'xl:border-current'
  | '2xl:border-current'
  | 'group-hover:border-current'
  | 'focus-within:border-current'
  | 'hover:border-current'
  | 'focus:border-current'
  | 'sm:border-black'
  | 'md:border-black'
  | 'lg:border-black'
  | 'xl:border-black'
  | '2xl:border-black'
  | 'group-hover:border-black'
  | 'focus-within:border-black'
  | 'hover:border-black'
  | 'focus:border-black'
  | 'sm:border-white'
  | 'md:border-white'
  | 'lg:border-white'
  | 'xl:border-white'
  | '2xl:border-white'
  | 'group-hover:border-white'
  | 'focus-within:border-white'
  | 'hover:border-white'
  | 'focus:border-white'
  | 'sm:border-gray-50'
  | 'md:border-gray-50'
  | 'lg:border-gray-50'
  | 'xl:border-gray-50'
  | '2xl:border-gray-50'
  | 'group-hover:border-gray-50'
  | 'focus-within:border-gray-50'
  | 'hover:border-gray-50'
  | 'focus:border-gray-50'
  | 'sm:border-gray-100'
  | 'md:border-gray-100'
  | 'lg:border-gray-100'
  | 'xl:border-gray-100'
  | '2xl:border-gray-100'
  | 'group-hover:border-gray-100'
  | 'focus-within:border-gray-100'
  | 'hover:border-gray-100'
  | 'focus:border-gray-100'
  | 'sm:border-gray-200'
  | 'md:border-gray-200'
  | 'lg:border-gray-200'
  | 'xl:border-gray-200'
  | '2xl:border-gray-200'
  | 'group-hover:border-gray-200'
  | 'focus-within:border-gray-200'
  | 'hover:border-gray-200'
  | 'focus:border-gray-200'
  | 'sm:border-gray-300'
  | 'md:border-gray-300'
  | 'lg:border-gray-300'
  | 'xl:border-gray-300'
  | '2xl:border-gray-300'
  | 'group-hover:border-gray-300'
  | 'focus-within:border-gray-300'
  | 'hover:border-gray-300'
  | 'focus:border-gray-300'
  | 'sm:border-gray-400'
  | 'md:border-gray-400'
  | 'lg:border-gray-400'
  | 'xl:border-gray-400'
  | '2xl:border-gray-400'
  | 'group-hover:border-gray-400'
  | 'focus-within:border-gray-400'
  | 'hover:border-gray-400'
  | 'focus:border-gray-400'
  | 'sm:border-gray-500'
  | 'md:border-gray-500'
  | 'lg:border-gray-500'
  | 'xl:border-gray-500'
  | '2xl:border-gray-500'
  | 'group-hover:border-gray-500'
  | 'focus-within:border-gray-500'
  | 'hover:border-gray-500'
  | 'focus:border-gray-500'
  | 'sm:border-gray-600'
  | 'md:border-gray-600'
  | 'lg:border-gray-600'
  | 'xl:border-gray-600'
  | '2xl:border-gray-600'
  | 'group-hover:border-gray-600'
  | 'focus-within:border-gray-600'
  | 'hover:border-gray-600'
  | 'focus:border-gray-600'
  | 'sm:border-gray-700'
  | 'md:border-gray-700'
  | 'lg:border-gray-700'
  | 'xl:border-gray-700'
  | '2xl:border-gray-700'
  | 'group-hover:border-gray-700'
  | 'focus-within:border-gray-700'
  | 'hover:border-gray-700'
  | 'focus:border-gray-700'
  | 'sm:border-gray-800'
  | 'md:border-gray-800'
  | 'lg:border-gray-800'
  | 'xl:border-gray-800'
  | '2xl:border-gray-800'
  | 'group-hover:border-gray-800'
  | 'focus-within:border-gray-800'
  | 'hover:border-gray-800'
  | 'focus:border-gray-800'
  | 'sm:border-gray-900'
  | 'md:border-gray-900'
  | 'lg:border-gray-900'
  | 'xl:border-gray-900'
  | '2xl:border-gray-900'
  | 'group-hover:border-gray-900'
  | 'focus-within:border-gray-900'
  | 'hover:border-gray-900'
  | 'focus:border-gray-900'
  | 'sm:border-red-50'
  | 'md:border-red-50'
  | 'lg:border-red-50'
  | 'xl:border-red-50'
  | '2xl:border-red-50'
  | 'group-hover:border-red-50'
  | 'focus-within:border-red-50'
  | 'hover:border-red-50'
  | 'focus:border-red-50'
  | 'sm:border-red-100'
  | 'md:border-red-100'
  | 'lg:border-red-100'
  | 'xl:border-red-100'
  | '2xl:border-red-100'
  | 'group-hover:border-red-100'
  | 'focus-within:border-red-100'
  | 'hover:border-red-100'
  | 'focus:border-red-100'
  | 'sm:border-red-200'
  | 'md:border-red-200'
  | 'lg:border-red-200'
  | 'xl:border-red-200'
  | '2xl:border-red-200'
  | 'group-hover:border-red-200'
  | 'focus-within:border-red-200'
  | 'hover:border-red-200'
  | 'focus:border-red-200'
  | 'sm:border-red-300'
  | 'md:border-red-300'
  | 'lg:border-red-300'
  | 'xl:border-red-300'
  | '2xl:border-red-300'
  | 'group-hover:border-red-300'
  | 'focus-within:border-red-300'
  | 'hover:border-red-300'
  | 'focus:border-red-300'
  | 'sm:border-red-400'
  | 'md:border-red-400'
  | 'lg:border-red-400'
  | 'xl:border-red-400'
  | '2xl:border-red-400'
  | 'group-hover:border-red-400'
  | 'focus-within:border-red-400'
  | 'hover:border-red-400'
  | 'focus:border-red-400'
  | 'sm:border-red-500'
  | 'md:border-red-500'
  | 'lg:border-red-500'
  | 'xl:border-red-500'
  | '2xl:border-red-500'
  | 'group-hover:border-red-500'
  | 'focus-within:border-red-500'
  | 'hover:border-red-500'
  | 'focus:border-red-500'
  | 'sm:border-red-600'
  | 'md:border-red-600'
  | 'lg:border-red-600'
  | 'xl:border-red-600'
  | '2xl:border-red-600'
  | 'group-hover:border-red-600'
  | 'focus-within:border-red-600'
  | 'hover:border-red-600'
  | 'focus:border-red-600'
  | 'sm:border-red-700'
  | 'md:border-red-700'
  | 'lg:border-red-700'
  | 'xl:border-red-700'
  | '2xl:border-red-700'
  | 'group-hover:border-red-700'
  | 'focus-within:border-red-700'
  | 'hover:border-red-700'
  | 'focus:border-red-700'
  | 'sm:border-red-800'
  | 'md:border-red-800'
  | 'lg:border-red-800'
  | 'xl:border-red-800'
  | '2xl:border-red-800'
  | 'group-hover:border-red-800'
  | 'focus-within:border-red-800'
  | 'hover:border-red-800'
  | 'focus:border-red-800'
  | 'sm:border-red-900'
  | 'md:border-red-900'
  | 'lg:border-red-900'
  | 'xl:border-red-900'
  | '2xl:border-red-900'
  | 'group-hover:border-red-900'
  | 'focus-within:border-red-900'
  | 'hover:border-red-900'
  | 'focus:border-red-900'
  | 'sm:border-yellow-50'
  | 'md:border-yellow-50'
  | 'lg:border-yellow-50'
  | 'xl:border-yellow-50'
  | '2xl:border-yellow-50'
  | 'group-hover:border-yellow-50'
  | 'focus-within:border-yellow-50'
  | 'hover:border-yellow-50'
  | 'focus:border-yellow-50'
  | 'sm:border-yellow-100'
  | 'md:border-yellow-100'
  | 'lg:border-yellow-100'
  | 'xl:border-yellow-100'
  | '2xl:border-yellow-100'
  | 'group-hover:border-yellow-100'
  | 'focus-within:border-yellow-100'
  | 'hover:border-yellow-100'
  | 'focus:border-yellow-100'
  | 'sm:border-yellow-200'
  | 'md:border-yellow-200'
  | 'lg:border-yellow-200'
  | 'xl:border-yellow-200'
  | '2xl:border-yellow-200'
  | 'group-hover:border-yellow-200'
  | 'focus-within:border-yellow-200'
  | 'hover:border-yellow-200'
  | 'focus:border-yellow-200'
  | 'sm:border-yellow-300'
  | 'md:border-yellow-300'
  | 'lg:border-yellow-300'
  | 'xl:border-yellow-300'
  | '2xl:border-yellow-300'
  | 'group-hover:border-yellow-300'
  | 'focus-within:border-yellow-300'
  | 'hover:border-yellow-300'
  | 'focus:border-yellow-300'
  | 'sm:border-yellow-400'
  | 'md:border-yellow-400'
  | 'lg:border-yellow-400'
  | 'xl:border-yellow-400'
  | '2xl:border-yellow-400'
  | 'group-hover:border-yellow-400'
  | 'focus-within:border-yellow-400'
  | 'hover:border-yellow-400'
  | 'focus:border-yellow-400'
  | 'sm:border-yellow-500'
  | 'md:border-yellow-500'
  | 'lg:border-yellow-500'
  | 'xl:border-yellow-500'
  | '2xl:border-yellow-500'
  | 'group-hover:border-yellow-500'
  | 'focus-within:border-yellow-500'
  | 'hover:border-yellow-500'
  | 'focus:border-yellow-500'
  | 'sm:border-yellow-600'
  | 'md:border-yellow-600'
  | 'lg:border-yellow-600'
  | 'xl:border-yellow-600'
  | '2xl:border-yellow-600'
  | 'group-hover:border-yellow-600'
  | 'focus-within:border-yellow-600'
  | 'hover:border-yellow-600'
  | 'focus:border-yellow-600'
  | 'sm:border-yellow-700'
  | 'md:border-yellow-700'
  | 'lg:border-yellow-700'
  | 'xl:border-yellow-700'
  | '2xl:border-yellow-700'
  | 'group-hover:border-yellow-700'
  | 'focus-within:border-yellow-700'
  | 'hover:border-yellow-700'
  | 'focus:border-yellow-700'
  | 'sm:border-yellow-800'
  | 'md:border-yellow-800'
  | 'lg:border-yellow-800'
  | 'xl:border-yellow-800'
  | '2xl:border-yellow-800'
  | 'group-hover:border-yellow-800'
  | 'focus-within:border-yellow-800'
  | 'hover:border-yellow-800'
  | 'focus:border-yellow-800'
  | 'sm:border-yellow-900'
  | 'md:border-yellow-900'
  | 'lg:border-yellow-900'
  | 'xl:border-yellow-900'
  | '2xl:border-yellow-900'
  | 'group-hover:border-yellow-900'
  | 'focus-within:border-yellow-900'
  | 'hover:border-yellow-900'
  | 'focus:border-yellow-900'
  | 'sm:border-green-50'
  | 'md:border-green-50'
  | 'lg:border-green-50'
  | 'xl:border-green-50'
  | '2xl:border-green-50'
  | 'group-hover:border-green-50'
  | 'focus-within:border-green-50'
  | 'hover:border-green-50'
  | 'focus:border-green-50'
  | 'sm:border-green-100'
  | 'md:border-green-100'
  | 'lg:border-green-100'
  | 'xl:border-green-100'
  | '2xl:border-green-100'
  | 'group-hover:border-green-100'
  | 'focus-within:border-green-100'
  | 'hover:border-green-100'
  | 'focus:border-green-100'
  | 'sm:border-green-200'
  | 'md:border-green-200'
  | 'lg:border-green-200'
  | 'xl:border-green-200'
  | '2xl:border-green-200'
  | 'group-hover:border-green-200'
  | 'focus-within:border-green-200'
  | 'hover:border-green-200'
  | 'focus:border-green-200'
  | 'sm:border-green-300'
  | 'md:border-green-300'
  | 'lg:border-green-300'
  | 'xl:border-green-300'
  | '2xl:border-green-300'
  | 'group-hover:border-green-300'
  | 'focus-within:border-green-300'
  | 'hover:border-green-300'
  | 'focus:border-green-300'
  | 'sm:border-green-400'
  | 'md:border-green-400'
  | 'lg:border-green-400'
  | 'xl:border-green-400'
  | '2xl:border-green-400'
  | 'group-hover:border-green-400'
  | 'focus-within:border-green-400'
  | 'hover:border-green-400'
  | 'focus:border-green-400'
  | 'sm:border-green-500'
  | 'md:border-green-500'
  | 'lg:border-green-500'
  | 'xl:border-green-500'
  | '2xl:border-green-500'
  | 'group-hover:border-green-500'
  | 'focus-within:border-green-500'
  | 'hover:border-green-500'
  | 'focus:border-green-500'
  | 'sm:border-green-600'
  | 'md:border-green-600'
  | 'lg:border-green-600'
  | 'xl:border-green-600'
  | '2xl:border-green-600'
  | 'group-hover:border-green-600'
  | 'focus-within:border-green-600'
  | 'hover:border-green-600'
  | 'focus:border-green-600'
  | 'sm:border-green-700'
  | 'md:border-green-700'
  | 'lg:border-green-700'
  | 'xl:border-green-700'
  | '2xl:border-green-700'
  | 'group-hover:border-green-700'
  | 'focus-within:border-green-700'
  | 'hover:border-green-700'
  | 'focus:border-green-700'
  | 'sm:border-green-800'
  | 'md:border-green-800'
  | 'lg:border-green-800'
  | 'xl:border-green-800'
  | '2xl:border-green-800'
  | 'group-hover:border-green-800'
  | 'focus-within:border-green-800'
  | 'hover:border-green-800'
  | 'focus:border-green-800'
  | 'sm:border-green-900'
  | 'md:border-green-900'
  | 'lg:border-green-900'
  | 'xl:border-green-900'
  | '2xl:border-green-900'
  | 'group-hover:border-green-900'
  | 'focus-within:border-green-900'
  | 'hover:border-green-900'
  | 'focus:border-green-900'
  | 'sm:border-blue-50'
  | 'md:border-blue-50'
  | 'lg:border-blue-50'
  | 'xl:border-blue-50'
  | '2xl:border-blue-50'
  | 'group-hover:border-blue-50'
  | 'focus-within:border-blue-50'
  | 'hover:border-blue-50'
  | 'focus:border-blue-50'
  | 'sm:border-blue-100'
  | 'md:border-blue-100'
  | 'lg:border-blue-100'
  | 'xl:border-blue-100'
  | '2xl:border-blue-100'
  | 'group-hover:border-blue-100'
  | 'focus-within:border-blue-100'
  | 'hover:border-blue-100'
  | 'focus:border-blue-100'
  | 'sm:border-blue-200'
  | 'md:border-blue-200'
  | 'lg:border-blue-200'
  | 'xl:border-blue-200'
  | '2xl:border-blue-200'
  | 'group-hover:border-blue-200'
  | 'focus-within:border-blue-200'
  | 'hover:border-blue-200'
  | 'focus:border-blue-200'
  | 'sm:border-blue-300'
  | 'md:border-blue-300'
  | 'lg:border-blue-300'
  | 'xl:border-blue-300'
  | '2xl:border-blue-300'
  | 'group-hover:border-blue-300'
  | 'focus-within:border-blue-300'
  | 'hover:border-blue-300'
  | 'focus:border-blue-300'
  | 'sm:border-blue-400'
  | 'md:border-blue-400'
  | 'lg:border-blue-400'
  | 'xl:border-blue-400'
  | '2xl:border-blue-400'
  | 'group-hover:border-blue-400'
  | 'focus-within:border-blue-400'
  | 'hover:border-blue-400'
  | 'focus:border-blue-400'
  | 'sm:border-blue-500'
  | 'md:border-blue-500'
  | 'lg:border-blue-500'
  | 'xl:border-blue-500'
  | '2xl:border-blue-500'
  | 'group-hover:border-blue-500'
  | 'focus-within:border-blue-500'
  | 'hover:border-blue-500'
  | 'focus:border-blue-500'
  | 'sm:border-blue-600'
  | 'md:border-blue-600'
  | 'lg:border-blue-600'
  | 'xl:border-blue-600'
  | '2xl:border-blue-600'
  | 'group-hover:border-blue-600'
  | 'focus-within:border-blue-600'
  | 'hover:border-blue-600'
  | 'focus:border-blue-600'
  | 'sm:border-blue-700'
  | 'md:border-blue-700'
  | 'lg:border-blue-700'
  | 'xl:border-blue-700'
  | '2xl:border-blue-700'
  | 'group-hover:border-blue-700'
  | 'focus-within:border-blue-700'
  | 'hover:border-blue-700'
  | 'focus:border-blue-700'
  | 'sm:border-blue-800'
  | 'md:border-blue-800'
  | 'lg:border-blue-800'
  | 'xl:border-blue-800'
  | '2xl:border-blue-800'
  | 'group-hover:border-blue-800'
  | 'focus-within:border-blue-800'
  | 'hover:border-blue-800'
  | 'focus:border-blue-800'
  | 'sm:border-blue-900'
  | 'md:border-blue-900'
  | 'lg:border-blue-900'
  | 'xl:border-blue-900'
  | '2xl:border-blue-900'
  | 'group-hover:border-blue-900'
  | 'focus-within:border-blue-900'
  | 'hover:border-blue-900'
  | 'focus:border-blue-900'
  | 'sm:border-indigo-50'
  | 'md:border-indigo-50'
  | 'lg:border-indigo-50'
  | 'xl:border-indigo-50'
  | '2xl:border-indigo-50'
  | 'group-hover:border-indigo-50'
  | 'focus-within:border-indigo-50'
  | 'hover:border-indigo-50'
  | 'focus:border-indigo-50'
  | 'sm:border-indigo-100'
  | 'md:border-indigo-100'
  | 'lg:border-indigo-100'
  | 'xl:border-indigo-100'
  | '2xl:border-indigo-100'
  | 'group-hover:border-indigo-100'
  | 'focus-within:border-indigo-100'
  | 'hover:border-indigo-100'
  | 'focus:border-indigo-100'
  | 'sm:border-indigo-200'
  | 'md:border-indigo-200'
  | 'lg:border-indigo-200'
  | 'xl:border-indigo-200'
  | '2xl:border-indigo-200'
  | 'group-hover:border-indigo-200'
  | 'focus-within:border-indigo-200'
  | 'hover:border-indigo-200'
  | 'focus:border-indigo-200'
  | 'sm:border-indigo-300'
  | 'md:border-indigo-300'
  | 'lg:border-indigo-300'
  | 'xl:border-indigo-300'
  | '2xl:border-indigo-300'
  | 'group-hover:border-indigo-300'
  | 'focus-within:border-indigo-300'
  | 'hover:border-indigo-300'
  | 'focus:border-indigo-300'
  | 'sm:border-indigo-400'
  | 'md:border-indigo-400'
  | 'lg:border-indigo-400'
  | 'xl:border-indigo-400'
  | '2xl:border-indigo-400'
  | 'group-hover:border-indigo-400'
  | 'focus-within:border-indigo-400'
  | 'hover:border-indigo-400'
  | 'focus:border-indigo-400'
  | 'sm:border-indigo-500'
  | 'md:border-indigo-500'
  | 'lg:border-indigo-500'
  | 'xl:border-indigo-500'
  | '2xl:border-indigo-500'
  | 'group-hover:border-indigo-500'
  | 'focus-within:border-indigo-500'
  | 'hover:border-indigo-500'
  | 'focus:border-indigo-500'
  | 'sm:border-indigo-600'
  | 'md:border-indigo-600'
  | 'lg:border-indigo-600'
  | 'xl:border-indigo-600'
  | '2xl:border-indigo-600'
  | 'group-hover:border-indigo-600'
  | 'focus-within:border-indigo-600'
  | 'hover:border-indigo-600'
  | 'focus:border-indigo-600'
  | 'sm:border-indigo-700'
  | 'md:border-indigo-700'
  | 'lg:border-indigo-700'
  | 'xl:border-indigo-700'
  | '2xl:border-indigo-700'
  | 'group-hover:border-indigo-700'
  | 'focus-within:border-indigo-700'
  | 'hover:border-indigo-700'
  | 'focus:border-indigo-700'
  | 'sm:border-indigo-800'
  | 'md:border-indigo-800'
  | 'lg:border-indigo-800'
  | 'xl:border-indigo-800'
  | '2xl:border-indigo-800'
  | 'group-hover:border-indigo-800'
  | 'focus-within:border-indigo-800'
  | 'hover:border-indigo-800'
  | 'focus:border-indigo-800'
  | 'sm:border-indigo-900'
  | 'md:border-indigo-900'
  | 'lg:border-indigo-900'
  | 'xl:border-indigo-900'
  | '2xl:border-indigo-900'
  | 'group-hover:border-indigo-900'
  | 'focus-within:border-indigo-900'
  | 'hover:border-indigo-900'
  | 'focus:border-indigo-900'
  | 'sm:border-purple-50'
  | 'md:border-purple-50'
  | 'lg:border-purple-50'
  | 'xl:border-purple-50'
  | '2xl:border-purple-50'
  | 'group-hover:border-purple-50'
  | 'focus-within:border-purple-50'
  | 'hover:border-purple-50'
  | 'focus:border-purple-50'
  | 'sm:border-purple-100'
  | 'md:border-purple-100'
  | 'lg:border-purple-100'
  | 'xl:border-purple-100'
  | '2xl:border-purple-100'
  | 'group-hover:border-purple-100'
  | 'focus-within:border-purple-100'
  | 'hover:border-purple-100'
  | 'focus:border-purple-100'
  | 'sm:border-purple-200'
  | 'md:border-purple-200'
  | 'lg:border-purple-200'
  | 'xl:border-purple-200'
  | '2xl:border-purple-200'
  | 'group-hover:border-purple-200'
  | 'focus-within:border-purple-200'
  | 'hover:border-purple-200'
  | 'focus:border-purple-200'
  | 'sm:border-purple-300'
  | 'md:border-purple-300'
  | 'lg:border-purple-300'
  | 'xl:border-purple-300'
  | '2xl:border-purple-300'
  | 'group-hover:border-purple-300'
  | 'focus-within:border-purple-300'
  | 'hover:border-purple-300'
  | 'focus:border-purple-300'
  | 'sm:border-purple-400'
  | 'md:border-purple-400'
  | 'lg:border-purple-400'
  | 'xl:border-purple-400'
  | '2xl:border-purple-400'
  | 'group-hover:border-purple-400'
  | 'focus-within:border-purple-400'
  | 'hover:border-purple-400'
  | 'focus:border-purple-400'
  | 'sm:border-purple-500'
  | 'md:border-purple-500'
  | 'lg:border-purple-500'
  | 'xl:border-purple-500'
  | '2xl:border-purple-500'
  | 'group-hover:border-purple-500'
  | 'focus-within:border-purple-500'
  | 'hover:border-purple-500'
  | 'focus:border-purple-500'
  | 'sm:border-purple-600'
  | 'md:border-purple-600'
  | 'lg:border-purple-600'
  | 'xl:border-purple-600'
  | '2xl:border-purple-600'
  | 'group-hover:border-purple-600'
  | 'focus-within:border-purple-600'
  | 'hover:border-purple-600'
  | 'focus:border-purple-600'
  | 'sm:border-purple-700'
  | 'md:border-purple-700'
  | 'lg:border-purple-700'
  | 'xl:border-purple-700'
  | '2xl:border-purple-700'
  | 'group-hover:border-purple-700'
  | 'focus-within:border-purple-700'
  | 'hover:border-purple-700'
  | 'focus:border-purple-700'
  | 'sm:border-purple-800'
  | 'md:border-purple-800'
  | 'lg:border-purple-800'
  | 'xl:border-purple-800'
  | '2xl:border-purple-800'
  | 'group-hover:border-purple-800'
  | 'focus-within:border-purple-800'
  | 'hover:border-purple-800'
  | 'focus:border-purple-800'
  | 'sm:border-purple-900'
  | 'md:border-purple-900'
  | 'lg:border-purple-900'
  | 'xl:border-purple-900'
  | '2xl:border-purple-900'
  | 'group-hover:border-purple-900'
  | 'focus-within:border-purple-900'
  | 'hover:border-purple-900'
  | 'focus:border-purple-900'
  | 'sm:border-pink-50'
  | 'md:border-pink-50'
  | 'lg:border-pink-50'
  | 'xl:border-pink-50'
  | '2xl:border-pink-50'
  | 'group-hover:border-pink-50'
  | 'focus-within:border-pink-50'
  | 'hover:border-pink-50'
  | 'focus:border-pink-50'
  | 'sm:border-pink-100'
  | 'md:border-pink-100'
  | 'lg:border-pink-100'
  | 'xl:border-pink-100'
  | '2xl:border-pink-100'
  | 'group-hover:border-pink-100'
  | 'focus-within:border-pink-100'
  | 'hover:border-pink-100'
  | 'focus:border-pink-100'
  | 'sm:border-pink-200'
  | 'md:border-pink-200'
  | 'lg:border-pink-200'
  | 'xl:border-pink-200'
  | '2xl:border-pink-200'
  | 'group-hover:border-pink-200'
  | 'focus-within:border-pink-200'
  | 'hover:border-pink-200'
  | 'focus:border-pink-200'
  | 'sm:border-pink-300'
  | 'md:border-pink-300'
  | 'lg:border-pink-300'
  | 'xl:border-pink-300'
  | '2xl:border-pink-300'
  | 'group-hover:border-pink-300'
  | 'focus-within:border-pink-300'
  | 'hover:border-pink-300'
  | 'focus:border-pink-300'
  | 'sm:border-pink-400'
  | 'md:border-pink-400'
  | 'lg:border-pink-400'
  | 'xl:border-pink-400'
  | '2xl:border-pink-400'
  | 'group-hover:border-pink-400'
  | 'focus-within:border-pink-400'
  | 'hover:border-pink-400'
  | 'focus:border-pink-400'
  | 'sm:border-pink-500'
  | 'md:border-pink-500'
  | 'lg:border-pink-500'
  | 'xl:border-pink-500'
  | '2xl:border-pink-500'
  | 'group-hover:border-pink-500'
  | 'focus-within:border-pink-500'
  | 'hover:border-pink-500'
  | 'focus:border-pink-500'
  | 'sm:border-pink-600'
  | 'md:border-pink-600'
  | 'lg:border-pink-600'
  | 'xl:border-pink-600'
  | '2xl:border-pink-600'
  | 'group-hover:border-pink-600'
  | 'focus-within:border-pink-600'
  | 'hover:border-pink-600'
  | 'focus:border-pink-600'
  | 'sm:border-pink-700'
  | 'md:border-pink-700'
  | 'lg:border-pink-700'
  | 'xl:border-pink-700'
  | '2xl:border-pink-700'
  | 'group-hover:border-pink-700'
  | 'focus-within:border-pink-700'
  | 'hover:border-pink-700'
  | 'focus:border-pink-700'
  | 'sm:border-pink-800'
  | 'md:border-pink-800'
  | 'lg:border-pink-800'
  | 'xl:border-pink-800'
  | '2xl:border-pink-800'
  | 'group-hover:border-pink-800'
  | 'focus-within:border-pink-800'
  | 'hover:border-pink-800'
  | 'focus:border-pink-800'
  | 'sm:border-pink-900'
  | 'md:border-pink-900'
  | 'lg:border-pink-900'
  | 'xl:border-pink-900'
  | '2xl:border-pink-900'
  | 'group-hover:border-pink-900'
  | 'focus-within:border-pink-900'
  | 'hover:border-pink-900'
  | 'focus:border-pink-900'
  | 'sm:border-opacity-0'
  | 'md:border-opacity-0'
  | 'lg:border-opacity-0'
  | 'xl:border-opacity-0'
  | '2xl:border-opacity-0'
  | 'group-hover:border-opacity-0'
  | 'focus-within:border-opacity-0'
  | 'hover:border-opacity-0'
  | 'focus:border-opacity-0'
  | 'sm:border-opacity-5'
  | 'md:border-opacity-5'
  | 'lg:border-opacity-5'
  | 'xl:border-opacity-5'
  | '2xl:border-opacity-5'
  | 'group-hover:border-opacity-5'
  | 'focus-within:border-opacity-5'
  | 'hover:border-opacity-5'
  | 'focus:border-opacity-5'
  | 'sm:border-opacity-10'
  | 'md:border-opacity-10'
  | 'lg:border-opacity-10'
  | 'xl:border-opacity-10'
  | '2xl:border-opacity-10'
  | 'group-hover:border-opacity-10'
  | 'focus-within:border-opacity-10'
  | 'hover:border-opacity-10'
  | 'focus:border-opacity-10'
  | 'sm:border-opacity-20'
  | 'md:border-opacity-20'
  | 'lg:border-opacity-20'
  | 'xl:border-opacity-20'
  | '2xl:border-opacity-20'
  | 'group-hover:border-opacity-20'
  | 'focus-within:border-opacity-20'
  | 'hover:border-opacity-20'
  | 'focus:border-opacity-20'
  | 'sm:border-opacity-25'
  | 'md:border-opacity-25'
  | 'lg:border-opacity-25'
  | 'xl:border-opacity-25'
  | '2xl:border-opacity-25'
  | 'group-hover:border-opacity-25'
  | 'focus-within:border-opacity-25'
  | 'hover:border-opacity-25'
  | 'focus:border-opacity-25'
  | 'sm:border-opacity-30'
  | 'md:border-opacity-30'
  | 'lg:border-opacity-30'
  | 'xl:border-opacity-30'
  | '2xl:border-opacity-30'
  | 'group-hover:border-opacity-30'
  | 'focus-within:border-opacity-30'
  | 'hover:border-opacity-30'
  | 'focus:border-opacity-30'
  | 'sm:border-opacity-40'
  | 'md:border-opacity-40'
  | 'lg:border-opacity-40'
  | 'xl:border-opacity-40'
  | '2xl:border-opacity-40'
  | 'group-hover:border-opacity-40'
  | 'focus-within:border-opacity-40'
  | 'hover:border-opacity-40'
  | 'focus:border-opacity-40'
  | 'sm:border-opacity-50'
  | 'md:border-opacity-50'
  | 'lg:border-opacity-50'
  | 'xl:border-opacity-50'
  | '2xl:border-opacity-50'
  | 'group-hover:border-opacity-50'
  | 'focus-within:border-opacity-50'
  | 'hover:border-opacity-50'
  | 'focus:border-opacity-50'
  | 'sm:border-opacity-60'
  | 'md:border-opacity-60'
  | 'lg:border-opacity-60'
  | 'xl:border-opacity-60'
  | '2xl:border-opacity-60'
  | 'group-hover:border-opacity-60'
  | 'focus-within:border-opacity-60'
  | 'hover:border-opacity-60'
  | 'focus:border-opacity-60'
  | 'sm:border-opacity-70'
  | 'md:border-opacity-70'
  | 'lg:border-opacity-70'
  | 'xl:border-opacity-70'
  | '2xl:border-opacity-70'
  | 'group-hover:border-opacity-70'
  | 'focus-within:border-opacity-70'
  | 'hover:border-opacity-70'
  | 'focus:border-opacity-70'
  | 'sm:border-opacity-75'
  | 'md:border-opacity-75'
  | 'lg:border-opacity-75'
  | 'xl:border-opacity-75'
  | '2xl:border-opacity-75'
  | 'group-hover:border-opacity-75'
  | 'focus-within:border-opacity-75'
  | 'hover:border-opacity-75'
  | 'focus:border-opacity-75'
  | 'sm:border-opacity-80'
  | 'md:border-opacity-80'
  | 'lg:border-opacity-80'
  | 'xl:border-opacity-80'
  | '2xl:border-opacity-80'
  | 'group-hover:border-opacity-80'
  | 'focus-within:border-opacity-80'
  | 'hover:border-opacity-80'
  | 'focus:border-opacity-80'
  | 'sm:border-opacity-90'
  | 'md:border-opacity-90'
  | 'lg:border-opacity-90'
  | 'xl:border-opacity-90'
  | '2xl:border-opacity-90'
  | 'group-hover:border-opacity-90'
  | 'focus-within:border-opacity-90'
  | 'hover:border-opacity-90'
  | 'focus:border-opacity-90'
  | 'sm:border-opacity-95'
  | 'md:border-opacity-95'
  | 'lg:border-opacity-95'
  | 'xl:border-opacity-95'
  | '2xl:border-opacity-95'
  | 'group-hover:border-opacity-95'
  | 'focus-within:border-opacity-95'
  | 'hover:border-opacity-95'
  | 'focus:border-opacity-95'
  | 'sm:border-opacity-100'
  | 'md:border-opacity-100'
  | 'lg:border-opacity-100'
  | 'xl:border-opacity-100'
  | '2xl:border-opacity-100'
  | 'group-hover:border-opacity-100'
  | 'focus-within:border-opacity-100'
  | 'hover:border-opacity-100'
  | 'focus:border-opacity-100'
  | 'sm:rounded-t-none'
  | 'md:rounded-t-none'
  | 'lg:rounded-t-none'
  | 'xl:rounded-t-none'
  | '2xl:rounded-t-none'
  | 'sm:rounded-r-none'
  | 'md:rounded-r-none'
  | 'lg:rounded-r-none'
  | 'xl:rounded-r-none'
  | '2xl:rounded-r-none'
  | 'sm:rounded-b-none'
  | 'md:rounded-b-none'
  | 'lg:rounded-b-none'
  | 'xl:rounded-b-none'
  | '2xl:rounded-b-none'
  | 'sm:rounded-l-none'
  | 'md:rounded-l-none'
  | 'lg:rounded-l-none'
  | 'xl:rounded-l-none'
  | '2xl:rounded-l-none'
  | 'sm:rounded-tr-none'
  | 'md:rounded-tr-none'
  | 'lg:rounded-tr-none'
  | 'xl:rounded-tr-none'
  | '2xl:rounded-tr-none'
  | 'sm:rounded-tl-none'
  | 'md:rounded-tl-none'
  | 'lg:rounded-tl-none'
  | 'xl:rounded-tl-none'
  | '2xl:rounded-tl-none'
  | 'sm:rounded-br-none'
  | 'md:rounded-br-none'
  | 'lg:rounded-br-none'
  | 'xl:rounded-br-none'
  | '2xl:rounded-br-none'
  | 'sm:rounded-bl-none'
  | 'md:rounded-bl-none'
  | 'lg:rounded-bl-none'
  | 'xl:rounded-bl-none'
  | '2xl:rounded-bl-none'
  | 'sm:rounded-none'
  | 'md:rounded-none'
  | 'lg:rounded-none'
  | 'xl:rounded-none'
  | '2xl:rounded-none'
  | 'sm:rounded-t-sm'
  | 'md:rounded-t-sm'
  | 'lg:rounded-t-sm'
  | 'xl:rounded-t-sm'
  | '2xl:rounded-t-sm'
  | 'sm:rounded-r-sm'
  | 'md:rounded-r-sm'
  | 'lg:rounded-r-sm'
  | 'xl:rounded-r-sm'
  | '2xl:rounded-r-sm'
  | 'sm:rounded-b-sm'
  | 'md:rounded-b-sm'
  | 'lg:rounded-b-sm'
  | 'xl:rounded-b-sm'
  | '2xl:rounded-b-sm'
  | 'sm:rounded-l-sm'
  | 'md:rounded-l-sm'
  | 'lg:rounded-l-sm'
  | 'xl:rounded-l-sm'
  | '2xl:rounded-l-sm'
  | 'sm:rounded-tr-sm'
  | 'md:rounded-tr-sm'
  | 'lg:rounded-tr-sm'
  | 'xl:rounded-tr-sm'
  | '2xl:rounded-tr-sm'
  | 'sm:rounded-tl-sm'
  | 'md:rounded-tl-sm'
  | 'lg:rounded-tl-sm'
  | 'xl:rounded-tl-sm'
  | '2xl:rounded-tl-sm'
  | 'sm:rounded-br-sm'
  | 'md:rounded-br-sm'
  | 'lg:rounded-br-sm'
  | 'xl:rounded-br-sm'
  | '2xl:rounded-br-sm'
  | 'sm:rounded-bl-sm'
  | 'md:rounded-bl-sm'
  | 'lg:rounded-bl-sm'
  | 'xl:rounded-bl-sm'
  | '2xl:rounded-bl-sm'
  | 'sm:rounded-sm'
  | 'md:rounded-sm'
  | 'lg:rounded-sm'
  | 'xl:rounded-sm'
  | '2xl:rounded-sm'
  | 'sm:rounded-t'
  | 'md:rounded-t'
  | 'lg:rounded-t'
  | 'xl:rounded-t'
  | '2xl:rounded-t'
  | 'sm:rounded-r'
  | 'md:rounded-r'
  | 'lg:rounded-r'
  | 'xl:rounded-r'
  | '2xl:rounded-r'
  | 'sm:rounded-b'
  | 'md:rounded-b'
  | 'lg:rounded-b'
  | 'xl:rounded-b'
  | '2xl:rounded-b'
  | 'sm:rounded-l'
  | 'md:rounded-l'
  | 'lg:rounded-l'
  | 'xl:rounded-l'
  | '2xl:rounded-l'
  | 'sm:rounded-tr'
  | 'md:rounded-tr'
  | 'lg:rounded-tr'
  | 'xl:rounded-tr'
  | '2xl:rounded-tr'
  | 'sm:rounded-tl'
  | 'md:rounded-tl'
  | 'lg:rounded-tl'
  | 'xl:rounded-tl'
  | '2xl:rounded-tl'
  | 'sm:rounded-br'
  | 'md:rounded-br'
  | 'lg:rounded-br'
  | 'xl:rounded-br'
  | '2xl:rounded-br'
  | 'sm:rounded-bl'
  | 'md:rounded-bl'
  | 'lg:rounded-bl'
  | 'xl:rounded-bl'
  | '2xl:rounded-bl'
  | 'sm:rounded'
  | 'md:rounded'
  | 'lg:rounded'
  | 'xl:rounded'
  | '2xl:rounded'
  | 'sm:rounded-t-md'
  | 'md:rounded-t-md'
  | 'lg:rounded-t-md'
  | 'xl:rounded-t-md'
  | '2xl:rounded-t-md'
  | 'sm:rounded-r-md'
  | 'md:rounded-r-md'
  | 'lg:rounded-r-md'
  | 'xl:rounded-r-md'
  | '2xl:rounded-r-md'
  | 'sm:rounded-b-md'
  | 'md:rounded-b-md'
  | 'lg:rounded-b-md'
  | 'xl:rounded-b-md'
  | '2xl:rounded-b-md'
  | 'sm:rounded-l-md'
  | 'md:rounded-l-md'
  | 'lg:rounded-l-md'
  | 'xl:rounded-l-md'
  | '2xl:rounded-l-md'
  | 'sm:rounded-tr-md'
  | 'md:rounded-tr-md'
  | 'lg:rounded-tr-md'
  | 'xl:rounded-tr-md'
  | '2xl:rounded-tr-md'
  | 'sm:rounded-tl-md'
  | 'md:rounded-tl-md'
  | 'lg:rounded-tl-md'
  | 'xl:rounded-tl-md'
  | '2xl:rounded-tl-md'
  | 'sm:rounded-br-md'
  | 'md:rounded-br-md'
  | 'lg:rounded-br-md'
  | 'xl:rounded-br-md'
  | '2xl:rounded-br-md'
  | 'sm:rounded-bl-md'
  | 'md:rounded-bl-md'
  | 'lg:rounded-bl-md'
  | 'xl:rounded-bl-md'
  | '2xl:rounded-bl-md'
  | 'sm:rounded-md'
  | 'md:rounded-md'
  | 'lg:rounded-md'
  | 'xl:rounded-md'
  | '2xl:rounded-md'
  | 'sm:rounded-t-lg'
  | 'md:rounded-t-lg'
  | 'lg:rounded-t-lg'
  | 'xl:rounded-t-lg'
  | '2xl:rounded-t-lg'
  | 'sm:rounded-r-lg'
  | 'md:rounded-r-lg'
  | 'lg:rounded-r-lg'
  | 'xl:rounded-r-lg'
  | '2xl:rounded-r-lg'
  | 'sm:rounded-b-lg'
  | 'md:rounded-b-lg'
  | 'lg:rounded-b-lg'
  | 'xl:rounded-b-lg'
  | '2xl:rounded-b-lg'
  | 'sm:rounded-l-lg'
  | 'md:rounded-l-lg'
  | 'lg:rounded-l-lg'
  | 'xl:rounded-l-lg'
  | '2xl:rounded-l-lg'
  | 'sm:rounded-tr-lg'
  | 'md:rounded-tr-lg'
  | 'lg:rounded-tr-lg'
  | 'xl:rounded-tr-lg'
  | '2xl:rounded-tr-lg'
  | 'sm:rounded-tl-lg'
  | 'md:rounded-tl-lg'
  | 'lg:rounded-tl-lg'
  | 'xl:rounded-tl-lg'
  | '2xl:rounded-tl-lg'
  | 'sm:rounded-br-lg'
  | 'md:rounded-br-lg'
  | 'lg:rounded-br-lg'
  | 'xl:rounded-br-lg'
  | '2xl:rounded-br-lg'
  | 'sm:rounded-bl-lg'
  | 'md:rounded-bl-lg'
  | 'lg:rounded-bl-lg'
  | 'xl:rounded-bl-lg'
  | '2xl:rounded-bl-lg'
  | 'sm:rounded-lg'
  | 'md:rounded-lg'
  | 'lg:rounded-lg'
  | 'xl:rounded-lg'
  | '2xl:rounded-lg'
  | 'sm:rounded-t-xl'
  | 'md:rounded-t-xl'
  | 'lg:rounded-t-xl'
  | 'xl:rounded-t-xl'
  | '2xl:rounded-t-xl'
  | 'sm:rounded-r-xl'
  | 'md:rounded-r-xl'
  | 'lg:rounded-r-xl'
  | 'xl:rounded-r-xl'
  | '2xl:rounded-r-xl'
  | 'sm:rounded-b-xl'
  | 'md:rounded-b-xl'
  | 'lg:rounded-b-xl'
  | 'xl:rounded-b-xl'
  | '2xl:rounded-b-xl'
  | 'sm:rounded-l-xl'
  | 'md:rounded-l-xl'
  | 'lg:rounded-l-xl'
  | 'xl:rounded-l-xl'
  | '2xl:rounded-l-xl'
  | 'sm:rounded-tr-xl'
  | 'md:rounded-tr-xl'
  | 'lg:rounded-tr-xl'
  | 'xl:rounded-tr-xl'
  | '2xl:rounded-tr-xl'
  | 'sm:rounded-tl-xl'
  | 'md:rounded-tl-xl'
  | 'lg:rounded-tl-xl'
  | 'xl:rounded-tl-xl'
  | '2xl:rounded-tl-xl'
  | 'sm:rounded-br-xl'
  | 'md:rounded-br-xl'
  | 'lg:rounded-br-xl'
  | 'xl:rounded-br-xl'
  | '2xl:rounded-br-xl'
  | 'sm:rounded-bl-xl'
  | 'md:rounded-bl-xl'
  | 'lg:rounded-bl-xl'
  | 'xl:rounded-bl-xl'
  | '2xl:rounded-bl-xl'
  | 'sm:rounded-xl'
  | 'md:rounded-xl'
  | 'lg:rounded-xl'
  | 'xl:rounded-xl'
  | '2xl:rounded-xl'
  | 'sm:rounded-t-2xl'
  | 'md:rounded-t-2xl'
  | 'lg:rounded-t-2xl'
  | 'xl:rounded-t-2xl'
  | '2xl:rounded-t-2xl'
  | 'sm:rounded-r-2xl'
  | 'md:rounded-r-2xl'
  | 'lg:rounded-r-2xl'
  | 'xl:rounded-r-2xl'
  | '2xl:rounded-r-2xl'
  | 'sm:rounded-b-2xl'
  | 'md:rounded-b-2xl'
  | 'lg:rounded-b-2xl'
  | 'xl:rounded-b-2xl'
  | '2xl:rounded-b-2xl'
  | 'sm:rounded-l-2xl'
  | 'md:rounded-l-2xl'
  | 'lg:rounded-l-2xl'
  | 'xl:rounded-l-2xl'
  | '2xl:rounded-l-2xl'
  | 'sm:rounded-tr-2xl'
  | 'md:rounded-tr-2xl'
  | 'lg:rounded-tr-2xl'
  | 'xl:rounded-tr-2xl'
  | '2xl:rounded-tr-2xl'
  | 'sm:rounded-tl-2xl'
  | 'md:rounded-tl-2xl'
  | 'lg:rounded-tl-2xl'
  | 'xl:rounded-tl-2xl'
  | '2xl:rounded-tl-2xl'
  | 'sm:rounded-br-2xl'
  | 'md:rounded-br-2xl'
  | 'lg:rounded-br-2xl'
  | 'xl:rounded-br-2xl'
  | '2xl:rounded-br-2xl'
  | 'sm:rounded-bl-2xl'
  | 'md:rounded-bl-2xl'
  | 'lg:rounded-bl-2xl'
  | 'xl:rounded-bl-2xl'
  | '2xl:rounded-bl-2xl'
  | 'sm:rounded-2xl'
  | 'md:rounded-2xl'
  | 'lg:rounded-2xl'
  | 'xl:rounded-2xl'
  | '2xl:rounded-2xl'
  | 'sm:rounded-t-3xl'
  | 'md:rounded-t-3xl'
  | 'lg:rounded-t-3xl'
  | 'xl:rounded-t-3xl'
  | '2xl:rounded-t-3xl'
  | 'sm:rounded-r-3xl'
  | 'md:rounded-r-3xl'
  | 'lg:rounded-r-3xl'
  | 'xl:rounded-r-3xl'
  | '2xl:rounded-r-3xl'
  | 'sm:rounded-b-3xl'
  | 'md:rounded-b-3xl'
  | 'lg:rounded-b-3xl'
  | 'xl:rounded-b-3xl'
  | '2xl:rounded-b-3xl'
  | 'sm:rounded-l-3xl'
  | 'md:rounded-l-3xl'
  | 'lg:rounded-l-3xl'
  | 'xl:rounded-l-3xl'
  | '2xl:rounded-l-3xl'
  | 'sm:rounded-tr-3xl'
  | 'md:rounded-tr-3xl'
  | 'lg:rounded-tr-3xl'
  | 'xl:rounded-tr-3xl'
  | '2xl:rounded-tr-3xl'
  | 'sm:rounded-tl-3xl'
  | 'md:rounded-tl-3xl'
  | 'lg:rounded-tl-3xl'
  | 'xl:rounded-tl-3xl'
  | '2xl:rounded-tl-3xl'
  | 'sm:rounded-br-3xl'
  | 'md:rounded-br-3xl'
  | 'lg:rounded-br-3xl'
  | 'xl:rounded-br-3xl'
  | '2xl:rounded-br-3xl'
  | 'sm:rounded-bl-3xl'
  | 'md:rounded-bl-3xl'
  | 'lg:rounded-bl-3xl'
  | 'xl:rounded-bl-3xl'
  | '2xl:rounded-bl-3xl'
  | 'sm:rounded-3xl'
  | 'md:rounded-3xl'
  | 'lg:rounded-3xl'
  | 'xl:rounded-3xl'
  | '2xl:rounded-3xl'
  | 'sm:rounded-t-full'
  | 'md:rounded-t-full'
  | 'lg:rounded-t-full'
  | 'xl:rounded-t-full'
  | '2xl:rounded-t-full'
  | 'sm:rounded-r-full'
  | 'md:rounded-r-full'
  | 'lg:rounded-r-full'
  | 'xl:rounded-r-full'
  | '2xl:rounded-r-full'
  | 'sm:rounded-b-full'
  | 'md:rounded-b-full'
  | 'lg:rounded-b-full'
  | 'xl:rounded-b-full'
  | '2xl:rounded-b-full'
  | 'sm:rounded-l-full'
  | 'md:rounded-l-full'
  | 'lg:rounded-l-full'
  | 'xl:rounded-l-full'
  | '2xl:rounded-l-full'
  | 'sm:rounded-tr-full'
  | 'md:rounded-tr-full'
  | 'lg:rounded-tr-full'
  | 'xl:rounded-tr-full'
  | '2xl:rounded-tr-full'
  | 'sm:rounded-tl-full'
  | 'md:rounded-tl-full'
  | 'lg:rounded-tl-full'
  | 'xl:rounded-tl-full'
  | '2xl:rounded-tl-full'
  | 'sm:rounded-br-full'
  | 'md:rounded-br-full'
  | 'lg:rounded-br-full'
  | 'xl:rounded-br-full'
  | '2xl:rounded-br-full'
  | 'sm:rounded-bl-full'
  | 'md:rounded-bl-full'
  | 'lg:rounded-bl-full'
  | 'xl:rounded-bl-full'
  | '2xl:rounded-bl-full'
  | 'sm:rounded-full'
  | 'md:rounded-full'
  | 'lg:rounded-full'
  | 'xl:rounded-full'
  | '2xl:rounded-full'
  | 'sm:border-solid'
  | 'md:border-solid'
  | 'lg:border-solid'
  | 'xl:border-solid'
  | '2xl:border-solid'
  | 'sm:border-dashed'
  | 'md:border-dashed'
  | 'lg:border-dashed'
  | 'xl:border-dashed'
  | '2xl:border-dashed'
  | 'sm:border-dotted'
  | 'md:border-dotted'
  | 'lg:border-dotted'
  | 'xl:border-dotted'
  | '2xl:border-dotted'
  | 'sm:border-double'
  | 'md:border-double'
  | 'lg:border-double'
  | 'xl:border-double'
  | '2xl:border-double'
  | 'sm:border-none'
  | 'md:border-none'
  | 'lg:border-none'
  | 'xl:border-none'
  | '2xl:border-none'
  | 'sm:border-t-0'
  | 'md:border-t-0'
  | 'lg:border-t-0'
  | 'xl:border-t-0'
  | '2xl:border-t-0'
  | 'sm:border-r-0'
  | 'md:border-r-0'
  | 'lg:border-r-0'
  | 'xl:border-r-0'
  | '2xl:border-r-0'
  | 'sm:border-b-0'
  | 'md:border-b-0'
  | 'lg:border-b-0'
  | 'xl:border-b-0'
  | '2xl:border-b-0'
  | 'sm:border-l-0'
  | 'md:border-l-0'
  | 'lg:border-l-0'
  | 'xl:border-l-0'
  | '2xl:border-l-0'
  | 'sm:border-0'
  | 'md:border-0'
  | 'lg:border-0'
  | 'xl:border-0'
  | '2xl:border-0'
  | 'sm:border-t-2'
  | 'md:border-t-2'
  | 'lg:border-t-2'
  | 'xl:border-t-2'
  | '2xl:border-t-2'
  | 'sm:border-r-2'
  | 'md:border-r-2'
  | 'lg:border-r-2'
  | 'xl:border-r-2'
  | '2xl:border-r-2'
  | 'sm:border-b-2'
  | 'md:border-b-2'
  | 'lg:border-b-2'
  | 'xl:border-b-2'
  | '2xl:border-b-2'
  | 'sm:border-l-2'
  | 'md:border-l-2'
  | 'lg:border-l-2'
  | 'xl:border-l-2'
  | '2xl:border-l-2'
  | 'sm:border-2'
  | 'md:border-2'
  | 'lg:border-2'
  | 'xl:border-2'
  | '2xl:border-2'
  | 'sm:border-t-4'
  | 'md:border-t-4'
  | 'lg:border-t-4'
  | 'xl:border-t-4'
  | '2xl:border-t-4'
  | 'sm:border-r-4'
  | 'md:border-r-4'
  | 'lg:border-r-4'
  | 'xl:border-r-4'
  | '2xl:border-r-4'
  | 'sm:border-b-4'
  | 'md:border-b-4'
  | 'lg:border-b-4'
  | 'xl:border-b-4'
  | '2xl:border-b-4'
  | 'sm:border-l-4'
  | 'md:border-l-4'
  | 'lg:border-l-4'
  | 'xl:border-l-4'
  | '2xl:border-l-4'
  | 'sm:border-4'
  | 'md:border-4'
  | 'lg:border-4'
  | 'xl:border-4'
  | '2xl:border-4'
  | 'sm:border-t-8'
  | 'md:border-t-8'
  | 'lg:border-t-8'
  | 'xl:border-t-8'
  | '2xl:border-t-8'
  | 'sm:border-r-8'
  | 'md:border-r-8'
  | 'lg:border-r-8'
  | 'xl:border-r-8'
  | '2xl:border-r-8'
  | 'sm:border-b-8'
  | 'md:border-b-8'
  | 'lg:border-b-8'
  | 'xl:border-b-8'
  | '2xl:border-b-8'
  | 'sm:border-l-8'
  | 'md:border-l-8'
  | 'lg:border-l-8'
  | 'xl:border-l-8'
  | '2xl:border-l-8'
  | 'sm:border-8'
  | 'md:border-8'
  | 'lg:border-8'
  | 'xl:border-8'
  | '2xl:border-8'
  | 'sm:border-t'
  | 'md:border-t'
  | 'lg:border-t'
  | 'xl:border-t'
  | '2xl:border-t'
  | 'sm:border-r'
  | 'md:border-r'
  | 'lg:border-r'
  | 'xl:border-r'
  | '2xl:border-r'
  | 'sm:border-b'
  | 'md:border-b'
  | 'lg:border-b'
  | 'xl:border-b'
  | '2xl:border-b'
  | 'sm:border-l'
  | 'md:border-l'
  | 'lg:border-l'
  | 'xl:border-l'
  | '2xl:border-l'
  | 'sm:border'
  | 'md:border'
  | 'lg:border'
  | 'xl:border'
  | '2xl:border'
  | 'sm:decoration-slice'
  | 'md:decoration-slice'
  | 'lg:decoration-slice'
  | 'xl:decoration-slice'
  | '2xl:decoration-slice'
  | 'sm:decoration-clone'
  | 'md:decoration-clone'
  | 'lg:decoration-clone'
  | 'xl:decoration-clone'
  | '2xl:decoration-clone'
  | 'sm:shadow-sm'
  | 'md:shadow-sm'
  | 'lg:shadow-sm'
  | 'xl:shadow-sm'
  | '2xl:shadow-sm'
  | 'group-hover:shadow-sm'
  | 'focus-within:shadow-sm'
  | 'hover:shadow-sm'
  | 'focus:shadow-sm'
  | 'sm:shadow'
  | 'md:shadow'
  | 'lg:shadow'
  | 'xl:shadow'
  | '2xl:shadow'
  | 'group-hover:shadow'
  | 'focus-within:shadow'
  | 'hover:shadow'
  | 'focus:shadow'
  | 'sm:shadow-md'
  | 'md:shadow-md'
  | 'lg:shadow-md'
  | 'xl:shadow-md'
  | '2xl:shadow-md'
  | 'group-hover:shadow-md'
  | 'focus-within:shadow-md'
  | 'hover:shadow-md'
  | 'focus:shadow-md'
  | 'sm:shadow-lg'
  | 'md:shadow-lg'
  | 'lg:shadow-lg'
  | 'xl:shadow-lg'
  | '2xl:shadow-lg'
  | 'group-hover:shadow-lg'
  | 'focus-within:shadow-lg'
  | 'hover:shadow-lg'
  | 'focus:shadow-lg'
  | 'sm:shadow-xl'
  | 'md:shadow-xl'
  | 'lg:shadow-xl'
  | 'xl:shadow-xl'
  | '2xl:shadow-xl'
  | 'group-hover:shadow-xl'
  | 'focus-within:shadow-xl'
  | 'hover:shadow-xl'
  | 'focus:shadow-xl'
  | 'sm:shadow-2xl'
  | 'md:shadow-2xl'
  | 'lg:shadow-2xl'
  | 'xl:shadow-2xl'
  | '2xl:shadow-2xl'
  | 'group-hover:shadow-2xl'
  | 'focus-within:shadow-2xl'
  | 'hover:shadow-2xl'
  | 'focus:shadow-2xl'
  | 'sm:shadow-inner'
  | 'md:shadow-inner'
  | 'lg:shadow-inner'
  | 'xl:shadow-inner'
  | '2xl:shadow-inner'
  | 'group-hover:shadow-inner'
  | 'focus-within:shadow-inner'
  | 'hover:shadow-inner'
  | 'focus:shadow-inner'
  | 'sm:shadow-none'
  | 'md:shadow-none'
  | 'lg:shadow-none'
  | 'xl:shadow-none'
  | '2xl:shadow-none'
  | 'group-hover:shadow-none'
  | 'focus-within:shadow-none'
  | 'hover:shadow-none'
  | 'focus:shadow-none'
  | 'sm:box-border'
  | 'md:box-border'
  | 'lg:box-border'
  | 'xl:box-border'
  | '2xl:box-border'
  | 'sm:box-content'
  | 'md:box-content'
  | 'lg:box-content'
  | 'xl:box-content'
  | '2xl:box-content'
  | 'sm:brightness-0'
  | 'md:brightness-0'
  | 'lg:brightness-0'
  | 'xl:brightness-0'
  | '2xl:brightness-0'
  | 'sm:brightness-50'
  | 'md:brightness-50'
  | 'lg:brightness-50'
  | 'xl:brightness-50'
  | '2xl:brightness-50'
  | 'sm:brightness-75'
  | 'md:brightness-75'
  | 'lg:brightness-75'
  | 'xl:brightness-75'
  | '2xl:brightness-75'
  | 'sm:brightness-90'
  | 'md:brightness-90'
  | 'lg:brightness-90'
  | 'xl:brightness-90'
  | '2xl:brightness-90'
  | 'sm:brightness-95'
  | 'md:brightness-95'
  | 'lg:brightness-95'
  | 'xl:brightness-95'
  | '2xl:brightness-95'
  | 'sm:brightness-100'
  | 'md:brightness-100'
  | 'lg:brightness-100'
  | 'xl:brightness-100'
  | '2xl:brightness-100'
  | 'sm:brightness-105'
  | 'md:brightness-105'
  | 'lg:brightness-105'
  | 'xl:brightness-105'
  | '2xl:brightness-105'
  | 'sm:brightness-110'
  | 'md:brightness-110'
  | 'lg:brightness-110'
  | 'xl:brightness-110'
  | '2xl:brightness-110'
  | 'sm:brightness-125'
  | 'md:brightness-125'
  | 'lg:brightness-125'
  | 'xl:brightness-125'
  | '2xl:brightness-125'
  | 'sm:brightness-150'
  | 'md:brightness-150'
  | 'lg:brightness-150'
  | 'xl:brightness-150'
  | '2xl:brightness-150'
  | 'sm:brightness-200'
  | 'md:brightness-200'
  | 'lg:brightness-200'
  | 'xl:brightness-200'
  | '2xl:brightness-200'
  | 'sm:clear-left'
  | 'md:clear-left'
  | 'lg:clear-left'
  | 'xl:clear-left'
  | '2xl:clear-left'
  | 'sm:clear-right'
  | 'md:clear-right'
  | 'lg:clear-right'
  | 'xl:clear-right'
  | '2xl:clear-right'
  | 'sm:clear-both'
  | 'md:clear-both'
  | 'lg:clear-both'
  | 'xl:clear-both'
  | '2xl:clear-both'
  | 'sm:clear-none'
  | 'md:clear-none'
  | 'lg:clear-none'
  | 'xl:clear-none'
  | '2xl:clear-none'
  | 'sm:container'
  | 'md:container'
  | 'lg:container'
  | 'xl:container'
  | '2xl:container'
  | 'sm:contrast-0'
  | 'md:contrast-0'
  | 'lg:contrast-0'
  | 'xl:contrast-0'
  | '2xl:contrast-0'
  | 'sm:contrast-50'
  | 'md:contrast-50'
  | 'lg:contrast-50'
  | 'xl:contrast-50'
  | '2xl:contrast-50'
  | 'sm:contrast-75'
  | 'md:contrast-75'
  | 'lg:contrast-75'
  | 'xl:contrast-75'
  | '2xl:contrast-75'
  | 'sm:contrast-100'
  | 'md:contrast-100'
  | 'lg:contrast-100'
  | 'xl:contrast-100'
  | '2xl:contrast-100'
  | 'sm:contrast-125'
  | 'md:contrast-125'
  | 'lg:contrast-125'
  | 'xl:contrast-125'
  | '2xl:contrast-125'
  | 'sm:contrast-150'
  | 'md:contrast-150'
  | 'lg:contrast-150'
  | 'xl:contrast-150'
  | '2xl:contrast-150'
  | 'sm:contrast-200'
  | 'md:contrast-200'
  | 'lg:contrast-200'
  | 'xl:contrast-200'
  | '2xl:contrast-200'
  | 'sm:cursor-auto'
  | 'md:cursor-auto'
  | 'lg:cursor-auto'
  | 'xl:cursor-auto'
  | '2xl:cursor-auto'
  | 'sm:cursor-default'
  | 'md:cursor-default'
  | 'lg:cursor-default'
  | 'xl:cursor-default'
  | '2xl:cursor-default'
  | 'sm:cursor-pointer'
  | 'md:cursor-pointer'
  | 'lg:cursor-pointer'
  | 'xl:cursor-pointer'
  | '2xl:cursor-pointer'
  | 'sm:cursor-wait'
  | 'md:cursor-wait'
  | 'lg:cursor-wait'
  | 'xl:cursor-wait'
  | '2xl:cursor-wait'
  | 'sm:cursor-text'
  | 'md:cursor-text'
  | 'lg:cursor-text'
  | 'xl:cursor-text'
  | '2xl:cursor-text'
  | 'sm:cursor-move'
  | 'md:cursor-move'
  | 'lg:cursor-move'
  | 'xl:cursor-move'
  | '2xl:cursor-move'
  | 'sm:cursor-help'
  | 'md:cursor-help'
  | 'lg:cursor-help'
  | 'xl:cursor-help'
  | '2xl:cursor-help'
  | 'sm:cursor-not-allowed'
  | 'md:cursor-not-allowed'
  | 'lg:cursor-not-allowed'
  | 'xl:cursor-not-allowed'
  | '2xl:cursor-not-allowed'
  | 'sm:block'
  | 'md:block'
  | 'lg:block'
  | 'xl:block'
  | '2xl:block'
  | 'sm:inline-block'
  | 'md:inline-block'
  | 'lg:inline-block'
  | 'xl:inline-block'
  | '2xl:inline-block'
  | 'sm:inline'
  | 'md:inline'
  | 'lg:inline'
  | 'xl:inline'
  | '2xl:inline'
  | 'sm:flex'
  | 'md:flex'
  | 'lg:flex'
  | 'xl:flex'
  | '2xl:flex'
  | 'sm:inline-flex'
  | 'md:inline-flex'
  | 'lg:inline-flex'
  | 'xl:inline-flex'
  | '2xl:inline-flex'
  | 'sm:grid'
  | 'md:grid'
  | 'lg:grid'
  | 'xl:grid'
  | '2xl:grid'
  | 'sm:inline-grid'
  | 'md:inline-grid'
  | 'lg:inline-grid'
  | 'xl:inline-grid'
  | '2xl:inline-grid'
  | 'sm:table'
  | 'md:table'
  | 'lg:table'
  | 'xl:table'
  | '2xl:table'
  | 'sm:inline-table'
  | 'md:inline-table'
  | 'lg:inline-table'
  | 'xl:inline-table'
  | '2xl:inline-table'
  | 'sm:table-row'
  | 'md:table-row'
  | 'lg:table-row'
  | 'xl:table-row'
  | '2xl:table-row'
  | 'sm:table-cell'
  | 'md:table-cell'
  | 'lg:table-cell'
  | 'xl:table-cell'
  | '2xl:table-cell'
  | 'sm:contents'
  | 'md:contents'
  | 'lg:contents'
  | 'xl:contents'
  | '2xl:contents'
  | 'sm:list-item'
  | 'md:list-item'
  | 'lg:list-item'
  | 'xl:list-item'
  | '2xl:list-item'
  | 'sm:hidden'
  | 'md:hidden'
  | 'lg:hidden'
  | 'xl:hidden'
  | '2xl:hidden'
  | 'sm:divide-transparent'
  | 'md:divide-transparent'
  | 'lg:divide-transparent'
  | 'xl:divide-transparent'
  | '2xl:divide-transparent'
  | 'sm:divide-current'
  | 'md:divide-current'
  | 'lg:divide-current'
  | 'xl:divide-current'
  | '2xl:divide-current'
  | 'sm:divide-black'
  | 'md:divide-black'
  | 'lg:divide-black'
  | 'xl:divide-black'
  | '2xl:divide-black'
  | 'sm:divide-white'
  | 'md:divide-white'
  | 'lg:divide-white'
  | 'xl:divide-white'
  | '2xl:divide-white'
  | 'sm:divide-gray-50'
  | 'md:divide-gray-50'
  | 'lg:divide-gray-50'
  | 'xl:divide-gray-50'
  | '2xl:divide-gray-50'
  | 'sm:divide-gray-100'
  | 'md:divide-gray-100'
  | 'lg:divide-gray-100'
  | 'xl:divide-gray-100'
  | '2xl:divide-gray-100'
  | 'sm:divide-gray-200'
  | 'md:divide-gray-200'
  | 'lg:divide-gray-200'
  | 'xl:divide-gray-200'
  | '2xl:divide-gray-200'
  | 'sm:divide-gray-300'
  | 'md:divide-gray-300'
  | 'lg:divide-gray-300'
  | 'xl:divide-gray-300'
  | '2xl:divide-gray-300'
  | 'sm:divide-gray-400'
  | 'md:divide-gray-400'
  | 'lg:divide-gray-400'
  | 'xl:divide-gray-400'
  | '2xl:divide-gray-400'
  | 'sm:divide-gray-500'
  | 'md:divide-gray-500'
  | 'lg:divide-gray-500'
  | 'xl:divide-gray-500'
  | '2xl:divide-gray-500'
  | 'sm:divide-gray-600'
  | 'md:divide-gray-600'
  | 'lg:divide-gray-600'
  | 'xl:divide-gray-600'
  | '2xl:divide-gray-600'
  | 'sm:divide-gray-700'
  | 'md:divide-gray-700'
  | 'lg:divide-gray-700'
  | 'xl:divide-gray-700'
  | '2xl:divide-gray-700'
  | 'sm:divide-gray-800'
  | 'md:divide-gray-800'
  | 'lg:divide-gray-800'
  | 'xl:divide-gray-800'
  | '2xl:divide-gray-800'
  | 'sm:divide-gray-900'
  | 'md:divide-gray-900'
  | 'lg:divide-gray-900'
  | 'xl:divide-gray-900'
  | '2xl:divide-gray-900'
  | 'sm:divide-red-50'
  | 'md:divide-red-50'
  | 'lg:divide-red-50'
  | 'xl:divide-red-50'
  | '2xl:divide-red-50'
  | 'sm:divide-red-100'
  | 'md:divide-red-100'
  | 'lg:divide-red-100'
  | 'xl:divide-red-100'
  | '2xl:divide-red-100'
  | 'sm:divide-red-200'
  | 'md:divide-red-200'
  | 'lg:divide-red-200'
  | 'xl:divide-red-200'
  | '2xl:divide-red-200'
  | 'sm:divide-red-300'
  | 'md:divide-red-300'
  | 'lg:divide-red-300'
  | 'xl:divide-red-300'
  | '2xl:divide-red-300'
  | 'sm:divide-red-400'
  | 'md:divide-red-400'
  | 'lg:divide-red-400'
  | 'xl:divide-red-400'
  | '2xl:divide-red-400'
  | 'sm:divide-red-500'
  | 'md:divide-red-500'
  | 'lg:divide-red-500'
  | 'xl:divide-red-500'
  | '2xl:divide-red-500'
  | 'sm:divide-red-600'
  | 'md:divide-red-600'
  | 'lg:divide-red-600'
  | 'xl:divide-red-600'
  | '2xl:divide-red-600'
  | 'sm:divide-red-700'
  | 'md:divide-red-700'
  | 'lg:divide-red-700'
  | 'xl:divide-red-700'
  | '2xl:divide-red-700'
  | 'sm:divide-red-800'
  | 'md:divide-red-800'
  | 'lg:divide-red-800'
  | 'xl:divide-red-800'
  | '2xl:divide-red-800'
  | 'sm:divide-red-900'
  | 'md:divide-red-900'
  | 'lg:divide-red-900'
  | 'xl:divide-red-900'
  | '2xl:divide-red-900'
  | 'sm:divide-yellow-50'
  | 'md:divide-yellow-50'
  | 'lg:divide-yellow-50'
  | 'xl:divide-yellow-50'
  | '2xl:divide-yellow-50'
  | 'sm:divide-yellow-100'
  | 'md:divide-yellow-100'
  | 'lg:divide-yellow-100'
  | 'xl:divide-yellow-100'
  | '2xl:divide-yellow-100'
  | 'sm:divide-yellow-200'
  | 'md:divide-yellow-200'
  | 'lg:divide-yellow-200'
  | 'xl:divide-yellow-200'
  | '2xl:divide-yellow-200'
  | 'sm:divide-yellow-300'
  | 'md:divide-yellow-300'
  | 'lg:divide-yellow-300'
  | 'xl:divide-yellow-300'
  | '2xl:divide-yellow-300'
  | 'sm:divide-yellow-400'
  | 'md:divide-yellow-400'
  | 'lg:divide-yellow-400'
  | 'xl:divide-yellow-400'
  | '2xl:divide-yellow-400'
  | 'sm:divide-yellow-500'
  | 'md:divide-yellow-500'
  | 'lg:divide-yellow-500'
  | 'xl:divide-yellow-500'
  | '2xl:divide-yellow-500'
  | 'sm:divide-yellow-600'
  | 'md:divide-yellow-600'
  | 'lg:divide-yellow-600'
  | 'xl:divide-yellow-600'
  | '2xl:divide-yellow-600'
  | 'sm:divide-yellow-700'
  | 'md:divide-yellow-700'
  | 'lg:divide-yellow-700'
  | 'xl:divide-yellow-700'
  | '2xl:divide-yellow-700'
  | 'sm:divide-yellow-800'
  | 'md:divide-yellow-800'
  | 'lg:divide-yellow-800'
  | 'xl:divide-yellow-800'
  | '2xl:divide-yellow-800'
  | 'sm:divide-yellow-900'
  | 'md:divide-yellow-900'
  | 'lg:divide-yellow-900'
  | 'xl:divide-yellow-900'
  | '2xl:divide-yellow-900'
  | 'sm:divide-green-50'
  | 'md:divide-green-50'
  | 'lg:divide-green-50'
  | 'xl:divide-green-50'
  | '2xl:divide-green-50'
  | 'sm:divide-green-100'
  | 'md:divide-green-100'
  | 'lg:divide-green-100'
  | 'xl:divide-green-100'
  | '2xl:divide-green-100'
  | 'sm:divide-green-200'
  | 'md:divide-green-200'
  | 'lg:divide-green-200'
  | 'xl:divide-green-200'
  | '2xl:divide-green-200'
  | 'sm:divide-green-300'
  | 'md:divide-green-300'
  | 'lg:divide-green-300'
  | 'xl:divide-green-300'
  | '2xl:divide-green-300'
  | 'sm:divide-green-400'
  | 'md:divide-green-400'
  | 'lg:divide-green-400'
  | 'xl:divide-green-400'
  | '2xl:divide-green-400'
  | 'sm:divide-green-500'
  | 'md:divide-green-500'
  | 'lg:divide-green-500'
  | 'xl:divide-green-500'
  | '2xl:divide-green-500'
  | 'sm:divide-green-600'
  | 'md:divide-green-600'
  | 'lg:divide-green-600'
  | 'xl:divide-green-600'
  | '2xl:divide-green-600'
  | 'sm:divide-green-700'
  | 'md:divide-green-700'
  | 'lg:divide-green-700'
  | 'xl:divide-green-700'
  | '2xl:divide-green-700'
  | 'sm:divide-green-800'
  | 'md:divide-green-800'
  | 'lg:divide-green-800'
  | 'xl:divide-green-800'
  | '2xl:divide-green-800'
  | 'sm:divide-green-900'
  | 'md:divide-green-900'
  | 'lg:divide-green-900'
  | 'xl:divide-green-900'
  | '2xl:divide-green-900'
  | 'sm:divide-blue-50'
  | 'md:divide-blue-50'
  | 'lg:divide-blue-50'
  | 'xl:divide-blue-50'
  | '2xl:divide-blue-50'
  | 'sm:divide-blue-100'
  | 'md:divide-blue-100'
  | 'lg:divide-blue-100'
  | 'xl:divide-blue-100'
  | '2xl:divide-blue-100'
  | 'sm:divide-blue-200'
  | 'md:divide-blue-200'
  | 'lg:divide-blue-200'
  | 'xl:divide-blue-200'
  | '2xl:divide-blue-200'
  | 'sm:divide-blue-300'
  | 'md:divide-blue-300'
  | 'lg:divide-blue-300'
  | 'xl:divide-blue-300'
  | '2xl:divide-blue-300'
  | 'sm:divide-blue-400'
  | 'md:divide-blue-400'
  | 'lg:divide-blue-400'
  | 'xl:divide-blue-400'
  | '2xl:divide-blue-400'
  | 'sm:divide-blue-500'
  | 'md:divide-blue-500'
  | 'lg:divide-blue-500'
  | 'xl:divide-blue-500'
  | '2xl:divide-blue-500'
  | 'sm:divide-blue-600'
  | 'md:divide-blue-600'
  | 'lg:divide-blue-600'
  | 'xl:divide-blue-600'
  | '2xl:divide-blue-600'
  | 'sm:divide-blue-700'
  | 'md:divide-blue-700'
  | 'lg:divide-blue-700'
  | 'xl:divide-blue-700'
  | '2xl:divide-blue-700'
  | 'sm:divide-blue-800'
  | 'md:divide-blue-800'
  | 'lg:divide-blue-800'
  | 'xl:divide-blue-800'
  | '2xl:divide-blue-800'
  | 'sm:divide-blue-900'
  | 'md:divide-blue-900'
  | 'lg:divide-blue-900'
  | 'xl:divide-blue-900'
  | '2xl:divide-blue-900'
  | 'sm:divide-indigo-50'
  | 'md:divide-indigo-50'
  | 'lg:divide-indigo-50'
  | 'xl:divide-indigo-50'
  | '2xl:divide-indigo-50'
  | 'sm:divide-indigo-100'
  | 'md:divide-indigo-100'
  | 'lg:divide-indigo-100'
  | 'xl:divide-indigo-100'
  | '2xl:divide-indigo-100'
  | 'sm:divide-indigo-200'
  | 'md:divide-indigo-200'
  | 'lg:divide-indigo-200'
  | 'xl:divide-indigo-200'
  | '2xl:divide-indigo-200'
  | 'sm:divide-indigo-300'
  | 'md:divide-indigo-300'
  | 'lg:divide-indigo-300'
  | 'xl:divide-indigo-300'
  | '2xl:divide-indigo-300'
  | 'sm:divide-indigo-400'
  | 'md:divide-indigo-400'
  | 'lg:divide-indigo-400'
  | 'xl:divide-indigo-400'
  | '2xl:divide-indigo-400'
  | 'sm:divide-indigo-500'
  | 'md:divide-indigo-500'
  | 'lg:divide-indigo-500'
  | 'xl:divide-indigo-500'
  | '2xl:divide-indigo-500'
  | 'sm:divide-indigo-600'
  | 'md:divide-indigo-600'
  | 'lg:divide-indigo-600'
  | 'xl:divide-indigo-600'
  | '2xl:divide-indigo-600'
  | 'sm:divide-indigo-700'
  | 'md:divide-indigo-700'
  | 'lg:divide-indigo-700'
  | 'xl:divide-indigo-700'
  | '2xl:divide-indigo-700'
  | 'sm:divide-indigo-800'
  | 'md:divide-indigo-800'
  | 'lg:divide-indigo-800'
  | 'xl:divide-indigo-800'
  | '2xl:divide-indigo-800'
  | 'sm:divide-indigo-900'
  | 'md:divide-indigo-900'
  | 'lg:divide-indigo-900'
  | 'xl:divide-indigo-900'
  | '2xl:divide-indigo-900'
  | 'sm:divide-purple-50'
  | 'md:divide-purple-50'
  | 'lg:divide-purple-50'
  | 'xl:divide-purple-50'
  | '2xl:divide-purple-50'
  | 'sm:divide-purple-100'
  | 'md:divide-purple-100'
  | 'lg:divide-purple-100'
  | 'xl:divide-purple-100'
  | '2xl:divide-purple-100'
  | 'sm:divide-purple-200'
  | 'md:divide-purple-200'
  | 'lg:divide-purple-200'
  | 'xl:divide-purple-200'
  | '2xl:divide-purple-200'
  | 'sm:divide-purple-300'
  | 'md:divide-purple-300'
  | 'lg:divide-purple-300'
  | 'xl:divide-purple-300'
  | '2xl:divide-purple-300'
  | 'sm:divide-purple-400'
  | 'md:divide-purple-400'
  | 'lg:divide-purple-400'
  | 'xl:divide-purple-400'
  | '2xl:divide-purple-400'
  | 'sm:divide-purple-500'
  | 'md:divide-purple-500'
  | 'lg:divide-purple-500'
  | 'xl:divide-purple-500'
  | '2xl:divide-purple-500'
  | 'sm:divide-purple-600'
  | 'md:divide-purple-600'
  | 'lg:divide-purple-600'
  | 'xl:divide-purple-600'
  | '2xl:divide-purple-600'
  | 'sm:divide-purple-700'
  | 'md:divide-purple-700'
  | 'lg:divide-purple-700'
  | 'xl:divide-purple-700'
  | '2xl:divide-purple-700'
  | 'sm:divide-purple-800'
  | 'md:divide-purple-800'
  | 'lg:divide-purple-800'
  | 'xl:divide-purple-800'
  | '2xl:divide-purple-800'
  | 'sm:divide-purple-900'
  | 'md:divide-purple-900'
  | 'lg:divide-purple-900'
  | 'xl:divide-purple-900'
  | '2xl:divide-purple-900'
  | 'sm:divide-pink-50'
  | 'md:divide-pink-50'
  | 'lg:divide-pink-50'
  | 'xl:divide-pink-50'
  | '2xl:divide-pink-50'
  | 'sm:divide-pink-100'
  | 'md:divide-pink-100'
  | 'lg:divide-pink-100'
  | 'xl:divide-pink-100'
  | '2xl:divide-pink-100'
  | 'sm:divide-pink-200'
  | 'md:divide-pink-200'
  | 'lg:divide-pink-200'
  | 'xl:divide-pink-200'
  | '2xl:divide-pink-200'
  | 'sm:divide-pink-300'
  | 'md:divide-pink-300'
  | 'lg:divide-pink-300'
  | 'xl:divide-pink-300'
  | '2xl:divide-pink-300'
  | 'sm:divide-pink-400'
  | 'md:divide-pink-400'
  | 'lg:divide-pink-400'
  | 'xl:divide-pink-400'
  | '2xl:divide-pink-400'
  | 'sm:divide-pink-500'
  | 'md:divide-pink-500'
  | 'lg:divide-pink-500'
  | 'xl:divide-pink-500'
  | '2xl:divide-pink-500'
  | 'sm:divide-pink-600'
  | 'md:divide-pink-600'
  | 'lg:divide-pink-600'
  | 'xl:divide-pink-600'
  | '2xl:divide-pink-600'
  | 'sm:divide-pink-700'
  | 'md:divide-pink-700'
  | 'lg:divide-pink-700'
  | 'xl:divide-pink-700'
  | '2xl:divide-pink-700'
  | 'sm:divide-pink-800'
  | 'md:divide-pink-800'
  | 'lg:divide-pink-800'
  | 'xl:divide-pink-800'
  | '2xl:divide-pink-800'
  | 'sm:divide-pink-900'
  | 'md:divide-pink-900'
  | 'lg:divide-pink-900'
  | 'xl:divide-pink-900'
  | '2xl:divide-pink-900'
  | 'sm:divide-opacity-0'
  | 'md:divide-opacity-0'
  | 'lg:divide-opacity-0'
  | 'xl:divide-opacity-0'
  | '2xl:divide-opacity-0'
  | 'sm:divide-opacity-5'
  | 'md:divide-opacity-5'
  | 'lg:divide-opacity-5'
  | 'xl:divide-opacity-5'
  | '2xl:divide-opacity-5'
  | 'sm:divide-opacity-10'
  | 'md:divide-opacity-10'
  | 'lg:divide-opacity-10'
  | 'xl:divide-opacity-10'
  | '2xl:divide-opacity-10'
  | 'sm:divide-opacity-20'
  | 'md:divide-opacity-20'
  | 'lg:divide-opacity-20'
  | 'xl:divide-opacity-20'
  | '2xl:divide-opacity-20'
  | 'sm:divide-opacity-25'
  | 'md:divide-opacity-25'
  | 'lg:divide-opacity-25'
  | 'xl:divide-opacity-25'
  | '2xl:divide-opacity-25'
  | 'sm:divide-opacity-30'
  | 'md:divide-opacity-30'
  | 'lg:divide-opacity-30'
  | 'xl:divide-opacity-30'
  | '2xl:divide-opacity-30'
  | 'sm:divide-opacity-40'
  | 'md:divide-opacity-40'
  | 'lg:divide-opacity-40'
  | 'xl:divide-opacity-40'
  | '2xl:divide-opacity-40'
  | 'sm:divide-opacity-50'
  | 'md:divide-opacity-50'
  | 'lg:divide-opacity-50'
  | 'xl:divide-opacity-50'
  | '2xl:divide-opacity-50'
  | 'sm:divide-opacity-60'
  | 'md:divide-opacity-60'
  | 'lg:divide-opacity-60'
  | 'xl:divide-opacity-60'
  | '2xl:divide-opacity-60'
  | 'sm:divide-opacity-70'
  | 'md:divide-opacity-70'
  | 'lg:divide-opacity-70'
  | 'xl:divide-opacity-70'
  | '2xl:divide-opacity-70'
  | 'sm:divide-opacity-75'
  | 'md:divide-opacity-75'
  | 'lg:divide-opacity-75'
  | 'xl:divide-opacity-75'
  | '2xl:divide-opacity-75'
  | 'sm:divide-opacity-80'
  | 'md:divide-opacity-80'
  | 'lg:divide-opacity-80'
  | 'xl:divide-opacity-80'
  | '2xl:divide-opacity-80'
  | 'sm:divide-opacity-90'
  | 'md:divide-opacity-90'
  | 'lg:divide-opacity-90'
  | 'xl:divide-opacity-90'
  | '2xl:divide-opacity-90'
  | 'sm:divide-opacity-95'
  | 'md:divide-opacity-95'
  | 'lg:divide-opacity-95'
  | 'xl:divide-opacity-95'
  | '2xl:divide-opacity-95'
  | 'sm:divide-opacity-100'
  | 'md:divide-opacity-100'
  | 'lg:divide-opacity-100'
  | 'xl:divide-opacity-100'
  | '2xl:divide-opacity-100'
  | 'sm:divide-solid'
  | 'md:divide-solid'
  | 'lg:divide-solid'
  | 'xl:divide-solid'
  | '2xl:divide-solid'
  | 'sm:divide-dashed'
  | 'md:divide-dashed'
  | 'lg:divide-dashed'
  | 'xl:divide-dashed'
  | '2xl:divide-dashed'
  | 'sm:divide-dotted'
  | 'md:divide-dotted'
  | 'lg:divide-dotted'
  | 'xl:divide-dotted'
  | '2xl:divide-dotted'
  | 'sm:divide-double'
  | 'md:divide-double'
  | 'lg:divide-double'
  | 'xl:divide-double'
  | '2xl:divide-double'
  | 'sm:divide-none'
  | 'md:divide-none'
  | 'lg:divide-none'
  | 'xl:divide-none'
  | '2xl:divide-none'
  | 'sm:divide-x-0'
  | 'md:divide-x-0'
  | 'lg:divide-x-0'
  | 'xl:divide-x-0'
  | '2xl:divide-x-0'
  | 'sm:divide-y-0'
  | 'md:divide-y-0'
  | 'lg:divide-y-0'
  | 'xl:divide-y-0'
  | '2xl:divide-y-0'
  | 'sm:divide-x-2'
  | 'md:divide-x-2'
  | 'lg:divide-x-2'
  | 'xl:divide-x-2'
  | '2xl:divide-x-2'
  | 'sm:divide-y-2'
  | 'md:divide-y-2'
  | 'lg:divide-y-2'
  | 'xl:divide-y-2'
  | '2xl:divide-y-2'
  | 'sm:divide-x-4'
  | 'md:divide-x-4'
  | 'lg:divide-x-4'
  | 'xl:divide-x-4'
  | '2xl:divide-x-4'
  | 'sm:divide-y-4'
  | 'md:divide-y-4'
  | 'lg:divide-y-4'
  | 'xl:divide-y-4'
  | '2xl:divide-y-4'
  | 'sm:divide-x-8'
  | 'md:divide-x-8'
  | 'lg:divide-x-8'
  | 'xl:divide-x-8'
  | '2xl:divide-x-8'
  | 'sm:divide-y-8'
  | 'md:divide-y-8'
  | 'lg:divide-y-8'
  | 'xl:divide-y-8'
  | '2xl:divide-y-8'
  | 'sm:divide-x'
  | 'md:divide-x'
  | 'lg:divide-x'
  | 'xl:divide-x'
  | '2xl:divide-x'
  | 'sm:divide-y'
  | 'md:divide-y'
  | 'lg:divide-y'
  | 'xl:divide-y'
  | '2xl:divide-y'
  | 'sm:divide-x-reverse'
  | 'md:divide-x-reverse'
  | 'lg:divide-x-reverse'
  | 'xl:divide-x-reverse'
  | '2xl:divide-x-reverse'
  | 'sm:divide-y-reverse'
  | 'md:divide-y-reverse'
  | 'lg:divide-y-reverse'
  | 'xl:divide-y-reverse'
  | '2xl:divide-y-reverse'
  | 'sm:drop-shadow-sm'
  | 'md:drop-shadow-sm'
  | 'lg:drop-shadow-sm'
  | 'xl:drop-shadow-sm'
  | '2xl:drop-shadow-sm'
  | 'sm:drop-shadow'
  | 'md:drop-shadow'
  | 'lg:drop-shadow'
  | 'xl:drop-shadow'
  | '2xl:drop-shadow'
  | 'sm:drop-shadow-md'
  | 'md:drop-shadow-md'
  | 'lg:drop-shadow-md'
  | 'xl:drop-shadow-md'
  | '2xl:drop-shadow-md'
  | 'sm:drop-shadow-lg'
  | 'md:drop-shadow-lg'
  | 'lg:drop-shadow-lg'
  | 'xl:drop-shadow-lg'
  | '2xl:drop-shadow-lg'
  | 'sm:drop-shadow-xl'
  | 'md:drop-shadow-xl'
  | 'lg:drop-shadow-xl'
  | 'xl:drop-shadow-xl'
  | '2xl:drop-shadow-xl'
  | 'sm:drop-shadow-2xl'
  | 'md:drop-shadow-2xl'
  | 'lg:drop-shadow-2xl'
  | 'xl:drop-shadow-2xl'
  | '2xl:drop-shadow-2xl'
  | 'sm:drop-shadow-none'
  | 'md:drop-shadow-none'
  | 'lg:drop-shadow-none'
  | 'xl:drop-shadow-none'
  | '2xl:drop-shadow-none'
  | 'sm:fill-current'
  | 'md:fill-current'
  | 'lg:fill-current'
  | 'xl:fill-current'
  | '2xl:fill-current'
  | 'sm:filter'
  | 'md:filter'
  | 'lg:filter'
  | 'xl:filter'
  | '2xl:filter'
  | 'sm:filter-none'
  | 'md:filter-none'
  | 'lg:filter-none'
  | 'xl:filter-none'
  | '2xl:filter-none'
  | 'sm:flex-initial'
  | 'md:flex-initial'
  | 'lg:flex-initial'
  | 'xl:flex-initial'
  | '2xl:flex-initial'
  | 'sm:flex-1'
  | 'md:flex-1'
  | 'lg:flex-1'
  | 'xl:flex-1'
  | '2xl:flex-1'
  | 'sm:flex-auto'
  | 'md:flex-auto'
  | 'lg:flex-auto'
  | 'xl:flex-auto'
  | '2xl:flex-auto'
  | 'sm:flex-none'
  | 'md:flex-none'
  | 'lg:flex-none'
  | 'xl:flex-none'
  | '2xl:flex-none'
  | 'sm:flex-row'
  | 'md:flex-row'
  | 'lg:flex-row'
  | 'xl:flex-row'
  | '2xl:flex-row'
  | 'sm:flex-row-reverse'
  | 'md:flex-row-reverse'
  | 'lg:flex-row-reverse'
  | 'xl:flex-row-reverse'
  | '2xl:flex-row-reverse'
  | 'sm:flex-col'
  | 'md:flex-col'
  | 'lg:flex-col'
  | 'xl:flex-col'
  | '2xl:flex-col'
  | 'sm:flex-col-reverse'
  | 'md:flex-col-reverse'
  | 'lg:flex-col-reverse'
  | 'xl:flex-col-reverse'
  | '2xl:flex-col-reverse'
  | 'sm:flex-grow-0'
  | 'md:flex-grow-0'
  | 'lg:flex-grow-0'
  | 'xl:flex-grow-0'
  | '2xl:flex-grow-0'
  | 'sm:flex-grow'
  | 'md:flex-grow'
  | 'lg:flex-grow'
  | 'xl:flex-grow'
  | '2xl:flex-grow'
  | 'sm:flex-shrink-0'
  | 'md:flex-shrink-0'
  | 'lg:flex-shrink-0'
  | 'xl:flex-shrink-0'
  | '2xl:flex-shrink-0'
  | 'sm:flex-shrink'
  | 'md:flex-shrink'
  | 'lg:flex-shrink'
  | 'xl:flex-shrink'
  | '2xl:flex-shrink'
  | 'sm:flex-nowrap'
  | 'md:flex-nowrap'
  | 'lg:flex-nowrap'
  | 'xl:flex-nowrap'
  | '2xl:flex-nowrap'
  | 'sm:flex-wrap'
  | 'md:flex-wrap'
  | 'lg:flex-wrap'
  | 'xl:flex-wrap'
  | '2xl:flex-wrap'
  | 'sm:flex-wrap-reverse'
  | 'md:flex-wrap-reverse'
  | 'lg:flex-wrap-reverse'
  | 'xl:flex-wrap-reverse'
  | '2xl:flex-wrap-reverse'
  | 'sm:float-right'
  | 'md:float-right'
  | 'lg:float-right'
  | 'xl:float-right'
  | '2xl:float-right'
  | 'sm:float-left'
  | 'md:float-left'
  | 'lg:float-left'
  | 'xl:float-left'
  | '2xl:float-left'
  | 'sm:float-none'
  | 'md:float-none'
  | 'lg:float-none'
  | 'xl:float-none'
  | '2xl:float-none'
  | 'sm:font-sans'
  | 'md:font-sans'
  | 'lg:font-sans'
  | 'xl:font-sans'
  | '2xl:font-sans'
  | 'sm:font-serif'
  | 'md:font-serif'
  | 'lg:font-serif'
  | 'xl:font-serif'
  | '2xl:font-serif'
  | 'sm:font-mono'
  | 'md:font-mono'
  | 'lg:font-mono'
  | 'xl:font-mono'
  | '2xl:font-mono'
  | 'sm:text-xs'
  | 'md:text-xs'
  | 'lg:text-xs'
  | 'xl:text-xs'
  | '2xl:text-xs'
  | 'sm:text-sm'
  | 'md:text-sm'
  | 'lg:text-sm'
  | 'xl:text-sm'
  | '2xl:text-sm'
  | 'sm:text-base'
  | 'md:text-base'
  | 'lg:text-base'
  | 'xl:text-base'
  | '2xl:text-base'
  | 'sm:text-lg'
  | 'md:text-lg'
  | 'lg:text-lg'
  | 'xl:text-lg'
  | '2xl:text-lg'
  | 'sm:text-xl'
  | 'md:text-xl'
  | 'lg:text-xl'
  | 'xl:text-xl'
  | '2xl:text-xl'
  | 'sm:text-2xl'
  | 'md:text-2xl'
  | 'lg:text-2xl'
  | 'xl:text-2xl'
  | '2xl:text-2xl'
  | 'sm:text-3xl'
  | 'md:text-3xl'
  | 'lg:text-3xl'
  | 'xl:text-3xl'
  | '2xl:text-3xl'
  | 'sm:text-4xl'
  | 'md:text-4xl'
  | 'lg:text-4xl'
  | 'xl:text-4xl'
  | '2xl:text-4xl'
  | 'sm:text-5xl'
  | 'md:text-5xl'
  | 'lg:text-5xl'
  | 'xl:text-5xl'
  | '2xl:text-5xl'
  | 'sm:text-6xl'
  | 'md:text-6xl'
  | 'lg:text-6xl'
  | 'xl:text-6xl'
  | '2xl:text-6xl'
  | 'sm:text-7xl'
  | 'md:text-7xl'
  | 'lg:text-7xl'
  | 'xl:text-7xl'
  | '2xl:text-7xl'
  | 'sm:text-8xl'
  | 'md:text-8xl'
  | 'lg:text-8xl'
  | 'xl:text-8xl'
  | '2xl:text-8xl'
  | 'sm:text-9xl'
  | 'md:text-9xl'
  | 'lg:text-9xl'
  | 'xl:text-9xl'
  | '2xl:text-9xl'
  | 'sm:antialiased'
  | 'md:antialiased'
  | 'lg:antialiased'
  | 'xl:antialiased'
  | '2xl:antialiased'
  | 'sm:subpixel-antialiased'
  | 'md:subpixel-antialiased'
  | 'lg:subpixel-antialiased'
  | 'xl:subpixel-antialiased'
  | '2xl:subpixel-antialiased'
  | 'sm:italic'
  | 'md:italic'
  | 'lg:italic'
  | 'xl:italic'
  | '2xl:italic'
  | 'sm:non-italic'
  | 'md:non-italic'
  | 'lg:non-italic'
  | 'xl:non-italic'
  | '2xl:non-italic'
  | 'sm:normal-nums'
  | 'md:normal-nums'
  | 'lg:normal-nums'
  | 'xl:normal-nums'
  | '2xl:normal-nums'
  | 'sm:ordinal'
  | 'md:ordinal'
  | 'lg:ordinal'
  | 'xl:ordinal'
  | '2xl:ordinal'
  | 'sm:slashed-zero'
  | 'md:slashed-zero'
  | 'lg:slashed-zero'
  | 'xl:slashed-zero'
  | '2xl:slashed-zero'
  | 'sm:lining-nums'
  | 'md:lining-nums'
  | 'lg:lining-nums'
  | 'xl:lining-nums'
  | '2xl:lining-nums'
  | 'sm:oldstyle-nums'
  | 'md:oldstyle-nums'
  | 'lg:oldstyle-nums'
  | 'xl:oldstyle-nums'
  | '2xl:oldstyle-nums'
  | 'sm:proportional-nums'
  | 'md:proportional-nums'
  | 'lg:proportional-nums'
  | 'xl:proportional-nums'
  | '2xl:proportional-nums'
  | 'sm:tabular-nums'
  | 'md:tabular-nums'
  | 'lg:tabular-nums'
  | 'xl:tabular-nums'
  | '2xl:tabular-nums'
  | 'sm:diagonal-fractions'
  | 'md:diagonal-fractions'
  | 'lg:diagonal-fractions'
  | 'xl:diagonal-fractions'
  | '2xl:diagonal-fractions'
  | 'sm:stacked-fractions'
  | 'md:stacked-fractions'
  | 'lg:stacked-fractions'
  | 'xl:stacked-fractions'
  | '2xl:stacked-fractions'
  | 'sm:font-thin'
  | 'md:font-thin'
  | 'lg:font-thin'
  | 'xl:font-thin'
  | '2xl:font-thin'
  | 'sm:font-extralight'
  | 'md:font-extralight'
  | 'lg:font-extralight'
  | 'xl:font-extralight'
  | '2xl:font-extralight'
  | 'sm:font-light'
  | 'md:font-light'
  | 'lg:font-light'
  | 'xl:font-light'
  | '2xl:font-light'
  | 'sm:font-normal'
  | 'md:font-normal'
  | 'lg:font-normal'
  | 'xl:font-normal'
  | '2xl:font-normal'
  | 'sm:font-medium'
  | 'md:font-medium'
  | 'lg:font-medium'
  | 'xl:font-medium'
  | '2xl:font-medium'
  | 'sm:font-semibold'
  | 'md:font-semibold'
  | 'lg:font-semibold'
  | 'xl:font-semibold'
  | '2xl:font-semibold'
  | 'sm:font-bold'
  | 'md:font-bold'
  | 'lg:font-bold'
  | 'xl:font-bold'
  | '2xl:font-bold'
  | 'sm:font-extrabold'
  | 'md:font-extrabold'
  | 'lg:font-extrabold'
  | 'xl:font-extrabold'
  | '2xl:font-extrabold'
  | 'sm:font-black'
  | 'md:font-black'
  | 'lg:font-black'
  | 'xl:font-black'
  | '2xl:font-black'
  | 'sm:gap-0'
  | 'md:gap-0'
  | 'lg:gap-0'
  | 'xl:gap-0'
  | '2xl:gap-0'
  | 'sm:gap-1'
  | 'md:gap-1'
  | 'lg:gap-1'
  | 'xl:gap-1'
  | '2xl:gap-1'
  | 'sm:gap-2'
  | 'md:gap-2'
  | 'lg:gap-2'
  | 'xl:gap-2'
  | '2xl:gap-2'
  | 'sm:gap-3'
  | 'md:gap-3'
  | 'lg:gap-3'
  | 'xl:gap-3'
  | '2xl:gap-3'
  | 'sm:gap-4'
  | 'md:gap-4'
  | 'lg:gap-4'
  | 'xl:gap-4'
  | '2xl:gap-4'
  | 'sm:gap-5'
  | 'md:gap-5'
  | 'lg:gap-5'
  | 'xl:gap-5'
  | '2xl:gap-5'
  | 'sm:gap-6'
  | 'md:gap-6'
  | 'lg:gap-6'
  | 'xl:gap-6'
  | '2xl:gap-6'
  | 'sm:gap-7'
  | 'md:gap-7'
  | 'lg:gap-7'
  | 'xl:gap-7'
  | '2xl:gap-7'
  | 'sm:gap-8'
  | 'md:gap-8'
  | 'lg:gap-8'
  | 'xl:gap-8'
  | '2xl:gap-8'
  | 'sm:gap-9'
  | 'md:gap-9'
  | 'lg:gap-9'
  | 'xl:gap-9'
  | '2xl:gap-9'
  | 'sm:gap-10'
  | 'md:gap-10'
  | 'lg:gap-10'
  | 'xl:gap-10'
  | '2xl:gap-10'
  | 'sm:gap-11'
  | 'md:gap-11'
  | 'lg:gap-11'
  | 'xl:gap-11'
  | '2xl:gap-11'
  | 'sm:gap-12'
  | 'md:gap-12'
  | 'lg:gap-12'
  | 'xl:gap-12'
  | '2xl:gap-12'
  | 'sm:gap-14'
  | 'md:gap-14'
  | 'lg:gap-14'
  | 'xl:gap-14'
  | '2xl:gap-14'
  | 'sm:gap-16'
  | 'md:gap-16'
  | 'lg:gap-16'
  | 'xl:gap-16'
  | '2xl:gap-16'
  | 'sm:gap-20'
  | 'md:gap-20'
  | 'lg:gap-20'
  | 'xl:gap-20'
  | '2xl:gap-20'
  | 'sm:gap-24'
  | 'md:gap-24'
  | 'lg:gap-24'
  | 'xl:gap-24'
  | '2xl:gap-24'
  | 'sm:gap-28'
  | 'md:gap-28'
  | 'lg:gap-28'
  | 'xl:gap-28'
  | '2xl:gap-28'
  | 'sm:gap-32'
  | 'md:gap-32'
  | 'lg:gap-32'
  | 'xl:gap-32'
  | '2xl:gap-32'
  | 'sm:gap-36'
  | 'md:gap-36'
  | 'lg:gap-36'
  | 'xl:gap-36'
  | '2xl:gap-36'
  | 'sm:gap-40'
  | 'md:gap-40'
  | 'lg:gap-40'
  | 'xl:gap-40'
  | '2xl:gap-40'
  | 'sm:gap-44'
  | 'md:gap-44'
  | 'lg:gap-44'
  | 'xl:gap-44'
  | '2xl:gap-44'
  | 'sm:gap-48'
  | 'md:gap-48'
  | 'lg:gap-48'
  | 'xl:gap-48'
  | '2xl:gap-48'
  | 'sm:gap-52'
  | 'md:gap-52'
  | 'lg:gap-52'
  | 'xl:gap-52'
  | '2xl:gap-52'
  | 'sm:gap-56'
  | 'md:gap-56'
  | 'lg:gap-56'
  | 'xl:gap-56'
  | '2xl:gap-56'
  | 'sm:gap-60'
  | 'md:gap-60'
  | 'lg:gap-60'
  | 'xl:gap-60'
  | '2xl:gap-60'
  | 'sm:gap-64'
  | 'md:gap-64'
  | 'lg:gap-64'
  | 'xl:gap-64'
  | '2xl:gap-64'
  | 'sm:gap-72'
  | 'md:gap-72'
  | 'lg:gap-72'
  | 'xl:gap-72'
  | '2xl:gap-72'
  | 'sm:gap-80'
  | 'md:gap-80'
  | 'lg:gap-80'
  | 'xl:gap-80'
  | '2xl:gap-80'
  | 'sm:gap-96'
  | 'md:gap-96'
  | 'lg:gap-96'
  | 'xl:gap-96'
  | '2xl:gap-96'
  | 'sm:gap-px'
  | 'md:gap-px'
  | 'lg:gap-px'
  | 'xl:gap-px'
  | '2xl:gap-px'
  | 'sm:gap-0.5'
  | 'md:gap-0.5'
  | 'lg:gap-0.5'
  | 'xl:gap-0.5'
  | '2xl:gap-0.5'
  | 'sm:gap-1.5'
  | 'md:gap-1.5'
  | 'lg:gap-1.5'
  | 'xl:gap-1.5'
  | '2xl:gap-1.5'
  | 'sm:gap-2.5'
  | 'md:gap-2.5'
  | 'lg:gap-2.5'
  | 'xl:gap-2.5'
  | '2xl:gap-2.5'
  | 'sm:gap-3.5'
  | 'md:gap-3.5'
  | 'lg:gap-3.5'
  | 'xl:gap-3.5'
  | '2xl:gap-3.5'
  | 'sm:gap-y-0'
  | 'md:gap-y-0'
  | 'lg:gap-y-0'
  | 'xl:gap-y-0'
  | '2xl:gap-y-0'
  | 'sm:gap-y-1'
  | 'md:gap-y-1'
  | 'lg:gap-y-1'
  | 'xl:gap-y-1'
  | '2xl:gap-y-1'
  | 'sm:gap-y-2'
  | 'md:gap-y-2'
  | 'lg:gap-y-2'
  | 'xl:gap-y-2'
  | '2xl:gap-y-2'
  | 'sm:gap-y-3'
  | 'md:gap-y-3'
  | 'lg:gap-y-3'
  | 'xl:gap-y-3'
  | '2xl:gap-y-3'
  | 'sm:gap-y-4'
  | 'md:gap-y-4'
  | 'lg:gap-y-4'
  | 'xl:gap-y-4'
  | '2xl:gap-y-4'
  | 'sm:gap-y-5'
  | 'md:gap-y-5'
  | 'lg:gap-y-5'
  | 'xl:gap-y-5'
  | '2xl:gap-y-5'
  | 'sm:gap-y-6'
  | 'md:gap-y-6'
  | 'lg:gap-y-6'
  | 'xl:gap-y-6'
  | '2xl:gap-y-6'
  | 'sm:gap-y-7'
  | 'md:gap-y-7'
  | 'lg:gap-y-7'
  | 'xl:gap-y-7'
  | '2xl:gap-y-7'
  | 'sm:gap-y-8'
  | 'md:gap-y-8'
  | 'lg:gap-y-8'
  | 'xl:gap-y-8'
  | '2xl:gap-y-8'
  | 'sm:gap-y-9'
  | 'md:gap-y-9'
  | 'lg:gap-y-9'
  | 'xl:gap-y-9'
  | '2xl:gap-y-9'
  | 'sm:gap-y-10'
  | 'md:gap-y-10'
  | 'lg:gap-y-10'
  | 'xl:gap-y-10'
  | '2xl:gap-y-10'
  | 'sm:gap-y-11'
  | 'md:gap-y-11'
  | 'lg:gap-y-11'
  | 'xl:gap-y-11'
  | '2xl:gap-y-11'
  | 'sm:gap-y-12'
  | 'md:gap-y-12'
  | 'lg:gap-y-12'
  | 'xl:gap-y-12'
  | '2xl:gap-y-12'
  | 'sm:gap-y-14'
  | 'md:gap-y-14'
  | 'lg:gap-y-14'
  | 'xl:gap-y-14'
  | '2xl:gap-y-14'
  | 'sm:gap-y-16'
  | 'md:gap-y-16'
  | 'lg:gap-y-16'
  | 'xl:gap-y-16'
  | '2xl:gap-y-16'
  | 'sm:gap-y-20'
  | 'md:gap-y-20'
  | 'lg:gap-y-20'
  | 'xl:gap-y-20'
  | '2xl:gap-y-20'
  | 'sm:gap-y-24'
  | 'md:gap-y-24'
  | 'lg:gap-y-24'
  | 'xl:gap-y-24'
  | '2xl:gap-y-24'
  | 'sm:gap-y-28'
  | 'md:gap-y-28'
  | 'lg:gap-y-28'
  | 'xl:gap-y-28'
  | '2xl:gap-y-28'
  | 'sm:gap-y-32'
  | 'md:gap-y-32'
  | 'lg:gap-y-32'
  | 'xl:gap-y-32'
  | '2xl:gap-y-32'
  | 'sm:gap-y-36'
  | 'md:gap-y-36'
  | 'lg:gap-y-36'
  | 'xl:gap-y-36'
  | '2xl:gap-y-36'
  | 'sm:gap-y-40'
  | 'md:gap-y-40'
  | 'lg:gap-y-40'
  | 'xl:gap-y-40'
  | '2xl:gap-y-40'
  | 'sm:gap-y-44'
  | 'md:gap-y-44'
  | 'lg:gap-y-44'
  | 'xl:gap-y-44'
  | '2xl:gap-y-44'
  | 'sm:gap-y-48'
  | 'md:gap-y-48'
  | 'lg:gap-y-48'
  | 'xl:gap-y-48'
  | '2xl:gap-y-48'
  | 'sm:gap-y-52'
  | 'md:gap-y-52'
  | 'lg:gap-y-52'
  | 'xl:gap-y-52'
  | '2xl:gap-y-52'
  | 'sm:gap-y-56'
  | 'md:gap-y-56'
  | 'lg:gap-y-56'
  | 'xl:gap-y-56'
  | '2xl:gap-y-56'
  | 'sm:gap-y-60'
  | 'md:gap-y-60'
  | 'lg:gap-y-60'
  | 'xl:gap-y-60'
  | '2xl:gap-y-60'
  | 'sm:gap-y-64'
  | 'md:gap-y-64'
  | 'lg:gap-y-64'
  | 'xl:gap-y-64'
  | '2xl:gap-y-64'
  | 'sm:gap-y-72'
  | 'md:gap-y-72'
  | 'lg:gap-y-72'
  | 'xl:gap-y-72'
  | '2xl:gap-y-72'
  | 'sm:gap-y-80'
  | 'md:gap-y-80'
  | 'lg:gap-y-80'
  | 'xl:gap-y-80'
  | '2xl:gap-y-80'
  | 'sm:gap-y-96'
  | 'md:gap-y-96'
  | 'lg:gap-y-96'
  | 'xl:gap-y-96'
  | '2xl:gap-y-96'
  | 'sm:gap-y-px'
  | 'md:gap-y-px'
  | 'lg:gap-y-px'
  | 'xl:gap-y-px'
  | '2xl:gap-y-px'
  | 'sm:gap-y-0.5'
  | 'md:gap-y-0.5'
  | 'lg:gap-y-0.5'
  | 'xl:gap-y-0.5'
  | '2xl:gap-y-0.5'
  | 'sm:gap-y-1.5'
  | 'md:gap-y-1.5'
  | 'lg:gap-y-1.5'
  | 'xl:gap-y-1.5'
  | '2xl:gap-y-1.5'
  | 'sm:gap-y-2.5'
  | 'md:gap-y-2.5'
  | 'lg:gap-y-2.5'
  | 'xl:gap-y-2.5'
  | '2xl:gap-y-2.5'
  | 'sm:gap-y-3.5'
  | 'md:gap-y-3.5'
  | 'lg:gap-y-3.5'
  | 'xl:gap-y-3.5'
  | '2xl:gap-y-3.5'
  | 'sm:gap-x-0'
  | 'md:gap-x-0'
  | 'lg:gap-x-0'
  | 'xl:gap-x-0'
  | '2xl:gap-x-0'
  | 'sm:gap-x-1'
  | 'md:gap-x-1'
  | 'lg:gap-x-1'
  | 'xl:gap-x-1'
  | '2xl:gap-x-1'
  | 'sm:gap-x-2'
  | 'md:gap-x-2'
  | 'lg:gap-x-2'
  | 'xl:gap-x-2'
  | '2xl:gap-x-2'
  | 'sm:gap-x-3'
  | 'md:gap-x-3'
  | 'lg:gap-x-3'
  | 'xl:gap-x-3'
  | '2xl:gap-x-3'
  | 'sm:gap-x-4'
  | 'md:gap-x-4'
  | 'lg:gap-x-4'
  | 'xl:gap-x-4'
  | '2xl:gap-x-4'
  | 'sm:gap-x-5'
  | 'md:gap-x-5'
  | 'lg:gap-x-5'
  | 'xl:gap-x-5'
  | '2xl:gap-x-5'
  | 'sm:gap-x-6'
  | 'md:gap-x-6'
  | 'lg:gap-x-6'
  | 'xl:gap-x-6'
  | '2xl:gap-x-6'
  | 'sm:gap-x-7'
  | 'md:gap-x-7'
  | 'lg:gap-x-7'
  | 'xl:gap-x-7'
  | '2xl:gap-x-7'
  | 'sm:gap-x-8'
  | 'md:gap-x-8'
  | 'lg:gap-x-8'
  | 'xl:gap-x-8'
  | '2xl:gap-x-8'
  | 'sm:gap-x-9'
  | 'md:gap-x-9'
  | 'lg:gap-x-9'
  | 'xl:gap-x-9'
  | '2xl:gap-x-9'
  | 'sm:gap-x-10'
  | 'md:gap-x-10'
  | 'lg:gap-x-10'
  | 'xl:gap-x-10'
  | '2xl:gap-x-10'
  | 'sm:gap-x-11'
  | 'md:gap-x-11'
  | 'lg:gap-x-11'
  | 'xl:gap-x-11'
  | '2xl:gap-x-11'
  | 'sm:gap-x-12'
  | 'md:gap-x-12'
  | 'lg:gap-x-12'
  | 'xl:gap-x-12'
  | '2xl:gap-x-12'
  | 'sm:gap-x-14'
  | 'md:gap-x-14'
  | 'lg:gap-x-14'
  | 'xl:gap-x-14'
  | '2xl:gap-x-14'
  | 'sm:gap-x-16'
  | 'md:gap-x-16'
  | 'lg:gap-x-16'
  | 'xl:gap-x-16'
  | '2xl:gap-x-16'
  | 'sm:gap-x-20'
  | 'md:gap-x-20'
  | 'lg:gap-x-20'
  | 'xl:gap-x-20'
  | '2xl:gap-x-20'
  | 'sm:gap-x-24'
  | 'md:gap-x-24'
  | 'lg:gap-x-24'
  | 'xl:gap-x-24'
  | '2xl:gap-x-24'
  | 'sm:gap-x-28'
  | 'md:gap-x-28'
  | 'lg:gap-x-28'
  | 'xl:gap-x-28'
  | '2xl:gap-x-28'
  | 'sm:gap-x-32'
  | 'md:gap-x-32'
  | 'lg:gap-x-32'
  | 'xl:gap-x-32'
  | '2xl:gap-x-32'
  | 'sm:gap-x-36'
  | 'md:gap-x-36'
  | 'lg:gap-x-36'
  | 'xl:gap-x-36'
  | '2xl:gap-x-36'
  | 'sm:gap-x-40'
  | 'md:gap-x-40'
  | 'lg:gap-x-40'
  | 'xl:gap-x-40'
  | '2xl:gap-x-40'
  | 'sm:gap-x-44'
  | 'md:gap-x-44'
  | 'lg:gap-x-44'
  | 'xl:gap-x-44'
  | '2xl:gap-x-44'
  | 'sm:gap-x-48'
  | 'md:gap-x-48'
  | 'lg:gap-x-48'
  | 'xl:gap-x-48'
  | '2xl:gap-x-48'
  | 'sm:gap-x-52'
  | 'md:gap-x-52'
  | 'lg:gap-x-52'
  | 'xl:gap-x-52'
  | '2xl:gap-x-52'
  | 'sm:gap-x-56'
  | 'md:gap-x-56'
  | 'lg:gap-x-56'
  | 'xl:gap-x-56'
  | '2xl:gap-x-56'
  | 'sm:gap-x-60'
  | 'md:gap-x-60'
  | 'lg:gap-x-60'
  | 'xl:gap-x-60'
  | '2xl:gap-x-60'
  | 'sm:gap-x-64'
  | 'md:gap-x-64'
  | 'lg:gap-x-64'
  | 'xl:gap-x-64'
  | '2xl:gap-x-64'
  | 'sm:gap-x-72'
  | 'md:gap-x-72'
  | 'lg:gap-x-72'
  | 'xl:gap-x-72'
  | '2xl:gap-x-72'
  | 'sm:gap-x-80'
  | 'md:gap-x-80'
  | 'lg:gap-x-80'
  | 'xl:gap-x-80'
  | '2xl:gap-x-80'
  | 'sm:gap-x-96'
  | 'md:gap-x-96'
  | 'lg:gap-x-96'
  | 'xl:gap-x-96'
  | '2xl:gap-x-96'
  | 'sm:gap-x-px'
  | 'md:gap-x-px'
  | 'lg:gap-x-px'
  | 'xl:gap-x-px'
  | '2xl:gap-x-px'
  | 'sm:gap-x-0.5'
  | 'md:gap-x-0.5'
  | 'lg:gap-x-0.5'
  | 'xl:gap-x-0.5'
  | '2xl:gap-x-0.5'
  | 'sm:gap-x-1.5'
  | 'md:gap-x-1.5'
  | 'lg:gap-x-1.5'
  | 'xl:gap-x-1.5'
  | '2xl:gap-x-1.5'
  | 'sm:gap-x-2.5'
  | 'md:gap-x-2.5'
  | 'lg:gap-x-2.5'
  | 'xl:gap-x-2.5'
  | '2xl:gap-x-2.5'
  | 'sm:gap-x-3.5'
  | 'md:gap-x-3.5'
  | 'lg:gap-x-3.5'
  | 'xl:gap-x-3.5'
  | '2xl:gap-x-3.5'
  | 'sm:from-transparent'
  | 'md:from-transparent'
  | 'lg:from-transparent'
  | 'xl:from-transparent'
  | '2xl:from-transparent'
  | 'hover:from-transparent'
  | 'focus:from-transparent'
  | 'sm:via-transparent'
  | 'md:via-transparent'
  | 'lg:via-transparent'
  | 'xl:via-transparent'
  | '2xl:via-transparent'
  | 'hover:via-transparent'
  | 'focus:via-transparent'
  | 'sm:to-transparent'
  | 'md:to-transparent'
  | 'lg:to-transparent'
  | 'xl:to-transparent'
  | '2xl:to-transparent'
  | 'hover:to-transparent'
  | 'focus:to-transparent'
  | 'sm:from-current'
  | 'md:from-current'
  | 'lg:from-current'
  | 'xl:from-current'
  | '2xl:from-current'
  | 'hover:from-current'
  | 'focus:from-current'
  | 'sm:via-current'
  | 'md:via-current'
  | 'lg:via-current'
  | 'xl:via-current'
  | '2xl:via-current'
  | 'hover:via-current'
  | 'focus:via-current'
  | 'sm:to-current'
  | 'md:to-current'
  | 'lg:to-current'
  | 'xl:to-current'
  | '2xl:to-current'
  | 'hover:to-current'
  | 'focus:to-current'
  | 'sm:from-black'
  | 'md:from-black'
  | 'lg:from-black'
  | 'xl:from-black'
  | '2xl:from-black'
  | 'hover:from-black'
  | 'focus:from-black'
  | 'sm:via-black'
  | 'md:via-black'
  | 'lg:via-black'
  | 'xl:via-black'
  | '2xl:via-black'
  | 'hover:via-black'
  | 'focus:via-black'
  | 'sm:to-black'
  | 'md:to-black'
  | 'lg:to-black'
  | 'xl:to-black'
  | '2xl:to-black'
  | 'hover:to-black'
  | 'focus:to-black'
  | 'sm:from-white'
  | 'md:from-white'
  | 'lg:from-white'
  | 'xl:from-white'
  | '2xl:from-white'
  | 'hover:from-white'
  | 'focus:from-white'
  | 'sm:via-white'
  | 'md:via-white'
  | 'lg:via-white'
  | 'xl:via-white'
  | '2xl:via-white'
  | 'hover:via-white'
  | 'focus:via-white'
  | 'sm:to-white'
  | 'md:to-white'
  | 'lg:to-white'
  | 'xl:to-white'
  | '2xl:to-white'
  | 'hover:to-white'
  | 'focus:to-white'
  | 'sm:from-gray-50'
  | 'md:from-gray-50'
  | 'lg:from-gray-50'
  | 'xl:from-gray-50'
  | '2xl:from-gray-50'
  | 'hover:from-gray-50'
  | 'focus:from-gray-50'
  | 'sm:via-gray-50'
  | 'md:via-gray-50'
  | 'lg:via-gray-50'
  | 'xl:via-gray-50'
  | '2xl:via-gray-50'
  | 'hover:via-gray-50'
  | 'focus:via-gray-50'
  | 'sm:to-gray-50'
  | 'md:to-gray-50'
  | 'lg:to-gray-50'
  | 'xl:to-gray-50'
  | '2xl:to-gray-50'
  | 'hover:to-gray-50'
  | 'focus:to-gray-50'
  | 'sm:from-gray-100'
  | 'md:from-gray-100'
  | 'lg:from-gray-100'
  | 'xl:from-gray-100'
  | '2xl:from-gray-100'
  | 'hover:from-gray-100'
  | 'focus:from-gray-100'
  | 'sm:via-gray-100'
  | 'md:via-gray-100'
  | 'lg:via-gray-100'
  | 'xl:via-gray-100'
  | '2xl:via-gray-100'
  | 'hover:via-gray-100'
  | 'focus:via-gray-100'
  | 'sm:to-gray-100'
  | 'md:to-gray-100'
  | 'lg:to-gray-100'
  | 'xl:to-gray-100'
  | '2xl:to-gray-100'
  | 'hover:to-gray-100'
  | 'focus:to-gray-100'
  | 'sm:from-gray-200'
  | 'md:from-gray-200'
  | 'lg:from-gray-200'
  | 'xl:from-gray-200'
  | '2xl:from-gray-200'
  | 'hover:from-gray-200'
  | 'focus:from-gray-200'
  | 'sm:via-gray-200'
  | 'md:via-gray-200'
  | 'lg:via-gray-200'
  | 'xl:via-gray-200'
  | '2xl:via-gray-200'
  | 'hover:via-gray-200'
  | 'focus:via-gray-200'
  | 'sm:to-gray-200'
  | 'md:to-gray-200'
  | 'lg:to-gray-200'
  | 'xl:to-gray-200'
  | '2xl:to-gray-200'
  | 'hover:to-gray-200'
  | 'focus:to-gray-200'
  | 'sm:from-gray-300'
  | 'md:from-gray-300'
  | 'lg:from-gray-300'
  | 'xl:from-gray-300'
  | '2xl:from-gray-300'
  | 'hover:from-gray-300'
  | 'focus:from-gray-300'
  | 'sm:via-gray-300'
  | 'md:via-gray-300'
  | 'lg:via-gray-300'
  | 'xl:via-gray-300'
  | '2xl:via-gray-300'
  | 'hover:via-gray-300'
  | 'focus:via-gray-300'
  | 'sm:to-gray-300'
  | 'md:to-gray-300'
  | 'lg:to-gray-300'
  | 'xl:to-gray-300'
  | '2xl:to-gray-300'
  | 'hover:to-gray-300'
  | 'focus:to-gray-300'
  | 'sm:from-gray-400'
  | 'md:from-gray-400'
  | 'lg:from-gray-400'
  | 'xl:from-gray-400'
  | '2xl:from-gray-400'
  | 'hover:from-gray-400'
  | 'focus:from-gray-400'
  | 'sm:via-gray-400'
  | 'md:via-gray-400'
  | 'lg:via-gray-400'
  | 'xl:via-gray-400'
  | '2xl:via-gray-400'
  | 'hover:via-gray-400'
  | 'focus:via-gray-400'
  | 'sm:to-gray-400'
  | 'md:to-gray-400'
  | 'lg:to-gray-400'
  | 'xl:to-gray-400'
  | '2xl:to-gray-400'
  | 'hover:to-gray-400'
  | 'focus:to-gray-400'
  | 'sm:from-gray-500'
  | 'md:from-gray-500'
  | 'lg:from-gray-500'
  | 'xl:from-gray-500'
  | '2xl:from-gray-500'
  | 'hover:from-gray-500'
  | 'focus:from-gray-500'
  | 'sm:via-gray-500'
  | 'md:via-gray-500'
  | 'lg:via-gray-500'
  | 'xl:via-gray-500'
  | '2xl:via-gray-500'
  | 'hover:via-gray-500'
  | 'focus:via-gray-500'
  | 'sm:to-gray-500'
  | 'md:to-gray-500'
  | 'lg:to-gray-500'
  | 'xl:to-gray-500'
  | '2xl:to-gray-500'
  | 'hover:to-gray-500'
  | 'focus:to-gray-500'
  | 'sm:from-gray-600'
  | 'md:from-gray-600'
  | 'lg:from-gray-600'
  | 'xl:from-gray-600'
  | '2xl:from-gray-600'
  | 'hover:from-gray-600'
  | 'focus:from-gray-600'
  | 'sm:via-gray-600'
  | 'md:via-gray-600'
  | 'lg:via-gray-600'
  | 'xl:via-gray-600'
  | '2xl:via-gray-600'
  | 'hover:via-gray-600'
  | 'focus:via-gray-600'
  | 'sm:to-gray-600'
  | 'md:to-gray-600'
  | 'lg:to-gray-600'
  | 'xl:to-gray-600'
  | '2xl:to-gray-600'
  | 'hover:to-gray-600'
  | 'focus:to-gray-600'
  | 'sm:from-gray-700'
  | 'md:from-gray-700'
  | 'lg:from-gray-700'
  | 'xl:from-gray-700'
  | '2xl:from-gray-700'
  | 'hover:from-gray-700'
  | 'focus:from-gray-700'
  | 'sm:via-gray-700'
  | 'md:via-gray-700'
  | 'lg:via-gray-700'
  | 'xl:via-gray-700'
  | '2xl:via-gray-700'
  | 'hover:via-gray-700'
  | 'focus:via-gray-700'
  | 'sm:to-gray-700'
  | 'md:to-gray-700'
  | 'lg:to-gray-700'
  | 'xl:to-gray-700'
  | '2xl:to-gray-700'
  | 'hover:to-gray-700'
  | 'focus:to-gray-700'
  | 'sm:from-gray-800'
  | 'md:from-gray-800'
  | 'lg:from-gray-800'
  | 'xl:from-gray-800'
  | '2xl:from-gray-800'
  | 'hover:from-gray-800'
  | 'focus:from-gray-800'
  | 'sm:via-gray-800'
  | 'md:via-gray-800'
  | 'lg:via-gray-800'
  | 'xl:via-gray-800'
  | '2xl:via-gray-800'
  | 'hover:via-gray-800'
  | 'focus:via-gray-800'
  | 'sm:to-gray-800'
  | 'md:to-gray-800'
  | 'lg:to-gray-800'
  | 'xl:to-gray-800'
  | '2xl:to-gray-800'
  | 'hover:to-gray-800'
  | 'focus:to-gray-800'
  | 'sm:from-gray-900'
  | 'md:from-gray-900'
  | 'lg:from-gray-900'
  | 'xl:from-gray-900'
  | '2xl:from-gray-900'
  | 'hover:from-gray-900'
  | 'focus:from-gray-900'
  | 'sm:via-gray-900'
  | 'md:via-gray-900'
  | 'lg:via-gray-900'
  | 'xl:via-gray-900'
  | '2xl:via-gray-900'
  | 'hover:via-gray-900'
  | 'focus:via-gray-900'
  | 'sm:to-gray-900'
  | 'md:to-gray-900'
  | 'lg:to-gray-900'
  | 'xl:to-gray-900'
  | '2xl:to-gray-900'
  | 'hover:to-gray-900'
  | 'focus:to-gray-900'
  | 'sm:from-red-50'
  | 'md:from-red-50'
  | 'lg:from-red-50'
  | 'xl:from-red-50'
  | '2xl:from-red-50'
  | 'hover:from-red-50'
  | 'focus:from-red-50'
  | 'sm:via-red-50'
  | 'md:via-red-50'
  | 'lg:via-red-50'
  | 'xl:via-red-50'
  | '2xl:via-red-50'
  | 'hover:via-red-50'
  | 'focus:via-red-50'
  | 'sm:to-red-50'
  | 'md:to-red-50'
  | 'lg:to-red-50'
  | 'xl:to-red-50'
  | '2xl:to-red-50'
  | 'hover:to-red-50'
  | 'focus:to-red-50'
  | 'sm:from-red-100'
  | 'md:from-red-100'
  | 'lg:from-red-100'
  | 'xl:from-red-100'
  | '2xl:from-red-100'
  | 'hover:from-red-100'
  | 'focus:from-red-100'
  | 'sm:via-red-100'
  | 'md:via-red-100'
  | 'lg:via-red-100'
  | 'xl:via-red-100'
  | '2xl:via-red-100'
  | 'hover:via-red-100'
  | 'focus:via-red-100'
  | 'sm:to-red-100'
  | 'md:to-red-100'
  | 'lg:to-red-100'
  | 'xl:to-red-100'
  | '2xl:to-red-100'
  | 'hover:to-red-100'
  | 'focus:to-red-100'
  | 'sm:from-red-200'
  | 'md:from-red-200'
  | 'lg:from-red-200'
  | 'xl:from-red-200'
  | '2xl:from-red-200'
  | 'hover:from-red-200'
  | 'focus:from-red-200'
  | 'sm:via-red-200'
  | 'md:via-red-200'
  | 'lg:via-red-200'
  | 'xl:via-red-200'
  | '2xl:via-red-200'
  | 'hover:via-red-200'
  | 'focus:via-red-200'
  | 'sm:to-red-200'
  | 'md:to-red-200'
  | 'lg:to-red-200'
  | 'xl:to-red-200'
  | '2xl:to-red-200'
  | 'hover:to-red-200'
  | 'focus:to-red-200'
  | 'sm:from-red-300'
  | 'md:from-red-300'
  | 'lg:from-red-300'
  | 'xl:from-red-300'
  | '2xl:from-red-300'
  | 'hover:from-red-300'
  | 'focus:from-red-300'
  | 'sm:via-red-300'
  | 'md:via-red-300'
  | 'lg:via-red-300'
  | 'xl:via-red-300'
  | '2xl:via-red-300'
  | 'hover:via-red-300'
  | 'focus:via-red-300'
  | 'sm:to-red-300'
  | 'md:to-red-300'
  | 'lg:to-red-300'
  | 'xl:to-red-300'
  | '2xl:to-red-300'
  | 'hover:to-red-300'
  | 'focus:to-red-300'
  | 'sm:from-red-400'
  | 'md:from-red-400'
  | 'lg:from-red-400'
  | 'xl:from-red-400'
  | '2xl:from-red-400'
  | 'hover:from-red-400'
  | 'focus:from-red-400'
  | 'sm:via-red-400'
  | 'md:via-red-400'
  | 'lg:via-red-400'
  | 'xl:via-red-400'
  | '2xl:via-red-400'
  | 'hover:via-red-400'
  | 'focus:via-red-400'
  | 'sm:to-red-400'
  | 'md:to-red-400'
  | 'lg:to-red-400'
  | 'xl:to-red-400'
  | '2xl:to-red-400'
  | 'hover:to-red-400'
  | 'focus:to-red-400'
  | 'sm:from-red-500'
  | 'md:from-red-500'
  | 'lg:from-red-500'
  | 'xl:from-red-500'
  | '2xl:from-red-500'
  | 'hover:from-red-500'
  | 'focus:from-red-500'
  | 'sm:via-red-500'
  | 'md:via-red-500'
  | 'lg:via-red-500'
  | 'xl:via-red-500'
  | '2xl:via-red-500'
  | 'hover:via-red-500'
  | 'focus:via-red-500'
  | 'sm:to-red-500'
  | 'md:to-red-500'
  | 'lg:to-red-500'
  | 'xl:to-red-500'
  | '2xl:to-red-500'
  | 'hover:to-red-500'
  | 'focus:to-red-500'
  | 'sm:from-red-600'
  | 'md:from-red-600'
  | 'lg:from-red-600'
  | 'xl:from-red-600'
  | '2xl:from-red-600'
  | 'hover:from-red-600'
  | 'focus:from-red-600'
  | 'sm:via-red-600'
  | 'md:via-red-600'
  | 'lg:via-red-600'
  | 'xl:via-red-600'
  | '2xl:via-red-600'
  | 'hover:via-red-600'
  | 'focus:via-red-600'
  | 'sm:to-red-600'
  | 'md:to-red-600'
  | 'lg:to-red-600'
  | 'xl:to-red-600'
  | '2xl:to-red-600'
  | 'hover:to-red-600'
  | 'focus:to-red-600'
  | 'sm:from-red-700'
  | 'md:from-red-700'
  | 'lg:from-red-700'
  | 'xl:from-red-700'
  | '2xl:from-red-700'
  | 'hover:from-red-700'
  | 'focus:from-red-700'
  | 'sm:via-red-700'
  | 'md:via-red-700'
  | 'lg:via-red-700'
  | 'xl:via-red-700'
  | '2xl:via-red-700'
  | 'hover:via-red-700'
  | 'focus:via-red-700'
  | 'sm:to-red-700'
  | 'md:to-red-700'
  | 'lg:to-red-700'
  | 'xl:to-red-700'
  | '2xl:to-red-700'
  | 'hover:to-red-700'
  | 'focus:to-red-700'
  | 'sm:from-red-800'
  | 'md:from-red-800'
  | 'lg:from-red-800'
  | 'xl:from-red-800'
  | '2xl:from-red-800'
  | 'hover:from-red-800'
  | 'focus:from-red-800'
  | 'sm:via-red-800'
  | 'md:via-red-800'
  | 'lg:via-red-800'
  | 'xl:via-red-800'
  | '2xl:via-red-800'
  | 'hover:via-red-800'
  | 'focus:via-red-800'
  | 'sm:to-red-800'
  | 'md:to-red-800'
  | 'lg:to-red-800'
  | 'xl:to-red-800'
  | '2xl:to-red-800'
  | 'hover:to-red-800'
  | 'focus:to-red-800'
  | 'sm:from-red-900'
  | 'md:from-red-900'
  | 'lg:from-red-900'
  | 'xl:from-red-900'
  | '2xl:from-red-900'
  | 'hover:from-red-900'
  | 'focus:from-red-900'
  | 'sm:via-red-900'
  | 'md:via-red-900'
  | 'lg:via-red-900'
  | 'xl:via-red-900'
  | '2xl:via-red-900'
  | 'hover:via-red-900'
  | 'focus:via-red-900'
  | 'sm:to-red-900'
  | 'md:to-red-900'
  | 'lg:to-red-900'
  | 'xl:to-red-900'
  | '2xl:to-red-900'
  | 'hover:to-red-900'
  | 'focus:to-red-900'
  | 'sm:from-yellow-50'
  | 'md:from-yellow-50'
  | 'lg:from-yellow-50'
  | 'xl:from-yellow-50'
  | '2xl:from-yellow-50'
  | 'hover:from-yellow-50'
  | 'focus:from-yellow-50'
  | 'sm:via-yellow-50'
  | 'md:via-yellow-50'
  | 'lg:via-yellow-50'
  | 'xl:via-yellow-50'
  | '2xl:via-yellow-50'
  | 'hover:via-yellow-50'
  | 'focus:via-yellow-50'
  | 'sm:to-yellow-50'
  | 'md:to-yellow-50'
  | 'lg:to-yellow-50'
  | 'xl:to-yellow-50'
  | '2xl:to-yellow-50'
  | 'hover:to-yellow-50'
  | 'focus:to-yellow-50'
  | 'sm:from-yellow-100'
  | 'md:from-yellow-100'
  | 'lg:from-yellow-100'
  | 'xl:from-yellow-100'
  | '2xl:from-yellow-100'
  | 'hover:from-yellow-100'
  | 'focus:from-yellow-100'
  | 'sm:via-yellow-100'
  | 'md:via-yellow-100'
  | 'lg:via-yellow-100'
  | 'xl:via-yellow-100'
  | '2xl:via-yellow-100'
  | 'hover:via-yellow-100'
  | 'focus:via-yellow-100'
  | 'sm:to-yellow-100'
  | 'md:to-yellow-100'
  | 'lg:to-yellow-100'
  | 'xl:to-yellow-100'
  | '2xl:to-yellow-100'
  | 'hover:to-yellow-100'
  | 'focus:to-yellow-100'
  | 'sm:from-yellow-200'
  | 'md:from-yellow-200'
  | 'lg:from-yellow-200'
  | 'xl:from-yellow-200'
  | '2xl:from-yellow-200'
  | 'hover:from-yellow-200'
  | 'focus:from-yellow-200'
  | 'sm:via-yellow-200'
  | 'md:via-yellow-200'
  | 'lg:via-yellow-200'
  | 'xl:via-yellow-200'
  | '2xl:via-yellow-200'
  | 'hover:via-yellow-200'
  | 'focus:via-yellow-200'
  | 'sm:to-yellow-200'
  | 'md:to-yellow-200'
  | 'lg:to-yellow-200'
  | 'xl:to-yellow-200'
  | '2xl:to-yellow-200'
  | 'hover:to-yellow-200'
  | 'focus:to-yellow-200'
  | 'sm:from-yellow-300'
  | 'md:from-yellow-300'
  | 'lg:from-yellow-300'
  | 'xl:from-yellow-300'
  | '2xl:from-yellow-300'
  | 'hover:from-yellow-300'
  | 'focus:from-yellow-300'
  | 'sm:via-yellow-300'
  | 'md:via-yellow-300'
  | 'lg:via-yellow-300'
  | 'xl:via-yellow-300'
  | '2xl:via-yellow-300'
  | 'hover:via-yellow-300'
  | 'focus:via-yellow-300'
  | 'sm:to-yellow-300'
  | 'md:to-yellow-300'
  | 'lg:to-yellow-300'
  | 'xl:to-yellow-300'
  | '2xl:to-yellow-300'
  | 'hover:to-yellow-300'
  | 'focus:to-yellow-300'
  | 'sm:from-yellow-400'
  | 'md:from-yellow-400'
  | 'lg:from-yellow-400'
  | 'xl:from-yellow-400'
  | '2xl:from-yellow-400'
  | 'hover:from-yellow-400'
  | 'focus:from-yellow-400'
  | 'sm:via-yellow-400'
  | 'md:via-yellow-400'
  | 'lg:via-yellow-400'
  | 'xl:via-yellow-400'
  | '2xl:via-yellow-400'
  | 'hover:via-yellow-400'
  | 'focus:via-yellow-400'
  | 'sm:to-yellow-400'
  | 'md:to-yellow-400'
  | 'lg:to-yellow-400'
  | 'xl:to-yellow-400'
  | '2xl:to-yellow-400'
  | 'hover:to-yellow-400'
  | 'focus:to-yellow-400'
  | 'sm:from-yellow-500'
  | 'md:from-yellow-500'
  | 'lg:from-yellow-500'
  | 'xl:from-yellow-500'
  | '2xl:from-yellow-500'
  | 'hover:from-yellow-500'
  | 'focus:from-yellow-500'
  | 'sm:via-yellow-500'
  | 'md:via-yellow-500'
  | 'lg:via-yellow-500'
  | 'xl:via-yellow-500'
  | '2xl:via-yellow-500'
  | 'hover:via-yellow-500'
  | 'focus:via-yellow-500'
  | 'sm:to-yellow-500'
  | 'md:to-yellow-500'
  | 'lg:to-yellow-500'
  | 'xl:to-yellow-500'
  | '2xl:to-yellow-500'
  | 'hover:to-yellow-500'
  | 'focus:to-yellow-500'
  | 'sm:from-yellow-600'
  | 'md:from-yellow-600'
  | 'lg:from-yellow-600'
  | 'xl:from-yellow-600'
  | '2xl:from-yellow-600'
  | 'hover:from-yellow-600'
  | 'focus:from-yellow-600'
  | 'sm:via-yellow-600'
  | 'md:via-yellow-600'
  | 'lg:via-yellow-600'
  | 'xl:via-yellow-600'
  | '2xl:via-yellow-600'
  | 'hover:via-yellow-600'
  | 'focus:via-yellow-600'
  | 'sm:to-yellow-600'
  | 'md:to-yellow-600'
  | 'lg:to-yellow-600'
  | 'xl:to-yellow-600'
  | '2xl:to-yellow-600'
  | 'hover:to-yellow-600'
  | 'focus:to-yellow-600'
  | 'sm:from-yellow-700'
  | 'md:from-yellow-700'
  | 'lg:from-yellow-700'
  | 'xl:from-yellow-700'
  | '2xl:from-yellow-700'
  | 'hover:from-yellow-700'
  | 'focus:from-yellow-700'
  | 'sm:via-yellow-700'
  | 'md:via-yellow-700'
  | 'lg:via-yellow-700'
  | 'xl:via-yellow-700'
  | '2xl:via-yellow-700'
  | 'hover:via-yellow-700'
  | 'focus:via-yellow-700'
  | 'sm:to-yellow-700'
  | 'md:to-yellow-700'
  | 'lg:to-yellow-700'
  | 'xl:to-yellow-700'
  | '2xl:to-yellow-700'
  | 'hover:to-yellow-700'
  | 'focus:to-yellow-700'
  | 'sm:from-yellow-800'
  | 'md:from-yellow-800'
  | 'lg:from-yellow-800'
  | 'xl:from-yellow-800'
  | '2xl:from-yellow-800'
  | 'hover:from-yellow-800'
  | 'focus:from-yellow-800'
  | 'sm:via-yellow-800'
  | 'md:via-yellow-800'
  | 'lg:via-yellow-800'
  | 'xl:via-yellow-800'
  | '2xl:via-yellow-800'
  | 'hover:via-yellow-800'
  | 'focus:via-yellow-800'
  | 'sm:to-yellow-800'
  | 'md:to-yellow-800'
  | 'lg:to-yellow-800'
  | 'xl:to-yellow-800'
  | '2xl:to-yellow-800'
  | 'hover:to-yellow-800'
  | 'focus:to-yellow-800'
  | 'sm:from-yellow-900'
  | 'md:from-yellow-900'
  | 'lg:from-yellow-900'
  | 'xl:from-yellow-900'
  | '2xl:from-yellow-900'
  | 'hover:from-yellow-900'
  | 'focus:from-yellow-900'
  | 'sm:via-yellow-900'
  | 'md:via-yellow-900'
  | 'lg:via-yellow-900'
  | 'xl:via-yellow-900'
  | '2xl:via-yellow-900'
  | 'hover:via-yellow-900'
  | 'focus:via-yellow-900'
  | 'sm:to-yellow-900'
  | 'md:to-yellow-900'
  | 'lg:to-yellow-900'
  | 'xl:to-yellow-900'
  | '2xl:to-yellow-900'
  | 'hover:to-yellow-900'
  | 'focus:to-yellow-900'
  | 'sm:from-green-50'
  | 'md:from-green-50'
  | 'lg:from-green-50'
  | 'xl:from-green-50'
  | '2xl:from-green-50'
  | 'hover:from-green-50'
  | 'focus:from-green-50'
  | 'sm:via-green-50'
  | 'md:via-green-50'
  | 'lg:via-green-50'
  | 'xl:via-green-50'
  | '2xl:via-green-50'
  | 'hover:via-green-50'
  | 'focus:via-green-50'
  | 'sm:to-green-50'
  | 'md:to-green-50'
  | 'lg:to-green-50'
  | 'xl:to-green-50'
  | '2xl:to-green-50'
  | 'hover:to-green-50'
  | 'focus:to-green-50'
  | 'sm:from-green-100'
  | 'md:from-green-100'
  | 'lg:from-green-100'
  | 'xl:from-green-100'
  | '2xl:from-green-100'
  | 'hover:from-green-100'
  | 'focus:from-green-100'
  | 'sm:via-green-100'
  | 'md:via-green-100'
  | 'lg:via-green-100'
  | 'xl:via-green-100'
  | '2xl:via-green-100'
  | 'hover:via-green-100'
  | 'focus:via-green-100'
  | 'sm:to-green-100'
  | 'md:to-green-100'
  | 'lg:to-green-100'
  | 'xl:to-green-100'
  | '2xl:to-green-100'
  | 'hover:to-green-100'
  | 'focus:to-green-100'
  | 'sm:from-green-200'
  | 'md:from-green-200'
  | 'lg:from-green-200'
  | 'xl:from-green-200'
  | '2xl:from-green-200'
  | 'hover:from-green-200'
  | 'focus:from-green-200'
  | 'sm:via-green-200'
  | 'md:via-green-200'
  | 'lg:via-green-200'
  | 'xl:via-green-200'
  | '2xl:via-green-200'
  | 'hover:via-green-200'
  | 'focus:via-green-200'
  | 'sm:to-green-200'
  | 'md:to-green-200'
  | 'lg:to-green-200'
  | 'xl:to-green-200'
  | '2xl:to-green-200'
  | 'hover:to-green-200'
  | 'focus:to-green-200'
  | 'sm:from-green-300'
  | 'md:from-green-300'
  | 'lg:from-green-300'
  | 'xl:from-green-300'
  | '2xl:from-green-300'
  | 'hover:from-green-300'
  | 'focus:from-green-300'
  | 'sm:via-green-300'
  | 'md:via-green-300'
  | 'lg:via-green-300'
  | 'xl:via-green-300'
  | '2xl:via-green-300'
  | 'hover:via-green-300'
  | 'focus:via-green-300'
  | 'sm:to-green-300'
  | 'md:to-green-300'
  | 'lg:to-green-300'
  | 'xl:to-green-300'
  | '2xl:to-green-300'
  | 'hover:to-green-300'
  | 'focus:to-green-300'
  | 'sm:from-green-400'
  | 'md:from-green-400'
  | 'lg:from-green-400'
  | 'xl:from-green-400'
  | '2xl:from-green-400'
  | 'hover:from-green-400'
  | 'focus:from-green-400'
  | 'sm:via-green-400'
  | 'md:via-green-400'
  | 'lg:via-green-400'
  | 'xl:via-green-400'
  | '2xl:via-green-400'
  | 'hover:via-green-400'
  | 'focus:via-green-400'
  | 'sm:to-green-400'
  | 'md:to-green-400'
  | 'lg:to-green-400'
  | 'xl:to-green-400'
  | '2xl:to-green-400'
  | 'hover:to-green-400'
  | 'focus:to-green-400'
  | 'sm:from-green-500'
  | 'md:from-green-500'
  | 'lg:from-green-500'
  | 'xl:from-green-500'
  | '2xl:from-green-500'
  | 'hover:from-green-500'
  | 'focus:from-green-500'
  | 'sm:via-green-500'
  | 'md:via-green-500'
  | 'lg:via-green-500'
  | 'xl:via-green-500'
  | '2xl:via-green-500'
  | 'hover:via-green-500'
  | 'focus:via-green-500'
  | 'sm:to-green-500'
  | 'md:to-green-500'
  | 'lg:to-green-500'
  | 'xl:to-green-500'
  | '2xl:to-green-500'
  | 'hover:to-green-500'
  | 'focus:to-green-500'
  | 'sm:from-green-600'
  | 'md:from-green-600'
  | 'lg:from-green-600'
  | 'xl:from-green-600'
  | '2xl:from-green-600'
  | 'hover:from-green-600'
  | 'focus:from-green-600'
  | 'sm:via-green-600'
  | 'md:via-green-600'
  | 'lg:via-green-600'
  | 'xl:via-green-600'
  | '2xl:via-green-600'
  | 'hover:via-green-600'
  | 'focus:via-green-600'
  | 'sm:to-green-600'
  | 'md:to-green-600'
  | 'lg:to-green-600'
  | 'xl:to-green-600'
  | '2xl:to-green-600'
  | 'hover:to-green-600'
  | 'focus:to-green-600'
  | 'sm:from-green-700'
  | 'md:from-green-700'
  | 'lg:from-green-700'
  | 'xl:from-green-700'
  | '2xl:from-green-700'
  | 'hover:from-green-700'
  | 'focus:from-green-700'
  | 'sm:via-green-700'
  | 'md:via-green-700'
  | 'lg:via-green-700'
  | 'xl:via-green-700'
  | '2xl:via-green-700'
  | 'hover:via-green-700'
  | 'focus:via-green-700'
  | 'sm:to-green-700'
  | 'md:to-green-700'
  | 'lg:to-green-700'
  | 'xl:to-green-700'
  | '2xl:to-green-700'
  | 'hover:to-green-700'
  | 'focus:to-green-700'
  | 'sm:from-green-800'
  | 'md:from-green-800'
  | 'lg:from-green-800'
  | 'xl:from-green-800'
  | '2xl:from-green-800'
  | 'hover:from-green-800'
  | 'focus:from-green-800'
  | 'sm:via-green-800'
  | 'md:via-green-800'
  | 'lg:via-green-800'
  | 'xl:via-green-800'
  | '2xl:via-green-800'
  | 'hover:via-green-800'
  | 'focus:via-green-800'
  | 'sm:to-green-800'
  | 'md:to-green-800'
  | 'lg:to-green-800'
  | 'xl:to-green-800'
  | '2xl:to-green-800'
  | 'hover:to-green-800'
  | 'focus:to-green-800'
  | 'sm:from-green-900'
  | 'md:from-green-900'
  | 'lg:from-green-900'
  | 'xl:from-green-900'
  | '2xl:from-green-900'
  | 'hover:from-green-900'
  | 'focus:from-green-900'
  | 'sm:via-green-900'
  | 'md:via-green-900'
  | 'lg:via-green-900'
  | 'xl:via-green-900'
  | '2xl:via-green-900'
  | 'hover:via-green-900'
  | 'focus:via-green-900'
  | 'sm:to-green-900'
  | 'md:to-green-900'
  | 'lg:to-green-900'
  | 'xl:to-green-900'
  | '2xl:to-green-900'
  | 'hover:to-green-900'
  | 'focus:to-green-900'
  | 'sm:from-blue-50'
  | 'md:from-blue-50'
  | 'lg:from-blue-50'
  | 'xl:from-blue-50'
  | '2xl:from-blue-50'
  | 'hover:from-blue-50'
  | 'focus:from-blue-50'
  | 'sm:via-blue-50'
  | 'md:via-blue-50'
  | 'lg:via-blue-50'
  | 'xl:via-blue-50'
  | '2xl:via-blue-50'
  | 'hover:via-blue-50'
  | 'focus:via-blue-50'
  | 'sm:to-blue-50'
  | 'md:to-blue-50'
  | 'lg:to-blue-50'
  | 'xl:to-blue-50'
  | '2xl:to-blue-50'
  | 'hover:to-blue-50'
  | 'focus:to-blue-50'
  | 'sm:from-blue-100'
  | 'md:from-blue-100'
  | 'lg:from-blue-100'
  | 'xl:from-blue-100'
  | '2xl:from-blue-100'
  | 'hover:from-blue-100'
  | 'focus:from-blue-100'
  | 'sm:via-blue-100'
  | 'md:via-blue-100'
  | 'lg:via-blue-100'
  | 'xl:via-blue-100'
  | '2xl:via-blue-100'
  | 'hover:via-blue-100'
  | 'focus:via-blue-100'
  | 'sm:to-blue-100'
  | 'md:to-blue-100'
  | 'lg:to-blue-100'
  | 'xl:to-blue-100'
  | '2xl:to-blue-100'
  | 'hover:to-blue-100'
  | 'focus:to-blue-100'
  | 'sm:from-blue-200'
  | 'md:from-blue-200'
  | 'lg:from-blue-200'
  | 'xl:from-blue-200'
  | '2xl:from-blue-200'
  | 'hover:from-blue-200'
  | 'focus:from-blue-200'
  | 'sm:via-blue-200'
  | 'md:via-blue-200'
  | 'lg:via-blue-200'
  | 'xl:via-blue-200'
  | '2xl:via-blue-200'
  | 'hover:via-blue-200'
  | 'focus:via-blue-200'
  | 'sm:to-blue-200'
  | 'md:to-blue-200'
  | 'lg:to-blue-200'
  | 'xl:to-blue-200'
  | '2xl:to-blue-200'
  | 'hover:to-blue-200'
  | 'focus:to-blue-200'
  | 'sm:from-blue-300'
  | 'md:from-blue-300'
  | 'lg:from-blue-300'
  | 'xl:from-blue-300'
  | '2xl:from-blue-300'
  | 'hover:from-blue-300'
  | 'focus:from-blue-300'
  | 'sm:via-blue-300'
  | 'md:via-blue-300'
  | 'lg:via-blue-300'
  | 'xl:via-blue-300'
  | '2xl:via-blue-300'
  | 'hover:via-blue-300'
  | 'focus:via-blue-300'
  | 'sm:to-blue-300'
  | 'md:to-blue-300'
  | 'lg:to-blue-300'
  | 'xl:to-blue-300'
  | '2xl:to-blue-300'
  | 'hover:to-blue-300'
  | 'focus:to-blue-300'
  | 'sm:from-blue-400'
  | 'md:from-blue-400'
  | 'lg:from-blue-400'
  | 'xl:from-blue-400'
  | '2xl:from-blue-400'
  | 'hover:from-blue-400'
  | 'focus:from-blue-400'
  | 'sm:via-blue-400'
  | 'md:via-blue-400'
  | 'lg:via-blue-400'
  | 'xl:via-blue-400'
  | '2xl:via-blue-400'
  | 'hover:via-blue-400'
  | 'focus:via-blue-400'
  | 'sm:to-blue-400'
  | 'md:to-blue-400'
  | 'lg:to-blue-400'
  | 'xl:to-blue-400'
  | '2xl:to-blue-400'
  | 'hover:to-blue-400'
  | 'focus:to-blue-400'
  | 'sm:from-blue-500'
  | 'md:from-blue-500'
  | 'lg:from-blue-500'
  | 'xl:from-blue-500'
  | '2xl:from-blue-500'
  | 'hover:from-blue-500'
  | 'focus:from-blue-500'
  | 'sm:via-blue-500'
  | 'md:via-blue-500'
  | 'lg:via-blue-500'
  | 'xl:via-blue-500'
  | '2xl:via-blue-500'
  | 'hover:via-blue-500'
  | 'focus:via-blue-500'
  | 'sm:to-blue-500'
  | 'md:to-blue-500'
  | 'lg:to-blue-500'
  | 'xl:to-blue-500'
  | '2xl:to-blue-500'
  | 'hover:to-blue-500'
  | 'focus:to-blue-500'
  | 'sm:from-blue-600'
  | 'md:from-blue-600'
  | 'lg:from-blue-600'
  | 'xl:from-blue-600'
  | '2xl:from-blue-600'
  | 'hover:from-blue-600'
  | 'focus:from-blue-600'
  | 'sm:via-blue-600'
  | 'md:via-blue-600'
  | 'lg:via-blue-600'
  | 'xl:via-blue-600'
  | '2xl:via-blue-600'
  | 'hover:via-blue-600'
  | 'focus:via-blue-600'
  | 'sm:to-blue-600'
  | 'md:to-blue-600'
  | 'lg:to-blue-600'
  | 'xl:to-blue-600'
  | '2xl:to-blue-600'
  | 'hover:to-blue-600'
  | 'focus:to-blue-600'
  | 'sm:from-blue-700'
  | 'md:from-blue-700'
  | 'lg:from-blue-700'
  | 'xl:from-blue-700'
  | '2xl:from-blue-700'
  | 'hover:from-blue-700'
  | 'focus:from-blue-700'
  | 'sm:via-blue-700'
  | 'md:via-blue-700'
  | 'lg:via-blue-700'
  | 'xl:via-blue-700'
  | '2xl:via-blue-700'
  | 'hover:via-blue-700'
  | 'focus:via-blue-700'
  | 'sm:to-blue-700'
  | 'md:to-blue-700'
  | 'lg:to-blue-700'
  | 'xl:to-blue-700'
  | '2xl:to-blue-700'
  | 'hover:to-blue-700'
  | 'focus:to-blue-700'
  | 'sm:from-blue-800'
  | 'md:from-blue-800'
  | 'lg:from-blue-800'
  | 'xl:from-blue-800'
  | '2xl:from-blue-800'
  | 'hover:from-blue-800'
  | 'focus:from-blue-800'
  | 'sm:via-blue-800'
  | 'md:via-blue-800'
  | 'lg:via-blue-800'
  | 'xl:via-blue-800'
  | '2xl:via-blue-800'
  | 'hover:via-blue-800'
  | 'focus:via-blue-800'
  | 'sm:to-blue-800'
  | 'md:to-blue-800'
  | 'lg:to-blue-800'
  | 'xl:to-blue-800'
  | '2xl:to-blue-800'
  | 'hover:to-blue-800'
  | 'focus:to-blue-800'
  | 'sm:from-blue-900'
  | 'md:from-blue-900'
  | 'lg:from-blue-900'
  | 'xl:from-blue-900'
  | '2xl:from-blue-900'
  | 'hover:from-blue-900'
  | 'focus:from-blue-900'
  | 'sm:via-blue-900'
  | 'md:via-blue-900'
  | 'lg:via-blue-900'
  | 'xl:via-blue-900'
  | '2xl:via-blue-900'
  | 'hover:via-blue-900'
  | 'focus:via-blue-900'
  | 'sm:to-blue-900'
  | 'md:to-blue-900'
  | 'lg:to-blue-900'
  | 'xl:to-blue-900'
  | '2xl:to-blue-900'
  | 'hover:to-blue-900'
  | 'focus:to-blue-900'
  | 'sm:from-indigo-50'
  | 'md:from-indigo-50'
  | 'lg:from-indigo-50'
  | 'xl:from-indigo-50'
  | '2xl:from-indigo-50'
  | 'hover:from-indigo-50'
  | 'focus:from-indigo-50'
  | 'sm:via-indigo-50'
  | 'md:via-indigo-50'
  | 'lg:via-indigo-50'
  | 'xl:via-indigo-50'
  | '2xl:via-indigo-50'
  | 'hover:via-indigo-50'
  | 'focus:via-indigo-50'
  | 'sm:to-indigo-50'
  | 'md:to-indigo-50'
  | 'lg:to-indigo-50'
  | 'xl:to-indigo-50'
  | '2xl:to-indigo-50'
  | 'hover:to-indigo-50'
  | 'focus:to-indigo-50'
  | 'sm:from-indigo-100'
  | 'md:from-indigo-100'
  | 'lg:from-indigo-100'
  | 'xl:from-indigo-100'
  | '2xl:from-indigo-100'
  | 'hover:from-indigo-100'
  | 'focus:from-indigo-100'
  | 'sm:via-indigo-100'
  | 'md:via-indigo-100'
  | 'lg:via-indigo-100'
  | 'xl:via-indigo-100'
  | '2xl:via-indigo-100'
  | 'hover:via-indigo-100'
  | 'focus:via-indigo-100'
  | 'sm:to-indigo-100'
  | 'md:to-indigo-100'
  | 'lg:to-indigo-100'
  | 'xl:to-indigo-100'
  | '2xl:to-indigo-100'
  | 'hover:to-indigo-100'
  | 'focus:to-indigo-100'
  | 'sm:from-indigo-200'
  | 'md:from-indigo-200'
  | 'lg:from-indigo-200'
  | 'xl:from-indigo-200'
  | '2xl:from-indigo-200'
  | 'hover:from-indigo-200'
  | 'focus:from-indigo-200'
  | 'sm:via-indigo-200'
  | 'md:via-indigo-200'
  | 'lg:via-indigo-200'
  | 'xl:via-indigo-200'
  | '2xl:via-indigo-200'
  | 'hover:via-indigo-200'
  | 'focus:via-indigo-200'
  | 'sm:to-indigo-200'
  | 'md:to-indigo-200'
  | 'lg:to-indigo-200'
  | 'xl:to-indigo-200'
  | '2xl:to-indigo-200'
  | 'hover:to-indigo-200'
  | 'focus:to-indigo-200'
  | 'sm:from-indigo-300'
  | 'md:from-indigo-300'
  | 'lg:from-indigo-300'
  | 'xl:from-indigo-300'
  | '2xl:from-indigo-300'
  | 'hover:from-indigo-300'
  | 'focus:from-indigo-300'
  | 'sm:via-indigo-300'
  | 'md:via-indigo-300'
  | 'lg:via-indigo-300'
  | 'xl:via-indigo-300'
  | '2xl:via-indigo-300'
  | 'hover:via-indigo-300'
  | 'focus:via-indigo-300'
  | 'sm:to-indigo-300'
  | 'md:to-indigo-300'
  | 'lg:to-indigo-300'
  | 'xl:to-indigo-300'
  | '2xl:to-indigo-300'
  | 'hover:to-indigo-300'
  | 'focus:to-indigo-300'
  | 'sm:from-indigo-400'
  | 'md:from-indigo-400'
  | 'lg:from-indigo-400'
  | 'xl:from-indigo-400'
  | '2xl:from-indigo-400'
  | 'hover:from-indigo-400'
  | 'focus:from-indigo-400'
  | 'sm:via-indigo-400'
  | 'md:via-indigo-400'
  | 'lg:via-indigo-400'
  | 'xl:via-indigo-400'
  | '2xl:via-indigo-400'
  | 'hover:via-indigo-400'
  | 'focus:via-indigo-400'
  | 'sm:to-indigo-400'
  | 'md:to-indigo-400'
  | 'lg:to-indigo-400'
  | 'xl:to-indigo-400'
  | '2xl:to-indigo-400'
  | 'hover:to-indigo-400'
  | 'focus:to-indigo-400'
  | 'sm:from-indigo-500'
  | 'md:from-indigo-500'
  | 'lg:from-indigo-500'
  | 'xl:from-indigo-500'
  | '2xl:from-indigo-500'
  | 'hover:from-indigo-500'
  | 'focus:from-indigo-500'
  | 'sm:via-indigo-500'
  | 'md:via-indigo-500'
  | 'lg:via-indigo-500'
  | 'xl:via-indigo-500'
  | '2xl:via-indigo-500'
  | 'hover:via-indigo-500'
  | 'focus:via-indigo-500'
  | 'sm:to-indigo-500'
  | 'md:to-indigo-500'
  | 'lg:to-indigo-500'
  | 'xl:to-indigo-500'
  | '2xl:to-indigo-500'
  | 'hover:to-indigo-500'
  | 'focus:to-indigo-500'
  | 'sm:from-indigo-600'
  | 'md:from-indigo-600'
  | 'lg:from-indigo-600'
  | 'xl:from-indigo-600'
  | '2xl:from-indigo-600'
  | 'hover:from-indigo-600'
  | 'focus:from-indigo-600'
  | 'sm:via-indigo-600'
  | 'md:via-indigo-600'
  | 'lg:via-indigo-600'
  | 'xl:via-indigo-600'
  | '2xl:via-indigo-600'
  | 'hover:via-indigo-600'
  | 'focus:via-indigo-600'
  | 'sm:to-indigo-600'
  | 'md:to-indigo-600'
  | 'lg:to-indigo-600'
  | 'xl:to-indigo-600'
  | '2xl:to-indigo-600'
  | 'hover:to-indigo-600'
  | 'focus:to-indigo-600'
  | 'sm:from-indigo-700'
  | 'md:from-indigo-700'
  | 'lg:from-indigo-700'
  | 'xl:from-indigo-700'
  | '2xl:from-indigo-700'
  | 'hover:from-indigo-700'
  | 'focus:from-indigo-700'
  | 'sm:via-indigo-700'
  | 'md:via-indigo-700'
  | 'lg:via-indigo-700'
  | 'xl:via-indigo-700'
  | '2xl:via-indigo-700'
  | 'hover:via-indigo-700'
  | 'focus:via-indigo-700'
  | 'sm:to-indigo-700'
  | 'md:to-indigo-700'
  | 'lg:to-indigo-700'
  | 'xl:to-indigo-700'
  | '2xl:to-indigo-700'
  | 'hover:to-indigo-700'
  | 'focus:to-indigo-700'
  | 'sm:from-indigo-800'
  | 'md:from-indigo-800'
  | 'lg:from-indigo-800'
  | 'xl:from-indigo-800'
  | '2xl:from-indigo-800'
  | 'hover:from-indigo-800'
  | 'focus:from-indigo-800'
  | 'sm:via-indigo-800'
  | 'md:via-indigo-800'
  | 'lg:via-indigo-800'
  | 'xl:via-indigo-800'
  | '2xl:via-indigo-800'
  | 'hover:via-indigo-800'
  | 'focus:via-indigo-800'
  | 'sm:to-indigo-800'
  | 'md:to-indigo-800'
  | 'lg:to-indigo-800'
  | 'xl:to-indigo-800'
  | '2xl:to-indigo-800'
  | 'hover:to-indigo-800'
  | 'focus:to-indigo-800'
  | 'sm:from-indigo-900'
  | 'md:from-indigo-900'
  | 'lg:from-indigo-900'
  | 'xl:from-indigo-900'
  | '2xl:from-indigo-900'
  | 'hover:from-indigo-900'
  | 'focus:from-indigo-900'
  | 'sm:via-indigo-900'
  | 'md:via-indigo-900'
  | 'lg:via-indigo-900'
  | 'xl:via-indigo-900'
  | '2xl:via-indigo-900'
  | 'hover:via-indigo-900'
  | 'focus:via-indigo-900'
  | 'sm:to-indigo-900'
  | 'md:to-indigo-900'
  | 'lg:to-indigo-900'
  | 'xl:to-indigo-900'
  | '2xl:to-indigo-900'
  | 'hover:to-indigo-900'
  | 'focus:to-indigo-900'
  | 'sm:from-purple-50'
  | 'md:from-purple-50'
  | 'lg:from-purple-50'
  | 'xl:from-purple-50'
  | '2xl:from-purple-50'
  | 'hover:from-purple-50'
  | 'focus:from-purple-50'
  | 'sm:via-purple-50'
  | 'md:via-purple-50'
  | 'lg:via-purple-50'
  | 'xl:via-purple-50'
  | '2xl:via-purple-50'
  | 'hover:via-purple-50'
  | 'focus:via-purple-50'
  | 'sm:to-purple-50'
  | 'md:to-purple-50'
  | 'lg:to-purple-50'
  | 'xl:to-purple-50'
  | '2xl:to-purple-50'
  | 'hover:to-purple-50'
  | 'focus:to-purple-50'
  | 'sm:from-purple-100'
  | 'md:from-purple-100'
  | 'lg:from-purple-100'
  | 'xl:from-purple-100'
  | '2xl:from-purple-100'
  | 'hover:from-purple-100'
  | 'focus:from-purple-100'
  | 'sm:via-purple-100'
  | 'md:via-purple-100'
  | 'lg:via-purple-100'
  | 'xl:via-purple-100'
  | '2xl:via-purple-100'
  | 'hover:via-purple-100'
  | 'focus:via-purple-100'
  | 'sm:to-purple-100'
  | 'md:to-purple-100'
  | 'lg:to-purple-100'
  | 'xl:to-purple-100'
  | '2xl:to-purple-100'
  | 'hover:to-purple-100'
  | 'focus:to-purple-100'
  | 'sm:from-purple-200'
  | 'md:from-purple-200'
  | 'lg:from-purple-200'
  | 'xl:from-purple-200'
  | '2xl:from-purple-200'
  | 'hover:from-purple-200'
  | 'focus:from-purple-200'
  | 'sm:via-purple-200'
  | 'md:via-purple-200'
  | 'lg:via-purple-200'
  | 'xl:via-purple-200'
  | '2xl:via-purple-200'
  | 'hover:via-purple-200'
  | 'focus:via-purple-200'
  | 'sm:to-purple-200'
  | 'md:to-purple-200'
  | 'lg:to-purple-200'
  | 'xl:to-purple-200'
  | '2xl:to-purple-200'
  | 'hover:to-purple-200'
  | 'focus:to-purple-200'
  | 'sm:from-purple-300'
  | 'md:from-purple-300'
  | 'lg:from-purple-300'
  | 'xl:from-purple-300'
  | '2xl:from-purple-300'
  | 'hover:from-purple-300'
  | 'focus:from-purple-300'
  | 'sm:via-purple-300'
  | 'md:via-purple-300'
  | 'lg:via-purple-300'
  | 'xl:via-purple-300'
  | '2xl:via-purple-300'
  | 'hover:via-purple-300'
  | 'focus:via-purple-300'
  | 'sm:to-purple-300'
  | 'md:to-purple-300'
  | 'lg:to-purple-300'
  | 'xl:to-purple-300'
  | '2xl:to-purple-300'
  | 'hover:to-purple-300'
  | 'focus:to-purple-300'
  | 'sm:from-purple-400'
  | 'md:from-purple-400'
  | 'lg:from-purple-400'
  | 'xl:from-purple-400'
  | '2xl:from-purple-400'
  | 'hover:from-purple-400'
  | 'focus:from-purple-400'
  | 'sm:via-purple-400'
  | 'md:via-purple-400'
  | 'lg:via-purple-400'
  | 'xl:via-purple-400'
  | '2xl:via-purple-400'
  | 'hover:via-purple-400'
  | 'focus:via-purple-400'
  | 'sm:to-purple-400'
  | 'md:to-purple-400'
  | 'lg:to-purple-400'
  | 'xl:to-purple-400'
  | '2xl:to-purple-400'
  | 'hover:to-purple-400'
  | 'focus:to-purple-400'
  | 'sm:from-purple-500'
  | 'md:from-purple-500'
  | 'lg:from-purple-500'
  | 'xl:from-purple-500'
  | '2xl:from-purple-500'
  | 'hover:from-purple-500'
  | 'focus:from-purple-500'
  | 'sm:via-purple-500'
  | 'md:via-purple-500'
  | 'lg:via-purple-500'
  | 'xl:via-purple-500'
  | '2xl:via-purple-500'
  | 'hover:via-purple-500'
  | 'focus:via-purple-500'
  | 'sm:to-purple-500'
  | 'md:to-purple-500'
  | 'lg:to-purple-500'
  | 'xl:to-purple-500'
  | '2xl:to-purple-500'
  | 'hover:to-purple-500'
  | 'focus:to-purple-500'
  | 'sm:from-purple-600'
  | 'md:from-purple-600'
  | 'lg:from-purple-600'
  | 'xl:from-purple-600'
  | '2xl:from-purple-600'
  | 'hover:from-purple-600'
  | 'focus:from-purple-600'
  | 'sm:via-purple-600'
  | 'md:via-purple-600'
  | 'lg:via-purple-600'
  | 'xl:via-purple-600'
  | '2xl:via-purple-600'
  | 'hover:via-purple-600'
  | 'focus:via-purple-600'
  | 'sm:to-purple-600'
  | 'md:to-purple-600'
  | 'lg:to-purple-600'
  | 'xl:to-purple-600'
  | '2xl:to-purple-600'
  | 'hover:to-purple-600'
  | 'focus:to-purple-600'
  | 'sm:from-purple-700'
  | 'md:from-purple-700'
  | 'lg:from-purple-700'
  | 'xl:from-purple-700'
  | '2xl:from-purple-700'
  | 'hover:from-purple-700'
  | 'focus:from-purple-700'
  | 'sm:via-purple-700'
  | 'md:via-purple-700'
  | 'lg:via-purple-700'
  | 'xl:via-purple-700'
  | '2xl:via-purple-700'
  | 'hover:via-purple-700'
  | 'focus:via-purple-700'
  | 'sm:to-purple-700'
  | 'md:to-purple-700'
  | 'lg:to-purple-700'
  | 'xl:to-purple-700'
  | '2xl:to-purple-700'
  | 'hover:to-purple-700'
  | 'focus:to-purple-700'
  | 'sm:from-purple-800'
  | 'md:from-purple-800'
  | 'lg:from-purple-800'
  | 'xl:from-purple-800'
  | '2xl:from-purple-800'
  | 'hover:from-purple-800'
  | 'focus:from-purple-800'
  | 'sm:via-purple-800'
  | 'md:via-purple-800'
  | 'lg:via-purple-800'
  | 'xl:via-purple-800'
  | '2xl:via-purple-800'
  | 'hover:via-purple-800'
  | 'focus:via-purple-800'
  | 'sm:to-purple-800'
  | 'md:to-purple-800'
  | 'lg:to-purple-800'
  | 'xl:to-purple-800'
  | '2xl:to-purple-800'
  | 'hover:to-purple-800'
  | 'focus:to-purple-800'
  | 'sm:from-purple-900'
  | 'md:from-purple-900'
  | 'lg:from-purple-900'
  | 'xl:from-purple-900'
  | '2xl:from-purple-900'
  | 'hover:from-purple-900'
  | 'focus:from-purple-900'
  | 'sm:via-purple-900'
  | 'md:via-purple-900'
  | 'lg:via-purple-900'
  | 'xl:via-purple-900'
  | '2xl:via-purple-900'
  | 'hover:via-purple-900'
  | 'focus:via-purple-900'
  | 'sm:to-purple-900'
  | 'md:to-purple-900'
  | 'lg:to-purple-900'
  | 'xl:to-purple-900'
  | '2xl:to-purple-900'
  | 'hover:to-purple-900'
  | 'focus:to-purple-900'
  | 'sm:from-pink-50'
  | 'md:from-pink-50'
  | 'lg:from-pink-50'
  | 'xl:from-pink-50'
  | '2xl:from-pink-50'
  | 'hover:from-pink-50'
  | 'focus:from-pink-50'
  | 'sm:via-pink-50'
  | 'md:via-pink-50'
  | 'lg:via-pink-50'
  | 'xl:via-pink-50'
  | '2xl:via-pink-50'
  | 'hover:via-pink-50'
  | 'focus:via-pink-50'
  | 'sm:to-pink-50'
  | 'md:to-pink-50'
  | 'lg:to-pink-50'
  | 'xl:to-pink-50'
  | '2xl:to-pink-50'
  | 'hover:to-pink-50'
  | 'focus:to-pink-50'
  | 'sm:from-pink-100'
  | 'md:from-pink-100'
  | 'lg:from-pink-100'
  | 'xl:from-pink-100'
  | '2xl:from-pink-100'
  | 'hover:from-pink-100'
  | 'focus:from-pink-100'
  | 'sm:via-pink-100'
  | 'md:via-pink-100'
  | 'lg:via-pink-100'
  | 'xl:via-pink-100'
  | '2xl:via-pink-100'
  | 'hover:via-pink-100'
  | 'focus:via-pink-100'
  | 'sm:to-pink-100'
  | 'md:to-pink-100'
  | 'lg:to-pink-100'
  | 'xl:to-pink-100'
  | '2xl:to-pink-100'
  | 'hover:to-pink-100'
  | 'focus:to-pink-100'
  | 'sm:from-pink-200'
  | 'md:from-pink-200'
  | 'lg:from-pink-200'
  | 'xl:from-pink-200'
  | '2xl:from-pink-200'
  | 'hover:from-pink-200'
  | 'focus:from-pink-200'
  | 'sm:via-pink-200'
  | 'md:via-pink-200'
  | 'lg:via-pink-200'
  | 'xl:via-pink-200'
  | '2xl:via-pink-200'
  | 'hover:via-pink-200'
  | 'focus:via-pink-200'
  | 'sm:to-pink-200'
  | 'md:to-pink-200'
  | 'lg:to-pink-200'
  | 'xl:to-pink-200'
  | '2xl:to-pink-200'
  | 'hover:to-pink-200'
  | 'focus:to-pink-200'
  | 'sm:from-pink-300'
  | 'md:from-pink-300'
  | 'lg:from-pink-300'
  | 'xl:from-pink-300'
  | '2xl:from-pink-300'
  | 'hover:from-pink-300'
  | 'focus:from-pink-300'
  | 'sm:via-pink-300'
  | 'md:via-pink-300'
  | 'lg:via-pink-300'
  | 'xl:via-pink-300'
  | '2xl:via-pink-300'
  | 'hover:via-pink-300'
  | 'focus:via-pink-300'
  | 'sm:to-pink-300'
  | 'md:to-pink-300'
  | 'lg:to-pink-300'
  | 'xl:to-pink-300'
  | '2xl:to-pink-300'
  | 'hover:to-pink-300'
  | 'focus:to-pink-300'
  | 'sm:from-pink-400'
  | 'md:from-pink-400'
  | 'lg:from-pink-400'
  | 'xl:from-pink-400'
  | '2xl:from-pink-400'
  | 'hover:from-pink-400'
  | 'focus:from-pink-400'
  | 'sm:via-pink-400'
  | 'md:via-pink-400'
  | 'lg:via-pink-400'
  | 'xl:via-pink-400'
  | '2xl:via-pink-400'
  | 'hover:via-pink-400'
  | 'focus:via-pink-400'
  | 'sm:to-pink-400'
  | 'md:to-pink-400'
  | 'lg:to-pink-400'
  | 'xl:to-pink-400'
  | '2xl:to-pink-400'
  | 'hover:to-pink-400'
  | 'focus:to-pink-400'
  | 'sm:from-pink-500'
  | 'md:from-pink-500'
  | 'lg:from-pink-500'
  | 'xl:from-pink-500'
  | '2xl:from-pink-500'
  | 'hover:from-pink-500'
  | 'focus:from-pink-500'
  | 'sm:via-pink-500'
  | 'md:via-pink-500'
  | 'lg:via-pink-500'
  | 'xl:via-pink-500'
  | '2xl:via-pink-500'
  | 'hover:via-pink-500'
  | 'focus:via-pink-500'
  | 'sm:to-pink-500'
  | 'md:to-pink-500'
  | 'lg:to-pink-500'
  | 'xl:to-pink-500'
  | '2xl:to-pink-500'
  | 'hover:to-pink-500'
  | 'focus:to-pink-500'
  | 'sm:from-pink-600'
  | 'md:from-pink-600'
  | 'lg:from-pink-600'
  | 'xl:from-pink-600'
  | '2xl:from-pink-600'
  | 'hover:from-pink-600'
  | 'focus:from-pink-600'
  | 'sm:via-pink-600'
  | 'md:via-pink-600'
  | 'lg:via-pink-600'
  | 'xl:via-pink-600'
  | '2xl:via-pink-600'
  | 'hover:via-pink-600'
  | 'focus:via-pink-600'
  | 'sm:to-pink-600'
  | 'md:to-pink-600'
  | 'lg:to-pink-600'
  | 'xl:to-pink-600'
  | '2xl:to-pink-600'
  | 'hover:to-pink-600'
  | 'focus:to-pink-600'
  | 'sm:from-pink-700'
  | 'md:from-pink-700'
  | 'lg:from-pink-700'
  | 'xl:from-pink-700'
  | '2xl:from-pink-700'
  | 'hover:from-pink-700'
  | 'focus:from-pink-700'
  | 'sm:via-pink-700'
  | 'md:via-pink-700'
  | 'lg:via-pink-700'
  | 'xl:via-pink-700'
  | '2xl:via-pink-700'
  | 'hover:via-pink-700'
  | 'focus:via-pink-700'
  | 'sm:to-pink-700'
  | 'md:to-pink-700'
  | 'lg:to-pink-700'
  | 'xl:to-pink-700'
  | '2xl:to-pink-700'
  | 'hover:to-pink-700'
  | 'focus:to-pink-700'
  | 'sm:from-pink-800'
  | 'md:from-pink-800'
  | 'lg:from-pink-800'
  | 'xl:from-pink-800'
  | '2xl:from-pink-800'
  | 'hover:from-pink-800'
  | 'focus:from-pink-800'
  | 'sm:via-pink-800'
  | 'md:via-pink-800'
  | 'lg:via-pink-800'
  | 'xl:via-pink-800'
  | '2xl:via-pink-800'
  | 'hover:via-pink-800'
  | 'focus:via-pink-800'
  | 'sm:to-pink-800'
  | 'md:to-pink-800'
  | 'lg:to-pink-800'
  | 'xl:to-pink-800'
  | '2xl:to-pink-800'
  | 'hover:to-pink-800'
  | 'focus:to-pink-800'
  | 'sm:from-pink-900'
  | 'md:from-pink-900'
  | 'lg:from-pink-900'
  | 'xl:from-pink-900'
  | '2xl:from-pink-900'
  | 'hover:from-pink-900'
  | 'focus:from-pink-900'
  | 'sm:via-pink-900'
  | 'md:via-pink-900'
  | 'lg:via-pink-900'
  | 'xl:via-pink-900'
  | '2xl:via-pink-900'
  | 'hover:via-pink-900'
  | 'focus:via-pink-900'
  | 'sm:to-pink-900'
  | 'md:to-pink-900'
  | 'lg:to-pink-900'
  | 'xl:to-pink-900'
  | '2xl:to-pink-900'
  | 'hover:to-pink-900'
  | 'focus:to-pink-900'
  | 'sm:grayscale-0'
  | 'md:grayscale-0'
  | 'lg:grayscale-0'
  | 'xl:grayscale-0'
  | '2xl:grayscale-0'
  | 'sm:grayscale'
  | 'md:grayscale'
  | 'lg:grayscale'
  | 'xl:grayscale'
  | '2xl:grayscale'
  | 'sm:auto-cols-auto'
  | 'md:auto-cols-auto'
  | 'lg:auto-cols-auto'
  | 'xl:auto-cols-auto'
  | '2xl:auto-cols-auto'
  | 'sm:auto-cols-min'
  | 'md:auto-cols-min'
  | 'lg:auto-cols-min'
  | 'xl:auto-cols-min'
  | '2xl:auto-cols-min'
  | 'sm:auto-cols-max'
  | 'md:auto-cols-max'
  | 'lg:auto-cols-max'
  | 'xl:auto-cols-max'
  | '2xl:auto-cols-max'
  | 'sm:auto-cols-fr'
  | 'md:auto-cols-fr'
  | 'lg:auto-cols-fr'
  | 'xl:auto-cols-fr'
  | '2xl:auto-cols-fr'
  | 'sm:grid-flow-row'
  | 'md:grid-flow-row'
  | 'lg:grid-flow-row'
  | 'xl:grid-flow-row'
  | '2xl:grid-flow-row'
  | 'sm:grid-flow-col'
  | 'md:grid-flow-col'
  | 'lg:grid-flow-col'
  | 'xl:grid-flow-col'
  | '2xl:grid-flow-col'
  | 'sm:grid-flow-row-dense'
  | 'md:grid-flow-row-dense'
  | 'lg:grid-flow-row-dense'
  | 'xl:grid-flow-row-dense'
  | '2xl:grid-flow-row-dense'
  | 'sm:grid-flow-col-dense'
  | 'md:grid-flow-col-dense'
  | 'lg:grid-flow-col-dense'
  | 'xl:grid-flow-col-dense'
  | '2xl:grid-flow-col-dense'
  | 'sm:auto-rows-auto'
  | 'md:auto-rows-auto'
  | 'lg:auto-rows-auto'
  | 'xl:auto-rows-auto'
  | '2xl:auto-rows-auto'
  | 'sm:auto-rows-min'
  | 'md:auto-rows-min'
  | 'lg:auto-rows-min'
  | 'xl:auto-rows-min'
  | '2xl:auto-rows-min'
  | 'sm:auto-rows-max'
  | 'md:auto-rows-max'
  | 'lg:auto-rows-max'
  | 'xl:auto-rows-max'
  | '2xl:auto-rows-max'
  | 'sm:auto-rows-fr'
  | 'md:auto-rows-fr'
  | 'lg:auto-rows-fr'
  | 'xl:auto-rows-fr'
  | '2xl:auto-rows-fr'
  | 'sm:col-auto'
  | 'md:col-auto'
  | 'lg:col-auto'
  | 'xl:col-auto'
  | '2xl:col-auto'
  | 'sm:col-span-1'
  | 'md:col-span-1'
  | 'lg:col-span-1'
  | 'xl:col-span-1'
  | '2xl:col-span-1'
  | 'sm:col-span-2'
  | 'md:col-span-2'
  | 'lg:col-span-2'
  | 'xl:col-span-2'
  | '2xl:col-span-2'
  | 'sm:col-span-3'
  | 'md:col-span-3'
  | 'lg:col-span-3'
  | 'xl:col-span-3'
  | '2xl:col-span-3'
  | 'sm:col-span-4'
  | 'md:col-span-4'
  | 'lg:col-span-4'
  | 'xl:col-span-4'
  | '2xl:col-span-4'
  | 'sm:col-span-5'
  | 'md:col-span-5'
  | 'lg:col-span-5'
  | 'xl:col-span-5'
  | '2xl:col-span-5'
  | 'sm:col-span-6'
  | 'md:col-span-6'
  | 'lg:col-span-6'
  | 'xl:col-span-6'
  | '2xl:col-span-6'
  | 'sm:col-span-7'
  | 'md:col-span-7'
  | 'lg:col-span-7'
  | 'xl:col-span-7'
  | '2xl:col-span-7'
  | 'sm:col-span-8'
  | 'md:col-span-8'
  | 'lg:col-span-8'
  | 'xl:col-span-8'
  | '2xl:col-span-8'
  | 'sm:col-span-9'
  | 'md:col-span-9'
  | 'lg:col-span-9'
  | 'xl:col-span-9'
  | '2xl:col-span-9'
  | 'sm:col-span-10'
  | 'md:col-span-10'
  | 'lg:col-span-10'
  | 'xl:col-span-10'
  | '2xl:col-span-10'
  | 'sm:col-span-11'
  | 'md:col-span-11'
  | 'lg:col-span-11'
  | 'xl:col-span-11'
  | '2xl:col-span-11'
  | 'sm:col-span-12'
  | 'md:col-span-12'
  | 'lg:col-span-12'
  | 'xl:col-span-12'
  | '2xl:col-span-12'
  | 'sm:col-span-full'
  | 'md:col-span-full'
  | 'lg:col-span-full'
  | 'xl:col-span-full'
  | '2xl:col-span-full'
  | 'sm:col-end-1'
  | 'md:col-end-1'
  | 'lg:col-end-1'
  | 'xl:col-end-1'
  | '2xl:col-end-1'
  | 'sm:col-end-2'
  | 'md:col-end-2'
  | 'lg:col-end-2'
  | 'xl:col-end-2'
  | '2xl:col-end-2'
  | 'sm:col-end-3'
  | 'md:col-end-3'
  | 'lg:col-end-3'
  | 'xl:col-end-3'
  | '2xl:col-end-3'
  | 'sm:col-end-4'
  | 'md:col-end-4'
  | 'lg:col-end-4'
  | 'xl:col-end-4'
  | '2xl:col-end-4'
  | 'sm:col-end-5'
  | 'md:col-end-5'
  | 'lg:col-end-5'
  | 'xl:col-end-5'
  | '2xl:col-end-5'
  | 'sm:col-end-6'
  | 'md:col-end-6'
  | 'lg:col-end-6'
  | 'xl:col-end-6'
  | '2xl:col-end-6'
  | 'sm:col-end-7'
  | 'md:col-end-7'
  | 'lg:col-end-7'
  | 'xl:col-end-7'
  | '2xl:col-end-7'
  | 'sm:col-end-8'
  | 'md:col-end-8'
  | 'lg:col-end-8'
  | 'xl:col-end-8'
  | '2xl:col-end-8'
  | 'sm:col-end-9'
  | 'md:col-end-9'
  | 'lg:col-end-9'
  | 'xl:col-end-9'
  | '2xl:col-end-9'
  | 'sm:col-end-10'
  | 'md:col-end-10'
  | 'lg:col-end-10'
  | 'xl:col-end-10'
  | '2xl:col-end-10'
  | 'sm:col-end-11'
  | 'md:col-end-11'
  | 'lg:col-end-11'
  | 'xl:col-end-11'
  | '2xl:col-end-11'
  | 'sm:col-end-12'
  | 'md:col-end-12'
  | 'lg:col-end-12'
  | 'xl:col-end-12'
  | '2xl:col-end-12'
  | 'sm:col-end-13'
  | 'md:col-end-13'
  | 'lg:col-end-13'
  | 'xl:col-end-13'
  | '2xl:col-end-13'
  | 'sm:col-end-auto'
  | 'md:col-end-auto'
  | 'lg:col-end-auto'
  | 'xl:col-end-auto'
  | '2xl:col-end-auto'
  | 'sm:col-start-1'
  | 'md:col-start-1'
  | 'lg:col-start-1'
  | 'xl:col-start-1'
  | '2xl:col-start-1'
  | 'sm:col-start-2'
  | 'md:col-start-2'
  | 'lg:col-start-2'
  | 'xl:col-start-2'
  | '2xl:col-start-2'
  | 'sm:col-start-3'
  | 'md:col-start-3'
  | 'lg:col-start-3'
  | 'xl:col-start-3'
  | '2xl:col-start-3'
  | 'sm:col-start-4'
  | 'md:col-start-4'
  | 'lg:col-start-4'
  | 'xl:col-start-4'
  | '2xl:col-start-4'
  | 'sm:col-start-5'
  | 'md:col-start-5'
  | 'lg:col-start-5'
  | 'xl:col-start-5'
  | '2xl:col-start-5'
  | 'sm:col-start-6'
  | 'md:col-start-6'
  | 'lg:col-start-6'
  | 'xl:col-start-6'
  | '2xl:col-start-6'
  | 'sm:col-start-7'
  | 'md:col-start-7'
  | 'lg:col-start-7'
  | 'xl:col-start-7'
  | '2xl:col-start-7'
  | 'sm:col-start-8'
  | 'md:col-start-8'
  | 'lg:col-start-8'
  | 'xl:col-start-8'
  | '2xl:col-start-8'
  | 'sm:col-start-9'
  | 'md:col-start-9'
  | 'lg:col-start-9'
  | 'xl:col-start-9'
  | '2xl:col-start-9'
  | 'sm:col-start-10'
  | 'md:col-start-10'
  | 'lg:col-start-10'
  | 'xl:col-start-10'
  | '2xl:col-start-10'
  | 'sm:col-start-11'
  | 'md:col-start-11'
  | 'lg:col-start-11'
  | 'xl:col-start-11'
  | '2xl:col-start-11'
  | 'sm:col-start-12'
  | 'md:col-start-12'
  | 'lg:col-start-12'
  | 'xl:col-start-12'
  | '2xl:col-start-12'
  | 'sm:col-start-13'
  | 'md:col-start-13'
  | 'lg:col-start-13'
  | 'xl:col-start-13'
  | '2xl:col-start-13'
  | 'sm:col-start-auto'
  | 'md:col-start-auto'
  | 'lg:col-start-auto'
  | 'xl:col-start-auto'
  | '2xl:col-start-auto'
  | 'sm:row-auto'
  | 'md:row-auto'
  | 'lg:row-auto'
  | 'xl:row-auto'
  | '2xl:row-auto'
  | 'sm:row-span-1'
  | 'md:row-span-1'
  | 'lg:row-span-1'
  | 'xl:row-span-1'
  | '2xl:row-span-1'
  | 'sm:row-span-2'
  | 'md:row-span-2'
  | 'lg:row-span-2'
  | 'xl:row-span-2'
  | '2xl:row-span-2'
  | 'sm:row-span-3'
  | 'md:row-span-3'
  | 'lg:row-span-3'
  | 'xl:row-span-3'
  | '2xl:row-span-3'
  | 'sm:row-span-4'
  | 'md:row-span-4'
  | 'lg:row-span-4'
  | 'xl:row-span-4'
  | '2xl:row-span-4'
  | 'sm:row-span-5'
  | 'md:row-span-5'
  | 'lg:row-span-5'
  | 'xl:row-span-5'
  | '2xl:row-span-5'
  | 'sm:row-span-6'
  | 'md:row-span-6'
  | 'lg:row-span-6'
  | 'xl:row-span-6'
  | '2xl:row-span-6'
  | 'sm:row-span-full'
  | 'md:row-span-full'
  | 'lg:row-span-full'
  | 'xl:row-span-full'
  | '2xl:row-span-full'
  | 'sm:row-end-1'
  | 'md:row-end-1'
  | 'lg:row-end-1'
  | 'xl:row-end-1'
  | '2xl:row-end-1'
  | 'sm:row-end-2'
  | 'md:row-end-2'
  | 'lg:row-end-2'
  | 'xl:row-end-2'
  | '2xl:row-end-2'
  | 'sm:row-end-3'
  | 'md:row-end-3'
  | 'lg:row-end-3'
  | 'xl:row-end-3'
  | '2xl:row-end-3'
  | 'sm:row-end-4'
  | 'md:row-end-4'
  | 'lg:row-end-4'
  | 'xl:row-end-4'
  | '2xl:row-end-4'
  | 'sm:row-end-5'
  | 'md:row-end-5'
  | 'lg:row-end-5'
  | 'xl:row-end-5'
  | '2xl:row-end-5'
  | 'sm:row-end-6'
  | 'md:row-end-6'
  | 'lg:row-end-6'
  | 'xl:row-end-6'
  | '2xl:row-end-6'
  | 'sm:row-end-7'
  | 'md:row-end-7'
  | 'lg:row-end-7'
  | 'xl:row-end-7'
  | '2xl:row-end-7'
  | 'sm:row-end-auto'
  | 'md:row-end-auto'
  | 'lg:row-end-auto'
  | 'xl:row-end-auto'
  | '2xl:row-end-auto'
  | 'sm:row-start-1'
  | 'md:row-start-1'
  | 'lg:row-start-1'
  | 'xl:row-start-1'
  | '2xl:row-start-1'
  | 'sm:row-start-2'
  | 'md:row-start-2'
  | 'lg:row-start-2'
  | 'xl:row-start-2'
  | '2xl:row-start-2'
  | 'sm:row-start-3'
  | 'md:row-start-3'
  | 'lg:row-start-3'
  | 'xl:row-start-3'
  | '2xl:row-start-3'
  | 'sm:row-start-4'
  | 'md:row-start-4'
  | 'lg:row-start-4'
  | 'xl:row-start-4'
  | '2xl:row-start-4'
  | 'sm:row-start-5'
  | 'md:row-start-5'
  | 'lg:row-start-5'
  | 'xl:row-start-5'
  | '2xl:row-start-5'
  | 'sm:row-start-6'
  | 'md:row-start-6'
  | 'lg:row-start-6'
  | 'xl:row-start-6'
  | '2xl:row-start-6'
  | 'sm:row-start-7'
  | 'md:row-start-7'
  | 'lg:row-start-7'
  | 'xl:row-start-7'
  | '2xl:row-start-7'
  | 'sm:row-start-auto'
  | 'md:row-start-auto'
  | 'lg:row-start-auto'
  | 'xl:row-start-auto'
  | '2xl:row-start-auto'
  | 'sm:grid-cols-1'
  | 'md:grid-cols-1'
  | 'lg:grid-cols-1'
  | 'xl:grid-cols-1'
  | '2xl:grid-cols-1'
  | 'sm:grid-cols-2'
  | 'md:grid-cols-2'
  | 'lg:grid-cols-2'
  | 'xl:grid-cols-2'
  | '2xl:grid-cols-2'
  | 'sm:grid-cols-3'
  | 'md:grid-cols-3'
  | 'lg:grid-cols-3'
  | 'xl:grid-cols-3'
  | '2xl:grid-cols-3'
  | 'sm:grid-cols-4'
  | 'md:grid-cols-4'
  | 'lg:grid-cols-4'
  | 'xl:grid-cols-4'
  | '2xl:grid-cols-4'
  | 'sm:grid-cols-5'
  | 'md:grid-cols-5'
  | 'lg:grid-cols-5'
  | 'xl:grid-cols-5'
  | '2xl:grid-cols-5'
  | 'sm:grid-cols-6'
  | 'md:grid-cols-6'
  | 'lg:grid-cols-6'
  | 'xl:grid-cols-6'
  | '2xl:grid-cols-6'
  | 'sm:grid-cols-7'
  | 'md:grid-cols-7'
  | 'lg:grid-cols-7'
  | 'xl:grid-cols-7'
  | '2xl:grid-cols-7'
  | 'sm:grid-cols-8'
  | 'md:grid-cols-8'
  | 'lg:grid-cols-8'
  | 'xl:grid-cols-8'
  | '2xl:grid-cols-8'
  | 'sm:grid-cols-9'
  | 'md:grid-cols-9'
  | 'lg:grid-cols-9'
  | 'xl:grid-cols-9'
  | '2xl:grid-cols-9'
  | 'sm:grid-cols-10'
  | 'md:grid-cols-10'
  | 'lg:grid-cols-10'
  | 'xl:grid-cols-10'
  | '2xl:grid-cols-10'
  | 'sm:grid-cols-11'
  | 'md:grid-cols-11'
  | 'lg:grid-cols-11'
  | 'xl:grid-cols-11'
  | '2xl:grid-cols-11'
  | 'sm:grid-cols-12'
  | 'md:grid-cols-12'
  | 'lg:grid-cols-12'
  | 'xl:grid-cols-12'
  | '2xl:grid-cols-12'
  | 'sm:grid-cols-none'
  | 'md:grid-cols-none'
  | 'lg:grid-cols-none'
  | 'xl:grid-cols-none'
  | '2xl:grid-cols-none'
  | 'sm:grid-rows-1'
  | 'md:grid-rows-1'
  | 'lg:grid-rows-1'
  | 'xl:grid-rows-1'
  | '2xl:grid-rows-1'
  | 'sm:grid-rows-2'
  | 'md:grid-rows-2'
  | 'lg:grid-rows-2'
  | 'xl:grid-rows-2'
  | '2xl:grid-rows-2'
  | 'sm:grid-rows-3'
  | 'md:grid-rows-3'
  | 'lg:grid-rows-3'
  | 'xl:grid-rows-3'
  | '2xl:grid-rows-3'
  | 'sm:grid-rows-4'
  | 'md:grid-rows-4'
  | 'lg:grid-rows-4'
  | 'xl:grid-rows-4'
  | '2xl:grid-rows-4'
  | 'sm:grid-rows-5'
  | 'md:grid-rows-5'
  | 'lg:grid-rows-5'
  | 'xl:grid-rows-5'
  | '2xl:grid-rows-5'
  | 'sm:grid-rows-6'
  | 'md:grid-rows-6'
  | 'lg:grid-rows-6'
  | 'xl:grid-rows-6'
  | '2xl:grid-rows-6'
  | 'sm:grid-rows-none'
  | 'md:grid-rows-none'
  | 'lg:grid-rows-none'
  | 'xl:grid-rows-none'
  | '2xl:grid-rows-none'
  | 'sm:h-0'
  | 'md:h-0'
  | 'lg:h-0'
  | 'xl:h-0'
  | '2xl:h-0'
  | 'sm:h-1'
  | 'md:h-1'
  | 'lg:h-1'
  | 'xl:h-1'
  | '2xl:h-1'
  | 'sm:h-2'
  | 'md:h-2'
  | 'lg:h-2'
  | 'xl:h-2'
  | '2xl:h-2'
  | 'sm:h-3'
  | 'md:h-3'
  | 'lg:h-3'
  | 'xl:h-3'
  | '2xl:h-3'
  | 'sm:h-4'
  | 'md:h-4'
  | 'lg:h-4'
  | 'xl:h-4'
  | '2xl:h-4'
  | 'sm:h-5'
  | 'md:h-5'
  | 'lg:h-5'
  | 'xl:h-5'
  | '2xl:h-5'
  | 'sm:h-6'
  | 'md:h-6'
  | 'lg:h-6'
  | 'xl:h-6'
  | '2xl:h-6'
  | 'sm:h-7'
  | 'md:h-7'
  | 'lg:h-7'
  | 'xl:h-7'
  | '2xl:h-7'
  | 'sm:h-8'
  | 'md:h-8'
  | 'lg:h-8'
  | 'xl:h-8'
  | '2xl:h-8'
  | 'sm:h-9'
  | 'md:h-9'
  | 'lg:h-9'
  | 'xl:h-9'
  | '2xl:h-9'
  | 'sm:h-10'
  | 'md:h-10'
  | 'lg:h-10'
  | 'xl:h-10'
  | '2xl:h-10'
  | 'sm:h-11'
  | 'md:h-11'
  | 'lg:h-11'
  | 'xl:h-11'
  | '2xl:h-11'
  | 'sm:h-12'
  | 'md:h-12'
  | 'lg:h-12'
  | 'xl:h-12'
  | '2xl:h-12'
  | 'sm:h-14'
  | 'md:h-14'
  | 'lg:h-14'
  | 'xl:h-14'
  | '2xl:h-14'
  | 'sm:h-16'
  | 'md:h-16'
  | 'lg:h-16'
  | 'xl:h-16'
  | '2xl:h-16'
  | 'sm:h-20'
  | 'md:h-20'
  | 'lg:h-20'
  | 'xl:h-20'
  | '2xl:h-20'
  | 'sm:h-24'
  | 'md:h-24'
  | 'lg:h-24'
  | 'xl:h-24'
  | '2xl:h-24'
  | 'sm:h-28'
  | 'md:h-28'
  | 'lg:h-28'
  | 'xl:h-28'
  | '2xl:h-28'
  | 'sm:h-32'
  | 'md:h-32'
  | 'lg:h-32'
  | 'xl:h-32'
  | '2xl:h-32'
  | 'sm:h-36'
  | 'md:h-36'
  | 'lg:h-36'
  | 'xl:h-36'
  | '2xl:h-36'
  | 'sm:h-40'
  | 'md:h-40'
  | 'lg:h-40'
  | 'xl:h-40'
  | '2xl:h-40'
  | 'sm:h-44'
  | 'md:h-44'
  | 'lg:h-44'
  | 'xl:h-44'
  | '2xl:h-44'
  | 'sm:h-48'
  | 'md:h-48'
  | 'lg:h-48'
  | 'xl:h-48'
  | '2xl:h-48'
  | 'sm:h-52'
  | 'md:h-52'
  | 'lg:h-52'
  | 'xl:h-52'
  | '2xl:h-52'
  | 'sm:h-56'
  | 'md:h-56'
  | 'lg:h-56'
  | 'xl:h-56'
  | '2xl:h-56'
  | 'sm:h-60'
  | 'md:h-60'
  | 'lg:h-60'
  | 'xl:h-60'
  | '2xl:h-60'
  | 'sm:h-64'
  | 'md:h-64'
  | 'lg:h-64'
  | 'xl:h-64'
  | '2xl:h-64'
  | 'sm:h-72'
  | 'md:h-72'
  | 'lg:h-72'
  | 'xl:h-72'
  | '2xl:h-72'
  | 'sm:h-80'
  | 'md:h-80'
  | 'lg:h-80'
  | 'xl:h-80'
  | '2xl:h-80'
  | 'sm:h-96'
  | 'md:h-96'
  | 'lg:h-96'
  | 'xl:h-96'
  | '2xl:h-96'
  | 'sm:h-auto'
  | 'md:h-auto'
  | 'lg:h-auto'
  | 'xl:h-auto'
  | '2xl:h-auto'
  | 'sm:h-px'
  | 'md:h-px'
  | 'lg:h-px'
  | 'xl:h-px'
  | '2xl:h-px'
  | 'sm:h-0.5'
  | 'md:h-0.5'
  | 'lg:h-0.5'
  | 'xl:h-0.5'
  | '2xl:h-0.5'
  | 'sm:h-1.5'
  | 'md:h-1.5'
  | 'lg:h-1.5'
  | 'xl:h-1.5'
  | '2xl:h-1.5'
  | 'sm:h-2.5'
  | 'md:h-2.5'
  | 'lg:h-2.5'
  | 'xl:h-2.5'
  | '2xl:h-2.5'
  | 'sm:h-3.5'
  | 'md:h-3.5'
  | 'lg:h-3.5'
  | 'xl:h-3.5'
  | '2xl:h-3.5'
  | 'sm:h-1/2'
  | 'md:h-1/2'
  | 'lg:h-1/2'
  | 'xl:h-1/2'
  | '2xl:h-1/2'
  | 'sm:h-1/3'
  | 'md:h-1/3'
  | 'lg:h-1/3'
  | 'xl:h-1/3'
  | '2xl:h-1/3'
  | 'sm:h-2/3'
  | 'md:h-2/3'
  | 'lg:h-2/3'
  | 'xl:h-2/3'
  | '2xl:h-2/3'
  | 'sm:h-1/4'
  | 'md:h-1/4'
  | 'lg:h-1/4'
  | 'xl:h-1/4'
  | '2xl:h-1/4'
  | 'sm:h-2/4'
  | 'md:h-2/4'
  | 'lg:h-2/4'
  | 'xl:h-2/4'
  | '2xl:h-2/4'
  | 'sm:h-3/4'
  | 'md:h-3/4'
  | 'lg:h-3/4'
  | 'xl:h-3/4'
  | '2xl:h-3/4'
  | 'sm:h-1/5'
  | 'md:h-1/5'
  | 'lg:h-1/5'
  | 'xl:h-1/5'
  | '2xl:h-1/5'
  | 'sm:h-2/5'
  | 'md:h-2/5'
  | 'lg:h-2/5'
  | 'xl:h-2/5'
  | '2xl:h-2/5'
  | 'sm:h-3/5'
  | 'md:h-3/5'
  | 'lg:h-3/5'
  | 'xl:h-3/5'
  | '2xl:h-3/5'
  | 'sm:h-4/5'
  | 'md:h-4/5'
  | 'lg:h-4/5'
  | 'xl:h-4/5'
  | '2xl:h-4/5'
  | 'sm:h-1/6'
  | 'md:h-1/6'
  | 'lg:h-1/6'
  | 'xl:h-1/6'
  | '2xl:h-1/6'
  | 'sm:h-2/6'
  | 'md:h-2/6'
  | 'lg:h-2/6'
  | 'xl:h-2/6'
  | '2xl:h-2/6'
  | 'sm:h-3/6'
  | 'md:h-3/6'
  | 'lg:h-3/6'
  | 'xl:h-3/6'
  | '2xl:h-3/6'
  | 'sm:h-4/6'
  | 'md:h-4/6'
  | 'lg:h-4/6'
  | 'xl:h-4/6'
  | '2xl:h-4/6'
  | 'sm:h-5/6'
  | 'md:h-5/6'
  | 'lg:h-5/6'
  | 'xl:h-5/6'
  | '2xl:h-5/6'
  | 'sm:h-full'
  | 'md:h-full'
  | 'lg:h-full'
  | 'xl:h-full'
  | '2xl:h-full'
  | 'sm:h-screen'
  | 'md:h-screen'
  | 'lg:h-screen'
  | 'xl:h-screen'
  | '2xl:h-screen'
  | 'sm:hue-rotate-0'
  | 'md:hue-rotate-0'
  | 'lg:hue-rotate-0'
  | 'xl:hue-rotate-0'
  | '2xl:hue-rotate-0'
  | 'sm:hue-rotate-15'
  | 'md:hue-rotate-15'
  | 'lg:hue-rotate-15'
  | 'xl:hue-rotate-15'
  | '2xl:hue-rotate-15'
  | 'sm:hue-rotate-30'
  | 'md:hue-rotate-30'
  | 'lg:hue-rotate-30'
  | 'xl:hue-rotate-30'
  | '2xl:hue-rotate-30'
  | 'sm:hue-rotate-60'
  | 'md:hue-rotate-60'
  | 'lg:hue-rotate-60'
  | 'xl:hue-rotate-60'
  | '2xl:hue-rotate-60'
  | 'sm:hue-rotate-90'
  | 'md:hue-rotate-90'
  | 'lg:hue-rotate-90'
  | 'xl:hue-rotate-90'
  | '2xl:hue-rotate-90'
  | 'sm:hue-rotate-180'
  | 'md:hue-rotate-180'
  | 'lg:hue-rotate-180'
  | 'xl:hue-rotate-180'
  | '2xl:hue-rotate-180'
  | 'sm:-hue-rotate-180'
  | 'md:-hue-rotate-180'
  | 'lg:-hue-rotate-180'
  | 'xl:-hue-rotate-180'
  | '2xl:-hue-rotate-180'
  | 'sm:-hue-rotate-90'
  | 'md:-hue-rotate-90'
  | 'lg:-hue-rotate-90'
  | 'xl:-hue-rotate-90'
  | '2xl:-hue-rotate-90'
  | 'sm:-hue-rotate-60'
  | 'md:-hue-rotate-60'
  | 'lg:-hue-rotate-60'
  | 'xl:-hue-rotate-60'
  | '2xl:-hue-rotate-60'
  | 'sm:-hue-rotate-30'
  | 'md:-hue-rotate-30'
  | 'lg:-hue-rotate-30'
  | 'xl:-hue-rotate-30'
  | '2xl:-hue-rotate-30'
  | 'sm:-hue-rotate-15'
  | 'md:-hue-rotate-15'
  | 'lg:-hue-rotate-15'
  | 'xl:-hue-rotate-15'
  | '2xl:-hue-rotate-15'
  | 'sm:inset-0'
  | 'md:inset-0'
  | 'lg:inset-0'
  | 'xl:inset-0'
  | '2xl:inset-0'
  | 'sm:inset-x-0'
  | 'md:inset-x-0'
  | 'lg:inset-x-0'
  | 'xl:inset-x-0'
  | '2xl:inset-x-0'
  | 'sm:inset-y-0'
  | 'md:inset-y-0'
  | 'lg:inset-y-0'
  | 'xl:inset-y-0'
  | '2xl:inset-y-0'
  | 'sm:top-0'
  | 'md:top-0'
  | 'lg:top-0'
  | 'xl:top-0'
  | '2xl:top-0'
  | 'sm:right-0'
  | 'md:right-0'
  | 'lg:right-0'
  | 'xl:right-0'
  | '2xl:right-0'
  | 'sm:bottom-0'
  | 'md:bottom-0'
  | 'lg:bottom-0'
  | 'xl:bottom-0'
  | '2xl:bottom-0'
  | 'sm:left-0'
  | 'md:left-0'
  | 'lg:left-0'
  | 'xl:left-0'
  | '2xl:left-0'
  | 'sm:inset-1'
  | 'md:inset-1'
  | 'lg:inset-1'
  | 'xl:inset-1'
  | '2xl:inset-1'
  | 'sm:inset-x-1'
  | 'md:inset-x-1'
  | 'lg:inset-x-1'
  | 'xl:inset-x-1'
  | '2xl:inset-x-1'
  | 'sm:inset-y-1'
  | 'md:inset-y-1'
  | 'lg:inset-y-1'
  | 'xl:inset-y-1'
  | '2xl:inset-y-1'
  | 'sm:top-1'
  | 'md:top-1'
  | 'lg:top-1'
  | 'xl:top-1'
  | '2xl:top-1'
  | 'sm:right-1'
  | 'md:right-1'
  | 'lg:right-1'
  | 'xl:right-1'
  | '2xl:right-1'
  | 'sm:bottom-1'
  | 'md:bottom-1'
  | 'lg:bottom-1'
  | 'xl:bottom-1'
  | '2xl:bottom-1'
  | 'sm:left-1'
  | 'md:left-1'
  | 'lg:left-1'
  | 'xl:left-1'
  | '2xl:left-1'
  | 'sm:inset-2'
  | 'md:inset-2'
  | 'lg:inset-2'
  | 'xl:inset-2'
  | '2xl:inset-2'
  | 'sm:inset-x-2'
  | 'md:inset-x-2'
  | 'lg:inset-x-2'
  | 'xl:inset-x-2'
  | '2xl:inset-x-2'
  | 'sm:inset-y-2'
  | 'md:inset-y-2'
  | 'lg:inset-y-2'
  | 'xl:inset-y-2'
  | '2xl:inset-y-2'
  | 'sm:top-2'
  | 'md:top-2'
  | 'lg:top-2'
  | 'xl:top-2'
  | '2xl:top-2'
  | 'sm:right-2'
  | 'md:right-2'
  | 'lg:right-2'
  | 'xl:right-2'
  | '2xl:right-2'
  | 'sm:bottom-2'
  | 'md:bottom-2'
  | 'lg:bottom-2'
  | 'xl:bottom-2'
  | '2xl:bottom-2'
  | 'sm:left-2'
  | 'md:left-2'
  | 'lg:left-2'
  | 'xl:left-2'
  | '2xl:left-2'
  | 'sm:inset-3'
  | 'md:inset-3'
  | 'lg:inset-3'
  | 'xl:inset-3'
  | '2xl:inset-3'
  | 'sm:inset-x-3'
  | 'md:inset-x-3'
  | 'lg:inset-x-3'
  | 'xl:inset-x-3'
  | '2xl:inset-x-3'
  | 'sm:inset-y-3'
  | 'md:inset-y-3'
  | 'lg:inset-y-3'
  | 'xl:inset-y-3'
  | '2xl:inset-y-3'
  | 'sm:top-3'
  | 'md:top-3'
  | 'lg:top-3'
  | 'xl:top-3'
  | '2xl:top-3'
  | 'sm:right-3'
  | 'md:right-3'
  | 'lg:right-3'
  | 'xl:right-3'
  | '2xl:right-3'
  | 'sm:bottom-3'
  | 'md:bottom-3'
  | 'lg:bottom-3'
  | 'xl:bottom-3'
  | '2xl:bottom-3'
  | 'sm:left-3'
  | 'md:left-3'
  | 'lg:left-3'
  | 'xl:left-3'
  | '2xl:left-3'
  | 'sm:inset-4'
  | 'md:inset-4'
  | 'lg:inset-4'
  | 'xl:inset-4'
  | '2xl:inset-4'
  | 'sm:inset-x-4'
  | 'md:inset-x-4'
  | 'lg:inset-x-4'
  | 'xl:inset-x-4'
  | '2xl:inset-x-4'
  | 'sm:inset-y-4'
  | 'md:inset-y-4'
  | 'lg:inset-y-4'
  | 'xl:inset-y-4'
  | '2xl:inset-y-4'
  | 'sm:top-4'
  | 'md:top-4'
  | 'lg:top-4'
  | 'xl:top-4'
  | '2xl:top-4'
  | 'sm:right-4'
  | 'md:right-4'
  | 'lg:right-4'
  | 'xl:right-4'
  | '2xl:right-4'
  | 'sm:bottom-4'
  | 'md:bottom-4'
  | 'lg:bottom-4'
  | 'xl:bottom-4'
  | '2xl:bottom-4'
  | 'sm:left-4'
  | 'md:left-4'
  | 'lg:left-4'
  | 'xl:left-4'
  | '2xl:left-4'
  | 'sm:inset-5'
  | 'md:inset-5'
  | 'lg:inset-5'
  | 'xl:inset-5'
  | '2xl:inset-5'
  | 'sm:inset-x-5'
  | 'md:inset-x-5'
  | 'lg:inset-x-5'
  | 'xl:inset-x-5'
  | '2xl:inset-x-5'
  | 'sm:inset-y-5'
  | 'md:inset-y-5'
  | 'lg:inset-y-5'
  | 'xl:inset-y-5'
  | '2xl:inset-y-5'
  | 'sm:top-5'
  | 'md:top-5'
  | 'lg:top-5'
  | 'xl:top-5'
  | '2xl:top-5'
  | 'sm:right-5'
  | 'md:right-5'
  | 'lg:right-5'
  | 'xl:right-5'
  | '2xl:right-5'
  | 'sm:bottom-5'
  | 'md:bottom-5'
  | 'lg:bottom-5'
  | 'xl:bottom-5'
  | '2xl:bottom-5'
  | 'sm:left-5'
  | 'md:left-5'
  | 'lg:left-5'
  | 'xl:left-5'
  | '2xl:left-5'
  | 'sm:inset-6'
  | 'md:inset-6'
  | 'lg:inset-6'
  | 'xl:inset-6'
  | '2xl:inset-6'
  | 'sm:inset-x-6'
  | 'md:inset-x-6'
  | 'lg:inset-x-6'
  | 'xl:inset-x-6'
  | '2xl:inset-x-6'
  | 'sm:inset-y-6'
  | 'md:inset-y-6'
  | 'lg:inset-y-6'
  | 'xl:inset-y-6'
  | '2xl:inset-y-6'
  | 'sm:top-6'
  | 'md:top-6'
  | 'lg:top-6'
  | 'xl:top-6'
  | '2xl:top-6'
  | 'sm:right-6'
  | 'md:right-6'
  | 'lg:right-6'
  | 'xl:right-6'
  | '2xl:right-6'
  | 'sm:bottom-6'
  | 'md:bottom-6'
  | 'lg:bottom-6'
  | 'xl:bottom-6'
  | '2xl:bottom-6'
  | 'sm:left-6'
  | 'md:left-6'
  | 'lg:left-6'
  | 'xl:left-6'
  | '2xl:left-6'
  | 'sm:inset-7'
  | 'md:inset-7'
  | 'lg:inset-7'
  | 'xl:inset-7'
  | '2xl:inset-7'
  | 'sm:inset-x-7'
  | 'md:inset-x-7'
  | 'lg:inset-x-7'
  | 'xl:inset-x-7'
  | '2xl:inset-x-7'
  | 'sm:inset-y-7'
  | 'md:inset-y-7'
  | 'lg:inset-y-7'
  | 'xl:inset-y-7'
  | '2xl:inset-y-7'
  | 'sm:top-7'
  | 'md:top-7'
  | 'lg:top-7'
  | 'xl:top-7'
  | '2xl:top-7'
  | 'sm:right-7'
  | 'md:right-7'
  | 'lg:right-7'
  | 'xl:right-7'
  | '2xl:right-7'
  | 'sm:bottom-7'
  | 'md:bottom-7'
  | 'lg:bottom-7'
  | 'xl:bottom-7'
  | '2xl:bottom-7'
  | 'sm:left-7'
  | 'md:left-7'
  | 'lg:left-7'
  | 'xl:left-7'
  | '2xl:left-7'
  | 'sm:inset-8'
  | 'md:inset-8'
  | 'lg:inset-8'
  | 'xl:inset-8'
  | '2xl:inset-8'
  | 'sm:inset-x-8'
  | 'md:inset-x-8'
  | 'lg:inset-x-8'
  | 'xl:inset-x-8'
  | '2xl:inset-x-8'
  | 'sm:inset-y-8'
  | 'md:inset-y-8'
  | 'lg:inset-y-8'
  | 'xl:inset-y-8'
  | '2xl:inset-y-8'
  | 'sm:top-8'
  | 'md:top-8'
  | 'lg:top-8'
  | 'xl:top-8'
  | '2xl:top-8'
  | 'sm:right-8'
  | 'md:right-8'
  | 'lg:right-8'
  | 'xl:right-8'
  | '2xl:right-8'
  | 'sm:bottom-8'
  | 'md:bottom-8'
  | 'lg:bottom-8'
  | 'xl:bottom-8'
  | '2xl:bottom-8'
  | 'sm:left-8'
  | 'md:left-8'
  | 'lg:left-8'
  | 'xl:left-8'
  | '2xl:left-8'
  | 'sm:inset-9'
  | 'md:inset-9'
  | 'lg:inset-9'
  | 'xl:inset-9'
  | '2xl:inset-9'
  | 'sm:inset-x-9'
  | 'md:inset-x-9'
  | 'lg:inset-x-9'
  | 'xl:inset-x-9'
  | '2xl:inset-x-9'
  | 'sm:inset-y-9'
  | 'md:inset-y-9'
  | 'lg:inset-y-9'
  | 'xl:inset-y-9'
  | '2xl:inset-y-9'
  | 'sm:top-9'
  | 'md:top-9'
  | 'lg:top-9'
  | 'xl:top-9'
  | '2xl:top-9'
  | 'sm:right-9'
  | 'md:right-9'
  | 'lg:right-9'
  | 'xl:right-9'
  | '2xl:right-9'
  | 'sm:bottom-9'
  | 'md:bottom-9'
  | 'lg:bottom-9'
  | 'xl:bottom-9'
  | '2xl:bottom-9'
  | 'sm:left-9'
  | 'md:left-9'
  | 'lg:left-9'
  | 'xl:left-9'
  | '2xl:left-9'
  | 'sm:inset-10'
  | 'md:inset-10'
  | 'lg:inset-10'
  | 'xl:inset-10'
  | '2xl:inset-10'
  | 'sm:inset-x-10'
  | 'md:inset-x-10'
  | 'lg:inset-x-10'
  | 'xl:inset-x-10'
  | '2xl:inset-x-10'
  | 'sm:inset-y-10'
  | 'md:inset-y-10'
  | 'lg:inset-y-10'
  | 'xl:inset-y-10'
  | '2xl:inset-y-10'
  | 'sm:top-10'
  | 'md:top-10'
  | 'lg:top-10'
  | 'xl:top-10'
  | '2xl:top-10'
  | 'sm:right-10'
  | 'md:right-10'
  | 'lg:right-10'
  | 'xl:right-10'
  | '2xl:right-10'
  | 'sm:bottom-10'
  | 'md:bottom-10'
  | 'lg:bottom-10'
  | 'xl:bottom-10'
  | '2xl:bottom-10'
  | 'sm:left-10'
  | 'md:left-10'
  | 'lg:left-10'
  | 'xl:left-10'
  | '2xl:left-10'
  | 'sm:inset-11'
  | 'md:inset-11'
  | 'lg:inset-11'
  | 'xl:inset-11'
  | '2xl:inset-11'
  | 'sm:inset-x-11'
  | 'md:inset-x-11'
  | 'lg:inset-x-11'
  | 'xl:inset-x-11'
  | '2xl:inset-x-11'
  | 'sm:inset-y-11'
  | 'md:inset-y-11'
  | 'lg:inset-y-11'
  | 'xl:inset-y-11'
  | '2xl:inset-y-11'
  | 'sm:top-11'
  | 'md:top-11'
  | 'lg:top-11'
  | 'xl:top-11'
  | '2xl:top-11'
  | 'sm:right-11'
  | 'md:right-11'
  | 'lg:right-11'
  | 'xl:right-11'
  | '2xl:right-11'
  | 'sm:bottom-11'
  | 'md:bottom-11'
  | 'lg:bottom-11'
  | 'xl:bottom-11'
  | '2xl:bottom-11'
  | 'sm:left-11'
  | 'md:left-11'
  | 'lg:left-11'
  | 'xl:left-11'
  | '2xl:left-11'
  | 'sm:inset-12'
  | 'md:inset-12'
  | 'lg:inset-12'
  | 'xl:inset-12'
  | '2xl:inset-12'
  | 'sm:inset-x-12'
  | 'md:inset-x-12'
  | 'lg:inset-x-12'
  | 'xl:inset-x-12'
  | '2xl:inset-x-12'
  | 'sm:inset-y-12'
  | 'md:inset-y-12'
  | 'lg:inset-y-12'
  | 'xl:inset-y-12'
  | '2xl:inset-y-12'
  | 'sm:top-12'
  | 'md:top-12'
  | 'lg:top-12'
  | 'xl:top-12'
  | '2xl:top-12'
  | 'sm:right-12'
  | 'md:right-12'
  | 'lg:right-12'
  | 'xl:right-12'
  | '2xl:right-12'
  | 'sm:bottom-12'
  | 'md:bottom-12'
  | 'lg:bottom-12'
  | 'xl:bottom-12'
  | '2xl:bottom-12'
  | 'sm:left-12'
  | 'md:left-12'
  | 'lg:left-12'
  | 'xl:left-12'
  | '2xl:left-12'
  | 'sm:inset-14'
  | 'md:inset-14'
  | 'lg:inset-14'
  | 'xl:inset-14'
  | '2xl:inset-14'
  | 'sm:inset-x-14'
  | 'md:inset-x-14'
  | 'lg:inset-x-14'
  | 'xl:inset-x-14'
  | '2xl:inset-x-14'
  | 'sm:inset-y-14'
  | 'md:inset-y-14'
  | 'lg:inset-y-14'
  | 'xl:inset-y-14'
  | '2xl:inset-y-14'
  | 'sm:top-14'
  | 'md:top-14'
  | 'lg:top-14'
  | 'xl:top-14'
  | '2xl:top-14'
  | 'sm:right-14'
  | 'md:right-14'
  | 'lg:right-14'
  | 'xl:right-14'
  | '2xl:right-14'
  | 'sm:bottom-14'
  | 'md:bottom-14'
  | 'lg:bottom-14'
  | 'xl:bottom-14'
  | '2xl:bottom-14'
  | 'sm:left-14'
  | 'md:left-14'
  | 'lg:left-14'
  | 'xl:left-14'
  | '2xl:left-14'
  | 'sm:inset-16'
  | 'md:inset-16'
  | 'lg:inset-16'
  | 'xl:inset-16'
  | '2xl:inset-16'
  | 'sm:inset-x-16'
  | 'md:inset-x-16'
  | 'lg:inset-x-16'
  | 'xl:inset-x-16'
  | '2xl:inset-x-16'
  | 'sm:inset-y-16'
  | 'md:inset-y-16'
  | 'lg:inset-y-16'
  | 'xl:inset-y-16'
  | '2xl:inset-y-16'
  | 'sm:top-16'
  | 'md:top-16'
  | 'lg:top-16'
  | 'xl:top-16'
  | '2xl:top-16'
  | 'sm:right-16'
  | 'md:right-16'
  | 'lg:right-16'
  | 'xl:right-16'
  | '2xl:right-16'
  | 'sm:bottom-16'
  | 'md:bottom-16'
  | 'lg:bottom-16'
  | 'xl:bottom-16'
  | '2xl:bottom-16'
  | 'sm:left-16'
  | 'md:left-16'
  | 'lg:left-16'
  | 'xl:left-16'
  | '2xl:left-16'
  | 'sm:inset-20'
  | 'md:inset-20'
  | 'lg:inset-20'
  | 'xl:inset-20'
  | '2xl:inset-20'
  | 'sm:inset-x-20'
  | 'md:inset-x-20'
  | 'lg:inset-x-20'
  | 'xl:inset-x-20'
  | '2xl:inset-x-20'
  | 'sm:inset-y-20'
  | 'md:inset-y-20'
  | 'lg:inset-y-20'
  | 'xl:inset-y-20'
  | '2xl:inset-y-20'
  | 'sm:top-20'
  | 'md:top-20'
  | 'lg:top-20'
  | 'xl:top-20'
  | '2xl:top-20'
  | 'sm:right-20'
  | 'md:right-20'
  | 'lg:right-20'
  | 'xl:right-20'
  | '2xl:right-20'
  | 'sm:bottom-20'
  | 'md:bottom-20'
  | 'lg:bottom-20'
  | 'xl:bottom-20'
  | '2xl:bottom-20'
  | 'sm:left-20'
  | 'md:left-20'
  | 'lg:left-20'
  | 'xl:left-20'
  | '2xl:left-20'
  | 'sm:inset-24'
  | 'md:inset-24'
  | 'lg:inset-24'
  | 'xl:inset-24'
  | '2xl:inset-24'
  | 'sm:inset-x-24'
  | 'md:inset-x-24'
  | 'lg:inset-x-24'
  | 'xl:inset-x-24'
  | '2xl:inset-x-24'
  | 'sm:inset-y-24'
  | 'md:inset-y-24'
  | 'lg:inset-y-24'
  | 'xl:inset-y-24'
  | '2xl:inset-y-24'
  | 'sm:top-24'
  | 'md:top-24'
  | 'lg:top-24'
  | 'xl:top-24'
  | '2xl:top-24'
  | 'sm:right-24'
  | 'md:right-24'
  | 'lg:right-24'
  | 'xl:right-24'
  | '2xl:right-24'
  | 'sm:bottom-24'
  | 'md:bottom-24'
  | 'lg:bottom-24'
  | 'xl:bottom-24'
  | '2xl:bottom-24'
  | 'sm:left-24'
  | 'md:left-24'
  | 'lg:left-24'
  | 'xl:left-24'
  | '2xl:left-24'
  | 'sm:inset-28'
  | 'md:inset-28'
  | 'lg:inset-28'
  | 'xl:inset-28'
  | '2xl:inset-28'
  | 'sm:inset-x-28'
  | 'md:inset-x-28'
  | 'lg:inset-x-28'
  | 'xl:inset-x-28'
  | '2xl:inset-x-28'
  | 'sm:inset-y-28'
  | 'md:inset-y-28'
  | 'lg:inset-y-28'
  | 'xl:inset-y-28'
  | '2xl:inset-y-28'
  | 'sm:top-28'
  | 'md:top-28'
  | 'lg:top-28'
  | 'xl:top-28'
  | '2xl:top-28'
  | 'sm:right-28'
  | 'md:right-28'
  | 'lg:right-28'
  | 'xl:right-28'
  | '2xl:right-28'
  | 'sm:bottom-28'
  | 'md:bottom-28'
  | 'lg:bottom-28'
  | 'xl:bottom-28'
  | '2xl:bottom-28'
  | 'sm:left-28'
  | 'md:left-28'
  | 'lg:left-28'
  | 'xl:left-28'
  | '2xl:left-28'
  | 'sm:inset-32'
  | 'md:inset-32'
  | 'lg:inset-32'
  | 'xl:inset-32'
  | '2xl:inset-32'
  | 'sm:inset-x-32'
  | 'md:inset-x-32'
  | 'lg:inset-x-32'
  | 'xl:inset-x-32'
  | '2xl:inset-x-32'
  | 'sm:inset-y-32'
  | 'md:inset-y-32'
  | 'lg:inset-y-32'
  | 'xl:inset-y-32'
  | '2xl:inset-y-32'
  | 'sm:top-32'
  | 'md:top-32'
  | 'lg:top-32'
  | 'xl:top-32'
  | '2xl:top-32'
  | 'sm:right-32'
  | 'md:right-32'
  | 'lg:right-32'
  | 'xl:right-32'
  | '2xl:right-32'
  | 'sm:bottom-32'
  | 'md:bottom-32'
  | 'lg:bottom-32'
  | 'xl:bottom-32'
  | '2xl:bottom-32'
  | 'sm:left-32'
  | 'md:left-32'
  | 'lg:left-32'
  | 'xl:left-32'
  | '2xl:left-32'
  | 'sm:inset-36'
  | 'md:inset-36'
  | 'lg:inset-36'
  | 'xl:inset-36'
  | '2xl:inset-36'
  | 'sm:inset-x-36'
  | 'md:inset-x-36'
  | 'lg:inset-x-36'
  | 'xl:inset-x-36'
  | '2xl:inset-x-36'
  | 'sm:inset-y-36'
  | 'md:inset-y-36'
  | 'lg:inset-y-36'
  | 'xl:inset-y-36'
  | '2xl:inset-y-36'
  | 'sm:top-36'
  | 'md:top-36'
  | 'lg:top-36'
  | 'xl:top-36'
  | '2xl:top-36'
  | 'sm:right-36'
  | 'md:right-36'
  | 'lg:right-36'
  | 'xl:right-36'
  | '2xl:right-36'
  | 'sm:bottom-36'
  | 'md:bottom-36'
  | 'lg:bottom-36'
  | 'xl:bottom-36'
  | '2xl:bottom-36'
  | 'sm:left-36'
  | 'md:left-36'
  | 'lg:left-36'
  | 'xl:left-36'
  | '2xl:left-36'
  | 'sm:inset-40'
  | 'md:inset-40'
  | 'lg:inset-40'
  | 'xl:inset-40'
  | '2xl:inset-40'
  | 'sm:inset-x-40'
  | 'md:inset-x-40'
  | 'lg:inset-x-40'
  | 'xl:inset-x-40'
  | '2xl:inset-x-40'
  | 'sm:inset-y-40'
  | 'md:inset-y-40'
  | 'lg:inset-y-40'
  | 'xl:inset-y-40'
  | '2xl:inset-y-40'
  | 'sm:top-40'
  | 'md:top-40'
  | 'lg:top-40'
  | 'xl:top-40'
  | '2xl:top-40'
  | 'sm:right-40'
  | 'md:right-40'
  | 'lg:right-40'
  | 'xl:right-40'
  | '2xl:right-40'
  | 'sm:bottom-40'
  | 'md:bottom-40'
  | 'lg:bottom-40'
  | 'xl:bottom-40'
  | '2xl:bottom-40'
  | 'sm:left-40'
  | 'md:left-40'
  | 'lg:left-40'
  | 'xl:left-40'
  | '2xl:left-40'
  | 'sm:inset-44'
  | 'md:inset-44'
  | 'lg:inset-44'
  | 'xl:inset-44'
  | '2xl:inset-44'
  | 'sm:inset-x-44'
  | 'md:inset-x-44'
  | 'lg:inset-x-44'
  | 'xl:inset-x-44'
  | '2xl:inset-x-44'
  | 'sm:inset-y-44'
  | 'md:inset-y-44'
  | 'lg:inset-y-44'
  | 'xl:inset-y-44'
  | '2xl:inset-y-44'
  | 'sm:top-44'
  | 'md:top-44'
  | 'lg:top-44'
  | 'xl:top-44'
  | '2xl:top-44'
  | 'sm:right-44'
  | 'md:right-44'
  | 'lg:right-44'
  | 'xl:right-44'
  | '2xl:right-44'
  | 'sm:bottom-44'
  | 'md:bottom-44'
  | 'lg:bottom-44'
  | 'xl:bottom-44'
  | '2xl:bottom-44'
  | 'sm:left-44'
  | 'md:left-44'
  | 'lg:left-44'
  | 'xl:left-44'
  | '2xl:left-44'
  | 'sm:inset-48'
  | 'md:inset-48'
  | 'lg:inset-48'
  | 'xl:inset-48'
  | '2xl:inset-48'
  | 'sm:inset-x-48'
  | 'md:inset-x-48'
  | 'lg:inset-x-48'
  | 'xl:inset-x-48'
  | '2xl:inset-x-48'
  | 'sm:inset-y-48'
  | 'md:inset-y-48'
  | 'lg:inset-y-48'
  | 'xl:inset-y-48'
  | '2xl:inset-y-48'
  | 'sm:top-48'
  | 'md:top-48'
  | 'lg:top-48'
  | 'xl:top-48'
  | '2xl:top-48'
  | 'sm:right-48'
  | 'md:right-48'
  | 'lg:right-48'
  | 'xl:right-48'
  | '2xl:right-48'
  | 'sm:bottom-48'
  | 'md:bottom-48'
  | 'lg:bottom-48'
  | 'xl:bottom-48'
  | '2xl:bottom-48'
  | 'sm:left-48'
  | 'md:left-48'
  | 'lg:left-48'
  | 'xl:left-48'
  | '2xl:left-48'
  | 'sm:inset-52'
  | 'md:inset-52'
  | 'lg:inset-52'
  | 'xl:inset-52'
  | '2xl:inset-52'
  | 'sm:inset-x-52'
  | 'md:inset-x-52'
  | 'lg:inset-x-52'
  | 'xl:inset-x-52'
  | '2xl:inset-x-52'
  | 'sm:inset-y-52'
  | 'md:inset-y-52'
  | 'lg:inset-y-52'
  | 'xl:inset-y-52'
  | '2xl:inset-y-52'
  | 'sm:top-52'
  | 'md:top-52'
  | 'lg:top-52'
  | 'xl:top-52'
  | '2xl:top-52'
  | 'sm:right-52'
  | 'md:right-52'
  | 'lg:right-52'
  | 'xl:right-52'
  | '2xl:right-52'
  | 'sm:bottom-52'
  | 'md:bottom-52'
  | 'lg:bottom-52'
  | 'xl:bottom-52'
  | '2xl:bottom-52'
  | 'sm:left-52'
  | 'md:left-52'
  | 'lg:left-52'
  | 'xl:left-52'
  | '2xl:left-52'
  | 'sm:inset-56'
  | 'md:inset-56'
  | 'lg:inset-56'
  | 'xl:inset-56'
  | '2xl:inset-56'
  | 'sm:inset-x-56'
  | 'md:inset-x-56'
  | 'lg:inset-x-56'
  | 'xl:inset-x-56'
  | '2xl:inset-x-56'
  | 'sm:inset-y-56'
  | 'md:inset-y-56'
  | 'lg:inset-y-56'
  | 'xl:inset-y-56'
  | '2xl:inset-y-56'
  | 'sm:top-56'
  | 'md:top-56'
  | 'lg:top-56'
  | 'xl:top-56'
  | '2xl:top-56'
  | 'sm:right-56'
  | 'md:right-56'
  | 'lg:right-56'
  | 'xl:right-56'
  | '2xl:right-56'
  | 'sm:bottom-56'
  | 'md:bottom-56'
  | 'lg:bottom-56'
  | 'xl:bottom-56'
  | '2xl:bottom-56'
  | 'sm:left-56'
  | 'md:left-56'
  | 'lg:left-56'
  | 'xl:left-56'
  | '2xl:left-56'
  | 'sm:inset-60'
  | 'md:inset-60'
  | 'lg:inset-60'
  | 'xl:inset-60'
  | '2xl:inset-60'
  | 'sm:inset-x-60'
  | 'md:inset-x-60'
  | 'lg:inset-x-60'
  | 'xl:inset-x-60'
  | '2xl:inset-x-60'
  | 'sm:inset-y-60'
  | 'md:inset-y-60'
  | 'lg:inset-y-60'
  | 'xl:inset-y-60'
  | '2xl:inset-y-60'
  | 'sm:top-60'
  | 'md:top-60'
  | 'lg:top-60'
  | 'xl:top-60'
  | '2xl:top-60'
  | 'sm:right-60'
  | 'md:right-60'
  | 'lg:right-60'
  | 'xl:right-60'
  | '2xl:right-60'
  | 'sm:bottom-60'
  | 'md:bottom-60'
  | 'lg:bottom-60'
  | 'xl:bottom-60'
  | '2xl:bottom-60'
  | 'sm:left-60'
  | 'md:left-60'
  | 'lg:left-60'
  | 'xl:left-60'
  | '2xl:left-60'
  | 'sm:inset-64'
  | 'md:inset-64'
  | 'lg:inset-64'
  | 'xl:inset-64'
  | '2xl:inset-64'
  | 'sm:inset-x-64'
  | 'md:inset-x-64'
  | 'lg:inset-x-64'
  | 'xl:inset-x-64'
  | '2xl:inset-x-64'
  | 'sm:inset-y-64'
  | 'md:inset-y-64'
  | 'lg:inset-y-64'
  | 'xl:inset-y-64'
  | '2xl:inset-y-64'
  | 'sm:top-64'
  | 'md:top-64'
  | 'lg:top-64'
  | 'xl:top-64'
  | '2xl:top-64'
  | 'sm:right-64'
  | 'md:right-64'
  | 'lg:right-64'
  | 'xl:right-64'
  | '2xl:right-64'
  | 'sm:bottom-64'
  | 'md:bottom-64'
  | 'lg:bottom-64'
  | 'xl:bottom-64'
  | '2xl:bottom-64'
  | 'sm:left-64'
  | 'md:left-64'
  | 'lg:left-64'
  | 'xl:left-64'
  | '2xl:left-64'
  | 'sm:inset-72'
  | 'md:inset-72'
  | 'lg:inset-72'
  | 'xl:inset-72'
  | '2xl:inset-72'
  | 'sm:inset-x-72'
  | 'md:inset-x-72'
  | 'lg:inset-x-72'
  | 'xl:inset-x-72'
  | '2xl:inset-x-72'
  | 'sm:inset-y-72'
  | 'md:inset-y-72'
  | 'lg:inset-y-72'
  | 'xl:inset-y-72'
  | '2xl:inset-y-72'
  | 'sm:top-72'
  | 'md:top-72'
  | 'lg:top-72'
  | 'xl:top-72'
  | '2xl:top-72'
  | 'sm:right-72'
  | 'md:right-72'
  | 'lg:right-72'
  | 'xl:right-72'
  | '2xl:right-72'
  | 'sm:bottom-72'
  | 'md:bottom-72'
  | 'lg:bottom-72'
  | 'xl:bottom-72'
  | '2xl:bottom-72'
  | 'sm:left-72'
  | 'md:left-72'
  | 'lg:left-72'
  | 'xl:left-72'
  | '2xl:left-72'
  | 'sm:inset-80'
  | 'md:inset-80'
  | 'lg:inset-80'
  | 'xl:inset-80'
  | '2xl:inset-80'
  | 'sm:inset-x-80'
  | 'md:inset-x-80'
  | 'lg:inset-x-80'
  | 'xl:inset-x-80'
  | '2xl:inset-x-80'
  | 'sm:inset-y-80'
  | 'md:inset-y-80'
  | 'lg:inset-y-80'
  | 'xl:inset-y-80'
  | '2xl:inset-y-80'
  | 'sm:top-80'
  | 'md:top-80'
  | 'lg:top-80'
  | 'xl:top-80'
  | '2xl:top-80'
  | 'sm:right-80'
  | 'md:right-80'
  | 'lg:right-80'
  | 'xl:right-80'
  | '2xl:right-80'
  | 'sm:bottom-80'
  | 'md:bottom-80'
  | 'lg:bottom-80'
  | 'xl:bottom-80'
  | '2xl:bottom-80'
  | 'sm:left-80'
  | 'md:left-80'
  | 'lg:left-80'
  | 'xl:left-80'
  | '2xl:left-80'
  | 'sm:inset-96'
  | 'md:inset-96'
  | 'lg:inset-96'
  | 'xl:inset-96'
  | '2xl:inset-96'
  | 'sm:inset-x-96'
  | 'md:inset-x-96'
  | 'lg:inset-x-96'
  | 'xl:inset-x-96'
  | '2xl:inset-x-96'
  | 'sm:inset-y-96'
  | 'md:inset-y-96'
  | 'lg:inset-y-96'
  | 'xl:inset-y-96'
  | '2xl:inset-y-96'
  | 'sm:top-96'
  | 'md:top-96'
  | 'lg:top-96'
  | 'xl:top-96'
  | '2xl:top-96'
  | 'sm:right-96'
  | 'md:right-96'
  | 'lg:right-96'
  | 'xl:right-96'
  | '2xl:right-96'
  | 'sm:bottom-96'
  | 'md:bottom-96'
  | 'lg:bottom-96'
  | 'xl:bottom-96'
  | '2xl:bottom-96'
  | 'sm:left-96'
  | 'md:left-96'
  | 'lg:left-96'
  | 'xl:left-96'
  | '2xl:left-96'
  | 'sm:inset-auto'
  | 'md:inset-auto'
  | 'lg:inset-auto'
  | 'xl:inset-auto'
  | '2xl:inset-auto'
  | 'sm:inset-x-auto'
  | 'md:inset-x-auto'
  | 'lg:inset-x-auto'
  | 'xl:inset-x-auto'
  | '2xl:inset-x-auto'
  | 'sm:inset-y-auto'
  | 'md:inset-y-auto'
  | 'lg:inset-y-auto'
  | 'xl:inset-y-auto'
  | '2xl:inset-y-auto'
  | 'sm:top-auto'
  | 'md:top-auto'
  | 'lg:top-auto'
  | 'xl:top-auto'
  | '2xl:top-auto'
  | 'sm:right-auto'
  | 'md:right-auto'
  | 'lg:right-auto'
  | 'xl:right-auto'
  | '2xl:right-auto'
  | 'sm:bottom-auto'
  | 'md:bottom-auto'
  | 'lg:bottom-auto'
  | 'xl:bottom-auto'
  | '2xl:bottom-auto'
  | 'sm:left-auto'
  | 'md:left-auto'
  | 'lg:left-auto'
  | 'xl:left-auto'
  | '2xl:left-auto'
  | 'sm:inset-px'
  | 'md:inset-px'
  | 'lg:inset-px'
  | 'xl:inset-px'
  | '2xl:inset-px'
  | 'sm:inset-x-px'
  | 'md:inset-x-px'
  | 'lg:inset-x-px'
  | 'xl:inset-x-px'
  | '2xl:inset-x-px'
  | 'sm:inset-y-px'
  | 'md:inset-y-px'
  | 'lg:inset-y-px'
  | 'xl:inset-y-px'
  | '2xl:inset-y-px'
  | 'sm:top-px'
  | 'md:top-px'
  | 'lg:top-px'
  | 'xl:top-px'
  | '2xl:top-px'
  | 'sm:right-px'
  | 'md:right-px'
  | 'lg:right-px'
  | 'xl:right-px'
  | '2xl:right-px'
  | 'sm:bottom-px'
  | 'md:bottom-px'
  | 'lg:bottom-px'
  | 'xl:bottom-px'
  | '2xl:bottom-px'
  | 'sm:left-px'
  | 'md:left-px'
  | 'lg:left-px'
  | 'xl:left-px'
  | '2xl:left-px'
  | 'sm:inset-0.5'
  | 'md:inset-0.5'
  | 'lg:inset-0.5'
  | 'xl:inset-0.5'
  | '2xl:inset-0.5'
  | 'sm:inset-x-0.5'
  | 'md:inset-x-0.5'
  | 'lg:inset-x-0.5'
  | 'xl:inset-x-0.5'
  | '2xl:inset-x-0.5'
  | 'sm:inset-y-0.5'
  | 'md:inset-y-0.5'
  | 'lg:inset-y-0.5'
  | 'xl:inset-y-0.5'
  | '2xl:inset-y-0.5'
  | 'sm:top-0.5'
  | 'md:top-0.5'
  | 'lg:top-0.5'
  | 'xl:top-0.5'
  | '2xl:top-0.5'
  | 'sm:right-0.5'
  | 'md:right-0.5'
  | 'lg:right-0.5'
  | 'xl:right-0.5'
  | '2xl:right-0.5'
  | 'sm:bottom-0.5'
  | 'md:bottom-0.5'
  | 'lg:bottom-0.5'
  | 'xl:bottom-0.5'
  | '2xl:bottom-0.5'
  | 'sm:left-0.5'
  | 'md:left-0.5'
  | 'lg:left-0.5'
  | 'xl:left-0.5'
  | '2xl:left-0.5'
  | 'sm:inset-1.5'
  | 'md:inset-1.5'
  | 'lg:inset-1.5'
  | 'xl:inset-1.5'
  | '2xl:inset-1.5'
  | 'sm:inset-x-1.5'
  | 'md:inset-x-1.5'
  | 'lg:inset-x-1.5'
  | 'xl:inset-x-1.5'
  | '2xl:inset-x-1.5'
  | 'sm:inset-y-1.5'
  | 'md:inset-y-1.5'
  | 'lg:inset-y-1.5'
  | 'xl:inset-y-1.5'
  | '2xl:inset-y-1.5'
  | 'sm:top-1.5'
  | 'md:top-1.5'
  | 'lg:top-1.5'
  | 'xl:top-1.5'
  | '2xl:top-1.5'
  | 'sm:right-1.5'
  | 'md:right-1.5'
  | 'lg:right-1.5'
  | 'xl:right-1.5'
  | '2xl:right-1.5'
  | 'sm:bottom-1.5'
  | 'md:bottom-1.5'
  | 'lg:bottom-1.5'
  | 'xl:bottom-1.5'
  | '2xl:bottom-1.5'
  | 'sm:left-1.5'
  | 'md:left-1.5'
  | 'lg:left-1.5'
  | 'xl:left-1.5'
  | '2xl:left-1.5'
  | 'sm:inset-2.5'
  | 'md:inset-2.5'
  | 'lg:inset-2.5'
  | 'xl:inset-2.5'
  | '2xl:inset-2.5'
  | 'sm:inset-x-2.5'
  | 'md:inset-x-2.5'
  | 'lg:inset-x-2.5'
  | 'xl:inset-x-2.5'
  | '2xl:inset-x-2.5'
  | 'sm:inset-y-2.5'
  | 'md:inset-y-2.5'
  | 'lg:inset-y-2.5'
  | 'xl:inset-y-2.5'
  | '2xl:inset-y-2.5'
  | 'sm:top-2.5'
  | 'md:top-2.5'
  | 'lg:top-2.5'
  | 'xl:top-2.5'
  | '2xl:top-2.5'
  | 'sm:right-2.5'
  | 'md:right-2.5'
  | 'lg:right-2.5'
  | 'xl:right-2.5'
  | '2xl:right-2.5'
  | 'sm:bottom-2.5'
  | 'md:bottom-2.5'
  | 'lg:bottom-2.5'
  | 'xl:bottom-2.5'
  | '2xl:bottom-2.5'
  | 'sm:left-2.5'
  | 'md:left-2.5'
  | 'lg:left-2.5'
  | 'xl:left-2.5'
  | '2xl:left-2.5'
  | 'sm:inset-3.5'
  | 'md:inset-3.5'
  | 'lg:inset-3.5'
  | 'xl:inset-3.5'
  | '2xl:inset-3.5'
  | 'sm:inset-x-3.5'
  | 'md:inset-x-3.5'
  | 'lg:inset-x-3.5'
  | 'xl:inset-x-3.5'
  | '2xl:inset-x-3.5'
  | 'sm:inset-y-3.5'
  | 'md:inset-y-3.5'
  | 'lg:inset-y-3.5'
  | 'xl:inset-y-3.5'
  | '2xl:inset-y-3.5'
  | 'sm:top-3.5'
  | 'md:top-3.5'
  | 'lg:top-3.5'
  | 'xl:top-3.5'
  | '2xl:top-3.5'
  | 'sm:right-3.5'
  | 'md:right-3.5'
  | 'lg:right-3.5'
  | 'xl:right-3.5'
  | '2xl:right-3.5'
  | 'sm:bottom-3.5'
  | 'md:bottom-3.5'
  | 'lg:bottom-3.5'
  | 'xl:bottom-3.5'
  | '2xl:bottom-3.5'
  | 'sm:left-3.5'
  | 'md:left-3.5'
  | 'lg:left-3.5'
  | 'xl:left-3.5'
  | '2xl:left-3.5'
  | 'sm:-inset-0'
  | 'md:-inset-0'
  | 'lg:-inset-0'
  | 'xl:-inset-0'
  | '2xl:-inset-0'
  | 'sm:-inset-x-0'
  | 'md:-inset-x-0'
  | 'lg:-inset-x-0'
  | 'xl:-inset-x-0'
  | '2xl:-inset-x-0'
  | 'sm:-inset-y-0'
  | 'md:-inset-y-0'
  | 'lg:-inset-y-0'
  | 'xl:-inset-y-0'
  | '2xl:-inset-y-0'
  | 'sm:-top-0'
  | 'md:-top-0'
  | 'lg:-top-0'
  | 'xl:-top-0'
  | '2xl:-top-0'
  | 'sm:-right-0'
  | 'md:-right-0'
  | 'lg:-right-0'
  | 'xl:-right-0'
  | '2xl:-right-0'
  | 'sm:-bottom-0'
  | 'md:-bottom-0'
  | 'lg:-bottom-0'
  | 'xl:-bottom-0'
  | '2xl:-bottom-0'
  | 'sm:-left-0'
  | 'md:-left-0'
  | 'lg:-left-0'
  | 'xl:-left-0'
  | '2xl:-left-0'
  | 'sm:-inset-1'
  | 'md:-inset-1'
  | 'lg:-inset-1'
  | 'xl:-inset-1'
  | '2xl:-inset-1'
  | 'sm:-inset-x-1'
  | 'md:-inset-x-1'
  | 'lg:-inset-x-1'
  | 'xl:-inset-x-1'
  | '2xl:-inset-x-1'
  | 'sm:-inset-y-1'
  | 'md:-inset-y-1'
  | 'lg:-inset-y-1'
  | 'xl:-inset-y-1'
  | '2xl:-inset-y-1'
  | 'sm:-top-1'
  | 'md:-top-1'
  | 'lg:-top-1'
  | 'xl:-top-1'
  | '2xl:-top-1'
  | 'sm:-right-1'
  | 'md:-right-1'
  | 'lg:-right-1'
  | 'xl:-right-1'
  | '2xl:-right-1'
  | 'sm:-bottom-1'
  | 'md:-bottom-1'
  | 'lg:-bottom-1'
  | 'xl:-bottom-1'
  | '2xl:-bottom-1'
  | 'sm:-left-1'
  | 'md:-left-1'
  | 'lg:-left-1'
  | 'xl:-left-1'
  | '2xl:-left-1'
  | 'sm:-inset-2'
  | 'md:-inset-2'
  | 'lg:-inset-2'
  | 'xl:-inset-2'
  | '2xl:-inset-2'
  | 'sm:-inset-x-2'
  | 'md:-inset-x-2'
  | 'lg:-inset-x-2'
  | 'xl:-inset-x-2'
  | '2xl:-inset-x-2'
  | 'sm:-inset-y-2'
  | 'md:-inset-y-2'
  | 'lg:-inset-y-2'
  | 'xl:-inset-y-2'
  | '2xl:-inset-y-2'
  | 'sm:-top-2'
  | 'md:-top-2'
  | 'lg:-top-2'
  | 'xl:-top-2'
  | '2xl:-top-2'
  | 'sm:-right-2'
  | 'md:-right-2'
  | 'lg:-right-2'
  | 'xl:-right-2'
  | '2xl:-right-2'
  | 'sm:-bottom-2'
  | 'md:-bottom-2'
  | 'lg:-bottom-2'
  | 'xl:-bottom-2'
  | '2xl:-bottom-2'
  | 'sm:-left-2'
  | 'md:-left-2'
  | 'lg:-left-2'
  | 'xl:-left-2'
  | '2xl:-left-2'
  | 'sm:-inset-3'
  | 'md:-inset-3'
  | 'lg:-inset-3'
  | 'xl:-inset-3'
  | '2xl:-inset-3'
  | 'sm:-inset-x-3'
  | 'md:-inset-x-3'
  | 'lg:-inset-x-3'
  | 'xl:-inset-x-3'
  | '2xl:-inset-x-3'
  | 'sm:-inset-y-3'
  | 'md:-inset-y-3'
  | 'lg:-inset-y-3'
  | 'xl:-inset-y-3'
  | '2xl:-inset-y-3'
  | 'sm:-top-3'
  | 'md:-top-3'
  | 'lg:-top-3'
  | 'xl:-top-3'
  | '2xl:-top-3'
  | 'sm:-right-3'
  | 'md:-right-3'
  | 'lg:-right-3'
  | 'xl:-right-3'
  | '2xl:-right-3'
  | 'sm:-bottom-3'
  | 'md:-bottom-3'
  | 'lg:-bottom-3'
  | 'xl:-bottom-3'
  | '2xl:-bottom-3'
  | 'sm:-left-3'
  | 'md:-left-3'
  | 'lg:-left-3'
  | 'xl:-left-3'
  | '2xl:-left-3'
  | 'sm:-inset-4'
  | 'md:-inset-4'
  | 'lg:-inset-4'
  | 'xl:-inset-4'
  | '2xl:-inset-4'
  | 'sm:-inset-x-4'
  | 'md:-inset-x-4'
  | 'lg:-inset-x-4'
  | 'xl:-inset-x-4'
  | '2xl:-inset-x-4'
  | 'sm:-inset-y-4'
  | 'md:-inset-y-4'
  | 'lg:-inset-y-4'
  | 'xl:-inset-y-4'
  | '2xl:-inset-y-4'
  | 'sm:-top-4'
  | 'md:-top-4'
  | 'lg:-top-4'
  | 'xl:-top-4'
  | '2xl:-top-4'
  | 'sm:-right-4'
  | 'md:-right-4'
  | 'lg:-right-4'
  | 'xl:-right-4'
  | '2xl:-right-4'
  | 'sm:-bottom-4'
  | 'md:-bottom-4'
  | 'lg:-bottom-4'
  | 'xl:-bottom-4'
  | '2xl:-bottom-4'
  | 'sm:-left-4'
  | 'md:-left-4'
  | 'lg:-left-4'
  | 'xl:-left-4'
  | '2xl:-left-4'
  | 'sm:-inset-5'
  | 'md:-inset-5'
  | 'lg:-inset-5'
  | 'xl:-inset-5'
  | '2xl:-inset-5'
  | 'sm:-inset-x-5'
  | 'md:-inset-x-5'
  | 'lg:-inset-x-5'
  | 'xl:-inset-x-5'
  | '2xl:-inset-x-5'
  | 'sm:-inset-y-5'
  | 'md:-inset-y-5'
  | 'lg:-inset-y-5'
  | 'xl:-inset-y-5'
  | '2xl:-inset-y-5'
  | 'sm:-top-5'
  | 'md:-top-5'
  | 'lg:-top-5'
  | 'xl:-top-5'
  | '2xl:-top-5'
  | 'sm:-right-5'
  | 'md:-right-5'
  | 'lg:-right-5'
  | 'xl:-right-5'
  | '2xl:-right-5'
  | 'sm:-bottom-5'
  | 'md:-bottom-5'
  | 'lg:-bottom-5'
  | 'xl:-bottom-5'
  | '2xl:-bottom-5'
  | 'sm:-left-5'
  | 'md:-left-5'
  | 'lg:-left-5'
  | 'xl:-left-5'
  | '2xl:-left-5'
  | 'sm:-inset-6'
  | 'md:-inset-6'
  | 'lg:-inset-6'
  | 'xl:-inset-6'
  | '2xl:-inset-6'
  | 'sm:-inset-x-6'
  | 'md:-inset-x-6'
  | 'lg:-inset-x-6'
  | 'xl:-inset-x-6'
  | '2xl:-inset-x-6'
  | 'sm:-inset-y-6'
  | 'md:-inset-y-6'
  | 'lg:-inset-y-6'
  | 'xl:-inset-y-6'
  | '2xl:-inset-y-6'
  | 'sm:-top-6'
  | 'md:-top-6'
  | 'lg:-top-6'
  | 'xl:-top-6'
  | '2xl:-top-6'
  | 'sm:-right-6'
  | 'md:-right-6'
  | 'lg:-right-6'
  | 'xl:-right-6'
  | '2xl:-right-6'
  | 'sm:-bottom-6'
  | 'md:-bottom-6'
  | 'lg:-bottom-6'
  | 'xl:-bottom-6'
  | '2xl:-bottom-6'
  | 'sm:-left-6'
  | 'md:-left-6'
  | 'lg:-left-6'
  | 'xl:-left-6'
  | '2xl:-left-6'
  | 'sm:-inset-7'
  | 'md:-inset-7'
  | 'lg:-inset-7'
  | 'xl:-inset-7'
  | '2xl:-inset-7'
  | 'sm:-inset-x-7'
  | 'md:-inset-x-7'
  | 'lg:-inset-x-7'
  | 'xl:-inset-x-7'
  | '2xl:-inset-x-7'
  | 'sm:-inset-y-7'
  | 'md:-inset-y-7'
  | 'lg:-inset-y-7'
  | 'xl:-inset-y-7'
  | '2xl:-inset-y-7'
  | 'sm:-top-7'
  | 'md:-top-7'
  | 'lg:-top-7'
  | 'xl:-top-7'
  | '2xl:-top-7'
  | 'sm:-right-7'
  | 'md:-right-7'
  | 'lg:-right-7'
  | 'xl:-right-7'
  | '2xl:-right-7'
  | 'sm:-bottom-7'
  | 'md:-bottom-7'
  | 'lg:-bottom-7'
  | 'xl:-bottom-7'
  | '2xl:-bottom-7'
  | 'sm:-left-7'
  | 'md:-left-7'
  | 'lg:-left-7'
  | 'xl:-left-7'
  | '2xl:-left-7'
  | 'sm:-inset-8'
  | 'md:-inset-8'
  | 'lg:-inset-8'
  | 'xl:-inset-8'
  | '2xl:-inset-8'
  | 'sm:-inset-x-8'
  | 'md:-inset-x-8'
  | 'lg:-inset-x-8'
  | 'xl:-inset-x-8'
  | '2xl:-inset-x-8'
  | 'sm:-inset-y-8'
  | 'md:-inset-y-8'
  | 'lg:-inset-y-8'
  | 'xl:-inset-y-8'
  | '2xl:-inset-y-8'
  | 'sm:-top-8'
  | 'md:-top-8'
  | 'lg:-top-8'
  | 'xl:-top-8'
  | '2xl:-top-8'
  | 'sm:-right-8'
  | 'md:-right-8'
  | 'lg:-right-8'
  | 'xl:-right-8'
  | '2xl:-right-8'
  | 'sm:-bottom-8'
  | 'md:-bottom-8'
  | 'lg:-bottom-8'
  | 'xl:-bottom-8'
  | '2xl:-bottom-8'
  | 'sm:-left-8'
  | 'md:-left-8'
  | 'lg:-left-8'
  | 'xl:-left-8'
  | '2xl:-left-8'
  | 'sm:-inset-9'
  | 'md:-inset-9'
  | 'lg:-inset-9'
  | 'xl:-inset-9'
  | '2xl:-inset-9'
  | 'sm:-inset-x-9'
  | 'md:-inset-x-9'
  | 'lg:-inset-x-9'
  | 'xl:-inset-x-9'
  | '2xl:-inset-x-9'
  | 'sm:-inset-y-9'
  | 'md:-inset-y-9'
  | 'lg:-inset-y-9'
  | 'xl:-inset-y-9'
  | '2xl:-inset-y-9'
  | 'sm:-top-9'
  | 'md:-top-9'
  | 'lg:-top-9'
  | 'xl:-top-9'
  | '2xl:-top-9'
  | 'sm:-right-9'
  | 'md:-right-9'
  | 'lg:-right-9'
  | 'xl:-right-9'
  | '2xl:-right-9'
  | 'sm:-bottom-9'
  | 'md:-bottom-9'
  | 'lg:-bottom-9'
  | 'xl:-bottom-9'
  | '2xl:-bottom-9'
  | 'sm:-left-9'
  | 'md:-left-9'
  | 'lg:-left-9'
  | 'xl:-left-9'
  | '2xl:-left-9'
  | 'sm:-inset-10'
  | 'md:-inset-10'
  | 'lg:-inset-10'
  | 'xl:-inset-10'
  | '2xl:-inset-10'
  | 'sm:-inset-x-10'
  | 'md:-inset-x-10'
  | 'lg:-inset-x-10'
  | 'xl:-inset-x-10'
  | '2xl:-inset-x-10'
  | 'sm:-inset-y-10'
  | 'md:-inset-y-10'
  | 'lg:-inset-y-10'
  | 'xl:-inset-y-10'
  | '2xl:-inset-y-10'
  | 'sm:-top-10'
  | 'md:-top-10'
  | 'lg:-top-10'
  | 'xl:-top-10'
  | '2xl:-top-10'
  | 'sm:-right-10'
  | 'md:-right-10'
  | 'lg:-right-10'
  | 'xl:-right-10'
  | '2xl:-right-10'
  | 'sm:-bottom-10'
  | 'md:-bottom-10'
  | 'lg:-bottom-10'
  | 'xl:-bottom-10'
  | '2xl:-bottom-10'
  | 'sm:-left-10'
  | 'md:-left-10'
  | 'lg:-left-10'
  | 'xl:-left-10'
  | '2xl:-left-10'
  | 'sm:-inset-11'
  | 'md:-inset-11'
  | 'lg:-inset-11'
  | 'xl:-inset-11'
  | '2xl:-inset-11'
  | 'sm:-inset-x-11'
  | 'md:-inset-x-11'
  | 'lg:-inset-x-11'
  | 'xl:-inset-x-11'
  | '2xl:-inset-x-11'
  | 'sm:-inset-y-11'
  | 'md:-inset-y-11'
  | 'lg:-inset-y-11'
  | 'xl:-inset-y-11'
  | '2xl:-inset-y-11'
  | 'sm:-top-11'
  | 'md:-top-11'
  | 'lg:-top-11'
  | 'xl:-top-11'
  | '2xl:-top-11'
  | 'sm:-right-11'
  | 'md:-right-11'
  | 'lg:-right-11'
  | 'xl:-right-11'
  | '2xl:-right-11'
  | 'sm:-bottom-11'
  | 'md:-bottom-11'
  | 'lg:-bottom-11'
  | 'xl:-bottom-11'
  | '2xl:-bottom-11'
  | 'sm:-left-11'
  | 'md:-left-11'
  | 'lg:-left-11'
  | 'xl:-left-11'
  | '2xl:-left-11'
  | 'sm:-inset-12'
  | 'md:-inset-12'
  | 'lg:-inset-12'
  | 'xl:-inset-12'
  | '2xl:-inset-12'
  | 'sm:-inset-x-12'
  | 'md:-inset-x-12'
  | 'lg:-inset-x-12'
  | 'xl:-inset-x-12'
  | '2xl:-inset-x-12'
  | 'sm:-inset-y-12'
  | 'md:-inset-y-12'
  | 'lg:-inset-y-12'
  | 'xl:-inset-y-12'
  | '2xl:-inset-y-12'
  | 'sm:-top-12'
  | 'md:-top-12'
  | 'lg:-top-12'
  | 'xl:-top-12'
  | '2xl:-top-12'
  | 'sm:-right-12'
  | 'md:-right-12'
  | 'lg:-right-12'
  | 'xl:-right-12'
  | '2xl:-right-12'
  | 'sm:-bottom-12'
  | 'md:-bottom-12'
  | 'lg:-bottom-12'
  | 'xl:-bottom-12'
  | '2xl:-bottom-12'
  | 'sm:-left-12'
  | 'md:-left-12'
  | 'lg:-left-12'
  | 'xl:-left-12'
  | '2xl:-left-12'
  | 'sm:-inset-14'
  | 'md:-inset-14'
  | 'lg:-inset-14'
  | 'xl:-inset-14'
  | '2xl:-inset-14'
  | 'sm:-inset-x-14'
  | 'md:-inset-x-14'
  | 'lg:-inset-x-14'
  | 'xl:-inset-x-14'
  | '2xl:-inset-x-14'
  | 'sm:-inset-y-14'
  | 'md:-inset-y-14'
  | 'lg:-inset-y-14'
  | 'xl:-inset-y-14'
  | '2xl:-inset-y-14'
  | 'sm:-top-14'
  | 'md:-top-14'
  | 'lg:-top-14'
  | 'xl:-top-14'
  | '2xl:-top-14'
  | 'sm:-right-14'
  | 'md:-right-14'
  | 'lg:-right-14'
  | 'xl:-right-14'
  | '2xl:-right-14'
  | 'sm:-bottom-14'
  | 'md:-bottom-14'
  | 'lg:-bottom-14'
  | 'xl:-bottom-14'
  | '2xl:-bottom-14'
  | 'sm:-left-14'
  | 'md:-left-14'
  | 'lg:-left-14'
  | 'xl:-left-14'
  | '2xl:-left-14'
  | 'sm:-inset-16'
  | 'md:-inset-16'
  | 'lg:-inset-16'
  | 'xl:-inset-16'
  | '2xl:-inset-16'
  | 'sm:-inset-x-16'
  | 'md:-inset-x-16'
  | 'lg:-inset-x-16'
  | 'xl:-inset-x-16'
  | '2xl:-inset-x-16'
  | 'sm:-inset-y-16'
  | 'md:-inset-y-16'
  | 'lg:-inset-y-16'
  | 'xl:-inset-y-16'
  | '2xl:-inset-y-16'
  | 'sm:-top-16'
  | 'md:-top-16'
  | 'lg:-top-16'
  | 'xl:-top-16'
  | '2xl:-top-16'
  | 'sm:-right-16'
  | 'md:-right-16'
  | 'lg:-right-16'
  | 'xl:-right-16'
  | '2xl:-right-16'
  | 'sm:-bottom-16'
  | 'md:-bottom-16'
  | 'lg:-bottom-16'
  | 'xl:-bottom-16'
  | '2xl:-bottom-16'
  | 'sm:-left-16'
  | 'md:-left-16'
  | 'lg:-left-16'
  | 'xl:-left-16'
  | '2xl:-left-16'
  | 'sm:-inset-20'
  | 'md:-inset-20'
  | 'lg:-inset-20'
  | 'xl:-inset-20'
  | '2xl:-inset-20'
  | 'sm:-inset-x-20'
  | 'md:-inset-x-20'
  | 'lg:-inset-x-20'
  | 'xl:-inset-x-20'
  | '2xl:-inset-x-20'
  | 'sm:-inset-y-20'
  | 'md:-inset-y-20'
  | 'lg:-inset-y-20'
  | 'xl:-inset-y-20'
  | '2xl:-inset-y-20'
  | 'sm:-top-20'
  | 'md:-top-20'
  | 'lg:-top-20'
  | 'xl:-top-20'
  | '2xl:-top-20'
  | 'sm:-right-20'
  | 'md:-right-20'
  | 'lg:-right-20'
  | 'xl:-right-20'
  | '2xl:-right-20'
  | 'sm:-bottom-20'
  | 'md:-bottom-20'
  | 'lg:-bottom-20'
  | 'xl:-bottom-20'
  | '2xl:-bottom-20'
  | 'sm:-left-20'
  | 'md:-left-20'
  | 'lg:-left-20'
  | 'xl:-left-20'
  | '2xl:-left-20'
  | 'sm:-inset-24'
  | 'md:-inset-24'
  | 'lg:-inset-24'
  | 'xl:-inset-24'
  | '2xl:-inset-24'
  | 'sm:-inset-x-24'
  | 'md:-inset-x-24'
  | 'lg:-inset-x-24'
  | 'xl:-inset-x-24'
  | '2xl:-inset-x-24'
  | 'sm:-inset-y-24'
  | 'md:-inset-y-24'
  | 'lg:-inset-y-24'
  | 'xl:-inset-y-24'
  | '2xl:-inset-y-24'
  | 'sm:-top-24'
  | 'md:-top-24'
  | 'lg:-top-24'
  | 'xl:-top-24'
  | '2xl:-top-24'
  | 'sm:-right-24'
  | 'md:-right-24'
  | 'lg:-right-24'
  | 'xl:-right-24'
  | '2xl:-right-24'
  | 'sm:-bottom-24'
  | 'md:-bottom-24'
  | 'lg:-bottom-24'
  | 'xl:-bottom-24'
  | '2xl:-bottom-24'
  | 'sm:-left-24'
  | 'md:-left-24'
  | 'lg:-left-24'
  | 'xl:-left-24'
  | '2xl:-left-24'
  | 'sm:-inset-28'
  | 'md:-inset-28'
  | 'lg:-inset-28'
  | 'xl:-inset-28'
  | '2xl:-inset-28'
  | 'sm:-inset-x-28'
  | 'md:-inset-x-28'
  | 'lg:-inset-x-28'
  | 'xl:-inset-x-28'
  | '2xl:-inset-x-28'
  | 'sm:-inset-y-28'
  | 'md:-inset-y-28'
  | 'lg:-inset-y-28'
  | 'xl:-inset-y-28'
  | '2xl:-inset-y-28'
  | 'sm:-top-28'
  | 'md:-top-28'
  | 'lg:-top-28'
  | 'xl:-top-28'
  | '2xl:-top-28'
  | 'sm:-right-28'
  | 'md:-right-28'
  | 'lg:-right-28'
  | 'xl:-right-28'
  | '2xl:-right-28'
  | 'sm:-bottom-28'
  | 'md:-bottom-28'
  | 'lg:-bottom-28'
  | 'xl:-bottom-28'
  | '2xl:-bottom-28'
  | 'sm:-left-28'
  | 'md:-left-28'
  | 'lg:-left-28'
  | 'xl:-left-28'
  | '2xl:-left-28'
  | 'sm:-inset-32'
  | 'md:-inset-32'
  | 'lg:-inset-32'
  | 'xl:-inset-32'
  | '2xl:-inset-32'
  | 'sm:-inset-x-32'
  | 'md:-inset-x-32'
  | 'lg:-inset-x-32'
  | 'xl:-inset-x-32'
  | '2xl:-inset-x-32'
  | 'sm:-inset-y-32'
  | 'md:-inset-y-32'
  | 'lg:-inset-y-32'
  | 'xl:-inset-y-32'
  | '2xl:-inset-y-32'
  | 'sm:-top-32'
  | 'md:-top-32'
  | 'lg:-top-32'
  | 'xl:-top-32'
  | '2xl:-top-32'
  | 'sm:-right-32'
  | 'md:-right-32'
  | 'lg:-right-32'
  | 'xl:-right-32'
  | '2xl:-right-32'
  | 'sm:-bottom-32'
  | 'md:-bottom-32'
  | 'lg:-bottom-32'
  | 'xl:-bottom-32'
  | '2xl:-bottom-32'
  | 'sm:-left-32'
  | 'md:-left-32'
  | 'lg:-left-32'
  | 'xl:-left-32'
  | '2xl:-left-32'
  | 'sm:-inset-36'
  | 'md:-inset-36'
  | 'lg:-inset-36'
  | 'xl:-inset-36'
  | '2xl:-inset-36'
  | 'sm:-inset-x-36'
  | 'md:-inset-x-36'
  | 'lg:-inset-x-36'
  | 'xl:-inset-x-36'
  | '2xl:-inset-x-36'
  | 'sm:-inset-y-36'
  | 'md:-inset-y-36'
  | 'lg:-inset-y-36'
  | 'xl:-inset-y-36'
  | '2xl:-inset-y-36'
  | 'sm:-top-36'
  | 'md:-top-36'
  | 'lg:-top-36'
  | 'xl:-top-36'
  | '2xl:-top-36'
  | 'sm:-right-36'
  | 'md:-right-36'
  | 'lg:-right-36'
  | 'xl:-right-36'
  | '2xl:-right-36'
  | 'sm:-bottom-36'
  | 'md:-bottom-36'
  | 'lg:-bottom-36'
  | 'xl:-bottom-36'
  | '2xl:-bottom-36'
  | 'sm:-left-36'
  | 'md:-left-36'
  | 'lg:-left-36'
  | 'xl:-left-36'
  | '2xl:-left-36'
  | 'sm:-inset-40'
  | 'md:-inset-40'
  | 'lg:-inset-40'
  | 'xl:-inset-40'
  | '2xl:-inset-40'
  | 'sm:-inset-x-40'
  | 'md:-inset-x-40'
  | 'lg:-inset-x-40'
  | 'xl:-inset-x-40'
  | '2xl:-inset-x-40'
  | 'sm:-inset-y-40'
  | 'md:-inset-y-40'
  | 'lg:-inset-y-40'
  | 'xl:-inset-y-40'
  | '2xl:-inset-y-40'
  | 'sm:-top-40'
  | 'md:-top-40'
  | 'lg:-top-40'
  | 'xl:-top-40'
  | '2xl:-top-40'
  | 'sm:-right-40'
  | 'md:-right-40'
  | 'lg:-right-40'
  | 'xl:-right-40'
  | '2xl:-right-40'
  | 'sm:-bottom-40'
  | 'md:-bottom-40'
  | 'lg:-bottom-40'
  | 'xl:-bottom-40'
  | '2xl:-bottom-40'
  | 'sm:-left-40'
  | 'md:-left-40'
  | 'lg:-left-40'
  | 'xl:-left-40'
  | '2xl:-left-40'
  | 'sm:-inset-44'
  | 'md:-inset-44'
  | 'lg:-inset-44'
  | 'xl:-inset-44'
  | '2xl:-inset-44'
  | 'sm:-inset-x-44'
  | 'md:-inset-x-44'
  | 'lg:-inset-x-44'
  | 'xl:-inset-x-44'
  | '2xl:-inset-x-44'
  | 'sm:-inset-y-44'
  | 'md:-inset-y-44'
  | 'lg:-inset-y-44'
  | 'xl:-inset-y-44'
  | '2xl:-inset-y-44'
  | 'sm:-top-44'
  | 'md:-top-44'
  | 'lg:-top-44'
  | 'xl:-top-44'
  | '2xl:-top-44'
  | 'sm:-right-44'
  | 'md:-right-44'
  | 'lg:-right-44'
  | 'xl:-right-44'
  | '2xl:-right-44'
  | 'sm:-bottom-44'
  | 'md:-bottom-44'
  | 'lg:-bottom-44'
  | 'xl:-bottom-44'
  | '2xl:-bottom-44'
  | 'sm:-left-44'
  | 'md:-left-44'
  | 'lg:-left-44'
  | 'xl:-left-44'
  | '2xl:-left-44'
  | 'sm:-inset-48'
  | 'md:-inset-48'
  | 'lg:-inset-48'
  | 'xl:-inset-48'
  | '2xl:-inset-48'
  | 'sm:-inset-x-48'
  | 'md:-inset-x-48'
  | 'lg:-inset-x-48'
  | 'xl:-inset-x-48'
  | '2xl:-inset-x-48'
  | 'sm:-inset-y-48'
  | 'md:-inset-y-48'
  | 'lg:-inset-y-48'
  | 'xl:-inset-y-48'
  | '2xl:-inset-y-48'
  | 'sm:-top-48'
  | 'md:-top-48'
  | 'lg:-top-48'
  | 'xl:-top-48'
  | '2xl:-top-48'
  | 'sm:-right-48'
  | 'md:-right-48'
  | 'lg:-right-48'
  | 'xl:-right-48'
  | '2xl:-right-48'
  | 'sm:-bottom-48'
  | 'md:-bottom-48'
  | 'lg:-bottom-48'
  | 'xl:-bottom-48'
  | '2xl:-bottom-48'
  | 'sm:-left-48'
  | 'md:-left-48'
  | 'lg:-left-48'
  | 'xl:-left-48'
  | '2xl:-left-48'
  | 'sm:-inset-52'
  | 'md:-inset-52'
  | 'lg:-inset-52'
  | 'xl:-inset-52'
  | '2xl:-inset-52'
  | 'sm:-inset-x-52'
  | 'md:-inset-x-52'
  | 'lg:-inset-x-52'
  | 'xl:-inset-x-52'
  | '2xl:-inset-x-52'
  | 'sm:-inset-y-52'
  | 'md:-inset-y-52'
  | 'lg:-inset-y-52'
  | 'xl:-inset-y-52'
  | '2xl:-inset-y-52'
  | 'sm:-top-52'
  | 'md:-top-52'
  | 'lg:-top-52'
  | 'xl:-top-52'
  | '2xl:-top-52'
  | 'sm:-right-52'
  | 'md:-right-52'
  | 'lg:-right-52'
  | 'xl:-right-52'
  | '2xl:-right-52'
  | 'sm:-bottom-52'
  | 'md:-bottom-52'
  | 'lg:-bottom-52'
  | 'xl:-bottom-52'
  | '2xl:-bottom-52'
  | 'sm:-left-52'
  | 'md:-left-52'
  | 'lg:-left-52'
  | 'xl:-left-52'
  | '2xl:-left-52'
  | 'sm:-inset-56'
  | 'md:-inset-56'
  | 'lg:-inset-56'
  | 'xl:-inset-56'
  | '2xl:-inset-56'
  | 'sm:-inset-x-56'
  | 'md:-inset-x-56'
  | 'lg:-inset-x-56'
  | 'xl:-inset-x-56'
  | '2xl:-inset-x-56'
  | 'sm:-inset-y-56'
  | 'md:-inset-y-56'
  | 'lg:-inset-y-56'
  | 'xl:-inset-y-56'
  | '2xl:-inset-y-56'
  | 'sm:-top-56'
  | 'md:-top-56'
  | 'lg:-top-56'
  | 'xl:-top-56'
  | '2xl:-top-56'
  | 'sm:-right-56'
  | 'md:-right-56'
  | 'lg:-right-56'
  | 'xl:-right-56'
  | '2xl:-right-56'
  | 'sm:-bottom-56'
  | 'md:-bottom-56'
  | 'lg:-bottom-56'
  | 'xl:-bottom-56'
  | '2xl:-bottom-56'
  | 'sm:-left-56'
  | 'md:-left-56'
  | 'lg:-left-56'
  | 'xl:-left-56'
  | '2xl:-left-56'
  | 'sm:-inset-60'
  | 'md:-inset-60'
  | 'lg:-inset-60'
  | 'xl:-inset-60'
  | '2xl:-inset-60'
  | 'sm:-inset-x-60'
  | 'md:-inset-x-60'
  | 'lg:-inset-x-60'
  | 'xl:-inset-x-60'
  | '2xl:-inset-x-60'
  | 'sm:-inset-y-60'
  | 'md:-inset-y-60'
  | 'lg:-inset-y-60'
  | 'xl:-inset-y-60'
  | '2xl:-inset-y-60'
  | 'sm:-top-60'
  | 'md:-top-60'
  | 'lg:-top-60'
  | 'xl:-top-60'
  | '2xl:-top-60'
  | 'sm:-right-60'
  | 'md:-right-60'
  | 'lg:-right-60'
  | 'xl:-right-60'
  | '2xl:-right-60'
  | 'sm:-bottom-60'
  | 'md:-bottom-60'
  | 'lg:-bottom-60'
  | 'xl:-bottom-60'
  | '2xl:-bottom-60'
  | 'sm:-left-60'
  | 'md:-left-60'
  | 'lg:-left-60'
  | 'xl:-left-60'
  | '2xl:-left-60'
  | 'sm:-inset-64'
  | 'md:-inset-64'
  | 'lg:-inset-64'
  | 'xl:-inset-64'
  | '2xl:-inset-64'
  | 'sm:-inset-x-64'
  | 'md:-inset-x-64'
  | 'lg:-inset-x-64'
  | 'xl:-inset-x-64'
  | '2xl:-inset-x-64'
  | 'sm:-inset-y-64'
  | 'md:-inset-y-64'
  | 'lg:-inset-y-64'
  | 'xl:-inset-y-64'
  | '2xl:-inset-y-64'
  | 'sm:-top-64'
  | 'md:-top-64'
  | 'lg:-top-64'
  | 'xl:-top-64'
  | '2xl:-top-64'
  | 'sm:-right-64'
  | 'md:-right-64'
  | 'lg:-right-64'
  | 'xl:-right-64'
  | '2xl:-right-64'
  | 'sm:-bottom-64'
  | 'md:-bottom-64'
  | 'lg:-bottom-64'
  | 'xl:-bottom-64'
  | '2xl:-bottom-64'
  | 'sm:-left-64'
  | 'md:-left-64'
  | 'lg:-left-64'
  | 'xl:-left-64'
  | '2xl:-left-64'
  | 'sm:-inset-72'
  | 'md:-inset-72'
  | 'lg:-inset-72'
  | 'xl:-inset-72'
  | '2xl:-inset-72'
  | 'sm:-inset-x-72'
  | 'md:-inset-x-72'
  | 'lg:-inset-x-72'
  | 'xl:-inset-x-72'
  | '2xl:-inset-x-72'
  | 'sm:-inset-y-72'
  | 'md:-inset-y-72'
  | 'lg:-inset-y-72'
  | 'xl:-inset-y-72'
  | '2xl:-inset-y-72'
  | 'sm:-top-72'
  | 'md:-top-72'
  | 'lg:-top-72'
  | 'xl:-top-72'
  | '2xl:-top-72'
  | 'sm:-right-72'
  | 'md:-right-72'
  | 'lg:-right-72'
  | 'xl:-right-72'
  | '2xl:-right-72'
  | 'sm:-bottom-72'
  | 'md:-bottom-72'
  | 'lg:-bottom-72'
  | 'xl:-bottom-72'
  | '2xl:-bottom-72'
  | 'sm:-left-72'
  | 'md:-left-72'
  | 'lg:-left-72'
  | 'xl:-left-72'
  | '2xl:-left-72'
  | 'sm:-inset-80'
  | 'md:-inset-80'
  | 'lg:-inset-80'
  | 'xl:-inset-80'
  | '2xl:-inset-80'
  | 'sm:-inset-x-80'
  | 'md:-inset-x-80'
  | 'lg:-inset-x-80'
  | 'xl:-inset-x-80'
  | '2xl:-inset-x-80'
  | 'sm:-inset-y-80'
  | 'md:-inset-y-80'
  | 'lg:-inset-y-80'
  | 'xl:-inset-y-80'
  | '2xl:-inset-y-80'
  | 'sm:-top-80'
  | 'md:-top-80'
  | 'lg:-top-80'
  | 'xl:-top-80'
  | '2xl:-top-80'
  | 'sm:-right-80'
  | 'md:-right-80'
  | 'lg:-right-80'
  | 'xl:-right-80'
  | '2xl:-right-80'
  | 'sm:-bottom-80'
  | 'md:-bottom-80'
  | 'lg:-bottom-80'
  | 'xl:-bottom-80'
  | '2xl:-bottom-80'
  | 'sm:-left-80'
  | 'md:-left-80'
  | 'lg:-left-80'
  | 'xl:-left-80'
  | '2xl:-left-80'
  | 'sm:-inset-96'
  | 'md:-inset-96'
  | 'lg:-inset-96'
  | 'xl:-inset-96'
  | '2xl:-inset-96'
  | 'sm:-inset-x-96'
  | 'md:-inset-x-96'
  | 'lg:-inset-x-96'
  | 'xl:-inset-x-96'
  | '2xl:-inset-x-96'
  | 'sm:-inset-y-96'
  | 'md:-inset-y-96'
  | 'lg:-inset-y-96'
  | 'xl:-inset-y-96'
  | '2xl:-inset-y-96'
  | 'sm:-top-96'
  | 'md:-top-96'
  | 'lg:-top-96'
  | 'xl:-top-96'
  | '2xl:-top-96'
  | 'sm:-right-96'
  | 'md:-right-96'
  | 'lg:-right-96'
  | 'xl:-right-96'
  | '2xl:-right-96'
  | 'sm:-bottom-96'
  | 'md:-bottom-96'
  | 'lg:-bottom-96'
  | 'xl:-bottom-96'
  | '2xl:-bottom-96'
  | 'sm:-left-96'
  | 'md:-left-96'
  | 'lg:-left-96'
  | 'xl:-left-96'
  | '2xl:-left-96'
  | 'sm:-inset-px'
  | 'md:-inset-px'
  | 'lg:-inset-px'
  | 'xl:-inset-px'
  | '2xl:-inset-px'
  | 'sm:-inset-x-px'
  | 'md:-inset-x-px'
  | 'lg:-inset-x-px'
  | 'xl:-inset-x-px'
  | '2xl:-inset-x-px'
  | 'sm:-inset-y-px'
  | 'md:-inset-y-px'
  | 'lg:-inset-y-px'
  | 'xl:-inset-y-px'
  | '2xl:-inset-y-px'
  | 'sm:-top-px'
  | 'md:-top-px'
  | 'lg:-top-px'
  | 'xl:-top-px'
  | '2xl:-top-px'
  | 'sm:-right-px'
  | 'md:-right-px'
  | 'lg:-right-px'
  | 'xl:-right-px'
  | '2xl:-right-px'
  | 'sm:-bottom-px'
  | 'md:-bottom-px'
  | 'lg:-bottom-px'
  | 'xl:-bottom-px'
  | '2xl:-bottom-px'
  | 'sm:-left-px'
  | 'md:-left-px'
  | 'lg:-left-px'
  | 'xl:-left-px'
  | '2xl:-left-px'
  | 'sm:-inset-0.5'
  | 'md:-inset-0.5'
  | 'lg:-inset-0.5'
  | 'xl:-inset-0.5'
  | '2xl:-inset-0.5'
  | 'sm:-inset-x-0.5'
  | 'md:-inset-x-0.5'
  | 'lg:-inset-x-0.5'
  | 'xl:-inset-x-0.5'
  | '2xl:-inset-x-0.5'
  | 'sm:-inset-y-0.5'
  | 'md:-inset-y-0.5'
  | 'lg:-inset-y-0.5'
  | 'xl:-inset-y-0.5'
  | '2xl:-inset-y-0.5'
  | 'sm:-top-0.5'
  | 'md:-top-0.5'
  | 'lg:-top-0.5'
  | 'xl:-top-0.5'
  | '2xl:-top-0.5'
  | 'sm:-right-0.5'
  | 'md:-right-0.5'
  | 'lg:-right-0.5'
  | 'xl:-right-0.5'
  | '2xl:-right-0.5'
  | 'sm:-bottom-0.5'
  | 'md:-bottom-0.5'
  | 'lg:-bottom-0.5'
  | 'xl:-bottom-0.5'
  | '2xl:-bottom-0.5'
  | 'sm:-left-0.5'
  | 'md:-left-0.5'
  | 'lg:-left-0.5'
  | 'xl:-left-0.5'
  | '2xl:-left-0.5'
  | 'sm:-inset-1.5'
  | 'md:-inset-1.5'
  | 'lg:-inset-1.5'
  | 'xl:-inset-1.5'
  | '2xl:-inset-1.5'
  | 'sm:-inset-x-1.5'
  | 'md:-inset-x-1.5'
  | 'lg:-inset-x-1.5'
  | 'xl:-inset-x-1.5'
  | '2xl:-inset-x-1.5'
  | 'sm:-inset-y-1.5'
  | 'md:-inset-y-1.5'
  | 'lg:-inset-y-1.5'
  | 'xl:-inset-y-1.5'
  | '2xl:-inset-y-1.5'
  | 'sm:-top-1.5'
  | 'md:-top-1.5'
  | 'lg:-top-1.5'
  | 'xl:-top-1.5'
  | '2xl:-top-1.5'
  | 'sm:-right-1.5'
  | 'md:-right-1.5'
  | 'lg:-right-1.5'
  | 'xl:-right-1.5'
  | '2xl:-right-1.5'
  | 'sm:-bottom-1.5'
  | 'md:-bottom-1.5'
  | 'lg:-bottom-1.5'
  | 'xl:-bottom-1.5'
  | '2xl:-bottom-1.5'
  | 'sm:-left-1.5'
  | 'md:-left-1.5'
  | 'lg:-left-1.5'
  | 'xl:-left-1.5'
  | '2xl:-left-1.5'
  | 'sm:-inset-2.5'
  | 'md:-inset-2.5'
  | 'lg:-inset-2.5'
  | 'xl:-inset-2.5'
  | '2xl:-inset-2.5'
  | 'sm:-inset-x-2.5'
  | 'md:-inset-x-2.5'
  | 'lg:-inset-x-2.5'
  | 'xl:-inset-x-2.5'
  | '2xl:-inset-x-2.5'
  | 'sm:-inset-y-2.5'
  | 'md:-inset-y-2.5'
  | 'lg:-inset-y-2.5'
  | 'xl:-inset-y-2.5'
  | '2xl:-inset-y-2.5'
  | 'sm:-top-2.5'
  | 'md:-top-2.5'
  | 'lg:-top-2.5'
  | 'xl:-top-2.5'
  | '2xl:-top-2.5'
  | 'sm:-right-2.5'
  | 'md:-right-2.5'
  | 'lg:-right-2.5'
  | 'xl:-right-2.5'
  | '2xl:-right-2.5'
  | 'sm:-bottom-2.5'
  | 'md:-bottom-2.5'
  | 'lg:-bottom-2.5'
  | 'xl:-bottom-2.5'
  | '2xl:-bottom-2.5'
  | 'sm:-left-2.5'
  | 'md:-left-2.5'
  | 'lg:-left-2.5'
  | 'xl:-left-2.5'
  | '2xl:-left-2.5'
  | 'sm:-inset-3.5'
  | 'md:-inset-3.5'
  | 'lg:-inset-3.5'
  | 'xl:-inset-3.5'
  | '2xl:-inset-3.5'
  | 'sm:-inset-x-3.5'
  | 'md:-inset-x-3.5'
  | 'lg:-inset-x-3.5'
  | 'xl:-inset-x-3.5'
  | '2xl:-inset-x-3.5'
  | 'sm:-inset-y-3.5'
  | 'md:-inset-y-3.5'
  | 'lg:-inset-y-3.5'
  | 'xl:-inset-y-3.5'
  | '2xl:-inset-y-3.5'
  | 'sm:-top-3.5'
  | 'md:-top-3.5'
  | 'lg:-top-3.5'
  | 'xl:-top-3.5'
  | '2xl:-top-3.5'
  | 'sm:-right-3.5'
  | 'md:-right-3.5'
  | 'lg:-right-3.5'
  | 'xl:-right-3.5'
  | '2xl:-right-3.5'
  | 'sm:-bottom-3.5'
  | 'md:-bottom-3.5'
  | 'lg:-bottom-3.5'
  | 'xl:-bottom-3.5'
  | '2xl:-bottom-3.5'
  | 'sm:-left-3.5'
  | 'md:-left-3.5'
  | 'lg:-left-3.5'
  | 'xl:-left-3.5'
  | '2xl:-left-3.5'
  | 'sm:inset-1/2'
  | 'md:inset-1/2'
  | 'lg:inset-1/2'
  | 'xl:inset-1/2'
  | '2xl:inset-1/2'
  | 'sm:inset-x-1/2'
  | 'md:inset-x-1/2'
  | 'lg:inset-x-1/2'
  | 'xl:inset-x-1/2'
  | '2xl:inset-x-1/2'
  | 'sm:inset-y-1/2'
  | 'md:inset-y-1/2'
  | 'lg:inset-y-1/2'
  | 'xl:inset-y-1/2'
  | '2xl:inset-y-1/2'
  | 'sm:top-1/2'
  | 'md:top-1/2'
  | 'lg:top-1/2'
  | 'xl:top-1/2'
  | '2xl:top-1/2'
  | 'sm:right-1/2'
  | 'md:right-1/2'
  | 'lg:right-1/2'
  | 'xl:right-1/2'
  | '2xl:right-1/2'
  | 'sm:bottom-1/2'
  | 'md:bottom-1/2'
  | 'lg:bottom-1/2'
  | 'xl:bottom-1/2'
  | '2xl:bottom-1/2'
  | 'sm:left-1/2'
  | 'md:left-1/2'
  | 'lg:left-1/2'
  | 'xl:left-1/2'
  | '2xl:left-1/2'
  | 'sm:inset-1/3'
  | 'md:inset-1/3'
  | 'lg:inset-1/3'
  | 'xl:inset-1/3'
  | '2xl:inset-1/3'
  | 'sm:inset-x-1/3'
  | 'md:inset-x-1/3'
  | 'lg:inset-x-1/3'
  | 'xl:inset-x-1/3'
  | '2xl:inset-x-1/3'
  | 'sm:inset-y-1/3'
  | 'md:inset-y-1/3'
  | 'lg:inset-y-1/3'
  | 'xl:inset-y-1/3'
  | '2xl:inset-y-1/3'
  | 'sm:top-1/3'
  | 'md:top-1/3'
  | 'lg:top-1/3'
  | 'xl:top-1/3'
  | '2xl:top-1/3'
  | 'sm:right-1/3'
  | 'md:right-1/3'
  | 'lg:right-1/3'
  | 'xl:right-1/3'
  | '2xl:right-1/3'
  | 'sm:bottom-1/3'
  | 'md:bottom-1/3'
  | 'lg:bottom-1/3'
  | 'xl:bottom-1/3'
  | '2xl:bottom-1/3'
  | 'sm:left-1/3'
  | 'md:left-1/3'
  | 'lg:left-1/3'
  | 'xl:left-1/3'
  | '2xl:left-1/3'
  | 'sm:inset-2/3'
  | 'md:inset-2/3'
  | 'lg:inset-2/3'
  | 'xl:inset-2/3'
  | '2xl:inset-2/3'
  | 'sm:inset-x-2/3'
  | 'md:inset-x-2/3'
  | 'lg:inset-x-2/3'
  | 'xl:inset-x-2/3'
  | '2xl:inset-x-2/3'
  | 'sm:inset-y-2/3'
  | 'md:inset-y-2/3'
  | 'lg:inset-y-2/3'
  | 'xl:inset-y-2/3'
  | '2xl:inset-y-2/3'
  | 'sm:top-2/3'
  | 'md:top-2/3'
  | 'lg:top-2/3'
  | 'xl:top-2/3'
  | '2xl:top-2/3'
  | 'sm:right-2/3'
  | 'md:right-2/3'
  | 'lg:right-2/3'
  | 'xl:right-2/3'
  | '2xl:right-2/3'
  | 'sm:bottom-2/3'
  | 'md:bottom-2/3'
  | 'lg:bottom-2/3'
  | 'xl:bottom-2/3'
  | '2xl:bottom-2/3'
  | 'sm:left-2/3'
  | 'md:left-2/3'
  | 'lg:left-2/3'
  | 'xl:left-2/3'
  | '2xl:left-2/3'
  | 'sm:inset-1/4'
  | 'md:inset-1/4'
  | 'lg:inset-1/4'
  | 'xl:inset-1/4'
  | '2xl:inset-1/4'
  | 'sm:inset-x-1/4'
  | 'md:inset-x-1/4'
  | 'lg:inset-x-1/4'
  | 'xl:inset-x-1/4'
  | '2xl:inset-x-1/4'
  | 'sm:inset-y-1/4'
  | 'md:inset-y-1/4'
  | 'lg:inset-y-1/4'
  | 'xl:inset-y-1/4'
  | '2xl:inset-y-1/4'
  | 'sm:top-1/4'
  | 'md:top-1/4'
  | 'lg:top-1/4'
  | 'xl:top-1/4'
  | '2xl:top-1/4'
  | 'sm:right-1/4'
  | 'md:right-1/4'
  | 'lg:right-1/4'
  | 'xl:right-1/4'
  | '2xl:right-1/4'
  | 'sm:bottom-1/4'
  | 'md:bottom-1/4'
  | 'lg:bottom-1/4'
  | 'xl:bottom-1/4'
  | '2xl:bottom-1/4'
  | 'sm:left-1/4'
  | 'md:left-1/4'
  | 'lg:left-1/4'
  | 'xl:left-1/4'
  | '2xl:left-1/4'
  | 'sm:inset-2/4'
  | 'md:inset-2/4'
  | 'lg:inset-2/4'
  | 'xl:inset-2/4'
  | '2xl:inset-2/4'
  | 'sm:inset-x-2/4'
  | 'md:inset-x-2/4'
  | 'lg:inset-x-2/4'
  | 'xl:inset-x-2/4'
  | '2xl:inset-x-2/4'
  | 'sm:inset-y-2/4'
  | 'md:inset-y-2/4'
  | 'lg:inset-y-2/4'
  | 'xl:inset-y-2/4'
  | '2xl:inset-y-2/4'
  | 'sm:top-2/4'
  | 'md:top-2/4'
  | 'lg:top-2/4'
  | 'xl:top-2/4'
  | '2xl:top-2/4'
  | 'sm:right-2/4'
  | 'md:right-2/4'
  | 'lg:right-2/4'
  | 'xl:right-2/4'
  | '2xl:right-2/4'
  | 'sm:bottom-2/4'
  | 'md:bottom-2/4'
  | 'lg:bottom-2/4'
  | 'xl:bottom-2/4'
  | '2xl:bottom-2/4'
  | 'sm:left-2/4'
  | 'md:left-2/4'
  | 'lg:left-2/4'
  | 'xl:left-2/4'
  | '2xl:left-2/4'
  | 'sm:inset-3/4'
  | 'md:inset-3/4'
  | 'lg:inset-3/4'
  | 'xl:inset-3/4'
  | '2xl:inset-3/4'
  | 'sm:inset-x-3/4'
  | 'md:inset-x-3/4'
  | 'lg:inset-x-3/4'
  | 'xl:inset-x-3/4'
  | '2xl:inset-x-3/4'
  | 'sm:inset-y-3/4'
  | 'md:inset-y-3/4'
  | 'lg:inset-y-3/4'
  | 'xl:inset-y-3/4'
  | '2xl:inset-y-3/4'
  | 'sm:top-3/4'
  | 'md:top-3/4'
  | 'lg:top-3/4'
  | 'xl:top-3/4'
  | '2xl:top-3/4'
  | 'sm:right-3/4'
  | 'md:right-3/4'
  | 'lg:right-3/4'
  | 'xl:right-3/4'
  | '2xl:right-3/4'
  | 'sm:bottom-3/4'
  | 'md:bottom-3/4'
  | 'lg:bottom-3/4'
  | 'xl:bottom-3/4'
  | '2xl:bottom-3/4'
  | 'sm:left-3/4'
  | 'md:left-3/4'
  | 'lg:left-3/4'
  | 'xl:left-3/4'
  | '2xl:left-3/4'
  | 'sm:inset-full'
  | 'md:inset-full'
  | 'lg:inset-full'
  | 'xl:inset-full'
  | '2xl:inset-full'
  | 'sm:inset-x-full'
  | 'md:inset-x-full'
  | 'lg:inset-x-full'
  | 'xl:inset-x-full'
  | '2xl:inset-x-full'
  | 'sm:inset-y-full'
  | 'md:inset-y-full'
  | 'lg:inset-y-full'
  | 'xl:inset-y-full'
  | '2xl:inset-y-full'
  | 'sm:top-full'
  | 'md:top-full'
  | 'lg:top-full'
  | 'xl:top-full'
  | '2xl:top-full'
  | 'sm:right-full'
  | 'md:right-full'
  | 'lg:right-full'
  | 'xl:right-full'
  | '2xl:right-full'
  | 'sm:bottom-full'
  | 'md:bottom-full'
  | 'lg:bottom-full'
  | 'xl:bottom-full'
  | '2xl:bottom-full'
  | 'sm:left-full'
  | 'md:left-full'
  | 'lg:left-full'
  | 'xl:left-full'
  | '2xl:left-full'
  | 'sm:-inset-1/2'
  | 'md:-inset-1/2'
  | 'lg:-inset-1/2'
  | 'xl:-inset-1/2'
  | '2xl:-inset-1/2'
  | 'sm:-inset-x-1/2'
  | 'md:-inset-x-1/2'
  | 'lg:-inset-x-1/2'
  | 'xl:-inset-x-1/2'
  | '2xl:-inset-x-1/2'
  | 'sm:-inset-y-1/2'
  | 'md:-inset-y-1/2'
  | 'lg:-inset-y-1/2'
  | 'xl:-inset-y-1/2'
  | '2xl:-inset-y-1/2'
  | 'sm:-top-1/2'
  | 'md:-top-1/2'
  | 'lg:-top-1/2'
  | 'xl:-top-1/2'
  | '2xl:-top-1/2'
  | 'sm:-right-1/2'
  | 'md:-right-1/2'
  | 'lg:-right-1/2'
  | 'xl:-right-1/2'
  | '2xl:-right-1/2'
  | 'sm:-bottom-1/2'
  | 'md:-bottom-1/2'
  | 'lg:-bottom-1/2'
  | 'xl:-bottom-1/2'
  | '2xl:-bottom-1/2'
  | 'sm:-left-1/2'
  | 'md:-left-1/2'
  | 'lg:-left-1/2'
  | 'xl:-left-1/2'
  | '2xl:-left-1/2'
  | 'sm:-inset-1/3'
  | 'md:-inset-1/3'
  | 'lg:-inset-1/3'
  | 'xl:-inset-1/3'
  | '2xl:-inset-1/3'
  | 'sm:-inset-x-1/3'
  | 'md:-inset-x-1/3'
  | 'lg:-inset-x-1/3'
  | 'xl:-inset-x-1/3'
  | '2xl:-inset-x-1/3'
  | 'sm:-inset-y-1/3'
  | 'md:-inset-y-1/3'
  | 'lg:-inset-y-1/3'
  | 'xl:-inset-y-1/3'
  | '2xl:-inset-y-1/3'
  | 'sm:-top-1/3'
  | 'md:-top-1/3'
  | 'lg:-top-1/3'
  | 'xl:-top-1/3'
  | '2xl:-top-1/3'
  | 'sm:-right-1/3'
  | 'md:-right-1/3'
  | 'lg:-right-1/3'
  | 'xl:-right-1/3'
  | '2xl:-right-1/3'
  | 'sm:-bottom-1/3'
  | 'md:-bottom-1/3'
  | 'lg:-bottom-1/3'
  | 'xl:-bottom-1/3'
  | '2xl:-bottom-1/3'
  | 'sm:-left-1/3'
  | 'md:-left-1/3'
  | 'lg:-left-1/3'
  | 'xl:-left-1/3'
  | '2xl:-left-1/3'
  | 'sm:-inset-2/3'
  | 'md:-inset-2/3'
  | 'lg:-inset-2/3'
  | 'xl:-inset-2/3'
  | '2xl:-inset-2/3'
  | 'sm:-inset-x-2/3'
  | 'md:-inset-x-2/3'
  | 'lg:-inset-x-2/3'
  | 'xl:-inset-x-2/3'
  | '2xl:-inset-x-2/3'
  | 'sm:-inset-y-2/3'
  | 'md:-inset-y-2/3'
  | 'lg:-inset-y-2/3'
  | 'xl:-inset-y-2/3'
  | '2xl:-inset-y-2/3'
  | 'sm:-top-2/3'
  | 'md:-top-2/3'
  | 'lg:-top-2/3'
  | 'xl:-top-2/3'
  | '2xl:-top-2/3'
  | 'sm:-right-2/3'
  | 'md:-right-2/3'
  | 'lg:-right-2/3'
  | 'xl:-right-2/3'
  | '2xl:-right-2/3'
  | 'sm:-bottom-2/3'
  | 'md:-bottom-2/3'
  | 'lg:-bottom-2/3'
  | 'xl:-bottom-2/3'
  | '2xl:-bottom-2/3'
  | 'sm:-left-2/3'
  | 'md:-left-2/3'
  | 'lg:-left-2/3'
  | 'xl:-left-2/3'
  | '2xl:-left-2/3'
  | 'sm:-inset-1/4'
  | 'md:-inset-1/4'
  | 'lg:-inset-1/4'
  | 'xl:-inset-1/4'
  | '2xl:-inset-1/4'
  | 'sm:-inset-x-1/4'
  | 'md:-inset-x-1/4'
  | 'lg:-inset-x-1/4'
  | 'xl:-inset-x-1/4'
  | '2xl:-inset-x-1/4'
  | 'sm:-inset-y-1/4'
  | 'md:-inset-y-1/4'
  | 'lg:-inset-y-1/4'
  | 'xl:-inset-y-1/4'
  | '2xl:-inset-y-1/4'
  | 'sm:-top-1/4'
  | 'md:-top-1/4'
  | 'lg:-top-1/4'
  | 'xl:-top-1/4'
  | '2xl:-top-1/4'
  | 'sm:-right-1/4'
  | 'md:-right-1/4'
  | 'lg:-right-1/4'
  | 'xl:-right-1/4'
  | '2xl:-right-1/4'
  | 'sm:-bottom-1/4'
  | 'md:-bottom-1/4'
  | 'lg:-bottom-1/4'
  | 'xl:-bottom-1/4'
  | '2xl:-bottom-1/4'
  | 'sm:-left-1/4'
  | 'md:-left-1/4'
  | 'lg:-left-1/4'
  | 'xl:-left-1/4'
  | '2xl:-left-1/4'
  | 'sm:-inset-2/4'
  | 'md:-inset-2/4'
  | 'lg:-inset-2/4'
  | 'xl:-inset-2/4'
  | '2xl:-inset-2/4'
  | 'sm:-inset-x-2/4'
  | 'md:-inset-x-2/4'
  | 'lg:-inset-x-2/4'
  | 'xl:-inset-x-2/4'
  | '2xl:-inset-x-2/4'
  | 'sm:-inset-y-2/4'
  | 'md:-inset-y-2/4'
  | 'lg:-inset-y-2/4'
  | 'xl:-inset-y-2/4'
  | '2xl:-inset-y-2/4'
  | 'sm:-top-2/4'
  | 'md:-top-2/4'
  | 'lg:-top-2/4'
  | 'xl:-top-2/4'
  | '2xl:-top-2/4'
  | 'sm:-right-2/4'
  | 'md:-right-2/4'
  | 'lg:-right-2/4'
  | 'xl:-right-2/4'
  | '2xl:-right-2/4'
  | 'sm:-bottom-2/4'
  | 'md:-bottom-2/4'
  | 'lg:-bottom-2/4'
  | 'xl:-bottom-2/4'
  | '2xl:-bottom-2/4'
  | 'sm:-left-2/4'
  | 'md:-left-2/4'
  | 'lg:-left-2/4'
  | 'xl:-left-2/4'
  | '2xl:-left-2/4'
  | 'sm:-inset-3/4'
  | 'md:-inset-3/4'
  | 'lg:-inset-3/4'
  | 'xl:-inset-3/4'
  | '2xl:-inset-3/4'
  | 'sm:-inset-x-3/4'
  | 'md:-inset-x-3/4'
  | 'lg:-inset-x-3/4'
  | 'xl:-inset-x-3/4'
  | '2xl:-inset-x-3/4'
  | 'sm:-inset-y-3/4'
  | 'md:-inset-y-3/4'
  | 'lg:-inset-y-3/4'
  | 'xl:-inset-y-3/4'
  | '2xl:-inset-y-3/4'
  | 'sm:-top-3/4'
  | 'md:-top-3/4'
  | 'lg:-top-3/4'
  | 'xl:-top-3/4'
  | '2xl:-top-3/4'
  | 'sm:-right-3/4'
  | 'md:-right-3/4'
  | 'lg:-right-3/4'
  | 'xl:-right-3/4'
  | '2xl:-right-3/4'
  | 'sm:-bottom-3/4'
  | 'md:-bottom-3/4'
  | 'lg:-bottom-3/4'
  | 'xl:-bottom-3/4'
  | '2xl:-bottom-3/4'
  | 'sm:-left-3/4'
  | 'md:-left-3/4'
  | 'lg:-left-3/4'
  | 'xl:-left-3/4'
  | '2xl:-left-3/4'
  | 'sm:-inset-full'
  | 'md:-inset-full'
  | 'lg:-inset-full'
  | 'xl:-inset-full'
  | '2xl:-inset-full'
  | 'sm:-inset-x-full'
  | 'md:-inset-x-full'
  | 'lg:-inset-x-full'
  | 'xl:-inset-x-full'
  | '2xl:-inset-x-full'
  | 'sm:-inset-y-full'
  | 'md:-inset-y-full'
  | 'lg:-inset-y-full'
  | 'xl:-inset-y-full'
  | '2xl:-inset-y-full'
  | 'sm:-top-full'
  | 'md:-top-full'
  | 'lg:-top-full'
  | 'xl:-top-full'
  | '2xl:-top-full'
  | 'sm:-right-full'
  | 'md:-right-full'
  | 'lg:-right-full'
  | 'xl:-right-full'
  | '2xl:-right-full'
  | 'sm:-bottom-full'
  | 'md:-bottom-full'
  | 'lg:-bottom-full'
  | 'xl:-bottom-full'
  | '2xl:-bottom-full'
  | 'sm:-left-full'
  | 'md:-left-full'
  | 'lg:-left-full'
  | 'xl:-left-full'
  | '2xl:-left-full'
  | 'sm:invert-0'
  | 'md:invert-0'
  | 'lg:invert-0'
  | 'xl:invert-0'
  | '2xl:invert-0'
  | 'sm:invert'
  | 'md:invert'
  | 'lg:invert'
  | 'xl:invert'
  | '2xl:invert'
  | 'sm:isolate'
  | 'md:isolate'
  | 'lg:isolate'
  | 'xl:isolate'
  | '2xl:isolate'
  | 'sm:isolation-auto'
  | 'md:isolation-auto'
  | 'lg:isolation-auto'
  | 'xl:isolation-auto'
  | '2xl:isolation-auto'
  | 'sm:justify-start'
  | 'md:justify-start'
  | 'lg:justify-start'
  | 'xl:justify-start'
  | '2xl:justify-start'
  | 'sm:justify-center'
  | 'md:justify-center'
  | 'lg:justify-center'
  | 'xl:justify-center'
  | '2xl:justify-center'
  | 'sm:justify-end'
  | 'md:justify-end'
  | 'lg:justify-end'
  | 'xl:justify-end'
  | '2xl:justify-end'
  | 'sm:justify-between'
  | 'md:justify-between'
  | 'lg:justify-between'
  | 'xl:justify-between'
  | '2xl:justify-between'
  | 'sm:justify-around'
  | 'md:justify-around'
  | 'lg:justify-around'
  | 'xl:justify-around'
  | '2xl:justify-around'
  | 'sm:justify-items-auto'
  | 'md:justify-items-auto'
  | 'lg:justify-items-auto'
  | 'xl:justify-items-auto'
  | '2xl:justify-items-auto'
  | 'sm:justify-items-start'
  | 'md:justify-items-start'
  | 'lg:justify-items-start'
  | 'xl:justify-items-start'
  | '2xl:justify-items-start'
  | 'sm:justify-items-center'
  | 'md:justify-items-center'
  | 'lg:justify-items-center'
  | 'xl:justify-items-center'
  | '2xl:justify-items-center'
  | 'sm:justify-items-end'
  | 'md:justify-items-end'
  | 'lg:justify-items-end'
  | 'xl:justify-items-end'
  | '2xl:justify-items-end'
  | 'sm:justify-items-stretch'
  | 'md:justify-items-stretch'
  | 'lg:justify-items-stretch'
  | 'xl:justify-items-stretch'
  | '2xl:justify-items-stretch'
  | 'sm:justify-self-auto'
  | 'md:justify-self-auto'
  | 'lg:justify-self-auto'
  | 'xl:justify-self-auto'
  | '2xl:justify-self-auto'
  | 'sm:justify-self-start'
  | 'md:justify-self-start'
  | 'lg:justify-self-start'
  | 'xl:justify-self-start'
  | '2xl:justify-self-start'
  | 'sm:justify-self-center'
  | 'md:justify-self-center'
  | 'lg:justify-self-center'
  | 'xl:justify-self-center'
  | '2xl:justify-self-center'
  | 'sm:justify-self-end'
  | 'md:justify-self-end'
  | 'lg:justify-self-end'
  | 'xl:justify-self-end'
  | '2xl:justify-self-end'
  | 'sm:justify-self-stretch'
  | 'md:justify-self-stretch'
  | 'lg:justify-self-stretch'
  | 'xl:justify-self-stretch'
  | '2xl:justify-self-stretch'
  | 'sm:tracking-tighter'
  | 'md:tracking-tighter'
  | 'lg:tracking-tighter'
  | 'xl:tracking-tighter'
  | '2xl:tracking-tighter'
  | 'sm:tracking-tight'
  | 'md:tracking-tight'
  | 'lg:tracking-tight'
  | 'xl:tracking-tight'
  | '2xl:tracking-tight'
  | 'sm:tracking-normal'
  | 'md:tracking-normal'
  | 'lg:tracking-normal'
  | 'xl:tracking-normal'
  | '2xl:tracking-normal'
  | 'sm:tracking-wide'
  | 'md:tracking-wide'
  | 'lg:tracking-wide'
  | 'xl:tracking-wide'
  | '2xl:tracking-wide'
  | 'sm:tracking-wider'
  | 'md:tracking-wider'
  | 'lg:tracking-wider'
  | 'xl:tracking-wider'
  | '2xl:tracking-wider'
  | 'sm:tracking-widest'
  | 'md:tracking-widest'
  | 'lg:tracking-widest'
  | 'xl:tracking-widest'
  | '2xl:tracking-widest'
  | 'sm:leading-3'
  | 'md:leading-3'
  | 'lg:leading-3'
  | 'xl:leading-3'
  | '2xl:leading-3'
  | 'sm:leading-4'
  | 'md:leading-4'
  | 'lg:leading-4'
  | 'xl:leading-4'
  | '2xl:leading-4'
  | 'sm:leading-5'
  | 'md:leading-5'
  | 'lg:leading-5'
  | 'xl:leading-5'
  | '2xl:leading-5'
  | 'sm:leading-6'
  | 'md:leading-6'
  | 'lg:leading-6'
  | 'xl:leading-6'
  | '2xl:leading-6'
  | 'sm:leading-7'
  | 'md:leading-7'
  | 'lg:leading-7'
  | 'xl:leading-7'
  | '2xl:leading-7'
  | 'sm:leading-8'
  | 'md:leading-8'
  | 'lg:leading-8'
  | 'xl:leading-8'
  | '2xl:leading-8'
  | 'sm:leading-9'
  | 'md:leading-9'
  | 'lg:leading-9'
  | 'xl:leading-9'
  | '2xl:leading-9'
  | 'sm:leading-10'
  | 'md:leading-10'
  | 'lg:leading-10'
  | 'xl:leading-10'
  | '2xl:leading-10'
  | 'sm:leading-none'
  | 'md:leading-none'
  | 'lg:leading-none'
  | 'xl:leading-none'
  | '2xl:leading-none'
  | 'sm:leading-tight'
  | 'md:leading-tight'
  | 'lg:leading-tight'
  | 'xl:leading-tight'
  | '2xl:leading-tight'
  | 'sm:leading-snug'
  | 'md:leading-snug'
  | 'lg:leading-snug'
  | 'xl:leading-snug'
  | '2xl:leading-snug'
  | 'sm:leading-normal'
  | 'md:leading-normal'
  | 'lg:leading-normal'
  | 'xl:leading-normal'
  | '2xl:leading-normal'
  | 'sm:leading-relaxed'
  | 'md:leading-relaxed'
  | 'lg:leading-relaxed'
  | 'xl:leading-relaxed'
  | '2xl:leading-relaxed'
  | 'sm:leading-loose'
  | 'md:leading-loose'
  | 'lg:leading-loose'
  | 'xl:leading-loose'
  | '2xl:leading-loose'
  | 'sm:list-inside'
  | 'md:list-inside'
  | 'lg:list-inside'
  | 'xl:list-inside'
  | '2xl:list-inside'
  | 'sm:list-outside'
  | 'md:list-outside'
  | 'lg:list-outside'
  | 'xl:list-outside'
  | '2xl:list-outside'
  | 'sm:list-none'
  | 'md:list-none'
  | 'lg:list-none'
  | 'xl:list-none'
  | '2xl:list-none'
  | 'sm:list-disc'
  | 'md:list-disc'
  | 'lg:list-disc'
  | 'xl:list-disc'
  | '2xl:list-disc'
  | 'sm:list-decimal'
  | 'md:list-decimal'
  | 'lg:list-decimal'
  | 'xl:list-decimal'
  | '2xl:list-decimal'
  | 'sm:m-0'
  | 'md:m-0'
  | 'lg:m-0'
  | 'xl:m-0'
  | '2xl:m-0'
  | 'sm:m-1'
  | 'md:m-1'
  | 'lg:m-1'
  | 'xl:m-1'
  | '2xl:m-1'
  | 'sm:m-2'
  | 'md:m-2'
  | 'lg:m-2'
  | 'xl:m-2'
  | '2xl:m-2'
  | 'sm:m-3'
  | 'md:m-3'
  | 'lg:m-3'
  | 'xl:m-3'
  | '2xl:m-3'
  | 'sm:m-4'
  | 'md:m-4'
  | 'lg:m-4'
  | 'xl:m-4'
  | '2xl:m-4'
  | 'sm:m-5'
  | 'md:m-5'
  | 'lg:m-5'
  | 'xl:m-5'
  | '2xl:m-5'
  | 'sm:m-6'
  | 'md:m-6'
  | 'lg:m-6'
  | 'xl:m-6'
  | '2xl:m-6'
  | 'sm:m-7'
  | 'md:m-7'
  | 'lg:m-7'
  | 'xl:m-7'
  | '2xl:m-7'
  | 'sm:m-8'
  | 'md:m-8'
  | 'lg:m-8'
  | 'xl:m-8'
  | '2xl:m-8'
  | 'sm:m-9'
  | 'md:m-9'
  | 'lg:m-9'
  | 'xl:m-9'
  | '2xl:m-9'
  | 'sm:m-10'
  | 'md:m-10'
  | 'lg:m-10'
  | 'xl:m-10'
  | '2xl:m-10'
  | 'sm:m-11'
  | 'md:m-11'
  | 'lg:m-11'
  | 'xl:m-11'
  | '2xl:m-11'
  | 'sm:m-12'
  | 'md:m-12'
  | 'lg:m-12'
  | 'xl:m-12'
  | '2xl:m-12'
  | 'sm:m-14'
  | 'md:m-14'
  | 'lg:m-14'
  | 'xl:m-14'
  | '2xl:m-14'
  | 'sm:m-16'
  | 'md:m-16'
  | 'lg:m-16'
  | 'xl:m-16'
  | '2xl:m-16'
  | 'sm:m-20'
  | 'md:m-20'
  | 'lg:m-20'
  | 'xl:m-20'
  | '2xl:m-20'
  | 'sm:m-24'
  | 'md:m-24'
  | 'lg:m-24'
  | 'xl:m-24'
  | '2xl:m-24'
  | 'sm:m-28'
  | 'md:m-28'
  | 'lg:m-28'
  | 'xl:m-28'
  | '2xl:m-28'
  | 'sm:m-32'
  | 'md:m-32'
  | 'lg:m-32'
  | 'xl:m-32'
  | '2xl:m-32'
  | 'sm:m-36'
  | 'md:m-36'
  | 'lg:m-36'
  | 'xl:m-36'
  | '2xl:m-36'
  | 'sm:m-40'
  | 'md:m-40'
  | 'lg:m-40'
  | 'xl:m-40'
  | '2xl:m-40'
  | 'sm:m-44'
  | 'md:m-44'
  | 'lg:m-44'
  | 'xl:m-44'
  | '2xl:m-44'
  | 'sm:m-48'
  | 'md:m-48'
  | 'lg:m-48'
  | 'xl:m-48'
  | '2xl:m-48'
  | 'sm:m-52'
  | 'md:m-52'
  | 'lg:m-52'
  | 'xl:m-52'
  | '2xl:m-52'
  | 'sm:m-56'
  | 'md:m-56'
  | 'lg:m-56'
  | 'xl:m-56'
  | '2xl:m-56'
  | 'sm:m-60'
  | 'md:m-60'
  | 'lg:m-60'
  | 'xl:m-60'
  | '2xl:m-60'
  | 'sm:m-64'
  | 'md:m-64'
  | 'lg:m-64'
  | 'xl:m-64'
  | '2xl:m-64'
  | 'sm:m-72'
  | 'md:m-72'
  | 'lg:m-72'
  | 'xl:m-72'
  | '2xl:m-72'
  | 'sm:m-80'
  | 'md:m-80'
  | 'lg:m-80'
  | 'xl:m-80'
  | '2xl:m-80'
  | 'sm:m-96'
  | 'md:m-96'
  | 'lg:m-96'
  | 'xl:m-96'
  | '2xl:m-96'
  | 'sm:m-auto'
  | 'md:m-auto'
  | 'lg:m-auto'
  | 'xl:m-auto'
  | '2xl:m-auto'
  | 'sm:m-px'
  | 'md:m-px'
  | 'lg:m-px'
  | 'xl:m-px'
  | '2xl:m-px'
  | 'sm:m-0.5'
  | 'md:m-0.5'
  | 'lg:m-0.5'
  | 'xl:m-0.5'
  | '2xl:m-0.5'
  | 'sm:m-1.5'
  | 'md:m-1.5'
  | 'lg:m-1.5'
  | 'xl:m-1.5'
  | '2xl:m-1.5'
  | 'sm:m-2.5'
  | 'md:m-2.5'
  | 'lg:m-2.5'
  | 'xl:m-2.5'
  | '2xl:m-2.5'
  | 'sm:m-3.5'
  | 'md:m-3.5'
  | 'lg:m-3.5'
  | 'xl:m-3.5'
  | '2xl:m-3.5'
  | 'sm:-m-0'
  | 'md:-m-0'
  | 'lg:-m-0'
  | 'xl:-m-0'
  | '2xl:-m-0'
  | 'sm:-m-1'
  | 'md:-m-1'
  | 'lg:-m-1'
  | 'xl:-m-1'
  | '2xl:-m-1'
  | 'sm:-m-2'
  | 'md:-m-2'
  | 'lg:-m-2'
  | 'xl:-m-2'
  | '2xl:-m-2'
  | 'sm:-m-3'
  | 'md:-m-3'
  | 'lg:-m-3'
  | 'xl:-m-3'
  | '2xl:-m-3'
  | 'sm:-m-4'
  | 'md:-m-4'
  | 'lg:-m-4'
  | 'xl:-m-4'
  | '2xl:-m-4'
  | 'sm:-m-5'
  | 'md:-m-5'
  | 'lg:-m-5'
  | 'xl:-m-5'
  | '2xl:-m-5'
  | 'sm:-m-6'
  | 'md:-m-6'
  | 'lg:-m-6'
  | 'xl:-m-6'
  | '2xl:-m-6'
  | 'sm:-m-7'
  | 'md:-m-7'
  | 'lg:-m-7'
  | 'xl:-m-7'
  | '2xl:-m-7'
  | 'sm:-m-8'
  | 'md:-m-8'
  | 'lg:-m-8'
  | 'xl:-m-8'
  | '2xl:-m-8'
  | 'sm:-m-9'
  | 'md:-m-9'
  | 'lg:-m-9'
  | 'xl:-m-9'
  | '2xl:-m-9'
  | 'sm:-m-10'
  | 'md:-m-10'
  | 'lg:-m-10'
  | 'xl:-m-10'
  | '2xl:-m-10'
  | 'sm:-m-11'
  | 'md:-m-11'
  | 'lg:-m-11'
  | 'xl:-m-11'
  | '2xl:-m-11'
  | 'sm:-m-12'
  | 'md:-m-12'
  | 'lg:-m-12'
  | 'xl:-m-12'
  | '2xl:-m-12'
  | 'sm:-m-14'
  | 'md:-m-14'
  | 'lg:-m-14'
  | 'xl:-m-14'
  | '2xl:-m-14'
  | 'sm:-m-16'
  | 'md:-m-16'
  | 'lg:-m-16'
  | 'xl:-m-16'
  | '2xl:-m-16'
  | 'sm:-m-20'
  | 'md:-m-20'
  | 'lg:-m-20'
  | 'xl:-m-20'
  | '2xl:-m-20'
  | 'sm:-m-24'
  | 'md:-m-24'
  | 'lg:-m-24'
  | 'xl:-m-24'
  | '2xl:-m-24'
  | 'sm:-m-28'
  | 'md:-m-28'
  | 'lg:-m-28'
  | 'xl:-m-28'
  | '2xl:-m-28'
  | 'sm:-m-32'
  | 'md:-m-32'
  | 'lg:-m-32'
  | 'xl:-m-32'
  | '2xl:-m-32'
  | 'sm:-m-36'
  | 'md:-m-36'
  | 'lg:-m-36'
  | 'xl:-m-36'
  | '2xl:-m-36'
  | 'sm:-m-40'
  | 'md:-m-40'
  | 'lg:-m-40'
  | 'xl:-m-40'
  | '2xl:-m-40'
  | 'sm:-m-44'
  | 'md:-m-44'
  | 'lg:-m-44'
  | 'xl:-m-44'
  | '2xl:-m-44'
  | 'sm:-m-48'
  | 'md:-m-48'
  | 'lg:-m-48'
  | 'xl:-m-48'
  | '2xl:-m-48'
  | 'sm:-m-52'
  | 'md:-m-52'
  | 'lg:-m-52'
  | 'xl:-m-52'
  | '2xl:-m-52'
  | 'sm:-m-56'
  | 'md:-m-56'
  | 'lg:-m-56'
  | 'xl:-m-56'
  | '2xl:-m-56'
  | 'sm:-m-60'
  | 'md:-m-60'
  | 'lg:-m-60'
  | 'xl:-m-60'
  | '2xl:-m-60'
  | 'sm:-m-64'
  | 'md:-m-64'
  | 'lg:-m-64'
  | 'xl:-m-64'
  | '2xl:-m-64'
  | 'sm:-m-72'
  | 'md:-m-72'
  | 'lg:-m-72'
  | 'xl:-m-72'
  | '2xl:-m-72'
  | 'sm:-m-80'
  | 'md:-m-80'
  | 'lg:-m-80'
  | 'xl:-m-80'
  | '2xl:-m-80'
  | 'sm:-m-96'
  | 'md:-m-96'
  | 'lg:-m-96'
  | 'xl:-m-96'
  | '2xl:-m-96'
  | 'sm:-m-px'
  | 'md:-m-px'
  | 'lg:-m-px'
  | 'xl:-m-px'
  | '2xl:-m-px'
  | 'sm:-m-0.5'
  | 'md:-m-0.5'
  | 'lg:-m-0.5'
  | 'xl:-m-0.5'
  | '2xl:-m-0.5'
  | 'sm:-m-1.5'
  | 'md:-m-1.5'
  | 'lg:-m-1.5'
  | 'xl:-m-1.5'
  | '2xl:-m-1.5'
  | 'sm:-m-2.5'
  | 'md:-m-2.5'
  | 'lg:-m-2.5'
  | 'xl:-m-2.5'
  | '2xl:-m-2.5'
  | 'sm:-m-3.5'
  | 'md:-m-3.5'
  | 'lg:-m-3.5'
  | 'xl:-m-3.5'
  | '2xl:-m-3.5'
  | 'sm:my-0'
  | 'md:my-0'
  | 'lg:my-0'
  | 'xl:my-0'
  | '2xl:my-0'
  | 'sm:my-1'
  | 'md:my-1'
  | 'lg:my-1'
  | 'xl:my-1'
  | '2xl:my-1'
  | 'sm:my-2'
  | 'md:my-2'
  | 'lg:my-2'
  | 'xl:my-2'
  | '2xl:my-2'
  | 'sm:my-3'
  | 'md:my-3'
  | 'lg:my-3'
  | 'xl:my-3'
  | '2xl:my-3'
  | 'sm:my-4'
  | 'md:my-4'
  | 'lg:my-4'
  | 'xl:my-4'
  | '2xl:my-4'
  | 'sm:my-5'
  | 'md:my-5'
  | 'lg:my-5'
  | 'xl:my-5'
  | '2xl:my-5'
  | 'sm:my-6'
  | 'md:my-6'
  | 'lg:my-6'
  | 'xl:my-6'
  | '2xl:my-6'
  | 'sm:my-7'
  | 'md:my-7'
  | 'lg:my-7'
  | 'xl:my-7'
  | '2xl:my-7'
  | 'sm:my-8'
  | 'md:my-8'
  | 'lg:my-8'
  | 'xl:my-8'
  | '2xl:my-8'
  | 'sm:my-9'
  | 'md:my-9'
  | 'lg:my-9'
  | 'xl:my-9'
  | '2xl:my-9'
  | 'sm:my-10'
  | 'md:my-10'
  | 'lg:my-10'
  | 'xl:my-10'
  | '2xl:my-10'
  | 'sm:my-11'
  | 'md:my-11'
  | 'lg:my-11'
  | 'xl:my-11'
  | '2xl:my-11'
  | 'sm:my-12'
  | 'md:my-12'
  | 'lg:my-12'
  | 'xl:my-12'
  | '2xl:my-12'
  | 'sm:my-14'
  | 'md:my-14'
  | 'lg:my-14'
  | 'xl:my-14'
  | '2xl:my-14'
  | 'sm:my-16'
  | 'md:my-16'
  | 'lg:my-16'
  | 'xl:my-16'
  | '2xl:my-16'
  | 'sm:my-20'
  | 'md:my-20'
  | 'lg:my-20'
  | 'xl:my-20'
  | '2xl:my-20'
  | 'sm:my-24'
  | 'md:my-24'
  | 'lg:my-24'
  | 'xl:my-24'
  | '2xl:my-24'
  | 'sm:my-28'
  | 'md:my-28'
  | 'lg:my-28'
  | 'xl:my-28'
  | '2xl:my-28'
  | 'sm:my-32'
  | 'md:my-32'
  | 'lg:my-32'
  | 'xl:my-32'
  | '2xl:my-32'
  | 'sm:my-36'
  | 'md:my-36'
  | 'lg:my-36'
  | 'xl:my-36'
  | '2xl:my-36'
  | 'sm:my-40'
  | 'md:my-40'
  | 'lg:my-40'
  | 'xl:my-40'
  | '2xl:my-40'
  | 'sm:my-44'
  | 'md:my-44'
  | 'lg:my-44'
  | 'xl:my-44'
  | '2xl:my-44'
  | 'sm:my-48'
  | 'md:my-48'
  | 'lg:my-48'
  | 'xl:my-48'
  | '2xl:my-48'
  | 'sm:my-52'
  | 'md:my-52'
  | 'lg:my-52'
  | 'xl:my-52'
  | '2xl:my-52'
  | 'sm:my-56'
  | 'md:my-56'
  | 'lg:my-56'
  | 'xl:my-56'
  | '2xl:my-56'
  | 'sm:my-60'
  | 'md:my-60'
  | 'lg:my-60'
  | 'xl:my-60'
  | '2xl:my-60'
  | 'sm:my-64'
  | 'md:my-64'
  | 'lg:my-64'
  | 'xl:my-64'
  | '2xl:my-64'
  | 'sm:my-72'
  | 'md:my-72'
  | 'lg:my-72'
  | 'xl:my-72'
  | '2xl:my-72'
  | 'sm:my-80'
  | 'md:my-80'
  | 'lg:my-80'
  | 'xl:my-80'
  | '2xl:my-80'
  | 'sm:my-96'
  | 'md:my-96'
  | 'lg:my-96'
  | 'xl:my-96'
  | '2xl:my-96'
  | 'sm:my-auto'
  | 'md:my-auto'
  | 'lg:my-auto'
  | 'xl:my-auto'
  | '2xl:my-auto'
  | 'sm:my-px'
  | 'md:my-px'
  | 'lg:my-px'
  | 'xl:my-px'
  | '2xl:my-px'
  | 'sm:my-0.5'
  | 'md:my-0.5'
  | 'lg:my-0.5'
  | 'xl:my-0.5'
  | '2xl:my-0.5'
  | 'sm:my-1.5'
  | 'md:my-1.5'
  | 'lg:my-1.5'
  | 'xl:my-1.5'
  | '2xl:my-1.5'
  | 'sm:my-2.5'
  | 'md:my-2.5'
  | 'lg:my-2.5'
  | 'xl:my-2.5'
  | '2xl:my-2.5'
  | 'sm:my-3.5'
  | 'md:my-3.5'
  | 'lg:my-3.5'
  | 'xl:my-3.5'
  | '2xl:my-3.5'
  | 'sm:-my-0'
  | 'md:-my-0'
  | 'lg:-my-0'
  | 'xl:-my-0'
  | '2xl:-my-0'
  | 'sm:-my-1'
  | 'md:-my-1'
  | 'lg:-my-1'
  | 'xl:-my-1'
  | '2xl:-my-1'
  | 'sm:-my-2'
  | 'md:-my-2'
  | 'lg:-my-2'
  | 'xl:-my-2'
  | '2xl:-my-2'
  | 'sm:-my-3'
  | 'md:-my-3'
  | 'lg:-my-3'
  | 'xl:-my-3'
  | '2xl:-my-3'
  | 'sm:-my-4'
  | 'md:-my-4'
  | 'lg:-my-4'
  | 'xl:-my-4'
  | '2xl:-my-4'
  | 'sm:-my-5'
  | 'md:-my-5'
  | 'lg:-my-5'
  | 'xl:-my-5'
  | '2xl:-my-5'
  | 'sm:-my-6'
  | 'md:-my-6'
  | 'lg:-my-6'
  | 'xl:-my-6'
  | '2xl:-my-6'
  | 'sm:-my-7'
  | 'md:-my-7'
  | 'lg:-my-7'
  | 'xl:-my-7'
  | '2xl:-my-7'
  | 'sm:-my-8'
  | 'md:-my-8'
  | 'lg:-my-8'
  | 'xl:-my-8'
  | '2xl:-my-8'
  | 'sm:-my-9'
  | 'md:-my-9'
  | 'lg:-my-9'
  | 'xl:-my-9'
  | '2xl:-my-9'
  | 'sm:-my-10'
  | 'md:-my-10'
  | 'lg:-my-10'
  | 'xl:-my-10'
  | '2xl:-my-10'
  | 'sm:-my-11'
  | 'md:-my-11'
  | 'lg:-my-11'
  | 'xl:-my-11'
  | '2xl:-my-11'
  | 'sm:-my-12'
  | 'md:-my-12'
  | 'lg:-my-12'
  | 'xl:-my-12'
  | '2xl:-my-12'
  | 'sm:-my-14'
  | 'md:-my-14'
  | 'lg:-my-14'
  | 'xl:-my-14'
  | '2xl:-my-14'
  | 'sm:-my-16'
  | 'md:-my-16'
  | 'lg:-my-16'
  | 'xl:-my-16'
  | '2xl:-my-16'
  | 'sm:-my-20'
  | 'md:-my-20'
  | 'lg:-my-20'
  | 'xl:-my-20'
  | '2xl:-my-20'
  | 'sm:-my-24'
  | 'md:-my-24'
  | 'lg:-my-24'
  | 'xl:-my-24'
  | '2xl:-my-24'
  | 'sm:-my-28'
  | 'md:-my-28'
  | 'lg:-my-28'
  | 'xl:-my-28'
  | '2xl:-my-28'
  | 'sm:-my-32'
  | 'md:-my-32'
  | 'lg:-my-32'
  | 'xl:-my-32'
  | '2xl:-my-32'
  | 'sm:-my-36'
  | 'md:-my-36'
  | 'lg:-my-36'
  | 'xl:-my-36'
  | '2xl:-my-36'
  | 'sm:-my-40'
  | 'md:-my-40'
  | 'lg:-my-40'
  | 'xl:-my-40'
  | '2xl:-my-40'
  | 'sm:-my-44'
  | 'md:-my-44'
  | 'lg:-my-44'
  | 'xl:-my-44'
  | '2xl:-my-44'
  | 'sm:-my-48'
  | 'md:-my-48'
  | 'lg:-my-48'
  | 'xl:-my-48'
  | '2xl:-my-48'
  | 'sm:-my-52'
  | 'md:-my-52'
  | 'lg:-my-52'
  | 'xl:-my-52'
  | '2xl:-my-52'
  | 'sm:-my-56'
  | 'md:-my-56'
  | 'lg:-my-56'
  | 'xl:-my-56'
  | '2xl:-my-56'
  | 'sm:-my-60'
  | 'md:-my-60'
  | 'lg:-my-60'
  | 'xl:-my-60'
  | '2xl:-my-60'
  | 'sm:-my-64'
  | 'md:-my-64'
  | 'lg:-my-64'
  | 'xl:-my-64'
  | '2xl:-my-64'
  | 'sm:-my-72'
  | 'md:-my-72'
  | 'lg:-my-72'
  | 'xl:-my-72'
  | '2xl:-my-72'
  | 'sm:-my-80'
  | 'md:-my-80'
  | 'lg:-my-80'
  | 'xl:-my-80'
  | '2xl:-my-80'
  | 'sm:-my-96'
  | 'md:-my-96'
  | 'lg:-my-96'
  | 'xl:-my-96'
  | '2xl:-my-96'
  | 'sm:-my-px'
  | 'md:-my-px'
  | 'lg:-my-px'
  | 'xl:-my-px'
  | '2xl:-my-px'
  | 'sm:-my-0.5'
  | 'md:-my-0.5'
  | 'lg:-my-0.5'
  | 'xl:-my-0.5'
  | '2xl:-my-0.5'
  | 'sm:-my-1.5'
  | 'md:-my-1.5'
  | 'lg:-my-1.5'
  | 'xl:-my-1.5'
  | '2xl:-my-1.5'
  | 'sm:-my-2.5'
  | 'md:-my-2.5'
  | 'lg:-my-2.5'
  | 'xl:-my-2.5'
  | '2xl:-my-2.5'
  | 'sm:-my-3.5'
  | 'md:-my-3.5'
  | 'lg:-my-3.5'
  | 'xl:-my-3.5'
  | '2xl:-my-3.5'
  | 'sm:mx-0'
  | 'md:mx-0'
  | 'lg:mx-0'
  | 'xl:mx-0'
  | '2xl:mx-0'
  | 'sm:mx-1'
  | 'md:mx-1'
  | 'lg:mx-1'
  | 'xl:mx-1'
  | '2xl:mx-1'
  | 'sm:mx-2'
  | 'md:mx-2'
  | 'lg:mx-2'
  | 'xl:mx-2'
  | '2xl:mx-2'
  | 'sm:mx-3'
  | 'md:mx-3'
  | 'lg:mx-3'
  | 'xl:mx-3'
  | '2xl:mx-3'
  | 'sm:mx-4'
  | 'md:mx-4'
  | 'lg:mx-4'
  | 'xl:mx-4'
  | '2xl:mx-4'
  | 'sm:mx-5'
  | 'md:mx-5'
  | 'lg:mx-5'
  | 'xl:mx-5'
  | '2xl:mx-5'
  | 'sm:mx-6'
  | 'md:mx-6'
  | 'lg:mx-6'
  | 'xl:mx-6'
  | '2xl:mx-6'
  | 'sm:mx-7'
  | 'md:mx-7'
  | 'lg:mx-7'
  | 'xl:mx-7'
  | '2xl:mx-7'
  | 'sm:mx-8'
  | 'md:mx-8'
  | 'lg:mx-8'
  | 'xl:mx-8'
  | '2xl:mx-8'
  | 'sm:mx-9'
  | 'md:mx-9'
  | 'lg:mx-9'
  | 'xl:mx-9'
  | '2xl:mx-9'
  | 'sm:mx-10'
  | 'md:mx-10'
  | 'lg:mx-10'
  | 'xl:mx-10'
  | '2xl:mx-10'
  | 'sm:mx-11'
  | 'md:mx-11'
  | 'lg:mx-11'
  | 'xl:mx-11'
  | '2xl:mx-11'
  | 'sm:mx-12'
  | 'md:mx-12'
  | 'lg:mx-12'
  | 'xl:mx-12'
  | '2xl:mx-12'
  | 'sm:mx-14'
  | 'md:mx-14'
  | 'lg:mx-14'
  | 'xl:mx-14'
  | '2xl:mx-14'
  | 'sm:mx-16'
  | 'md:mx-16'
  | 'lg:mx-16'
  | 'xl:mx-16'
  | '2xl:mx-16'
  | 'sm:mx-20'
  | 'md:mx-20'
  | 'lg:mx-20'
  | 'xl:mx-20'
  | '2xl:mx-20'
  | 'sm:mx-24'
  | 'md:mx-24'
  | 'lg:mx-24'
  | 'xl:mx-24'
  | '2xl:mx-24'
  | 'sm:mx-28'
  | 'md:mx-28'
  | 'lg:mx-28'
  | 'xl:mx-28'
  | '2xl:mx-28'
  | 'sm:mx-32'
  | 'md:mx-32'
  | 'lg:mx-32'
  | 'xl:mx-32'
  | '2xl:mx-32'
  | 'sm:mx-36'
  | 'md:mx-36'
  | 'lg:mx-36'
  | 'xl:mx-36'
  | '2xl:mx-36'
  | 'sm:mx-40'
  | 'md:mx-40'
  | 'lg:mx-40'
  | 'xl:mx-40'
  | '2xl:mx-40'
  | 'sm:mx-44'
  | 'md:mx-44'
  | 'lg:mx-44'
  | 'xl:mx-44'
  | '2xl:mx-44'
  | 'sm:mx-48'
  | 'md:mx-48'
  | 'lg:mx-48'
  | 'xl:mx-48'
  | '2xl:mx-48'
  | 'sm:mx-52'
  | 'md:mx-52'
  | 'lg:mx-52'
  | 'xl:mx-52'
  | '2xl:mx-52'
  | 'sm:mx-56'
  | 'md:mx-56'
  | 'lg:mx-56'
  | 'xl:mx-56'
  | '2xl:mx-56'
  | 'sm:mx-60'
  | 'md:mx-60'
  | 'lg:mx-60'
  | 'xl:mx-60'
  | '2xl:mx-60'
  | 'sm:mx-64'
  | 'md:mx-64'
  | 'lg:mx-64'
  | 'xl:mx-64'
  | '2xl:mx-64'
  | 'sm:mx-72'
  | 'md:mx-72'
  | 'lg:mx-72'
  | 'xl:mx-72'
  | '2xl:mx-72'
  | 'sm:mx-80'
  | 'md:mx-80'
  | 'lg:mx-80'
  | 'xl:mx-80'
  | '2xl:mx-80'
  | 'sm:mx-96'
  | 'md:mx-96'
  | 'lg:mx-96'
  | 'xl:mx-96'
  | '2xl:mx-96'
  | 'sm:mx-auto'
  | 'md:mx-auto'
  | 'lg:mx-auto'
  | 'xl:mx-auto'
  | '2xl:mx-auto'
  | 'sm:mx-px'
  | 'md:mx-px'
  | 'lg:mx-px'
  | 'xl:mx-px'
  | '2xl:mx-px'
  | 'sm:mx-0.5'
  | 'md:mx-0.5'
  | 'lg:mx-0.5'
  | 'xl:mx-0.5'
  | '2xl:mx-0.5'
  | 'sm:mx-1.5'
  | 'md:mx-1.5'
  | 'lg:mx-1.5'
  | 'xl:mx-1.5'
  | '2xl:mx-1.5'
  | 'sm:mx-2.5'
  | 'md:mx-2.5'
  | 'lg:mx-2.5'
  | 'xl:mx-2.5'
  | '2xl:mx-2.5'
  | 'sm:mx-3.5'
  | 'md:mx-3.5'
  | 'lg:mx-3.5'
  | 'xl:mx-3.5'
  | '2xl:mx-3.5'
  | 'sm:-mx-0'
  | 'md:-mx-0'
  | 'lg:-mx-0'
  | 'xl:-mx-0'
  | '2xl:-mx-0'
  | 'sm:-mx-1'
  | 'md:-mx-1'
  | 'lg:-mx-1'
  | 'xl:-mx-1'
  | '2xl:-mx-1'
  | 'sm:-mx-2'
  | 'md:-mx-2'
  | 'lg:-mx-2'
  | 'xl:-mx-2'
  | '2xl:-mx-2'
  | 'sm:-mx-3'
  | 'md:-mx-3'
  | 'lg:-mx-3'
  | 'xl:-mx-3'
  | '2xl:-mx-3'
  | 'sm:-mx-4'
  | 'md:-mx-4'
  | 'lg:-mx-4'
  | 'xl:-mx-4'
  | '2xl:-mx-4'
  | 'sm:-mx-5'
  | 'md:-mx-5'
  | 'lg:-mx-5'
  | 'xl:-mx-5'
  | '2xl:-mx-5'
  | 'sm:-mx-6'
  | 'md:-mx-6'
  | 'lg:-mx-6'
  | 'xl:-mx-6'
  | '2xl:-mx-6'
  | 'sm:-mx-7'
  | 'md:-mx-7'
  | 'lg:-mx-7'
  | 'xl:-mx-7'
  | '2xl:-mx-7'
  | 'sm:-mx-8'
  | 'md:-mx-8'
  | 'lg:-mx-8'
  | 'xl:-mx-8'
  | '2xl:-mx-8'
  | 'sm:-mx-9'
  | 'md:-mx-9'
  | 'lg:-mx-9'
  | 'xl:-mx-9'
  | '2xl:-mx-9'
  | 'sm:-mx-10'
  | 'md:-mx-10'
  | 'lg:-mx-10'
  | 'xl:-mx-10'
  | '2xl:-mx-10'
  | 'sm:-mx-11'
  | 'md:-mx-11'
  | 'lg:-mx-11'
  | 'xl:-mx-11'
  | '2xl:-mx-11'
  | 'sm:-mx-12'
  | 'md:-mx-12'
  | 'lg:-mx-12'
  | 'xl:-mx-12'
  | '2xl:-mx-12'
  | 'sm:-mx-14'
  | 'md:-mx-14'
  | 'lg:-mx-14'
  | 'xl:-mx-14'
  | '2xl:-mx-14'
  | 'sm:-mx-16'
  | 'md:-mx-16'
  | 'lg:-mx-16'
  | 'xl:-mx-16'
  | '2xl:-mx-16'
  | 'sm:-mx-20'
  | 'md:-mx-20'
  | 'lg:-mx-20'
  | 'xl:-mx-20'
  | '2xl:-mx-20'
  | 'sm:-mx-24'
  | 'md:-mx-24'
  | 'lg:-mx-24'
  | 'xl:-mx-24'
  | '2xl:-mx-24'
  | 'sm:-mx-28'
  | 'md:-mx-28'
  | 'lg:-mx-28'
  | 'xl:-mx-28'
  | '2xl:-mx-28'
  | 'sm:-mx-32'
  | 'md:-mx-32'
  | 'lg:-mx-32'
  | 'xl:-mx-32'
  | '2xl:-mx-32'
  | 'sm:-mx-36'
  | 'md:-mx-36'
  | 'lg:-mx-36'
  | 'xl:-mx-36'
  | '2xl:-mx-36'
  | 'sm:-mx-40'
  | 'md:-mx-40'
  | 'lg:-mx-40'
  | 'xl:-mx-40'
  | '2xl:-mx-40'
  | 'sm:-mx-44'
  | 'md:-mx-44'
  | 'lg:-mx-44'
  | 'xl:-mx-44'
  | '2xl:-mx-44'
  | 'sm:-mx-48'
  | 'md:-mx-48'
  | 'lg:-mx-48'
  | 'xl:-mx-48'
  | '2xl:-mx-48'
  | 'sm:-mx-52'
  | 'md:-mx-52'
  | 'lg:-mx-52'
  | 'xl:-mx-52'
  | '2xl:-mx-52'
  | 'sm:-mx-56'
  | 'md:-mx-56'
  | 'lg:-mx-56'
  | 'xl:-mx-56'
  | '2xl:-mx-56'
  | 'sm:-mx-60'
  | 'md:-mx-60'
  | 'lg:-mx-60'
  | 'xl:-mx-60'
  | '2xl:-mx-60'
  | 'sm:-mx-64'
  | 'md:-mx-64'
  | 'lg:-mx-64'
  | 'xl:-mx-64'
  | '2xl:-mx-64'
  | 'sm:-mx-72'
  | 'md:-mx-72'
  | 'lg:-mx-72'
  | 'xl:-mx-72'
  | '2xl:-mx-72'
  | 'sm:-mx-80'
  | 'md:-mx-80'
  | 'lg:-mx-80'
  | 'xl:-mx-80'
  | '2xl:-mx-80'
  | 'sm:-mx-96'
  | 'md:-mx-96'
  | 'lg:-mx-96'
  | 'xl:-mx-96'
  | '2xl:-mx-96'
  | 'sm:-mx-px'
  | 'md:-mx-px'
  | 'lg:-mx-px'
  | 'xl:-mx-px'
  | '2xl:-mx-px'
  | 'sm:-mx-0.5'
  | 'md:-mx-0.5'
  | 'lg:-mx-0.5'
  | 'xl:-mx-0.5'
  | '2xl:-mx-0.5'
  | 'sm:-mx-1.5'
  | 'md:-mx-1.5'
  | 'lg:-mx-1.5'
  | 'xl:-mx-1.5'
  | '2xl:-mx-1.5'
  | 'sm:-mx-2.5'
  | 'md:-mx-2.5'
  | 'lg:-mx-2.5'
  | 'xl:-mx-2.5'
  | '2xl:-mx-2.5'
  | 'sm:-mx-3.5'
  | 'md:-mx-3.5'
  | 'lg:-mx-3.5'
  | 'xl:-mx-3.5'
  | '2xl:-mx-3.5'
  | 'sm:mt-0'
  | 'md:mt-0'
  | 'lg:mt-0'
  | 'xl:mt-0'
  | '2xl:mt-0'
  | 'sm:mt-1'
  | 'md:mt-1'
  | 'lg:mt-1'
  | 'xl:mt-1'
  | '2xl:mt-1'
  | 'sm:mt-2'
  | 'md:mt-2'
  | 'lg:mt-2'
  | 'xl:mt-2'
  | '2xl:mt-2'
  | 'sm:mt-3'
  | 'md:mt-3'
  | 'lg:mt-3'
  | 'xl:mt-3'
  | '2xl:mt-3'
  | 'sm:mt-4'
  | 'md:mt-4'
  | 'lg:mt-4'
  | 'xl:mt-4'
  | '2xl:mt-4'
  | 'sm:mt-5'
  | 'md:mt-5'
  | 'lg:mt-5'
  | 'xl:mt-5'
  | '2xl:mt-5'
  | 'sm:mt-6'
  | 'md:mt-6'
  | 'lg:mt-6'
  | 'xl:mt-6'
  | '2xl:mt-6'
  | 'sm:mt-7'
  | 'md:mt-7'
  | 'lg:mt-7'
  | 'xl:mt-7'
  | '2xl:mt-7'
  | 'sm:mt-8'
  | 'md:mt-8'
  | 'lg:mt-8'
  | 'xl:mt-8'
  | '2xl:mt-8'
  | 'sm:mt-9'
  | 'md:mt-9'
  | 'lg:mt-9'
  | 'xl:mt-9'
  | '2xl:mt-9'
  | 'sm:mt-10'
  | 'md:mt-10'
  | 'lg:mt-10'
  | 'xl:mt-10'
  | '2xl:mt-10'
  | 'sm:mt-11'
  | 'md:mt-11'
  | 'lg:mt-11'
  | 'xl:mt-11'
  | '2xl:mt-11'
  | 'sm:mt-12'
  | 'md:mt-12'
  | 'lg:mt-12'
  | 'xl:mt-12'
  | '2xl:mt-12'
  | 'sm:mt-14'
  | 'md:mt-14'
  | 'lg:mt-14'
  | 'xl:mt-14'
  | '2xl:mt-14'
  | 'sm:mt-16'
  | 'md:mt-16'
  | 'lg:mt-16'
  | 'xl:mt-16'
  | '2xl:mt-16'
  | 'sm:mt-20'
  | 'md:mt-20'
  | 'lg:mt-20'
  | 'xl:mt-20'
  | '2xl:mt-20'
  | 'sm:mt-24'
  | 'md:mt-24'
  | 'lg:mt-24'
  | 'xl:mt-24'
  | '2xl:mt-24'
  | 'sm:mt-28'
  | 'md:mt-28'
  | 'lg:mt-28'
  | 'xl:mt-28'
  | '2xl:mt-28'
  | 'sm:mt-32'
  | 'md:mt-32'
  | 'lg:mt-32'
  | 'xl:mt-32'
  | '2xl:mt-32'
  | 'sm:mt-36'
  | 'md:mt-36'
  | 'lg:mt-36'
  | 'xl:mt-36'
  | '2xl:mt-36'
  | 'sm:mt-40'
  | 'md:mt-40'
  | 'lg:mt-40'
  | 'xl:mt-40'
  | '2xl:mt-40'
  | 'sm:mt-44'
  | 'md:mt-44'
  | 'lg:mt-44'
  | 'xl:mt-44'
  | '2xl:mt-44'
  | 'sm:mt-48'
  | 'md:mt-48'
  | 'lg:mt-48'
  | 'xl:mt-48'
  | '2xl:mt-48'
  | 'sm:mt-52'
  | 'md:mt-52'
  | 'lg:mt-52'
  | 'xl:mt-52'
  | '2xl:mt-52'
  | 'sm:mt-56'
  | 'md:mt-56'
  | 'lg:mt-56'
  | 'xl:mt-56'
  | '2xl:mt-56'
  | 'sm:mt-60'
  | 'md:mt-60'
  | 'lg:mt-60'
  | 'xl:mt-60'
  | '2xl:mt-60'
  | 'sm:mt-64'
  | 'md:mt-64'
  | 'lg:mt-64'
  | 'xl:mt-64'
  | '2xl:mt-64'
  | 'sm:mt-72'
  | 'md:mt-72'
  | 'lg:mt-72'
  | 'xl:mt-72'
  | '2xl:mt-72'
  | 'sm:mt-80'
  | 'md:mt-80'
  | 'lg:mt-80'
  | 'xl:mt-80'
  | '2xl:mt-80'
  | 'sm:mt-96'
  | 'md:mt-96'
  | 'lg:mt-96'
  | 'xl:mt-96'
  | '2xl:mt-96'
  | 'sm:mt-auto'
  | 'md:mt-auto'
  | 'lg:mt-auto'
  | 'xl:mt-auto'
  | '2xl:mt-auto'
  | 'sm:mt-px'
  | 'md:mt-px'
  | 'lg:mt-px'
  | 'xl:mt-px'
  | '2xl:mt-px'
  | 'sm:mt-0.5'
  | 'md:mt-0.5'
  | 'lg:mt-0.5'
  | 'xl:mt-0.5'
  | '2xl:mt-0.5'
  | 'sm:mt-1.5'
  | 'md:mt-1.5'
  | 'lg:mt-1.5'
  | 'xl:mt-1.5'
  | '2xl:mt-1.5'
  | 'sm:mt-2.5'
  | 'md:mt-2.5'
  | 'lg:mt-2.5'
  | 'xl:mt-2.5'
  | '2xl:mt-2.5'
  | 'sm:mt-3.5'
  | 'md:mt-3.5'
  | 'lg:mt-3.5'
  | 'xl:mt-3.5'
  | '2xl:mt-3.5'
  | 'sm:-mt-0'
  | 'md:-mt-0'
  | 'lg:-mt-0'
  | 'xl:-mt-0'
  | '2xl:-mt-0'
  | 'sm:-mt-1'
  | 'md:-mt-1'
  | 'lg:-mt-1'
  | 'xl:-mt-1'
  | '2xl:-mt-1'
  | 'sm:-mt-2'
  | 'md:-mt-2'
  | 'lg:-mt-2'
  | 'xl:-mt-2'
  | '2xl:-mt-2'
  | 'sm:-mt-3'
  | 'md:-mt-3'
  | 'lg:-mt-3'
  | 'xl:-mt-3'
  | '2xl:-mt-3'
  | 'sm:-mt-4'
  | 'md:-mt-4'
  | 'lg:-mt-4'
  | 'xl:-mt-4'
  | '2xl:-mt-4'
  | 'sm:-mt-5'
  | 'md:-mt-5'
  | 'lg:-mt-5'
  | 'xl:-mt-5'
  | '2xl:-mt-5'
  | 'sm:-mt-6'
  | 'md:-mt-6'
  | 'lg:-mt-6'
  | 'xl:-mt-6'
  | '2xl:-mt-6'
  | 'sm:-mt-7'
  | 'md:-mt-7'
  | 'lg:-mt-7'
  | 'xl:-mt-7'
  | '2xl:-mt-7'
  | 'sm:-mt-8'
  | 'md:-mt-8'
  | 'lg:-mt-8'
  | 'xl:-mt-8'
  | '2xl:-mt-8'
  | 'sm:-mt-9'
  | 'md:-mt-9'
  | 'lg:-mt-9'
  | 'xl:-mt-9'
  | '2xl:-mt-9'
  | 'sm:-mt-10'
  | 'md:-mt-10'
  | 'lg:-mt-10'
  | 'xl:-mt-10'
  | '2xl:-mt-10'
  | 'sm:-mt-11'
  | 'md:-mt-11'
  | 'lg:-mt-11'
  | 'xl:-mt-11'
  | '2xl:-mt-11'
  | 'sm:-mt-12'
  | 'md:-mt-12'
  | 'lg:-mt-12'
  | 'xl:-mt-12'
  | '2xl:-mt-12'
  | 'sm:-mt-14'
  | 'md:-mt-14'
  | 'lg:-mt-14'
  | 'xl:-mt-14'
  | '2xl:-mt-14'
  | 'sm:-mt-16'
  | 'md:-mt-16'
  | 'lg:-mt-16'
  | 'xl:-mt-16'
  | '2xl:-mt-16'
  | 'sm:-mt-20'
  | 'md:-mt-20'
  | 'lg:-mt-20'
  | 'xl:-mt-20'
  | '2xl:-mt-20'
  | 'sm:-mt-24'
  | 'md:-mt-24'
  | 'lg:-mt-24'
  | 'xl:-mt-24'
  | '2xl:-mt-24'
  | 'sm:-mt-28'
  | 'md:-mt-28'
  | 'lg:-mt-28'
  | 'xl:-mt-28'
  | '2xl:-mt-28'
  | 'sm:-mt-32'
  | 'md:-mt-32'
  | 'lg:-mt-32'
  | 'xl:-mt-32'
  | '2xl:-mt-32'
  | 'sm:-mt-36'
  | 'md:-mt-36'
  | 'lg:-mt-36'
  | 'xl:-mt-36'
  | '2xl:-mt-36'
  | 'sm:-mt-40'
  | 'md:-mt-40'
  | 'lg:-mt-40'
  | 'xl:-mt-40'
  | '2xl:-mt-40'
  | 'sm:-mt-44'
  | 'md:-mt-44'
  | 'lg:-mt-44'
  | 'xl:-mt-44'
  | '2xl:-mt-44'
  | 'sm:-mt-48'
  | 'md:-mt-48'
  | 'lg:-mt-48'
  | 'xl:-mt-48'
  | '2xl:-mt-48'
  | 'sm:-mt-52'
  | 'md:-mt-52'
  | 'lg:-mt-52'
  | 'xl:-mt-52'
  | '2xl:-mt-52'
  | 'sm:-mt-56'
  | 'md:-mt-56'
  | 'lg:-mt-56'
  | 'xl:-mt-56'
  | '2xl:-mt-56'
  | 'sm:-mt-60'
  | 'md:-mt-60'
  | 'lg:-mt-60'
  | 'xl:-mt-60'
  | '2xl:-mt-60'
  | 'sm:-mt-64'
  | 'md:-mt-64'
  | 'lg:-mt-64'
  | 'xl:-mt-64'
  | '2xl:-mt-64'
  | 'sm:-mt-72'
  | 'md:-mt-72'
  | 'lg:-mt-72'
  | 'xl:-mt-72'
  | '2xl:-mt-72'
  | 'sm:-mt-80'
  | 'md:-mt-80'
  | 'lg:-mt-80'
  | 'xl:-mt-80'
  | '2xl:-mt-80'
  | 'sm:-mt-96'
  | 'md:-mt-96'
  | 'lg:-mt-96'
  | 'xl:-mt-96'
  | '2xl:-mt-96'
  | 'sm:-mt-px'
  | 'md:-mt-px'
  | 'lg:-mt-px'
  | 'xl:-mt-px'
  | '2xl:-mt-px'
  | 'sm:-mt-0.5'
  | 'md:-mt-0.5'
  | 'lg:-mt-0.5'
  | 'xl:-mt-0.5'
  | '2xl:-mt-0.5'
  | 'sm:-mt-1.5'
  | 'md:-mt-1.5'
  | 'lg:-mt-1.5'
  | 'xl:-mt-1.5'
  | '2xl:-mt-1.5'
  | 'sm:-mt-2.5'
  | 'md:-mt-2.5'
  | 'lg:-mt-2.5'
  | 'xl:-mt-2.5'
  | '2xl:-mt-2.5'
  | 'sm:-mt-3.5'
  | 'md:-mt-3.5'
  | 'lg:-mt-3.5'
  | 'xl:-mt-3.5'
  | '2xl:-mt-3.5'
  | 'sm:mr-0'
  | 'md:mr-0'
  | 'lg:mr-0'
  | 'xl:mr-0'
  | '2xl:mr-0'
  | 'sm:mr-1'
  | 'md:mr-1'
  | 'lg:mr-1'
  | 'xl:mr-1'
  | '2xl:mr-1'
  | 'sm:mr-2'
  | 'md:mr-2'
  | 'lg:mr-2'
  | 'xl:mr-2'
  | '2xl:mr-2'
  | 'sm:mr-3'
  | 'md:mr-3'
  | 'lg:mr-3'
  | 'xl:mr-3'
  | '2xl:mr-3'
  | 'sm:mr-4'
  | 'md:mr-4'
  | 'lg:mr-4'
  | 'xl:mr-4'
  | '2xl:mr-4'
  | 'sm:mr-5'
  | 'md:mr-5'
  | 'lg:mr-5'
  | 'xl:mr-5'
  | '2xl:mr-5'
  | 'sm:mr-6'
  | 'md:mr-6'
  | 'lg:mr-6'
  | 'xl:mr-6'
  | '2xl:mr-6'
  | 'sm:mr-7'
  | 'md:mr-7'
  | 'lg:mr-7'
  | 'xl:mr-7'
  | '2xl:mr-7'
  | 'sm:mr-8'
  | 'md:mr-8'
  | 'lg:mr-8'
  | 'xl:mr-8'
  | '2xl:mr-8'
  | 'sm:mr-9'
  | 'md:mr-9'
  | 'lg:mr-9'
  | 'xl:mr-9'
  | '2xl:mr-9'
  | 'sm:mr-10'
  | 'md:mr-10'
  | 'lg:mr-10'
  | 'xl:mr-10'
  | '2xl:mr-10'
  | 'sm:mr-11'
  | 'md:mr-11'
  | 'lg:mr-11'
  | 'xl:mr-11'
  | '2xl:mr-11'
  | 'sm:mr-12'
  | 'md:mr-12'
  | 'lg:mr-12'
  | 'xl:mr-12'
  | '2xl:mr-12'
  | 'sm:mr-14'
  | 'md:mr-14'
  | 'lg:mr-14'
  | 'xl:mr-14'
  | '2xl:mr-14'
  | 'sm:mr-16'
  | 'md:mr-16'
  | 'lg:mr-16'
  | 'xl:mr-16'
  | '2xl:mr-16'
  | 'sm:mr-20'
  | 'md:mr-20'
  | 'lg:mr-20'
  | 'xl:mr-20'
  | '2xl:mr-20'
  | 'sm:mr-24'
  | 'md:mr-24'
  | 'lg:mr-24'
  | 'xl:mr-24'
  | '2xl:mr-24'
  | 'sm:mr-28'
  | 'md:mr-28'
  | 'lg:mr-28'
  | 'xl:mr-28'
  | '2xl:mr-28'
  | 'sm:mr-32'
  | 'md:mr-32'
  | 'lg:mr-32'
  | 'xl:mr-32'
  | '2xl:mr-32'
  | 'sm:mr-36'
  | 'md:mr-36'
  | 'lg:mr-36'
  | 'xl:mr-36'
  | '2xl:mr-36'
  | 'sm:mr-40'
  | 'md:mr-40'
  | 'lg:mr-40'
  | 'xl:mr-40'
  | '2xl:mr-40'
  | 'sm:mr-44'
  | 'md:mr-44'
  | 'lg:mr-44'
  | 'xl:mr-44'
  | '2xl:mr-44'
  | 'sm:mr-48'
  | 'md:mr-48'
  | 'lg:mr-48'
  | 'xl:mr-48'
  | '2xl:mr-48'
  | 'sm:mr-52'
  | 'md:mr-52'
  | 'lg:mr-52'
  | 'xl:mr-52'
  | '2xl:mr-52'
  | 'sm:mr-56'
  | 'md:mr-56'
  | 'lg:mr-56'
  | 'xl:mr-56'
  | '2xl:mr-56'
  | 'sm:mr-60'
  | 'md:mr-60'
  | 'lg:mr-60'
  | 'xl:mr-60'
  | '2xl:mr-60'
  | 'sm:mr-64'
  | 'md:mr-64'
  | 'lg:mr-64'
  | 'xl:mr-64'
  | '2xl:mr-64'
  | 'sm:mr-72'
  | 'md:mr-72'
  | 'lg:mr-72'
  | 'xl:mr-72'
  | '2xl:mr-72'
  | 'sm:mr-80'
  | 'md:mr-80'
  | 'lg:mr-80'
  | 'xl:mr-80'
  | '2xl:mr-80'
  | 'sm:mr-96'
  | 'md:mr-96'
  | 'lg:mr-96'
  | 'xl:mr-96'
  | '2xl:mr-96'
  | 'sm:mr-auto'
  | 'md:mr-auto'
  | 'lg:mr-auto'
  | 'xl:mr-auto'
  | '2xl:mr-auto'
  | 'sm:mr-px'
  | 'md:mr-px'
  | 'lg:mr-px'
  | 'xl:mr-px'
  | '2xl:mr-px'
  | 'sm:mr-0.5'
  | 'md:mr-0.5'
  | 'lg:mr-0.5'
  | 'xl:mr-0.5'
  | '2xl:mr-0.5'
  | 'sm:mr-1.5'
  | 'md:mr-1.5'
  | 'lg:mr-1.5'
  | 'xl:mr-1.5'
  | '2xl:mr-1.5'
  | 'sm:mr-2.5'
  | 'md:mr-2.5'
  | 'lg:mr-2.5'
  | 'xl:mr-2.5'
  | '2xl:mr-2.5'
  | 'sm:mr-3.5'
  | 'md:mr-3.5'
  | 'lg:mr-3.5'
  | 'xl:mr-3.5'
  | '2xl:mr-3.5'
  | 'sm:-mr-0'
  | 'md:-mr-0'
  | 'lg:-mr-0'
  | 'xl:-mr-0'
  | '2xl:-mr-0'
  | 'sm:-mr-1'
  | 'md:-mr-1'
  | 'lg:-mr-1'
  | 'xl:-mr-1'
  | '2xl:-mr-1'
  | 'sm:-mr-2'
  | 'md:-mr-2'
  | 'lg:-mr-2'
  | 'xl:-mr-2'
  | '2xl:-mr-2'
  | 'sm:-mr-3'
  | 'md:-mr-3'
  | 'lg:-mr-3'
  | 'xl:-mr-3'
  | '2xl:-mr-3'
  | 'sm:-mr-4'
  | 'md:-mr-4'
  | 'lg:-mr-4'
  | 'xl:-mr-4'
  | '2xl:-mr-4'
  | 'sm:-mr-5'
  | 'md:-mr-5'
  | 'lg:-mr-5'
  | 'xl:-mr-5'
  | '2xl:-mr-5'
  | 'sm:-mr-6'
  | 'md:-mr-6'
  | 'lg:-mr-6'
  | 'xl:-mr-6'
  | '2xl:-mr-6'
  | 'sm:-mr-7'
  | 'md:-mr-7'
  | 'lg:-mr-7'
  | 'xl:-mr-7'
  | '2xl:-mr-7'
  | 'sm:-mr-8'
  | 'md:-mr-8'
  | 'lg:-mr-8'
  | 'xl:-mr-8'
  | '2xl:-mr-8'
  | 'sm:-mr-9'
  | 'md:-mr-9'
  | 'lg:-mr-9'
  | 'xl:-mr-9'
  | '2xl:-mr-9'
  | 'sm:-mr-10'
  | 'md:-mr-10'
  | 'lg:-mr-10'
  | 'xl:-mr-10'
  | '2xl:-mr-10'
  | 'sm:-mr-11'
  | 'md:-mr-11'
  | 'lg:-mr-11'
  | 'xl:-mr-11'
  | '2xl:-mr-11'
  | 'sm:-mr-12'
  | 'md:-mr-12'
  | 'lg:-mr-12'
  | 'xl:-mr-12'
  | '2xl:-mr-12'
  | 'sm:-mr-14'
  | 'md:-mr-14'
  | 'lg:-mr-14'
  | 'xl:-mr-14'
  | '2xl:-mr-14'
  | 'sm:-mr-16'
  | 'md:-mr-16'
  | 'lg:-mr-16'
  | 'xl:-mr-16'
  | '2xl:-mr-16'
  | 'sm:-mr-20'
  | 'md:-mr-20'
  | 'lg:-mr-20'
  | 'xl:-mr-20'
  | '2xl:-mr-20'
  | 'sm:-mr-24'
  | 'md:-mr-24'
  | 'lg:-mr-24'
  | 'xl:-mr-24'
  | '2xl:-mr-24'
  | 'sm:-mr-28'
  | 'md:-mr-28'
  | 'lg:-mr-28'
  | 'xl:-mr-28'
  | '2xl:-mr-28'
  | 'sm:-mr-32'
  | 'md:-mr-32'
  | 'lg:-mr-32'
  | 'xl:-mr-32'
  | '2xl:-mr-32'
  | 'sm:-mr-36'
  | 'md:-mr-36'
  | 'lg:-mr-36'
  | 'xl:-mr-36'
  | '2xl:-mr-36'
  | 'sm:-mr-40'
  | 'md:-mr-40'
  | 'lg:-mr-40'
  | 'xl:-mr-40'
  | '2xl:-mr-40'
  | 'sm:-mr-44'
  | 'md:-mr-44'
  | 'lg:-mr-44'
  | 'xl:-mr-44'
  | '2xl:-mr-44'
  | 'sm:-mr-48'
  | 'md:-mr-48'
  | 'lg:-mr-48'
  | 'xl:-mr-48'
  | '2xl:-mr-48'
  | 'sm:-mr-52'
  | 'md:-mr-52'
  | 'lg:-mr-52'
  | 'xl:-mr-52'
  | '2xl:-mr-52'
  | 'sm:-mr-56'
  | 'md:-mr-56'
  | 'lg:-mr-56'
  | 'xl:-mr-56'
  | '2xl:-mr-56'
  | 'sm:-mr-60'
  | 'md:-mr-60'
  | 'lg:-mr-60'
  | 'xl:-mr-60'
  | '2xl:-mr-60'
  | 'sm:-mr-64'
  | 'md:-mr-64'
  | 'lg:-mr-64'
  | 'xl:-mr-64'
  | '2xl:-mr-64'
  | 'sm:-mr-72'
  | 'md:-mr-72'
  | 'lg:-mr-72'
  | 'xl:-mr-72'
  | '2xl:-mr-72'
  | 'sm:-mr-80'
  | 'md:-mr-80'
  | 'lg:-mr-80'
  | 'xl:-mr-80'
  | '2xl:-mr-80'
  | 'sm:-mr-96'
  | 'md:-mr-96'
  | 'lg:-mr-96'
  | 'xl:-mr-96'
  | '2xl:-mr-96'
  | 'sm:-mr-px'
  | 'md:-mr-px'
  | 'lg:-mr-px'
  | 'xl:-mr-px'
  | '2xl:-mr-px'
  | 'sm:-mr-0.5'
  | 'md:-mr-0.5'
  | 'lg:-mr-0.5'
  | 'xl:-mr-0.5'
  | '2xl:-mr-0.5'
  | 'sm:-mr-1.5'
  | 'md:-mr-1.5'
  | 'lg:-mr-1.5'
  | 'xl:-mr-1.5'
  | '2xl:-mr-1.5'
  | 'sm:-mr-2.5'
  | 'md:-mr-2.5'
  | 'lg:-mr-2.5'
  | 'xl:-mr-2.5'
  | '2xl:-mr-2.5'
  | 'sm:-mr-3.5'
  | 'md:-mr-3.5'
  | 'lg:-mr-3.5'
  | 'xl:-mr-3.5'
  | '2xl:-mr-3.5'
  | 'sm:mb-0'
  | 'md:mb-0'
  | 'lg:mb-0'
  | 'xl:mb-0'
  | '2xl:mb-0'
  | 'sm:mb-1'
  | 'md:mb-1'
  | 'lg:mb-1'
  | 'xl:mb-1'
  | '2xl:mb-1'
  | 'sm:mb-2'
  | 'md:mb-2'
  | 'lg:mb-2'
  | 'xl:mb-2'
  | '2xl:mb-2'
  | 'sm:mb-3'
  | 'md:mb-3'
  | 'lg:mb-3'
  | 'xl:mb-3'
  | '2xl:mb-3'
  | 'sm:mb-4'
  | 'md:mb-4'
  | 'lg:mb-4'
  | 'xl:mb-4'
  | '2xl:mb-4'
  | 'sm:mb-5'
  | 'md:mb-5'
  | 'lg:mb-5'
  | 'xl:mb-5'
  | '2xl:mb-5'
  | 'sm:mb-6'
  | 'md:mb-6'
  | 'lg:mb-6'
  | 'xl:mb-6'
  | '2xl:mb-6'
  | 'sm:mb-7'
  | 'md:mb-7'
  | 'lg:mb-7'
  | 'xl:mb-7'
  | '2xl:mb-7'
  | 'sm:mb-8'
  | 'md:mb-8'
  | 'lg:mb-8'
  | 'xl:mb-8'
  | '2xl:mb-8'
  | 'sm:mb-9'
  | 'md:mb-9'
  | 'lg:mb-9'
  | 'xl:mb-9'
  | '2xl:mb-9'
  | 'sm:mb-10'
  | 'md:mb-10'
  | 'lg:mb-10'
  | 'xl:mb-10'
  | '2xl:mb-10'
  | 'sm:mb-11'
  | 'md:mb-11'
  | 'lg:mb-11'
  | 'xl:mb-11'
  | '2xl:mb-11'
  | 'sm:mb-12'
  | 'md:mb-12'
  | 'lg:mb-12'
  | 'xl:mb-12'
  | '2xl:mb-12'
  | 'sm:mb-14'
  | 'md:mb-14'
  | 'lg:mb-14'
  | 'xl:mb-14'
  | '2xl:mb-14'
  | 'sm:mb-16'
  | 'md:mb-16'
  | 'lg:mb-16'
  | 'xl:mb-16'
  | '2xl:mb-16'
  | 'sm:mb-20'
  | 'md:mb-20'
  | 'lg:mb-20'
  | 'xl:mb-20'
  | '2xl:mb-20'
  | 'sm:mb-24'
  | 'md:mb-24'
  | 'lg:mb-24'
  | 'xl:mb-24'
  | '2xl:mb-24'
  | 'sm:mb-28'
  | 'md:mb-28'
  | 'lg:mb-28'
  | 'xl:mb-28'
  | '2xl:mb-28'
  | 'sm:mb-32'
  | 'md:mb-32'
  | 'lg:mb-32'
  | 'xl:mb-32'
  | '2xl:mb-32'
  | 'sm:mb-36'
  | 'md:mb-36'
  | 'lg:mb-36'
  | 'xl:mb-36'
  | '2xl:mb-36'
  | 'sm:mb-40'
  | 'md:mb-40'
  | 'lg:mb-40'
  | 'xl:mb-40'
  | '2xl:mb-40'
  | 'sm:mb-44'
  | 'md:mb-44'
  | 'lg:mb-44'
  | 'xl:mb-44'
  | '2xl:mb-44'
  | 'sm:mb-48'
  | 'md:mb-48'
  | 'lg:mb-48'
  | 'xl:mb-48'
  | '2xl:mb-48'
  | 'sm:mb-52'
  | 'md:mb-52'
  | 'lg:mb-52'
  | 'xl:mb-52'
  | '2xl:mb-52'
  | 'sm:mb-56'
  | 'md:mb-56'
  | 'lg:mb-56'
  | 'xl:mb-56'
  | '2xl:mb-56'
  | 'sm:mb-60'
  | 'md:mb-60'
  | 'lg:mb-60'
  | 'xl:mb-60'
  | '2xl:mb-60'
  | 'sm:mb-64'
  | 'md:mb-64'
  | 'lg:mb-64'
  | 'xl:mb-64'
  | '2xl:mb-64'
  | 'sm:mb-72'
  | 'md:mb-72'
  | 'lg:mb-72'
  | 'xl:mb-72'
  | '2xl:mb-72'
  | 'sm:mb-80'
  | 'md:mb-80'
  | 'lg:mb-80'
  | 'xl:mb-80'
  | '2xl:mb-80'
  | 'sm:mb-96'
  | 'md:mb-96'
  | 'lg:mb-96'
  | 'xl:mb-96'
  | '2xl:mb-96'
  | 'sm:mb-auto'
  | 'md:mb-auto'
  | 'lg:mb-auto'
  | 'xl:mb-auto'
  | '2xl:mb-auto'
  | 'sm:mb-px'
  | 'md:mb-px'
  | 'lg:mb-px'
  | 'xl:mb-px'
  | '2xl:mb-px'
  | 'sm:mb-0.5'
  | 'md:mb-0.5'
  | 'lg:mb-0.5'
  | 'xl:mb-0.5'
  | '2xl:mb-0.5'
  | 'sm:mb-1.5'
  | 'md:mb-1.5'
  | 'lg:mb-1.5'
  | 'xl:mb-1.5'
  | '2xl:mb-1.5'
  | 'sm:mb-2.5'
  | 'md:mb-2.5'
  | 'lg:mb-2.5'
  | 'xl:mb-2.5'
  | '2xl:mb-2.5'
  | 'sm:mb-3.5'
  | 'md:mb-3.5'
  | 'lg:mb-3.5'
  | 'xl:mb-3.5'
  | '2xl:mb-3.5'
  | 'sm:-mb-0'
  | 'md:-mb-0'
  | 'lg:-mb-0'
  | 'xl:-mb-0'
  | '2xl:-mb-0'
  | 'sm:-mb-1'
  | 'md:-mb-1'
  | 'lg:-mb-1'
  | 'xl:-mb-1'
  | '2xl:-mb-1'
  | 'sm:-mb-2'
  | 'md:-mb-2'
  | 'lg:-mb-2'
  | 'xl:-mb-2'
  | '2xl:-mb-2'
  | 'sm:-mb-3'
  | 'md:-mb-3'
  | 'lg:-mb-3'
  | 'xl:-mb-3'
  | '2xl:-mb-3'
  | 'sm:-mb-4'
  | 'md:-mb-4'
  | 'lg:-mb-4'
  | 'xl:-mb-4'
  | '2xl:-mb-4'
  | 'sm:-mb-5'
  | 'md:-mb-5'
  | 'lg:-mb-5'
  | 'xl:-mb-5'
  | '2xl:-mb-5'
  | 'sm:-mb-6'
  | 'md:-mb-6'
  | 'lg:-mb-6'
  | 'xl:-mb-6'
  | '2xl:-mb-6'
  | 'sm:-mb-7'
  | 'md:-mb-7'
  | 'lg:-mb-7'
  | 'xl:-mb-7'
  | '2xl:-mb-7'
  | 'sm:-mb-8'
  | 'md:-mb-8'
  | 'lg:-mb-8'
  | 'xl:-mb-8'
  | '2xl:-mb-8'
  | 'sm:-mb-9'
  | 'md:-mb-9'
  | 'lg:-mb-9'
  | 'xl:-mb-9'
  | '2xl:-mb-9'
  | 'sm:-mb-10'
  | 'md:-mb-10'
  | 'lg:-mb-10'
  | 'xl:-mb-10'
  | '2xl:-mb-10'
  | 'sm:-mb-11'
  | 'md:-mb-11'
  | 'lg:-mb-11'
  | 'xl:-mb-11'
  | '2xl:-mb-11'
  | 'sm:-mb-12'
  | 'md:-mb-12'
  | 'lg:-mb-12'
  | 'xl:-mb-12'
  | '2xl:-mb-12'
  | 'sm:-mb-14'
  | 'md:-mb-14'
  | 'lg:-mb-14'
  | 'xl:-mb-14'
  | '2xl:-mb-14'
  | 'sm:-mb-16'
  | 'md:-mb-16'
  | 'lg:-mb-16'
  | 'xl:-mb-16'
  | '2xl:-mb-16'
  | 'sm:-mb-20'
  | 'md:-mb-20'
  | 'lg:-mb-20'
  | 'xl:-mb-20'
  | '2xl:-mb-20'
  | 'sm:-mb-24'
  | 'md:-mb-24'
  | 'lg:-mb-24'
  | 'xl:-mb-24'
  | '2xl:-mb-24'
  | 'sm:-mb-28'
  | 'md:-mb-28'
  | 'lg:-mb-28'
  | 'xl:-mb-28'
  | '2xl:-mb-28'
  | 'sm:-mb-32'
  | 'md:-mb-32'
  | 'lg:-mb-32'
  | 'xl:-mb-32'
  | '2xl:-mb-32'
  | 'sm:-mb-36'
  | 'md:-mb-36'
  | 'lg:-mb-36'
  | 'xl:-mb-36'
  | '2xl:-mb-36'
  | 'sm:-mb-40'
  | 'md:-mb-40'
  | 'lg:-mb-40'
  | 'xl:-mb-40'
  | '2xl:-mb-40'
  | 'sm:-mb-44'
  | 'md:-mb-44'
  | 'lg:-mb-44'
  | 'xl:-mb-44'
  | '2xl:-mb-44'
  | 'sm:-mb-48'
  | 'md:-mb-48'
  | 'lg:-mb-48'
  | 'xl:-mb-48'
  | '2xl:-mb-48'
  | 'sm:-mb-52'
  | 'md:-mb-52'
  | 'lg:-mb-52'
  | 'xl:-mb-52'
  | '2xl:-mb-52'
  | 'sm:-mb-56'
  | 'md:-mb-56'
  | 'lg:-mb-56'
  | 'xl:-mb-56'
  | '2xl:-mb-56'
  | 'sm:-mb-60'
  | 'md:-mb-60'
  | 'lg:-mb-60'
  | 'xl:-mb-60'
  | '2xl:-mb-60'
  | 'sm:-mb-64'
  | 'md:-mb-64'
  | 'lg:-mb-64'
  | 'xl:-mb-64'
  | '2xl:-mb-64'
  | 'sm:-mb-72'
  | 'md:-mb-72'
  | 'lg:-mb-72'
  | 'xl:-mb-72'
  | '2xl:-mb-72'
  | 'sm:-mb-80'
  | 'md:-mb-80'
  | 'lg:-mb-80'
  | 'xl:-mb-80'
  | '2xl:-mb-80'
  | 'sm:-mb-96'
  | 'md:-mb-96'
  | 'lg:-mb-96'
  | 'xl:-mb-96'
  | '2xl:-mb-96'
  | 'sm:-mb-px'
  | 'md:-mb-px'
  | 'lg:-mb-px'
  | 'xl:-mb-px'
  | '2xl:-mb-px'
  | 'sm:-mb-0.5'
  | 'md:-mb-0.5'
  | 'lg:-mb-0.5'
  | 'xl:-mb-0.5'
  | '2xl:-mb-0.5'
  | 'sm:-mb-1.5'
  | 'md:-mb-1.5'
  | 'lg:-mb-1.5'
  | 'xl:-mb-1.5'
  | '2xl:-mb-1.5'
  | 'sm:-mb-2.5'
  | 'md:-mb-2.5'
  | 'lg:-mb-2.5'
  | 'xl:-mb-2.5'
  | '2xl:-mb-2.5'
  | 'sm:-mb-3.5'
  | 'md:-mb-3.5'
  | 'lg:-mb-3.5'
  | 'xl:-mb-3.5'
  | '2xl:-mb-3.5'
  | 'sm:ml-0'
  | 'md:ml-0'
  | 'lg:ml-0'
  | 'xl:ml-0'
  | '2xl:ml-0'
  | 'sm:ml-1'
  | 'md:ml-1'
  | 'lg:ml-1'
  | 'xl:ml-1'
  | '2xl:ml-1'
  | 'sm:ml-2'
  | 'md:ml-2'
  | 'lg:ml-2'
  | 'xl:ml-2'
  | '2xl:ml-2'
  | 'sm:ml-3'
  | 'md:ml-3'
  | 'lg:ml-3'
  | 'xl:ml-3'
  | '2xl:ml-3'
  | 'sm:ml-4'
  | 'md:ml-4'
  | 'lg:ml-4'
  | 'xl:ml-4'
  | '2xl:ml-4'
  | 'sm:ml-5'
  | 'md:ml-5'
  | 'lg:ml-5'
  | 'xl:ml-5'
  | '2xl:ml-5'
  | 'sm:ml-6'
  | 'md:ml-6'
  | 'lg:ml-6'
  | 'xl:ml-6'
  | '2xl:ml-6'
  | 'sm:ml-7'
  | 'md:ml-7'
  | 'lg:ml-7'
  | 'xl:ml-7'
  | '2xl:ml-7'
  | 'sm:ml-8'
  | 'md:ml-8'
  | 'lg:ml-8'
  | 'xl:ml-8'
  | '2xl:ml-8'
  | 'sm:ml-9'
  | 'md:ml-9'
  | 'lg:ml-9'
  | 'xl:ml-9'
  | '2xl:ml-9'
  | 'sm:ml-10'
  | 'md:ml-10'
  | 'lg:ml-10'
  | 'xl:ml-10'
  | '2xl:ml-10'
  | 'sm:ml-11'
  | 'md:ml-11'
  | 'lg:ml-11'
  | 'xl:ml-11'
  | '2xl:ml-11'
  | 'sm:ml-12'
  | 'md:ml-12'
  | 'lg:ml-12'
  | 'xl:ml-12'
  | '2xl:ml-12'
  | 'sm:ml-14'
  | 'md:ml-14'
  | 'lg:ml-14'
  | 'xl:ml-14'
  | '2xl:ml-14'
  | 'sm:ml-16'
  | 'md:ml-16'
  | 'lg:ml-16'
  | 'xl:ml-16'
  | '2xl:ml-16'
  | 'sm:ml-20'
  | 'md:ml-20'
  | 'lg:ml-20'
  | 'xl:ml-20'
  | '2xl:ml-20'
  | 'sm:ml-24'
  | 'md:ml-24'
  | 'lg:ml-24'
  | 'xl:ml-24'
  | '2xl:ml-24'
  | 'sm:ml-28'
  | 'md:ml-28'
  | 'lg:ml-28'
  | 'xl:ml-28'
  | '2xl:ml-28'
  | 'sm:ml-32'
  | 'md:ml-32'
  | 'lg:ml-32'
  | 'xl:ml-32'
  | '2xl:ml-32'
  | 'sm:ml-36'
  | 'md:ml-36'
  | 'lg:ml-36'
  | 'xl:ml-36'
  | '2xl:ml-36'
  | 'sm:ml-40'
  | 'md:ml-40'
  | 'lg:ml-40'
  | 'xl:ml-40'
  | '2xl:ml-40'
  | 'sm:ml-44'
  | 'md:ml-44'
  | 'lg:ml-44'
  | 'xl:ml-44'
  | '2xl:ml-44'
  | 'sm:ml-48'
  | 'md:ml-48'
  | 'lg:ml-48'
  | 'xl:ml-48'
  | '2xl:ml-48'
  | 'sm:ml-52'
  | 'md:ml-52'
  | 'lg:ml-52'
  | 'xl:ml-52'
  | '2xl:ml-52'
  | 'sm:ml-56'
  | 'md:ml-56'
  | 'lg:ml-56'
  | 'xl:ml-56'
  | '2xl:ml-56'
  | 'sm:ml-60'
  | 'md:ml-60'
  | 'lg:ml-60'
  | 'xl:ml-60'
  | '2xl:ml-60'
  | 'sm:ml-64'
  | 'md:ml-64'
  | 'lg:ml-64'
  | 'xl:ml-64'
  | '2xl:ml-64'
  | 'sm:ml-72'
  | 'md:ml-72'
  | 'lg:ml-72'
  | 'xl:ml-72'
  | '2xl:ml-72'
  | 'sm:ml-80'
  | 'md:ml-80'
  | 'lg:ml-80'
  | 'xl:ml-80'
  | '2xl:ml-80'
  | 'sm:ml-96'
  | 'md:ml-96'
  | 'lg:ml-96'
  | 'xl:ml-96'
  | '2xl:ml-96'
  | 'sm:ml-auto'
  | 'md:ml-auto'
  | 'lg:ml-auto'
  | 'xl:ml-auto'
  | '2xl:ml-auto'
  | 'sm:ml-px'
  | 'md:ml-px'
  | 'lg:ml-px'
  | 'xl:ml-px'
  | '2xl:ml-px'
  | 'sm:ml-0.5'
  | 'md:ml-0.5'
  | 'lg:ml-0.5'
  | 'xl:ml-0.5'
  | '2xl:ml-0.5'
  | 'sm:ml-1.5'
  | 'md:ml-1.5'
  | 'lg:ml-1.5'
  | 'xl:ml-1.5'
  | '2xl:ml-1.5'
  | 'sm:ml-2.5'
  | 'md:ml-2.5'
  | 'lg:ml-2.5'
  | 'xl:ml-2.5'
  | '2xl:ml-2.5'
  | 'sm:ml-3.5'
  | 'md:ml-3.5'
  | 'lg:ml-3.5'
  | 'xl:ml-3.5'
  | '2xl:ml-3.5'
  | 'sm:-ml-0'
  | 'md:-ml-0'
  | 'lg:-ml-0'
  | 'xl:-ml-0'
  | '2xl:-ml-0'
  | 'sm:-ml-1'
  | 'md:-ml-1'
  | 'lg:-ml-1'
  | 'xl:-ml-1'
  | '2xl:-ml-1'
  | 'sm:-ml-2'
  | 'md:-ml-2'
  | 'lg:-ml-2'
  | 'xl:-ml-2'
  | '2xl:-ml-2'
  | 'sm:-ml-3'
  | 'md:-ml-3'
  | 'lg:-ml-3'
  | 'xl:-ml-3'
  | '2xl:-ml-3'
  | 'sm:-ml-4'
  | 'md:-ml-4'
  | 'lg:-ml-4'
  | 'xl:-ml-4'
  | '2xl:-ml-4'
  | 'sm:-ml-5'
  | 'md:-ml-5'
  | 'lg:-ml-5'
  | 'xl:-ml-5'
  | '2xl:-ml-5'
  | 'sm:-ml-6'
  | 'md:-ml-6'
  | 'lg:-ml-6'
  | 'xl:-ml-6'
  | '2xl:-ml-6'
  | 'sm:-ml-7'
  | 'md:-ml-7'
  | 'lg:-ml-7'
  | 'xl:-ml-7'
  | '2xl:-ml-7'
  | 'sm:-ml-8'
  | 'md:-ml-8'
  | 'lg:-ml-8'
  | 'xl:-ml-8'
  | '2xl:-ml-8'
  | 'sm:-ml-9'
  | 'md:-ml-9'
  | 'lg:-ml-9'
  | 'xl:-ml-9'
  | '2xl:-ml-9'
  | 'sm:-ml-10'
  | 'md:-ml-10'
  | 'lg:-ml-10'
  | 'xl:-ml-10'
  | '2xl:-ml-10'
  | 'sm:-ml-11'
  | 'md:-ml-11'
  | 'lg:-ml-11'
  | 'xl:-ml-11'
  | '2xl:-ml-11'
  | 'sm:-ml-12'
  | 'md:-ml-12'
  | 'lg:-ml-12'
  | 'xl:-ml-12'
  | '2xl:-ml-12'
  | 'sm:-ml-14'
  | 'md:-ml-14'
  | 'lg:-ml-14'
  | 'xl:-ml-14'
  | '2xl:-ml-14'
  | 'sm:-ml-16'
  | 'md:-ml-16'
  | 'lg:-ml-16'
  | 'xl:-ml-16'
  | '2xl:-ml-16'
  | 'sm:-ml-20'
  | 'md:-ml-20'
  | 'lg:-ml-20'
  | 'xl:-ml-20'
  | '2xl:-ml-20'
  | 'sm:-ml-24'
  | 'md:-ml-24'
  | 'lg:-ml-24'
  | 'xl:-ml-24'
  | '2xl:-ml-24'
  | 'sm:-ml-28'
  | 'md:-ml-28'
  | 'lg:-ml-28'
  | 'xl:-ml-28'
  | '2xl:-ml-28'
  | 'sm:-ml-32'
  | 'md:-ml-32'
  | 'lg:-ml-32'
  | 'xl:-ml-32'
  | '2xl:-ml-32'
  | 'sm:-ml-36'
  | 'md:-ml-36'
  | 'lg:-ml-36'
  | 'xl:-ml-36'
  | '2xl:-ml-36'
  | 'sm:-ml-40'
  | 'md:-ml-40'
  | 'lg:-ml-40'
  | 'xl:-ml-40'
  | '2xl:-ml-40'
  | 'sm:-ml-44'
  | 'md:-ml-44'
  | 'lg:-ml-44'
  | 'xl:-ml-44'
  | '2xl:-ml-44'
  | 'sm:-ml-48'
  | 'md:-ml-48'
  | 'lg:-ml-48'
  | 'xl:-ml-48'
  | '2xl:-ml-48'
  | 'sm:-ml-52'
  | 'md:-ml-52'
  | 'lg:-ml-52'
  | 'xl:-ml-52'
  | '2xl:-ml-52'
  | 'sm:-ml-56'
  | 'md:-ml-56'
  | 'lg:-ml-56'
  | 'xl:-ml-56'
  | '2xl:-ml-56'
  | 'sm:-ml-60'
  | 'md:-ml-60'
  | 'lg:-ml-60'
  | 'xl:-ml-60'
  | '2xl:-ml-60'
  | 'sm:-ml-64'
  | 'md:-ml-64'
  | 'lg:-ml-64'
  | 'xl:-ml-64'
  | '2xl:-ml-64'
  | 'sm:-ml-72'
  | 'md:-ml-72'
  | 'lg:-ml-72'
  | 'xl:-ml-72'
  | '2xl:-ml-72'
  | 'sm:-ml-80'
  | 'md:-ml-80'
  | 'lg:-ml-80'
  | 'xl:-ml-80'
  | '2xl:-ml-80'
  | 'sm:-ml-96'
  | 'md:-ml-96'
  | 'lg:-ml-96'
  | 'xl:-ml-96'
  | '2xl:-ml-96'
  | 'sm:-ml-px'
  | 'md:-ml-px'
  | 'lg:-ml-px'
  | 'xl:-ml-px'
  | '2xl:-ml-px'
  | 'sm:-ml-0.5'
  | 'md:-ml-0.5'
  | 'lg:-ml-0.5'
  | 'xl:-ml-0.5'
  | '2xl:-ml-0.5'
  | 'sm:-ml-1.5'
  | 'md:-ml-1.5'
  | 'lg:-ml-1.5'
  | 'xl:-ml-1.5'
  | '2xl:-ml-1.5'
  | 'sm:-ml-2.5'
  | 'md:-ml-2.5'
  | 'lg:-ml-2.5'
  | 'xl:-ml-2.5'
  | '2xl:-ml-2.5'
  | 'sm:-ml-3.5'
  | 'md:-ml-3.5'
  | 'lg:-ml-3.5'
  | 'xl:-ml-3.5'
  | '2xl:-ml-3.5'
  | 'sm:max-h-0'
  | 'md:max-h-0'
  | 'lg:max-h-0'
  | 'xl:max-h-0'
  | '2xl:max-h-0'
  | 'sm:max-h-1'
  | 'md:max-h-1'
  | 'lg:max-h-1'
  | 'xl:max-h-1'
  | '2xl:max-h-1'
  | 'sm:max-h-2'
  | 'md:max-h-2'
  | 'lg:max-h-2'
  | 'xl:max-h-2'
  | '2xl:max-h-2'
  | 'sm:max-h-3'
  | 'md:max-h-3'
  | 'lg:max-h-3'
  | 'xl:max-h-3'
  | '2xl:max-h-3'
  | 'sm:max-h-4'
  | 'md:max-h-4'
  | 'lg:max-h-4'
  | 'xl:max-h-4'
  | '2xl:max-h-4'
  | 'sm:max-h-5'
  | 'md:max-h-5'
  | 'lg:max-h-5'
  | 'xl:max-h-5'
  | '2xl:max-h-5'
  | 'sm:max-h-6'
  | 'md:max-h-6'
  | 'lg:max-h-6'
  | 'xl:max-h-6'
  | '2xl:max-h-6'
  | 'sm:max-h-7'
  | 'md:max-h-7'
  | 'lg:max-h-7'
  | 'xl:max-h-7'
  | '2xl:max-h-7'
  | 'sm:max-h-8'
  | 'md:max-h-8'
  | 'lg:max-h-8'
  | 'xl:max-h-8'
  | '2xl:max-h-8'
  | 'sm:max-h-9'
  | 'md:max-h-9'
  | 'lg:max-h-9'
  | 'xl:max-h-9'
  | '2xl:max-h-9'
  | 'sm:max-h-10'
  | 'md:max-h-10'
  | 'lg:max-h-10'
  | 'xl:max-h-10'
  | '2xl:max-h-10'
  | 'sm:max-h-11'
  | 'md:max-h-11'
  | 'lg:max-h-11'
  | 'xl:max-h-11'
  | '2xl:max-h-11'
  | 'sm:max-h-12'
  | 'md:max-h-12'
  | 'lg:max-h-12'
  | 'xl:max-h-12'
  | '2xl:max-h-12'
  | 'sm:max-h-14'
  | 'md:max-h-14'
  | 'lg:max-h-14'
  | 'xl:max-h-14'
  | '2xl:max-h-14'
  | 'sm:max-h-16'
  | 'md:max-h-16'
  | 'lg:max-h-16'
  | 'xl:max-h-16'
  | '2xl:max-h-16'
  | 'sm:max-h-20'
  | 'md:max-h-20'
  | 'lg:max-h-20'
  | 'xl:max-h-20'
  | '2xl:max-h-20'
  | 'sm:max-h-24'
  | 'md:max-h-24'
  | 'lg:max-h-24'
  | 'xl:max-h-24'
  | '2xl:max-h-24'
  | 'sm:max-h-28'
  | 'md:max-h-28'
  | 'lg:max-h-28'
  | 'xl:max-h-28'
  | '2xl:max-h-28'
  | 'sm:max-h-32'
  | 'md:max-h-32'
  | 'lg:max-h-32'
  | 'xl:max-h-32'
  | '2xl:max-h-32'
  | 'sm:max-h-36'
  | 'md:max-h-36'
  | 'lg:max-h-36'
  | 'xl:max-h-36'
  | '2xl:max-h-36'
  | 'sm:max-h-40'
  | 'md:max-h-40'
  | 'lg:max-h-40'
  | 'xl:max-h-40'
  | '2xl:max-h-40'
  | 'sm:max-h-44'
  | 'md:max-h-44'
  | 'lg:max-h-44'
  | 'xl:max-h-44'
  | '2xl:max-h-44'
  | 'sm:max-h-48'
  | 'md:max-h-48'
  | 'lg:max-h-48'
  | 'xl:max-h-48'
  | '2xl:max-h-48'
  | 'sm:max-h-52'
  | 'md:max-h-52'
  | 'lg:max-h-52'
  | 'xl:max-h-52'
  | '2xl:max-h-52'
  | 'sm:max-h-56'
  | 'md:max-h-56'
  | 'lg:max-h-56'
  | 'xl:max-h-56'
  | '2xl:max-h-56'
  | 'sm:max-h-60'
  | 'md:max-h-60'
  | 'lg:max-h-60'
  | 'xl:max-h-60'
  | '2xl:max-h-60'
  | 'sm:max-h-64'
  | 'md:max-h-64'
  | 'lg:max-h-64'
  | 'xl:max-h-64'
  | '2xl:max-h-64'
  | 'sm:max-h-72'
  | 'md:max-h-72'
  | 'lg:max-h-72'
  | 'xl:max-h-72'
  | '2xl:max-h-72'
  | 'sm:max-h-80'
  | 'md:max-h-80'
  | 'lg:max-h-80'
  | 'xl:max-h-80'
  | '2xl:max-h-80'
  | 'sm:max-h-96'
  | 'md:max-h-96'
  | 'lg:max-h-96'
  | 'xl:max-h-96'
  | '2xl:max-h-96'
  | 'sm:max-h-px'
  | 'md:max-h-px'
  | 'lg:max-h-px'
  | 'xl:max-h-px'
  | '2xl:max-h-px'
  | 'sm:max-h-0.5'
  | 'md:max-h-0.5'
  | 'lg:max-h-0.5'
  | 'xl:max-h-0.5'
  | '2xl:max-h-0.5'
  | 'sm:max-h-1.5'
  | 'md:max-h-1.5'
  | 'lg:max-h-1.5'
  | 'xl:max-h-1.5'
  | '2xl:max-h-1.5'
  | 'sm:max-h-2.5'
  | 'md:max-h-2.5'
  | 'lg:max-h-2.5'
  | 'xl:max-h-2.5'
  | '2xl:max-h-2.5'
  | 'sm:max-h-3.5'
  | 'md:max-h-3.5'
  | 'lg:max-h-3.5'
  | 'xl:max-h-3.5'
  | '2xl:max-h-3.5'
  | 'sm:max-h-full'
  | 'md:max-h-full'
  | 'lg:max-h-full'
  | 'xl:max-h-full'
  | '2xl:max-h-full'
  | 'sm:max-h-screen'
  | 'md:max-h-screen'
  | 'lg:max-h-screen'
  | 'xl:max-h-screen'
  | '2xl:max-h-screen'
  | 'sm:max-w-0'
  | 'md:max-w-0'
  | 'lg:max-w-0'
  | 'xl:max-w-0'
  | '2xl:max-w-0'
  | 'sm:max-w-none'
  | 'md:max-w-none'
  | 'lg:max-w-none'
  | 'xl:max-w-none'
  | '2xl:max-w-none'
  | 'sm:max-w-xs'
  | 'md:max-w-xs'
  | 'lg:max-w-xs'
  | 'xl:max-w-xs'
  | '2xl:max-w-xs'
  | 'sm:max-w-sm'
  | 'md:max-w-sm'
  | 'lg:max-w-sm'
  | 'xl:max-w-sm'
  | '2xl:max-w-sm'
  | 'sm:max-w-md'
  | 'md:max-w-md'
  | 'lg:max-w-md'
  | 'xl:max-w-md'
  | '2xl:max-w-md'
  | 'sm:max-w-lg'
  | 'md:max-w-lg'
  | 'lg:max-w-lg'
  | 'xl:max-w-lg'
  | '2xl:max-w-lg'
  | 'sm:max-w-xl'
  | 'md:max-w-xl'
  | 'lg:max-w-xl'
  | 'xl:max-w-xl'
  | '2xl:max-w-xl'
  | 'sm:max-w-2xl'
  | 'md:max-w-2xl'
  | 'lg:max-w-2xl'
  | 'xl:max-w-2xl'
  | '2xl:max-w-2xl'
  | 'sm:max-w-3xl'
  | 'md:max-w-3xl'
  | 'lg:max-w-3xl'
  | 'xl:max-w-3xl'
  | '2xl:max-w-3xl'
  | 'sm:max-w-4xl'
  | 'md:max-w-4xl'
  | 'lg:max-w-4xl'
  | 'xl:max-w-4xl'
  | '2xl:max-w-4xl'
  | 'sm:max-w-5xl'
  | 'md:max-w-5xl'
  | 'lg:max-w-5xl'
  | 'xl:max-w-5xl'
  | '2xl:max-w-5xl'
  | 'sm:max-w-6xl'
  | 'md:max-w-6xl'
  | 'lg:max-w-6xl'
  | 'xl:max-w-6xl'
  | '2xl:max-w-6xl'
  | 'sm:max-w-7xl'
  | 'md:max-w-7xl'
  | 'lg:max-w-7xl'
  | 'xl:max-w-7xl'
  | '2xl:max-w-7xl'
  | 'sm:max-w-full'
  | 'md:max-w-full'
  | 'lg:max-w-full'
  | 'xl:max-w-full'
  | '2xl:max-w-full'
  | 'sm:max-w-min'
  | 'md:max-w-min'
  | 'lg:max-w-min'
  | 'xl:max-w-min'
  | '2xl:max-w-min'
  | 'sm:max-w-max'
  | 'md:max-w-max'
  | 'lg:max-w-max'
  | 'xl:max-w-max'
  | '2xl:max-w-max'
  | 'sm:max-w-prose'
  | 'md:max-w-prose'
  | 'lg:max-w-prose'
  | 'xl:max-w-prose'
  | '2xl:max-w-prose'
  | 'sm:max-w-screen-sm'
  | 'md:max-w-screen-sm'
  | 'lg:max-w-screen-sm'
  | 'xl:max-w-screen-sm'
  | '2xl:max-w-screen-sm'
  | 'sm:max-w-screen-md'
  | 'md:max-w-screen-md'
  | 'lg:max-w-screen-md'
  | 'xl:max-w-screen-md'
  | '2xl:max-w-screen-md'
  | 'sm:max-w-screen-lg'
  | 'md:max-w-screen-lg'
  | 'lg:max-w-screen-lg'
  | 'xl:max-w-screen-lg'
  | '2xl:max-w-screen-lg'
  | 'sm:max-w-screen-xl'
  | 'md:max-w-screen-xl'
  | 'lg:max-w-screen-xl'
  | 'xl:max-w-screen-xl'
  | '2xl:max-w-screen-xl'
  | 'sm:max-w-screen-2xl'
  | 'md:max-w-screen-2xl'
  | 'lg:max-w-screen-2xl'
  | 'xl:max-w-screen-2xl'
  | '2xl:max-w-screen-2xl'
  | 'sm:min-h-0'
  | 'md:min-h-0'
  | 'lg:min-h-0'
  | 'xl:min-h-0'
  | '2xl:min-h-0'
  | 'sm:min-h-full'
  | 'md:min-h-full'
  | 'lg:min-h-full'
  | 'xl:min-h-full'
  | '2xl:min-h-full'
  | 'sm:min-h-screen'
  | 'md:min-h-screen'
  | 'lg:min-h-screen'
  | 'xl:min-h-screen'
  | '2xl:min-h-screen'
  | 'sm:min-w-0'
  | 'md:min-w-0'
  | 'lg:min-w-0'
  | 'xl:min-w-0'
  | '2xl:min-w-0'
  | 'sm:min-w-full'
  | 'md:min-w-full'
  | 'lg:min-w-full'
  | 'xl:min-w-full'
  | '2xl:min-w-full'
  | 'sm:min-w-min'
  | 'md:min-w-min'
  | 'lg:min-w-min'
  | 'xl:min-w-min'
  | '2xl:min-w-min'
  | 'sm:min-w-max'
  | 'md:min-w-max'
  | 'lg:min-w-max'
  | 'xl:min-w-max'
  | '2xl:min-w-max'
  | 'sm:mix-blend-normal'
  | 'md:mix-blend-normal'
  | 'lg:mix-blend-normal'
  | 'xl:mix-blend-normal'
  | '2xl:mix-blend-normal'
  | 'sm:mix-blend-multiply'
  | 'md:mix-blend-multiply'
  | 'lg:mix-blend-multiply'
  | 'xl:mix-blend-multiply'
  | '2xl:mix-blend-multiply'
  | 'sm:mix-blend-screen'
  | 'md:mix-blend-screen'
  | 'lg:mix-blend-screen'
  | 'xl:mix-blend-screen'
  | '2xl:mix-blend-screen'
  | 'sm:mix-blend-overlay'
  | 'md:mix-blend-overlay'
  | 'lg:mix-blend-overlay'
  | 'xl:mix-blend-overlay'
  | '2xl:mix-blend-overlay'
  | 'sm:mix-blend-darken'
  | 'md:mix-blend-darken'
  | 'lg:mix-blend-darken'
  | 'xl:mix-blend-darken'
  | '2xl:mix-blend-darken'
  | 'sm:mix-blend-lighten'
  | 'md:mix-blend-lighten'
  | 'lg:mix-blend-lighten'
  | 'xl:mix-blend-lighten'
  | '2xl:mix-blend-lighten'
  | 'sm:mix-blend-color-dodge'
  | 'md:mix-blend-color-dodge'
  | 'lg:mix-blend-color-dodge'
  | 'xl:mix-blend-color-dodge'
  | '2xl:mix-blend-color-dodge'
  | 'sm:mix-blend-color-burn'
  | 'md:mix-blend-color-burn'
  | 'lg:mix-blend-color-burn'
  | 'xl:mix-blend-color-burn'
  | '2xl:mix-blend-color-burn'
  | 'sm:mix-blend-hard-light'
  | 'md:mix-blend-hard-light'
  | 'lg:mix-blend-hard-light'
  | 'xl:mix-blend-hard-light'
  | '2xl:mix-blend-hard-light'
  | 'sm:mix-blend-soft-light'
  | 'md:mix-blend-soft-light'
  | 'lg:mix-blend-soft-light'
  | 'xl:mix-blend-soft-light'
  | '2xl:mix-blend-soft-light'
  | 'sm:mix-blend-difference'
  | 'md:mix-blend-difference'
  | 'lg:mix-blend-difference'
  | 'xl:mix-blend-difference'
  | '2xl:mix-blend-difference'
  | 'sm:mix-blend-exclusion'
  | 'md:mix-blend-exclusion'
  | 'lg:mix-blend-exclusion'
  | 'xl:mix-blend-exclusion'
  | '2xl:mix-blend-exclusion'
  | 'sm:mix-blend-hue'
  | 'md:mix-blend-hue'
  | 'lg:mix-blend-hue'
  | 'xl:mix-blend-hue'
  | '2xl:mix-blend-hue'
  | 'sm:mix-blend-saturation'
  | 'md:mix-blend-saturation'
  | 'lg:mix-blend-saturation'
  | 'xl:mix-blend-saturation'
  | '2xl:mix-blend-saturation'
  | 'sm:mix-blend-color'
  | 'md:mix-blend-color'
  | 'lg:mix-blend-color'
  | 'xl:mix-blend-color'
  | '2xl:mix-blend-color'
  | 'sm:mix-blend-luminosity'
  | 'md:mix-blend-luminosity'
  | 'lg:mix-blend-luminosity'
  | 'xl:mix-blend-luminosity'
  | '2xl:mix-blend-luminosity'
  | 'sm:object-contain'
  | 'md:object-contain'
  | 'lg:object-contain'
  | 'xl:object-contain'
  | '2xl:object-contain'
  | 'sm:object-cover'
  | 'md:object-cover'
  | 'lg:object-cover'
  | 'xl:object-cover'
  | '2xl:object-cover'
  | 'sm:object-fill'
  | 'md:object-fill'
  | 'lg:object-fill'
  | 'xl:object-fill'
  | '2xl:object-fill'
  | 'sm:object-none'
  | 'md:object-none'
  | 'lg:object-none'
  | 'xl:object-none'
  | '2xl:object-none'
  | 'sm:object-scale-down'
  | 'md:object-scale-down'
  | 'lg:object-scale-down'
  | 'xl:object-scale-down'
  | '2xl:object-scale-down'
  | 'sm:object-bottom'
  | 'md:object-bottom'
  | 'lg:object-bottom'
  | 'xl:object-bottom'
  | '2xl:object-bottom'
  | 'sm:object-center'
  | 'md:object-center'
  | 'lg:object-center'
  | 'xl:object-center'
  | '2xl:object-center'
  | 'sm:object-left'
  | 'md:object-left'
  | 'lg:object-left'
  | 'xl:object-left'
  | '2xl:object-left'
  | 'sm:object-left-bottom'
  | 'md:object-left-bottom'
  | 'lg:object-left-bottom'
  | 'xl:object-left-bottom'
  | '2xl:object-left-bottom'
  | 'sm:object-left-top'
  | 'md:object-left-top'
  | 'lg:object-left-top'
  | 'xl:object-left-top'
  | '2xl:object-left-top'
  | 'sm:object-right'
  | 'md:object-right'
  | 'lg:object-right'
  | 'xl:object-right'
  | '2xl:object-right'
  | 'sm:object-right-bottom'
  | 'md:object-right-bottom'
  | 'lg:object-right-bottom'
  | 'xl:object-right-bottom'
  | '2xl:object-right-bottom'
  | 'sm:object-right-top'
  | 'md:object-right-top'
  | 'lg:object-right-top'
  | 'xl:object-right-top'
  | '2xl:object-right-top'
  | 'sm:object-top'
  | 'md:object-top'
  | 'lg:object-top'
  | 'xl:object-top'
  | '2xl:object-top'
  | 'sm:opacity-0'
  | 'md:opacity-0'
  | 'lg:opacity-0'
  | 'xl:opacity-0'
  | '2xl:opacity-0'
  | 'group-hover:opacity-0'
  | 'focus-within:opacity-0'
  | 'hover:opacity-0'
  | 'focus:opacity-0'
  | 'sm:opacity-5'
  | 'md:opacity-5'
  | 'lg:opacity-5'
  | 'xl:opacity-5'
  | '2xl:opacity-5'
  | 'group-hover:opacity-5'
  | 'focus-within:opacity-5'
  | 'hover:opacity-5'
  | 'focus:opacity-5'
  | 'sm:opacity-10'
  | 'md:opacity-10'
  | 'lg:opacity-10'
  | 'xl:opacity-10'
  | '2xl:opacity-10'
  | 'group-hover:opacity-10'
  | 'focus-within:opacity-10'
  | 'hover:opacity-10'
  | 'focus:opacity-10'
  | 'sm:opacity-20'
  | 'md:opacity-20'
  | 'lg:opacity-20'
  | 'xl:opacity-20'
  | '2xl:opacity-20'
  | 'group-hover:opacity-20'
  | 'focus-within:opacity-20'
  | 'hover:opacity-20'
  | 'focus:opacity-20'
  | 'sm:opacity-25'
  | 'md:opacity-25'
  | 'lg:opacity-25'
  | 'xl:opacity-25'
  | '2xl:opacity-25'
  | 'group-hover:opacity-25'
  | 'focus-within:opacity-25'
  | 'hover:opacity-25'
  | 'focus:opacity-25'
  | 'sm:opacity-30'
  | 'md:opacity-30'
  | 'lg:opacity-30'
  | 'xl:opacity-30'
  | '2xl:opacity-30'
  | 'group-hover:opacity-30'
  | 'focus-within:opacity-30'
  | 'hover:opacity-30'
  | 'focus:opacity-30'
  | 'sm:opacity-40'
  | 'md:opacity-40'
  | 'lg:opacity-40'
  | 'xl:opacity-40'
  | '2xl:opacity-40'
  | 'group-hover:opacity-40'
  | 'focus-within:opacity-40'
  | 'hover:opacity-40'
  | 'focus:opacity-40'
  | 'sm:opacity-50'
  | 'md:opacity-50'
  | 'lg:opacity-50'
  | 'xl:opacity-50'
  | '2xl:opacity-50'
  | 'group-hover:opacity-50'
  | 'focus-within:opacity-50'
  | 'hover:opacity-50'
  | 'focus:opacity-50'
  | 'sm:opacity-60'
  | 'md:opacity-60'
  | 'lg:opacity-60'
  | 'xl:opacity-60'
  | '2xl:opacity-60'
  | 'group-hover:opacity-60'
  | 'focus-within:opacity-60'
  | 'hover:opacity-60'
  | 'focus:opacity-60'
  | 'sm:opacity-70'
  | 'md:opacity-70'
  | 'lg:opacity-70'
  | 'xl:opacity-70'
  | '2xl:opacity-70'
  | 'group-hover:opacity-70'
  | 'focus-within:opacity-70'
  | 'hover:opacity-70'
  | 'focus:opacity-70'
  | 'sm:opacity-75'
  | 'md:opacity-75'
  | 'lg:opacity-75'
  | 'xl:opacity-75'
  | '2xl:opacity-75'
  | 'group-hover:opacity-75'
  | 'focus-within:opacity-75'
  | 'hover:opacity-75'
  | 'focus:opacity-75'
  | 'sm:opacity-80'
  | 'md:opacity-80'
  | 'lg:opacity-80'
  | 'xl:opacity-80'
  | '2xl:opacity-80'
  | 'group-hover:opacity-80'
  | 'focus-within:opacity-80'
  | 'hover:opacity-80'
  | 'focus:opacity-80'
  | 'sm:opacity-90'
  | 'md:opacity-90'
  | 'lg:opacity-90'
  | 'xl:opacity-90'
  | '2xl:opacity-90'
  | 'group-hover:opacity-90'
  | 'focus-within:opacity-90'
  | 'hover:opacity-90'
  | 'focus:opacity-90'
  | 'sm:opacity-95'
  | 'md:opacity-95'
  | 'lg:opacity-95'
  | 'xl:opacity-95'
  | '2xl:opacity-95'
  | 'group-hover:opacity-95'
  | 'focus-within:opacity-95'
  | 'hover:opacity-95'
  | 'focus:opacity-95'
  | 'sm:opacity-100'
  | 'md:opacity-100'
  | 'lg:opacity-100'
  | 'xl:opacity-100'
  | '2xl:opacity-100'
  | 'group-hover:opacity-100'
  | 'focus-within:opacity-100'
  | 'hover:opacity-100'
  | 'focus:opacity-100'
  | 'sm:order-1'
  | 'md:order-1'
  | 'lg:order-1'
  | 'xl:order-1'
  | '2xl:order-1'
  | 'sm:order-2'
  | 'md:order-2'
  | 'lg:order-2'
  | 'xl:order-2'
  | '2xl:order-2'
  | 'sm:order-3'
  | 'md:order-3'
  | 'lg:order-3'
  | 'xl:order-3'
  | '2xl:order-3'
  | 'sm:order-4'
  | 'md:order-4'
  | 'lg:order-4'
  | 'xl:order-4'
  | '2xl:order-4'
  | 'sm:order-5'
  | 'md:order-5'
  | 'lg:order-5'
  | 'xl:order-5'
  | '2xl:order-5'
  | 'sm:order-6'
  | 'md:order-6'
  | 'lg:order-6'
  | 'xl:order-6'
  | '2xl:order-6'
  | 'sm:order-7'
  | 'md:order-7'
  | 'lg:order-7'
  | 'xl:order-7'
  | '2xl:order-7'
  | 'sm:order-8'
  | 'md:order-8'
  | 'lg:order-8'
  | 'xl:order-8'
  | '2xl:order-8'
  | 'sm:order-9'
  | 'md:order-9'
  | 'lg:order-9'
  | 'xl:order-9'
  | '2xl:order-9'
  | 'sm:order-10'
  | 'md:order-10'
  | 'lg:order-10'
  | 'xl:order-10'
  | '2xl:order-10'
  | 'sm:order-11'
  | 'md:order-11'
  | 'lg:order-11'
  | 'xl:order-11'
  | '2xl:order-11'
  | 'sm:order-12'
  | 'md:order-12'
  | 'lg:order-12'
  | 'xl:order-12'
  | '2xl:order-12'
  | 'sm:order-first'
  | 'md:order-first'
  | 'lg:order-first'
  | 'xl:order-first'
  | '2xl:order-first'
  | 'sm:order-last'
  | 'md:order-last'
  | 'lg:order-last'
  | 'xl:order-last'
  | '2xl:order-last'
  | 'sm:order-none'
  | 'md:order-none'
  | 'lg:order-none'
  | 'xl:order-none'
  | '2xl:order-none'
  | 'sm:outline-none'
  | 'md:outline-none'
  | 'lg:outline-none'
  | 'xl:outline-none'
  | '2xl:outline-none'
  | 'focus-within:outline-none'
  | 'focus:outline-none'
  | 'sm:outline-white'
  | 'md:outline-white'
  | 'lg:outline-white'
  | 'xl:outline-white'
  | '2xl:outline-white'
  | 'focus-within:outline-white'
  | 'focus:outline-white'
  | 'sm:outline-black'
  | 'md:outline-black'
  | 'lg:outline-black'
  | 'xl:outline-black'
  | '2xl:outline-black'
  | 'focus-within:outline-black'
  | 'focus:outline-black'
  | 'sm:overflow-auto'
  | 'md:overflow-auto'
  | 'lg:overflow-auto'
  | 'xl:overflow-auto'
  | '2xl:overflow-auto'
  | 'sm:overflow-hidden'
  | 'md:overflow-hidden'
  | 'lg:overflow-hidden'
  | 'xl:overflow-hidden'
  | '2xl:overflow-hidden'
  | 'sm:overflow-visible'
  | 'md:overflow-visible'
  | 'lg:overflow-visible'
  | 'xl:overflow-visible'
  | '2xl:overflow-visible'
  | 'sm:overflow-scroll'
  | 'md:overflow-scroll'
  | 'lg:overflow-scroll'
  | 'xl:overflow-scroll'
  | '2xl:overflow-scroll'
  | 'sm:overflow-x-auto'
  | 'md:overflow-x-auto'
  | 'lg:overflow-x-auto'
  | 'xl:overflow-x-auto'
  | '2xl:overflow-x-auto'
  | 'sm:overflow-y-auto'
  | 'md:overflow-y-auto'
  | 'lg:overflow-y-auto'
  | 'xl:overflow-y-auto'
  | '2xl:overflow-y-auto'
  | 'sm:overflow-x-hidden'
  | 'md:overflow-x-hidden'
  | 'lg:overflow-x-hidden'
  | 'xl:overflow-x-hidden'
  | '2xl:overflow-x-hidden'
  | 'sm:overflow-y-hidden'
  | 'md:overflow-y-hidden'
  | 'lg:overflow-y-hidden'
  | 'xl:overflow-y-hidden'
  | '2xl:overflow-y-hidden'
  | 'sm:overflow-x-visible'
  | 'md:overflow-x-visible'
  | 'lg:overflow-x-visible'
  | 'xl:overflow-x-visible'
  | '2xl:overflow-x-visible'
  | 'sm:overflow-y-visible'
  | 'md:overflow-y-visible'
  | 'lg:overflow-y-visible'
  | 'xl:overflow-y-visible'
  | '2xl:overflow-y-visible'
  | 'sm:overflow-x-scroll'
  | 'md:overflow-x-scroll'
  | 'lg:overflow-x-scroll'
  | 'xl:overflow-x-scroll'
  | '2xl:overflow-x-scroll'
  | 'sm:overflow-y-scroll'
  | 'md:overflow-y-scroll'
  | 'lg:overflow-y-scroll'
  | 'xl:overflow-y-scroll'
  | '2xl:overflow-y-scroll'
  | 'sm:overscroll-auto'
  | 'md:overscroll-auto'
  | 'lg:overscroll-auto'
  | 'xl:overscroll-auto'
  | '2xl:overscroll-auto'
  | 'sm:overscroll-contain'
  | 'md:overscroll-contain'
  | 'lg:overscroll-contain'
  | 'xl:overscroll-contain'
  | '2xl:overscroll-contain'
  | 'sm:overscroll-none'
  | 'md:overscroll-none'
  | 'lg:overscroll-none'
  | 'xl:overscroll-none'
  | '2xl:overscroll-none'
  | 'sm:overscroll-y-auto'
  | 'md:overscroll-y-auto'
  | 'lg:overscroll-y-auto'
  | 'xl:overscroll-y-auto'
  | '2xl:overscroll-y-auto'
  | 'sm:overscroll-y-contain'
  | 'md:overscroll-y-contain'
  | 'lg:overscroll-y-contain'
  | 'xl:overscroll-y-contain'
  | '2xl:overscroll-y-contain'
  | 'sm:overscroll-y-none'
  | 'md:overscroll-y-none'
  | 'lg:overscroll-y-none'
  | 'xl:overscroll-y-none'
  | '2xl:overscroll-y-none'
  | 'sm:overscroll-x-auto'
  | 'md:overscroll-x-auto'
  | 'lg:overscroll-x-auto'
  | 'xl:overscroll-x-auto'
  | '2xl:overscroll-x-auto'
  | 'sm:overscroll-x-contain'
  | 'md:overscroll-x-contain'
  | 'lg:overscroll-x-contain'
  | 'xl:overscroll-x-contain'
  | '2xl:overscroll-x-contain'
  | 'sm:overscroll-x-none'
  | 'md:overscroll-x-none'
  | 'lg:overscroll-x-none'
  | 'xl:overscroll-x-none'
  | '2xl:overscroll-x-none'
  | 'sm:p-0'
  | 'md:p-0'
  | 'lg:p-0'
  | 'xl:p-0'
  | '2xl:p-0'
  | 'sm:p-1'
  | 'md:p-1'
  | 'lg:p-1'
  | 'xl:p-1'
  | '2xl:p-1'
  | 'sm:p-2'
  | 'md:p-2'
  | 'lg:p-2'
  | 'xl:p-2'
  | '2xl:p-2'
  | 'sm:p-3'
  | 'md:p-3'
  | 'lg:p-3'
  | 'xl:p-3'
  | '2xl:p-3'
  | 'sm:p-4'
  | 'md:p-4'
  | 'lg:p-4'
  | 'xl:p-4'
  | '2xl:p-4'
  | 'sm:p-5'
  | 'md:p-5'
  | 'lg:p-5'
  | 'xl:p-5'
  | '2xl:p-5'
  | 'sm:p-6'
  | 'md:p-6'
  | 'lg:p-6'
  | 'xl:p-6'
  | '2xl:p-6'
  | 'sm:p-7'
  | 'md:p-7'
  | 'lg:p-7'
  | 'xl:p-7'
  | '2xl:p-7'
  | 'sm:p-8'
  | 'md:p-8'
  | 'lg:p-8'
  | 'xl:p-8'
  | '2xl:p-8'
  | 'sm:p-9'
  | 'md:p-9'
  | 'lg:p-9'
  | 'xl:p-9'
  | '2xl:p-9'
  | 'sm:p-10'
  | 'md:p-10'
  | 'lg:p-10'
  | 'xl:p-10'
  | '2xl:p-10'
  | 'sm:p-11'
  | 'md:p-11'
  | 'lg:p-11'
  | 'xl:p-11'
  | '2xl:p-11'
  | 'sm:p-12'
  | 'md:p-12'
  | 'lg:p-12'
  | 'xl:p-12'
  | '2xl:p-12'
  | 'sm:p-14'
  | 'md:p-14'
  | 'lg:p-14'
  | 'xl:p-14'
  | '2xl:p-14'
  | 'sm:p-16'
  | 'md:p-16'
  | 'lg:p-16'
  | 'xl:p-16'
  | '2xl:p-16'
  | 'sm:p-20'
  | 'md:p-20'
  | 'lg:p-20'
  | 'xl:p-20'
  | '2xl:p-20'
  | 'sm:p-24'
  | 'md:p-24'
  | 'lg:p-24'
  | 'xl:p-24'
  | '2xl:p-24'
  | 'sm:p-28'
  | 'md:p-28'
  | 'lg:p-28'
  | 'xl:p-28'
  | '2xl:p-28'
  | 'sm:p-32'
  | 'md:p-32'
  | 'lg:p-32'
  | 'xl:p-32'
  | '2xl:p-32'
  | 'sm:p-36'
  | 'md:p-36'
  | 'lg:p-36'
  | 'xl:p-36'
  | '2xl:p-36'
  | 'sm:p-40'
  | 'md:p-40'
  | 'lg:p-40'
  | 'xl:p-40'
  | '2xl:p-40'
  | 'sm:p-44'
  | 'md:p-44'
  | 'lg:p-44'
  | 'xl:p-44'
  | '2xl:p-44'
  | 'sm:p-48'
  | 'md:p-48'
  | 'lg:p-48'
  | 'xl:p-48'
  | '2xl:p-48'
  | 'sm:p-52'
  | 'md:p-52'
  | 'lg:p-52'
  | 'xl:p-52'
  | '2xl:p-52'
  | 'sm:p-56'
  | 'md:p-56'
  | 'lg:p-56'
  | 'xl:p-56'
  | '2xl:p-56'
  | 'sm:p-60'
  | 'md:p-60'
  | 'lg:p-60'
  | 'xl:p-60'
  | '2xl:p-60'
  | 'sm:p-64'
  | 'md:p-64'
  | 'lg:p-64'
  | 'xl:p-64'
  | '2xl:p-64'
  | 'sm:p-72'
  | 'md:p-72'
  | 'lg:p-72'
  | 'xl:p-72'
  | '2xl:p-72'
  | 'sm:p-80'
  | 'md:p-80'
  | 'lg:p-80'
  | 'xl:p-80'
  | '2xl:p-80'
  | 'sm:p-96'
  | 'md:p-96'
  | 'lg:p-96'
  | 'xl:p-96'
  | '2xl:p-96'
  | 'sm:p-px'
  | 'md:p-px'
  | 'lg:p-px'
  | 'xl:p-px'
  | '2xl:p-px'
  | 'sm:p-0.5'
  | 'md:p-0.5'
  | 'lg:p-0.5'
  | 'xl:p-0.5'
  | '2xl:p-0.5'
  | 'sm:p-1.5'
  | 'md:p-1.5'
  | 'lg:p-1.5'
  | 'xl:p-1.5'
  | '2xl:p-1.5'
  | 'sm:p-2.5'
  | 'md:p-2.5'
  | 'lg:p-2.5'
  | 'xl:p-2.5'
  | '2xl:p-2.5'
  | 'sm:p-3.5'
  | 'md:p-3.5'
  | 'lg:p-3.5'
  | 'xl:p-3.5'
  | '2xl:p-3.5'
  | 'sm:py-0'
  | 'md:py-0'
  | 'lg:py-0'
  | 'xl:py-0'
  | '2xl:py-0'
  | 'sm:py-1'
  | 'md:py-1'
  | 'lg:py-1'
  | 'xl:py-1'
  | '2xl:py-1'
  | 'sm:py-2'
  | 'md:py-2'
  | 'lg:py-2'
  | 'xl:py-2'
  | '2xl:py-2'
  | 'sm:py-3'
  | 'md:py-3'
  | 'lg:py-3'
  | 'xl:py-3'
  | '2xl:py-3'
  | 'sm:py-4'
  | 'md:py-4'
  | 'lg:py-4'
  | 'xl:py-4'
  | '2xl:py-4'
  | 'sm:py-5'
  | 'md:py-5'
  | 'lg:py-5'
  | 'xl:py-5'
  | '2xl:py-5'
  | 'sm:py-6'
  | 'md:py-6'
  | 'lg:py-6'
  | 'xl:py-6'
  | '2xl:py-6'
  | 'sm:py-7'
  | 'md:py-7'
  | 'lg:py-7'
  | 'xl:py-7'
  | '2xl:py-7'
  | 'sm:py-8'
  | 'md:py-8'
  | 'lg:py-8'
  | 'xl:py-8'
  | '2xl:py-8'
  | 'sm:py-9'
  | 'md:py-9'
  | 'lg:py-9'
  | 'xl:py-9'
  | '2xl:py-9'
  | 'sm:py-10'
  | 'md:py-10'
  | 'lg:py-10'
  | 'xl:py-10'
  | '2xl:py-10'
  | 'sm:py-11'
  | 'md:py-11'
  | 'lg:py-11'
  | 'xl:py-11'
  | '2xl:py-11'
  | 'sm:py-12'
  | 'md:py-12'
  | 'lg:py-12'
  | 'xl:py-12'
  | '2xl:py-12'
  | 'sm:py-14'
  | 'md:py-14'
  | 'lg:py-14'
  | 'xl:py-14'
  | '2xl:py-14'
  | 'sm:py-16'
  | 'md:py-16'
  | 'lg:py-16'
  | 'xl:py-16'
  | '2xl:py-16'
  | 'sm:py-20'
  | 'md:py-20'
  | 'lg:py-20'
  | 'xl:py-20'
  | '2xl:py-20'
  | 'sm:py-24'
  | 'md:py-24'
  | 'lg:py-24'
  | 'xl:py-24'
  | '2xl:py-24'
  | 'sm:py-28'
  | 'md:py-28'
  | 'lg:py-28'
  | 'xl:py-28'
  | '2xl:py-28'
  | 'sm:py-32'
  | 'md:py-32'
  | 'lg:py-32'
  | 'xl:py-32'
  | '2xl:py-32'
  | 'sm:py-36'
  | 'md:py-36'
  | 'lg:py-36'
  | 'xl:py-36'
  | '2xl:py-36'
  | 'sm:py-40'
  | 'md:py-40'
  | 'lg:py-40'
  | 'xl:py-40'
  | '2xl:py-40'
  | 'sm:py-44'
  | 'md:py-44'
  | 'lg:py-44'
  | 'xl:py-44'
  | '2xl:py-44'
  | 'sm:py-48'
  | 'md:py-48'
  | 'lg:py-48'
  | 'xl:py-48'
  | '2xl:py-48'
  | 'sm:py-52'
  | 'md:py-52'
  | 'lg:py-52'
  | 'xl:py-52'
  | '2xl:py-52'
  | 'sm:py-56'
  | 'md:py-56'
  | 'lg:py-56'
  | 'xl:py-56'
  | '2xl:py-56'
  | 'sm:py-60'
  | 'md:py-60'
  | 'lg:py-60'
  | 'xl:py-60'
  | '2xl:py-60'
  | 'sm:py-64'
  | 'md:py-64'
  | 'lg:py-64'
  | 'xl:py-64'
  | '2xl:py-64'
  | 'sm:py-72'
  | 'md:py-72'
  | 'lg:py-72'
  | 'xl:py-72'
  | '2xl:py-72'
  | 'sm:py-80'
  | 'md:py-80'
  | 'lg:py-80'
  | 'xl:py-80'
  | '2xl:py-80'
  | 'sm:py-96'
  | 'md:py-96'
  | 'lg:py-96'
  | 'xl:py-96'
  | '2xl:py-96'
  | 'sm:py-px'
  | 'md:py-px'
  | 'lg:py-px'
  | 'xl:py-px'
  | '2xl:py-px'
  | 'sm:py-0.5'
  | 'md:py-0.5'
  | 'lg:py-0.5'
  | 'xl:py-0.5'
  | '2xl:py-0.5'
  | 'sm:py-1.5'
  | 'md:py-1.5'
  | 'lg:py-1.5'
  | 'xl:py-1.5'
  | '2xl:py-1.5'
  | 'sm:py-2.5'
  | 'md:py-2.5'
  | 'lg:py-2.5'
  | 'xl:py-2.5'
  | '2xl:py-2.5'
  | 'sm:py-3.5'
  | 'md:py-3.5'
  | 'lg:py-3.5'
  | 'xl:py-3.5'
  | '2xl:py-3.5'
  | 'sm:px-0'
  | 'md:px-0'
  | 'lg:px-0'
  | 'xl:px-0'
  | '2xl:px-0'
  | 'sm:px-1'
  | 'md:px-1'
  | 'lg:px-1'
  | 'xl:px-1'
  | '2xl:px-1'
  | 'sm:px-2'
  | 'md:px-2'
  | 'lg:px-2'
  | 'xl:px-2'
  | '2xl:px-2'
  | 'sm:px-3'
  | 'md:px-3'
  | 'lg:px-3'
  | 'xl:px-3'
  | '2xl:px-3'
  | 'sm:px-4'
  | 'md:px-4'
  | 'lg:px-4'
  | 'xl:px-4'
  | '2xl:px-4'
  | 'sm:px-5'
  | 'md:px-5'
  | 'lg:px-5'
  | 'xl:px-5'
  | '2xl:px-5'
  | 'sm:px-6'
  | 'md:px-6'
  | 'lg:px-6'
  | 'xl:px-6'
  | '2xl:px-6'
  | 'sm:px-7'
  | 'md:px-7'
  | 'lg:px-7'
  | 'xl:px-7'
  | '2xl:px-7'
  | 'sm:px-8'
  | 'md:px-8'
  | 'lg:px-8'
  | 'xl:px-8'
  | '2xl:px-8'
  | 'sm:px-9'
  | 'md:px-9'
  | 'lg:px-9'
  | 'xl:px-9'
  | '2xl:px-9'
  | 'sm:px-10'
  | 'md:px-10'
  | 'lg:px-10'
  | 'xl:px-10'
  | '2xl:px-10'
  | 'sm:px-11'
  | 'md:px-11'
  | 'lg:px-11'
  | 'xl:px-11'
  | '2xl:px-11'
  | 'sm:px-12'
  | 'md:px-12'
  | 'lg:px-12'
  | 'xl:px-12'
  | '2xl:px-12'
  | 'sm:px-14'
  | 'md:px-14'
  | 'lg:px-14'
  | 'xl:px-14'
  | '2xl:px-14'
  | 'sm:px-16'
  | 'md:px-16'
  | 'lg:px-16'
  | 'xl:px-16'
  | '2xl:px-16'
  | 'sm:px-20'
  | 'md:px-20'
  | 'lg:px-20'
  | 'xl:px-20'
  | '2xl:px-20'
  | 'sm:px-24'
  | 'md:px-24'
  | 'lg:px-24'
  | 'xl:px-24'
  | '2xl:px-24'
  | 'sm:px-28'
  | 'md:px-28'
  | 'lg:px-28'
  | 'xl:px-28'
  | '2xl:px-28'
  | 'sm:px-32'
  | 'md:px-32'
  | 'lg:px-32'
  | 'xl:px-32'
  | '2xl:px-32'
  | 'sm:px-36'
  | 'md:px-36'
  | 'lg:px-36'
  | 'xl:px-36'
  | '2xl:px-36'
  | 'sm:px-40'
  | 'md:px-40'
  | 'lg:px-40'
  | 'xl:px-40'
  | '2xl:px-40'
  | 'sm:px-44'
  | 'md:px-44'
  | 'lg:px-44'
  | 'xl:px-44'
  | '2xl:px-44'
  | 'sm:px-48'
  | 'md:px-48'
  | 'lg:px-48'
  | 'xl:px-48'
  | '2xl:px-48'
  | 'sm:px-52'
  | 'md:px-52'
  | 'lg:px-52'
  | 'xl:px-52'
  | '2xl:px-52'
  | 'sm:px-56'
  | 'md:px-56'
  | 'lg:px-56'
  | 'xl:px-56'
  | '2xl:px-56'
  | 'sm:px-60'
  | 'md:px-60'
  | 'lg:px-60'
  | 'xl:px-60'
  | '2xl:px-60'
  | 'sm:px-64'
  | 'md:px-64'
  | 'lg:px-64'
  | 'xl:px-64'
  | '2xl:px-64'
  | 'sm:px-72'
  | 'md:px-72'
  | 'lg:px-72'
  | 'xl:px-72'
  | '2xl:px-72'
  | 'sm:px-80'
  | 'md:px-80'
  | 'lg:px-80'
  | 'xl:px-80'
  | '2xl:px-80'
  | 'sm:px-96'
  | 'md:px-96'
  | 'lg:px-96'
  | 'xl:px-96'
  | '2xl:px-96'
  | 'sm:px-px'
  | 'md:px-px'
  | 'lg:px-px'
  | 'xl:px-px'
  | '2xl:px-px'
  | 'sm:px-0.5'
  | 'md:px-0.5'
  | 'lg:px-0.5'
  | 'xl:px-0.5'
  | '2xl:px-0.5'
  | 'sm:px-1.5'
  | 'md:px-1.5'
  | 'lg:px-1.5'
  | 'xl:px-1.5'
  | '2xl:px-1.5'
  | 'sm:px-2.5'
  | 'md:px-2.5'
  | 'lg:px-2.5'
  | 'xl:px-2.5'
  | '2xl:px-2.5'
  | 'sm:px-3.5'
  | 'md:px-3.5'
  | 'lg:px-3.5'
  | 'xl:px-3.5'
  | '2xl:px-3.5'
  | 'sm:pt-0'
  | 'md:pt-0'
  | 'lg:pt-0'
  | 'xl:pt-0'
  | '2xl:pt-0'
  | 'sm:pt-1'
  | 'md:pt-1'
  | 'lg:pt-1'
  | 'xl:pt-1'
  | '2xl:pt-1'
  | 'sm:pt-2'
  | 'md:pt-2'
  | 'lg:pt-2'
  | 'xl:pt-2'
  | '2xl:pt-2'
  | 'sm:pt-3'
  | 'md:pt-3'
  | 'lg:pt-3'
  | 'xl:pt-3'
  | '2xl:pt-3'
  | 'sm:pt-4'
  | 'md:pt-4'
  | 'lg:pt-4'
  | 'xl:pt-4'
  | '2xl:pt-4'
  | 'sm:pt-5'
  | 'md:pt-5'
  | 'lg:pt-5'
  | 'xl:pt-5'
  | '2xl:pt-5'
  | 'sm:pt-6'
  | 'md:pt-6'
  | 'lg:pt-6'
  | 'xl:pt-6'
  | '2xl:pt-6'
  | 'sm:pt-7'
  | 'md:pt-7'
  | 'lg:pt-7'
  | 'xl:pt-7'
  | '2xl:pt-7'
  | 'sm:pt-8'
  | 'md:pt-8'
  | 'lg:pt-8'
  | 'xl:pt-8'
  | '2xl:pt-8'
  | 'sm:pt-9'
  | 'md:pt-9'
  | 'lg:pt-9'
  | 'xl:pt-9'
  | '2xl:pt-9'
  | 'sm:pt-10'
  | 'md:pt-10'
  | 'lg:pt-10'
  | 'xl:pt-10'
  | '2xl:pt-10'
  | 'sm:pt-11'
  | 'md:pt-11'
  | 'lg:pt-11'
  | 'xl:pt-11'
  | '2xl:pt-11'
  | 'sm:pt-12'
  | 'md:pt-12'
  | 'lg:pt-12'
  | 'xl:pt-12'
  | '2xl:pt-12'
  | 'sm:pt-14'
  | 'md:pt-14'
  | 'lg:pt-14'
  | 'xl:pt-14'
  | '2xl:pt-14'
  | 'sm:pt-16'
  | 'md:pt-16'
  | 'lg:pt-16'
  | 'xl:pt-16'
  | '2xl:pt-16'
  | 'sm:pt-20'
  | 'md:pt-20'
  | 'lg:pt-20'
  | 'xl:pt-20'
  | '2xl:pt-20'
  | 'sm:pt-24'
  | 'md:pt-24'
  | 'lg:pt-24'
  | 'xl:pt-24'
  | '2xl:pt-24'
  | 'sm:pt-28'
  | 'md:pt-28'
  | 'lg:pt-28'
  | 'xl:pt-28'
  | '2xl:pt-28'
  | 'sm:pt-32'
  | 'md:pt-32'
  | 'lg:pt-32'
  | 'xl:pt-32'
  | '2xl:pt-32'
  | 'sm:pt-36'
  | 'md:pt-36'
  | 'lg:pt-36'
  | 'xl:pt-36'
  | '2xl:pt-36'
  | 'sm:pt-40'
  | 'md:pt-40'
  | 'lg:pt-40'
  | 'xl:pt-40'
  | '2xl:pt-40'
  | 'sm:pt-44'
  | 'md:pt-44'
  | 'lg:pt-44'
  | 'xl:pt-44'
  | '2xl:pt-44'
  | 'sm:pt-48'
  | 'md:pt-48'
  | 'lg:pt-48'
  | 'xl:pt-48'
  | '2xl:pt-48'
  | 'sm:pt-52'
  | 'md:pt-52'
  | 'lg:pt-52'
  | 'xl:pt-52'
  | '2xl:pt-52'
  | 'sm:pt-56'
  | 'md:pt-56'
  | 'lg:pt-56'
  | 'xl:pt-56'
  | '2xl:pt-56'
  | 'sm:pt-60'
  | 'md:pt-60'
  | 'lg:pt-60'
  | 'xl:pt-60'
  | '2xl:pt-60'
  | 'sm:pt-64'
  | 'md:pt-64'
  | 'lg:pt-64'
  | 'xl:pt-64'
  | '2xl:pt-64'
  | 'sm:pt-72'
  | 'md:pt-72'
  | 'lg:pt-72'
  | 'xl:pt-72'
  | '2xl:pt-72'
  | 'sm:pt-80'
  | 'md:pt-80'
  | 'lg:pt-80'
  | 'xl:pt-80'
  | '2xl:pt-80'
  | 'sm:pt-96'
  | 'md:pt-96'
  | 'lg:pt-96'
  | 'xl:pt-96'
  | '2xl:pt-96'
  | 'sm:pt-px'
  | 'md:pt-px'
  | 'lg:pt-px'
  | 'xl:pt-px'
  | '2xl:pt-px'
  | 'sm:pt-0.5'
  | 'md:pt-0.5'
  | 'lg:pt-0.5'
  | 'xl:pt-0.5'
  | '2xl:pt-0.5'
  | 'sm:pt-1.5'
  | 'md:pt-1.5'
  | 'lg:pt-1.5'
  | 'xl:pt-1.5'
  | '2xl:pt-1.5'
  | 'sm:pt-2.5'
  | 'md:pt-2.5'
  | 'lg:pt-2.5'
  | 'xl:pt-2.5'
  | '2xl:pt-2.5'
  | 'sm:pt-3.5'
  | 'md:pt-3.5'
  | 'lg:pt-3.5'
  | 'xl:pt-3.5'
  | '2xl:pt-3.5'
  | 'sm:pr-0'
  | 'md:pr-0'
  | 'lg:pr-0'
  | 'xl:pr-0'
  | '2xl:pr-0'
  | 'sm:pr-1'
  | 'md:pr-1'
  | 'lg:pr-1'
  | 'xl:pr-1'
  | '2xl:pr-1'
  | 'sm:pr-2'
  | 'md:pr-2'
  | 'lg:pr-2'
  | 'xl:pr-2'
  | '2xl:pr-2'
  | 'sm:pr-3'
  | 'md:pr-3'
  | 'lg:pr-3'
  | 'xl:pr-3'
  | '2xl:pr-3'
  | 'sm:pr-4'
  | 'md:pr-4'
  | 'lg:pr-4'
  | 'xl:pr-4'
  | '2xl:pr-4'
  | 'sm:pr-5'
  | 'md:pr-5'
  | 'lg:pr-5'
  | 'xl:pr-5'
  | '2xl:pr-5'
  | 'sm:pr-6'
  | 'md:pr-6'
  | 'lg:pr-6'
  | 'xl:pr-6'
  | '2xl:pr-6'
  | 'sm:pr-7'
  | 'md:pr-7'
  | 'lg:pr-7'
  | 'xl:pr-7'
  | '2xl:pr-7'
  | 'sm:pr-8'
  | 'md:pr-8'
  | 'lg:pr-8'
  | 'xl:pr-8'
  | '2xl:pr-8'
  | 'sm:pr-9'
  | 'md:pr-9'
  | 'lg:pr-9'
  | 'xl:pr-9'
  | '2xl:pr-9'
  | 'sm:pr-10'
  | 'md:pr-10'
  | 'lg:pr-10'
  | 'xl:pr-10'
  | '2xl:pr-10'
  | 'sm:pr-11'
  | 'md:pr-11'
  | 'lg:pr-11'
  | 'xl:pr-11'
  | '2xl:pr-11'
  | 'sm:pr-12'
  | 'md:pr-12'
  | 'lg:pr-12'
  | 'xl:pr-12'
  | '2xl:pr-12'
  | 'sm:pr-14'
  | 'md:pr-14'
  | 'lg:pr-14'
  | 'xl:pr-14'
  | '2xl:pr-14'
  | 'sm:pr-16'
  | 'md:pr-16'
  | 'lg:pr-16'
  | 'xl:pr-16'
  | '2xl:pr-16'
  | 'sm:pr-20'
  | 'md:pr-20'
  | 'lg:pr-20'
  | 'xl:pr-20'
  | '2xl:pr-20'
  | 'sm:pr-24'
  | 'md:pr-24'
  | 'lg:pr-24'
  | 'xl:pr-24'
  | '2xl:pr-24'
  | 'sm:pr-28'
  | 'md:pr-28'
  | 'lg:pr-28'
  | 'xl:pr-28'
  | '2xl:pr-28'
  | 'sm:pr-32'
  | 'md:pr-32'
  | 'lg:pr-32'
  | 'xl:pr-32'
  | '2xl:pr-32'
  | 'sm:pr-36'
  | 'md:pr-36'
  | 'lg:pr-36'
  | 'xl:pr-36'
  | '2xl:pr-36'
  | 'sm:pr-40'
  | 'md:pr-40'
  | 'lg:pr-40'
  | 'xl:pr-40'
  | '2xl:pr-40'
  | 'sm:pr-44'
  | 'md:pr-44'
  | 'lg:pr-44'
  | 'xl:pr-44'
  | '2xl:pr-44'
  | 'sm:pr-48'
  | 'md:pr-48'
  | 'lg:pr-48'
  | 'xl:pr-48'
  | '2xl:pr-48'
  | 'sm:pr-52'
  | 'md:pr-52'
  | 'lg:pr-52'
  | 'xl:pr-52'
  | '2xl:pr-52'
  | 'sm:pr-56'
  | 'md:pr-56'
  | 'lg:pr-56'
  | 'xl:pr-56'
  | '2xl:pr-56'
  | 'sm:pr-60'
  | 'md:pr-60'
  | 'lg:pr-60'
  | 'xl:pr-60'
  | '2xl:pr-60'
  | 'sm:pr-64'
  | 'md:pr-64'
  | 'lg:pr-64'
  | 'xl:pr-64'
  | '2xl:pr-64'
  | 'sm:pr-72'
  | 'md:pr-72'
  | 'lg:pr-72'
  | 'xl:pr-72'
  | '2xl:pr-72'
  | 'sm:pr-80'
  | 'md:pr-80'
  | 'lg:pr-80'
  | 'xl:pr-80'
  | '2xl:pr-80'
  | 'sm:pr-96'
  | 'md:pr-96'
  | 'lg:pr-96'
  | 'xl:pr-96'
  | '2xl:pr-96'
  | 'sm:pr-px'
  | 'md:pr-px'
  | 'lg:pr-px'
  | 'xl:pr-px'
  | '2xl:pr-px'
  | 'sm:pr-0.5'
  | 'md:pr-0.5'
  | 'lg:pr-0.5'
  | 'xl:pr-0.5'
  | '2xl:pr-0.5'
  | 'sm:pr-1.5'
  | 'md:pr-1.5'
  | 'lg:pr-1.5'
  | 'xl:pr-1.5'
  | '2xl:pr-1.5'
  | 'sm:pr-2.5'
  | 'md:pr-2.5'
  | 'lg:pr-2.5'
  | 'xl:pr-2.5'
  | '2xl:pr-2.5'
  | 'sm:pr-3.5'
  | 'md:pr-3.5'
  | 'lg:pr-3.5'
  | 'xl:pr-3.5'
  | '2xl:pr-3.5'
  | 'sm:pb-0'
  | 'md:pb-0'
  | 'lg:pb-0'
  | 'xl:pb-0'
  | '2xl:pb-0'
  | 'sm:pb-1'
  | 'md:pb-1'
  | 'lg:pb-1'
  | 'xl:pb-1'
  | '2xl:pb-1'
  | 'sm:pb-2'
  | 'md:pb-2'
  | 'lg:pb-2'
  | 'xl:pb-2'
  | '2xl:pb-2'
  | 'sm:pb-3'
  | 'md:pb-3'
  | 'lg:pb-3'
  | 'xl:pb-3'
  | '2xl:pb-3'
  | 'sm:pb-4'
  | 'md:pb-4'
  | 'lg:pb-4'
  | 'xl:pb-4'
  | '2xl:pb-4'
  | 'sm:pb-5'
  | 'md:pb-5'
  | 'lg:pb-5'
  | 'xl:pb-5'
  | '2xl:pb-5'
  | 'sm:pb-6'
  | 'md:pb-6'
  | 'lg:pb-6'
  | 'xl:pb-6'
  | '2xl:pb-6'
  | 'sm:pb-7'
  | 'md:pb-7'
  | 'lg:pb-7'
  | 'xl:pb-7'
  | '2xl:pb-7'
  | 'sm:pb-8'
  | 'md:pb-8'
  | 'lg:pb-8'
  | 'xl:pb-8'
  | '2xl:pb-8'
  | 'sm:pb-9'
  | 'md:pb-9'
  | 'lg:pb-9'
  | 'xl:pb-9'
  | '2xl:pb-9'
  | 'sm:pb-10'
  | 'md:pb-10'
  | 'lg:pb-10'
  | 'xl:pb-10'
  | '2xl:pb-10'
  | 'sm:pb-11'
  | 'md:pb-11'
  | 'lg:pb-11'
  | 'xl:pb-11'
  | '2xl:pb-11'
  | 'sm:pb-12'
  | 'md:pb-12'
  | 'lg:pb-12'
  | 'xl:pb-12'
  | '2xl:pb-12'
  | 'sm:pb-14'
  | 'md:pb-14'
  | 'lg:pb-14'
  | 'xl:pb-14'
  | '2xl:pb-14'
  | 'sm:pb-16'
  | 'md:pb-16'
  | 'lg:pb-16'
  | 'xl:pb-16'
  | '2xl:pb-16'
  | 'sm:pb-20'
  | 'md:pb-20'
  | 'lg:pb-20'
  | 'xl:pb-20'
  | '2xl:pb-20'
  | 'sm:pb-24'
  | 'md:pb-24'
  | 'lg:pb-24'
  | 'xl:pb-24'
  | '2xl:pb-24'
  | 'sm:pb-28'
  | 'md:pb-28'
  | 'lg:pb-28'
  | 'xl:pb-28'
  | '2xl:pb-28'
  | 'sm:pb-32'
  | 'md:pb-32'
  | 'lg:pb-32'
  | 'xl:pb-32'
  | '2xl:pb-32'
  | 'sm:pb-36'
  | 'md:pb-36'
  | 'lg:pb-36'
  | 'xl:pb-36'
  | '2xl:pb-36'
  | 'sm:pb-40'
  | 'md:pb-40'
  | 'lg:pb-40'
  | 'xl:pb-40'
  | '2xl:pb-40'
  | 'sm:pb-44'
  | 'md:pb-44'
  | 'lg:pb-44'
  | 'xl:pb-44'
  | '2xl:pb-44'
  | 'sm:pb-48'
  | 'md:pb-48'
  | 'lg:pb-48'
  | 'xl:pb-48'
  | '2xl:pb-48'
  | 'sm:pb-52'
  | 'md:pb-52'
  | 'lg:pb-52'
  | 'xl:pb-52'
  | '2xl:pb-52'
  | 'sm:pb-56'
  | 'md:pb-56'
  | 'lg:pb-56'
  | 'xl:pb-56'
  | '2xl:pb-56'
  | 'sm:pb-60'
  | 'md:pb-60'
  | 'lg:pb-60'
  | 'xl:pb-60'
  | '2xl:pb-60'
  | 'sm:pb-64'
  | 'md:pb-64'
  | 'lg:pb-64'
  | 'xl:pb-64'
  | '2xl:pb-64'
  | 'sm:pb-72'
  | 'md:pb-72'
  | 'lg:pb-72'
  | 'xl:pb-72'
  | '2xl:pb-72'
  | 'sm:pb-80'
  | 'md:pb-80'
  | 'lg:pb-80'
  | 'xl:pb-80'
  | '2xl:pb-80'
  | 'sm:pb-96'
  | 'md:pb-96'
  | 'lg:pb-96'
  | 'xl:pb-96'
  | '2xl:pb-96'
  | 'sm:pb-px'
  | 'md:pb-px'
  | 'lg:pb-px'
  | 'xl:pb-px'
  | '2xl:pb-px'
  | 'sm:pb-0.5'
  | 'md:pb-0.5'
  | 'lg:pb-0.5'
  | 'xl:pb-0.5'
  | '2xl:pb-0.5'
  | 'sm:pb-1.5'
  | 'md:pb-1.5'
  | 'lg:pb-1.5'
  | 'xl:pb-1.5'
  | '2xl:pb-1.5'
  | 'sm:pb-2.5'
  | 'md:pb-2.5'
  | 'lg:pb-2.5'
  | 'xl:pb-2.5'
  | '2xl:pb-2.5'
  | 'sm:pb-3.5'
  | 'md:pb-3.5'
  | 'lg:pb-3.5'
  | 'xl:pb-3.5'
  | '2xl:pb-3.5'
  | 'sm:pl-0'
  | 'md:pl-0'
  | 'lg:pl-0'
  | 'xl:pl-0'
  | '2xl:pl-0'
  | 'sm:pl-1'
  | 'md:pl-1'
  | 'lg:pl-1'
  | 'xl:pl-1'
  | '2xl:pl-1'
  | 'sm:pl-2'
  | 'md:pl-2'
  | 'lg:pl-2'
  | 'xl:pl-2'
  | '2xl:pl-2'
  | 'sm:pl-3'
  | 'md:pl-3'
  | 'lg:pl-3'
  | 'xl:pl-3'
  | '2xl:pl-3'
  | 'sm:pl-4'
  | 'md:pl-4'
  | 'lg:pl-4'
  | 'xl:pl-4'
  | '2xl:pl-4'
  | 'sm:pl-5'
  | 'md:pl-5'
  | 'lg:pl-5'
  | 'xl:pl-5'
  | '2xl:pl-5'
  | 'sm:pl-6'
  | 'md:pl-6'
  | 'lg:pl-6'
  | 'xl:pl-6'
  | '2xl:pl-6'
  | 'sm:pl-7'
  | 'md:pl-7'
  | 'lg:pl-7'
  | 'xl:pl-7'
  | '2xl:pl-7'
  | 'sm:pl-8'
  | 'md:pl-8'
  | 'lg:pl-8'
  | 'xl:pl-8'
  | '2xl:pl-8'
  | 'sm:pl-9'
  | 'md:pl-9'
  | 'lg:pl-9'
  | 'xl:pl-9'
  | '2xl:pl-9'
  | 'sm:pl-10'
  | 'md:pl-10'
  | 'lg:pl-10'
  | 'xl:pl-10'
  | '2xl:pl-10'
  | 'sm:pl-11'
  | 'md:pl-11'
  | 'lg:pl-11'
  | 'xl:pl-11'
  | '2xl:pl-11'
  | 'sm:pl-12'
  | 'md:pl-12'
  | 'lg:pl-12'
  | 'xl:pl-12'
  | '2xl:pl-12'
  | 'sm:pl-14'
  | 'md:pl-14'
  | 'lg:pl-14'
  | 'xl:pl-14'
  | '2xl:pl-14'
  | 'sm:pl-16'
  | 'md:pl-16'
  | 'lg:pl-16'
  | 'xl:pl-16'
  | '2xl:pl-16'
  | 'sm:pl-20'
  | 'md:pl-20'
  | 'lg:pl-20'
  | 'xl:pl-20'
  | '2xl:pl-20'
  | 'sm:pl-24'
  | 'md:pl-24'
  | 'lg:pl-24'
  | 'xl:pl-24'
  | '2xl:pl-24'
  | 'sm:pl-28'
  | 'md:pl-28'
  | 'lg:pl-28'
  | 'xl:pl-28'
  | '2xl:pl-28'
  | 'sm:pl-32'
  | 'md:pl-32'
  | 'lg:pl-32'
  | 'xl:pl-32'
  | '2xl:pl-32'
  | 'sm:pl-36'
  | 'md:pl-36'
  | 'lg:pl-36'
  | 'xl:pl-36'
  | '2xl:pl-36'
  | 'sm:pl-40'
  | 'md:pl-40'
  | 'lg:pl-40'
  | 'xl:pl-40'
  | '2xl:pl-40'
  | 'sm:pl-44'
  | 'md:pl-44'
  | 'lg:pl-44'
  | 'xl:pl-44'
  | '2xl:pl-44'
  | 'sm:pl-48'
  | 'md:pl-48'
  | 'lg:pl-48'
  | 'xl:pl-48'
  | '2xl:pl-48'
  | 'sm:pl-52'
  | 'md:pl-52'
  | 'lg:pl-52'
  | 'xl:pl-52'
  | '2xl:pl-52'
  | 'sm:pl-56'
  | 'md:pl-56'
  | 'lg:pl-56'
  | 'xl:pl-56'
  | '2xl:pl-56'
  | 'sm:pl-60'
  | 'md:pl-60'
  | 'lg:pl-60'
  | 'xl:pl-60'
  | '2xl:pl-60'
  | 'sm:pl-64'
  | 'md:pl-64'
  | 'lg:pl-64'
  | 'xl:pl-64'
  | '2xl:pl-64'
  | 'sm:pl-72'
  | 'md:pl-72'
  | 'lg:pl-72'
  | 'xl:pl-72'
  | '2xl:pl-72'
  | 'sm:pl-80'
  | 'md:pl-80'
  | 'lg:pl-80'
  | 'xl:pl-80'
  | '2xl:pl-80'
  | 'sm:pl-96'
  | 'md:pl-96'
  | 'lg:pl-96'
  | 'xl:pl-96'
  | '2xl:pl-96'
  | 'sm:pl-px'
  | 'md:pl-px'
  | 'lg:pl-px'
  | 'xl:pl-px'
  | '2xl:pl-px'
  | 'sm:pl-0.5'
  | 'md:pl-0.5'
  | 'lg:pl-0.5'
  | 'xl:pl-0.5'
  | '2xl:pl-0.5'
  | 'sm:pl-1.5'
  | 'md:pl-1.5'
  | 'lg:pl-1.5'
  | 'xl:pl-1.5'
  | '2xl:pl-1.5'
  | 'sm:pl-2.5'
  | 'md:pl-2.5'
  | 'lg:pl-2.5'
  | 'xl:pl-2.5'
  | '2xl:pl-2.5'
  | 'sm:pl-3.5'
  | 'md:pl-3.5'
  | 'lg:pl-3.5'
  | 'xl:pl-3.5'
  | '2xl:pl-3.5'
  | 'sm:place-content-start'
  | 'md:place-content-start'
  | 'lg:place-content-start'
  | 'xl:place-content-start'
  | '2xl:place-content-start'
  | 'sm:place-content-center'
  | 'md:place-content-center'
  | 'lg:place-content-center'
  | 'xl:place-content-center'
  | '2xl:place-content-center'
  | 'sm:place-content-end'
  | 'md:place-content-end'
  | 'lg:place-content-end'
  | 'xl:place-content-end'
  | '2xl:place-content-end'
  | 'sm:place-content-between'
  | 'md:place-content-between'
  | 'lg:place-content-between'
  | 'xl:place-content-between'
  | '2xl:place-content-between'
  | 'sm:place-content-around'
  | 'md:place-content-around'
  | 'lg:place-content-around'
  | 'xl:place-content-around'
  | '2xl:place-content-around'
  | 'sm:place-content-evenly'
  | 'md:place-content-evenly'
  | 'lg:place-content-evenly'
  | 'xl:place-content-evenly'
  | '2xl:place-content-evenly'
  | 'sm:place-content-stretch'
  | 'md:place-content-stretch'
  | 'lg:place-content-stretch'
  | 'xl:place-content-stretch'
  | '2xl:place-content-stretch'
  | 'sm:place-items-auto'
  | 'md:place-items-auto'
  | 'lg:place-items-auto'
  | 'xl:place-items-auto'
  | '2xl:place-items-auto'
  | 'sm:place-items-start'
  | 'md:place-items-start'
  | 'lg:place-items-start'
  | 'xl:place-items-start'
  | '2xl:place-items-start'
  | 'sm:place-items-center'
  | 'md:place-items-center'
  | 'lg:place-items-center'
  | 'xl:place-items-center'
  | '2xl:place-items-center'
  | 'sm:place-items-end'
  | 'md:place-items-end'
  | 'lg:place-items-end'
  | 'xl:place-items-end'
  | '2xl:place-items-end'
  | 'sm:place-items-stretch'
  | 'md:place-items-stretch'
  | 'lg:place-items-stretch'
  | 'xl:place-items-stretch'
  | '2xl:place-items-stretch'
  | 'sm:place-self-auto'
  | 'md:place-self-auto'
  | 'lg:place-self-auto'
  | 'xl:place-self-auto'
  | '2xl:place-self-auto'
  | 'sm:place-self-start'
  | 'md:place-self-start'
  | 'lg:place-self-start'
  | 'xl:place-self-start'
  | '2xl:place-self-start'
  | 'sm:place-self-center'
  | 'md:place-self-center'
  | 'lg:place-self-center'
  | 'xl:place-self-center'
  | '2xl:place-self-center'
  | 'sm:place-self-end'
  | 'md:place-self-end'
  | 'lg:place-self-end'
  | 'xl:place-self-end'
  | '2xl:place-self-end'
  | 'sm:place-self-stretch'
  | 'md:place-self-stretch'
  | 'lg:place-self-stretch'
  | 'xl:place-self-stretch'
  | '2xl:place-self-stretch'
  | 'sm:placeholder-transparent'
  | 'md:placeholder-transparent'
  | 'lg:placeholder-transparent'
  | 'xl:placeholder-transparent'
  | '2xl:placeholder-transparent'
  | 'focus:placeholder-transparent'
  | 'sm:placeholder-current'
  | 'md:placeholder-current'
  | 'lg:placeholder-current'
  | 'xl:placeholder-current'
  | '2xl:placeholder-current'
  | 'focus:placeholder-current'
  | 'sm:placeholder-black'
  | 'md:placeholder-black'
  | 'lg:placeholder-black'
  | 'xl:placeholder-black'
  | '2xl:placeholder-black'
  | 'focus:placeholder-black'
  | 'sm:placeholder-white'
  | 'md:placeholder-white'
  | 'lg:placeholder-white'
  | 'xl:placeholder-white'
  | '2xl:placeholder-white'
  | 'focus:placeholder-white'
  | 'sm:placeholder-gray-50'
  | 'md:placeholder-gray-50'
  | 'lg:placeholder-gray-50'
  | 'xl:placeholder-gray-50'
  | '2xl:placeholder-gray-50'
  | 'focus:placeholder-gray-50'
  | 'sm:placeholder-gray-100'
  | 'md:placeholder-gray-100'
  | 'lg:placeholder-gray-100'
  | 'xl:placeholder-gray-100'
  | '2xl:placeholder-gray-100'
  | 'focus:placeholder-gray-100'
  | 'sm:placeholder-gray-200'
  | 'md:placeholder-gray-200'
  | 'lg:placeholder-gray-200'
  | 'xl:placeholder-gray-200'
  | '2xl:placeholder-gray-200'
  | 'focus:placeholder-gray-200'
  | 'sm:placeholder-gray-300'
  | 'md:placeholder-gray-300'
  | 'lg:placeholder-gray-300'
  | 'xl:placeholder-gray-300'
  | '2xl:placeholder-gray-300'
  | 'focus:placeholder-gray-300'
  | 'sm:placeholder-gray-400'
  | 'md:placeholder-gray-400'
  | 'lg:placeholder-gray-400'
  | 'xl:placeholder-gray-400'
  | '2xl:placeholder-gray-400'
  | 'focus:placeholder-gray-400'
  | 'sm:placeholder-gray-500'
  | 'md:placeholder-gray-500'
  | 'lg:placeholder-gray-500'
  | 'xl:placeholder-gray-500'
  | '2xl:placeholder-gray-500'
  | 'focus:placeholder-gray-500'
  | 'sm:placeholder-gray-600'
  | 'md:placeholder-gray-600'
  | 'lg:placeholder-gray-600'
  | 'xl:placeholder-gray-600'
  | '2xl:placeholder-gray-600'
  | 'focus:placeholder-gray-600'
  | 'sm:placeholder-gray-700'
  | 'md:placeholder-gray-700'
  | 'lg:placeholder-gray-700'
  | 'xl:placeholder-gray-700'
  | '2xl:placeholder-gray-700'
  | 'focus:placeholder-gray-700'
  | 'sm:placeholder-gray-800'
  | 'md:placeholder-gray-800'
  | 'lg:placeholder-gray-800'
  | 'xl:placeholder-gray-800'
  | '2xl:placeholder-gray-800'
  | 'focus:placeholder-gray-800'
  | 'sm:placeholder-gray-900'
  | 'md:placeholder-gray-900'
  | 'lg:placeholder-gray-900'
  | 'xl:placeholder-gray-900'
  | '2xl:placeholder-gray-900'
  | 'focus:placeholder-gray-900'
  | 'sm:placeholder-red-50'
  | 'md:placeholder-red-50'
  | 'lg:placeholder-red-50'
  | 'xl:placeholder-red-50'
  | '2xl:placeholder-red-50'
  | 'focus:placeholder-red-50'
  | 'sm:placeholder-red-100'
  | 'md:placeholder-red-100'
  | 'lg:placeholder-red-100'
  | 'xl:placeholder-red-100'
  | '2xl:placeholder-red-100'
  | 'focus:placeholder-red-100'
  | 'sm:placeholder-red-200'
  | 'md:placeholder-red-200'
  | 'lg:placeholder-red-200'
  | 'xl:placeholder-red-200'
  | '2xl:placeholder-red-200'
  | 'focus:placeholder-red-200'
  | 'sm:placeholder-red-300'
  | 'md:placeholder-red-300'
  | 'lg:placeholder-red-300'
  | 'xl:placeholder-red-300'
  | '2xl:placeholder-red-300'
  | 'focus:placeholder-red-300'
  | 'sm:placeholder-red-400'
  | 'md:placeholder-red-400'
  | 'lg:placeholder-red-400'
  | 'xl:placeholder-red-400'
  | '2xl:placeholder-red-400'
  | 'focus:placeholder-red-400'
  | 'sm:placeholder-red-500'
  | 'md:placeholder-red-500'
  | 'lg:placeholder-red-500'
  | 'xl:placeholder-red-500'
  | '2xl:placeholder-red-500'
  | 'focus:placeholder-red-500'
  | 'sm:placeholder-red-600'
  | 'md:placeholder-red-600'
  | 'lg:placeholder-red-600'
  | 'xl:placeholder-red-600'
  | '2xl:placeholder-red-600'
  | 'focus:placeholder-red-600'
  | 'sm:placeholder-red-700'
  | 'md:placeholder-red-700'
  | 'lg:placeholder-red-700'
  | 'xl:placeholder-red-700'
  | '2xl:placeholder-red-700'
  | 'focus:placeholder-red-700'
  | 'sm:placeholder-red-800'
  | 'md:placeholder-red-800'
  | 'lg:placeholder-red-800'
  | 'xl:placeholder-red-800'
  | '2xl:placeholder-red-800'
  | 'focus:placeholder-red-800'
  | 'sm:placeholder-red-900'
  | 'md:placeholder-red-900'
  | 'lg:placeholder-red-900'
  | 'xl:placeholder-red-900'
  | '2xl:placeholder-red-900'
  | 'focus:placeholder-red-900'
  | 'sm:placeholder-yellow-50'
  | 'md:placeholder-yellow-50'
  | 'lg:placeholder-yellow-50'
  | 'xl:placeholder-yellow-50'
  | '2xl:placeholder-yellow-50'
  | 'focus:placeholder-yellow-50'
  | 'sm:placeholder-yellow-100'
  | 'md:placeholder-yellow-100'
  | 'lg:placeholder-yellow-100'
  | 'xl:placeholder-yellow-100'
  | '2xl:placeholder-yellow-100'
  | 'focus:placeholder-yellow-100'
  | 'sm:placeholder-yellow-200'
  | 'md:placeholder-yellow-200'
  | 'lg:placeholder-yellow-200'
  | 'xl:placeholder-yellow-200'
  | '2xl:placeholder-yellow-200'
  | 'focus:placeholder-yellow-200'
  | 'sm:placeholder-yellow-300'
  | 'md:placeholder-yellow-300'
  | 'lg:placeholder-yellow-300'
  | 'xl:placeholder-yellow-300'
  | '2xl:placeholder-yellow-300'
  | 'focus:placeholder-yellow-300'
  | 'sm:placeholder-yellow-400'
  | 'md:placeholder-yellow-400'
  | 'lg:placeholder-yellow-400'
  | 'xl:placeholder-yellow-400'
  | '2xl:placeholder-yellow-400'
  | 'focus:placeholder-yellow-400'
  | 'sm:placeholder-yellow-500'
  | 'md:placeholder-yellow-500'
  | 'lg:placeholder-yellow-500'
  | 'xl:placeholder-yellow-500'
  | '2xl:placeholder-yellow-500'
  | 'focus:placeholder-yellow-500'
  | 'sm:placeholder-yellow-600'
  | 'md:placeholder-yellow-600'
  | 'lg:placeholder-yellow-600'
  | 'xl:placeholder-yellow-600'
  | '2xl:placeholder-yellow-600'
  | 'focus:placeholder-yellow-600'
  | 'sm:placeholder-yellow-700'
  | 'md:placeholder-yellow-700'
  | 'lg:placeholder-yellow-700'
  | 'xl:placeholder-yellow-700'
  | '2xl:placeholder-yellow-700'
  | 'focus:placeholder-yellow-700'
  | 'sm:placeholder-yellow-800'
  | 'md:placeholder-yellow-800'
  | 'lg:placeholder-yellow-800'
  | 'xl:placeholder-yellow-800'
  | '2xl:placeholder-yellow-800'
  | 'focus:placeholder-yellow-800'
  | 'sm:placeholder-yellow-900'
  | 'md:placeholder-yellow-900'
  | 'lg:placeholder-yellow-900'
  | 'xl:placeholder-yellow-900'
  | '2xl:placeholder-yellow-900'
  | 'focus:placeholder-yellow-900'
  | 'sm:placeholder-green-50'
  | 'md:placeholder-green-50'
  | 'lg:placeholder-green-50'
  | 'xl:placeholder-green-50'
  | '2xl:placeholder-green-50'
  | 'focus:placeholder-green-50'
  | 'sm:placeholder-green-100'
  | 'md:placeholder-green-100'
  | 'lg:placeholder-green-100'
  | 'xl:placeholder-green-100'
  | '2xl:placeholder-green-100'
  | 'focus:placeholder-green-100'
  | 'sm:placeholder-green-200'
  | 'md:placeholder-green-200'
  | 'lg:placeholder-green-200'
  | 'xl:placeholder-green-200'
  | '2xl:placeholder-green-200'
  | 'focus:placeholder-green-200'
  | 'sm:placeholder-green-300'
  | 'md:placeholder-green-300'
  | 'lg:placeholder-green-300'
  | 'xl:placeholder-green-300'
  | '2xl:placeholder-green-300'
  | 'focus:placeholder-green-300'
  | 'sm:placeholder-green-400'
  | 'md:placeholder-green-400'
  | 'lg:placeholder-green-400'
  | 'xl:placeholder-green-400'
  | '2xl:placeholder-green-400'
  | 'focus:placeholder-green-400'
  | 'sm:placeholder-green-500'
  | 'md:placeholder-green-500'
  | 'lg:placeholder-green-500'
  | 'xl:placeholder-green-500'
  | '2xl:placeholder-green-500'
  | 'focus:placeholder-green-500'
  | 'sm:placeholder-green-600'
  | 'md:placeholder-green-600'
  | 'lg:placeholder-green-600'
  | 'xl:placeholder-green-600'
  | '2xl:placeholder-green-600'
  | 'focus:placeholder-green-600'
  | 'sm:placeholder-green-700'
  | 'md:placeholder-green-700'
  | 'lg:placeholder-green-700'
  | 'xl:placeholder-green-700'
  | '2xl:placeholder-green-700'
  | 'focus:placeholder-green-700'
  | 'sm:placeholder-green-800'
  | 'md:placeholder-green-800'
  | 'lg:placeholder-green-800'
  | 'xl:placeholder-green-800'
  | '2xl:placeholder-green-800'
  | 'focus:placeholder-green-800'
  | 'sm:placeholder-green-900'
  | 'md:placeholder-green-900'
  | 'lg:placeholder-green-900'
  | 'xl:placeholder-green-900'
  | '2xl:placeholder-green-900'
  | 'focus:placeholder-green-900'
  | 'sm:placeholder-blue-50'
  | 'md:placeholder-blue-50'
  | 'lg:placeholder-blue-50'
  | 'xl:placeholder-blue-50'
  | '2xl:placeholder-blue-50'
  | 'focus:placeholder-blue-50'
  | 'sm:placeholder-blue-100'
  | 'md:placeholder-blue-100'
  | 'lg:placeholder-blue-100'
  | 'xl:placeholder-blue-100'
  | '2xl:placeholder-blue-100'
  | 'focus:placeholder-blue-100'
  | 'sm:placeholder-blue-200'
  | 'md:placeholder-blue-200'
  | 'lg:placeholder-blue-200'
  | 'xl:placeholder-blue-200'
  | '2xl:placeholder-blue-200'
  | 'focus:placeholder-blue-200'
  | 'sm:placeholder-blue-300'
  | 'md:placeholder-blue-300'
  | 'lg:placeholder-blue-300'
  | 'xl:placeholder-blue-300'
  | '2xl:placeholder-blue-300'
  | 'focus:placeholder-blue-300'
  | 'sm:placeholder-blue-400'
  | 'md:placeholder-blue-400'
  | 'lg:placeholder-blue-400'
  | 'xl:placeholder-blue-400'
  | '2xl:placeholder-blue-400'
  | 'focus:placeholder-blue-400'
  | 'sm:placeholder-blue-500'
  | 'md:placeholder-blue-500'
  | 'lg:placeholder-blue-500'
  | 'xl:placeholder-blue-500'
  | '2xl:placeholder-blue-500'
  | 'focus:placeholder-blue-500'
  | 'sm:placeholder-blue-600'
  | 'md:placeholder-blue-600'
  | 'lg:placeholder-blue-600'
  | 'xl:placeholder-blue-600'
  | '2xl:placeholder-blue-600'
  | 'focus:placeholder-blue-600'
  | 'sm:placeholder-blue-700'
  | 'md:placeholder-blue-700'
  | 'lg:placeholder-blue-700'
  | 'xl:placeholder-blue-700'
  | '2xl:placeholder-blue-700'
  | 'focus:placeholder-blue-700'
  | 'sm:placeholder-blue-800'
  | 'md:placeholder-blue-800'
  | 'lg:placeholder-blue-800'
  | 'xl:placeholder-blue-800'
  | '2xl:placeholder-blue-800'
  | 'focus:placeholder-blue-800'
  | 'sm:placeholder-blue-900'
  | 'md:placeholder-blue-900'
  | 'lg:placeholder-blue-900'
  | 'xl:placeholder-blue-900'
  | '2xl:placeholder-blue-900'
  | 'focus:placeholder-blue-900'
  | 'sm:placeholder-indigo-50'
  | 'md:placeholder-indigo-50'
  | 'lg:placeholder-indigo-50'
  | 'xl:placeholder-indigo-50'
  | '2xl:placeholder-indigo-50'
  | 'focus:placeholder-indigo-50'
  | 'sm:placeholder-indigo-100'
  | 'md:placeholder-indigo-100'
  | 'lg:placeholder-indigo-100'
  | 'xl:placeholder-indigo-100'
  | '2xl:placeholder-indigo-100'
  | 'focus:placeholder-indigo-100'
  | 'sm:placeholder-indigo-200'
  | 'md:placeholder-indigo-200'
  | 'lg:placeholder-indigo-200'
  | 'xl:placeholder-indigo-200'
  | '2xl:placeholder-indigo-200'
  | 'focus:placeholder-indigo-200'
  | 'sm:placeholder-indigo-300'
  | 'md:placeholder-indigo-300'
  | 'lg:placeholder-indigo-300'
  | 'xl:placeholder-indigo-300'
  | '2xl:placeholder-indigo-300'
  | 'focus:placeholder-indigo-300'
  | 'sm:placeholder-indigo-400'
  | 'md:placeholder-indigo-400'
  | 'lg:placeholder-indigo-400'
  | 'xl:placeholder-indigo-400'
  | '2xl:placeholder-indigo-400'
  | 'focus:placeholder-indigo-400'
  | 'sm:placeholder-indigo-500'
  | 'md:placeholder-indigo-500'
  | 'lg:placeholder-indigo-500'
  | 'xl:placeholder-indigo-500'
  | '2xl:placeholder-indigo-500'
  | 'focus:placeholder-indigo-500'
  | 'sm:placeholder-indigo-600'
  | 'md:placeholder-indigo-600'
  | 'lg:placeholder-indigo-600'
  | 'xl:placeholder-indigo-600'
  | '2xl:placeholder-indigo-600'
  | 'focus:placeholder-indigo-600'
  | 'sm:placeholder-indigo-700'
  | 'md:placeholder-indigo-700'
  | 'lg:placeholder-indigo-700'
  | 'xl:placeholder-indigo-700'
  | '2xl:placeholder-indigo-700'
  | 'focus:placeholder-indigo-700'
  | 'sm:placeholder-indigo-800'
  | 'md:placeholder-indigo-800'
  | 'lg:placeholder-indigo-800'
  | 'xl:placeholder-indigo-800'
  | '2xl:placeholder-indigo-800'
  | 'focus:placeholder-indigo-800'
  | 'sm:placeholder-indigo-900'
  | 'md:placeholder-indigo-900'
  | 'lg:placeholder-indigo-900'
  | 'xl:placeholder-indigo-900'
  | '2xl:placeholder-indigo-900'
  | 'focus:placeholder-indigo-900'
  | 'sm:placeholder-purple-50'
  | 'md:placeholder-purple-50'
  | 'lg:placeholder-purple-50'
  | 'xl:placeholder-purple-50'
  | '2xl:placeholder-purple-50'
  | 'focus:placeholder-purple-50'
  | 'sm:placeholder-purple-100'
  | 'md:placeholder-purple-100'
  | 'lg:placeholder-purple-100'
  | 'xl:placeholder-purple-100'
  | '2xl:placeholder-purple-100'
  | 'focus:placeholder-purple-100'
  | 'sm:placeholder-purple-200'
  | 'md:placeholder-purple-200'
  | 'lg:placeholder-purple-200'
  | 'xl:placeholder-purple-200'
  | '2xl:placeholder-purple-200'
  | 'focus:placeholder-purple-200'
  | 'sm:placeholder-purple-300'
  | 'md:placeholder-purple-300'
  | 'lg:placeholder-purple-300'
  | 'xl:placeholder-purple-300'
  | '2xl:placeholder-purple-300'
  | 'focus:placeholder-purple-300'
  | 'sm:placeholder-purple-400'
  | 'md:placeholder-purple-400'
  | 'lg:placeholder-purple-400'
  | 'xl:placeholder-purple-400'
  | '2xl:placeholder-purple-400'
  | 'focus:placeholder-purple-400'
  | 'sm:placeholder-purple-500'
  | 'md:placeholder-purple-500'
  | 'lg:placeholder-purple-500'
  | 'xl:placeholder-purple-500'
  | '2xl:placeholder-purple-500'
  | 'focus:placeholder-purple-500'
  | 'sm:placeholder-purple-600'
  | 'md:placeholder-purple-600'
  | 'lg:placeholder-purple-600'
  | 'xl:placeholder-purple-600'
  | '2xl:placeholder-purple-600'
  | 'focus:placeholder-purple-600'
  | 'sm:placeholder-purple-700'
  | 'md:placeholder-purple-700'
  | 'lg:placeholder-purple-700'
  | 'xl:placeholder-purple-700'
  | '2xl:placeholder-purple-700'
  | 'focus:placeholder-purple-700'
  | 'sm:placeholder-purple-800'
  | 'md:placeholder-purple-800'
  | 'lg:placeholder-purple-800'
  | 'xl:placeholder-purple-800'
  | '2xl:placeholder-purple-800'
  | 'focus:placeholder-purple-800'
  | 'sm:placeholder-purple-900'
  | 'md:placeholder-purple-900'
  | 'lg:placeholder-purple-900'
  | 'xl:placeholder-purple-900'
  | '2xl:placeholder-purple-900'
  | 'focus:placeholder-purple-900'
  | 'sm:placeholder-pink-50'
  | 'md:placeholder-pink-50'
  | 'lg:placeholder-pink-50'
  | 'xl:placeholder-pink-50'
  | '2xl:placeholder-pink-50'
  | 'focus:placeholder-pink-50'
  | 'sm:placeholder-pink-100'
  | 'md:placeholder-pink-100'
  | 'lg:placeholder-pink-100'
  | 'xl:placeholder-pink-100'
  | '2xl:placeholder-pink-100'
  | 'focus:placeholder-pink-100'
  | 'sm:placeholder-pink-200'
  | 'md:placeholder-pink-200'
  | 'lg:placeholder-pink-200'
  | 'xl:placeholder-pink-200'
  | '2xl:placeholder-pink-200'
  | 'focus:placeholder-pink-200'
  | 'sm:placeholder-pink-300'
  | 'md:placeholder-pink-300'
  | 'lg:placeholder-pink-300'
  | 'xl:placeholder-pink-300'
  | '2xl:placeholder-pink-300'
  | 'focus:placeholder-pink-300'
  | 'sm:placeholder-pink-400'
  | 'md:placeholder-pink-400'
  | 'lg:placeholder-pink-400'
  | 'xl:placeholder-pink-400'
  | '2xl:placeholder-pink-400'
  | 'focus:placeholder-pink-400'
  | 'sm:placeholder-pink-500'
  | 'md:placeholder-pink-500'
  | 'lg:placeholder-pink-500'
  | 'xl:placeholder-pink-500'
  | '2xl:placeholder-pink-500'
  | 'focus:placeholder-pink-500'
  | 'sm:placeholder-pink-600'
  | 'md:placeholder-pink-600'
  | 'lg:placeholder-pink-600'
  | 'xl:placeholder-pink-600'
  | '2xl:placeholder-pink-600'
  | 'focus:placeholder-pink-600'
  | 'sm:placeholder-pink-700'
  | 'md:placeholder-pink-700'
  | 'lg:placeholder-pink-700'
  | 'xl:placeholder-pink-700'
  | '2xl:placeholder-pink-700'
  | 'focus:placeholder-pink-700'
  | 'sm:placeholder-pink-800'
  | 'md:placeholder-pink-800'
  | 'lg:placeholder-pink-800'
  | 'xl:placeholder-pink-800'
  | '2xl:placeholder-pink-800'
  | 'focus:placeholder-pink-800'
  | 'sm:placeholder-pink-900'
  | 'md:placeholder-pink-900'
  | 'lg:placeholder-pink-900'
  | 'xl:placeholder-pink-900'
  | '2xl:placeholder-pink-900'
  | 'focus:placeholder-pink-900'
  | 'sm:placeholder-opacity-0'
  | 'md:placeholder-opacity-0'
  | 'lg:placeholder-opacity-0'
  | 'xl:placeholder-opacity-0'
  | '2xl:placeholder-opacity-0'
  | 'focus:placeholder-opacity-0'
  | 'sm:placeholder-opacity-5'
  | 'md:placeholder-opacity-5'
  | 'lg:placeholder-opacity-5'
  | 'xl:placeholder-opacity-5'
  | '2xl:placeholder-opacity-5'
  | 'focus:placeholder-opacity-5'
  | 'sm:placeholder-opacity-10'
  | 'md:placeholder-opacity-10'
  | 'lg:placeholder-opacity-10'
  | 'xl:placeholder-opacity-10'
  | '2xl:placeholder-opacity-10'
  | 'focus:placeholder-opacity-10'
  | 'sm:placeholder-opacity-20'
  | 'md:placeholder-opacity-20'
  | 'lg:placeholder-opacity-20'
  | 'xl:placeholder-opacity-20'
  | '2xl:placeholder-opacity-20'
  | 'focus:placeholder-opacity-20'
  | 'sm:placeholder-opacity-25'
  | 'md:placeholder-opacity-25'
  | 'lg:placeholder-opacity-25'
  | 'xl:placeholder-opacity-25'
  | '2xl:placeholder-opacity-25'
  | 'focus:placeholder-opacity-25'
  | 'sm:placeholder-opacity-30'
  | 'md:placeholder-opacity-30'
  | 'lg:placeholder-opacity-30'
  | 'xl:placeholder-opacity-30'
  | '2xl:placeholder-opacity-30'
  | 'focus:placeholder-opacity-30'
  | 'sm:placeholder-opacity-40'
  | 'md:placeholder-opacity-40'
  | 'lg:placeholder-opacity-40'
  | 'xl:placeholder-opacity-40'
  | '2xl:placeholder-opacity-40'
  | 'focus:placeholder-opacity-40'
  | 'sm:placeholder-opacity-50'
  | 'md:placeholder-opacity-50'
  | 'lg:placeholder-opacity-50'
  | 'xl:placeholder-opacity-50'
  | '2xl:placeholder-opacity-50'
  | 'focus:placeholder-opacity-50'
  | 'sm:placeholder-opacity-60'
  | 'md:placeholder-opacity-60'
  | 'lg:placeholder-opacity-60'
  | 'xl:placeholder-opacity-60'
  | '2xl:placeholder-opacity-60'
  | 'focus:placeholder-opacity-60'
  | 'sm:placeholder-opacity-70'
  | 'md:placeholder-opacity-70'
  | 'lg:placeholder-opacity-70'
  | 'xl:placeholder-opacity-70'
  | '2xl:placeholder-opacity-70'
  | 'focus:placeholder-opacity-70'
  | 'sm:placeholder-opacity-75'
  | 'md:placeholder-opacity-75'
  | 'lg:placeholder-opacity-75'
  | 'xl:placeholder-opacity-75'
  | '2xl:placeholder-opacity-75'
  | 'focus:placeholder-opacity-75'
  | 'sm:placeholder-opacity-80'
  | 'md:placeholder-opacity-80'
  | 'lg:placeholder-opacity-80'
  | 'xl:placeholder-opacity-80'
  | '2xl:placeholder-opacity-80'
  | 'focus:placeholder-opacity-80'
  | 'sm:placeholder-opacity-90'
  | 'md:placeholder-opacity-90'
  | 'lg:placeholder-opacity-90'
  | 'xl:placeholder-opacity-90'
  | '2xl:placeholder-opacity-90'
  | 'focus:placeholder-opacity-90'
  | 'sm:placeholder-opacity-95'
  | 'md:placeholder-opacity-95'
  | 'lg:placeholder-opacity-95'
  | 'xl:placeholder-opacity-95'
  | '2xl:placeholder-opacity-95'
  | 'focus:placeholder-opacity-95'
  | 'sm:placeholder-opacity-100'
  | 'md:placeholder-opacity-100'
  | 'lg:placeholder-opacity-100'
  | 'xl:placeholder-opacity-100'
  | '2xl:placeholder-opacity-100'
  | 'focus:placeholder-opacity-100'
  | 'sm:pointer-events-none'
  | 'md:pointer-events-none'
  | 'lg:pointer-events-none'
  | 'xl:pointer-events-none'
  | '2xl:pointer-events-none'
  | 'sm:pointer-events-auto'
  | 'md:pointer-events-auto'
  | 'lg:pointer-events-auto'
  | 'xl:pointer-events-auto'
  | '2xl:pointer-events-auto'
  | 'sm:static'
  | 'md:static'
  | 'lg:static'
  | 'xl:static'
  | '2xl:static'
  | 'sm:fixed'
  | 'md:fixed'
  | 'lg:fixed'
  | 'xl:fixed'
  | '2xl:fixed'
  | 'sm:absolute'
  | 'md:absolute'
  | 'lg:absolute'
  | 'xl:absolute'
  | '2xl:absolute'
  | 'sm:relative'
  | 'md:relative'
  | 'lg:relative'
  | 'xl:relative'
  | '2xl:relative'
  | 'sm:sticky'
  | 'md:sticky'
  | 'lg:sticky'
  | 'xl:sticky'
  | '2xl:sticky'
  | 'sm:resize-none'
  | 'md:resize-none'
  | 'lg:resize-none'
  | 'xl:resize-none'
  | '2xl:resize-none'
  | 'sm:resize'
  | 'md:resize'
  | 'lg:resize'
  | 'xl:resize'
  | '2xl:resize'
  | 'sm:resize-y'
  | 'md:resize-y'
  | 'lg:resize-y'
  | 'xl:resize-y'
  | '2xl:resize-y'
  | 'sm:resize-x'
  | 'md:resize-x'
  | 'lg:resize-x'
  | 'xl:resize-x'
  | '2xl:resize-x'
  | 'sm:ring-transparent'
  | 'md:ring-transparent'
  | 'lg:ring-transparent'
  | 'xl:ring-transparent'
  | '2xl:ring-transparent'
  | 'focus-within:ring-transparent'
  | 'focus:ring-transparent'
  | 'sm:ring-current'
  | 'md:ring-current'
  | 'lg:ring-current'
  | 'xl:ring-current'
  | '2xl:ring-current'
  | 'focus-within:ring-current'
  | 'focus:ring-current'
  | 'sm:ring-black'
  | 'md:ring-black'
  | 'lg:ring-black'
  | 'xl:ring-black'
  | '2xl:ring-black'
  | 'focus-within:ring-black'
  | 'focus:ring-black'
  | 'sm:ring-white'
  | 'md:ring-white'
  | 'lg:ring-white'
  | 'xl:ring-white'
  | '2xl:ring-white'
  | 'focus-within:ring-white'
  | 'focus:ring-white'
  | 'sm:ring-gray'
  | 'md:ring-gray'
  | 'lg:ring-gray'
  | 'xl:ring-gray'
  | '2xl:ring-gray'
  | 'focus-within:ring-gray'
  | 'focus:ring-gray'
  | 'sm:ring-red-50'
  | 'md:ring-red-50'
  | 'lg:ring-red-50'
  | 'xl:ring-red-50'
  | '2xl:ring-red-50'
  | 'focus-within:ring-red-50'
  | 'focus:ring-red-50'
  | 'sm:ring-red-100'
  | 'md:ring-red-100'
  | 'lg:ring-red-100'
  | 'xl:ring-red-100'
  | '2xl:ring-red-100'
  | 'focus-within:ring-red-100'
  | 'focus:ring-red-100'
  | 'sm:ring-red-200'
  | 'md:ring-red-200'
  | 'lg:ring-red-200'
  | 'xl:ring-red-200'
  | '2xl:ring-red-200'
  | 'focus-within:ring-red-200'
  | 'focus:ring-red-200'
  | 'sm:ring-red-300'
  | 'md:ring-red-300'
  | 'lg:ring-red-300'
  | 'xl:ring-red-300'
  | '2xl:ring-red-300'
  | 'focus-within:ring-red-300'
  | 'focus:ring-red-300'
  | 'sm:ring-red-400'
  | 'md:ring-red-400'
  | 'lg:ring-red-400'
  | 'xl:ring-red-400'
  | '2xl:ring-red-400'
  | 'focus-within:ring-red-400'
  | 'focus:ring-red-400'
  | 'sm:ring-red-500'
  | 'md:ring-red-500'
  | 'lg:ring-red-500'
  | 'xl:ring-red-500'
  | '2xl:ring-red-500'
  | 'focus-within:ring-red-500'
  | 'focus:ring-red-500'
  | 'sm:ring-red-600'
  | 'md:ring-red-600'
  | 'lg:ring-red-600'
  | 'xl:ring-red-600'
  | '2xl:ring-red-600'
  | 'focus-within:ring-red-600'
  | 'focus:ring-red-600'
  | 'sm:ring-red-700'
  | 'md:ring-red-700'
  | 'lg:ring-red-700'
  | 'xl:ring-red-700'
  | '2xl:ring-red-700'
  | 'focus-within:ring-red-700'
  | 'focus:ring-red-700'
  | 'sm:ring-red-800'
  | 'md:ring-red-800'
  | 'lg:ring-red-800'
  | 'xl:ring-red-800'
  | '2xl:ring-red-800'
  | 'focus-within:ring-red-800'
  | 'focus:ring-red-800'
  | 'sm:ring-red-900'
  | 'md:ring-red-900'
  | 'lg:ring-red-900'
  | 'xl:ring-red-900'
  | '2xl:ring-red-900'
  | 'focus-within:ring-red-900'
  | 'focus:ring-red-900'
  | 'sm:ring-yellow-50'
  | 'md:ring-yellow-50'
  | 'lg:ring-yellow-50'
  | 'xl:ring-yellow-50'
  | '2xl:ring-yellow-50'
  | 'focus-within:ring-yellow-50'
  | 'focus:ring-yellow-50'
  | 'sm:ring-yellow-100'
  | 'md:ring-yellow-100'
  | 'lg:ring-yellow-100'
  | 'xl:ring-yellow-100'
  | '2xl:ring-yellow-100'
  | 'focus-within:ring-yellow-100'
  | 'focus:ring-yellow-100'
  | 'sm:ring-yellow-200'
  | 'md:ring-yellow-200'
  | 'lg:ring-yellow-200'
  | 'xl:ring-yellow-200'
  | '2xl:ring-yellow-200'
  | 'focus-within:ring-yellow-200'
  | 'focus:ring-yellow-200'
  | 'sm:ring-yellow-300'
  | 'md:ring-yellow-300'
  | 'lg:ring-yellow-300'
  | 'xl:ring-yellow-300'
  | '2xl:ring-yellow-300'
  | 'focus-within:ring-yellow-300'
  | 'focus:ring-yellow-300'
  | 'sm:ring-yellow-400'
  | 'md:ring-yellow-400'
  | 'lg:ring-yellow-400'
  | 'xl:ring-yellow-400'
  | '2xl:ring-yellow-400'
  | 'focus-within:ring-yellow-400'
  | 'focus:ring-yellow-400'
  | 'sm:ring-yellow-500'
  | 'md:ring-yellow-500'
  | 'lg:ring-yellow-500'
  | 'xl:ring-yellow-500'
  | '2xl:ring-yellow-500'
  | 'focus-within:ring-yellow-500'
  | 'focus:ring-yellow-500'
  | 'sm:ring-yellow-600'
  | 'md:ring-yellow-600'
  | 'lg:ring-yellow-600'
  | 'xl:ring-yellow-600'
  | '2xl:ring-yellow-600'
  | 'focus-within:ring-yellow-600'
  | 'focus:ring-yellow-600'
  | 'sm:ring-yellow-700'
  | 'md:ring-yellow-700'
  | 'lg:ring-yellow-700'
  | 'xl:ring-yellow-700'
  | '2xl:ring-yellow-700'
  | 'focus-within:ring-yellow-700'
  | 'focus:ring-yellow-700'
  | 'sm:ring-yellow-800'
  | 'md:ring-yellow-800'
  | 'lg:ring-yellow-800'
  | 'xl:ring-yellow-800'
  | '2xl:ring-yellow-800'
  | 'focus-within:ring-yellow-800'
  | 'focus:ring-yellow-800'
  | 'sm:ring-yellow-900'
  | 'md:ring-yellow-900'
  | 'lg:ring-yellow-900'
  | 'xl:ring-yellow-900'
  | '2xl:ring-yellow-900'
  | 'focus-within:ring-yellow-900'
  | 'focus:ring-yellow-900'
  | 'sm:ring-green-50'
  | 'md:ring-green-50'
  | 'lg:ring-green-50'
  | 'xl:ring-green-50'
  | '2xl:ring-green-50'
  | 'focus-within:ring-green-50'
  | 'focus:ring-green-50'
  | 'sm:ring-green-100'
  | 'md:ring-green-100'
  | 'lg:ring-green-100'
  | 'xl:ring-green-100'
  | '2xl:ring-green-100'
  | 'focus-within:ring-green-100'
  | 'focus:ring-green-100'
  | 'sm:ring-green-200'
  | 'md:ring-green-200'
  | 'lg:ring-green-200'
  | 'xl:ring-green-200'
  | '2xl:ring-green-200'
  | 'focus-within:ring-green-200'
  | 'focus:ring-green-200'
  | 'sm:ring-green-300'
  | 'md:ring-green-300'
  | 'lg:ring-green-300'
  | 'xl:ring-green-300'
  | '2xl:ring-green-300'
  | 'focus-within:ring-green-300'
  | 'focus:ring-green-300'
  | 'sm:ring-green-400'
  | 'md:ring-green-400'
  | 'lg:ring-green-400'
  | 'xl:ring-green-400'
  | '2xl:ring-green-400'
  | 'focus-within:ring-green-400'
  | 'focus:ring-green-400'
  | 'sm:ring-green-500'
  | 'md:ring-green-500'
  | 'lg:ring-green-500'
  | 'xl:ring-green-500'
  | '2xl:ring-green-500'
  | 'focus-within:ring-green-500'
  | 'focus:ring-green-500'
  | 'sm:ring-green-600'
  | 'md:ring-green-600'
  | 'lg:ring-green-600'
  | 'xl:ring-green-600'
  | '2xl:ring-green-600'
  | 'focus-within:ring-green-600'
  | 'focus:ring-green-600'
  | 'sm:ring-green-700'
  | 'md:ring-green-700'
  | 'lg:ring-green-700'
  | 'xl:ring-green-700'
  | '2xl:ring-green-700'
  | 'focus-within:ring-green-700'
  | 'focus:ring-green-700'
  | 'sm:ring-green-800'
  | 'md:ring-green-800'
  | 'lg:ring-green-800'
  | 'xl:ring-green-800'
  | '2xl:ring-green-800'
  | 'focus-within:ring-green-800'
  | 'focus:ring-green-800'
  | 'sm:ring-green-900'
  | 'md:ring-green-900'
  | 'lg:ring-green-900'
  | 'xl:ring-green-900'
  | '2xl:ring-green-900'
  | 'focus-within:ring-green-900'
  | 'focus:ring-green-900'
  | 'sm:ring-blue-50'
  | 'md:ring-blue-50'
  | 'lg:ring-blue-50'
  | 'xl:ring-blue-50'
  | '2xl:ring-blue-50'
  | 'focus-within:ring-blue-50'
  | 'focus:ring-blue-50'
  | 'sm:ring-blue-100'
  | 'md:ring-blue-100'
  | 'lg:ring-blue-100'
  | 'xl:ring-blue-100'
  | '2xl:ring-blue-100'
  | 'focus-within:ring-blue-100'
  | 'focus:ring-blue-100'
  | 'sm:ring-blue-200'
  | 'md:ring-blue-200'
  | 'lg:ring-blue-200'
  | 'xl:ring-blue-200'
  | '2xl:ring-blue-200'
  | 'focus-within:ring-blue-200'
  | 'focus:ring-blue-200'
  | 'sm:ring-blue-300'
  | 'md:ring-blue-300'
  | 'lg:ring-blue-300'
  | 'xl:ring-blue-300'
  | '2xl:ring-blue-300'
  | 'focus-within:ring-blue-300'
  | 'focus:ring-blue-300'
  | 'sm:ring-blue-400'
  | 'md:ring-blue-400'
  | 'lg:ring-blue-400'
  | 'xl:ring-blue-400'
  | '2xl:ring-blue-400'
  | 'focus-within:ring-blue-400'
  | 'focus:ring-blue-400'
  | 'sm:ring-blue-500'
  | 'md:ring-blue-500'
  | 'lg:ring-blue-500'
  | 'xl:ring-blue-500'
  | '2xl:ring-blue-500'
  | 'focus-within:ring-blue-500'
  | 'focus:ring-blue-500'
  | 'sm:ring-blue-600'
  | 'md:ring-blue-600'
  | 'lg:ring-blue-600'
  | 'xl:ring-blue-600'
  | '2xl:ring-blue-600'
  | 'focus-within:ring-blue-600'
  | 'focus:ring-blue-600'
  | 'sm:ring-blue-700'
  | 'md:ring-blue-700'
  | 'lg:ring-blue-700'
  | 'xl:ring-blue-700'
  | '2xl:ring-blue-700'
  | 'focus-within:ring-blue-700'
  | 'focus:ring-blue-700'
  | 'sm:ring-blue-800'
  | 'md:ring-blue-800'
  | 'lg:ring-blue-800'
  | 'xl:ring-blue-800'
  | '2xl:ring-blue-800'
  | 'focus-within:ring-blue-800'
  | 'focus:ring-blue-800'
  | 'sm:ring-blue-900'
  | 'md:ring-blue-900'
  | 'lg:ring-blue-900'
  | 'xl:ring-blue-900'
  | '2xl:ring-blue-900'
  | 'focus-within:ring-blue-900'
  | 'focus:ring-blue-900'
  | 'sm:ring-indigo-50'
  | 'md:ring-indigo-50'
  | 'lg:ring-indigo-50'
  | 'xl:ring-indigo-50'
  | '2xl:ring-indigo-50'
  | 'focus-within:ring-indigo-50'
  | 'focus:ring-indigo-50'
  | 'sm:ring-indigo-100'
  | 'md:ring-indigo-100'
  | 'lg:ring-indigo-100'
  | 'xl:ring-indigo-100'
  | '2xl:ring-indigo-100'
  | 'focus-within:ring-indigo-100'
  | 'focus:ring-indigo-100'
  | 'sm:ring-indigo-200'
  | 'md:ring-indigo-200'
  | 'lg:ring-indigo-200'
  | 'xl:ring-indigo-200'
  | '2xl:ring-indigo-200'
  | 'focus-within:ring-indigo-200'
  | 'focus:ring-indigo-200'
  | 'sm:ring-indigo-300'
  | 'md:ring-indigo-300'
  | 'lg:ring-indigo-300'
  | 'xl:ring-indigo-300'
  | '2xl:ring-indigo-300'
  | 'focus-within:ring-indigo-300'
  | 'focus:ring-indigo-300'
  | 'sm:ring-indigo-400'
  | 'md:ring-indigo-400'
  | 'lg:ring-indigo-400'
  | 'xl:ring-indigo-400'
  | '2xl:ring-indigo-400'
  | 'focus-within:ring-indigo-400'
  | 'focus:ring-indigo-400'
  | 'sm:ring-indigo-500'
  | 'md:ring-indigo-500'
  | 'lg:ring-indigo-500'
  | 'xl:ring-indigo-500'
  | '2xl:ring-indigo-500'
  | 'focus-within:ring-indigo-500'
  | 'focus:ring-indigo-500'
  | 'sm:ring-indigo-600'
  | 'md:ring-indigo-600'
  | 'lg:ring-indigo-600'
  | 'xl:ring-indigo-600'
  | '2xl:ring-indigo-600'
  | 'focus-within:ring-indigo-600'
  | 'focus:ring-indigo-600'
  | 'sm:ring-indigo-700'
  | 'md:ring-indigo-700'
  | 'lg:ring-indigo-700'
  | 'xl:ring-indigo-700'
  | '2xl:ring-indigo-700'
  | 'focus-within:ring-indigo-700'
  | 'focus:ring-indigo-700'
  | 'sm:ring-indigo-800'
  | 'md:ring-indigo-800'
  | 'lg:ring-indigo-800'
  | 'xl:ring-indigo-800'
  | '2xl:ring-indigo-800'
  | 'focus-within:ring-indigo-800'
  | 'focus:ring-indigo-800'
  | 'sm:ring-indigo-900'
  | 'md:ring-indigo-900'
  | 'lg:ring-indigo-900'
  | 'xl:ring-indigo-900'
  | '2xl:ring-indigo-900'
  | 'focus-within:ring-indigo-900'
  | 'focus:ring-indigo-900'
  | 'sm:ring-purple-50'
  | 'md:ring-purple-50'
  | 'lg:ring-purple-50'
  | 'xl:ring-purple-50'
  | '2xl:ring-purple-50'
  | 'focus-within:ring-purple-50'
  | 'focus:ring-purple-50'
  | 'sm:ring-purple-100'
  | 'md:ring-purple-100'
  | 'lg:ring-purple-100'
  | 'xl:ring-purple-100'
  | '2xl:ring-purple-100'
  | 'focus-within:ring-purple-100'
  | 'focus:ring-purple-100'
  | 'sm:ring-purple-200'
  | 'md:ring-purple-200'
  | 'lg:ring-purple-200'
  | 'xl:ring-purple-200'
  | '2xl:ring-purple-200'
  | 'focus-within:ring-purple-200'
  | 'focus:ring-purple-200'
  | 'sm:ring-purple-300'
  | 'md:ring-purple-300'
  | 'lg:ring-purple-300'
  | 'xl:ring-purple-300'
  | '2xl:ring-purple-300'
  | 'focus-within:ring-purple-300'
  | 'focus:ring-purple-300'
  | 'sm:ring-purple-400'
  | 'md:ring-purple-400'
  | 'lg:ring-purple-400'
  | 'xl:ring-purple-400'
  | '2xl:ring-purple-400'
  | 'focus-within:ring-purple-400'
  | 'focus:ring-purple-400'
  | 'sm:ring-purple-500'
  | 'md:ring-purple-500'
  | 'lg:ring-purple-500'
  | 'xl:ring-purple-500'
  | '2xl:ring-purple-500'
  | 'focus-within:ring-purple-500'
  | 'focus:ring-purple-500'
  | 'sm:ring-purple-600'
  | 'md:ring-purple-600'
  | 'lg:ring-purple-600'
  | 'xl:ring-purple-600'
  | '2xl:ring-purple-600'
  | 'focus-within:ring-purple-600'
  | 'focus:ring-purple-600'
  | 'sm:ring-purple-700'
  | 'md:ring-purple-700'
  | 'lg:ring-purple-700'
  | 'xl:ring-purple-700'
  | '2xl:ring-purple-700'
  | 'focus-within:ring-purple-700'
  | 'focus:ring-purple-700'
  | 'sm:ring-purple-800'
  | 'md:ring-purple-800'
  | 'lg:ring-purple-800'
  | 'xl:ring-purple-800'
  | '2xl:ring-purple-800'
  | 'focus-within:ring-purple-800'
  | 'focus:ring-purple-800'
  | 'sm:ring-purple-900'
  | 'md:ring-purple-900'
  | 'lg:ring-purple-900'
  | 'xl:ring-purple-900'
  | '2xl:ring-purple-900'
  | 'focus-within:ring-purple-900'
  | 'focus:ring-purple-900'
  | 'sm:ring-pink-50'
  | 'md:ring-pink-50'
  | 'lg:ring-pink-50'
  | 'xl:ring-pink-50'
  | '2xl:ring-pink-50'
  | 'focus-within:ring-pink-50'
  | 'focus:ring-pink-50'
  | 'sm:ring-pink-100'
  | 'md:ring-pink-100'
  | 'lg:ring-pink-100'
  | 'xl:ring-pink-100'
  | '2xl:ring-pink-100'
  | 'focus-within:ring-pink-100'
  | 'focus:ring-pink-100'
  | 'sm:ring-pink-200'
  | 'md:ring-pink-200'
  | 'lg:ring-pink-200'
  | 'xl:ring-pink-200'
  | '2xl:ring-pink-200'
  | 'focus-within:ring-pink-200'
  | 'focus:ring-pink-200'
  | 'sm:ring-pink-300'
  | 'md:ring-pink-300'
  | 'lg:ring-pink-300'
  | 'xl:ring-pink-300'
  | '2xl:ring-pink-300'
  | 'focus-within:ring-pink-300'
  | 'focus:ring-pink-300'
  | 'sm:ring-pink-400'
  | 'md:ring-pink-400'
  | 'lg:ring-pink-400'
  | 'xl:ring-pink-400'
  | '2xl:ring-pink-400'
  | 'focus-within:ring-pink-400'
  | 'focus:ring-pink-400'
  | 'sm:ring-pink-500'
  | 'md:ring-pink-500'
  | 'lg:ring-pink-500'
  | 'xl:ring-pink-500'
  | '2xl:ring-pink-500'
  | 'focus-within:ring-pink-500'
  | 'focus:ring-pink-500'
  | 'sm:ring-pink-600'
  | 'md:ring-pink-600'
  | 'lg:ring-pink-600'
  | 'xl:ring-pink-600'
  | '2xl:ring-pink-600'
  | 'focus-within:ring-pink-600'
  | 'focus:ring-pink-600'
  | 'sm:ring-pink-700'
  | 'md:ring-pink-700'
  | 'lg:ring-pink-700'
  | 'xl:ring-pink-700'
  | '2xl:ring-pink-700'
  | 'focus-within:ring-pink-700'
  | 'focus:ring-pink-700'
  | 'sm:ring-pink-800'
  | 'md:ring-pink-800'
  | 'lg:ring-pink-800'
  | 'xl:ring-pink-800'
  | '2xl:ring-pink-800'
  | 'focus-within:ring-pink-800'
  | 'focus:ring-pink-800'
  | 'sm:ring-pink-900'
  | 'md:ring-pink-900'
  | 'lg:ring-pink-900'
  | 'xl:ring-pink-900'
  | '2xl:ring-pink-900'
  | 'focus-within:ring-pink-900'
  | 'focus:ring-pink-900'
  | 'sm:ring-offset-transparent'
  | 'md:ring-offset-transparent'
  | 'lg:ring-offset-transparent'
  | 'xl:ring-offset-transparent'
  | '2xl:ring-offset-transparent'
  | 'focus-within:ring-offset-transparent'
  | 'focus:ring-offset-transparent'
  | 'sm:ring-offset-current'
  | 'md:ring-offset-current'
  | 'lg:ring-offset-current'
  | 'xl:ring-offset-current'
  | '2xl:ring-offset-current'
  | 'focus-within:ring-offset-current'
  | 'focus:ring-offset-current'
  | 'sm:ring-offset-black'
  | 'md:ring-offset-black'
  | 'lg:ring-offset-black'
  | 'xl:ring-offset-black'
  | '2xl:ring-offset-black'
  | 'focus-within:ring-offset-black'
  | 'focus:ring-offset-black'
  | 'sm:ring-offset-white'
  | 'md:ring-offset-white'
  | 'lg:ring-offset-white'
  | 'xl:ring-offset-white'
  | '2xl:ring-offset-white'
  | 'focus-within:ring-offset-white'
  | 'focus:ring-offset-white'
  | 'sm:ring-offset-gray-50'
  | 'md:ring-offset-gray-50'
  | 'lg:ring-offset-gray-50'
  | 'xl:ring-offset-gray-50'
  | '2xl:ring-offset-gray-50'
  | 'focus-within:ring-offset-gray-50'
  | 'focus:ring-offset-gray-50'
  | 'sm:ring-offset-gray-100'
  | 'md:ring-offset-gray-100'
  | 'lg:ring-offset-gray-100'
  | 'xl:ring-offset-gray-100'
  | '2xl:ring-offset-gray-100'
  | 'focus-within:ring-offset-gray-100'
  | 'focus:ring-offset-gray-100'
  | 'sm:ring-offset-gray-200'
  | 'md:ring-offset-gray-200'
  | 'lg:ring-offset-gray-200'
  | 'xl:ring-offset-gray-200'
  | '2xl:ring-offset-gray-200'
  | 'focus-within:ring-offset-gray-200'
  | 'focus:ring-offset-gray-200'
  | 'sm:ring-offset-gray-300'
  | 'md:ring-offset-gray-300'
  | 'lg:ring-offset-gray-300'
  | 'xl:ring-offset-gray-300'
  | '2xl:ring-offset-gray-300'
  | 'focus-within:ring-offset-gray-300'
  | 'focus:ring-offset-gray-300'
  | 'sm:ring-offset-gray-400'
  | 'md:ring-offset-gray-400'
  | 'lg:ring-offset-gray-400'
  | 'xl:ring-offset-gray-400'
  | '2xl:ring-offset-gray-400'
  | 'focus-within:ring-offset-gray-400'
  | 'focus:ring-offset-gray-400'
  | 'sm:ring-offset-gray-500'
  | 'md:ring-offset-gray-500'
  | 'lg:ring-offset-gray-500'
  | 'xl:ring-offset-gray-500'
  | '2xl:ring-offset-gray-500'
  | 'focus-within:ring-offset-gray-500'
  | 'focus:ring-offset-gray-500'
  | 'sm:ring-offset-gray-600'
  | 'md:ring-offset-gray-600'
  | 'lg:ring-offset-gray-600'
  | 'xl:ring-offset-gray-600'
  | '2xl:ring-offset-gray-600'
  | 'focus-within:ring-offset-gray-600'
  | 'focus:ring-offset-gray-600'
  | 'sm:ring-offset-gray-700'
  | 'md:ring-offset-gray-700'
  | 'lg:ring-offset-gray-700'
  | 'xl:ring-offset-gray-700'
  | '2xl:ring-offset-gray-700'
  | 'focus-within:ring-offset-gray-700'
  | 'focus:ring-offset-gray-700'
  | 'sm:ring-offset-gray-800'
  | 'md:ring-offset-gray-800'
  | 'lg:ring-offset-gray-800'
  | 'xl:ring-offset-gray-800'
  | '2xl:ring-offset-gray-800'
  | 'focus-within:ring-offset-gray-800'
  | 'focus:ring-offset-gray-800'
  | 'sm:ring-offset-gray-900'
  | 'md:ring-offset-gray-900'
  | 'lg:ring-offset-gray-900'
  | 'xl:ring-offset-gray-900'
  | '2xl:ring-offset-gray-900'
  | 'focus-within:ring-offset-gray-900'
  | 'focus:ring-offset-gray-900'
  | 'sm:ring-offset-red-50'
  | 'md:ring-offset-red-50'
  | 'lg:ring-offset-red-50'
  | 'xl:ring-offset-red-50'
  | '2xl:ring-offset-red-50'
  | 'focus-within:ring-offset-red-50'
  | 'focus:ring-offset-red-50'
  | 'sm:ring-offset-red-100'
  | 'md:ring-offset-red-100'
  | 'lg:ring-offset-red-100'
  | 'xl:ring-offset-red-100'
  | '2xl:ring-offset-red-100'
  | 'focus-within:ring-offset-red-100'
  | 'focus:ring-offset-red-100'
  | 'sm:ring-offset-red-200'
  | 'md:ring-offset-red-200'
  | 'lg:ring-offset-red-200'
  | 'xl:ring-offset-red-200'
  | '2xl:ring-offset-red-200'
  | 'focus-within:ring-offset-red-200'
  | 'focus:ring-offset-red-200'
  | 'sm:ring-offset-red-300'
  | 'md:ring-offset-red-300'
  | 'lg:ring-offset-red-300'
  | 'xl:ring-offset-red-300'
  | '2xl:ring-offset-red-300'
  | 'focus-within:ring-offset-red-300'
  | 'focus:ring-offset-red-300'
  | 'sm:ring-offset-red-400'
  | 'md:ring-offset-red-400'
  | 'lg:ring-offset-red-400'
  | 'xl:ring-offset-red-400'
  | '2xl:ring-offset-red-400'
  | 'focus-within:ring-offset-red-400'
  | 'focus:ring-offset-red-400'
  | 'sm:ring-offset-red-500'
  | 'md:ring-offset-red-500'
  | 'lg:ring-offset-red-500'
  | 'xl:ring-offset-red-500'
  | '2xl:ring-offset-red-500'
  | 'focus-within:ring-offset-red-500'
  | 'focus:ring-offset-red-500'
  | 'sm:ring-offset-red-600'
  | 'md:ring-offset-red-600'
  | 'lg:ring-offset-red-600'
  | 'xl:ring-offset-red-600'
  | '2xl:ring-offset-red-600'
  | 'focus-within:ring-offset-red-600'
  | 'focus:ring-offset-red-600'
  | 'sm:ring-offset-red-700'
  | 'md:ring-offset-red-700'
  | 'lg:ring-offset-red-700'
  | 'xl:ring-offset-red-700'
  | '2xl:ring-offset-red-700'
  | 'focus-within:ring-offset-red-700'
  | 'focus:ring-offset-red-700'
  | 'sm:ring-offset-red-800'
  | 'md:ring-offset-red-800'
  | 'lg:ring-offset-red-800'
  | 'xl:ring-offset-red-800'
  | '2xl:ring-offset-red-800'
  | 'focus-within:ring-offset-red-800'
  | 'focus:ring-offset-red-800'
  | 'sm:ring-offset-red-900'
  | 'md:ring-offset-red-900'
  | 'lg:ring-offset-red-900'
  | 'xl:ring-offset-red-900'
  | '2xl:ring-offset-red-900'
  | 'focus-within:ring-offset-red-900'
  | 'focus:ring-offset-red-900'
  | 'sm:ring-offset-yellow-50'
  | 'md:ring-offset-yellow-50'
  | 'lg:ring-offset-yellow-50'
  | 'xl:ring-offset-yellow-50'
  | '2xl:ring-offset-yellow-50'
  | 'focus-within:ring-offset-yellow-50'
  | 'focus:ring-offset-yellow-50'
  | 'sm:ring-offset-yellow-100'
  | 'md:ring-offset-yellow-100'
  | 'lg:ring-offset-yellow-100'
  | 'xl:ring-offset-yellow-100'
  | '2xl:ring-offset-yellow-100'
  | 'focus-within:ring-offset-yellow-100'
  | 'focus:ring-offset-yellow-100'
  | 'sm:ring-offset-yellow-200'
  | 'md:ring-offset-yellow-200'
  | 'lg:ring-offset-yellow-200'
  | 'xl:ring-offset-yellow-200'
  | '2xl:ring-offset-yellow-200'
  | 'focus-within:ring-offset-yellow-200'
  | 'focus:ring-offset-yellow-200'
  | 'sm:ring-offset-yellow-300'
  | 'md:ring-offset-yellow-300'
  | 'lg:ring-offset-yellow-300'
  | 'xl:ring-offset-yellow-300'
  | '2xl:ring-offset-yellow-300'
  | 'focus-within:ring-offset-yellow-300'
  | 'focus:ring-offset-yellow-300'
  | 'sm:ring-offset-yellow-400'
  | 'md:ring-offset-yellow-400'
  | 'lg:ring-offset-yellow-400'
  | 'xl:ring-offset-yellow-400'
  | '2xl:ring-offset-yellow-400'
  | 'focus-within:ring-offset-yellow-400'
  | 'focus:ring-offset-yellow-400'
  | 'sm:ring-offset-yellow-500'
  | 'md:ring-offset-yellow-500'
  | 'lg:ring-offset-yellow-500'
  | 'xl:ring-offset-yellow-500'
  | '2xl:ring-offset-yellow-500'
  | 'focus-within:ring-offset-yellow-500'
  | 'focus:ring-offset-yellow-500'
  | 'sm:ring-offset-yellow-600'
  | 'md:ring-offset-yellow-600'
  | 'lg:ring-offset-yellow-600'
  | 'xl:ring-offset-yellow-600'
  | '2xl:ring-offset-yellow-600'
  | 'focus-within:ring-offset-yellow-600'
  | 'focus:ring-offset-yellow-600'
  | 'sm:ring-offset-yellow-700'
  | 'md:ring-offset-yellow-700'
  | 'lg:ring-offset-yellow-700'
  | 'xl:ring-offset-yellow-700'
  | '2xl:ring-offset-yellow-700'
  | 'focus-within:ring-offset-yellow-700'
  | 'focus:ring-offset-yellow-700'
  | 'sm:ring-offset-yellow-800'
  | 'md:ring-offset-yellow-800'
  | 'lg:ring-offset-yellow-800'
  | 'xl:ring-offset-yellow-800'
  | '2xl:ring-offset-yellow-800'
  | 'focus-within:ring-offset-yellow-800'
  | 'focus:ring-offset-yellow-800'
  | 'sm:ring-offset-yellow-900'
  | 'md:ring-offset-yellow-900'
  | 'lg:ring-offset-yellow-900'
  | 'xl:ring-offset-yellow-900'
  | '2xl:ring-offset-yellow-900'
  | 'focus-within:ring-offset-yellow-900'
  | 'focus:ring-offset-yellow-900'
  | 'sm:ring-offset-green-50'
  | 'md:ring-offset-green-50'
  | 'lg:ring-offset-green-50'
  | 'xl:ring-offset-green-50'
  | '2xl:ring-offset-green-50'
  | 'focus-within:ring-offset-green-50'
  | 'focus:ring-offset-green-50'
  | 'sm:ring-offset-green-100'
  | 'md:ring-offset-green-100'
  | 'lg:ring-offset-green-100'
  | 'xl:ring-offset-green-100'
  | '2xl:ring-offset-green-100'
  | 'focus-within:ring-offset-green-100'
  | 'focus:ring-offset-green-100'
  | 'sm:ring-offset-green-200'
  | 'md:ring-offset-green-200'
  | 'lg:ring-offset-green-200'
  | 'xl:ring-offset-green-200'
  | '2xl:ring-offset-green-200'
  | 'focus-within:ring-offset-green-200'
  | 'focus:ring-offset-green-200'
  | 'sm:ring-offset-green-300'
  | 'md:ring-offset-green-300'
  | 'lg:ring-offset-green-300'
  | 'xl:ring-offset-green-300'
  | '2xl:ring-offset-green-300'
  | 'focus-within:ring-offset-green-300'
  | 'focus:ring-offset-green-300'
  | 'sm:ring-offset-green-400'
  | 'md:ring-offset-green-400'
  | 'lg:ring-offset-green-400'
  | 'xl:ring-offset-green-400'
  | '2xl:ring-offset-green-400'
  | 'focus-within:ring-offset-green-400'
  | 'focus:ring-offset-green-400'
  | 'sm:ring-offset-green-500'
  | 'md:ring-offset-green-500'
  | 'lg:ring-offset-green-500'
  | 'xl:ring-offset-green-500'
  | '2xl:ring-offset-green-500'
  | 'focus-within:ring-offset-green-500'
  | 'focus:ring-offset-green-500'
  | 'sm:ring-offset-green-600'
  | 'md:ring-offset-green-600'
  | 'lg:ring-offset-green-600'
  | 'xl:ring-offset-green-600'
  | '2xl:ring-offset-green-600'
  | 'focus-within:ring-offset-green-600'
  | 'focus:ring-offset-green-600'
  | 'sm:ring-offset-green-700'
  | 'md:ring-offset-green-700'
  | 'lg:ring-offset-green-700'
  | 'xl:ring-offset-green-700'
  | '2xl:ring-offset-green-700'
  | 'focus-within:ring-offset-green-700'
  | 'focus:ring-offset-green-700'
  | 'sm:ring-offset-green-800'
  | 'md:ring-offset-green-800'
  | 'lg:ring-offset-green-800'
  | 'xl:ring-offset-green-800'
  | '2xl:ring-offset-green-800'
  | 'focus-within:ring-offset-green-800'
  | 'focus:ring-offset-green-800'
  | 'sm:ring-offset-green-900'
  | 'md:ring-offset-green-900'
  | 'lg:ring-offset-green-900'
  | 'xl:ring-offset-green-900'
  | '2xl:ring-offset-green-900'
  | 'focus-within:ring-offset-green-900'
  | 'focus:ring-offset-green-900'
  | 'sm:ring-offset-blue-50'
  | 'md:ring-offset-blue-50'
  | 'lg:ring-offset-blue-50'
  | 'xl:ring-offset-blue-50'
  | '2xl:ring-offset-blue-50'
  | 'focus-within:ring-offset-blue-50'
  | 'focus:ring-offset-blue-50'
  | 'sm:ring-offset-blue-100'
  | 'md:ring-offset-blue-100'
  | 'lg:ring-offset-blue-100'
  | 'xl:ring-offset-blue-100'
  | '2xl:ring-offset-blue-100'
  | 'focus-within:ring-offset-blue-100'
  | 'focus:ring-offset-blue-100'
  | 'sm:ring-offset-blue-200'
  | 'md:ring-offset-blue-200'
  | 'lg:ring-offset-blue-200'
  | 'xl:ring-offset-blue-200'
  | '2xl:ring-offset-blue-200'
  | 'focus-within:ring-offset-blue-200'
  | 'focus:ring-offset-blue-200'
  | 'sm:ring-offset-blue-300'
  | 'md:ring-offset-blue-300'
  | 'lg:ring-offset-blue-300'
  | 'xl:ring-offset-blue-300'
  | '2xl:ring-offset-blue-300'
  | 'focus-within:ring-offset-blue-300'
  | 'focus:ring-offset-blue-300'
  | 'sm:ring-offset-blue-400'
  | 'md:ring-offset-blue-400'
  | 'lg:ring-offset-blue-400'
  | 'xl:ring-offset-blue-400'
  | '2xl:ring-offset-blue-400'
  | 'focus-within:ring-offset-blue-400'
  | 'focus:ring-offset-blue-400'
  | 'sm:ring-offset-blue-500'
  | 'md:ring-offset-blue-500'
  | 'lg:ring-offset-blue-500'
  | 'xl:ring-offset-blue-500'
  | '2xl:ring-offset-blue-500'
  | 'focus-within:ring-offset-blue-500'
  | 'focus:ring-offset-blue-500'
  | 'sm:ring-offset-blue-600'
  | 'md:ring-offset-blue-600'
  | 'lg:ring-offset-blue-600'
  | 'xl:ring-offset-blue-600'
  | '2xl:ring-offset-blue-600'
  | 'focus-within:ring-offset-blue-600'
  | 'focus:ring-offset-blue-600'
  | 'sm:ring-offset-blue-700'
  | 'md:ring-offset-blue-700'
  | 'lg:ring-offset-blue-700'
  | 'xl:ring-offset-blue-700'
  | '2xl:ring-offset-blue-700'
  | 'focus-within:ring-offset-blue-700'
  | 'focus:ring-offset-blue-700'
  | 'sm:ring-offset-blue-800'
  | 'md:ring-offset-blue-800'
  | 'lg:ring-offset-blue-800'
  | 'xl:ring-offset-blue-800'
  | '2xl:ring-offset-blue-800'
  | 'focus-within:ring-offset-blue-800'
  | 'focus:ring-offset-blue-800'
  | 'sm:ring-offset-blue-900'
  | 'md:ring-offset-blue-900'
  | 'lg:ring-offset-blue-900'
  | 'xl:ring-offset-blue-900'
  | '2xl:ring-offset-blue-900'
  | 'focus-within:ring-offset-blue-900'
  | 'focus:ring-offset-blue-900'
  | 'sm:ring-offset-indigo-50'
  | 'md:ring-offset-indigo-50'
  | 'lg:ring-offset-indigo-50'
  | 'xl:ring-offset-indigo-50'
  | '2xl:ring-offset-indigo-50'
  | 'focus-within:ring-offset-indigo-50'
  | 'focus:ring-offset-indigo-50'
  | 'sm:ring-offset-indigo-100'
  | 'md:ring-offset-indigo-100'
  | 'lg:ring-offset-indigo-100'
  | 'xl:ring-offset-indigo-100'
  | '2xl:ring-offset-indigo-100'
  | 'focus-within:ring-offset-indigo-100'
  | 'focus:ring-offset-indigo-100'
  | 'sm:ring-offset-indigo-200'
  | 'md:ring-offset-indigo-200'
  | 'lg:ring-offset-indigo-200'
  | 'xl:ring-offset-indigo-200'
  | '2xl:ring-offset-indigo-200'
  | 'focus-within:ring-offset-indigo-200'
  | 'focus:ring-offset-indigo-200'
  | 'sm:ring-offset-indigo-300'
  | 'md:ring-offset-indigo-300'
  | 'lg:ring-offset-indigo-300'
  | 'xl:ring-offset-indigo-300'
  | '2xl:ring-offset-indigo-300'
  | 'focus-within:ring-offset-indigo-300'
  | 'focus:ring-offset-indigo-300'
  | 'sm:ring-offset-indigo-400'
  | 'md:ring-offset-indigo-400'
  | 'lg:ring-offset-indigo-400'
  | 'xl:ring-offset-indigo-400'
  | '2xl:ring-offset-indigo-400'
  | 'focus-within:ring-offset-indigo-400'
  | 'focus:ring-offset-indigo-400'
  | 'sm:ring-offset-indigo-500'
  | 'md:ring-offset-indigo-500'
  | 'lg:ring-offset-indigo-500'
  | 'xl:ring-offset-indigo-500'
  | '2xl:ring-offset-indigo-500'
  | 'focus-within:ring-offset-indigo-500'
  | 'focus:ring-offset-indigo-500'
  | 'sm:ring-offset-indigo-600'
  | 'md:ring-offset-indigo-600'
  | 'lg:ring-offset-indigo-600'
  | 'xl:ring-offset-indigo-600'
  | '2xl:ring-offset-indigo-600'
  | 'focus-within:ring-offset-indigo-600'
  | 'focus:ring-offset-indigo-600'
  | 'sm:ring-offset-indigo-700'
  | 'md:ring-offset-indigo-700'
  | 'lg:ring-offset-indigo-700'
  | 'xl:ring-offset-indigo-700'
  | '2xl:ring-offset-indigo-700'
  | 'focus-within:ring-offset-indigo-700'
  | 'focus:ring-offset-indigo-700'
  | 'sm:ring-offset-indigo-800'
  | 'md:ring-offset-indigo-800'
  | 'lg:ring-offset-indigo-800'
  | 'xl:ring-offset-indigo-800'
  | '2xl:ring-offset-indigo-800'
  | 'focus-within:ring-offset-indigo-800'
  | 'focus:ring-offset-indigo-800'
  | 'sm:ring-offset-indigo-900'
  | 'md:ring-offset-indigo-900'
  | 'lg:ring-offset-indigo-900'
  | 'xl:ring-offset-indigo-900'
  | '2xl:ring-offset-indigo-900'
  | 'focus-within:ring-offset-indigo-900'
  | 'focus:ring-offset-indigo-900'
  | 'sm:ring-offset-purple-50'
  | 'md:ring-offset-purple-50'
  | 'lg:ring-offset-purple-50'
  | 'xl:ring-offset-purple-50'
  | '2xl:ring-offset-purple-50'
  | 'focus-within:ring-offset-purple-50'
  | 'focus:ring-offset-purple-50'
  | 'sm:ring-offset-purple-100'
  | 'md:ring-offset-purple-100'
  | 'lg:ring-offset-purple-100'
  | 'xl:ring-offset-purple-100'
  | '2xl:ring-offset-purple-100'
  | 'focus-within:ring-offset-purple-100'
  | 'focus:ring-offset-purple-100'
  | 'sm:ring-offset-purple-200'
  | 'md:ring-offset-purple-200'
  | 'lg:ring-offset-purple-200'
  | 'xl:ring-offset-purple-200'
  | '2xl:ring-offset-purple-200'
  | 'focus-within:ring-offset-purple-200'
  | 'focus:ring-offset-purple-200'
  | 'sm:ring-offset-purple-300'
  | 'md:ring-offset-purple-300'
  | 'lg:ring-offset-purple-300'
  | 'xl:ring-offset-purple-300'
  | '2xl:ring-offset-purple-300'
  | 'focus-within:ring-offset-purple-300'
  | 'focus:ring-offset-purple-300'
  | 'sm:ring-offset-purple-400'
  | 'md:ring-offset-purple-400'
  | 'lg:ring-offset-purple-400'
  | 'xl:ring-offset-purple-400'
  | '2xl:ring-offset-purple-400'
  | 'focus-within:ring-offset-purple-400'
  | 'focus:ring-offset-purple-400'
  | 'sm:ring-offset-purple-500'
  | 'md:ring-offset-purple-500'
  | 'lg:ring-offset-purple-500'
  | 'xl:ring-offset-purple-500'
  | '2xl:ring-offset-purple-500'
  | 'focus-within:ring-offset-purple-500'
  | 'focus:ring-offset-purple-500'
  | 'sm:ring-offset-purple-600'
  | 'md:ring-offset-purple-600'
  | 'lg:ring-offset-purple-600'
  | 'xl:ring-offset-purple-600'
  | '2xl:ring-offset-purple-600'
  | 'focus-within:ring-offset-purple-600'
  | 'focus:ring-offset-purple-600'
  | 'sm:ring-offset-purple-700'
  | 'md:ring-offset-purple-700'
  | 'lg:ring-offset-purple-700'
  | 'xl:ring-offset-purple-700'
  | '2xl:ring-offset-purple-700'
  | 'focus-within:ring-offset-purple-700'
  | 'focus:ring-offset-purple-700'
  | 'sm:ring-offset-purple-800'
  | 'md:ring-offset-purple-800'
  | 'lg:ring-offset-purple-800'
  | 'xl:ring-offset-purple-800'
  | '2xl:ring-offset-purple-800'
  | 'focus-within:ring-offset-purple-800'
  | 'focus:ring-offset-purple-800'
  | 'sm:ring-offset-purple-900'
  | 'md:ring-offset-purple-900'
  | 'lg:ring-offset-purple-900'
  | 'xl:ring-offset-purple-900'
  | '2xl:ring-offset-purple-900'
  | 'focus-within:ring-offset-purple-900'
  | 'focus:ring-offset-purple-900'
  | 'sm:ring-offset-pink-50'
  | 'md:ring-offset-pink-50'
  | 'lg:ring-offset-pink-50'
  | 'xl:ring-offset-pink-50'
  | '2xl:ring-offset-pink-50'
  | 'focus-within:ring-offset-pink-50'
  | 'focus:ring-offset-pink-50'
  | 'sm:ring-offset-pink-100'
  | 'md:ring-offset-pink-100'
  | 'lg:ring-offset-pink-100'
  | 'xl:ring-offset-pink-100'
  | '2xl:ring-offset-pink-100'
  | 'focus-within:ring-offset-pink-100'
  | 'focus:ring-offset-pink-100'
  | 'sm:ring-offset-pink-200'
  | 'md:ring-offset-pink-200'
  | 'lg:ring-offset-pink-200'
  | 'xl:ring-offset-pink-200'
  | '2xl:ring-offset-pink-200'
  | 'focus-within:ring-offset-pink-200'
  | 'focus:ring-offset-pink-200'
  | 'sm:ring-offset-pink-300'
  | 'md:ring-offset-pink-300'
  | 'lg:ring-offset-pink-300'
  | 'xl:ring-offset-pink-300'
  | '2xl:ring-offset-pink-300'
  | 'focus-within:ring-offset-pink-300'
  | 'focus:ring-offset-pink-300'
  | 'sm:ring-offset-pink-400'
  | 'md:ring-offset-pink-400'
  | 'lg:ring-offset-pink-400'
  | 'xl:ring-offset-pink-400'
  | '2xl:ring-offset-pink-400'
  | 'focus-within:ring-offset-pink-400'
  | 'focus:ring-offset-pink-400'
  | 'sm:ring-offset-pink-500'
  | 'md:ring-offset-pink-500'
  | 'lg:ring-offset-pink-500'
  | 'xl:ring-offset-pink-500'
  | '2xl:ring-offset-pink-500'
  | 'focus-within:ring-offset-pink-500'
  | 'focus:ring-offset-pink-500'
  | 'sm:ring-offset-pink-600'
  | 'md:ring-offset-pink-600'
  | 'lg:ring-offset-pink-600'
  | 'xl:ring-offset-pink-600'
  | '2xl:ring-offset-pink-600'
  | 'focus-within:ring-offset-pink-600'
  | 'focus:ring-offset-pink-600'
  | 'sm:ring-offset-pink-700'
  | 'md:ring-offset-pink-700'
  | 'lg:ring-offset-pink-700'
  | 'xl:ring-offset-pink-700'
  | '2xl:ring-offset-pink-700'
  | 'focus-within:ring-offset-pink-700'
  | 'focus:ring-offset-pink-700'
  | 'sm:ring-offset-pink-800'
  | 'md:ring-offset-pink-800'
  | 'lg:ring-offset-pink-800'
  | 'xl:ring-offset-pink-800'
  | '2xl:ring-offset-pink-800'
  | 'focus-within:ring-offset-pink-800'
  | 'focus:ring-offset-pink-800'
  | 'sm:ring-offset-pink-900'
  | 'md:ring-offset-pink-900'
  | 'lg:ring-offset-pink-900'
  | 'xl:ring-offset-pink-900'
  | '2xl:ring-offset-pink-900'
  | 'focus-within:ring-offset-pink-900'
  | 'focus:ring-offset-pink-900'
  | 'sm:ring-offset-0'
  | 'md:ring-offset-0'
  | 'lg:ring-offset-0'
  | 'xl:ring-offset-0'
  | '2xl:ring-offset-0'
  | 'focus-within:ring-offset-0'
  | 'focus:ring-offset-0'
  | 'sm:ring-offset-1'
  | 'md:ring-offset-1'
  | 'lg:ring-offset-1'
  | 'xl:ring-offset-1'
  | '2xl:ring-offset-1'
  | 'focus-within:ring-offset-1'
  | 'focus:ring-offset-1'
  | 'sm:ring-offset-2'
  | 'md:ring-offset-2'
  | 'lg:ring-offset-2'
  | 'xl:ring-offset-2'
  | '2xl:ring-offset-2'
  | 'focus-within:ring-offset-2'
  | 'focus:ring-offset-2'
  | 'sm:ring-offset-4'
  | 'md:ring-offset-4'
  | 'lg:ring-offset-4'
  | 'xl:ring-offset-4'
  | '2xl:ring-offset-4'
  | 'focus-within:ring-offset-4'
  | 'focus:ring-offset-4'
  | 'sm:ring-offset-8'
  | 'md:ring-offset-8'
  | 'lg:ring-offset-8'
  | 'xl:ring-offset-8'
  | '2xl:ring-offset-8'
  | 'focus-within:ring-offset-8'
  | 'focus:ring-offset-8'
  | 'sm:ring-opacity-0'
  | 'md:ring-opacity-0'
  | 'lg:ring-opacity-0'
  | 'xl:ring-opacity-0'
  | '2xl:ring-opacity-0'
  | 'focus-within:ring-opacity-0'
  | 'focus:ring-opacity-0'
  | 'sm:ring-opacity-5'
  | 'md:ring-opacity-5'
  | 'lg:ring-opacity-5'
  | 'xl:ring-opacity-5'
  | '2xl:ring-opacity-5'
  | 'focus-within:ring-opacity-5'
  | 'focus:ring-opacity-5'
  | 'sm:ring-opacity-10'
  | 'md:ring-opacity-10'
  | 'lg:ring-opacity-10'
  | 'xl:ring-opacity-10'
  | '2xl:ring-opacity-10'
  | 'focus-within:ring-opacity-10'
  | 'focus:ring-opacity-10'
  | 'sm:ring-opacity-20'
  | 'md:ring-opacity-20'
  | 'lg:ring-opacity-20'
  | 'xl:ring-opacity-20'
  | '2xl:ring-opacity-20'
  | 'focus-within:ring-opacity-20'
  | 'focus:ring-opacity-20'
  | 'sm:ring-opacity-25'
  | 'md:ring-opacity-25'
  | 'lg:ring-opacity-25'
  | 'xl:ring-opacity-25'
  | '2xl:ring-opacity-25'
  | 'focus-within:ring-opacity-25'
  | 'focus:ring-opacity-25'
  | 'sm:ring-opacity-30'
  | 'md:ring-opacity-30'
  | 'lg:ring-opacity-30'
  | 'xl:ring-opacity-30'
  | '2xl:ring-opacity-30'
  | 'focus-within:ring-opacity-30'
  | 'focus:ring-opacity-30'
  | 'sm:ring-opacity-40'
  | 'md:ring-opacity-40'
  | 'lg:ring-opacity-40'
  | 'xl:ring-opacity-40'
  | '2xl:ring-opacity-40'
  | 'focus-within:ring-opacity-40'
  | 'focus:ring-opacity-40'
  | 'sm:ring-opacity-50'
  | 'md:ring-opacity-50'
  | 'lg:ring-opacity-50'
  | 'xl:ring-opacity-50'
  | '2xl:ring-opacity-50'
  | 'focus-within:ring-opacity-50'
  | 'focus:ring-opacity-50'
  | 'sm:ring-opacity-60'
  | 'md:ring-opacity-60'
  | 'lg:ring-opacity-60'
  | 'xl:ring-opacity-60'
  | '2xl:ring-opacity-60'
  | 'focus-within:ring-opacity-60'
  | 'focus:ring-opacity-60'
  | 'sm:ring-opacity-70'
  | 'md:ring-opacity-70'
  | 'lg:ring-opacity-70'
  | 'xl:ring-opacity-70'
  | '2xl:ring-opacity-70'
  | 'focus-within:ring-opacity-70'
  | 'focus:ring-opacity-70'
  | 'sm:ring-opacity-75'
  | 'md:ring-opacity-75'
  | 'lg:ring-opacity-75'
  | 'xl:ring-opacity-75'
  | '2xl:ring-opacity-75'
  | 'focus-within:ring-opacity-75'
  | 'focus:ring-opacity-75'
  | 'sm:ring-opacity-80'
  | 'md:ring-opacity-80'
  | 'lg:ring-opacity-80'
  | 'xl:ring-opacity-80'
  | '2xl:ring-opacity-80'
  | 'focus-within:ring-opacity-80'
  | 'focus:ring-opacity-80'
  | 'sm:ring-opacity-90'
  | 'md:ring-opacity-90'
  | 'lg:ring-opacity-90'
  | 'xl:ring-opacity-90'
  | '2xl:ring-opacity-90'
  | 'focus-within:ring-opacity-90'
  | 'focus:ring-opacity-90'
  | 'sm:ring-opacity-95'
  | 'md:ring-opacity-95'
  | 'lg:ring-opacity-95'
  | 'xl:ring-opacity-95'
  | '2xl:ring-opacity-95'
  | 'focus-within:ring-opacity-95'
  | 'focus:ring-opacity-95'
  | 'sm:ring-opacity-100'
  | 'md:ring-opacity-100'
  | 'lg:ring-opacity-100'
  | 'xl:ring-opacity-100'
  | '2xl:ring-opacity-100'
  | 'focus-within:ring-opacity-100'
  | 'focus:ring-opacity-100'
  | 'sm:ring-opacity'
  | 'md:ring-opacity'
  | 'lg:ring-opacity'
  | 'xl:ring-opacity'
  | '2xl:ring-opacity'
  | 'focus-within:ring-opacity'
  | 'focus:ring-opacity'
  | 'sm:ring-0'
  | 'md:ring-0'
  | 'lg:ring-0'
  | 'xl:ring-0'
  | '2xl:ring-0'
  | 'focus-within:ring-0'
  | 'focus:ring-0'
  | 'sm:ring-1'
  | 'md:ring-1'
  | 'lg:ring-1'
  | 'xl:ring-1'
  | '2xl:ring-1'
  | 'focus-within:ring-1'
  | 'focus:ring-1'
  | 'sm:ring-2'
  | 'md:ring-2'
  | 'lg:ring-2'
  | 'xl:ring-2'
  | '2xl:ring-2'
  | 'focus-within:ring-2'
  | 'focus:ring-2'
  | 'sm:ring-4'
  | 'md:ring-4'
  | 'lg:ring-4'
  | 'xl:ring-4'
  | '2xl:ring-4'
  | 'focus-within:ring-4'
  | 'focus:ring-4'
  | 'sm:ring-8'
  | 'md:ring-8'
  | 'lg:ring-8'
  | 'xl:ring-8'
  | '2xl:ring-8'
  | 'focus-within:ring-8'
  | 'focus:ring-8'
  | 'sm:ring'
  | 'md:ring'
  | 'lg:ring'
  | 'xl:ring'
  | '2xl:ring'
  | 'focus-within:ring'
  | 'focus:ring'
  | 'sm:inset'
  | 'md:inset'
  | 'lg:inset'
  | 'xl:inset'
  | '2xl:inset'
  | 'focus-within:inset'
  | 'focus:inset'
  | 'sm:rotate-0'
  | 'md:rotate-0'
  | 'lg:rotate-0'
  | 'xl:rotate-0'
  | '2xl:rotate-0'
  | 'hover:rotate-0'
  | 'focus:rotate-0'
  | 'sm:rotate-1'
  | 'md:rotate-1'
  | 'lg:rotate-1'
  | 'xl:rotate-1'
  | '2xl:rotate-1'
  | 'hover:rotate-1'
  | 'focus:rotate-1'
  | 'sm:rotate-2'
  | 'md:rotate-2'
  | 'lg:rotate-2'
  | 'xl:rotate-2'
  | '2xl:rotate-2'
  | 'hover:rotate-2'
  | 'focus:rotate-2'
  | 'sm:rotate-3'
  | 'md:rotate-3'
  | 'lg:rotate-3'
  | 'xl:rotate-3'
  | '2xl:rotate-3'
  | 'hover:rotate-3'
  | 'focus:rotate-3'
  | 'sm:rotate-6'
  | 'md:rotate-6'
  | 'lg:rotate-6'
  | 'xl:rotate-6'
  | '2xl:rotate-6'
  | 'hover:rotate-6'
  | 'focus:rotate-6'
  | 'sm:rotate-12'
  | 'md:rotate-12'
  | 'lg:rotate-12'
  | 'xl:rotate-12'
  | '2xl:rotate-12'
  | 'hover:rotate-12'
  | 'focus:rotate-12'
  | 'sm:rotate-45'
  | 'md:rotate-45'
  | 'lg:rotate-45'
  | 'xl:rotate-45'
  | '2xl:rotate-45'
  | 'hover:rotate-45'
  | 'focus:rotate-45'
  | 'sm:rotate-90'
  | 'md:rotate-90'
  | 'lg:rotate-90'
  | 'xl:rotate-90'
  | '2xl:rotate-90'
  | 'hover:rotate-90'
  | 'focus:rotate-90'
  | 'sm:rotate-180'
  | 'md:rotate-180'
  | 'lg:rotate-180'
  | 'xl:rotate-180'
  | '2xl:rotate-180'
  | 'hover:rotate-180'
  | 'focus:rotate-180'
  | 'sm:-rotate-180'
  | 'md:-rotate-180'
  | 'lg:-rotate-180'
  | 'xl:-rotate-180'
  | '2xl:-rotate-180'
  | 'hover:-rotate-180'
  | 'focus:-rotate-180'
  | 'sm:-rotate-90'
  | 'md:-rotate-90'
  | 'lg:-rotate-90'
  | 'xl:-rotate-90'
  | '2xl:-rotate-90'
  | 'hover:-rotate-90'
  | 'focus:-rotate-90'
  | 'sm:-rotate-45'
  | 'md:-rotate-45'
  | 'lg:-rotate-45'
  | 'xl:-rotate-45'
  | '2xl:-rotate-45'
  | 'hover:-rotate-45'
  | 'focus:-rotate-45'
  | 'sm:-rotate-12'
  | 'md:-rotate-12'
  | 'lg:-rotate-12'
  | 'xl:-rotate-12'
  | '2xl:-rotate-12'
  | 'hover:-rotate-12'
  | 'focus:-rotate-12'
  | 'sm:-rotate-6'
  | 'md:-rotate-6'
  | 'lg:-rotate-6'
  | 'xl:-rotate-6'
  | '2xl:-rotate-6'
  | 'hover:-rotate-6'
  | 'focus:-rotate-6'
  | 'sm:-rotate-3'
  | 'md:-rotate-3'
  | 'lg:-rotate-3'
  | 'xl:-rotate-3'
  | '2xl:-rotate-3'
  | 'hover:-rotate-3'
  | 'focus:-rotate-3'
  | 'sm:-rotate-2'
  | 'md:-rotate-2'
  | 'lg:-rotate-2'
  | 'xl:-rotate-2'
  | '2xl:-rotate-2'
  | 'hover:-rotate-2'
  | 'focus:-rotate-2'
  | 'sm:-rotate-1'
  | 'md:-rotate-1'
  | 'lg:-rotate-1'
  | 'xl:-rotate-1'
  | '2xl:-rotate-1'
  | 'hover:-rotate-1'
  | 'focus:-rotate-1'
  | 'sm:saturate-0'
  | 'md:saturate-0'
  | 'lg:saturate-0'
  | 'xl:saturate-0'
  | '2xl:saturate-0'
  | 'sm:saturate-50'
  | 'md:saturate-50'
  | 'lg:saturate-50'
  | 'xl:saturate-50'
  | '2xl:saturate-50'
  | 'sm:saturate-100'
  | 'md:saturate-100'
  | 'lg:saturate-100'
  | 'xl:saturate-100'
  | '2xl:saturate-100'
  | 'sm:saturate-150'
  | 'md:saturate-150'
  | 'lg:saturate-150'
  | 'xl:saturate-150'
  | '2xl:saturate-150'
  | 'sm:saturate-200'
  | 'md:saturate-200'
  | 'lg:saturate-200'
  | 'xl:saturate-200'
  | '2xl:saturate-200'
  | 'sm:scale-0'
  | 'md:scale-0'
  | 'lg:scale-0'
  | 'xl:scale-0'
  | '2xl:scale-0'
  | 'hover:scale-0'
  | 'focus:scale-0'
  | 'sm:scale-50'
  | 'md:scale-50'
  | 'lg:scale-50'
  | 'xl:scale-50'
  | '2xl:scale-50'
  | 'hover:scale-50'
  | 'focus:scale-50'
  | 'sm:scale-75'
  | 'md:scale-75'
  | 'lg:scale-75'
  | 'xl:scale-75'
  | '2xl:scale-75'
  | 'hover:scale-75'
  | 'focus:scale-75'
  | 'sm:scale-90'
  | 'md:scale-90'
  | 'lg:scale-90'
  | 'xl:scale-90'
  | '2xl:scale-90'
  | 'hover:scale-90'
  | 'focus:scale-90'
  | 'sm:scale-95'
  | 'md:scale-95'
  | 'lg:scale-95'
  | 'xl:scale-95'
  | '2xl:scale-95'
  | 'hover:scale-95'
  | 'focus:scale-95'
  | 'sm:scale-100'
  | 'md:scale-100'
  | 'lg:scale-100'
  | 'xl:scale-100'
  | '2xl:scale-100'
  | 'hover:scale-100'
  | 'focus:scale-100'
  | 'sm:scale-105'
  | 'md:scale-105'
  | 'lg:scale-105'
  | 'xl:scale-105'
  | '2xl:scale-105'
  | 'hover:scale-105'
  | 'focus:scale-105'
  | 'sm:scale-110'
  | 'md:scale-110'
  | 'lg:scale-110'
  | 'xl:scale-110'
  | '2xl:scale-110'
  | 'hover:scale-110'
  | 'focus:scale-110'
  | 'sm:scale-125'
  | 'md:scale-125'
  | 'lg:scale-125'
  | 'xl:scale-125'
  | '2xl:scale-125'
  | 'hover:scale-125'
  | 'focus:scale-125'
  | 'sm:scale-150'
  | 'md:scale-150'
  | 'lg:scale-150'
  | 'xl:scale-150'
  | '2xl:scale-150'
  | 'hover:scale-150'
  | 'focus:scale-150'
  | 'sm:scale-x-0'
  | 'md:scale-x-0'
  | 'lg:scale-x-0'
  | 'xl:scale-x-0'
  | '2xl:scale-x-0'
  | 'hover:scale-x-0'
  | 'focus:scale-x-0'
  | 'sm:scale-x-50'
  | 'md:scale-x-50'
  | 'lg:scale-x-50'
  | 'xl:scale-x-50'
  | '2xl:scale-x-50'
  | 'hover:scale-x-50'
  | 'focus:scale-x-50'
  | 'sm:scale-x-75'
  | 'md:scale-x-75'
  | 'lg:scale-x-75'
  | 'xl:scale-x-75'
  | '2xl:scale-x-75'
  | 'hover:scale-x-75'
  | 'focus:scale-x-75'
  | 'sm:scale-x-90'
  | 'md:scale-x-90'
  | 'lg:scale-x-90'
  | 'xl:scale-x-90'
  | '2xl:scale-x-90'
  | 'hover:scale-x-90'
  | 'focus:scale-x-90'
  | 'sm:scale-x-95'
  | 'md:scale-x-95'
  | 'lg:scale-x-95'
  | 'xl:scale-x-95'
  | '2xl:scale-x-95'
  | 'hover:scale-x-95'
  | 'focus:scale-x-95'
  | 'sm:scale-x-100'
  | 'md:scale-x-100'
  | 'lg:scale-x-100'
  | 'xl:scale-x-100'
  | '2xl:scale-x-100'
  | 'hover:scale-x-100'
  | 'focus:scale-x-100'
  | 'sm:scale-x-105'
  | 'md:scale-x-105'
  | 'lg:scale-x-105'
  | 'xl:scale-x-105'
  | '2xl:scale-x-105'
  | 'hover:scale-x-105'
  | 'focus:scale-x-105'
  | 'sm:scale-x-110'
  | 'md:scale-x-110'
  | 'lg:scale-x-110'
  | 'xl:scale-x-110'
  | '2xl:scale-x-110'
  | 'hover:scale-x-110'
  | 'focus:scale-x-110'
  | 'sm:scale-x-125'
  | 'md:scale-x-125'
  | 'lg:scale-x-125'
  | 'xl:scale-x-125'
  | '2xl:scale-x-125'
  | 'hover:scale-x-125'
  | 'focus:scale-x-125'
  | 'sm:scale-x-150'
  | 'md:scale-x-150'
  | 'lg:scale-x-150'
  | 'xl:scale-x-150'
  | '2xl:scale-x-150'
  | 'hover:scale-x-150'
  | 'focus:scale-x-150'
  | 'sm:scale-y-0'
  | 'md:scale-y-0'
  | 'lg:scale-y-0'
  | 'xl:scale-y-0'
  | '2xl:scale-y-0'
  | 'hover:scale-y-0'
  | 'focus:scale-y-0'
  | 'sm:scale-y-50'
  | 'md:scale-y-50'
  | 'lg:scale-y-50'
  | 'xl:scale-y-50'
  | '2xl:scale-y-50'
  | 'hover:scale-y-50'
  | 'focus:scale-y-50'
  | 'sm:scale-y-75'
  | 'md:scale-y-75'
  | 'lg:scale-y-75'
  | 'xl:scale-y-75'
  | '2xl:scale-y-75'
  | 'hover:scale-y-75'
  | 'focus:scale-y-75'
  | 'sm:scale-y-90'
  | 'md:scale-y-90'
  | 'lg:scale-y-90'
  | 'xl:scale-y-90'
  | '2xl:scale-y-90'
  | 'hover:scale-y-90'
  | 'focus:scale-y-90'
  | 'sm:scale-y-95'
  | 'md:scale-y-95'
  | 'lg:scale-y-95'
  | 'xl:scale-y-95'
  | '2xl:scale-y-95'
  | 'hover:scale-y-95'
  | 'focus:scale-y-95'
  | 'sm:scale-y-100'
  | 'md:scale-y-100'
  | 'lg:scale-y-100'
  | 'xl:scale-y-100'
  | '2xl:scale-y-100'
  | 'hover:scale-y-100'
  | 'focus:scale-y-100'
  | 'sm:scale-y-105'
  | 'md:scale-y-105'
  | 'lg:scale-y-105'
  | 'xl:scale-y-105'
  | '2xl:scale-y-105'
  | 'hover:scale-y-105'
  | 'focus:scale-y-105'
  | 'sm:scale-y-110'
  | 'md:scale-y-110'
  | 'lg:scale-y-110'
  | 'xl:scale-y-110'
  | '2xl:scale-y-110'
  | 'hover:scale-y-110'
  | 'focus:scale-y-110'
  | 'sm:scale-y-125'
  | 'md:scale-y-125'
  | 'lg:scale-y-125'
  | 'xl:scale-y-125'
  | '2xl:scale-y-125'
  | 'hover:scale-y-125'
  | 'focus:scale-y-125'
  | 'sm:scale-y-150'
  | 'md:scale-y-150'
  | 'lg:scale-y-150'
  | 'xl:scale-y-150'
  | '2xl:scale-y-150'
  | 'hover:scale-y-150'
  | 'focus:scale-y-150'
  | 'sm:sepia-0'
  | 'md:sepia-0'
  | 'lg:sepia-0'
  | 'xl:sepia-0'
  | '2xl:sepia-0'
  | 'sm:sepia'
  | 'md:sepia'
  | 'lg:sepia'
  | 'xl:sepia'
  | '2xl:sepia'
  | 'sm:skew-x-0'
  | 'md:skew-x-0'
  | 'lg:skew-x-0'
  | 'xl:skew-x-0'
  | '2xl:skew-x-0'
  | 'hover:skew-x-0'
  | 'focus:skew-x-0'
  | 'sm:skew-x-1'
  | 'md:skew-x-1'
  | 'lg:skew-x-1'
  | 'xl:skew-x-1'
  | '2xl:skew-x-1'
  | 'hover:skew-x-1'
  | 'focus:skew-x-1'
  | 'sm:skew-x-2'
  | 'md:skew-x-2'
  | 'lg:skew-x-2'
  | 'xl:skew-x-2'
  | '2xl:skew-x-2'
  | 'hover:skew-x-2'
  | 'focus:skew-x-2'
  | 'sm:skew-x-3'
  | 'md:skew-x-3'
  | 'lg:skew-x-3'
  | 'xl:skew-x-3'
  | '2xl:skew-x-3'
  | 'hover:skew-x-3'
  | 'focus:skew-x-3'
  | 'sm:skew-x-6'
  | 'md:skew-x-6'
  | 'lg:skew-x-6'
  | 'xl:skew-x-6'
  | '2xl:skew-x-6'
  | 'hover:skew-x-6'
  | 'focus:skew-x-6'
  | 'sm:skew-x-12'
  | 'md:skew-x-12'
  | 'lg:skew-x-12'
  | 'xl:skew-x-12'
  | '2xl:skew-x-12'
  | 'hover:skew-x-12'
  | 'focus:skew-x-12'
  | 'sm:-skew-x-12'
  | 'md:-skew-x-12'
  | 'lg:-skew-x-12'
  | 'xl:-skew-x-12'
  | '2xl:-skew-x-12'
  | 'hover:-skew-x-12'
  | 'focus:-skew-x-12'
  | 'sm:-skew-x-6'
  | 'md:-skew-x-6'
  | 'lg:-skew-x-6'
  | 'xl:-skew-x-6'
  | '2xl:-skew-x-6'
  | 'hover:-skew-x-6'
  | 'focus:-skew-x-6'
  | 'sm:-skew-x-3'
  | 'md:-skew-x-3'
  | 'lg:-skew-x-3'
  | 'xl:-skew-x-3'
  | '2xl:-skew-x-3'
  | 'hover:-skew-x-3'
  | 'focus:-skew-x-3'
  | 'sm:-skew-x-2'
  | 'md:-skew-x-2'
  | 'lg:-skew-x-2'
  | 'xl:-skew-x-2'
  | '2xl:-skew-x-2'
  | 'hover:-skew-x-2'
  | 'focus:-skew-x-2'
  | 'sm:-skew-x-1'
  | 'md:-skew-x-1'
  | 'lg:-skew-x-1'
  | 'xl:-skew-x-1'
  | '2xl:-skew-x-1'
  | 'hover:-skew-x-1'
  | 'focus:-skew-x-1'
  | 'sm:skew-y-0'
  | 'md:skew-y-0'
  | 'lg:skew-y-0'
  | 'xl:skew-y-0'
  | '2xl:skew-y-0'
  | 'hover:skew-y-0'
  | 'focus:skew-y-0'
  | 'sm:skew-y-1'
  | 'md:skew-y-1'
  | 'lg:skew-y-1'
  | 'xl:skew-y-1'
  | '2xl:skew-y-1'
  | 'hover:skew-y-1'
  | 'focus:skew-y-1'
  | 'sm:skew-y-2'
  | 'md:skew-y-2'
  | 'lg:skew-y-2'
  | 'xl:skew-y-2'
  | '2xl:skew-y-2'
  | 'hover:skew-y-2'
  | 'focus:skew-y-2'
  | 'sm:skew-y-3'
  | 'md:skew-y-3'
  | 'lg:skew-y-3'
  | 'xl:skew-y-3'
  | '2xl:skew-y-3'
  | 'hover:skew-y-3'
  | 'focus:skew-y-3'
  | 'sm:skew-y-6'
  | 'md:skew-y-6'
  | 'lg:skew-y-6'
  | 'xl:skew-y-6'
  | '2xl:skew-y-6'
  | 'hover:skew-y-6'
  | 'focus:skew-y-6'
  | 'sm:skew-y-12'
  | 'md:skew-y-12'
  | 'lg:skew-y-12'
  | 'xl:skew-y-12'
  | '2xl:skew-y-12'
  | 'hover:skew-y-12'
  | 'focus:skew-y-12'
  | 'sm:-skew-y-12'
  | 'md:-skew-y-12'
  | 'lg:-skew-y-12'
  | 'xl:-skew-y-12'
  | '2xl:-skew-y-12'
  | 'hover:-skew-y-12'
  | 'focus:-skew-y-12'
  | 'sm:-skew-y-6'
  | 'md:-skew-y-6'
  | 'lg:-skew-y-6'
  | 'xl:-skew-y-6'
  | '2xl:-skew-y-6'
  | 'hover:-skew-y-6'
  | 'focus:-skew-y-6'
  | 'sm:-skew-y-3'
  | 'md:-skew-y-3'
  | 'lg:-skew-y-3'
  | 'xl:-skew-y-3'
  | '2xl:-skew-y-3'
  | 'hover:-skew-y-3'
  | 'focus:-skew-y-3'
  | 'sm:-skew-y-2'
  | 'md:-skew-y-2'
  | 'lg:-skew-y-2'
  | 'xl:-skew-y-2'
  | '2xl:-skew-y-2'
  | 'hover:-skew-y-2'
  | 'focus:-skew-y-2'
  | 'sm:-skew-y-1'
  | 'md:-skew-y-1'
  | 'lg:-skew-y-1'
  | 'xl:-skew-y-1'
  | '2xl:-skew-y-1'
  | 'hover:-skew-y-1'
  | 'focus:-skew-y-1'
  | 'sm:space-x-0'
  | 'md:space-x-0'
  | 'lg:space-x-0'
  | 'xl:space-x-0'
  | '2xl:space-x-0'
  | 'sm:space-x-1'
  | 'md:space-x-1'
  | 'lg:space-x-1'
  | 'xl:space-x-1'
  | '2xl:space-x-1'
  | 'sm:space-x-2'
  | 'md:space-x-2'
  | 'lg:space-x-2'
  | 'xl:space-x-2'
  | '2xl:space-x-2'
  | 'sm:space-x-3'
  | 'md:space-x-3'
  | 'lg:space-x-3'
  | 'xl:space-x-3'
  | '2xl:space-x-3'
  | 'sm:space-x-4'
  | 'md:space-x-4'
  | 'lg:space-x-4'
  | 'xl:space-x-4'
  | '2xl:space-x-4'
  | 'sm:space-x-5'
  | 'md:space-x-5'
  | 'lg:space-x-5'
  | 'xl:space-x-5'
  | '2xl:space-x-5'
  | 'sm:space-x-6'
  | 'md:space-x-6'
  | 'lg:space-x-6'
  | 'xl:space-x-6'
  | '2xl:space-x-6'
  | 'sm:space-x-7'
  | 'md:space-x-7'
  | 'lg:space-x-7'
  | 'xl:space-x-7'
  | '2xl:space-x-7'
  | 'sm:space-x-8'
  | 'md:space-x-8'
  | 'lg:space-x-8'
  | 'xl:space-x-8'
  | '2xl:space-x-8'
  | 'sm:space-x-9'
  | 'md:space-x-9'
  | 'lg:space-x-9'
  | 'xl:space-x-9'
  | '2xl:space-x-9'
  | 'sm:space-x-10'
  | 'md:space-x-10'
  | 'lg:space-x-10'
  | 'xl:space-x-10'
  | '2xl:space-x-10'
  | 'sm:space-x-11'
  | 'md:space-x-11'
  | 'lg:space-x-11'
  | 'xl:space-x-11'
  | '2xl:space-x-11'
  | 'sm:space-x-12'
  | 'md:space-x-12'
  | 'lg:space-x-12'
  | 'xl:space-x-12'
  | '2xl:space-x-12'
  | 'sm:space-x-14'
  | 'md:space-x-14'
  | 'lg:space-x-14'
  | 'xl:space-x-14'
  | '2xl:space-x-14'
  | 'sm:space-x-16'
  | 'md:space-x-16'
  | 'lg:space-x-16'
  | 'xl:space-x-16'
  | '2xl:space-x-16'
  | 'sm:space-x-20'
  | 'md:space-x-20'
  | 'lg:space-x-20'
  | 'xl:space-x-20'
  | '2xl:space-x-20'
  | 'sm:space-x-24'
  | 'md:space-x-24'
  | 'lg:space-x-24'
  | 'xl:space-x-24'
  | '2xl:space-x-24'
  | 'sm:space-x-28'
  | 'md:space-x-28'
  | 'lg:space-x-28'
  | 'xl:space-x-28'
  | '2xl:space-x-28'
  | 'sm:space-x-32'
  | 'md:space-x-32'
  | 'lg:space-x-32'
  | 'xl:space-x-32'
  | '2xl:space-x-32'
  | 'sm:space-x-36'
  | 'md:space-x-36'
  | 'lg:space-x-36'
  | 'xl:space-x-36'
  | '2xl:space-x-36'
  | 'sm:space-x-40'
  | 'md:space-x-40'
  | 'lg:space-x-40'
  | 'xl:space-x-40'
  | '2xl:space-x-40'
  | 'sm:space-x-44'
  | 'md:space-x-44'
  | 'lg:space-x-44'
  | 'xl:space-x-44'
  | '2xl:space-x-44'
  | 'sm:space-x-48'
  | 'md:space-x-48'
  | 'lg:space-x-48'
  | 'xl:space-x-48'
  | '2xl:space-x-48'
  | 'sm:space-x-52'
  | 'md:space-x-52'
  | 'lg:space-x-52'
  | 'xl:space-x-52'
  | '2xl:space-x-52'
  | 'sm:space-x-56'
  | 'md:space-x-56'
  | 'lg:space-x-56'
  | 'xl:space-x-56'
  | '2xl:space-x-56'
  | 'sm:space-x-60'
  | 'md:space-x-60'
  | 'lg:space-x-60'
  | 'xl:space-x-60'
  | '2xl:space-x-60'
  | 'sm:space-x-64'
  | 'md:space-x-64'
  | 'lg:space-x-64'
  | 'xl:space-x-64'
  | '2xl:space-x-64'
  | 'sm:space-x-72'
  | 'md:space-x-72'
  | 'lg:space-x-72'
  | 'xl:space-x-72'
  | '2xl:space-x-72'
  | 'sm:space-x-80'
  | 'md:space-x-80'
  | 'lg:space-x-80'
  | 'xl:space-x-80'
  | '2xl:space-x-80'
  | 'sm:space-x-96'
  | 'md:space-x-96'
  | 'lg:space-x-96'
  | 'xl:space-x-96'
  | '2xl:space-x-96'
  | 'sm:space-x-px'
  | 'md:space-x-px'
  | 'lg:space-x-px'
  | 'xl:space-x-px'
  | '2xl:space-x-px'
  | 'sm:space-x-0.5'
  | 'md:space-x-0.5'
  | 'lg:space-x-0.5'
  | 'xl:space-x-0.5'
  | '2xl:space-x-0.5'
  | 'sm:space-x-1.5'
  | 'md:space-x-1.5'
  | 'lg:space-x-1.5'
  | 'xl:space-x-1.5'
  | '2xl:space-x-1.5'
  | 'sm:space-x-2.5'
  | 'md:space-x-2.5'
  | 'lg:space-x-2.5'
  | 'xl:space-x-2.5'
  | '2xl:space-x-2.5'
  | 'sm:space-x-3.5'
  | 'md:space-x-3.5'
  | 'lg:space-x-3.5'
  | 'xl:space-x-3.5'
  | '2xl:space-x-3.5'
  | 'sm:-space-x-0'
  | 'md:-space-x-0'
  | 'lg:-space-x-0'
  | 'xl:-space-x-0'
  | '2xl:-space-x-0'
  | 'sm:-space-x-1'
  | 'md:-space-x-1'
  | 'lg:-space-x-1'
  | 'xl:-space-x-1'
  | '2xl:-space-x-1'
  | 'sm:-space-x-2'
  | 'md:-space-x-2'
  | 'lg:-space-x-2'
  | 'xl:-space-x-2'
  | '2xl:-space-x-2'
  | 'sm:-space-x-3'
  | 'md:-space-x-3'
  | 'lg:-space-x-3'
  | 'xl:-space-x-3'
  | '2xl:-space-x-3'
  | 'sm:-space-x-4'
  | 'md:-space-x-4'
  | 'lg:-space-x-4'
  | 'xl:-space-x-4'
  | '2xl:-space-x-4'
  | 'sm:-space-x-5'
  | 'md:-space-x-5'
  | 'lg:-space-x-5'
  | 'xl:-space-x-5'
  | '2xl:-space-x-5'
  | 'sm:-space-x-6'
  | 'md:-space-x-6'
  | 'lg:-space-x-6'
  | 'xl:-space-x-6'
  | '2xl:-space-x-6'
  | 'sm:-space-x-7'
  | 'md:-space-x-7'
  | 'lg:-space-x-7'
  | 'xl:-space-x-7'
  | '2xl:-space-x-7'
  | 'sm:-space-x-8'
  | 'md:-space-x-8'
  | 'lg:-space-x-8'
  | 'xl:-space-x-8'
  | '2xl:-space-x-8'
  | 'sm:-space-x-9'
  | 'md:-space-x-9'
  | 'lg:-space-x-9'
  | 'xl:-space-x-9'
  | '2xl:-space-x-9'
  | 'sm:-space-x-10'
  | 'md:-space-x-10'
  | 'lg:-space-x-10'
  | 'xl:-space-x-10'
  | '2xl:-space-x-10'
  | 'sm:-space-x-11'
  | 'md:-space-x-11'
  | 'lg:-space-x-11'
  | 'xl:-space-x-11'
  | '2xl:-space-x-11'
  | 'sm:-space-x-12'
  | 'md:-space-x-12'
  | 'lg:-space-x-12'
  | 'xl:-space-x-12'
  | '2xl:-space-x-12'
  | 'sm:-space-x-14'
  | 'md:-space-x-14'
  | 'lg:-space-x-14'
  | 'xl:-space-x-14'
  | '2xl:-space-x-14'
  | 'sm:-space-x-16'
  | 'md:-space-x-16'
  | 'lg:-space-x-16'
  | 'xl:-space-x-16'
  | '2xl:-space-x-16'
  | 'sm:-space-x-20'
  | 'md:-space-x-20'
  | 'lg:-space-x-20'
  | 'xl:-space-x-20'
  | '2xl:-space-x-20'
  | 'sm:-space-x-24'
  | 'md:-space-x-24'
  | 'lg:-space-x-24'
  | 'xl:-space-x-24'
  | '2xl:-space-x-24'
  | 'sm:-space-x-28'
  | 'md:-space-x-28'
  | 'lg:-space-x-28'
  | 'xl:-space-x-28'
  | '2xl:-space-x-28'
  | 'sm:-space-x-32'
  | 'md:-space-x-32'
  | 'lg:-space-x-32'
  | 'xl:-space-x-32'
  | '2xl:-space-x-32'
  | 'sm:-space-x-36'
  | 'md:-space-x-36'
  | 'lg:-space-x-36'
  | 'xl:-space-x-36'
  | '2xl:-space-x-36'
  | 'sm:-space-x-40'
  | 'md:-space-x-40'
  | 'lg:-space-x-40'
  | 'xl:-space-x-40'
  | '2xl:-space-x-40'
  | 'sm:-space-x-44'
  | 'md:-space-x-44'
  | 'lg:-space-x-44'
  | 'xl:-space-x-44'
  | '2xl:-space-x-44'
  | 'sm:-space-x-48'
  | 'md:-space-x-48'
  | 'lg:-space-x-48'
  | 'xl:-space-x-48'
  | '2xl:-space-x-48'
  | 'sm:-space-x-52'
  | 'md:-space-x-52'
  | 'lg:-space-x-52'
  | 'xl:-space-x-52'
  | '2xl:-space-x-52'
  | 'sm:-space-x-56'
  | 'md:-space-x-56'
  | 'lg:-space-x-56'
  | 'xl:-space-x-56'
  | '2xl:-space-x-56'
  | 'sm:-space-x-60'
  | 'md:-space-x-60'
  | 'lg:-space-x-60'
  | 'xl:-space-x-60'
  | '2xl:-space-x-60'
  | 'sm:-space-x-64'
  | 'md:-space-x-64'
  | 'lg:-space-x-64'
  | 'xl:-space-x-64'
  | '2xl:-space-x-64'
  | 'sm:-space-x-72'
  | 'md:-space-x-72'
  | 'lg:-space-x-72'
  | 'xl:-space-x-72'
  | '2xl:-space-x-72'
  | 'sm:-space-x-80'
  | 'md:-space-x-80'
  | 'lg:-space-x-80'
  | 'xl:-space-x-80'
  | '2xl:-space-x-80'
  | 'sm:-space-x-96'
  | 'md:-space-x-96'
  | 'lg:-space-x-96'
  | 'xl:-space-x-96'
  | '2xl:-space-x-96'
  | 'sm:-space-x-px'
  | 'md:-space-x-px'
  | 'lg:-space-x-px'
  | 'xl:-space-x-px'
  | '2xl:-space-x-px'
  | 'sm:-space-x-0.5'
  | 'md:-space-x-0.5'
  | 'lg:-space-x-0.5'
  | 'xl:-space-x-0.5'
  | '2xl:-space-x-0.5'
  | 'sm:-space-x-1.5'
  | 'md:-space-x-1.5'
  | 'lg:-space-x-1.5'
  | 'xl:-space-x-1.5'
  | '2xl:-space-x-1.5'
  | 'sm:-space-x-2.5'
  | 'md:-space-x-2.5'
  | 'lg:-space-x-2.5'
  | 'xl:-space-x-2.5'
  | '2xl:-space-x-2.5'
  | 'sm:-space-x-3.5'
  | 'md:-space-x-3.5'
  | 'lg:-space-x-3.5'
  | 'xl:-space-x-3.5'
  | '2xl:-space-x-3.5'
  | 'sm:space-x-reverse'
  | 'md:space-x-reverse'
  | 'lg:space-x-reverse'
  | 'xl:space-x-reverse'
  | '2xl:space-x-reverse'
  | 'sm:space-y-0'
  | 'md:space-y-0'
  | 'lg:space-y-0'
  | 'xl:space-y-0'
  | '2xl:space-y-0'
  | 'sm:space-y-1'
  | 'md:space-y-1'
  | 'lg:space-y-1'
  | 'xl:space-y-1'
  | '2xl:space-y-1'
  | 'sm:space-y-2'
  | 'md:space-y-2'
  | 'lg:space-y-2'
  | 'xl:space-y-2'
  | '2xl:space-y-2'
  | 'sm:space-y-3'
  | 'md:space-y-3'
  | 'lg:space-y-3'
  | 'xl:space-y-3'
  | '2xl:space-y-3'
  | 'sm:space-y-4'
  | 'md:space-y-4'
  | 'lg:space-y-4'
  | 'xl:space-y-4'
  | '2xl:space-y-4'
  | 'sm:space-y-5'
  | 'md:space-y-5'
  | 'lg:space-y-5'
  | 'xl:space-y-5'
  | '2xl:space-y-5'
  | 'sm:space-y-6'
  | 'md:space-y-6'
  | 'lg:space-y-6'
  | 'xl:space-y-6'
  | '2xl:space-y-6'
  | 'sm:space-y-7'
  | 'md:space-y-7'
  | 'lg:space-y-7'
  | 'xl:space-y-7'
  | '2xl:space-y-7'
  | 'sm:space-y-8'
  | 'md:space-y-8'
  | 'lg:space-y-8'
  | 'xl:space-y-8'
  | '2xl:space-y-8'
  | 'sm:space-y-9'
  | 'md:space-y-9'
  | 'lg:space-y-9'
  | 'xl:space-y-9'
  | '2xl:space-y-9'
  | 'sm:space-y-10'
  | 'md:space-y-10'
  | 'lg:space-y-10'
  | 'xl:space-y-10'
  | '2xl:space-y-10'
  | 'sm:space-y-11'
  | 'md:space-y-11'
  | 'lg:space-y-11'
  | 'xl:space-y-11'
  | '2xl:space-y-11'
  | 'sm:space-y-12'
  | 'md:space-y-12'
  | 'lg:space-y-12'
  | 'xl:space-y-12'
  | '2xl:space-y-12'
  | 'sm:space-y-14'
  | 'md:space-y-14'
  | 'lg:space-y-14'
  | 'xl:space-y-14'
  | '2xl:space-y-14'
  | 'sm:space-y-16'
  | 'md:space-y-16'
  | 'lg:space-y-16'
  | 'xl:space-y-16'
  | '2xl:space-y-16'
  | 'sm:space-y-20'
  | 'md:space-y-20'
  | 'lg:space-y-20'
  | 'xl:space-y-20'
  | '2xl:space-y-20'
  | 'sm:space-y-24'
  | 'md:space-y-24'
  | 'lg:space-y-24'
  | 'xl:space-y-24'
  | '2xl:space-y-24'
  | 'sm:space-y-28'
  | 'md:space-y-28'
  | 'lg:space-y-28'
  | 'xl:space-y-28'
  | '2xl:space-y-28'
  | 'sm:space-y-32'
  | 'md:space-y-32'
  | 'lg:space-y-32'
  | 'xl:space-y-32'
  | '2xl:space-y-32'
  | 'sm:space-y-36'
  | 'md:space-y-36'
  | 'lg:space-y-36'
  | 'xl:space-y-36'
  | '2xl:space-y-36'
  | 'sm:space-y-40'
  | 'md:space-y-40'
  | 'lg:space-y-40'
  | 'xl:space-y-40'
  | '2xl:space-y-40'
  | 'sm:space-y-44'
  | 'md:space-y-44'
  | 'lg:space-y-44'
  | 'xl:space-y-44'
  | '2xl:space-y-44'
  | 'sm:space-y-48'
  | 'md:space-y-48'
  | 'lg:space-y-48'
  | 'xl:space-y-48'
  | '2xl:space-y-48'
  | 'sm:space-y-52'
  | 'md:space-y-52'
  | 'lg:space-y-52'
  | 'xl:space-y-52'
  | '2xl:space-y-52'
  | 'sm:space-y-56'
  | 'md:space-y-56'
  | 'lg:space-y-56'
  | 'xl:space-y-56'
  | '2xl:space-y-56'
  | 'sm:space-y-60'
  | 'md:space-y-60'
  | 'lg:space-y-60'
  | 'xl:space-y-60'
  | '2xl:space-y-60'
  | 'sm:space-y-64'
  | 'md:space-y-64'
  | 'lg:space-y-64'
  | 'xl:space-y-64'
  | '2xl:space-y-64'
  | 'sm:space-y-72'
  | 'md:space-y-72'
  | 'lg:space-y-72'
  | 'xl:space-y-72'
  | '2xl:space-y-72'
  | 'sm:space-y-80'
  | 'md:space-y-80'
  | 'lg:space-y-80'
  | 'xl:space-y-80'
  | '2xl:space-y-80'
  | 'sm:space-y-96'
  | 'md:space-y-96'
  | 'lg:space-y-96'
  | 'xl:space-y-96'
  | '2xl:space-y-96'
  | 'sm:space-y-px'
  | 'md:space-y-px'
  | 'lg:space-y-px'
  | 'xl:space-y-px'
  | '2xl:space-y-px'
  | 'sm:space-y-0.5'
  | 'md:space-y-0.5'
  | 'lg:space-y-0.5'
  | 'xl:space-y-0.5'
  | '2xl:space-y-0.5'
  | 'sm:space-y-1.5'
  | 'md:space-y-1.5'
  | 'lg:space-y-1.5'
  | 'xl:space-y-1.5'
  | '2xl:space-y-1.5'
  | 'sm:space-y-2.5'
  | 'md:space-y-2.5'
  | 'lg:space-y-2.5'
  | 'xl:space-y-2.5'
  | '2xl:space-y-2.5'
  | 'sm:space-y-3.5'
  | 'md:space-y-3.5'
  | 'lg:space-y-3.5'
  | 'xl:space-y-3.5'
  | '2xl:space-y-3.5'
  | 'sm:-space-y-0'
  | 'md:-space-y-0'
  | 'lg:-space-y-0'
  | 'xl:-space-y-0'
  | '2xl:-space-y-0'
  | 'sm:-space-y-1'
  | 'md:-space-y-1'
  | 'lg:-space-y-1'
  | 'xl:-space-y-1'
  | '2xl:-space-y-1'
  | 'sm:-space-y-2'
  | 'md:-space-y-2'
  | 'lg:-space-y-2'
  | 'xl:-space-y-2'
  | '2xl:-space-y-2'
  | 'sm:-space-y-3'
  | 'md:-space-y-3'
  | 'lg:-space-y-3'
  | 'xl:-space-y-3'
  | '2xl:-space-y-3'
  | 'sm:-space-y-4'
  | 'md:-space-y-4'
  | 'lg:-space-y-4'
  | 'xl:-space-y-4'
  | '2xl:-space-y-4'
  | 'sm:-space-y-5'
  | 'md:-space-y-5'
  | 'lg:-space-y-5'
  | 'xl:-space-y-5'
  | '2xl:-space-y-5'
  | 'sm:-space-y-6'
  | 'md:-space-y-6'
  | 'lg:-space-y-6'
  | 'xl:-space-y-6'
  | '2xl:-space-y-6'
  | 'sm:-space-y-7'
  | 'md:-space-y-7'
  | 'lg:-space-y-7'
  | 'xl:-space-y-7'
  | '2xl:-space-y-7'
  | 'sm:-space-y-8'
  | 'md:-space-y-8'
  | 'lg:-space-y-8'
  | 'xl:-space-y-8'
  | '2xl:-space-y-8'
  | 'sm:-space-y-9'
  | 'md:-space-y-9'
  | 'lg:-space-y-9'
  | 'xl:-space-y-9'
  | '2xl:-space-y-9'
  | 'sm:-space-y-10'
  | 'md:-space-y-10'
  | 'lg:-space-y-10'
  | 'xl:-space-y-10'
  | '2xl:-space-y-10'
  | 'sm:-space-y-11'
  | 'md:-space-y-11'
  | 'lg:-space-y-11'
  | 'xl:-space-y-11'
  | '2xl:-space-y-11'
  | 'sm:-space-y-12'
  | 'md:-space-y-12'
  | 'lg:-space-y-12'
  | 'xl:-space-y-12'
  | '2xl:-space-y-12'
  | 'sm:-space-y-14'
  | 'md:-space-y-14'
  | 'lg:-space-y-14'
  | 'xl:-space-y-14'
  | '2xl:-space-y-14'
  | 'sm:-space-y-16'
  | 'md:-space-y-16'
  | 'lg:-space-y-16'
  | 'xl:-space-y-16'
  | '2xl:-space-y-16'
  | 'sm:-space-y-20'
  | 'md:-space-y-20'
  | 'lg:-space-y-20'
  | 'xl:-space-y-20'
  | '2xl:-space-y-20'
  | 'sm:-space-y-24'
  | 'md:-space-y-24'
  | 'lg:-space-y-24'
  | 'xl:-space-y-24'
  | '2xl:-space-y-24'
  | 'sm:-space-y-28'
  | 'md:-space-y-28'
  | 'lg:-space-y-28'
  | 'xl:-space-y-28'
  | '2xl:-space-y-28'
  | 'sm:-space-y-32'
  | 'md:-space-y-32'
  | 'lg:-space-y-32'
  | 'xl:-space-y-32'
  | '2xl:-space-y-32'
  | 'sm:-space-y-36'
  | 'md:-space-y-36'
  | 'lg:-space-y-36'
  | 'xl:-space-y-36'
  | '2xl:-space-y-36'
  | 'sm:-space-y-40'
  | 'md:-space-y-40'
  | 'lg:-space-y-40'
  | 'xl:-space-y-40'
  | '2xl:-space-y-40'
  | 'sm:-space-y-44'
  | 'md:-space-y-44'
  | 'lg:-space-y-44'
  | 'xl:-space-y-44'
  | '2xl:-space-y-44'
  | 'sm:-space-y-48'
  | 'md:-space-y-48'
  | 'lg:-space-y-48'
  | 'xl:-space-y-48'
  | '2xl:-space-y-48'
  | 'sm:-space-y-52'
  | 'md:-space-y-52'
  | 'lg:-space-y-52'
  | 'xl:-space-y-52'
  | '2xl:-space-y-52'
  | 'sm:-space-y-56'
  | 'md:-space-y-56'
  | 'lg:-space-y-56'
  | 'xl:-space-y-56'
  | '2xl:-space-y-56'
  | 'sm:-space-y-60'
  | 'md:-space-y-60'
  | 'lg:-space-y-60'
  | 'xl:-space-y-60'
  | '2xl:-space-y-60'
  | 'sm:-space-y-64'
  | 'md:-space-y-64'
  | 'lg:-space-y-64'
  | 'xl:-space-y-64'
  | '2xl:-space-y-64'
  | 'sm:-space-y-72'
  | 'md:-space-y-72'
  | 'lg:-space-y-72'
  | 'xl:-space-y-72'
  | '2xl:-space-y-72'
  | 'sm:-space-y-80'
  | 'md:-space-y-80'
  | 'lg:-space-y-80'
  | 'xl:-space-y-80'
  | '2xl:-space-y-80'
  | 'sm:-space-y-96'
  | 'md:-space-y-96'
  | 'lg:-space-y-96'
  | 'xl:-space-y-96'
  | '2xl:-space-y-96'
  | 'sm:-space-y-px'
  | 'md:-space-y-px'
  | 'lg:-space-y-px'
  | 'xl:-space-y-px'
  | '2xl:-space-y-px'
  | 'sm:-space-y-0.5'
  | 'md:-space-y-0.5'
  | 'lg:-space-y-0.5'
  | 'xl:-space-y-0.5'
  | '2xl:-space-y-0.5'
  | 'sm:-space-y-1.5'
  | 'md:-space-y-1.5'
  | 'lg:-space-y-1.5'
  | 'xl:-space-y-1.5'
  | '2xl:-space-y-1.5'
  | 'sm:-space-y-2.5'
  | 'md:-space-y-2.5'
  | 'lg:-space-y-2.5'
  | 'xl:-space-y-2.5'
  | '2xl:-space-y-2.5'
  | 'sm:-space-y-3.5'
  | 'md:-space-y-3.5'
  | 'lg:-space-y-3.5'
  | 'xl:-space-y-3.5'
  | '2xl:-space-y-3.5'
  | 'sm:space-y-reverse'
  | 'md:space-y-reverse'
  | 'lg:space-y-reverse'
  | 'xl:space-y-reverse'
  | '2xl:space-y-reverse'
  | 'sm:stroke-current'
  | 'md:stroke-current'
  | 'lg:stroke-current'
  | 'xl:stroke-current'
  | '2xl:stroke-current'
  | 'sm:stroke-0'
  | 'md:stroke-0'
  | 'lg:stroke-0'
  | 'xl:stroke-0'
  | '2xl:stroke-0'
  | 'sm:stroke-1'
  | 'md:stroke-1'
  | 'lg:stroke-1'
  | 'xl:stroke-1'
  | '2xl:stroke-1'
  | 'sm:stroke-2'
  | 'md:stroke-2'
  | 'lg:stroke-2'
  | 'xl:stroke-2'
  | '2xl:stroke-2'
  | 'sm:table-auto'
  | 'md:table-auto'
  | 'lg:table-auto'
  | 'xl:table-auto'
  | '2xl:table-auto'
  | 'sm:table-fixed'
  | 'md:table-fixed'
  | 'lg:table-fixed'
  | 'xl:table-fixed'
  | '2xl:table-fixed'
  | 'sm:text-left'
  | 'md:text-left'
  | 'lg:text-left'
  | 'xl:text-left'
  | '2xl:text-left'
  | 'sm:text-center'
  | 'md:text-center'
  | 'lg:text-center'
  | 'xl:text-center'
  | '2xl:text-center'
  | 'sm:text-right'
  | 'md:text-right'
  | 'lg:text-right'
  | 'xl:text-right'
  | '2xl:text-right'
  | 'sm:text-justify'
  | 'md:text-justify'
  | 'lg:text-justify'
  | 'xl:text-justify'
  | '2xl:text-justify'
  | 'sm:text-transparent'
  | 'md:text-transparent'
  | 'lg:text-transparent'
  | 'xl:text-transparent'
  | '2xl:text-transparent'
  | 'group-hover:text-transparent'
  | 'focus-within:text-transparent'
  | 'hover:text-transparent'
  | 'focus:text-transparent'
  | 'sm:text-current'
  | 'md:text-current'
  | 'lg:text-current'
  | 'xl:text-current'
  | '2xl:text-current'
  | 'group-hover:text-current'
  | 'focus-within:text-current'
  | 'hover:text-current'
  | 'focus:text-current'
  | 'sm:text-black'
  | 'md:text-black'
  | 'lg:text-black'
  | 'xl:text-black'
  | '2xl:text-black'
  | 'group-hover:text-black'
  | 'focus-within:text-black'
  | 'hover:text-black'
  | 'focus:text-black'
  | 'sm:text-white'
  | 'md:text-white'
  | 'lg:text-white'
  | 'xl:text-white'
  | '2xl:text-white'
  | 'group-hover:text-white'
  | 'focus-within:text-white'
  | 'hover:text-white'
  | 'focus:text-white'
  | 'sm:text-gray-50'
  | 'md:text-gray-50'
  | 'lg:text-gray-50'
  | 'xl:text-gray-50'
  | '2xl:text-gray-50'
  | 'group-hover:text-gray-50'
  | 'focus-within:text-gray-50'
  | 'hover:text-gray-50'
  | 'focus:text-gray-50'
  | 'sm:text-gray-100'
  | 'md:text-gray-100'
  | 'lg:text-gray-100'
  | 'xl:text-gray-100'
  | '2xl:text-gray-100'
  | 'group-hover:text-gray-100'
  | 'focus-within:text-gray-100'
  | 'hover:text-gray-100'
  | 'focus:text-gray-100'
  | 'sm:text-gray-200'
  | 'md:text-gray-200'
  | 'lg:text-gray-200'
  | 'xl:text-gray-200'
  | '2xl:text-gray-200'
  | 'group-hover:text-gray-200'
  | 'focus-within:text-gray-200'
  | 'hover:text-gray-200'
  | 'focus:text-gray-200'
  | 'sm:text-gray-300'
  | 'md:text-gray-300'
  | 'lg:text-gray-300'
  | 'xl:text-gray-300'
  | '2xl:text-gray-300'
  | 'group-hover:text-gray-300'
  | 'focus-within:text-gray-300'
  | 'hover:text-gray-300'
  | 'focus:text-gray-300'
  | 'sm:text-gray-400'
  | 'md:text-gray-400'
  | 'lg:text-gray-400'
  | 'xl:text-gray-400'
  | '2xl:text-gray-400'
  | 'group-hover:text-gray-400'
  | 'focus-within:text-gray-400'
  | 'hover:text-gray-400'
  | 'focus:text-gray-400'
  | 'sm:text-gray-500'
  | 'md:text-gray-500'
  | 'lg:text-gray-500'
  | 'xl:text-gray-500'
  | '2xl:text-gray-500'
  | 'group-hover:text-gray-500'
  | 'focus-within:text-gray-500'
  | 'hover:text-gray-500'
  | 'focus:text-gray-500'
  | 'sm:text-gray-600'
  | 'md:text-gray-600'
  | 'lg:text-gray-600'
  | 'xl:text-gray-600'
  | '2xl:text-gray-600'
  | 'group-hover:text-gray-600'
  | 'focus-within:text-gray-600'
  | 'hover:text-gray-600'
  | 'focus:text-gray-600'
  | 'sm:text-gray-700'
  | 'md:text-gray-700'
  | 'lg:text-gray-700'
  | 'xl:text-gray-700'
  | '2xl:text-gray-700'
  | 'group-hover:text-gray-700'
  | 'focus-within:text-gray-700'
  | 'hover:text-gray-700'
  | 'focus:text-gray-700'
  | 'sm:text-gray-800'
  | 'md:text-gray-800'
  | 'lg:text-gray-800'
  | 'xl:text-gray-800'
  | '2xl:text-gray-800'
  | 'group-hover:text-gray-800'
  | 'focus-within:text-gray-800'
  | 'hover:text-gray-800'
  | 'focus:text-gray-800'
  | 'sm:text-gray-900'
  | 'md:text-gray-900'
  | 'lg:text-gray-900'
  | 'xl:text-gray-900'
  | '2xl:text-gray-900'
  | 'group-hover:text-gray-900'
  | 'focus-within:text-gray-900'
  | 'hover:text-gray-900'
  | 'focus:text-gray-900'
  | 'sm:text-red-50'
  | 'md:text-red-50'
  | 'lg:text-red-50'
  | 'xl:text-red-50'
  | '2xl:text-red-50'
  | 'group-hover:text-red-50'
  | 'focus-within:text-red-50'
  | 'hover:text-red-50'
  | 'focus:text-red-50'
  | 'sm:text-red-100'
  | 'md:text-red-100'
  | 'lg:text-red-100'
  | 'xl:text-red-100'
  | '2xl:text-red-100'
  | 'group-hover:text-red-100'
  | 'focus-within:text-red-100'
  | 'hover:text-red-100'
  | 'focus:text-red-100'
  | 'sm:text-red-200'
  | 'md:text-red-200'
  | 'lg:text-red-200'
  | 'xl:text-red-200'
  | '2xl:text-red-200'
  | 'group-hover:text-red-200'
  | 'focus-within:text-red-200'
  | 'hover:text-red-200'
  | 'focus:text-red-200'
  | 'sm:text-red-300'
  | 'md:text-red-300'
  | 'lg:text-red-300'
  | 'xl:text-red-300'
  | '2xl:text-red-300'
  | 'group-hover:text-red-300'
  | 'focus-within:text-red-300'
  | 'hover:text-red-300'
  | 'focus:text-red-300'
  | 'sm:text-red-400'
  | 'md:text-red-400'
  | 'lg:text-red-400'
  | 'xl:text-red-400'
  | '2xl:text-red-400'
  | 'group-hover:text-red-400'
  | 'focus-within:text-red-400'
  | 'hover:text-red-400'
  | 'focus:text-red-400'
  | 'sm:text-red-500'
  | 'md:text-red-500'
  | 'lg:text-red-500'
  | 'xl:text-red-500'
  | '2xl:text-red-500'
  | 'group-hover:text-red-500'
  | 'focus-within:text-red-500'
  | 'hover:text-red-500'
  | 'focus:text-red-500'
  | 'sm:text-red-600'
  | 'md:text-red-600'
  | 'lg:text-red-600'
  | 'xl:text-red-600'
  | '2xl:text-red-600'
  | 'group-hover:text-red-600'
  | 'focus-within:text-red-600'
  | 'hover:text-red-600'
  | 'focus:text-red-600'
  | 'sm:text-red-700'
  | 'md:text-red-700'
  | 'lg:text-red-700'
  | 'xl:text-red-700'
  | '2xl:text-red-700'
  | 'group-hover:text-red-700'
  | 'focus-within:text-red-700'
  | 'hover:text-red-700'
  | 'focus:text-red-700'
  | 'sm:text-red-800'
  | 'md:text-red-800'
  | 'lg:text-red-800'
  | 'xl:text-red-800'
  | '2xl:text-red-800'
  | 'group-hover:text-red-800'
  | 'focus-within:text-red-800'
  | 'hover:text-red-800'
  | 'focus:text-red-800'
  | 'sm:text-red-900'
  | 'md:text-red-900'
  | 'lg:text-red-900'
  | 'xl:text-red-900'
  | '2xl:text-red-900'
  | 'group-hover:text-red-900'
  | 'focus-within:text-red-900'
  | 'hover:text-red-900'
  | 'focus:text-red-900'
  | 'sm:text-yellow-50'
  | 'md:text-yellow-50'
  | 'lg:text-yellow-50'
  | 'xl:text-yellow-50'
  | '2xl:text-yellow-50'
  | 'group-hover:text-yellow-50'
  | 'focus-within:text-yellow-50'
  | 'hover:text-yellow-50'
  | 'focus:text-yellow-50'
  | 'sm:text-yellow-100'
  | 'md:text-yellow-100'
  | 'lg:text-yellow-100'
  | 'xl:text-yellow-100'
  | '2xl:text-yellow-100'
  | 'group-hover:text-yellow-100'
  | 'focus-within:text-yellow-100'
  | 'hover:text-yellow-100'
  | 'focus:text-yellow-100'
  | 'sm:text-yellow-200'
  | 'md:text-yellow-200'
  | 'lg:text-yellow-200'
  | 'xl:text-yellow-200'
  | '2xl:text-yellow-200'
  | 'group-hover:text-yellow-200'
  | 'focus-within:text-yellow-200'
  | 'hover:text-yellow-200'
  | 'focus:text-yellow-200'
  | 'sm:text-yellow-300'
  | 'md:text-yellow-300'
  | 'lg:text-yellow-300'
  | 'xl:text-yellow-300'
  | '2xl:text-yellow-300'
  | 'group-hover:text-yellow-300'
  | 'focus-within:text-yellow-300'
  | 'hover:text-yellow-300'
  | 'focus:text-yellow-300'
  | 'sm:text-yellow-400'
  | 'md:text-yellow-400'
  | 'lg:text-yellow-400'
  | 'xl:text-yellow-400'
  | '2xl:text-yellow-400'
  | 'group-hover:text-yellow-400'
  | 'focus-within:text-yellow-400'
  | 'hover:text-yellow-400'
  | 'focus:text-yellow-400'
  | 'sm:text-yellow-500'
  | 'md:text-yellow-500'
  | 'lg:text-yellow-500'
  | 'xl:text-yellow-500'
  | '2xl:text-yellow-500'
  | 'group-hover:text-yellow-500'
  | 'focus-within:text-yellow-500'
  | 'hover:text-yellow-500'
  | 'focus:text-yellow-500'
  | 'sm:text-yellow-600'
  | 'md:text-yellow-600'
  | 'lg:text-yellow-600'
  | 'xl:text-yellow-600'
  | '2xl:text-yellow-600'
  | 'group-hover:text-yellow-600'
  | 'focus-within:text-yellow-600'
  | 'hover:text-yellow-600'
  | 'focus:text-yellow-600'
  | 'sm:text-yellow-700'
  | 'md:text-yellow-700'
  | 'lg:text-yellow-700'
  | 'xl:text-yellow-700'
  | '2xl:text-yellow-700'
  | 'group-hover:text-yellow-700'
  | 'focus-within:text-yellow-700'
  | 'hover:text-yellow-700'
  | 'focus:text-yellow-700'
  | 'sm:text-yellow-800'
  | 'md:text-yellow-800'
  | 'lg:text-yellow-800'
  | 'xl:text-yellow-800'
  | '2xl:text-yellow-800'
  | 'group-hover:text-yellow-800'
  | 'focus-within:text-yellow-800'
  | 'hover:text-yellow-800'
  | 'focus:text-yellow-800'
  | 'sm:text-yellow-900'
  | 'md:text-yellow-900'
  | 'lg:text-yellow-900'
  | 'xl:text-yellow-900'
  | '2xl:text-yellow-900'
  | 'group-hover:text-yellow-900'
  | 'focus-within:text-yellow-900'
  | 'hover:text-yellow-900'
  | 'focus:text-yellow-900'
  | 'sm:text-green-50'
  | 'md:text-green-50'
  | 'lg:text-green-50'
  | 'xl:text-green-50'
  | '2xl:text-green-50'
  | 'group-hover:text-green-50'
  | 'focus-within:text-green-50'
  | 'hover:text-green-50'
  | 'focus:text-green-50'
  | 'sm:text-green-100'
  | 'md:text-green-100'
  | 'lg:text-green-100'
  | 'xl:text-green-100'
  | '2xl:text-green-100'
  | 'group-hover:text-green-100'
  | 'focus-within:text-green-100'
  | 'hover:text-green-100'
  | 'focus:text-green-100'
  | 'sm:text-green-200'
  | 'md:text-green-200'
  | 'lg:text-green-200'
  | 'xl:text-green-200'
  | '2xl:text-green-200'
  | 'group-hover:text-green-200'
  | 'focus-within:text-green-200'
  | 'hover:text-green-200'
  | 'focus:text-green-200'
  | 'sm:text-green-300'
  | 'md:text-green-300'
  | 'lg:text-green-300'
  | 'xl:text-green-300'
  | '2xl:text-green-300'
  | 'group-hover:text-green-300'
  | 'focus-within:text-green-300'
  | 'hover:text-green-300'
  | 'focus:text-green-300'
  | 'sm:text-green-400'
  | 'md:text-green-400'
  | 'lg:text-green-400'
  | 'xl:text-green-400'
  | '2xl:text-green-400'
  | 'group-hover:text-green-400'
  | 'focus-within:text-green-400'
  | 'hover:text-green-400'
  | 'focus:text-green-400'
  | 'sm:text-green-500'
  | 'md:text-green-500'
  | 'lg:text-green-500'
  | 'xl:text-green-500'
  | '2xl:text-green-500'
  | 'group-hover:text-green-500'
  | 'focus-within:text-green-500'
  | 'hover:text-green-500'
  | 'focus:text-green-500'
  | 'sm:text-green-600'
  | 'md:text-green-600'
  | 'lg:text-green-600'
  | 'xl:text-green-600'
  | '2xl:text-green-600'
  | 'group-hover:text-green-600'
  | 'focus-within:text-green-600'
  | 'hover:text-green-600'
  | 'focus:text-green-600'
  | 'sm:text-green-700'
  | 'md:text-green-700'
  | 'lg:text-green-700'
  | 'xl:text-green-700'
  | '2xl:text-green-700'
  | 'group-hover:text-green-700'
  | 'focus-within:text-green-700'
  | 'hover:text-green-700'
  | 'focus:text-green-700'
  | 'sm:text-green-800'
  | 'md:text-green-800'
  | 'lg:text-green-800'
  | 'xl:text-green-800'
  | '2xl:text-green-800'
  | 'group-hover:text-green-800'
  | 'focus-within:text-green-800'
  | 'hover:text-green-800'
  | 'focus:text-green-800'
  | 'sm:text-green-900'
  | 'md:text-green-900'
  | 'lg:text-green-900'
  | 'xl:text-green-900'
  | '2xl:text-green-900'
  | 'group-hover:text-green-900'
  | 'focus-within:text-green-900'
  | 'hover:text-green-900'
  | 'focus:text-green-900'
  | 'sm:text-blue-50'
  | 'md:text-blue-50'
  | 'lg:text-blue-50'
  | 'xl:text-blue-50'
  | '2xl:text-blue-50'
  | 'group-hover:text-blue-50'
  | 'focus-within:text-blue-50'
  | 'hover:text-blue-50'
  | 'focus:text-blue-50'
  | 'sm:text-blue-100'
  | 'md:text-blue-100'
  | 'lg:text-blue-100'
  | 'xl:text-blue-100'
  | '2xl:text-blue-100'
  | 'group-hover:text-blue-100'
  | 'focus-within:text-blue-100'
  | 'hover:text-blue-100'
  | 'focus:text-blue-100'
  | 'sm:text-blue-200'
  | 'md:text-blue-200'
  | 'lg:text-blue-200'
  | 'xl:text-blue-200'
  | '2xl:text-blue-200'
  | 'group-hover:text-blue-200'
  | 'focus-within:text-blue-200'
  | 'hover:text-blue-200'
  | 'focus:text-blue-200'
  | 'sm:text-blue-300'
  | 'md:text-blue-300'
  | 'lg:text-blue-300'
  | 'xl:text-blue-300'
  | '2xl:text-blue-300'
  | 'group-hover:text-blue-300'
  | 'focus-within:text-blue-300'
  | 'hover:text-blue-300'
  | 'focus:text-blue-300'
  | 'sm:text-blue-400'
  | 'md:text-blue-400'
  | 'lg:text-blue-400'
  | 'xl:text-blue-400'
  | '2xl:text-blue-400'
  | 'group-hover:text-blue-400'
  | 'focus-within:text-blue-400'
  | 'hover:text-blue-400'
  | 'focus:text-blue-400'
  | 'sm:text-blue-500'
  | 'md:text-blue-500'
  | 'lg:text-blue-500'
  | 'xl:text-blue-500'
  | '2xl:text-blue-500'
  | 'group-hover:text-blue-500'
  | 'focus-within:text-blue-500'
  | 'hover:text-blue-500'
  | 'focus:text-blue-500'
  | 'sm:text-blue-600'
  | 'md:text-blue-600'
  | 'lg:text-blue-600'
  | 'xl:text-blue-600'
  | '2xl:text-blue-600'
  | 'group-hover:text-blue-600'
  | 'focus-within:text-blue-600'
  | 'hover:text-blue-600'
  | 'focus:text-blue-600'
  | 'sm:text-blue-700'
  | 'md:text-blue-700'
  | 'lg:text-blue-700'
  | 'xl:text-blue-700'
  | '2xl:text-blue-700'
  | 'group-hover:text-blue-700'
  | 'focus-within:text-blue-700'
  | 'hover:text-blue-700'
  | 'focus:text-blue-700'
  | 'sm:text-blue-800'
  | 'md:text-blue-800'
  | 'lg:text-blue-800'
  | 'xl:text-blue-800'
  | '2xl:text-blue-800'
  | 'group-hover:text-blue-800'
  | 'focus-within:text-blue-800'
  | 'hover:text-blue-800'
  | 'focus:text-blue-800'
  | 'sm:text-blue-900'
  | 'md:text-blue-900'
  | 'lg:text-blue-900'
  | 'xl:text-blue-900'
  | '2xl:text-blue-900'
  | 'group-hover:text-blue-900'
  | 'focus-within:text-blue-900'
  | 'hover:text-blue-900'
  | 'focus:text-blue-900'
  | 'sm:text-indigo-50'
  | 'md:text-indigo-50'
  | 'lg:text-indigo-50'
  | 'xl:text-indigo-50'
  | '2xl:text-indigo-50'
  | 'group-hover:text-indigo-50'
  | 'focus-within:text-indigo-50'
  | 'hover:text-indigo-50'
  | 'focus:text-indigo-50'
  | 'sm:text-indigo-100'
  | 'md:text-indigo-100'
  | 'lg:text-indigo-100'
  | 'xl:text-indigo-100'
  | '2xl:text-indigo-100'
  | 'group-hover:text-indigo-100'
  | 'focus-within:text-indigo-100'
  | 'hover:text-indigo-100'
  | 'focus:text-indigo-100'
  | 'sm:text-indigo-200'
  | 'md:text-indigo-200'
  | 'lg:text-indigo-200'
  | 'xl:text-indigo-200'
  | '2xl:text-indigo-200'
  | 'group-hover:text-indigo-200'
  | 'focus-within:text-indigo-200'
  | 'hover:text-indigo-200'
  | 'focus:text-indigo-200'
  | 'sm:text-indigo-300'
  | 'md:text-indigo-300'
  | 'lg:text-indigo-300'
  | 'xl:text-indigo-300'
  | '2xl:text-indigo-300'
  | 'group-hover:text-indigo-300'
  | 'focus-within:text-indigo-300'
  | 'hover:text-indigo-300'
  | 'focus:text-indigo-300'
  | 'sm:text-indigo-400'
  | 'md:text-indigo-400'
  | 'lg:text-indigo-400'
  | 'xl:text-indigo-400'
  | '2xl:text-indigo-400'
  | 'group-hover:text-indigo-400'
  | 'focus-within:text-indigo-400'
  | 'hover:text-indigo-400'
  | 'focus:text-indigo-400'
  | 'sm:text-indigo-500'
  | 'md:text-indigo-500'
  | 'lg:text-indigo-500'
  | 'xl:text-indigo-500'
  | '2xl:text-indigo-500'
  | 'group-hover:text-indigo-500'
  | 'focus-within:text-indigo-500'
  | 'hover:text-indigo-500'
  | 'focus:text-indigo-500'
  | 'sm:text-indigo-600'
  | 'md:text-indigo-600'
  | 'lg:text-indigo-600'
  | 'xl:text-indigo-600'
  | '2xl:text-indigo-600'
  | 'group-hover:text-indigo-600'
  | 'focus-within:text-indigo-600'
  | 'hover:text-indigo-600'
  | 'focus:text-indigo-600'
  | 'sm:text-indigo-700'
  | 'md:text-indigo-700'
  | 'lg:text-indigo-700'
  | 'xl:text-indigo-700'
  | '2xl:text-indigo-700'
  | 'group-hover:text-indigo-700'
  | 'focus-within:text-indigo-700'
  | 'hover:text-indigo-700'
  | 'focus:text-indigo-700'
  | 'sm:text-indigo-800'
  | 'md:text-indigo-800'
  | 'lg:text-indigo-800'
  | 'xl:text-indigo-800'
  | '2xl:text-indigo-800'
  | 'group-hover:text-indigo-800'
  | 'focus-within:text-indigo-800'
  | 'hover:text-indigo-800'
  | 'focus:text-indigo-800'
  | 'sm:text-indigo-900'
  | 'md:text-indigo-900'
  | 'lg:text-indigo-900'
  | 'xl:text-indigo-900'
  | '2xl:text-indigo-900'
  | 'group-hover:text-indigo-900'
  | 'focus-within:text-indigo-900'
  | 'hover:text-indigo-900'
  | 'focus:text-indigo-900'
  | 'sm:text-purple-50'
  | 'md:text-purple-50'
  | 'lg:text-purple-50'
  | 'xl:text-purple-50'
  | '2xl:text-purple-50'
  | 'group-hover:text-purple-50'
  | 'focus-within:text-purple-50'
  | 'hover:text-purple-50'
  | 'focus:text-purple-50'
  | 'sm:text-purple-100'
  | 'md:text-purple-100'
  | 'lg:text-purple-100'
  | 'xl:text-purple-100'
  | '2xl:text-purple-100'
  | 'group-hover:text-purple-100'
  | 'focus-within:text-purple-100'
  | 'hover:text-purple-100'
  | 'focus:text-purple-100'
  | 'sm:text-purple-200'
  | 'md:text-purple-200'
  | 'lg:text-purple-200'
  | 'xl:text-purple-200'
  | '2xl:text-purple-200'
  | 'group-hover:text-purple-200'
  | 'focus-within:text-purple-200'
  | 'hover:text-purple-200'
  | 'focus:text-purple-200'
  | 'sm:text-purple-300'
  | 'md:text-purple-300'
  | 'lg:text-purple-300'
  | 'xl:text-purple-300'
  | '2xl:text-purple-300'
  | 'group-hover:text-purple-300'
  | 'focus-within:text-purple-300'
  | 'hover:text-purple-300'
  | 'focus:text-purple-300'
  | 'sm:text-purple-400'
  | 'md:text-purple-400'
  | 'lg:text-purple-400'
  | 'xl:text-purple-400'
  | '2xl:text-purple-400'
  | 'group-hover:text-purple-400'
  | 'focus-within:text-purple-400'
  | 'hover:text-purple-400'
  | 'focus:text-purple-400'
  | 'sm:text-purple-500'
  | 'md:text-purple-500'
  | 'lg:text-purple-500'
  | 'xl:text-purple-500'
  | '2xl:text-purple-500'
  | 'group-hover:text-purple-500'
  | 'focus-within:text-purple-500'
  | 'hover:text-purple-500'
  | 'focus:text-purple-500'
  | 'sm:text-purple-600'
  | 'md:text-purple-600'
  | 'lg:text-purple-600'
  | 'xl:text-purple-600'
  | '2xl:text-purple-600'
  | 'group-hover:text-purple-600'
  | 'focus-within:text-purple-600'
  | 'hover:text-purple-600'
  | 'focus:text-purple-600'
  | 'sm:text-purple-700'
  | 'md:text-purple-700'
  | 'lg:text-purple-700'
  | 'xl:text-purple-700'
  | '2xl:text-purple-700'
  | 'group-hover:text-purple-700'
  | 'focus-within:text-purple-700'
  | 'hover:text-purple-700'
  | 'focus:text-purple-700'
  | 'sm:text-purple-800'
  | 'md:text-purple-800'
  | 'lg:text-purple-800'
  | 'xl:text-purple-800'
  | '2xl:text-purple-800'
  | 'group-hover:text-purple-800'
  | 'focus-within:text-purple-800'
  | 'hover:text-purple-800'
  | 'focus:text-purple-800'
  | 'sm:text-purple-900'
  | 'md:text-purple-900'
  | 'lg:text-purple-900'
  | 'xl:text-purple-900'
  | '2xl:text-purple-900'
  | 'group-hover:text-purple-900'
  | 'focus-within:text-purple-900'
  | 'hover:text-purple-900'
  | 'focus:text-purple-900'
  | 'sm:text-pink-50'
  | 'md:text-pink-50'
  | 'lg:text-pink-50'
  | 'xl:text-pink-50'
  | '2xl:text-pink-50'
  | 'group-hover:text-pink-50'
  | 'focus-within:text-pink-50'
  | 'hover:text-pink-50'
  | 'focus:text-pink-50'
  | 'sm:text-pink-100'
  | 'md:text-pink-100'
  | 'lg:text-pink-100'
  | 'xl:text-pink-100'
  | '2xl:text-pink-100'
  | 'group-hover:text-pink-100'
  | 'focus-within:text-pink-100'
  | 'hover:text-pink-100'
  | 'focus:text-pink-100'
  | 'sm:text-pink-200'
  | 'md:text-pink-200'
  | 'lg:text-pink-200'
  | 'xl:text-pink-200'
  | '2xl:text-pink-200'
  | 'group-hover:text-pink-200'
  | 'focus-within:text-pink-200'
  | 'hover:text-pink-200'
  | 'focus:text-pink-200'
  | 'sm:text-pink-300'
  | 'md:text-pink-300'
  | 'lg:text-pink-300'
  | 'xl:text-pink-300'
  | '2xl:text-pink-300'
  | 'group-hover:text-pink-300'
  | 'focus-within:text-pink-300'
  | 'hover:text-pink-300'
  | 'focus:text-pink-300'
  | 'sm:text-pink-400'
  | 'md:text-pink-400'
  | 'lg:text-pink-400'
  | 'xl:text-pink-400'
  | '2xl:text-pink-400'
  | 'group-hover:text-pink-400'
  | 'focus-within:text-pink-400'
  | 'hover:text-pink-400'
  | 'focus:text-pink-400'
  | 'sm:text-pink-500'
  | 'md:text-pink-500'
  | 'lg:text-pink-500'
  | 'xl:text-pink-500'
  | '2xl:text-pink-500'
  | 'group-hover:text-pink-500'
  | 'focus-within:text-pink-500'
  | 'hover:text-pink-500'
  | 'focus:text-pink-500'
  | 'sm:text-pink-600'
  | 'md:text-pink-600'
  | 'lg:text-pink-600'
  | 'xl:text-pink-600'
  | '2xl:text-pink-600'
  | 'group-hover:text-pink-600'
  | 'focus-within:text-pink-600'
  | 'hover:text-pink-600'
  | 'focus:text-pink-600'
  | 'sm:text-pink-700'
  | 'md:text-pink-700'
  | 'lg:text-pink-700'
  | 'xl:text-pink-700'
  | '2xl:text-pink-700'
  | 'group-hover:text-pink-700'
  | 'focus-within:text-pink-700'
  | 'hover:text-pink-700'
  | 'focus:text-pink-700'
  | 'sm:text-pink-800'
  | 'md:text-pink-800'
  | 'lg:text-pink-800'
  | 'xl:text-pink-800'
  | '2xl:text-pink-800'
  | 'group-hover:text-pink-800'
  | 'focus-within:text-pink-800'
  | 'hover:text-pink-800'
  | 'focus:text-pink-800'
  | 'sm:text-pink-900'
  | 'md:text-pink-900'
  | 'lg:text-pink-900'
  | 'xl:text-pink-900'
  | '2xl:text-pink-900'
  | 'group-hover:text-pink-900'
  | 'focus-within:text-pink-900'
  | 'hover:text-pink-900'
  | 'focus:text-pink-900'
  | 'sm:underline'
  | 'md:underline'
  | 'lg:underline'
  | 'xl:underline'
  | '2xl:underline'
  | 'group-hover:underline'
  | 'focus-within:underline'
  | 'hover:underline'
  | 'focus:underline'
  | 'sm:line-through'
  | 'md:line-through'
  | 'lg:line-through'
  | 'xl:line-through'
  | '2xl:line-through'
  | 'group-hover:line-through'
  | 'focus-within:line-through'
  | 'hover:line-through'
  | 'focus:line-through'
  | 'sm:no-underline'
  | 'md:no-underline'
  | 'lg:no-underline'
  | 'xl:no-underline'
  | '2xl:no-underline'
  | 'group-hover:no-underline'
  | 'focus-within:no-underline'
  | 'hover:no-underline'
  | 'focus:no-underline'
  | 'sm:text-opacity-0'
  | 'md:text-opacity-0'
  | 'lg:text-opacity-0'
  | 'xl:text-opacity-0'
  | '2xl:text-opacity-0'
  | 'group-hover:text-opacity-0'
  | 'focus-within:text-opacity-0'
  | 'hover:text-opacity-0'
  | 'focus:text-opacity-0'
  | 'sm:text-opacity-5'
  | 'md:text-opacity-5'
  | 'lg:text-opacity-5'
  | 'xl:text-opacity-5'
  | '2xl:text-opacity-5'
  | 'group-hover:text-opacity-5'
  | 'focus-within:text-opacity-5'
  | 'hover:text-opacity-5'
  | 'focus:text-opacity-5'
  | 'sm:text-opacity-10'
  | 'md:text-opacity-10'
  | 'lg:text-opacity-10'
  | 'xl:text-opacity-10'
  | '2xl:text-opacity-10'
  | 'group-hover:text-opacity-10'
  | 'focus-within:text-opacity-10'
  | 'hover:text-opacity-10'
  | 'focus:text-opacity-10'
  | 'sm:text-opacity-20'
  | 'md:text-opacity-20'
  | 'lg:text-opacity-20'
  | 'xl:text-opacity-20'
  | '2xl:text-opacity-20'
  | 'group-hover:text-opacity-20'
  | 'focus-within:text-opacity-20'
  | 'hover:text-opacity-20'
  | 'focus:text-opacity-20'
  | 'sm:text-opacity-25'
  | 'md:text-opacity-25'
  | 'lg:text-opacity-25'
  | 'xl:text-opacity-25'
  | '2xl:text-opacity-25'
  | 'group-hover:text-opacity-25'
  | 'focus-within:text-opacity-25'
  | 'hover:text-opacity-25'
  | 'focus:text-opacity-25'
  | 'sm:text-opacity-30'
  | 'md:text-opacity-30'
  | 'lg:text-opacity-30'
  | 'xl:text-opacity-30'
  | '2xl:text-opacity-30'
  | 'group-hover:text-opacity-30'
  | 'focus-within:text-opacity-30'
  | 'hover:text-opacity-30'
  | 'focus:text-opacity-30'
  | 'sm:text-opacity-40'
  | 'md:text-opacity-40'
  | 'lg:text-opacity-40'
  | 'xl:text-opacity-40'
  | '2xl:text-opacity-40'
  | 'group-hover:text-opacity-40'
  | 'focus-within:text-opacity-40'
  | 'hover:text-opacity-40'
  | 'focus:text-opacity-40'
  | 'sm:text-opacity-50'
  | 'md:text-opacity-50'
  | 'lg:text-opacity-50'
  | 'xl:text-opacity-50'
  | '2xl:text-opacity-50'
  | 'group-hover:text-opacity-50'
  | 'focus-within:text-opacity-50'
  | 'hover:text-opacity-50'
  | 'focus:text-opacity-50'
  | 'sm:text-opacity-60'
  | 'md:text-opacity-60'
  | 'lg:text-opacity-60'
  | 'xl:text-opacity-60'
  | '2xl:text-opacity-60'
  | 'group-hover:text-opacity-60'
  | 'focus-within:text-opacity-60'
  | 'hover:text-opacity-60'
  | 'focus:text-opacity-60'
  | 'sm:text-opacity-70'
  | 'md:text-opacity-70'
  | 'lg:text-opacity-70'
  | 'xl:text-opacity-70'
  | '2xl:text-opacity-70'
  | 'group-hover:text-opacity-70'
  | 'focus-within:text-opacity-70'
  | 'hover:text-opacity-70'
  | 'focus:text-opacity-70'
  | 'sm:text-opacity-75'
  | 'md:text-opacity-75'
  | 'lg:text-opacity-75'
  | 'xl:text-opacity-75'
  | '2xl:text-opacity-75'
  | 'group-hover:text-opacity-75'
  | 'focus-within:text-opacity-75'
  | 'hover:text-opacity-75'
  | 'focus:text-opacity-75'
  | 'sm:text-opacity-80'
  | 'md:text-opacity-80'
  | 'lg:text-opacity-80'
  | 'xl:text-opacity-80'
  | '2xl:text-opacity-80'
  | 'group-hover:text-opacity-80'
  | 'focus-within:text-opacity-80'
  | 'hover:text-opacity-80'
  | 'focus:text-opacity-80'
  | 'sm:text-opacity-90'
  | 'md:text-opacity-90'
  | 'lg:text-opacity-90'
  | 'xl:text-opacity-90'
  | '2xl:text-opacity-90'
  | 'group-hover:text-opacity-90'
  | 'focus-within:text-opacity-90'
  | 'hover:text-opacity-90'
  | 'focus:text-opacity-90'
  | 'sm:text-opacity-95'
  | 'md:text-opacity-95'
  | 'lg:text-opacity-95'
  | 'xl:text-opacity-95'
  | '2xl:text-opacity-95'
  | 'group-hover:text-opacity-95'
  | 'focus-within:text-opacity-95'
  | 'hover:text-opacity-95'
  | 'focus:text-opacity-95'
  | 'sm:text-opacity-100'
  | 'md:text-opacity-100'
  | 'lg:text-opacity-100'
  | 'xl:text-opacity-100'
  | '2xl:text-opacity-100'
  | 'group-hover:text-opacity-100'
  | 'focus-within:text-opacity-100'
  | 'hover:text-opacity-100'
  | 'focus:text-opacity-100'
  | 'sm:truncate'
  | 'md:truncate'
  | 'lg:truncate'
  | 'xl:truncate'
  | '2xl:truncate'
  | 'sm:overflow-ellipsis'
  | 'md:overflow-ellipsis'
  | 'lg:overflow-ellipsis'
  | 'xl:overflow-ellipsis'
  | '2xl:overflow-ellipsis'
  | 'sm:overflow-clip'
  | 'md:overflow-clip'
  | 'lg:overflow-clip'
  | 'xl:overflow-clip'
  | '2xl:overflow-clip'
  | 'sm:uppercase'
  | 'md:uppercase'
  | 'lg:uppercase'
  | 'xl:uppercase'
  | '2xl:uppercase'
  | 'sm:lowercase'
  | 'md:lowercase'
  | 'lg:lowercase'
  | 'xl:lowercase'
  | '2xl:lowercase'
  | 'sm:capitalize'
  | 'md:capitalize'
  | 'lg:capitalize'
  | 'xl:capitalize'
  | '2xl:capitalize'
  | 'sm:normal-case'
  | 'md:normal-case'
  | 'lg:normal-case'
  | 'xl:normal-case'
  | '2xl:normal-case'
  | 'sm:origin-center'
  | 'md:origin-center'
  | 'lg:origin-center'
  | 'xl:origin-center'
  | '2xl:origin-center'
  | 'sm:origin-top'
  | 'md:origin-top'
  | 'lg:origin-top'
  | 'xl:origin-top'
  | '2xl:origin-top'
  | 'sm:origin-top-right'
  | 'md:origin-top-right'
  | 'lg:origin-top-right'
  | 'xl:origin-top-right'
  | '2xl:origin-top-right'
  | 'sm:origin-right'
  | 'md:origin-right'
  | 'lg:origin-right'
  | 'xl:origin-right'
  | '2xl:origin-right'
  | 'sm:origin-bottom-right'
  | 'md:origin-bottom-right'
  | 'lg:origin-bottom-right'
  | 'xl:origin-bottom-right'
  | '2xl:origin-bottom-right'
  | 'sm:origin-bottom'
  | 'md:origin-bottom'
  | 'lg:origin-bottom'
  | 'xl:origin-bottom'
  | '2xl:origin-bottom'
  | 'sm:origin-bottom-left'
  | 'md:origin-bottom-left'
  | 'lg:origin-bottom-left'
  | 'xl:origin-bottom-left'
  | '2xl:origin-bottom-left'
  | 'sm:origin-left'
  | 'md:origin-left'
  | 'lg:origin-left'
  | 'xl:origin-left'
  | '2xl:origin-left'
  | 'sm:origin-top-left'
  | 'md:origin-top-left'
  | 'lg:origin-top-left'
  | 'xl:origin-top-left'
  | '2xl:origin-top-left'
  | 'sm:delay-75'
  | 'md:delay-75'
  | 'lg:delay-75'
  | 'xl:delay-75'
  | '2xl:delay-75'
  | 'sm:delay-100'
  | 'md:delay-100'
  | 'lg:delay-100'
  | 'xl:delay-100'
  | '2xl:delay-100'
  | 'sm:delay-150'
  | 'md:delay-150'
  | 'lg:delay-150'
  | 'xl:delay-150'
  | '2xl:delay-150'
  | 'sm:delay-200'
  | 'md:delay-200'
  | 'lg:delay-200'
  | 'xl:delay-200'
  | '2xl:delay-200'
  | 'sm:delay-300'
  | 'md:delay-300'
  | 'lg:delay-300'
  | 'xl:delay-300'
  | '2xl:delay-300'
  | 'sm:delay-500'
  | 'md:delay-500'
  | 'lg:delay-500'
  | 'xl:delay-500'
  | '2xl:delay-500'
  | 'sm:delay-700'
  | 'md:delay-700'
  | 'lg:delay-700'
  | 'xl:delay-700'
  | '2xl:delay-700'
  | 'sm:delay-1000'
  | 'md:delay-1000'
  | 'lg:delay-1000'
  | 'xl:delay-1000'
  | '2xl:delay-1000'
  | 'sm:duration-75'
  | 'md:duration-75'
  | 'lg:duration-75'
  | 'xl:duration-75'
  | '2xl:duration-75'
  | 'sm:duration-100'
  | 'md:duration-100'
  | 'lg:duration-100'
  | 'xl:duration-100'
  | '2xl:duration-100'
  | 'sm:duration-150'
  | 'md:duration-150'
  | 'lg:duration-150'
  | 'xl:duration-150'
  | '2xl:duration-150'
  | 'sm:duration-200'
  | 'md:duration-200'
  | 'lg:duration-200'
  | 'xl:duration-200'
  | '2xl:duration-200'
  | 'sm:duration-300'
  | 'md:duration-300'
  | 'lg:duration-300'
  | 'xl:duration-300'
  | '2xl:duration-300'
  | 'sm:duration-500'
  | 'md:duration-500'
  | 'lg:duration-500'
  | 'xl:duration-500'
  | '2xl:duration-500'
  | 'sm:duration-700'
  | 'md:duration-700'
  | 'lg:duration-700'
  | 'xl:duration-700'
  | '2xl:duration-700'
  | 'sm:duration-1000'
  | 'md:duration-1000'
  | 'lg:duration-1000'
  | 'xl:duration-1000'
  | '2xl:duration-1000'
  | 'sm:duration'
  | 'md:duration'
  | 'lg:duration'
  | 'xl:duration'
  | '2xl:duration'
  | 'sm:transition-none'
  | 'md:transition-none'
  | 'lg:transition-none'
  | 'xl:transition-none'
  | '2xl:transition-none'
  | 'sm:transition-all'
  | 'md:transition-all'
  | 'lg:transition-all'
  | 'xl:transition-all'
  | '2xl:transition-all'
  | 'sm:transition'
  | 'md:transition'
  | 'lg:transition'
  | 'xl:transition'
  | '2xl:transition'
  | 'sm:transition-colors'
  | 'md:transition-colors'
  | 'lg:transition-colors'
  | 'xl:transition-colors'
  | '2xl:transition-colors'
  | 'sm:transition-opacity'
  | 'md:transition-opacity'
  | 'lg:transition-opacity'
  | 'xl:transition-opacity'
  | '2xl:transition-opacity'
  | 'sm:transition-shadow'
  | 'md:transition-shadow'
  | 'lg:transition-shadow'
  | 'xl:transition-shadow'
  | '2xl:transition-shadow'
  | 'sm:transition-transform'
  | 'md:transition-transform'
  | 'lg:transition-transform'
  | 'xl:transition-transform'
  | '2xl:transition-transform'
  | 'sm:ease-linear'
  | 'md:ease-linear'
  | 'lg:ease-linear'
  | 'xl:ease-linear'
  | '2xl:ease-linear'
  | 'sm:ease-in'
  | 'md:ease-in'
  | 'lg:ease-in'
  | 'xl:ease-in'
  | '2xl:ease-in'
  | 'sm:ease-out'
  | 'md:ease-out'
  | 'lg:ease-out'
  | 'xl:ease-out'
  | '2xl:ease-out'
  | 'sm:ease-in-out'
  | 'md:ease-in-out'
  | 'lg:ease-in-out'
  | 'xl:ease-in-out'
  | '2xl:ease-in-out'
  | 'sm:translate-x-0'
  | 'md:translate-x-0'
  | 'lg:translate-x-0'
  | 'xl:translate-x-0'
  | '2xl:translate-x-0'
  | 'hover:translate-x-0'
  | 'focus:translate-x-0'
  | 'sm:translate-x-1'
  | 'md:translate-x-1'
  | 'lg:translate-x-1'
  | 'xl:translate-x-1'
  | '2xl:translate-x-1'
  | 'hover:translate-x-1'
  | 'focus:translate-x-1'
  | 'sm:translate-x-2'
  | 'md:translate-x-2'
  | 'lg:translate-x-2'
  | 'xl:translate-x-2'
  | '2xl:translate-x-2'
  | 'hover:translate-x-2'
  | 'focus:translate-x-2'
  | 'sm:translate-x-3'
  | 'md:translate-x-3'
  | 'lg:translate-x-3'
  | 'xl:translate-x-3'
  | '2xl:translate-x-3'
  | 'hover:translate-x-3'
  | 'focus:translate-x-3'
  | 'sm:translate-x-4'
  | 'md:translate-x-4'
  | 'lg:translate-x-4'
  | 'xl:translate-x-4'
  | '2xl:translate-x-4'
  | 'hover:translate-x-4'
  | 'focus:translate-x-4'
  | 'sm:translate-x-5'
  | 'md:translate-x-5'
  | 'lg:translate-x-5'
  | 'xl:translate-x-5'
  | '2xl:translate-x-5'
  | 'hover:translate-x-5'
  | 'focus:translate-x-5'
  | 'sm:translate-x-6'
  | 'md:translate-x-6'
  | 'lg:translate-x-6'
  | 'xl:translate-x-6'
  | '2xl:translate-x-6'
  | 'hover:translate-x-6'
  | 'focus:translate-x-6'
  | 'sm:translate-x-7'
  | 'md:translate-x-7'
  | 'lg:translate-x-7'
  | 'xl:translate-x-7'
  | '2xl:translate-x-7'
  | 'hover:translate-x-7'
  | 'focus:translate-x-7'
  | 'sm:translate-x-8'
  | 'md:translate-x-8'
  | 'lg:translate-x-8'
  | 'xl:translate-x-8'
  | '2xl:translate-x-8'
  | 'hover:translate-x-8'
  | 'focus:translate-x-8'
  | 'sm:translate-x-9'
  | 'md:translate-x-9'
  | 'lg:translate-x-9'
  | 'xl:translate-x-9'
  | '2xl:translate-x-9'
  | 'hover:translate-x-9'
  | 'focus:translate-x-9'
  | 'sm:translate-x-10'
  | 'md:translate-x-10'
  | 'lg:translate-x-10'
  | 'xl:translate-x-10'
  | '2xl:translate-x-10'
  | 'hover:translate-x-10'
  | 'focus:translate-x-10'
  | 'sm:translate-x-11'
  | 'md:translate-x-11'
  | 'lg:translate-x-11'
  | 'xl:translate-x-11'
  | '2xl:translate-x-11'
  | 'hover:translate-x-11'
  | 'focus:translate-x-11'
  | 'sm:translate-x-12'
  | 'md:translate-x-12'
  | 'lg:translate-x-12'
  | 'xl:translate-x-12'
  | '2xl:translate-x-12'
  | 'hover:translate-x-12'
  | 'focus:translate-x-12'
  | 'sm:translate-x-14'
  | 'md:translate-x-14'
  | 'lg:translate-x-14'
  | 'xl:translate-x-14'
  | '2xl:translate-x-14'
  | 'hover:translate-x-14'
  | 'focus:translate-x-14'
  | 'sm:translate-x-16'
  | 'md:translate-x-16'
  | 'lg:translate-x-16'
  | 'xl:translate-x-16'
  | '2xl:translate-x-16'
  | 'hover:translate-x-16'
  | 'focus:translate-x-16'
  | 'sm:translate-x-20'
  | 'md:translate-x-20'
  | 'lg:translate-x-20'
  | 'xl:translate-x-20'
  | '2xl:translate-x-20'
  | 'hover:translate-x-20'
  | 'focus:translate-x-20'
  | 'sm:translate-x-24'
  | 'md:translate-x-24'
  | 'lg:translate-x-24'
  | 'xl:translate-x-24'
  | '2xl:translate-x-24'
  | 'hover:translate-x-24'
  | 'focus:translate-x-24'
  | 'sm:translate-x-28'
  | 'md:translate-x-28'
  | 'lg:translate-x-28'
  | 'xl:translate-x-28'
  | '2xl:translate-x-28'
  | 'hover:translate-x-28'
  | 'focus:translate-x-28'
  | 'sm:translate-x-32'
  | 'md:translate-x-32'
  | 'lg:translate-x-32'
  | 'xl:translate-x-32'
  | '2xl:translate-x-32'
  | 'hover:translate-x-32'
  | 'focus:translate-x-32'
  | 'sm:translate-x-36'
  | 'md:translate-x-36'
  | 'lg:translate-x-36'
  | 'xl:translate-x-36'
  | '2xl:translate-x-36'
  | 'hover:translate-x-36'
  | 'focus:translate-x-36'
  | 'sm:translate-x-40'
  | 'md:translate-x-40'
  | 'lg:translate-x-40'
  | 'xl:translate-x-40'
  | '2xl:translate-x-40'
  | 'hover:translate-x-40'
  | 'focus:translate-x-40'
  | 'sm:translate-x-44'
  | 'md:translate-x-44'
  | 'lg:translate-x-44'
  | 'xl:translate-x-44'
  | '2xl:translate-x-44'
  | 'hover:translate-x-44'
  | 'focus:translate-x-44'
  | 'sm:translate-x-48'
  | 'md:translate-x-48'
  | 'lg:translate-x-48'
  | 'xl:translate-x-48'
  | '2xl:translate-x-48'
  | 'hover:translate-x-48'
  | 'focus:translate-x-48'
  | 'sm:translate-x-52'
  | 'md:translate-x-52'
  | 'lg:translate-x-52'
  | 'xl:translate-x-52'
  | '2xl:translate-x-52'
  | 'hover:translate-x-52'
  | 'focus:translate-x-52'
  | 'sm:translate-x-56'
  | 'md:translate-x-56'
  | 'lg:translate-x-56'
  | 'xl:translate-x-56'
  | '2xl:translate-x-56'
  | 'hover:translate-x-56'
  | 'focus:translate-x-56'
  | 'sm:translate-x-60'
  | 'md:translate-x-60'
  | 'lg:translate-x-60'
  | 'xl:translate-x-60'
  | '2xl:translate-x-60'
  | 'hover:translate-x-60'
  | 'focus:translate-x-60'
  | 'sm:translate-x-64'
  | 'md:translate-x-64'
  | 'lg:translate-x-64'
  | 'xl:translate-x-64'
  | '2xl:translate-x-64'
  | 'hover:translate-x-64'
  | 'focus:translate-x-64'
  | 'sm:translate-x-72'
  | 'md:translate-x-72'
  | 'lg:translate-x-72'
  | 'xl:translate-x-72'
  | '2xl:translate-x-72'
  | 'hover:translate-x-72'
  | 'focus:translate-x-72'
  | 'sm:translate-x-80'
  | 'md:translate-x-80'
  | 'lg:translate-x-80'
  | 'xl:translate-x-80'
  | '2xl:translate-x-80'
  | 'hover:translate-x-80'
  | 'focus:translate-x-80'
  | 'sm:translate-x-96'
  | 'md:translate-x-96'
  | 'lg:translate-x-96'
  | 'xl:translate-x-96'
  | '2xl:translate-x-96'
  | 'hover:translate-x-96'
  | 'focus:translate-x-96'
  | 'sm:translate-x-px'
  | 'md:translate-x-px'
  | 'lg:translate-x-px'
  | 'xl:translate-x-px'
  | '2xl:translate-x-px'
  | 'hover:translate-x-px'
  | 'focus:translate-x-px'
  | 'sm:translate-x-0.5'
  | 'md:translate-x-0.5'
  | 'lg:translate-x-0.5'
  | 'xl:translate-x-0.5'
  | '2xl:translate-x-0.5'
  | 'hover:translate-x-0.5'
  | 'focus:translate-x-0.5'
  | 'sm:translate-x-1.5'
  | 'md:translate-x-1.5'
  | 'lg:translate-x-1.5'
  | 'xl:translate-x-1.5'
  | '2xl:translate-x-1.5'
  | 'hover:translate-x-1.5'
  | 'focus:translate-x-1.5'
  | 'sm:translate-x-2.5'
  | 'md:translate-x-2.5'
  | 'lg:translate-x-2.5'
  | 'xl:translate-x-2.5'
  | '2xl:translate-x-2.5'
  | 'hover:translate-x-2.5'
  | 'focus:translate-x-2.5'
  | 'sm:translate-x-3.5'
  | 'md:translate-x-3.5'
  | 'lg:translate-x-3.5'
  | 'xl:translate-x-3.5'
  | '2xl:translate-x-3.5'
  | 'hover:translate-x-3.5'
  | 'focus:translate-x-3.5'
  | 'sm:-translate-x-0'
  | 'md:-translate-x-0'
  | 'lg:-translate-x-0'
  | 'xl:-translate-x-0'
  | '2xl:-translate-x-0'
  | 'hover:-translate-x-0'
  | 'focus:-translate-x-0'
  | 'sm:-translate-x-1'
  | 'md:-translate-x-1'
  | 'lg:-translate-x-1'
  | 'xl:-translate-x-1'
  | '2xl:-translate-x-1'
  | 'hover:-translate-x-1'
  | 'focus:-translate-x-1'
  | 'sm:-translate-x-2'
  | 'md:-translate-x-2'
  | 'lg:-translate-x-2'
  | 'xl:-translate-x-2'
  | '2xl:-translate-x-2'
  | 'hover:-translate-x-2'
  | 'focus:-translate-x-2'
  | 'sm:-translate-x-3'
  | 'md:-translate-x-3'
  | 'lg:-translate-x-3'
  | 'xl:-translate-x-3'
  | '2xl:-translate-x-3'
  | 'hover:-translate-x-3'
  | 'focus:-translate-x-3'
  | 'sm:-translate-x-4'
  | 'md:-translate-x-4'
  | 'lg:-translate-x-4'
  | 'xl:-translate-x-4'
  | '2xl:-translate-x-4'
  | 'hover:-translate-x-4'
  | 'focus:-translate-x-4'
  | 'sm:-translate-x-5'
  | 'md:-translate-x-5'
  | 'lg:-translate-x-5'
  | 'xl:-translate-x-5'
  | '2xl:-translate-x-5'
  | 'hover:-translate-x-5'
  | 'focus:-translate-x-5'
  | 'sm:-translate-x-6'
  | 'md:-translate-x-6'
  | 'lg:-translate-x-6'
  | 'xl:-translate-x-6'
  | '2xl:-translate-x-6'
  | 'hover:-translate-x-6'
  | 'focus:-translate-x-6'
  | 'sm:-translate-x-7'
  | 'md:-translate-x-7'
  | 'lg:-translate-x-7'
  | 'xl:-translate-x-7'
  | '2xl:-translate-x-7'
  | 'hover:-translate-x-7'
  | 'focus:-translate-x-7'
  | 'sm:-translate-x-8'
  | 'md:-translate-x-8'
  | 'lg:-translate-x-8'
  | 'xl:-translate-x-8'
  | '2xl:-translate-x-8'
  | 'hover:-translate-x-8'
  | 'focus:-translate-x-8'
  | 'sm:-translate-x-9'
  | 'md:-translate-x-9'
  | 'lg:-translate-x-9'
  | 'xl:-translate-x-9'
  | '2xl:-translate-x-9'
  | 'hover:-translate-x-9'
  | 'focus:-translate-x-9'
  | 'sm:-translate-x-10'
  | 'md:-translate-x-10'
  | 'lg:-translate-x-10'
  | 'xl:-translate-x-10'
  | '2xl:-translate-x-10'
  | 'hover:-translate-x-10'
  | 'focus:-translate-x-10'
  | 'sm:-translate-x-11'
  | 'md:-translate-x-11'
  | 'lg:-translate-x-11'
  | 'xl:-translate-x-11'
  | '2xl:-translate-x-11'
  | 'hover:-translate-x-11'
  | 'focus:-translate-x-11'
  | 'sm:-translate-x-12'
  | 'md:-translate-x-12'
  | 'lg:-translate-x-12'
  | 'xl:-translate-x-12'
  | '2xl:-translate-x-12'
  | 'hover:-translate-x-12'
  | 'focus:-translate-x-12'
  | 'sm:-translate-x-14'
  | 'md:-translate-x-14'
  | 'lg:-translate-x-14'
  | 'xl:-translate-x-14'
  | '2xl:-translate-x-14'
  | 'hover:-translate-x-14'
  | 'focus:-translate-x-14'
  | 'sm:-translate-x-16'
  | 'md:-translate-x-16'
  | 'lg:-translate-x-16'
  | 'xl:-translate-x-16'
  | '2xl:-translate-x-16'
  | 'hover:-translate-x-16'
  | 'focus:-translate-x-16'
  | 'sm:-translate-x-20'
  | 'md:-translate-x-20'
  | 'lg:-translate-x-20'
  | 'xl:-translate-x-20'
  | '2xl:-translate-x-20'
  | 'hover:-translate-x-20'
  | 'focus:-translate-x-20'
  | 'sm:-translate-x-24'
  | 'md:-translate-x-24'
  | 'lg:-translate-x-24'
  | 'xl:-translate-x-24'
  | '2xl:-translate-x-24'
  | 'hover:-translate-x-24'
  | 'focus:-translate-x-24'
  | 'sm:-translate-x-28'
  | 'md:-translate-x-28'
  | 'lg:-translate-x-28'
  | 'xl:-translate-x-28'
  | '2xl:-translate-x-28'
  | 'hover:-translate-x-28'
  | 'focus:-translate-x-28'
  | 'sm:-translate-x-32'
  | 'md:-translate-x-32'
  | 'lg:-translate-x-32'
  | 'xl:-translate-x-32'
  | '2xl:-translate-x-32'
  | 'hover:-translate-x-32'
  | 'focus:-translate-x-32'
  | 'sm:-translate-x-36'
  | 'md:-translate-x-36'
  | 'lg:-translate-x-36'
  | 'xl:-translate-x-36'
  | '2xl:-translate-x-36'
  | 'hover:-translate-x-36'
  | 'focus:-translate-x-36'
  | 'sm:-translate-x-40'
  | 'md:-translate-x-40'
  | 'lg:-translate-x-40'
  | 'xl:-translate-x-40'
  | '2xl:-translate-x-40'
  | 'hover:-translate-x-40'
  | 'focus:-translate-x-40'
  | 'sm:-translate-x-44'
  | 'md:-translate-x-44'
  | 'lg:-translate-x-44'
  | 'xl:-translate-x-44'
  | '2xl:-translate-x-44'
  | 'hover:-translate-x-44'
  | 'focus:-translate-x-44'
  | 'sm:-translate-x-48'
  | 'md:-translate-x-48'
  | 'lg:-translate-x-48'
  | 'xl:-translate-x-48'
  | '2xl:-translate-x-48'
  | 'hover:-translate-x-48'
  | 'focus:-translate-x-48'
  | 'sm:-translate-x-52'
  | 'md:-translate-x-52'
  | 'lg:-translate-x-52'
  | 'xl:-translate-x-52'
  | '2xl:-translate-x-52'
  | 'hover:-translate-x-52'
  | 'focus:-translate-x-52'
  | 'sm:-translate-x-56'
  | 'md:-translate-x-56'
  | 'lg:-translate-x-56'
  | 'xl:-translate-x-56'
  | '2xl:-translate-x-56'
  | 'hover:-translate-x-56'
  | 'focus:-translate-x-56'
  | 'sm:-translate-x-60'
  | 'md:-translate-x-60'
  | 'lg:-translate-x-60'
  | 'xl:-translate-x-60'
  | '2xl:-translate-x-60'
  | 'hover:-translate-x-60'
  | 'focus:-translate-x-60'
  | 'sm:-translate-x-64'
  | 'md:-translate-x-64'
  | 'lg:-translate-x-64'
  | 'xl:-translate-x-64'
  | '2xl:-translate-x-64'
  | 'hover:-translate-x-64'
  | 'focus:-translate-x-64'
  | 'sm:-translate-x-72'
  | 'md:-translate-x-72'
  | 'lg:-translate-x-72'
  | 'xl:-translate-x-72'
  | '2xl:-translate-x-72'
  | 'hover:-translate-x-72'
  | 'focus:-translate-x-72'
  | 'sm:-translate-x-80'
  | 'md:-translate-x-80'
  | 'lg:-translate-x-80'
  | 'xl:-translate-x-80'
  | '2xl:-translate-x-80'
  | 'hover:-translate-x-80'
  | 'focus:-translate-x-80'
  | 'sm:-translate-x-96'
  | 'md:-translate-x-96'
  | 'lg:-translate-x-96'
  | 'xl:-translate-x-96'
  | '2xl:-translate-x-96'
  | 'hover:-translate-x-96'
  | 'focus:-translate-x-96'
  | 'sm:-translate-x-px'
  | 'md:-translate-x-px'
  | 'lg:-translate-x-px'
  | 'xl:-translate-x-px'
  | '2xl:-translate-x-px'
  | 'hover:-translate-x-px'
  | 'focus:-translate-x-px'
  | 'sm:-translate-x-0.5'
  | 'md:-translate-x-0.5'
  | 'lg:-translate-x-0.5'
  | 'xl:-translate-x-0.5'
  | '2xl:-translate-x-0.5'
  | 'hover:-translate-x-0.5'
  | 'focus:-translate-x-0.5'
  | 'sm:-translate-x-1.5'
  | 'md:-translate-x-1.5'
  | 'lg:-translate-x-1.5'
  | 'xl:-translate-x-1.5'
  | '2xl:-translate-x-1.5'
  | 'hover:-translate-x-1.5'
  | 'focus:-translate-x-1.5'
  | 'sm:-translate-x-2.5'
  | 'md:-translate-x-2.5'
  | 'lg:-translate-x-2.5'
  | 'xl:-translate-x-2.5'
  | '2xl:-translate-x-2.5'
  | 'hover:-translate-x-2.5'
  | 'focus:-translate-x-2.5'
  | 'sm:-translate-x-3.5'
  | 'md:-translate-x-3.5'
  | 'lg:-translate-x-3.5'
  | 'xl:-translate-x-3.5'
  | '2xl:-translate-x-3.5'
  | 'hover:-translate-x-3.5'
  | 'focus:-translate-x-3.5'
  | 'sm:translate-x-1/2'
  | 'md:translate-x-1/2'
  | 'lg:translate-x-1/2'
  | 'xl:translate-x-1/2'
  | '2xl:translate-x-1/2'
  | 'hover:translate-x-1/2'
  | 'focus:translate-x-1/2'
  | 'sm:translate-x-1/3'
  | 'md:translate-x-1/3'
  | 'lg:translate-x-1/3'
  | 'xl:translate-x-1/3'
  | '2xl:translate-x-1/3'
  | 'hover:translate-x-1/3'
  | 'focus:translate-x-1/3'
  | 'sm:translate-x-2/3'
  | 'md:translate-x-2/3'
  | 'lg:translate-x-2/3'
  | 'xl:translate-x-2/3'
  | '2xl:translate-x-2/3'
  | 'hover:translate-x-2/3'
  | 'focus:translate-x-2/3'
  | 'sm:translate-x-1/4'
  | 'md:translate-x-1/4'
  | 'lg:translate-x-1/4'
  | 'xl:translate-x-1/4'
  | '2xl:translate-x-1/4'
  | 'hover:translate-x-1/4'
  | 'focus:translate-x-1/4'
  | 'sm:translate-x-2/4'
  | 'md:translate-x-2/4'
  | 'lg:translate-x-2/4'
  | 'xl:translate-x-2/4'
  | '2xl:translate-x-2/4'
  | 'hover:translate-x-2/4'
  | 'focus:translate-x-2/4'
  | 'sm:translate-x-3/4'
  | 'md:translate-x-3/4'
  | 'lg:translate-x-3/4'
  | 'xl:translate-x-3/4'
  | '2xl:translate-x-3/4'
  | 'hover:translate-x-3/4'
  | 'focus:translate-x-3/4'
  | 'sm:translate-x-full'
  | 'md:translate-x-full'
  | 'lg:translate-x-full'
  | 'xl:translate-x-full'
  | '2xl:translate-x-full'
  | 'hover:translate-x-full'
  | 'focus:translate-x-full'
  | 'sm:-translate-x-1/2'
  | 'md:-translate-x-1/2'
  | 'lg:-translate-x-1/2'
  | 'xl:-translate-x-1/2'
  | '2xl:-translate-x-1/2'
  | 'hover:-translate-x-1/2'
  | 'focus:-translate-x-1/2'
  | 'sm:-translate-x-1/3'
  | 'md:-translate-x-1/3'
  | 'lg:-translate-x-1/3'
  | 'xl:-translate-x-1/3'
  | '2xl:-translate-x-1/3'
  | 'hover:-translate-x-1/3'
  | 'focus:-translate-x-1/3'
  | 'sm:-translate-x-2/3'
  | 'md:-translate-x-2/3'
  | 'lg:-translate-x-2/3'
  | 'xl:-translate-x-2/3'
  | '2xl:-translate-x-2/3'
  | 'hover:-translate-x-2/3'
  | 'focus:-translate-x-2/3'
  | 'sm:-translate-x-1/4'
  | 'md:-translate-x-1/4'
  | 'lg:-translate-x-1/4'
  | 'xl:-translate-x-1/4'
  | '2xl:-translate-x-1/4'
  | 'hover:-translate-x-1/4'
  | 'focus:-translate-x-1/4'
  | 'sm:-translate-x-2/4'
  | 'md:-translate-x-2/4'
  | 'lg:-translate-x-2/4'
  | 'xl:-translate-x-2/4'
  | '2xl:-translate-x-2/4'
  | 'hover:-translate-x-2/4'
  | 'focus:-translate-x-2/4'
  | 'sm:-translate-x-3/4'
  | 'md:-translate-x-3/4'
  | 'lg:-translate-x-3/4'
  | 'xl:-translate-x-3/4'
  | '2xl:-translate-x-3/4'
  | 'hover:-translate-x-3/4'
  | 'focus:-translate-x-3/4'
  | 'sm:-translate-x-full'
  | 'md:-translate-x-full'
  | 'lg:-translate-x-full'
  | 'xl:-translate-x-full'
  | '2xl:-translate-x-full'
  | 'hover:-translate-x-full'
  | 'focus:-translate-x-full'
  | 'sm:translate-y-0'
  | 'md:translate-y-0'
  | 'lg:translate-y-0'
  | 'xl:translate-y-0'
  | '2xl:translate-y-0'
  | 'hover:translate-y-0'
  | 'focus:translate-y-0'
  | 'sm:translate-y-1'
  | 'md:translate-y-1'
  | 'lg:translate-y-1'
  | 'xl:translate-y-1'
  | '2xl:translate-y-1'
  | 'hover:translate-y-1'
  | 'focus:translate-y-1'
  | 'sm:translate-y-2'
  | 'md:translate-y-2'
  | 'lg:translate-y-2'
  | 'xl:translate-y-2'
  | '2xl:translate-y-2'
  | 'hover:translate-y-2'
  | 'focus:translate-y-2'
  | 'sm:translate-y-3'
  | 'md:translate-y-3'
  | 'lg:translate-y-3'
  | 'xl:translate-y-3'
  | '2xl:translate-y-3'
  | 'hover:translate-y-3'
  | 'focus:translate-y-3'
  | 'sm:translate-y-4'
  | 'md:translate-y-4'
  | 'lg:translate-y-4'
  | 'xl:translate-y-4'
  | '2xl:translate-y-4'
  | 'hover:translate-y-4'
  | 'focus:translate-y-4'
  | 'sm:translate-y-5'
  | 'md:translate-y-5'
  | 'lg:translate-y-5'
  | 'xl:translate-y-5'
  | '2xl:translate-y-5'
  | 'hover:translate-y-5'
  | 'focus:translate-y-5'
  | 'sm:translate-y-6'
  | 'md:translate-y-6'
  | 'lg:translate-y-6'
  | 'xl:translate-y-6'
  | '2xl:translate-y-6'
  | 'hover:translate-y-6'
  | 'focus:translate-y-6'
  | 'sm:translate-y-7'
  | 'md:translate-y-7'
  | 'lg:translate-y-7'
  | 'xl:translate-y-7'
  | '2xl:translate-y-7'
  | 'hover:translate-y-7'
  | 'focus:translate-y-7'
  | 'sm:translate-y-8'
  | 'md:translate-y-8'
  | 'lg:translate-y-8'
  | 'xl:translate-y-8'
  | '2xl:translate-y-8'
  | 'hover:translate-y-8'
  | 'focus:translate-y-8'
  | 'sm:translate-y-9'
  | 'md:translate-y-9'
  | 'lg:translate-y-9'
  | 'xl:translate-y-9'
  | '2xl:translate-y-9'
  | 'hover:translate-y-9'
  | 'focus:translate-y-9'
  | 'sm:translate-y-10'
  | 'md:translate-y-10'
  | 'lg:translate-y-10'
  | 'xl:translate-y-10'
  | '2xl:translate-y-10'
  | 'hover:translate-y-10'
  | 'focus:translate-y-10'
  | 'sm:translate-y-11'
  | 'md:translate-y-11'
  | 'lg:translate-y-11'
  | 'xl:translate-y-11'
  | '2xl:translate-y-11'
  | 'hover:translate-y-11'
  | 'focus:translate-y-11'
  | 'sm:translate-y-12'
  | 'md:translate-y-12'
  | 'lg:translate-y-12'
  | 'xl:translate-y-12'
  | '2xl:translate-y-12'
  | 'hover:translate-y-12'
  | 'focus:translate-y-12'
  | 'sm:translate-y-14'
  | 'md:translate-y-14'
  | 'lg:translate-y-14'
  | 'xl:translate-y-14'
  | '2xl:translate-y-14'
  | 'hover:translate-y-14'
  | 'focus:translate-y-14'
  | 'sm:translate-y-16'
  | 'md:translate-y-16'
  | 'lg:translate-y-16'
  | 'xl:translate-y-16'
  | '2xl:translate-y-16'
  | 'hover:translate-y-16'
  | 'focus:translate-y-16'
  | 'sm:translate-y-20'
  | 'md:translate-y-20'
  | 'lg:translate-y-20'
  | 'xl:translate-y-20'
  | '2xl:translate-y-20'
  | 'hover:translate-y-20'
  | 'focus:translate-y-20'
  | 'sm:translate-y-24'
  | 'md:translate-y-24'
  | 'lg:translate-y-24'
  | 'xl:translate-y-24'
  | '2xl:translate-y-24'
  | 'hover:translate-y-24'
  | 'focus:translate-y-24'
  | 'sm:translate-y-28'
  | 'md:translate-y-28'
  | 'lg:translate-y-28'
  | 'xl:translate-y-28'
  | '2xl:translate-y-28'
  | 'hover:translate-y-28'
  | 'focus:translate-y-28'
  | 'sm:translate-y-32'
  | 'md:translate-y-32'
  | 'lg:translate-y-32'
  | 'xl:translate-y-32'
  | '2xl:translate-y-32'
  | 'hover:translate-y-32'
  | 'focus:translate-y-32'
  | 'sm:translate-y-36'
  | 'md:translate-y-36'
  | 'lg:translate-y-36'
  | 'xl:translate-y-36'
  | '2xl:translate-y-36'
  | 'hover:translate-y-36'
  | 'focus:translate-y-36'
  | 'sm:translate-y-40'
  | 'md:translate-y-40'
  | 'lg:translate-y-40'
  | 'xl:translate-y-40'
  | '2xl:translate-y-40'
  | 'hover:translate-y-40'
  | 'focus:translate-y-40'
  | 'sm:translate-y-44'
  | 'md:translate-y-44'
  | 'lg:translate-y-44'
  | 'xl:translate-y-44'
  | '2xl:translate-y-44'
  | 'hover:translate-y-44'
  | 'focus:translate-y-44'
  | 'sm:translate-y-48'
  | 'md:translate-y-48'
  | 'lg:translate-y-48'
  | 'xl:translate-y-48'
  | '2xl:translate-y-48'
  | 'hover:translate-y-48'
  | 'focus:translate-y-48'
  | 'sm:translate-y-52'
  | 'md:translate-y-52'
  | 'lg:translate-y-52'
  | 'xl:translate-y-52'
  | '2xl:translate-y-52'
  | 'hover:translate-y-52'
  | 'focus:translate-y-52'
  | 'sm:translate-y-56'
  | 'md:translate-y-56'
  | 'lg:translate-y-56'
  | 'xl:translate-y-56'
  | '2xl:translate-y-56'
  | 'hover:translate-y-56'
  | 'focus:translate-y-56'
  | 'sm:translate-y-60'
  | 'md:translate-y-60'
  | 'lg:translate-y-60'
  | 'xl:translate-y-60'
  | '2xl:translate-y-60'
  | 'hover:translate-y-60'
  | 'focus:translate-y-60'
  | 'sm:translate-y-64'
  | 'md:translate-y-64'
  | 'lg:translate-y-64'
  | 'xl:translate-y-64'
  | '2xl:translate-y-64'
  | 'hover:translate-y-64'
  | 'focus:translate-y-64'
  | 'sm:translate-y-72'
  | 'md:translate-y-72'
  | 'lg:translate-y-72'
  | 'xl:translate-y-72'
  | '2xl:translate-y-72'
  | 'hover:translate-y-72'
  | 'focus:translate-y-72'
  | 'sm:translate-y-80'
  | 'md:translate-y-80'
  | 'lg:translate-y-80'
  | 'xl:translate-y-80'
  | '2xl:translate-y-80'
  | 'hover:translate-y-80'
  | 'focus:translate-y-80'
  | 'sm:translate-y-96'
  | 'md:translate-y-96'
  | 'lg:translate-y-96'
  | 'xl:translate-y-96'
  | '2xl:translate-y-96'
  | 'hover:translate-y-96'
  | 'focus:translate-y-96'
  | 'sm:translate-y-px'
  | 'md:translate-y-px'
  | 'lg:translate-y-px'
  | 'xl:translate-y-px'
  | '2xl:translate-y-px'
  | 'hover:translate-y-px'
  | 'focus:translate-y-px'
  | 'sm:translate-y-0.5'
  | 'md:translate-y-0.5'
  | 'lg:translate-y-0.5'
  | 'xl:translate-y-0.5'
  | '2xl:translate-y-0.5'
  | 'hover:translate-y-0.5'
  | 'focus:translate-y-0.5'
  | 'sm:translate-y-1.5'
  | 'md:translate-y-1.5'
  | 'lg:translate-y-1.5'
  | 'xl:translate-y-1.5'
  | '2xl:translate-y-1.5'
  | 'hover:translate-y-1.5'
  | 'focus:translate-y-1.5'
  | 'sm:translate-y-2.5'
  | 'md:translate-y-2.5'
  | 'lg:translate-y-2.5'
  | 'xl:translate-y-2.5'
  | '2xl:translate-y-2.5'
  | 'hover:translate-y-2.5'
  | 'focus:translate-y-2.5'
  | 'sm:translate-y-3.5'
  | 'md:translate-y-3.5'
  | 'lg:translate-y-3.5'
  | 'xl:translate-y-3.5'
  | '2xl:translate-y-3.5'
  | 'hover:translate-y-3.5'
  | 'focus:translate-y-3.5'
  | 'sm:-translate-y-0'
  | 'md:-translate-y-0'
  | 'lg:-translate-y-0'
  | 'xl:-translate-y-0'
  | '2xl:-translate-y-0'
  | 'hover:-translate-y-0'
  | 'focus:-translate-y-0'
  | 'sm:-translate-y-1'
  | 'md:-translate-y-1'
  | 'lg:-translate-y-1'
  | 'xl:-translate-y-1'
  | '2xl:-translate-y-1'
  | 'hover:-translate-y-1'
  | 'focus:-translate-y-1'
  | 'sm:-translate-y-2'
  | 'md:-translate-y-2'
  | 'lg:-translate-y-2'
  | 'xl:-translate-y-2'
  | '2xl:-translate-y-2'
  | 'hover:-translate-y-2'
  | 'focus:-translate-y-2'
  | 'sm:-translate-y-3'
  | 'md:-translate-y-3'
  | 'lg:-translate-y-3'
  | 'xl:-translate-y-3'
  | '2xl:-translate-y-3'
  | 'hover:-translate-y-3'
  | 'focus:-translate-y-3'
  | 'sm:-translate-y-4'
  | 'md:-translate-y-4'
  | 'lg:-translate-y-4'
  | 'xl:-translate-y-4'
  | '2xl:-translate-y-4'
  | 'hover:-translate-y-4'
  | 'focus:-translate-y-4'
  | 'sm:-translate-y-5'
  | 'md:-translate-y-5'
  | 'lg:-translate-y-5'
  | 'xl:-translate-y-5'
  | '2xl:-translate-y-5'
  | 'hover:-translate-y-5'
  | 'focus:-translate-y-5'
  | 'sm:-translate-y-6'
  | 'md:-translate-y-6'
  | 'lg:-translate-y-6'
  | 'xl:-translate-y-6'
  | '2xl:-translate-y-6'
  | 'hover:-translate-y-6'
  | 'focus:-translate-y-6'
  | 'sm:-translate-y-7'
  | 'md:-translate-y-7'
  | 'lg:-translate-y-7'
  | 'xl:-translate-y-7'
  | '2xl:-translate-y-7'
  | 'hover:-translate-y-7'
  | 'focus:-translate-y-7'
  | 'sm:-translate-y-8'
  | 'md:-translate-y-8'
  | 'lg:-translate-y-8'
  | 'xl:-translate-y-8'
  | '2xl:-translate-y-8'
  | 'hover:-translate-y-8'
  | 'focus:-translate-y-8'
  | 'sm:-translate-y-9'
  | 'md:-translate-y-9'
  | 'lg:-translate-y-9'
  | 'xl:-translate-y-9'
  | '2xl:-translate-y-9'
  | 'hover:-translate-y-9'
  | 'focus:-translate-y-9'
  | 'sm:-translate-y-10'
  | 'md:-translate-y-10'
  | 'lg:-translate-y-10'
  | 'xl:-translate-y-10'
  | '2xl:-translate-y-10'
  | 'hover:-translate-y-10'
  | 'focus:-translate-y-10'
  | 'sm:-translate-y-11'
  | 'md:-translate-y-11'
  | 'lg:-translate-y-11'
  | 'xl:-translate-y-11'
  | '2xl:-translate-y-11'
  | 'hover:-translate-y-11'
  | 'focus:-translate-y-11'
  | 'sm:-translate-y-12'
  | 'md:-translate-y-12'
  | 'lg:-translate-y-12'
  | 'xl:-translate-y-12'
  | '2xl:-translate-y-12'
  | 'hover:-translate-y-12'
  | 'focus:-translate-y-12'
  | 'sm:-translate-y-14'
  | 'md:-translate-y-14'
  | 'lg:-translate-y-14'
  | 'xl:-translate-y-14'
  | '2xl:-translate-y-14'
  | 'hover:-translate-y-14'
  | 'focus:-translate-y-14'
  | 'sm:-translate-y-16'
  | 'md:-translate-y-16'
  | 'lg:-translate-y-16'
  | 'xl:-translate-y-16'
  | '2xl:-translate-y-16'
  | 'hover:-translate-y-16'
  | 'focus:-translate-y-16'
  | 'sm:-translate-y-20'
  | 'md:-translate-y-20'
  | 'lg:-translate-y-20'
  | 'xl:-translate-y-20'
  | '2xl:-translate-y-20'
  | 'hover:-translate-y-20'
  | 'focus:-translate-y-20'
  | 'sm:-translate-y-24'
  | 'md:-translate-y-24'
  | 'lg:-translate-y-24'
  | 'xl:-translate-y-24'
  | '2xl:-translate-y-24'
  | 'hover:-translate-y-24'
  | 'focus:-translate-y-24'
  | 'sm:-translate-y-28'
  | 'md:-translate-y-28'
  | 'lg:-translate-y-28'
  | 'xl:-translate-y-28'
  | '2xl:-translate-y-28'
  | 'hover:-translate-y-28'
  | 'focus:-translate-y-28'
  | 'sm:-translate-y-32'
  | 'md:-translate-y-32'
  | 'lg:-translate-y-32'
  | 'xl:-translate-y-32'
  | '2xl:-translate-y-32'
  | 'hover:-translate-y-32'
  | 'focus:-translate-y-32'
  | 'sm:-translate-y-36'
  | 'md:-translate-y-36'
  | 'lg:-translate-y-36'
  | 'xl:-translate-y-36'
  | '2xl:-translate-y-36'
  | 'hover:-translate-y-36'
  | 'focus:-translate-y-36'
  | 'sm:-translate-y-40'
  | 'md:-translate-y-40'
  | 'lg:-translate-y-40'
  | 'xl:-translate-y-40'
  | '2xl:-translate-y-40'
  | 'hover:-translate-y-40'
  | 'focus:-translate-y-40'
  | 'sm:-translate-y-44'
  | 'md:-translate-y-44'
  | 'lg:-translate-y-44'
  | 'xl:-translate-y-44'
  | '2xl:-translate-y-44'
  | 'hover:-translate-y-44'
  | 'focus:-translate-y-44'
  | 'sm:-translate-y-48'
  | 'md:-translate-y-48'
  | 'lg:-translate-y-48'
  | 'xl:-translate-y-48'
  | '2xl:-translate-y-48'
  | 'hover:-translate-y-48'
  | 'focus:-translate-y-48'
  | 'sm:-translate-y-52'
  | 'md:-translate-y-52'
  | 'lg:-translate-y-52'
  | 'xl:-translate-y-52'
  | '2xl:-translate-y-52'
  | 'hover:-translate-y-52'
  | 'focus:-translate-y-52'
  | 'sm:-translate-y-56'
  | 'md:-translate-y-56'
  | 'lg:-translate-y-56'
  | 'xl:-translate-y-56'
  | '2xl:-translate-y-56'
  | 'hover:-translate-y-56'
  | 'focus:-translate-y-56'
  | 'sm:-translate-y-60'
  | 'md:-translate-y-60'
  | 'lg:-translate-y-60'
  | 'xl:-translate-y-60'
  | '2xl:-translate-y-60'
  | 'hover:-translate-y-60'
  | 'focus:-translate-y-60'
  | 'sm:-translate-y-64'
  | 'md:-translate-y-64'
  | 'lg:-translate-y-64'
  | 'xl:-translate-y-64'
  | '2xl:-translate-y-64'
  | 'hover:-translate-y-64'
  | 'focus:-translate-y-64'
  | 'sm:-translate-y-72'
  | 'md:-translate-y-72'
  | 'lg:-translate-y-72'
  | 'xl:-translate-y-72'
  | '2xl:-translate-y-72'
  | 'hover:-translate-y-72'
  | 'focus:-translate-y-72'
  | 'sm:-translate-y-80'
  | 'md:-translate-y-80'
  | 'lg:-translate-y-80'
  | 'xl:-translate-y-80'
  | '2xl:-translate-y-80'
  | 'hover:-translate-y-80'
  | 'focus:-translate-y-80'
  | 'sm:-translate-y-96'
  | 'md:-translate-y-96'
  | 'lg:-translate-y-96'
  | 'xl:-translate-y-96'
  | '2xl:-translate-y-96'
  | 'hover:-translate-y-96'
  | 'focus:-translate-y-96'
  | 'sm:-translate-y-px'
  | 'md:-translate-y-px'
  | 'lg:-translate-y-px'
  | 'xl:-translate-y-px'
  | '2xl:-translate-y-px'
  | 'hover:-translate-y-px'
  | 'focus:-translate-y-px'
  | 'sm:-translate-y-0.5'
  | 'md:-translate-y-0.5'
  | 'lg:-translate-y-0.5'
  | 'xl:-translate-y-0.5'
  | '2xl:-translate-y-0.5'
  | 'hover:-translate-y-0.5'
  | 'focus:-translate-y-0.5'
  | 'sm:-translate-y-1.5'
  | 'md:-translate-y-1.5'
  | 'lg:-translate-y-1.5'
  | 'xl:-translate-y-1.5'
  | '2xl:-translate-y-1.5'
  | 'hover:-translate-y-1.5'
  | 'focus:-translate-y-1.5'
  | 'sm:-translate-y-2.5'
  | 'md:-translate-y-2.5'
  | 'lg:-translate-y-2.5'
  | 'xl:-translate-y-2.5'
  | '2xl:-translate-y-2.5'
  | 'hover:-translate-y-2.5'
  | 'focus:-translate-y-2.5'
  | 'sm:-translate-y-3.5'
  | 'md:-translate-y-3.5'
  | 'lg:-translate-y-3.5'
  | 'xl:-translate-y-3.5'
  | '2xl:-translate-y-3.5'
  | 'hover:-translate-y-3.5'
  | 'focus:-translate-y-3.5'
  | 'sm:translate-y-1/2'
  | 'md:translate-y-1/2'
  | 'lg:translate-y-1/2'
  | 'xl:translate-y-1/2'
  | '2xl:translate-y-1/2'
  | 'hover:translate-y-1/2'
  | 'focus:translate-y-1/2'
  | 'sm:translate-y-1/3'
  | 'md:translate-y-1/3'
  | 'lg:translate-y-1/3'
  | 'xl:translate-y-1/3'
  | '2xl:translate-y-1/3'
  | 'hover:translate-y-1/3'
  | 'focus:translate-y-1/3'
  | 'sm:translate-y-2/3'
  | 'md:translate-y-2/3'
  | 'lg:translate-y-2/3'
  | 'xl:translate-y-2/3'
  | '2xl:translate-y-2/3'
  | 'hover:translate-y-2/3'
  | 'focus:translate-y-2/3'
  | 'sm:translate-y-1/4'
  | 'md:translate-y-1/4'
  | 'lg:translate-y-1/4'
  | 'xl:translate-y-1/4'
  | '2xl:translate-y-1/4'
  | 'hover:translate-y-1/4'
  | 'focus:translate-y-1/4'
  | 'sm:translate-y-2/4'
  | 'md:translate-y-2/4'
  | 'lg:translate-y-2/4'
  | 'xl:translate-y-2/4'
  | '2xl:translate-y-2/4'
  | 'hover:translate-y-2/4'
  | 'focus:translate-y-2/4'
  | 'sm:translate-y-3/4'
  | 'md:translate-y-3/4'
  | 'lg:translate-y-3/4'
  | 'xl:translate-y-3/4'
  | '2xl:translate-y-3/4'
  | 'hover:translate-y-3/4'
  | 'focus:translate-y-3/4'
  | 'sm:translate-y-full'
  | 'md:translate-y-full'
  | 'lg:translate-y-full'
  | 'xl:translate-y-full'
  | '2xl:translate-y-full'
  | 'hover:translate-y-full'
  | 'focus:translate-y-full'
  | 'sm:-translate-y-1/2'
  | 'md:-translate-y-1/2'
  | 'lg:-translate-y-1/2'
  | 'xl:-translate-y-1/2'
  | '2xl:-translate-y-1/2'
  | 'hover:-translate-y-1/2'
  | 'focus:-translate-y-1/2'
  | 'sm:-translate-y-1/3'
  | 'md:-translate-y-1/3'
  | 'lg:-translate-y-1/3'
  | 'xl:-translate-y-1/3'
  | '2xl:-translate-y-1/3'
  | 'hover:-translate-y-1/3'
  | 'focus:-translate-y-1/3'
  | 'sm:-translate-y-2/3'
  | 'md:-translate-y-2/3'
  | 'lg:-translate-y-2/3'
  | 'xl:-translate-y-2/3'
  | '2xl:-translate-y-2/3'
  | 'hover:-translate-y-2/3'
  | 'focus:-translate-y-2/3'
  | 'sm:-translate-y-1/4'
  | 'md:-translate-y-1/4'
  | 'lg:-translate-y-1/4'
  | 'xl:-translate-y-1/4'
  | '2xl:-translate-y-1/4'
  | 'hover:-translate-y-1/4'
  | 'focus:-translate-y-1/4'
  | 'sm:-translate-y-2/4'
  | 'md:-translate-y-2/4'
  | 'lg:-translate-y-2/4'
  | 'xl:-translate-y-2/4'
  | '2xl:-translate-y-2/4'
  | 'hover:-translate-y-2/4'
  | 'focus:-translate-y-2/4'
  | 'sm:-translate-y-3/4'
  | 'md:-translate-y-3/4'
  | 'lg:-translate-y-3/4'
  | 'xl:-translate-y-3/4'
  | '2xl:-translate-y-3/4'
  | 'hover:-translate-y-3/4'
  | 'focus:-translate-y-3/4'
  | 'sm:-translate-y-full'
  | 'md:-translate-y-full'
  | 'lg:-translate-y-full'
  | 'xl:-translate-y-full'
  | '2xl:-translate-y-full'
  | 'hover:-translate-y-full'
  | 'focus:-translate-y-full'
  | 'sm:select-none'
  | 'md:select-none'
  | 'lg:select-none'
  | 'xl:select-none'
  | '2xl:select-none'
  | 'sm:select-text'
  | 'md:select-text'
  | 'lg:select-text'
  | 'xl:select-text'
  | '2xl:select-text'
  | 'sm:select-all'
  | 'md:select-all'
  | 'lg:select-all'
  | 'xl:select-all'
  | '2xl:select-all'
  | 'sm:select-auto'
  | 'md:select-auto'
  | 'lg:select-auto'
  | 'xl:select-auto'
  | '2xl:select-auto'
  | 'sm:align-baseline'
  | 'md:align-baseline'
  | 'lg:align-baseline'
  | 'xl:align-baseline'
  | '2xl:align-baseline'
  | 'sm:align-top'
  | 'md:align-top'
  | 'lg:align-top'
  | 'xl:align-top'
  | '2xl:align-top'
  | 'sm:align-middle'
  | 'md:align-middle'
  | 'lg:align-middle'
  | 'xl:align-middle'
  | '2xl:align-middle'
  | 'sm:align-bottom'
  | 'md:align-bottom'
  | 'lg:align-bottom'
  | 'xl:align-bottom'
  | '2xl:align-bottom'
  | 'sm:align-text-top'
  | 'md:align-text-top'
  | 'lg:align-text-top'
  | 'xl:align-text-top'
  | '2xl:align-text-top'
  | 'sm:align-text-bottom'
  | 'md:align-text-bottom'
  | 'lg:align-text-bottom'
  | 'xl:align-text-bottom'
  | '2xl:align-text-bottom'
  | 'sm:visible'
  | 'md:visible'
  | 'lg:visible'
  | 'xl:visible'
  | '2xl:visible'
  | 'sm:invisible'
  | 'md:invisible'
  | 'lg:invisible'
  | 'xl:invisible'
  | '2xl:invisible'
  | 'sm:whitespace-normal'
  | 'md:whitespace-normal'
  | 'lg:whitespace-normal'
  | 'xl:whitespace-normal'
  | '2xl:whitespace-normal'
  | 'sm:whitespace-nowrap'
  | 'md:whitespace-nowrap'
  | 'lg:whitespace-nowrap'
  | 'xl:whitespace-nowrap'
  | '2xl:whitespace-nowrap'
  | 'sm:whitespace-pre'
  | 'md:whitespace-pre'
  | 'lg:whitespace-pre'
  | 'xl:whitespace-pre'
  | '2xl:whitespace-pre'
  | 'sm:whitespace-pre-line'
  | 'md:whitespace-pre-line'
  | 'lg:whitespace-pre-line'
  | 'xl:whitespace-pre-line'
  | '2xl:whitespace-pre-line'
  | 'sm:whitespace-pre-wrap'
  | 'md:whitespace-pre-wrap'
  | 'lg:whitespace-pre-wrap'
  | 'xl:whitespace-pre-wrap'
  | '2xl:whitespace-pre-wrap'
  | 'sm:w-0'
  | 'md:w-0'
  | 'lg:w-0'
  | 'xl:w-0'
  | '2xl:w-0'
  | 'sm:w-1'
  | 'md:w-1'
  | 'lg:w-1'
  | 'xl:w-1'
  | '2xl:w-1'
  | 'sm:w-2'
  | 'md:w-2'
  | 'lg:w-2'
  | 'xl:w-2'
  | '2xl:w-2'
  | 'sm:w-3'
  | 'md:w-3'
  | 'lg:w-3'
  | 'xl:w-3'
  | '2xl:w-3'
  | 'sm:w-4'
  | 'md:w-4'
  | 'lg:w-4'
  | 'xl:w-4'
  | '2xl:w-4'
  | 'sm:w-5'
  | 'md:w-5'
  | 'lg:w-5'
  | 'xl:w-5'
  | '2xl:w-5'
  | 'sm:w-6'
  | 'md:w-6'
  | 'lg:w-6'
  | 'xl:w-6'
  | '2xl:w-6'
  | 'sm:w-7'
  | 'md:w-7'
  | 'lg:w-7'
  | 'xl:w-7'
  | '2xl:w-7'
  | 'sm:w-8'
  | 'md:w-8'
  | 'lg:w-8'
  | 'xl:w-8'
  | '2xl:w-8'
  | 'sm:w-9'
  | 'md:w-9'
  | 'lg:w-9'
  | 'xl:w-9'
  | '2xl:w-9'
  | 'sm:w-10'
  | 'md:w-10'
  | 'lg:w-10'
  | 'xl:w-10'
  | '2xl:w-10'
  | 'sm:w-11'
  | 'md:w-11'
  | 'lg:w-11'
  | 'xl:w-11'
  | '2xl:w-11'
  | 'sm:w-12'
  | 'md:w-12'
  | 'lg:w-12'
  | 'xl:w-12'
  | '2xl:w-12'
  | 'sm:w-14'
  | 'md:w-14'
  | 'lg:w-14'
  | 'xl:w-14'
  | '2xl:w-14'
  | 'sm:w-16'
  | 'md:w-16'
  | 'lg:w-16'
  | 'xl:w-16'
  | '2xl:w-16'
  | 'sm:w-20'
  | 'md:w-20'
  | 'lg:w-20'
  | 'xl:w-20'
  | '2xl:w-20'
  | 'sm:w-24'
  | 'md:w-24'
  | 'lg:w-24'
  | 'xl:w-24'
  | '2xl:w-24'
  | 'sm:w-28'
  | 'md:w-28'
  | 'lg:w-28'
  | 'xl:w-28'
  | '2xl:w-28'
  | 'sm:w-32'
  | 'md:w-32'
  | 'lg:w-32'
  | 'xl:w-32'
  | '2xl:w-32'
  | 'sm:w-36'
  | 'md:w-36'
  | 'lg:w-36'
  | 'xl:w-36'
  | '2xl:w-36'
  | 'sm:w-40'
  | 'md:w-40'
  | 'lg:w-40'
  | 'xl:w-40'
  | '2xl:w-40'
  | 'sm:w-44'
  | 'md:w-44'
  | 'lg:w-44'
  | 'xl:w-44'
  | '2xl:w-44'
  | 'sm:w-48'
  | 'md:w-48'
  | 'lg:w-48'
  | 'xl:w-48'
  | '2xl:w-48'
  | 'sm:w-52'
  | 'md:w-52'
  | 'lg:w-52'
  | 'xl:w-52'
  | '2xl:w-52'
  | 'sm:w-56'
  | 'md:w-56'
  | 'lg:w-56'
  | 'xl:w-56'
  | '2xl:w-56'
  | 'sm:w-60'
  | 'md:w-60'
  | 'lg:w-60'
  | 'xl:w-60'
  | '2xl:w-60'
  | 'sm:w-64'
  | 'md:w-64'
  | 'lg:w-64'
  | 'xl:w-64'
  | '2xl:w-64'
  | 'sm:w-72'
  | 'md:w-72'
  | 'lg:w-72'
  | 'xl:w-72'
  | '2xl:w-72'
  | 'sm:w-80'
  | 'md:w-80'
  | 'lg:w-80'
  | 'xl:w-80'
  | '2xl:w-80'
  | 'sm:w-96'
  | 'md:w-96'
  | 'lg:w-96'
  | 'xl:w-96'
  | '2xl:w-96'
  | 'sm:w-auto'
  | 'md:w-auto'
  | 'lg:w-auto'
  | 'xl:w-auto'
  | '2xl:w-auto'
  | 'sm:w-px'
  | 'md:w-px'
  | 'lg:w-px'
  | 'xl:w-px'
  | '2xl:w-px'
  | 'sm:w-0.5'
  | 'md:w-0.5'
  | 'lg:w-0.5'
  | 'xl:w-0.5'
  | '2xl:w-0.5'
  | 'sm:w-1.5'
  | 'md:w-1.5'
  | 'lg:w-1.5'
  | 'xl:w-1.5'
  | '2xl:w-1.5'
  | 'sm:w-2.5'
  | 'md:w-2.5'
  | 'lg:w-2.5'
  | 'xl:w-2.5'
  | '2xl:w-2.5'
  | 'sm:w-3.5'
  | 'md:w-3.5'
  | 'lg:w-3.5'
  | 'xl:w-3.5'
  | '2xl:w-3.5'
  | 'sm:w-1/2'
  | 'md:w-1/2'
  | 'lg:w-1/2'
  | 'xl:w-1/2'
  | '2xl:w-1/2'
  | 'sm:w-1/3'
  | 'md:w-1/3'
  | 'lg:w-1/3'
  | 'xl:w-1/3'
  | '2xl:w-1/3'
  | 'sm:w-2/3'
  | 'md:w-2/3'
  | 'lg:w-2/3'
  | 'xl:w-2/3'
  | '2xl:w-2/3'
  | 'sm:w-1/4'
  | 'md:w-1/4'
  | 'lg:w-1/4'
  | 'xl:w-1/4'
  | '2xl:w-1/4'
  | 'sm:w-2/4'
  | 'md:w-2/4'
  | 'lg:w-2/4'
  | 'xl:w-2/4'
  | '2xl:w-2/4'
  | 'sm:w-3/4'
  | 'md:w-3/4'
  | 'lg:w-3/4'
  | 'xl:w-3/4'
  | '2xl:w-3/4'
  | 'sm:w-1/5'
  | 'md:w-1/5'
  | 'lg:w-1/5'
  | 'xl:w-1/5'
  | '2xl:w-1/5'
  | 'sm:w-2/5'
  | 'md:w-2/5'
  | 'lg:w-2/5'
  | 'xl:w-2/5'
  | '2xl:w-2/5'
  | 'sm:w-3/5'
  | 'md:w-3/5'
  | 'lg:w-3/5'
  | 'xl:w-3/5'
  | '2xl:w-3/5'
  | 'sm:w-4/5'
  | 'md:w-4/5'
  | 'lg:w-4/5'
  | 'xl:w-4/5'
  | '2xl:w-4/5'
  | 'sm:w-1/6'
  | 'md:w-1/6'
  | 'lg:w-1/6'
  | 'xl:w-1/6'
  | '2xl:w-1/6'
  | 'sm:w-2/6'
  | 'md:w-2/6'
  | 'lg:w-2/6'
  | 'xl:w-2/6'
  | '2xl:w-2/6'
  | 'sm:w-3/6'
  | 'md:w-3/6'
  | 'lg:w-3/6'
  | 'xl:w-3/6'
  | '2xl:w-3/6'
  | 'sm:w-4/6'
  | 'md:w-4/6'
  | 'lg:w-4/6'
  | 'xl:w-4/6'
  | '2xl:w-4/6'
  | 'sm:w-5/6'
  | 'md:w-5/6'
  | 'lg:w-5/6'
  | 'xl:w-5/6'
  | '2xl:w-5/6'
  | 'sm:w-1/12'
  | 'md:w-1/12'
  | 'lg:w-1/12'
  | 'xl:w-1/12'
  | '2xl:w-1/12'
  | 'sm:w-2/12'
  | 'md:w-2/12'
  | 'lg:w-2/12'
  | 'xl:w-2/12'
  | '2xl:w-2/12'
  | 'sm:w-3/12'
  | 'md:w-3/12'
  | 'lg:w-3/12'
  | 'xl:w-3/12'
  | '2xl:w-3/12'
  | 'sm:w-4/12'
  | 'md:w-4/12'
  | 'lg:w-4/12'
  | 'xl:w-4/12'
  | '2xl:w-4/12'
  | 'sm:w-5/12'
  | 'md:w-5/12'
  | 'lg:w-5/12'
  | 'xl:w-5/12'
  | '2xl:w-5/12'
  | 'sm:w-6/12'
  | 'md:w-6/12'
  | 'lg:w-6/12'
  | 'xl:w-6/12'
  | '2xl:w-6/12'
  | 'sm:w-7/12'
  | 'md:w-7/12'
  | 'lg:w-7/12'
  | 'xl:w-7/12'
  | '2xl:w-7/12'
  | 'sm:w-8/12'
  | 'md:w-8/12'
  | 'lg:w-8/12'
  | 'xl:w-8/12'
  | '2xl:w-8/12'
  | 'sm:w-9/12'
  | 'md:w-9/12'
  | 'lg:w-9/12'
  | 'xl:w-9/12'
  | '2xl:w-9/12'
  | 'sm:w-10/12'
  | 'md:w-10/12'
  | 'lg:w-10/12'
  | 'xl:w-10/12'
  | '2xl:w-10/12'
  | 'sm:w-11/12'
  | 'md:w-11/12'
  | 'lg:w-11/12'
  | 'xl:w-11/12'
  | '2xl:w-11/12'
  | 'sm:w-full'
  | 'md:w-full'
  | 'lg:w-full'
  | 'xl:w-full'
  | '2xl:w-full'
  | 'sm:w-screen'
  | 'md:w-screen'
  | 'lg:w-screen'
  | 'xl:w-screen'
  | '2xl:w-screen'
  | 'sm:w-min'
  | 'md:w-min'
  | 'lg:w-min'
  | 'xl:w-min'
  | '2xl:w-min'
  | 'sm:w-max'
  | 'md:w-max'
  | 'lg:w-max'
  | 'xl:w-max'
  | '2xl:w-max'
  | 'sm:break-normal'
  | 'md:break-normal'
  | 'lg:break-normal'
  | 'xl:break-normal'
  | '2xl:break-normal'
  | 'sm:break-words'
  | 'md:break-words'
  | 'lg:break-words'
  | 'xl:break-words'
  | '2xl:break-words'
  | 'sm:break-all'
  | 'md:break-all'
  | 'lg:break-all'
  | 'xl:break-all'
  | '2xl:break-all'
  | 'sm:z-0'
  | 'md:z-0'
  | 'lg:z-0'
  | 'xl:z-0'
  | '2xl:z-0'
  | 'focus-within:z-0'
  | 'focus:z-0'
  | 'sm:z-10'
  | 'md:z-10'
  | 'lg:z-10'
  | 'xl:z-10'
  | '2xl:z-10'
  | 'focus-within:z-10'
  | 'focus:z-10'
  | 'sm:z-20'
  | 'md:z-20'
  | 'lg:z-20'
  | 'xl:z-20'
  | '2xl:z-20'
  | 'focus-within:z-20'
  | 'focus:z-20'
  | 'sm:z-30'
  | 'md:z-30'
  | 'lg:z-30'
  | 'xl:z-30'
  | '2xl:z-30'
  | 'focus-within:z-30'
  | 'focus:z-30'
  | 'sm:z-40'
  | 'md:z-40'
  | 'lg:z-40'
  | 'xl:z-40'
  | '2xl:z-40'
  | 'focus-within:z-40'
  | 'focus:z-40'
  | 'sm:z-50'
  | 'md:z-50'
  | 'lg:z-50'
  | 'xl:z-50'
  | '2xl:z-50'
  | 'focus-within:z-50'
  | 'focus:z-50'
  | 'sm:z-auto'
  | 'md:z-auto'
  | 'lg:z-auto'
  | 'xl:z-auto'
  | '2xl:z-auto'
  | 'focus-within:z-auto'
  | 'focus:z-auto'
  | 'sm:sr-only'
  | 'md:sr-only'
  | 'lg:sr-only'
  | 'xl:sr-only'
  | '2xl:sr-only'
  | 'focus-within:sr-only'
  | 'focus:sr-only'
  | 'sm:not-sr-only'
  | 'md:not-sr-only'
  | 'lg:not-sr-only'
  | 'xl:not-sr-only'
  | '2xl:not-sr-only'
  | 'focus-within:not-sr-only'
  | 'focus:not-sr-only'

export type TClasses =
  | TAccessibility
  | TBackgrounds
  | TBorders
  | TTables
  | TEffects
  | TTransitionsAndAnimations
  | TFilters
  | TFlexBox
  | TGrid
  | TSpacing
  | TInteractivity
  | TLayout
  | TSizing
  | TSVG
  | TTransforms
  | TTypography
  | TPseudoClasses

export type TTailwindString = 'TAILWIND_STRING'

export type TKey = TClasses | TTailwindString

export type TArg =
  | TClasses
  | null
  | undefined
  | { [key in TKey]?: boolean }
  | TTailwindString

export type TTailwind = (...args: TArg[]) => TTailwindString

export const classnames: TTailwind = classnamesLib as any

export const tw = classnames

export default tw
